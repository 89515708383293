import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import LawyerListContainer from './LawyerListContainer';
import {TabPanel, TabView} from 'primereact/tabview';
import LawOfficeModel from '../../model/LawOfficeModel';
import LawOfficeContainer from './LawOfficeContainer';
import InternalUserService from '../../services/InternalUserService';

class LawOfficeNavContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.state = {
            loading: true,
            elementId: props.params.id,
            element: new LawOfficeModel(),
            dialogVisibile: false,
            backUrl: '',
            person: undefined,
        };
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    getContainerListName() {
        return 'law-office-list-container';
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('lawOffice.updateSuccessfull');
    }

    getRemoveSucces() {
        const {t} = this.props;
        return t('lawOffice.deleteSuccessfull');
    }

    renderFooter() {
        return null;
    }
    renderBackLink() {
        return null;
    }

    initBeforeSetElement() {
        let activeIndex = this.readCookie(`law-office-list-activeIndex`);

        new InternalUserService()
            .getUserType(this.props.elementId)
            .then((response) => {
                this.setState({
                    person: response.person,
                });
                if (activeIndex === undefined || activeIndex === null || activeIndex === '' || response.person === 'LAW_OFFICE') {
                    this.saveCookie(`law-office-list-activeIndex`, 0);
                    activeIndex = 0;
                }
                this.setState({activeIndex: parseInt(activeIndex)});
            })
            .catch((err) => {
                console.log(err);
            });

        this.unblockUi();
    }

    handleTabChange(e) {
        this.setState(
            {
                activeIndex: e.index,
            },
            () => {
                this.saveCookie(`law-office-list-activeIndex`, this.state.activeIndex);
            }
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.state.person === 'LAW_OFFICE_MANAGER' ? (
                    <>
                        <TabView activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange}>
                            <TabPanel header={'Dane kancelarii'}>
                                <LawOfficeContainer
                                    params={this.props.params}
                                    currentUser={this.props.currentUser}
                                    elementId={this.props.elementId}
                                    mainRef={this.props.mainRef}
                                    user={this.props.user}
                                    viewMode={this.props.viewMode}
                                    history={this.props.history}
                                />
                            </TabPanel>
                            <TabPanel header={'Prawnicy'}>
                                <LawyerListContainer elementId={this.props.elementId} />
                            </TabPanel>
                        </TabView>
                    </>
                ) : (
                    <>
                        <TabView activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange}>
                            <TabPanel header={'Dane kancelarii'}>
                                <LawOfficeContainer
                                    params={this.props.params}
                                    currentUser={this.props.currentUser}
                                    elementId={this.props.elementId}
                                    mainRef={this.props.mainRef}
                                    user={this.props.user}
                                    viewMode={this.props.viewMode}
                                    history={this.props.history}
                                />
                            </TabPanel>
                        </TabView>
                    </>
                )}
            </React.Fragment>
        );
    }
}

LawOfficeNavContainer.defaultProps = {
    backUrl: '/#/law-office-list',
    cancelUrl: '/#/law-office/details',
    editUrl: '/#/law-office/edit',
};

LawOfficeNavContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withTranslation()(LawOfficeNavContainer);
