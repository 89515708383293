import customTextAligner from './customTextAligner';
// ex) A command plugin to add "Range format element(util.isRangeFormatElement)" to selection
var pluginAlignLeft = {
    // @Required @Unique
    // plugin name
    name: 'plugin_align_left',
    // @Required
    // data display
    display: 'command',

    // @Options
    title: 'Add range tag',
    buttonClass: '',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
        const context = core.context;
        const rangeTag = core.util.createElement('div');
        core.util.addClass(rangeTag, '__se__format__range_custom_left');
        // @Required
        // Registering a namespace for caching as a plugin name in the context object
        context.customCommand = {
            targetButton: targetElement,
            tag: rangeTag,
        };
    },

    // @Override core
    // Plugins with active methods load immediately when the editor loads.
    // Called each time the selection is moved.
    active: function (element) {
        // if (!element) {
        //     this.util.removeClass(this.context.customCommand.targetButton, 'active');
        // } else if (this.util.hasClass(element, '__se__format__range_custom_left')) {
        //     this.util.addClass(this.context.customCommand.targetButton, 'active');
        //     return true;
        // }
        return false;
    },

    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
        // const rangeTag = this.util.getRangeFormatElement(this.getSelectionNode());

        // if (this.util.hasClass(rangeTag, '__se__format__range_custom_left')) {
        //     this.detachRangeFormatElement(rangeTag, null, null, false, false);
        // } else {
        //     this.applyRangeFormatElement(this.context.customCommand.tag.cloneNode(false));
        // }
        if (window.getSelection()) {
            customTextAligner(
                window.getSelection(),
                'left',
                this.context?.options?.insertTag ? this.context?.options?.insertTag : () => {},
                this.context?.options?.blockingAction ? this.context?.options?.blockingAction : () => {}
            );
            this.history.push();
        }
    },
};
export default pluginAlignLeft;
