import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import TemplateService from '../services/TemplateService';
import ActionLink from '../components/ActionLink';
import AuthService from '../services/AuthService';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CategoryService from '../services/CategoryService';
import Constants from '../utils/constants';
import DivContainer from '../components/DivContainer';
import CriteriaCheckboxComponent from '../components/criteria/CriteriaCheckboxComponent';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import DuplicateTemplateDialog from './templates/DuplicateTemplateDialog';
import CreatedCopyHighlightUtils from './templates/CreatedCopyHighlightUtils';
import {CookiesConstants} from '../utils/CookiesConstants';
import DialogResizer from '../components/DialogResizer';
import AgreementConfiguratorTemplateList from '../components/AgreementConfiguratorTemplateList';

class ConfiguratorTemplateListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new TemplateService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            roleOptions: [],
            categories: [],
            duplicationDialogVisible: false,
        };
        this.authService = new AuthService();
        this.categoryService = new CategoryService();
        this.agreementTemplateDetail = this.agreementTemplateDetail.bind(this);
        this.openDuplicationDialog = this.openDuplicationDialog.bind(this);
        this.closeDuplicationDialog = this.closeDuplicationDialog.bind(this);
        this.hrefDetail = this.hrefEdit.bind(this);
    }
    getDataKey() {
        return 'id';
    }

    getListTitle() {
        return 'template.listTitle';
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    readMessage() {
        if (!this.props.embedded) {
            super.readMessage();
        }
    }
    updateSearchCriteria(criteria) {
        return {
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            text: criteria.text,
            category: criteria.category,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            current: criteria.current,
            showSubTemplate: criteria.showSubTemplate,
        };
    }

    getCleanSearchCriteria() {
        return {
            dateFrom: undefined,
            dateTo: undefined,
            text: '',
            category: undefined,
            id: null,
            firstResult: 0,
            maxResults: this.props.current ? 5 : 10,
            sortField: 'id',
            sortAsc: false,
            current: this.props.current,
            showSubTemplate: true,
        };
    }

    initializeFromBackend() {
        this.categoryService
            .getAll()
            .then((categories) =>
                this.setState({
                    categories,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });
        this.unblockUi();
    }

    getCriteriaName() {
        return 'configurator-template-list-sc';
    }

    getContainerListName() {
        return 'configurator-template-list-container';
    }

    openDuplicationDialog(agreementTemplateType) {
        this.setState({duplicationDialogVisible: true, agreementTemplateType});
    }
    closeDuplicationDialog(callback) {
        const agreementTemplateType = JSON.parse(JSON.stringify(this.state.agreementTemplateType));
        this.setState(
            {duplicationDialogVisible: false, agreementTemplateType: null},
            () => {
                if (callback) {
                    callback(agreementTemplateType);
                }
            },
            () => {
                DialogResizer.removeMaskIfExistOneDialog();
            }
        );
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='text-sc'
                    name='text'
                    label={'Tytuł szablonu'}
                    placeholder='Wpisz tytuł...'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.text}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
                <CriteriaDropdownComponent
                    id='category-sc'
                    name='category'
                    showClear
                    label={'Kategoria'}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.category}
                    options={this.state.categories}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='name'
                />
                <CriteriaCheckboxComponent
                    id='showSubTemplate'
                    name='showSubTemplate'
                    label='Pokaż subszablony'
                    value={this.state.criteria.showSubTemplate}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    colClass='col-lg-4 col-sm-6'
                    refreshFromBackend
                />
            </div>
        );
    }

    prepareColumns() {
        const {t} = this.props;

        return this.props.current
            ? [
                  {
                      field: 'id',
                      header: t('agreement.listHeaders.id'),
                      sortable: true,
                      width: '80px',
                  },
                  {field: 'name', header: t('agreement.listHeaders.title'), sortable: true},
                  {field: 'category.name', header: 'Kategoria', sortable: true},
                  {
                      field: 'createDate',
                      header: t('agreement.listHeaders.createDate'),
                      width: '180px',
                      sortable: true,
                      body: this.dateTemplate.bind(this, 'createDate', 'DD-MM-YYYY'),
                  },
                  {
                      field: 'numberOfGeneratedDocuments',
                      header: 'Wygenerowano',
                      sortable: true,
                      width: '180px',
                      bodyClassName: 'ceneter-table-content',
                  },
                  {
                      field: 'agreementTemplateType',
                      header: 'Typ',
                      body: this.enumTemplate.bind(this, 'AgreementTemplateType', 'agreementTemplateType'),
                      sortable: true,
                      width: '130px',
                  },
                  {field: 'author', header: 'Autor', sortable: true},
                  {
                      field: 'status',
                      header: 'Status',
                      sortable: true,
                      body: this.enumTemplate.bind(this, 'AgreementTemplateStatus', 'status'),
                      width: '170px',
                  },

                  {
                      key: 'actions',
                      header: t('agreement.listHeaders.actions'),
                      body: this.actionTemplate,
                      className: 'table-column-action',
                  },
              ]
            : [
                  {field: 'name', header: t('agreement.listHeaders.title'), sortable: true},
                  {
                      field: 'modifyDate',
                      header: 'Data modyfikacji',
                      body: this.dateTemplate.bind(this, 'createDate', Constants.DATE_FORMAT),
                      sortable: true,
                  },

                  {field: 'author', header: 'Edytor', sortable: true},
                  {field: 'category.name', header: 'Kategoria', sortable: true},
                  {
                      field: 'status',
                      header: 'Status',
                      sortable: true,
                      body: this.enumTemplate.bind(this, 'AgreementTemplateStatus', 'status'),
                  },
                  {
                      key: 'actions',
                      header: t('agreement.listHeaders.actions'),
                      body: this.agreementTemplateDetail,
                      className: 'table-column-action',
                  },
              ];
    }

    actionTemplate(rowData) {
        const href = this.hrefEdit(rowData);
        return !this.isLexyPortal() ? (
            <div className='float-center'>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                    onClick={this.handleGoToDetail.bind(this, href)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        ) : (
            <ActionLink
                variant='blue'
                className='p-link'
                key={'view-button'}
                iconName='mdi-eye'
                iconSide='right'
                iconSize='xs'
                iconColor='blue'
            />
        );
    }

    hrefEdit(rowData) {
        const {editlUrl, editSubTemplateUrl} = this.props;
        return rowData?.agreementTemplateType === 'PARTIAL_TEMPLATE'
            ? `${editSubTemplateUrl}/${rowData?.id}`
            : `${editlUrl}/${rowData?.id}`;
    }

    agreementTemplateDetail(rowData) {
        const {detailUrl, detailSubTemplateUrl, editSubTemplateUrl, editlUrl} = this.props;
        let href;
        if (rowData.status === 'DUPLICATED_BY_EDITOR' || rowData.status === 'DRAFT') {
            href =
                rowData?.agreementTemplateType === 'PARTIAL_TEMPLATE'
                    ? `${editSubTemplateUrl}/${rowData?.id}`
                    : `${editlUrl}/${rowData?.id}`;
        } else {
            href =
                rowData?.agreementTemplateType === 'PARTIAL_TEMPLATE'
                    ? `${detailSubTemplateUrl}/${rowData?.id}`
                    : `${detailUrl}/${rowData?.id}`;
        }

        return this.isLexyPortal() ? (
            <ActionLink
                handleClick={this.handleGoToDetail.bind(this, href)}
                label=''
                variant='blue'
                key={'view-button'}
                iconName='mdi-eye'
            />
        ) : (
            <div className='float-center'>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                    onClick={this.handleGoToDetail.bind(this, href)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        );
    }

    canUserViewSubTemplate() {
        return this.authService.isUserInAnyRole(
            'ROLE_ADMIN_SUPER_MANAGE',
            'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
            'ROLE_LAW_OFFICE_EDITOR_MANAGE',
            'ROLE_ADMIN_TEMPLATE_MANAGE'
        );
    }
    renderDataTable(columns, list, size) {
        if (this.props.current && !this.isLexyPortal()) {
            return (
                <div id='just-paginator-from-table'>
                    <AgreementConfiguratorTemplateList
                        list={this.state.list}
                        editClickEvent={(element) => {
                            const href = this.hrefEdit(element);
                            window.location.href = AppPrefixUtils.locationHrefUrl(href);
                        }}
                    />

                    {super.renderDataTable(columns, list, size, false)}
                </div>
            );
        } else {
            return super.renderDataTable(columns, list, size);
        }
    }
    prepareHeader(items) {
        const leftItems = [];
        const rightItems = [];
        const rightSide = [];
        if (items !== undefined && items.length > 0) {
            items.forEach((item) => {
                if (item.className !== undefined && item.className.includes('float-right')) {
                    item.className = item.className.replace('float-right', '');
                    rightItems.push(item);
                } else if (item.className !== undefined && item.className.includes('right-side')) {
                    item.className = item.className.replace('right-side', '');
                    rightSide.push(item);
                } else {
                    leftItems.push(item);
                }
            });
        }
        return (
            <DivContainer colClass='p-card-header-minheight'>
                <DivContainer colClass='header-items custom-headers'>
                    {leftItems && leftItems.length > 0
                        ? leftItems.map((item, index) => {
                              const imageUrl = this.isLexyPortal()
                                  ? null
                                  : AppPrefixUtils.locationHrefUrl(`/images/lexdocs/`) +
                                    (index === 0 || index === 2 ? 'add_blue.svg' : 'post_add_blue.svg');
                              return item.customRenderFunction instanceof Function ? (
                                  item.customRenderFunction()
                              ) : this.isLexyPortal() ? (
                                  this.renderItem(item, index)
                              ) : (
                                  <div>{this.renderItem(item, index, imageUrl)}</div>
                              );
                          })
                        : null}
                    <div className='header-item float-right'>
                        {rightItems && rightItems.length > 0
                            ? rightItems.map((item, index) =>
                                  item.customRenderFunction instanceof Function ? item.customRenderFunction() : this.renderItem(item, index)
                              )
                            : null}
                    </div>
                    <div className='header-item-right float-right'>
                        {rightSide && rightSide.length > 0
                            ? rightSide.map((item, index) =>
                                  item.customRenderFunction instanceof Function ? item.customRenderFunction() : this.renderItem(item, index)
                              )
                            : null}
                    </div>
                </DivContainer>
            </DivContainer>
        );
    }
    prepareHeaderItems() {
        const {t} = this.props;
        return this.props.current
            ? [
                  {
                      label: t('agreement.addButton'),
                      href: AppPrefixUtils.locationHrefUrl(this.props.newUrl),
                      type: 'BUTTON',
                      className: 'float-left',
                      onClick: (e) => {
                          this.saveCookie(CookiesConstants.DETAILS_VIEW_MODE, 'NEW');
                      },
                      rendered: this.canUserViewSubTemplate(),
                  },
                  {
                      label: 'Utwórz na podstawie kopii szablonu',
                      onClick: (e) => {
                          e.preventDefault();
                          this.openDuplicationDialog('TEMPLATE');
                      },
                      type: 'BUTTON',
                      className: 'cancel-button gray',
                      variant: 'white',
                      rendered: true,
                  },
                  {
                      label: t('agreement.addSubtemplateButton'),
                      href: AppPrefixUtils.locationHrefUrl(this.props.newSubTemplateUrl),
                      type: 'BUTTON',
                      onClick: (e) => {
                          this.saveCookie(CookiesConstants.DETAILS_VIEW_MODE, 'NEW');
                      },
                      rendered: this.canUserViewSubTemplate(),
                      className: 'float-left',
                  },
                  // TODO odkrycie buttona!
                  {
                      label: 'Utwórz na podstawie kopii subszablonu',
                      onClick: (e) => {
                          e.preventDefault();
                          this.openDuplicationDialog('PARTIAL_TEMPLATE');
                      },
                      rendered: true,
                      type: 'BUTTON',
                      className: 'cancel-button gray',
                      variant: 'white',
                  },
              ]
            : [];
    }
    persistMessage(severity, summary, detail) {
        this.saveCookie(
            this.getContainerListName(),
            JSON.stringify({
                severity,
                summary,
                detail,
            })
        );
    }

    render() {
        return (
            <React.Fragment>
                <DuplicateTemplateDialog
                    className={'duplicate-template-dialog'}
                    key={`duplicate-template-dialog-${this.state.duplicationDialogVisible}-${this.state.agreementTemplateType}`}
                    agreementTemplateType={this.state.agreementTemplateType}
                    onHide={() => {
                        this.closeDuplicationDialog();
                    }}
                    visible={this.state.duplicationDialogVisible && this.state.agreementTemplateType}
                    viewMode={'NEW'}
                    afterSave={(response) =>
                        this.closeDuplicationDialog((type) => {
                            if (
                                !this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TEMPLATE_MANAGE') &&
                                this.props.handleTabChange
                            ) {
                                this.props.handleTabChange({index: 1});
                            }
                            this.persistMessage(
                                'success',
                                '',
                                `${
                                    type === 'PARTIAL_TEMPLATE'
                                        ? 'Kopia subszablonu została utworzona.'
                                        : 'Kopia szablonu została utworzona.'
                                }`
                            );
                            if (this.props.readMessage) {
                                this.props.readMessage();
                            }
                            this.setState(
                                {createdDuplicateId: response.id},
                                () => {
                                    this.refreshTable();
                                },
                                () => {
                                    DialogResizer.removeMaskIfExistOneDialog();
                                }
                            );
                        })
                    }
                    afterError={(errorMessage) => {
                        this.closeDuplicationDialog(() => {
                            this.showErrorMessage(errorMessage);
                        });
                    }}
                    blockUi={this.blockUi}
                    unblockUi={this.unblockUi}
                    _isMounted={this._isMounted}
                ></DuplicateTemplateDialog>
                <div className='container-fluid'>{this.renderView()}</div>
            </React.Fragment>
        );
    }
    onAfterGetList(res) {
        CreatedCopyHighlightUtils.highlightGivenRow(this.state.createdDuplicateId);
    }
}

ConfiguratorTemplateListContainer.defaultProps = {
    detailUrl: '/#/template/details',
    editlUrl: '/#/template/edit',
    newUrl: '/#/template/create',
    newAgreementUrl: '/#/agreement/create',
    newSubTemplateUrl: '/#/sub-template/create/subtemplate',
    detailSubTemplateUrl: '/#/sub-template/details/subtemplate',
    editSubTemplateUrl: '/#/sub-template/edit/subtemplate',
    lazy: true,
    customBlockUi: ()=>{},
};

ConfiguratorTemplateListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
    customBlockUi: PropTypes.func,
};

export default withTranslation()(ConfiguratorTemplateListContainer);
