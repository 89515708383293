import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ReminderContainer from '../ReminderContainer';
import ConfirmExitComponentsUtils from '../../utils/ConfirmExitComponentsUtils';
import {FaSignOutAlt} from 'react-icons/fa';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import {UAParser} from 'ua-parser-js';
export default function CollapsableLexDocsNavbar(props) {
    const [isCollapsedShow, setCollapsedShow] = useState(true);
    const [isSidebarCollapsed, setSideBarCollapsed] = useState(false);
    const {device} = UAParser(navigator.userAgent);

    const toggleNavbarCollapsed = (isCollapsed) => {
        const toogler = document.getElementById('navbar-toggler-laptop-colapsed');
        if (toogler) {
            isCollapsed ? (toogler.style.display = 'none') : (toogler.style.display = 'flex');
        }
    };
    const isCollapsed = () => {
        const sidebar = document.getElementsByClassName('pro-sidebar')[0];
        if (sidebar) {
            return sidebar.style.display === 'none';
        } else {
            return false;
        }
    };

    const renderMenuIcon = () => {
        const imgSrc = device.type === 'mobile' ? '/images/lexdocs/menu_mobile.svg' : '/images/lexdocs/menu_close.svg';
        return <img src={AppPrefixUtils.locationHrefUrl(imgSrc)} id='hamburger' alt='' width={45} />;
    };

    useEffect(() => {
        toggleNavbarCollapsed(!isCollapsed());
    });
    return (
        <div>
            <nav className='navbar '>
                <button
                    className={`navbar-toggler logged sidebar-toggler collapsed `}
                    type='button'
                    style={{marginTop: '12px'}}
                    data-toggle='collapse'
                    data-target='.dual-collapse'
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle sidebar'
                    onClick={() => {
                        const sidebarCollapsedCurrent = isSidebarCollapsed;
                        // if (!sidebarCollapsedCurrent) {

                        //     $('.pro-sidebar-inner').css('position', 'relative');
                        //     $('.sidebar-vertical').css('display', 'block');
                        // } else {
                        //     $('.pro-sidebar-inner').css('position', 'fixed');
                        //     $('.sidebar-vertical').css('display', 'none');
                        // }
                        const sidebar = document.getElementsByClassName('pro-sidebar')[0];
                        sidebar.style.display = 'flex';
                        props?.handleToggleSidebar(!sidebarCollapsedCurrent);
                        setSideBarCollapsed(!sidebarCollapsedCurrent);
                    }}
                >
                    <span className='navbar-toggler-icon'>{renderMenuIcon()}</span>
                </button>
                <div className={`navbar-collapse-custom dual-collapse order-0 px-2 `}>
                    <ul className='navbar-nav mr-auto '>
                        <li className='nav-item show-collapsed-row'>
                            <button
                                id={'navbar-toggler-laptop-colapsed'}
                                className={`navbar-toggler-laptop`}
                                type='button'
                                data-toggle='collapse'
                                data-target='.dual-collapse'
                                aria-controls='navbarSupportedContent'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={(e) => {
                                    const sidebar = document.getElementsByClassName('pro-sidebar')[0];

                                    toggleNavbarCollapsed(isCollapsed());
                                    if (sidebar.style.display === 'none') {
                                        sidebar.style.display = 'flex';
                                    } else {
                                        sidebar.style.display = 'none';
                                    }
                                    if (
                                        window.location.href.includes('template/edit') ||
                                        window.location.href.includes('template/create') ||
                                        window.location.href.includes('template/details')
                                    ) {
                                        if (window.templateRef.current) {
                                            if (window.templateRef.current?.onResizeEditor) {
                                                window.templateRef.current.onResizeEditor();
                                            }
                                        }
                                    }
                                    e.stopPropagation();
                                }}
                            >
                                <span className='navbar-toggler-icon'>
                                    <img src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/menu_open.svg')} id='hamburger' alt='' />
                                </span>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className={`navbar-collapse-custom dual-collapse order-1`}>
                    <ul className='navbar-nav ml-auto justify-content-end'>
                        <li className='nav-item'>
                            {!!props.showContact && (
                                <Link
                                    className='nav-link'
                                    to={``}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const redirect = '/#/contact';
                                        props.redirectOrShowConfirmationDialog(redirect);
                                    }}
                                >
                                    <div className='mt-2'>Kontakt</div>

                                    {/* <i alt='' className={`icon mdi mdi-mail`} /> */}
                                </Link>
                            )}
                        </li>
                        <li className='nav-item'>
                            <Link className='nav-link reminder-link'>
                                <ReminderContainer
                                    rel='noopener noreferrer'
                                    className='header-link'
                                    hideNavbar={() => {
                                        setCollapsedShow(!isCollapsedShow);
                                    }}
                                />
                            </Link>
                        </li>{' '}
                        {device.type !== 'mobile' && (
                            <li className='nav-item'>
                                <Link
                                    className='nav-link'
                                    to=''
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const redirect = '/#/manage-account/details/' + props.userId;
                                        props.redirectOrShowConfirmationDialog(redirect);
                                    }}
                                >
                                    <img src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/person.svg')} alt='' className='mt-2' />
                                </Link>
                            </li>
                        )}
                        {/* {device.type !== 'mobile' && ( */}
                        <li className='nav-item'>
                            <Link
                                className='nav-link mt-2'
                                to=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    const exist = ConfirmExitComponentsUtils.URLS.find((el) => {
                                        return window.location.href.includes(el);
                                    });
                                    if (exist) {
                                        props.redirectOrShowConfirmationDialog(props.logout);
                                    } else {
                                        props.logout();
                                    }
                                }}
                            >
                                <img src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/logout.svg')} alt='' />
                            </Link>
                        </li>
                        {/* )} */}
                    </ul>
                </div>
            </nav>
        </div>
    );
}
