export default class RegisterModel {
    constructor() {
        this.email = undefined;
        this.firstName = undefined;
        this.lastName = undefined;
        this.password = undefined;
        this.repeatPassword = undefined;
        this.person = undefined;
        this.companyName = undefined;
        this.portal = undefined;
        this.approvalContents = [];
        this.selectedsubs = undefined;
        this.subscriptionType = [];
        this.packages = [];
        this.address = [];
        this.nip = undefined;
        this.industry = undefined;
    }

    static assign(from, to) {
        if (from) {
            to.email = from.email;
            to.firstName = from.firstName;
            to.companyName = from.companyName;
            to.person = from.person.enumValue;
            to.lastName = from.lastName;
            to.portal = from.portal;
            to.password = from.password;
            to.repeatPassword = from.repeatPassword;
            to.address = from.address;
            to.nip = from.nip;
            to.industry = from.industry;
            to.approvalContents = from.approvalContents;
            to.packages = from.packages;
            to.selectedsubs = from.selectedsubs;
            to.subscriptionType = from.subscriptionType;
        } else {
            to = new RegisterModel();
        }
    }

    static copy(from) {
        const to = new RegisterModel();
        RegisterModel.assign(from, to);
        return to;
    }
}
