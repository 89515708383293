export default class ConsultationModel {
    constructor() {
        this.id = undefined;
        this.start =undefined;
        this.version = 0;
        this.end = undefined;
        this.user = undefined;
        this.status= "CONFIRMED";
        this.startHour = undefined;
        
        this.endHour = undefined;
        this.fullName = undefined;
        this.specialization= undefined;
        this.title= ''
    }

    static assign(from, to) {
        if (from) {
            to.id = from.id;
            to.start =from.start;
            to.version = from.version;
            to.end = from.end;
            to.startHour = from.startHour;
            to.endHour = from.endHour;
            to.user = from.user;
            to.fullName = from.fullName;
            to.status= from.status
            to.specialization= from.specialization;
            to.title= from.title;
        } else {
            to = new ConsultationModel();
        }
    }

    static copy(from) {
        const to = new ConsultationModel();
        ConsultationModel.assign(from, to);
        return to;
    }
}
