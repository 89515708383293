import HtmlParserUtils from './htmlParser/HtmlParserUtils';
import StringUtils from './StringUtils';
class AgreementCalculationUtils {
    static recalculateAndChangeHtml(key, value, map, transalteFunction) {
        if (value.sumElementUuids && value.sumElementUuids?.length > 0) {
            this.processSumElements(key, value, map, transalteFunction);
        }
        if (value.factorUuids && value.factorUuids?.length > 0) {
            this.proccessFactors(key, value, map, transalteFunction);
        }
    }
    static processSumElements(key, value, map, transalteFunction) {
        const numberPropertiesList = [];
        const currenciesMap = {};
        value.sumElementUuids.forEach((sumElementUUID) => {
            const sumElement = map[sumElementUUID];
            if (sumElement) {
                const decimalPlaces = AgreementCalculationUtils.getDecimalPlaces(sumElement?.numberProperties);
                if (sumElement?.parameterType === 'NUMBER') {
                    if (sumElement.answerCurrency) {
                        if (currenciesMap[sumElement.answerCurrency?.iso]) {
                            currenciesMap[sumElement.answerCurrency?.iso].result += +(
                                AgreementCalculationUtils.isBlank(sumElement.numberValue) ? 0 : sumElement.numberValue
                            ).toFixed(decimalPlaces);
                        } else {
                            currenciesMap[sumElement.answerCurrency?.iso] = {currency: sumElement.answerCurrency};
                            currenciesMap[sumElement.answerCurrency?.iso].result = +(
                                AgreementCalculationUtils.isBlank(sumElement.numberValue) ? 0 : sumElement.numberValue
                            ).toFixed(decimalPlaces);
                        }
                    } else {
                        if (currenciesMap['no-currency']) {
                            currenciesMap['no-currency'].result += +(
                                AgreementCalculationUtils.isBlank(sumElement.numberValue) ? 0 : sumElement.numberValue
                            ).toFixed(decimalPlaces);
                        } else {
                            currenciesMap['no-currency'] = {currency: null};
                            currenciesMap['no-currency'].result = +(
                                AgreementCalculationUtils.isBlank(sumElement.numberValue) ? 0 : sumElement.numberValue
                            ).toFixed(decimalPlaces);
                        }
                    }
                    numberPropertiesList.push(sumElement.numberProperties);
                } else if (sumElement?.parameterType === 'MULTIPLICATION') {
                    let multiplicationResult = 1;

                    let multiplicationCurrency = this.findCommonCurrencyForFactorList(map, sumElement.factorUuids);
                    sumElement.factorUuids?.forEach((factorUuid) => {
                        const factor = map[factorUuid];
                        const countDecimal = AgreementCalculationUtils.getDecimalPlaces(factor?.numberProperties);
                        multiplicationResult *= (AgreementCalculationUtils.isBlank(factor.numberValue) ? 0 : factor.numberValue).toFixed(
                            countDecimal
                        );
                        numberPropertiesList.push(factor.numberProperties);
                    });
                    if (multiplicationCurrency) {
                        if (currenciesMap[multiplicationCurrency?.iso]) {
                            currenciesMap[multiplicationCurrency?.iso].result += multiplicationResult;
                        } else {
                            currenciesMap[multiplicationCurrency?.iso] = {currency: multiplicationCurrency};
                            currenciesMap[multiplicationCurrency?.iso].result = multiplicationResult;
                        }
                    } else {
                        if (currenciesMap['no-currency']) {
                            currenciesMap['no-currency'].result += multiplicationResult;
                        } else {
                            currenciesMap['no-currency'] = {currency: null};
                            currenciesMap['no-currency'].result = multiplicationResult;
                        }
                    }
                }
            }
        });
        map[key].currencyMap = currenciesMap;

        const numberProperties = this.prepareOperationNumberPropertiesFromList(numberPropertiesList);
        this.currencyGroupsProcessingAfterArithmeticOperation(key, map[key].currencyMap, numberProperties, transalteFunction);
    }
    static proccessFactors(key, value, map, transalteFunction) {
        let multiplicationResult = 1;
        const numberPropertiesList = [];
        const currenciesMap = {};

        let multiplicationCurrency = this.findCommonCurrencyForFactorList(map, value.factorUuids);
        value.factorUuids.forEach((factorUUID) => {
            const factor = map[factorUUID];

            multiplicationResult *= isNaN(factor.numberValue) || factor.numberValue === null ? 0 : factor.numberValue;
            numberPropertiesList.push(factor.numberProperties);
        });

        if (multiplicationCurrency) {
            if (currenciesMap[multiplicationCurrency?.iso]) {
                currenciesMap[multiplicationCurrency?.iso].result *= multiplicationResult;
            } else {
                currenciesMap[multiplicationCurrency?.iso] = {
                    currency: multiplicationCurrency ? multiplicationCurrency : null,
                };
                currenciesMap[multiplicationCurrency?.iso].result = multiplicationResult;
            }
        } else {
            if (currenciesMap['no-currency']) {
                currenciesMap['no-currency'].result *= multiplicationResult;
            } else {
                currenciesMap['no-currency'] = {currency: multiplicationCurrency ? multiplicationCurrency : null};
                currenciesMap['no-currency'].result = multiplicationResult;
            }
        }
        map[key].currencyMap = currenciesMap;
        const numberProperties = this.prepareOperationNumberPropertiesFromList(numberPropertiesList);

        this.currencyGroupsProcessingAfterArithmeticOperation(key, map[key].currencyMap, numberProperties, transalteFunction);
    }
    static currencyGroupsProcessingAfterArithmeticOperation(operationUUID, currencyMap, numberProperties, transalteFunction) {
        let resultString = '';
        const currenciesOperationGroups = new Map(Object.entries(currencyMap));
        for (let [key, currencyGroup] of currenciesOperationGroups.entries()) {
            const lastGroup = key === Object.keys(currencyMap).pop();
            const currentOperationStr = StringUtils.formatInsertedNumberValue(
                currencyGroup.result,
                numberProperties,
                currencyGroup.currency,
                transalteFunction
            );
            resultString = resultString + `${currentOperationStr}${lastGroup ? '' : ' oraz '}`;
            HtmlParserUtils.innerHtmlById(resultString, operationUUID);
        }
    }

    static findCommonCurrencyForFactorList(valuesMap, factorUuidList) {
        const foundCurrency = factorUuidList?.filter((factorUuid) => {
            return valuesMap[factorUuid]?.answerCurrency;
        });
        return foundCurrency?.length > 0 ? valuesMap[foundCurrency[0]].answerCurrency : null;
    }
    static prepareOperationNumberPropertiesFromList(numberPropertiesList) {
        const numberProperties = {};
        numberProperties.showPercent = !!numberPropertiesList.find((n) => n.showPercent);
        numberProperties.showDescription = !!numberPropertiesList.find((n) => n.showDescription);
        numberProperties.showCurrency = !!numberPropertiesList.find((n) => n.showCurrency);
        numberProperties.convertToRoman = !!numberPropertiesList.find((n) => n.convertToRoman);
        const decimalPlaces = numberPropertiesList.map((p) => {
            return AgreementCalculationUtils.getDecimalPlaces(p);
        });
        numberProperties.decimalPlaces = Math.max(...decimalPlaces);
        return numberProperties;
    }
    static isBlank(value) {
        let areSpacesOnly = false;
        if (value !== undefined && value !== null && typeof value === 'string') {
            if (value.trim().length === 0) {
                areSpacesOnly = true;
            }
        }
        return typeof value === 'undefined' || value === null || value === '' || areSpacesOnly;
    }
    static getDecimalPlaces(numberProperties) {
        return !!numberProperties.showCurrency
            ? 2
            : !AgreementCalculationUtils.isBlank(numberProperties.decimalPlaces)
            ? numberProperties.decimalPlaces
            : 0;
    }
}
export default AgreementCalculationUtils;
