import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import ActionLink from '../../components/ActionLink';
import CriteriaCalendarRangeComponent from '../../components/criteria/CriteriaCalendarRangeComponent';
import {CriteriaDropdownComponent} from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import EnumService from '../../services/EnumService';
import ScheduledConsultationService from '../../services/ScheduledConsultationService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';

class ConsultationHistoryListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new ScheduledConsultationService());
        this.enumService = new EnumService();
        this.state = {
            list: [],
            loading: true,
            selectedList: [],
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }
    getDataKey() {
        return 'id';
    }

    getListTitle() {
        return 'consultationHistory.title';
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    onAfterGetList(data) {
        this.state.list.map((item) => {
            if (item.duration) {
                item.duration = moment.duration(item.duration / 1000, 'second').format('hh:mm:ss', {trim: false});
            }
            return item;
        });
        this.setState({list: this.state.list});
    }

    updateSearchCriteria(criteria) {
        return {
            paymentStatus: criteria.paymentStatus,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            client: criteria.client,
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }
    initializeFromBackend() {
        this.enumService
            .getEnumList('PaymentStatus', this.translate)
            .then((statusOptions) => {
                this.setState({
                    statusOptions,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
    }

    getCleanSearchCriteria() {
        return {
            status: undefined,
            firstResult: 0,
            paymentStatus: undefined,
            dateFrom: undefined,
            dateTo: undefined,
            client: undefined,
            maxResults: 10,
            sortField: 'id',
            sortAsc: false,
        };
    }

    prepareHeaderItems() {
        return [];
    }

    approveConsultations() {
        let {selectedList} = this.state;
        let ids = selectedList.filter((consultation) => consultation.status === 'REQUIRES_APPROVAL').map((consultation) => consultation.id);
        if (ids.length === 0) {
            this.showErrorMessage('Zaznaczone konsultacje zostały już zaakceptowane lub odrzucone');
        } else {
            this.service
                .acceptCheckedCosnultations(ids)
                .then(() => {
                    this.showSuccessMessage('Zaakceptowano wybrane konsulatacje');
                    this.refreshTable();
                })
                .catch(() => {
                    this.showErrorMessage('Nie udało się zatwierdzić wybranych konsultacji');
                });
        }
    }

    getCriteriaName() {
        return 'consultation-history-list-sc';
    }

    getContainerListName() {
        return 'consultation-history-list';
    }
    renderCriteria() {
        return (
            <div className='row'>
                {!this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE') && (
                    <CriteriaTextComponent
                        id='client-sc'
                        name='client'
                        label={'Klient'}
                        colClass='col-xl-3 col-lg-4 col-sm-6'
                        placeholder={'Klient'}
                        value={this.state.criteria.client}
                        onChange={this.handleChangeSc}
                        validator={this.validator}
                    />
                )}
                <CriteriaCalendarRangeComponent
                    id='date-sc'
                    idFrom='dateFrom-sc'
                    idTo='dateTo-sc'
                    name='startDate'
                    nameFrom='dateFrom'
                    nameTo='dateTo'
                    label={'Data przeprowadzenia konsultacji'}
                    colClass='col-xl-6 col-lg-6 col-sm-6'
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    refreshFromBackend
                    showButtonBar
                    sharedLabel
                    renderDash
                    maxDateInfinite
                    placeholderFrom={'Data od'}
                    placeholderTo={'Data do'}
                    inputsClassName='col-6'
                />
                <CriteriaDropdownComponent
                    id='paymentStatus-sc'
                    name='paymentStatus'
                    showClear
                    label={'Status'}
                    colClass='col-xl-3 col-lg-4 col-sm-6'
                    value={this.state.criteria.paymentStatus}
                    options={this.state.statusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
            </div>
        );
    }

    prepareColumns() {
        let columns = [];
        if (
            this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_LAW_OFFICE_CONSULTANT_MANAGE', 'ROLE_ADMIN_CONSULTATION_VIEW')
        ) {
            columns.push({field: 'user.fullName', header: 'Klient'}, {field: 'consultation.user.fullName', header: 'Nazwa kancelarii'});
        }

        columns.push(
            {
                field: 'startDate',
                header: 'Data przeprowadzenia konsultacji',
                sortable: true,
                body: this.dateTemplate.bind(this, 'startDate', 'DD-MM-YYYY HH:mm:ss'),
            },
            {field: 'duration', header: 'Czas trwania konsultacji'}
        );
        columns.push(
            {field: 'paymentStatus', header: 'Status', body: this.enumTemplate.bind(this, 'PaymentStatus', 'paymentStatus')},
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            }
        );
        return columns;
    }
    enumTemplate(enumType, field, rowData) {
        if (this.isLexyPortal() || enumType !== 'PaymentStatus') {
            if (rowData[field]) {
                return this.translate(`${enumType}.${rowData[field]}`);
            }
            return '';
        }
        let imageSrc = AppPrefixUtils.locationHrefUrl(`/images/lexdocs/`);

        switch (rowData[field]) {
            case 'PAID':
                return <img src={imageSrc + 'done_blue.svg'} alt='' title='Opłacony z abonamentu' />;
            case 'UNPAID':
                return <img src={imageSrc + 'close.svg'} alt='' title='Nieopłacony' />;
            default:
                return <img src={imageSrc + 'done_green.svg'} alt='' title='Opłacony' />;
        }
    }
    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        if (this.isLexyPortal()) {
            return (
                <div>
                    <ActionLink
                        rendered={this.backLinkRendered}
                        className='p-link'
                        handleClick={this.handleGoToDetail.bind(this, href)}
                        variant='blue'
                        iconName='mdi-eye'
                        iconSide='left'
                        iconSize='xs'
                        iconColor='blue'
                    />
                </div>
            );
        } else {
            return (
                <div className='float-center'>
                    <img
                        src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                        onClick={this.handleGoToDetail.bind(this, href)}
                        alt=''
                        className='cursor-pointer '
                    />
                </div>
            );
        }
    }
    breadCrumbItems() {
        return this.isLexyPortal() ? [] : [{name: 'Historia konsultacji', url: '/#/consultation-history-list'}];
    }
    getUpdateSucces() {
        return 'Konsultacja została zatwierdzona';
    }

    handleOpenDialog(rowData, e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, elementId: rowData.id, dialogViewMode: 'EDIT'});
    }
    handleOpenNewDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, dialogViewMode: 'NEW'});
    }
    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: false, elementId: undefined}, () => {
            if (callback) callback();
        });
    }

    render() {
        return (
            <div className='container-fluid'>
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                {this.renderView()}
            </div>
        );
    }
}

ConsultationHistoryListContainer.defaultProps = {
    detailUrl: '/#/consultation-history/details',
    lazy: true,
};

ConsultationHistoryListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(ConsultationHistoryListContainer);
