import {t} from 'i18next';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import InputTextareaComponent from '../components/inputs/InputTextareaComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import ContactService from '../services/ContactService';

class ContactContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new ContactService());

        this.isCustomHeaderItems = !this.isLexyPortal();
        this.state = {
            elementId: props.elementId,
            element: {
                person: '',
                subject: '',
                email: '',
                content: '',
            },
        };
    }
    initAfterSetElement() {
        this.setState({flag: true}, () => super.initAfterSetElement());
    }
    getAddSucces() {
        return t('contact.sendSuccess');
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    render() {
        return this.renderView();
    }
    breadCrumbItems() {
        return [{name: 'Kontakt'}];
    }
    afterAddSuccess(response) {
        this.showSuccessMessage('Wiadomość została wysłana');
        this.setState({
            element: {
                person: '',
                subject: '',
                email: '',
                content: '',
            },
        });
    }

    prepareFooterItems() {
        return [
            {
                label: 'Wyślij',
                className: 'float-right',
                onClick: this.handleFormSubmit,
            },
        ];
    }

    renderDetails() {
        const {t} = this.props;
        return (
            <React.Fragment>
                {this.renderProperStructureForDetail(
                    <>
                        <div className='row'>
                            <InputTextComponent
                                id='person'
                                name='person'
                                label={t('contact.person')}
                                colClass='col-lg-6 col-sm-12'
                                value={this.state.element.person}
                                viewMode={'NEW'}
                                validator={this.validator}
                                validators='required'
                                onChange={this.handleChange}
                            />
                            <InputTextComponent
                                id='email'
                                name='email'
                                label={t('contact.email')}
                                colClass='col-lg-6 col-sm-12'
                                value={this.state.element.email}
                                validator={this.validator}
                                validators='required|email'
                                onChange={this.handleChange}
                                viewMode={'NEW'}
                            />{' '}
                        </div>

                        <div className='row'>
                            <InputTextComponent
                                id='subject'
                                name='subject'
                                label={t('contact.subject')}
                                colClass='col-12'
                                value={this.state.element.subject}
                                validator={this.validator}
                                validators='required|max:100'
                                onChange={this.handleChange}
                                viewMode={'NEW'}
                            />
                            <InputTextareaComponent
                                id='content'
                                name='content'
                                label={t('contact.content')}
                                colClass='col-md-12'
                                showLabel={true}
                                value={this.state.element.content}
                                validators='required|max:2000'
                                onChange={this.handleChange}
                                validator={this.validator}
                                viewMode={'NEW'}
                                rows={5}
                            />
                        </div>
                        <div className='row'>
                            <div className='col-md col-sm-12'>
                                <div className='row'>
                                    <p className='col-12 form-group contact-details p-label'>
                                        Kancelaria Radcy Prawnego <br />
                                        Katarzyna Podyma <br />
                                        Plac wolności 3 lok 3A
                                        <br />
                                        40-078 Katowice
                                        <br />
                                        NIP 629-166-70-24
                                        <br />
                                        tel:(32) 400-89-91
                                        <br />
                                        tel. kom: 572-940-078
                                        <br />
                                        sekretariat@katarzynapodyma.com
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>,
                    false
                )}
            </React.Fragment>
        );
    }
}
ContactContainer.defaultProps = {
    backUrl: '/#/contact',
};
ContactContainer.propTypes = {};
export default withTranslation()(ContactContainer);
