import {TabPanel, TabView} from 'primereact/tabview';
import React from 'react';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import ProfileContainer from './ProfileContainer';
import UserPackageContainer from '../manage/UserPackageContainer';
import AuthService from '../../../services/AuthService';
import {CustomMessages} from '../../../components/CustomMessages';
import BaseDetailsContainer from '../../../baseContainers/BaseDetailsContainer';
import BaseBreadCrumb from '../../../baseContainers/BaseBreadCrumb';
import AppPrefixUtils from '../../../utils/AppPrefixUtils';

class ProfileNavContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.authService = new AuthService();
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    handleTabChange(e) {
        window.location.href = AppPrefixUtils.locationHrefUrl('/#/manage-account/details/' + this.props.elementId);
        this.setState(
            {
                activeIndex: e.index,
            },
            () => {
                this.saveCookie(`manage-draft-list-activeIndex`, this.state.activeIndex);
            }
        );
    }

    componentDidMount() {
        let activeIndex = this.readCookie(`manage-draft-list-activeIndex`);
        if (
            activeIndex === undefined ||
            activeIndex === null ||
            activeIndex === '' ||
            !this.authService.isUserInRole('ROLE_USER_LEX_DOCS_MANAGE')
        ) {
            activeIndex = 0;
            this.saveCookie(`manage-draft-list-activeIndex`, 0);
        }
        this.setState({
            activeIndex: parseInt(activeIndex),
        });
    }

    getContainerListName() {
        return 'manage-accounts';
    }
    breadCrumbItems() {
        return null;
    }
    render() {
        return (
            <React.Fragment>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                <BaseBreadCrumb items={this.breadCrumbItems()} />

                {this.authService.isUserInRole('ROLE_USER_LEX_DOCS_MANAGE') ? (
                    <React.Fragment>
                        <TabView activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange}>
                            <TabPanel header={'Moje konto'} key={`manage-account-${this.state.activeIndex}`}>
                                <ProfileContainer
                                    params={this.props.params}
                                    currentUser={this.props.currentUser}
                                    elementId={this.props.elementId}
                                    mainRef={this.props.mainRef}
                                    user={this.props.user}
                                    logoutFunc={this.props.logoutFunc}
                                    viewMode={this.props.viewMode}
                                    history={this.props.history}
                                    embedded
                                />
                            </TabPanel>

                            <TabPanel header={'Informacje o pakiecie'} key={`package-list-${this.state.activeIndex}`}>
                                <UserPackageContainer
                                    params={this.props.params}
                                    currentUser={this.props.currentUser}
                                    elementId={this.props.elementId}
                                    mainRef={this.props.mainRef}
                                    user={this.props.user}
                                    viewMode={this.props.viewMode}
                                    history={this.props.history}
                                    backUrl={this.props.backUrl}
                                    embedded
                                />
                            </TabPanel>
                        </TabView>
                    </React.Fragment>
                ) : (
                    <>
                        <TabView activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange}>
                            <TabPanel header={'Moje konto'} key={`manage-account-${this.state.activeIndex}`}>
                                <ProfileContainer
                                    params={this.props.params}
                                    currentUser={this.props.currentUser}
                                    elementId={this.props.elementId}
                                    mainRef={this.props.mainRef}
                                    user={this.props.user}
                                    viewMode={this.props.viewMode}
                                    history={this.props.history}
                                    logoutFunc={this.props.logoutFunc}
                                    embedded
                                />
                            </TabPanel>
                        </TabView>
                    </>
                )}
            </React.Fragment>
        );
    }
}

ProfileNavContainer.defaultProps = {
    cancelUrl: '/#/manage-account/details',
    detailUrl: '/#/manage-account/details',
    editUrl: '/#/manage-account/edit',
    backUrl: '/#/manage-account/details',
};

ProfileNavContainer.propTypes = {
    cancelUrl: PropTypes.string,
    detailUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withTranslation()(ProfileNavContainer);
