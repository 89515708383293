import {getAllISOCodes} from 'iso-country-currency';
import moment from 'moment';
import {Card} from 'primereact/card';
import PrimiDialog from '../components/PrimiDialog';
import qs from 'qs';
import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {v4 as uuidv4} from 'uuid';
import BaseBreadCrumb from '../baseContainers/BaseBreadCrumb';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import ActionButton from '../components/ActionButton';
import {StickyContainer, Sticky} from 'react-sticky';

import ActionLink from '../components/ActionLink';
import {CustomMessages} from '../components/CustomMessages';
import DivContainer from '../components/DivContainer';
import InputCalendarComponent from '../components/inputs/InputCalendarComponent';
import InputCheckboxComponent from '../components/inputs/InputCheckboxComponent';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputNumberComponent from '../components/inputs/InputNumberComponent';
import InputRadioButtonComponent from '../components/inputs/InputRadioButtonComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import InputTreeSelectComponent from '../components/inputs/InputTreeSelectComponent';
import MenuItemIcon from '../components/MenuItemIcon';
import PDFPreviewComponent from '../components/PDFPreviewComponent';
import Stepper from '../components/Stepper';
import BlockUi from '../components/waitPanel/BlockUi';
import YesNoDialog from '../components/YesNoDialog';
import AgreementService from '../services/AgreementService';
import AgreementPublicService from '../services/public/AgreementPublicService';
import ScheduledConsultationPublicService from '../services/public/ScheduledConsultationService';
import ScheduledConsultationService from '../services/ScheduledConsultationService';
import SettingService from '../services/SettingService';
import UserService from '../services/UserService';
import AgreementCalculationUtils from '../utils/AgreementCalculationUtils';
import AgreementUtils from '../utils/AgreementUtils';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import CurrencyUtils from '../utils/CurrencyUtils';
import HtmlParserUtils from '../utils/htmlParser/HtmlParserUtils';
import SessionUtils from '../utils/SessionUtils';
import StorageUtils from '../utils/StorageUtils';
import StringUtils from '../utils/StringUtils';
import SunEditorParentWidthCounter from '../utils/SunEditorParentWidthCounter';
import TooltipUtils from '../utils/TooltipUtils';
import TooltipAgreementUtils from '../utils/TooltipAgreementUtils';
import LegalConsultationDialog from './templates/LegalConsultationDialog';
import MailingDialog from './templates/MailingDialog';
import InputTextareaComponent from '../components/inputs/InputTextareaComponent';
import DisableCopyUtilsAgreement from '../utils/DisableCopyUtilsAgreement';
import {UAParser} from 'ua-parser-js';
import DialogResizer from '../components/DialogResizer';
import PreCreateStep from '../components/PreCreateStep';
import {InputText} from 'primereact/inputtext';
import {isSidebarOpen} from '../AppContext';
window.legalConsultationDialogRef = undefined;
window.tabViewLegalRef = undefined;
window.editorFromAgreement = undefined;

const inputIdToCompareText = 'hidden-input-text';
const inputIdToCompareDictionary = 'hidden-input-distionary';
const inputIdToCompareNumber = 'hidden-input-number';

export let refreshAgreementBase;

class AgreementBaseContainer extends BaseDetailsContainer {
    constructor(props, service) {
        super(props, service);
        this.settingService = new SettingService();
        this.editor = React.createRef();
        this.allSteps = React.createRef();
        this.legalConsultationDialogRef = React.createRef();
        this.tabViewLegalRef = React.createRef();
        this.editor = React.createRef();
        this.collapseFlag = false;
        this.expanderRef = React.createRef();
        const {device} = UAParser(navigator.userAgent);
        this.device = device;
        this.state = {
            previewVisible: false,
            showBtnEye: false,
            clients: [],
            unregisteredClient: {
                email: undefined,
                firstName: undefined,
                lastName: undefined,
            },
            visibleChooseClientDialog: false,
            templatePinnedToConsultation: false,
            oldValuesMap: new Map(),
            visibleStepper: true,
            visibleUnregisterdClientDialog: false,
            contractorEmail: undefined,
            visibleSendToContractorDialog: false,
            elementId: props.elementId,
            tags: [],
            element: {children: [], valuesMap: new Map()},
            dialogVisibile: false,
            pdfBase64: undefined,
            consultationDialogVisibile: false,
            currencies: [],
            paymentInfo: {paymentRequired: true},
            activeStepUUID: null,
            versionDialogVisible: false,
            agreementReportBase64: null,
            selectedStep: {stepIndex: 0},
            loadingFlag: true,
            availableWidth: SunEditorParentWidthCounter.countWidth(this.sidebarVisible(), this.cardMargins(), this.device),
        };
        this.scheduledConsultationService = this.authService.loggedIn()
            ? new ScheduledConsultationService()
            : new ScheduledConsultationPublicService();
        this.userService = new UserService();

        this.isContractor = AgreementUtils.isContractor(this.authService.loggedIn(), this.props.viewMode);
        this.renderStep = this.renderStep.bind(this);
        this.updateHtmlContent = this.updateHtmlContent.bind(this);
        this.showConfirmationBlankValue = this.showConfirmationBlankValue.bind(this);
        this.hideConfirmationBlankValue = this.hideConfirmationBlankValue.bind(this);
        this.selectHierarchicalDictionary = this.selectHierarchicalDictionary.bind(this);
        this.getSunEditorInstance = this.getSunEditorInstance.bind(this);
        this.getAllStepsEditorInstance = this.getAllStepsEditorInstance.bind(this);
        this.validateBeforeStepChange = this.validateBeforeStepChange.bind(this);
        this.initializedStepIndex = this.initializedStepIndex.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.sendBack = this.sendBack.bind(this);
        this.validationForDate = this.validationForDate.bind(this);
        this.updateActiveStepUUID = this.updateActiveStepUUID.bind(this);
        this.openVersionDialog = this.openVersionDialog.bind(this);
        this.closeVersionDialog = this.closeVersionDialog.bind(this);
        this.saveBeforeLastStep = this.saveBeforeLastStep.bind(this);
        this.saveBeforeConsultationStart = this.saveBeforeConsultationStart.bind(this);
        this.openConsultationDialog = this.openConsultationDialog.bind(this);
        this.closeConsultationDialog = this.closeConsultationDialog.bind(this);
        this.actionBeforePunultimateStep = this.actionBeforePunultimateStep.bind(this);
        this.showVersionDialogIfRequired = this.showVersionDialogIfRequired.bind(this);
        this.closeSendToContractorDialog = this.closeSendToContractorDialog.bind(this);
        this.openSendToContractorDialog = this.openSendToContractorDialog.bind(this);
        this.closeUnregisteredClientDialog = this.closeUnregisteredClientDialog.bind(this);
        this.openUnregisteredClientDialog = this.openUnregisteredClientDialog.bind(this);
        this.recalculatePointers = this.recalculatePointers.bind(this);
        this.iterateOverValuesMap = this.iterateOverValuesMap.bind(this);
        this.validator = this.authService.isUserInAnyRole('ROLE_LAW_OFFICE_CONSULTANT_MANAGE', 'ROLE_LAW_OFFICE_EDITOR_MANAGE')
            ? this.validatorDraft
            : this.validator;
        this.clearSelectionDictionary = this.clearSelectionDictionary.bind(this);
        this.contractorButtonRendered = this.contractorButtonRendered.bind(this);
        this.generateDocumentButtonRendered = this.generateDocumentButtonRendered.bind(this);
        this.showOfficeConsultantDialog = this.showOfficeConsultantDialog.bind(this);
        this.renderDraftNameInput = this.renderDraftNameInput.bind(this);
        this.renderGenerateButton = this.renderGenerateButton.bind(this);
        this.renderExpandPreviewIcon = this.renderExpandPreviewIcon.bind(this);
        this.getBlurredClassName = this.getBlurredClassName.bind(this);
        this.handleChangePrevalidation = this.handleChangePrevalidation.bind(this);
    }
    getAddSucces() {
        return 'Dokument został utworzony';
    }

    getUpdateSucces() {
        return 'Dokument został zaktualizowany';
    }

    getRemoveSucces() {
        return 'Dokument został usunięta';
    }
    sidebarVisible() {
        return !this.isLexyPortal() && window.outerWidth > 992;
    }
    cardLexyMargins() {
        return this.device?.type && this.device.type === 'mobile' ? -20 : window.innerWidth < 1024 ? 20 : 30;
    }
    cardLexdocsMargins(additionalMargin) {
        return this.device?.type && this.device.type === 'mobile' ? -20 : 20 + additionalMargin;
    }
    cardMargins(additionalMargin) {
        return this.isLexyPortal() ? this.cardLexyMargins() : this.cardLexdocsMargins(additionalMargin);
    }

    componentWillUnmount() {
        window.editorFromAgreement = null;
        super.componentWillUnmount();

        this.unRegisterResizeWidthEvent();
    }
    unRegisterResizeWidthEvent() {
        window.removeEventListener('resize', this.handleResizeComponentWidth);
    }
    componentDidMount() {
        window.editorFromAgreement = this.editor;
        refreshAgreementBase = () => {
            setTimeout(() => {
                this.handleResizeDictionary();
                this.handleResizeComponentWidth();
                this.forceUpdate();
            }, 300);
        };
        this.registerResizeWidthEvent();
        DisableCopyUtilsAgreement.disableCopyElements('agreement-content');
        TooltipUtils.registerTooltipFunction('help-tooltip');
        TooltipAgreementUtils.registerTooltipFunction('data-pr-tooltip');
        TooltipAgreementUtils.registerTooltipClassnameFunction(
            'blurred-tag',
            'Treść dokumentu będzie widoczna dla użytkownika po opłaceniu dokumentu'
        );
        TooltipAgreementUtils.registerTooltipClassnameFunction(
            'blurred-icon',
            'Treść dokumentu będzie widoczna dla użytkownika po opłaceniu dokumentu'
        );
        SunEditorParentWidthCounter.countWidthWhenResize(
            (calculatedWidth) => {
                if (calculatedWidth !== this.state.availableWidth) {
                    this.setState({
                        availableWidth: calculatedWidth,
                    });
                }
            },
            this.sidebarVisible(),
            this.cardMargins(),
            this.device
        );
        super.componentDidMount();

        this.orderPaidFor();
    }

    breadCrumbItems() {
        const {templatesUrl, publicTemplatesUrl} = this.props;
        return [{name: 'Szablony', url: this.authService.loggedIn() ? templatesUrl : publicTemplatesUrl}, {name: 'Kreator dokumentu'}];
    }
    getService() {
        return this.authService.loggedIn() ? new AgreementService() : new AgreementPublicService();
    }
    getOrderPaidForParam() {
        const urlStr = window.location.toString().replace('/#', '');
        const url = new URL(urlStr);
        return url.searchParams.get('orderPaidFor');
    }
    orderPaidFor() {
        const orderPaidFor = this.getOrderPaidForParam();

        if (orderPaidFor) {
            this.scheduledConsultationService.getByOrderUuid(orderPaidFor).then((sc) => {
                if (sc.paymentStatus === 'PAID') {
                    this.setState({
                        specializationId: sc.specialization.id,
                        scheduledConsultationId: sc.id,
                        visibleMailingDialog: true,
                        consultants: sc.consultants,
                    });
                }
            });
        }
    }
    setElement() {
        if (this.isContractor) {
            const {viewMode} = this.props;
            const {elementId} = this.state;

            if (elementId && (viewMode === 'EDIT' || viewMode === 'VIEW')) {
                this.blockUi();
                this.service
                    .getByUuidForContractor(elementId, viewMode)
                    .then((data) => {
                        this.setState({loading: false}, () => this.updateElement(data));
                    })
                    .catch((err) => {
                        this.handleGetDetailsError(err);
                    });
            } else {
                this.setState({loading: false}, this.initAfterSetElement());
            }
        } else {
            super.setElement();
        }
    }
    initBeforeSetElement() {
        if (this.props.viewMode === 'PRE_CREATE') {
            this.service.generateEmptyTemplate(this.props.elementId).then((res) => {
                this.setState({
                    pdfBase64: res.value,
                });
            });
        }

        window.legalConsultationDialogRef = this.legalConsultationDialogRef;
        window.tabViewLegalRef = this.tabViewLegalRef;

        if (this.props.viewMode === 'NEW' || this.props.viewMode === 'PRE_CREATE') {
            this.service
                .getTemplateTags(this.props.elementId)
                .then((element) => {
                    const steps = element.children;
                    steps.unshift({renderStep: () => this.renderFirstStep(), stepIndex: 0, label: 'Wprowadzenie', hideLabel: true});
                    steps.push({renderStep: () => this.renderLastStep(), stepIndex: steps.length, label: 'Podsumowanie', hideLabel: true});
                    element.children = steps;
                    this.setState({
                        element,
                    });
                })
                .catch((ex) => {
                    this.showErrorMessage(ex.message);
                });
        } else {
            this.scheduledConsultationService
                .isTemplatePinnedToConsultation(this.props.elementId)
                .then((templatePinnedToConsultation) => {
                    this.setState({templatePinnedToConsultation});
                })
                .catch(() => {
                    this.showErrorMessage('Sprawdzenia podpięcia umowy do konsultacji');
                });
        }

        let list = [...getAllISOCodes()].sort((first, second) => (first.currency > second.currency ? 1 : -1));
        function getUniqueListBy(arr, key) {
            return [...new Map(arr.map((item) => [item[key], item])).values()];
        }
        list = getUniqueListBy(list, 'currency');
        this.setState({
            currencies: list,
        });
        if (this.props.viewMode === 'NEW') {
            this.initializedStepIndex();
        }
    }

    isAfterPaymentState() {
        return qs.parse(this.props.history.location.search, {ignoreQueryPrefix: true}).paid;
    }

    initAfterSetElement() {
        let paymentInfoRequest = {};
        if (this.state.element?.id) {
            paymentInfoRequest.templateId = this.state.element?.template?.id;
            paymentInfoRequest.agreementId = this.state.element?.id;
        } else {
            paymentInfoRequest.templateId = this.props.elementId;
        }
        this.service
            .isPaymentForTemplateRequired(paymentInfoRequest)
            .then((result) => {
                if (this.isAfterPaymentState()) {
                    if (!result?.paymentRequired) {
                        this.showSuccessMessage('Dokument został opłacony');
                    } else {
                        this.showErrorMessage('Dokument nie został opłacony');
                    }
                }

                this.setState({paymentInfo: result}, () => this.unblockUi(this.showVersionDialogIfRequired));
            })
            .catch((err) => {
                this.unblockUi(this.showVersionDialogIfRequired);
            });
        if (this.isContractor) {
            this.setState(() => ({
                oldValuesMap: structuredClone(this.state.element.valuesMap),
            }));
        }
        this.setState({
            visibleStepper: AgreementUtils.stepperVisibility(this.authService.loggedIn(), this.isContractor, this.state.element.status),
        });

        this.showOfficeConsultantDialog();
        this.initializedStepIndex();
    }
    showOfficeConsultantDialog() {
        if (this.authService.isUserInAnyRole('ROLE_LAW_OFFICE_CONSULTANT_MANAGE') && this.props.viewMode === 'NEW') {
            this.userService
                .getByBasicPersonType('CLIENT')
                .then((clients) => {
                    this.setState({
                        clients: clients,
                        visibleChooseClientDialog: true,
                    });
                })
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy użytkowników');
                });
        }
    }
    showVersionDialogIfRequired() {
        if (this.state.element.showVersionAlert === true && !this.isContractor) {
            this.openVersionDialog();
        }
    }

    LexDocSFirstStep() {
        const {status} = this.state.element;
        return (
            <div className='template-fill-start border-black'>
                <span className='title-name'>{this.state.element.template.name}</span>
                <span className='silver-light '></span>
                <div className='row'>
                    <div className='description-text-fill col-12'>{this.state.element.template.description}</div>
                </div>
                <div className='row'>
                    <InputTextComponent
                        id={`contractorEmailSummary`}
                        name={`contractorEmailSummary`}
                        label={`Wysłano do kontrahenta`}
                        colClass='col-md-12'
                        value={this.state.element.contractorEmail}
                        validator={this.validator}
                        viewMode={'VIEW'}
                        validators='not_required'
                        onChange={() => {}}
                        rendered={status === 'SENT'}
                    />
                </div>
                <div className='row'>
                    <div className='silver-light col-12'>{this.renderPaymentInfo()}</div>
                </div>
            </div>
        );
    }

    LexyFirstStep() {
        const {status} = this.state.element;
        return (
            <>
                <div className=''>
                    <Card className={'card-silver-color'} header={() => <h4 className='title'>{this.state.element.template.name}</h4>}>
                        <div className='row'>
                            <div className='description-text col-12'>{this.state.element.template.description}</div>
                        </div>
                        <div className='row'>
                            <InputTextComponent
                                id={`contractorEmailSummary`}
                                name={`contractorEmailSummary`}
                                label={`Wysłano do kontrahenta`}
                                colClass='col-md-12'
                                value={this.state.element.contractorEmail}
                                validator={this.validator}
                                viewMode={'VIEW'}
                                validators='not_required'
                                onChange={() => {}}
                                rendered={status === 'SENT'}
                            />
                        </div>
                        <div className='row'>
                            <div className='description-text col-12'>{this.renderPaymentInfo()}</div>
                        </div>
                    </Card>
                </div>
            </>
        );
    }

    renderFirstStep() {
        if (this.isLexyPortal()) {
            return <div>{this.LexyFirstStep()}</div>;
        } else {
            return <div>{this.LexDocSFirstStep()}</div>;
        }
    }
    isViewEnabled() {
        const mobileStepper = document.getElementById('mobile-stepper');
        let isViewEnabled = true;
        if (mobileStepper) {
            isViewEnabled = !mobileStepper.innerText.includes('1 /');
        }
        return isViewEnabled;
    }
    renderDraftNameInput() {
        return (
            <div className='row draft-name-header '>
                <div
                    className={`${
                        this.isLexyPortal() ? 'col-xl-8 col-lg-6 col-md-5 col-sm-6 col-5' : 'col-xl-8 col-lg-6 col-md-6 col-sm-6 col-6'
                    }`}
                >
                    <InputTextComponent
                        id={`draftName`}
                        name={`draftName`}
                        placeholder={'Wpisz nazwę...'}
                        colClass='col-12'
                        value={this.state.element.draftName}
                        validator={this.validator}
                        viewMode={'EDIT'}
                        validators='not_required|max:255'
                        onChange={this.handleChange}
                    />
                </div>
                <div
                    className={`${
                        this.isLexyPortal() ? 'col-xl-4 col-lg-6 col-md-6 col-sm-6 col-7' : 'col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6'
                    } `}
                >
                    <div id='agreement-template-expander'>
                        <div className='row no-gutters'>
                            {this.isLexyPortal() && (
                                <div className={this.isViewEnabled() ? 'col-10' : 'col-12'}>
                                    <ActionButton
                                        rendered={
                                            this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE', 'ROLE_USER_LEKSY_MANAGE') ||
                                            (!this.authService.loggedIn() && this.props.viewMode !== 'VIEW')
                                        }
                                        className={`${
                                            this.isViewEnabled()
                                                ? 'header-item-consultation-next-side'
                                                : 'header-item-consultation-first-side'
                                        }  p-button-text-only`}
                                        label='Konsultacja prawna'
                                        handleClick={this.saveBeforeConsultationStart}
                                    />
                                </div>
                            )}

                            {this.isViewEnabled() && (
                                <div className='col-2'>
                                    <button
                                        id='expander-icon-expand'
                                        className='unstyled-btn'
                                        style={{marginTop: '6px'}}
                                        onClick={(e) => {
                                            this.blockUi();
                                            e.preventDefault();
                                            this.expanderRef.click();

                                            this.setState(
                                                {
                                                    showBtnEye: !this.state.showBtnEye,
                                                },
                                                () => {
                                                    setTimeout(() => {
                                                        this.unblockUi();
                                                    }, 200);
                                                }
                                            );
                                        }}
                                    >
                                        <div style={{color: 'white', fontSize: '25px'}}>
                                            {this.isLexyPortal()
                                                ? this.viewIcon()
                                                : this.state.showBtnEye
                                                ? this.viewTemplateLabel('preview_page.svg')
                                                : this.viewTemplateLabel('preview_page.svg')}
                                            {this.state.showBtnEye}
                                        </div>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    viewTemplateLabel(imgName) {
        const link = AppPrefixUtils.locationHrefUrl('/images/lexdocs/' + imgName);

        return (
            <div>
                <span id='view-template-label'>Podgląd</span> <img src={link} alt='' />
            </div>
        );
    }
    viewIcon() {
        return <div>{this.state.showBtnEye ? <i class='mdi mdi-eye-off-outline '></i> : <i class='mdi mdi-eye-outline'></i>}</div>;
    }
    contractorButtonRendered() {
        const {status} = this.state.element;

        return status !== 'GENERATED' && !this.isContractor && this.authService.loggedIn();
    }
    generateDocumentButtonRendered() {
        const {status} = this.state.element;
        return status !== 'GENERATED' && status !== 'SENT' && !this.isContractor;
    }
    renderLastStep() {
        return (
            <React.Fragment>
                <DivContainer colClass='row parent-with-centered-item'>
                    {this.state.agreementReportBase64 ? (
                        <React.Fragment>
                            {!this.state?.paymentInfo?.paymentRequired ? (
                                <div id='pdf-preview-component' className='pdf-preview-component'>
                                    <PDFPreviewComponent base64={this.state.agreementReportBase64} />
                                </div>
                            ) : (
                                <DivContainer>Dokument został przygotowany</DivContainer>
                            )}
                        </React.Fragment>
                    ) : null}
                </DivContainer>
                <div className='p-card-footer'>
                    <div className='stepper-footer footer-dialog-button-container text-end'>
                        {!this.state?.paymentInfo?.paymentRequired ||
                        this.authService.isUserInAnyRole('ROLE_LAW_OFFICE_CONSULTANT_MANAGE', 'ROLE_LAW_OFFICE_EDITOR_MANAGE') ? (
                            <React.Fragment>
                                {this.isLexyPortal() ? (
                                    <ActionButton
                                        label={'Wyślij do kontrahenta'}
                                        className={'header-item '}
                                        // disabled={status === 'SENT'}
                                        rendered={this.contractorButtonRendered()}
                                        handleClick={this.openSendToContractorDialog.bind(this)}
                                        key={'send-agreement'}
                                    />
                                ) : (
                                    <div>
                                        {this.contractorButtonRendered() && (
                                            <button
                                                className='lexdocs-classic-btn-outlined'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    this.openSendToContractorDialog();
                                                }}
                                            >
                                                <b>Wyślij do kontrahenta</b>
                                                {
                                                    <img
                                                        src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/send_consultation_blue.svg`)}
                                                        alt=''
                                                    />
                                                }
                                            </button>
                                        )}
                                    </div>
                                )}
                                {this.isLexyPortal() ? (
                                    <ActionButton
                                        label='Wygeneruj dokument'
                                        className={'header-item download-header-link '}
                                        handleClick={this.downloadAgreement.bind(this)}
                                        iconSvg='ico_download_link_icon_black'
                                        iconSide='left'
                                        rendered={this.generateDocumentButtonRendered()}
                                        iconSize='mini'
                                        key={'print-agreement'}
                                    />
                                ) : null}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className='left-footer-dialog-button-container'>
                                    <label className='price-summary'>
                                        Cena za dokument: {CurrencyUtils.currency(this.state.element.price)}
                                    </label>
                                </div>

                                <ActionButton
                                    label={'Zapłać'}
                                    className={'header-item btn-pay-for-template'}
                                    handleClick={
                                        !this.authService.loggedIn()
                                            ? this.openUnregisteredClientDialog.bind(this)
                                            : this.startPaymentForAgreement.bind(this)
                                    }
                                    // iconSvg='ico_download_link_icon_white'
                                    iconSide='left'
                                    iconSize='mini'
                                    key={'print-agreement'}
                                />
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
    renderGenerateButton() {
        return (
            <div>
                {this.generateDocumentButtonRendered() && (
                    <button className=' lexdocs-classic-btn-outlined-transparent' onClick={this.downloadAgreement.bind(this)}>
                        {this.device.type !== 'mobile' && 'Wygeneruj dokument'}
                        {<img src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/download.svg`)} alt='' />}
                    </button>
                )}
            </div>
        );
    }
    startPaymentForAgreement() {
        this.service
            .startPaymentForAgreement(this.state.element.id, this.state.unregisteredClient)
            .then((res) => {
                window.location.href = res.url + res.token;
            })
            .catch((err) => {
                this.showErrorMessage(err.message, 10000, true, 'Błąd ');
            });
    }
    renderPaymentInfo() {
        switch (this.state?.paymentInfo?.paymentStatus) {
            case 'PAID_BY_SUBSCRIPTION':
                return 'Dokument jest dostępny w ramach abonamentu';
            case 'UNPAID':
                return 'Dokument udostępniony po dokonaniu opłaty';
            case 'PAID':
                return 'Dokument jest opłacony';
            default:
                return '';
        }
    }

    handleChangePrevalidation(inputType, parameters, event, onAfterStateChange, stateField, id, validationLength) {
        const varName = event.target.name;
        let varValue = event.target.value || event.target.value === '' ? event.target.value : undefined;
        if (varValue?.length >= validationLength) {
            this.validator.showMessageFor(id);
        }
        this.handleChangeSetState(varName, varValue, onAfterStateChange, stateField, parameters ? parameters[0] : undefined);
    }
    downloadAgreement(e) {
        if (e) {
            e.preventDefault();
        }
        this.blockUi();
        this.service
            .generatePdf(this.state.element.id)
            .then((response) => {
                const fileName = `${this.state.element?.template?.name}.pdf`;

                this.setState(
                    (prevState) => ({
                        element: {
                            ...prevState.element,
                            status: 'GENERATED',
                        },
                    }),
                    () => {
                        StorageUtils.downloadBase64(response.base64, fileName);
                        this.afterGenerateDocument();
                    }
                );
            })
            .catch((err) => {
                this.persistMessage('error', 'Błąd', err.message);
                this.saveCookie(`agreement-draft-list-activeIndex`, this.props.viewMode === 'EDIT' ? 1 : 0);
                window.location.href = AppPrefixUtils.locationHrefUrl(this.props.templatesUrl);
            });
    }
    afterGenerateDocument() {
        window.location.href = !!this.authService.loggedIn()
            ? !!this.state.generatedDocumentAvailable
                ? AppPrefixUtils.locationHrefUrl(this.props.backUrl)
                : AppPrefixUtils.locationHrefUrl(this.props.templatesUrl)
            : AppPrefixUtils.locationHrefUrl(this.props.publicTemplatesUrl);
    }
    sendBack() {
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                status: 'FOR_APPROVAL',
            },
        }));
        this.validateForm(() => {
            this.blockUi(this.handleValidForm);
        });
    }
    createOrUpdate() {
        const {element} = this.state;
        this.scrollToTop();
        this.saveCookie(`agreement-draft-list-activeIndex`, 1);
        if (this._isMounted) {
            if (element.id === undefined || element.id === null) {
                this.service
                    .add(element)
                    .then((response) => {
                        this.afterAddSuccess(response);
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message, 10000);
                    });
            } else {
                this.service
                    .update(element)
                    .then((response) => {
                        this.afterUpdateSuccess(response);
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message, 10000);
                    });
            }
        }
    }
    prepareResponseBeforeUpdateElement(data) {
        if (data.valuesMap) {
            let newArray = Object.entries(data.valuesMap);

            let map = new Map(newArray);
            for (let [key, value] of map.entries()) {
                if (value.dateValue) {
                    value.dateValue = new Date(value.dateValue);
                }
            }
        }
        const steps = data.children;
        steps.unshift({renderStep: () => this.renderFirstStep(), stepIndex: 0, label: 'Wprowadzenie', hideLabel: true});
        steps.push({renderStep: () => this.renderLastStep(), stepIndex: steps.length, label: 'Podsumowanie', hideLabel: true});
        data.children = steps;
        return data;
    }
    updateElementAfterAgreementGeneration(data, stepChangeFunction) {
        data.wizard = this.prepareResponseBeforeUpdateElement(data.wizard);
        this.setState(
            {
                element: data.wizard,
                agreementReportBase64: data.base64,
            },
            () => {
                if (stepChangeFunction) {
                    stepChangeFunction();
                }
                super.initAfterSetElement();
            }
        );
    }
    updateElement(data) {
        data = this.prepareResponseBeforeUpdateElement(data);
        this.setState(
            {
                element: data,
                contractorEmail: data.contractorEmail,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    getContainerListName() {
        return 'user-template-list-container';
    }
    updateHtmlContent() {
        const modifiedList = this.state.element.children;
        for (let i = 0; i < modifiedList.length; i++) {
            const step = modifiedList[i];
            if (step.uuid) {
                step.html = document.getElementById(`agreement-content-${step.uuid}`).innerHTML;
            }
        }
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                children: modifiedList,
            },
        }));
    }

    showConfirmationBlankValue(value) {
        this.setState((prevState) => ({
            dialogVisibile: true,
            dialogValue: value,
        }));
    }
    hideConfirmationBlankValueWithConfirmation(value) {
        if (value?.uuid) {
            const map = this.state.element.valuesMap;
            map[value.uuid].confirmed = true;

            this.setState((prevState) => ({
                dialogVisibile: false,
                element: {
                    ...prevState.element,
                    valuesMap: map,
                },
            }));
        }
    }
    hideConfirmationBlankValue(value) {
        this.setState((prevState) => ({
            dialogVisibile: false,
        }));
    }
    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }

        this.validateForm(() => {
            this.blockUi(this.handleValidForm);
        }, true);
    }

    validatorMode(draftMode) {
        let validator = this.validator;
        let excludedKeys = [];

        !!draftMode ? validator.ignoreList.push('required') : (validator.ignoreList = []);

        const entries = Object.entries(validator.errorMessages);
        if (entries?.length > 0) {
            entries.forEach((keyValuePair) => {
                const element = document.getElementById(keyValuePair[0]);
                const requireed = element?.attributes?.required;
                if (requireed) {
                    if (!!!draftMode) {
                        if (!element.value) {
                            excludedKeys.push(keyValuePair[0]);
                        }
                    } else {
                        excludedKeys.push(keyValuePair[0]);
                    }
                }
            });
        }
        excludedKeys.forEach((key) => {
            validator.fields[key] = !!draftMode;
        });

        validator.rules.required.required = !!!draftMode;

        return validator;
    }

    validateForm(onSuccessCallback, draftMode) {
        const validator = this.validatorMode(draftMode);
        if (validator.allValid()) {
            let newArray = Object.entries(this.state.element.valuesMap);
            const confirmationParameters = newArray?.filter((val) => {
                const answer = val[1];
                return (
                    answer &&
                    AgreementUtils.answerIsInActiveStep(answer, this.state.activeStepUUID) &&
                    answer.requirementType === 'NOT_REQUIRED_DIALOG' &&
                    AgreementUtils.isEmptyValue(answer, this.validator) &&
                    !answer.confirmed
                );
            });
            if (confirmationParameters?.length > 0) {
                //todo straszna lipa jak wartość z mapy jest wyjmowana do dialogu !!!!
                if (confirmationParameters[0][1]) {
                    confirmationParameters[0][1].uuid = confirmationParameters[0][0];
                }
                this.showConfirmationBlankValue(confirmationParameters[0][1]);
                return;
            } else {
                onSuccessCallback();
            }
        } else {
            this.validator.showMessages();
            this.scrollToError = true;
            this.forceUpdate();
        }
    }
    validateBeforeStepChange(stepChangeFunction) {
        this.validateForm(stepChangeFunction);
    }
    actionBeforePunultimateStep(stepChangeFunction) {
        this.validateForm(() => this.saveBeforeLastStep(stepChangeFunction));
    }

    updateActiveStepUUID(activeStepUUID) {
        this.setState({activeStepUUID});
    }
    saveBeforeLastStep(stepChangeFunction) {
        const {element} = this.state;
        this.scrollToTop();
        this.blockUi();
        if (this._isMounted) {
            if (element.id === undefined || element.id === null) {
                this.service
                    .createAndGenerate(element)
                    .then((response) => {
                        this.updateElementAfterAgreementGeneration(response, stepChangeFunction);
                    })
                    .catch((err) => {
                        if (!this.authService.loggedIn()) {
                            window.location.href = AppPrefixUtils.locationHrefUrl(this.props.publicTemplatesUrl);
                        } else {
                            this.persistMessage('error', 'Błąd', err.message);
                            this.saveCookie(`agreement-draft-list-activeIndex`, 0);
                            window.location.href = AppPrefixUtils.locationHrefUrl(this.props.templatesUrl);
                        }
                    });
            } else {
                this.service
                    .updateAndGenerate(element)
                    .then((response) => {
                        this.updateElementAfterAgreementGeneration(response, stepChangeFunction);
                    })
                    .catch((err) => {
                        this.persistMessage('error', 'Błąd', err.message);
                        this.saveCookie(`agreement-draft-list-activeIndex`, 1);
                        window.location.href = AppPrefixUtils.locationHrefUrl(this.props.templatesUrl);
                    });
            }
        }
    }

    selectHierarchicalDictionary(event, customTagUUID, callback, maxSize) {
        const map = this.state.element.valuesMap;
        map[customTagUUID].hierarchicalDictionary = event.node;
        map[customTagUUID].objectValueStr = JSON.stringify(event.node);
        var textToAdd = event?.node?.label;
        HtmlParserUtils.innerHtmlById(textToAdd, customTagUUID);
        this.setState(
            (prevState) => ({
                element: {
                    ...prevState.element,
                    valuesMap: map,
                },
            }),
            () => {
                this.handleResizeDictionary(maxSize);
                if (callback) {
                    callback();
                }
            }
        );
    }
    clearSelectionDictionary(event, dictionaryToClearUUID, callBack) {
        const modifiedMap = this.state.element.valuesMap;
        modifiedMap[dictionaryToClearUUID].hierarchicalDictionary = null;
        HtmlParserUtils.innerHtmlById('', dictionaryToClearUUID);
        this.setState(
            (prevState) => ({
                element: {
                    ...prevState.element,
                    valuesMap: modifiedMap,
                },
            }),
            () => {
                if (callBack) {
                    callBack();
                }
            }
        );
    }
    renderContractorAcceptance(component, index) {
        const value = this.state.element.valuesMap[component.uuid];
        const oldValuesMap = this.state.oldValuesMap;
        return (
            <InputCheckboxComponent
                id={`${component.field}-${index}-accepted`}
                name='accepted'
                label={'Akceptuję'}
                rendered={this.state.isContractor}
                labelOnRight
                value={value?.accepted}
                stateField={`element.valuesMap[${component.uuid}]`}
                onChange={this.handleChange}
                validator={this.validator}
                viewMode={'EDIT'}
                validators='not_required'
                colClass='col-12'
                labelClassName='checkbox-label-fuel-card'
                valueLabel='label'
                valueKey='enumValue'
                onAfterStateChange={() => {
                    if (value?.accepted) {
                        const modifiedValuesMap = AgreementUtils.backToOldValue(component, this.state.element.valuesMap, oldValuesMap);
                        this.setState((prevState) => ({
                            element: {
                                ...prevState.element,
                                valuesMap: modifiedValuesMap,
                            },
                        }));
                    }
                }}
            />
        );
    }

    disabledComponent(component) {
        return !this.authService.loggedIn() && this.state.element.valuesMap[component.uuid]?.accepted;
    }

    acceptanceStyle(component) {
        if (this.isContractor || this.state.element.status === 'FOR_APPROVAL') {
            return this.state.element.valuesMap[component.uuid]?.accepted
                ? 'border-wrapper border border-success rounded ' // nie kasuj tego prawego odstepu!!!!!!!
                : 'border-wrapper border border-danger rounded  ';
        } else {
            return '';
        }
    }
    isAnswerOptionSelected(valuesMap, component) {
        return (
            component.answerOptionParentUuid && valuesMap[component.answerOptionParentUuid]?.objectValue?.id === component.answerOptionId
        );
    }
    elementIsNotChildofSelectTemplateComponent(component) {
        return component.answerOptionParentUuid === null || component.answerOptionParentUuid === undefined;
    }

    validationForDate(isRequired, valuesMap) {
        let validationRequired = 'not_required';
        if (isRequired) {
            validationRequired = 'required';
        }
        if (valuesMap.dateLimitRequired) {
            if (valuesMap?.minDate && valuesMap?.maxDate) {
                return (
                    validationRequired +
                    `|after_or_equal:${moment(valuesMap?.minDate).format('DD-MM-YYYY').toString()}|before_or_equal:${moment(
                        valuesMap?.maxDate
                    )
                        .format('DD-MM-YYYY')
                        .toString()}`
                );
            }
        }
        return validationRequired;
    }

    recalculateWidth(element, inputIdToCompare) {
        const {previewVisible} = this.state;
        const value = element.value;
        if (value === undefined) {
            return;
        }
        const arrayValue = value.split('\n');

        const longestElement = this.longestElement(arrayValue);
        const textWidth = this.textWidth(longestElement, element, 32);
        const cardTemplate = document.getElementById('width-fixed-element');
        const inputToCompare = document.getElementById(inputIdToCompare);
        if (previewVisible) {
            this.previewVisibleStyle(element);
            return;
        }
        element.style.whiteSpace = 'nowrap';
        const contractorBorder = element.parentNode.parentNode.parentNode.parentNode.parentNode;
        const contractorBorderExist = element.parentNode.parentNode.parentNode.parentNode.parentNode.classList.contains('border-wrapper');
        const isFromDynamicList = this.isDynamicListForText(element);
        let pixelsToRemove = isFromDynamicList ? 60 : 60;
        pixelsToRemove = contractorBorderExist ? pixelsToRemove + 70 : pixelsToRemove;
        if (textWidth > cardTemplate.clientWidth - pixelsToRemove) {
            console.log('WYKRACZAMY');
            const labelMargin = contractorBorderExist ? cardTemplate.clientWidth - 380 : cardTemplate.clientWidth - 280;
            const label = element.parentNode.parentNode.children[0];
            label.style.width = labelMargin + 'px';
            const elementWidth = contractorBorderExist
                ? cardTemplate.clientWidth - (isFromDynamicList ? 60 : 48) - 60
                : cardTemplate.clientWidth - (isFromDynamicList ? 60 : 48);
            element.style.width = elementWidth + 'px'; // tu musi byc 48px
            const wrapper = element.parentNode;
            wrapper.style.width = 100 + 'px';
            if (contractorBorderExist) contractorBorder.style.width = elementWidth + 37 + 'px';
            return;
        }
        this.previewUnvisible(element, inputToCompare, textWidth, () =>
            this.previewUnsisibleCallback(textWidth, cardTemplate, element, isFromDynamicList)
        );
    }

    previewUnsisibleCallback(textWidth, cardTemplate, element, isFromDynamicList) {
        const contractorBorder = element.parentNode.parentNode.parentNode.parentNode.parentNode;
        const contractorBorderExist = element.parentNode.parentNode.parentNode.parentNode.parentNode.classList.contains('border-wrapper');
        if (textWidth > cardTemplate.clientWidth - isFromDynamicList ? 120 : 80) {
            const label = element.parentNode.parentNode.children[0];
            label.style.width = cardTemplate.clientWidth - 100 + 'px';
            const wrapper = element.parentNode;
            wrapper.style.width = 100 + 'px';
            if (contractorBorderExist) {
                contractorBorder.style.width = textWidth + 40 + 'px';
            }
        }
    }

    isDynamicListForText(element) {
        return element.parentNode.parentNode.parentNode.parentNode.parentNode.classList.contains('dynamic-list');
    }

    previewVisibleStyle(element) {
        element = this.getElementParent(element);
        const label = element.parentNode.parentNode.children[0];

        const contractorBorder = element.parentNode.parentNode.parentNode.parentNode.parentNode;
        const contractorBorderExist = element.parentNode.parentNode.parentNode.parentNode.parentNode.classList.contains('border-wrapper');
        const wrapper = element.parentNode;
        wrapper.style.width = '100%';
        element.style.width = '100%';
        label.style.width = '100%';
        label.style.whiteSpace = 'wrap';
        element.style.whiteSpace = 'nowrap';
        if (contractorBorderExist) {
            contractorBorder.style.width = '';
        }
    }
    previewUnvisible(element, inputToCompare, textWidth, callback) {
        inputToCompare.style.display = 'block';
        const offsetWidth = inputToCompare.offsetWidth;
        element = this.getElementParent(element);
        const label = element.parentNode.parentNode.children[0];
        inputToCompare.style.display = 'block';
        label.style.marginRight = '20px!important';

        const contractorBorder = element.parentNode.parentNode.parentNode.parentNode.parentNode;
        const contractorBorderExist = element.parentNode.parentNode.parentNode.parentNode.parentNode.classList.contains('border-wrapper');
        console.log();
        if (textWidth < offsetWidth) {
            // jesli nie wykracza poza standardowy zakres
            inputToCompare.style.display = 'none';
            element.style.width = '100%';
            const wrapper = element.parentNode;
            wrapper.style.width = '100%';
            // if (contractorBorderExist) {
            //     contractorBorder.style.width = '100%';
            // }
            return;
        }

        inputToCompare.style.display = 'none';
        element.style.whiteSpace = 'normal';
        label.style.whiteSpace = 'normal';
        element.style.width = textWidth + 'px';
        label.style.width = textWidth + 'px';
        if (contractorBorderExist) {
            contractorBorder.style.width = textWidth + 40 + 'px';
        }
        if (callback) {
            callback();
        }
    }

    getElementParent(element) {
        return element.classList.contains('p-inputnumber-input') ? element.parentNode : element;
    }

    getIdToCompareComponentsWidth(component) {
        if (!component) {
            return undefined;
        }
        switch (component.type) {
            case 'TEXT':
                return inputIdToCompareText;
            case 'DICTIONARY':
                return inputIdToCompareDictionary;
            default:
                return undefined;
        }
    }

    initiateInputWidth(elementId, inputIdToCompare) {
        setTimeout(() => {
            let element = document.getElementById(elementId);
            if (element?.classList?.contains('p-treeselect')) {
                this.handleResizeDictionary();
                return;
            }
            if (!inputIdToCompare) {
                return;
            }
            if (!element) {
                return;
            }
            if (element.tagName.toLowerCase() === 'span') {
                // console.log('Komponent znajduje się w tagu span.');
                element = element.firstChild;
            }
            this.recalculateWidth(element, inputIdToCompare);
        }, 0);
    }

    textWidth(longestElement, element, sizeToAdd) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = window.getComputedStyle(element).getPropertyValue('font');
        return context.measureText(longestElement).width + sizeToAdd;
    }
    longestElement(arrayValue) {
        let longestElement = arrayValue[0];
        for (let i = 1; i < arrayValue.length; i++) {
            if (arrayValue[i].length > longestElement.length) {
                longestElement = arrayValue[i];
            }
        }
        return longestElement;
    }
    getWrapperMarginStyle(style) {
        const {previewVisible} = this.state;
        let wraperMarginStyle = {};
        if (window.innerWidth > 992 && !previewVisible) {
            wraperMarginStyle = style;
        }
        return wraperMarginStyle;
    }
    getComponentId(component, index) {
        if (component.type === 'TEXT') {
            return `${component.field}-${component.uuid}`;
        }
        if (component.type === 'DICTIONARY') {
            return `dictionary-select-${component.uuid}`;
        }
        return `${component.field}-${index}`;
    }
    // KOMPONENT kalendarz za długi albel
    renderRecursiveChild(component, index, indexStep, activeStep, isFromDynamicList) {
        const valuesMap = this.state.element.valuesMap[component.uuid];
        const col12 = 'col-12';
        const {previewVisible} = this.state;
        const colText = previewVisible ? col12 : 'col-custom-div-card';
        const colCheckbox = previewVisible ? col12 : 'col-lg-3';
        const colData = previewVisible ? 'col-xl-12 col-lg-9 col-md-12 col-sm-12 col-xs-12' : 'col-custom-div-card';
        // const colNumber = previewVisible ? col12 : 'col-lg-4';
        const colNumber = previewVisible ? col12 : 'col-custom-div-card';
        const colSelectOne = previewVisible ? col12 : 'col-lg-6';
        const colDictionary = previewVisible ? col12 : 'col-custom-div-card';
        const colMultiList = previewVisible ? col12 : 'col-lg-6';
        const colSelectTemplate = previewVisible ? col12 : 'col-lg-6';

        const componentCol = 'col-12';
        this.initiateInputWidth(this.getComponentId(component, index), this.getIdToCompareComponentsWidth(component));

        return this.elementIsNotChildofSelectTemplateComponent(component) ||
            this.isAnswerOptionSelected(this.state.element.valuesMap, component) ? (
            <React.Fragment>
                {component.type === 'TEXT' ? (
                    <React.Fragment>
                        <div className={`${this.acceptanceStyle(component)}${colText} ${isFromDynamicList && 'dynamic-list'}`}>
                            <InputTextareaComponent
                                autoResize
                                rows={valuesMap?.maxText < 100 ? 1 : 4}
                                id={`${component.field}-${component.uuid}`}
                                name={'stringValue'}
                                label={component.label}
                                insideTable
                                outerArea={!previewVisible ? 'outer-area-textarea' : ''}
                                // wrapperStyle={this.getWrapperMarginStyle({marginRight: '10px!important'})}
                                disabled={this.disabledComponent(component)}
                                stateField={`element.valuesMap[${component.uuid}]`}
                                viewMode={this.props.viewMode}
                                onChange={(inputType, parameters, event, onAfterStateChange, stateField) => {
                                    this.recalculateWidth(event.target, inputIdToCompareText);
                                    this.handleChangePrevalidation(
                                        inputType,
                                        parameters,
                                        event,
                                        onAfterStateChange,
                                        stateField,
                                        `${component.field}-${component.uuid}`,
                                        valuesMap?.maxText
                                    );
                                }}
                                value={valuesMap?.stringValue}
                                onAfterStateChange={() => {
                                    let textToAdd = valuesMap?.stringValue;
                                    textToAdd = textToAdd.replace(/\n/g, '<br/>');
                                    HtmlParserUtils.innerHtmlById(textToAdd, component.uuid);
                                    this.updateHtmlContent();
                                }}
                                validator={this.validator}
                                validators={
                                    valuesMap?.requirementType === 'REQUIRED'
                                        ? `required|min:${valuesMap?.minText}|max:${valuesMap?.maxText}`
                                        : `not_required|min:${valuesMap?.minText}|max:${valuesMap?.maxText}`
                                }
                                tooltip={component.description}
                            />
                            {this.isContractor && this.renderContractorAcceptance(component, index)}
                            <div style={this.getWrapperMarginStyle({marginRight: '20px'})}>
                                <InputText
                                    id={inputIdToCompareText}
                                    style={{width: '100%', display: 'none'}}
                                    className={inputIdToCompareText}
                                    onChange={(e) => {}}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                ) : null}

                {component.type === 'CHECKBOX' ? (
                    <React.Fragment>
                        <div className={`${this.acceptanceStyle(component)}${colCheckbox}`}>
                            <InputCheckboxComponent
                                id={`${component.field}-${index}`}
                                name='booleanValue'
                                label={component.label}
                                disabled={this.disabledComponent(component)}
                                labelOnRight
                                value={this.state.element.valuesMap[component.uuid]?.booleanValue}
                                stateField={`element.valuesMap[${component.uuid}]`}
                                onChange={this.handleChange}
                                validator={this.validator}
                                viewMode={this.props.viewMode}
                                validators='not_required'
                                colClass={componentCol}
                                labelClassName='checkbox-label-fuel-card'
                                valueLabel='label'
                                valueKey='enumValue'
                                onAfterStateChange={() => {
                                    HtmlParserUtils.activateOrHideElement(
                                        this.state.element.valuesMap[component.uuid]?.booleanValue === true,
                                        component.uuid
                                    );
                                    this.recalculatePointers();
                                }}
                                tooltip={component.description}
                            />
                            {this.isContractor && this.renderContractorAcceptance(component, index)}
                        </div>
                    </React.Fragment>
                ) : null}
                {component.type === 'DATE' ? (
                    <React.Fragment>
                        <div className={`${this.acceptanceStyle(component)}${colData}`}>
                            <InputCalendarComponent
                                id={`${component.field}-${index}`}
                                name='dateValue'
                                outerArea={!previewVisible ? 'outer-area-date' : ''}
                                marginLabelRight={!previewVisible ? 'margin-right:20px' : ''}
                                label={component.label}
                                wrapperStyle={this.getWrapperMarginStyle({marginRight: '7px'})}
                                dateFormatMoment='YYYY-MM-DD'
                                disabled={this.disabledComponent(component)}
                                colClass={`${componentCol} calendar-icon-white-bg ${!previewVisible && 'date-margin-right'}`}
                                value={this.state.element.valuesMap[component.uuid]?.dateValue}
                                stateField={`element.valuesMap[${component.uuid}]`}
                                validator={this.validator}
                                validators={`${
                                    this.state.element.valuesMap[component.uuid]?.requirementType === 'REQUIRED'
                                        ? this.validationForDate(true, valuesMap)
                                        : this.validationForDate(false, valuesMap)
                                }|date|date_format:DD-MM-YYYY,dd-mm-rrrr`}
                                onChange={this.handleChange}
                                showIcon
                                viewMode={this.props.viewMode}
                                onAfterStateChange={() => {
                                    var textToAdd = `${moment(this.state.element.valuesMap[component.uuid]?.dateValue).format(
                                        'DD.MM.YYYY'
                                    )}&nbsp;r.`;
                                    HtmlParserUtils.innerHtmlById(textToAdd, component.uuid);
                                    this.updateHtmlContent();
                                }}
                                tooltip={component.description}
                            />
                            {this.isContractor && this.renderContractorAcceptance(component, index)}
                        </div>
                    </React.Fragment>
                ) : null}

                {component.type === 'NUMBER' ? (
                    <React.Fragment>
                        <div className={`${this.acceptanceStyle(component)}${colNumber} `}>
                            <div
                                className={`row outer-area-number  ${
                                    !!component?.numberProperties?.showCurrency ? 'currency-value' : 'no-currency-value'
                                } ${isFromDynamicList ? 'dynamic-list' : ''}`}
                            >
                                {/* ml-auto justify-content-end */}
                                <div className={`col-xxxl-5 col-xxl-6 col-xl-6 col-md-5 col-sm-4 col-xs-12`}>
                                    <InputNumberComponent
                                        id={`${component.field}-${index}`}
                                        name='numberValue'
                                        locale='pl-PL'
                                        currency={
                                            this.state.element.valuesMap[component.uuid]?.answerCurrency?.currency
                                                ? this.state.element.valuesMap[component.uuid]?.answerCurrency?.currency
                                                : this.state.element.valuesMap[component.uuid]?.currency?.currency
                                        }
                                        mode={'decimal'}
                                        label={component.label}
                                        disabled={this.disabledComponent(component)}
                                        style={{border: '3px solid red!important'}}
                                        colClass={componentCol}
                                        value={this.state.element.valuesMap[component.uuid]?.numberValue}
                                        stateField={`element.valuesMap[${component.uuid}]`}
                                        validator={this.validator}
                                        validators={`${
                                            this.state.element.valuesMap[component.uuid]?.requirementType === 'REQUIRED'
                                                ? 'required'
                                                : 'not_required'
                                        }|number_max:${component?.numberProperties?.numberMax}|number_min:${
                                            component?.numberProperties?.numberMin
                                        }`}
                                        onChange={(a, b, event, d, e) => {
                                            if (event.value >= 999999999999999) {
                                                event.value = 999999999999999;
                                                const field = component.field === undefined ? 'undefined' : component.field;
                                                const element = document.getElementById(`${field}-${index}`);
                                                element.firstChild.value = '999 999 999 999 999';
                                                element.firstChild.setAttribute('aria-valuenow', 999999999999999);
                                                this.handleChange(a, b, event, d, e);
                                                element.firstChild.setAttribute('aria-valuenow', 999999999999999);
                                                return;
                                            }
                                            // this.recalculateWidth(event.originalEvent.currentTarget, inputIdToCompareNumber);
                                            this.handleChange(a, b, event, d, e);
                                        }}
                                        viewMode={this.props.viewMode}
                                        onBlur={() => {
                                            const number = this.state.element.valuesMap[component.uuid]?.numberValue;
                                            const currency = this.state.element.valuesMap[component.uuid]?.answerCurrency;
                                            var textToAdd = StringUtils.formatInsertedNumberValue(
                                                number,
                                                component.numberProperties,
                                                currency,
                                                this.translate
                                            );
                                            HtmlParserUtils.innerHtmlById(textToAdd, component.uuid);
                                            this.updateCountableResults(() => {
                                                this.updateHtmlContent();
                                            });
                                        }}
                                        minFractionDigits={0}
                                        max={999999999999999}
                                        maxFractionDigits={AgreementCalculationUtils.getDecimalPlaces(component?.numberProperties)}
                                        tooltip={component.description}
                                    />
                                </div>
                                <div className={`col-xxxl-1 col-xxl-1 col-xl-1 col-md-2 col-sm-2 col-xs-12`}></div>
                                <div
                                    className={`col-xxxl-4 col-xxl-4 col-xl-4 col-md-4 col-sm-4 col-xs-12 currency-component ${
                                        !previewVisible ? 'collapsed' : 'expanded'
                                    }`}
                                >
                                    <InputDropdownComponent
                                        id={`${component.field}-answerCurrency`}
                                        name='answerCurrency'
                                        label={'Waluta'}
                                        // placeholder=''
                                        colClass={componentCol}
                                        value={this.state.element.valuesMap[component.uuid]?.answerCurrency}
                                        validator={this.validator}
                                        disabled={this.disabledComponent(component)}
                                        options={this.state.currencies}
                                        onChange={this.handleChange}
                                        viewMode={this.props.viewMode}
                                        rendered={!!component?.numberProperties?.showCurrency}
                                        optionLabel='currency'
                                        stateField={`element.valuesMap[${component.uuid}]`}
                                        validators={
                                            this.state.element.valuesMap[component.uuid]?.requirementType === 'REQUIRED'
                                                ? 'required'
                                                : 'not_required'
                                        }
                                        filter
                                        filterBy='currency,symbol'
                                        itemTemplate={(item) => {
                                            return (
                                                <div>
                                                    <span>{item.currency}</span>
                                                    <span style={{paddingLeft: '4px'}}>{`(${item.symbol})`}</span>
                                                </div>
                                            );
                                        }}
                                        onAfterStateChange={() => {
                                            const number = this.state.element.valuesMap[component.uuid]?.numberValue;
                                            const currency = this.state.element.valuesMap[component.uuid]?.answerCurrency;
                                            var textToAdd = StringUtils.formatInsertedNumberValue(
                                                number,
                                                component.numberProperties,
                                                currency,
                                                this.translate
                                            );
                                            HtmlParserUtils.innerHtmlById(textToAdd, component.uuid);
                                            this.updateCountableResults(() => {
                                                this.updateHtmlContent();
                                            });
                                        }}
                                        dataKey='iso'
                                    />
                                </div>
                            </div>

                            {this.isContractor && this.renderContractorAcceptance(component, index)}
                        </div>
                    </React.Fragment>
                ) : null}
                {component.type === 'SELECT_ONE' ? (
                    <div className={`${this.acceptanceStyle(component)}${colSelectOne}`}>
                        <InputRadioButtonComponent
                            id={`${component.field}-${index}`}
                            name='objectValue'
                            colClass={'col-12'}
                            value={this.state.element.valuesMap[component.uuid]?.objectValue}
                            label={component.label}
                            viewMode={this.props.viewMode}
                            labelOnRight
                            options={this.state.element.valuesMap[component.uuid].answerOptions}
                            valueLabel='name'
                            itemColClass={componentCol}
                            validators={
                                this.state.element.valuesMap[component.uuid]?.requirementType === 'REQUIRED' ? 'required' : 'not_required'
                            }
                            stateField={`element.valuesMap[${component.uuid}]`}
                            onChange={this.handleChange}
                            validator={this.validator}
                            valueKey={'id'}
                            compareByOneProperty
                            onAfterStateChange={() => {
                                const modifiedMap = this.state.element.valuesMap;
                                modifiedMap[component.uuid].objectValueStr = JSON.stringify(
                                    this.state.element.valuesMap[component.uuid]?.objectValue
                                );
                                var textToAdd = this.state.element.valuesMap[component.uuid]?.objectValue?.name;
                                HtmlParserUtils.innerHtmlById(textToAdd, component.uuid);

                                this.setState(
                                    (prevState) => ({
                                        element: {
                                            ...prevState.element,
                                            valuesMap: modifiedMap,
                                        },
                                    }),
                                    () => this.updateHtmlContent()
                                );
                            }}
                            tooltip={component.description}
                        />
                        {this.isContractor && this.renderContractorAcceptance(component, index)}
                    </div>
                ) : null}
                {component.type === 'SELECT_TEMPLATE' ? (
                    <div className={`${this.acceptanceStyle(component)}${colSelectTemplate}`}>
                        <InputRadioButtonComponent
                            id={`${component.field}-${index}`}
                            name='objectValue'
                            colClass={componentCol}
                            value={this.state.element.valuesMap[component.uuid]?.objectValue}
                            label={component.label}
                            viewMode={this.props.viewMode}
                            labelOnRight
                            options={this.state.element.valuesMap[component.uuid].answerOptions}
                            valueLabel='name'
                            itemColClass={componentCol}
                            validators={
                                this.state.element.valuesMap[component.uuid]?.requirementType === 'REQUIRED' ? 'required' : 'not_required'
                            }
                            stateField={`element.valuesMap[${component.uuid}]`}
                            onChange={this.handleChange}
                            validator={this.validator}
                            valueKey={'id'}
                            compareByOneProperty
                            onAfterStateChange={() => {
                                const modifiedMap = this.state.element.valuesMap;
                                modifiedMap[component.uuid].objectValueStr = JSON.stringify(
                                    this.state.element.valuesMap[component.uuid]?.objectValue
                                );
                                //todo recalculate pointers???
                                // var textToAdd = this.state.element.valuesMap[component.uuid]?.objectValue?.name;
                                // HtmlParserUtils.innerHtmlById(textToAdd, component.uuid);

                                // HtmlParserUtils.activateOrHideElement(
                                //    false,
                                //     component.uuid
                                // );
                                const selectedId = this.state.element.valuesMap[component.uuid]?.objectValue.id;
                                HtmlParserUtils.activateOrHideElement(true, selectedId);
                                [...this.state.element.valuesMap[component.uuid].answerOptions]
                                    .filter((ao) => ao.id != selectedId)
                                    .forEach((optionToHide) => {
                                        HtmlParserUtils.activateOrHideElement(false, optionToHide.id);
                                    });
                                HtmlParserUtils.activateOrHideElement(true, selectedId);

                                this.setState(
                                    (prevState) => ({
                                        element: {
                                            ...prevState.element,
                                            valuesMap: modifiedMap,
                                        },
                                    }),
                                    () => this.updateHtmlContent()
                                );
                            }}
                            tooltip={component.description}
                        />
                        {this.isContractor && this.renderContractorAcceptance(component, index)}
                    </div>
                ) : null}
                {component.type === 'MULTI_LIST' ? (
                    <React.Fragment>
                        <div className={`${this.acceptanceStyle(component)}${colMultiList}`}>
                            <InputTextComponent
                                id={`${component.field}-${index}`}
                                name={`${component.field}-${index}`}
                                label={`${component.label}`}
                                colClass={componentCol}
                                value={`${component.label}`}
                                validator={this.validator}
                                viewMode={'VIEW'}
                                validators={'max:50'}
                                showLabel={false}
                                tooltipView={component.description}
                            />
                            {this.renderMultiOptionsList(component.uuid, activeStep, indexStep, component)}
                            {this.isContractor && this.renderContractorAcceptance(component, index)}
                        </div>
                    </React.Fragment>
                ) : null}

                {component.type === 'DICTIONARY' ? (
                    <div
                        className={`${this.acceptanceStyle(component)} ${colDictionary} dictionary ${
                            !previewVisible ? 'collapsed' : 'expanded'
                        } ${isFromDynamicList && 'dynamic-list'} `}
                    >
                        {/* // TODO: tutaj */}
                        <InputTreeSelectComponent
                            id={`dictionary-select-${component.uuid}`}
                            outerArea={
                                !previewVisible
                                    ? `outer-area-dictionary ${isFromDynamicList && 'dynamic-list'}`
                                    : `${isFromDynamicList && 'dynamic-list'}`
                            }
                            value={this.state.element.valuesMap[component.uuid]?.hierarchicalDictionary?.key}
                            valueView={this.state.element.valuesMap[component.uuid]?.hierarchicalDictionary?.label}
                            options={this.state.element.valuesMap[component.uuid]?.hierarchicalDictionaryItems}
                            onChange={(e, b, cs, c, x) => {
                                let maxSize = this.getMaxSizeDictionary(isFromDynamicList);
                                this.selectHierarchicalDictionary(e, component.uuid, () => this.updateHtmlContent(), maxSize);
                            }}
                            viewMode={this.props.viewMode}
                            validators={`${
                                this.state.element.valuesMap[component.uuid]?.requirementType === 'REQUIRED' ? 'required' : 'not_required'
                            }`}
                            validator={this.validator}
                            label={`${component.label}`}
                            colClass={componentCol}
                            filter
                            filterBy='label'
                            showClear={true}
                            clearFunction={(event, itemToClear) =>
                                this.clearSelectionDictionary(event, component.uuid, () => this.updateHtmlContent())
                            }
                            tooltip={component.description}
                        />
                        {this.isContractor && this.renderContractorAcceptance(component, index)}
                    </div>
                ) : null}
                {component.type === 'DYNAMIC_LIST' ? this.renderDynamicList(component, indexStep, activeStep) : null}
                {/* {component.description && !this.isDescriptionHidden(component.type) ? (
                    <InputTextareaComponent
                        id={`description-${component.uuid}`}
                        name='description'
                        showLabel={false}
                        colClass={componentCol}
                        value={component.description}
                        validator={this.validator}
                        validators='not_required|max:500'
                        viewMode={'VIEW'}
                        onChange={() => {}}
                        label='Opis'
                    />
                ) : null} */}
                {(this.isBooleanValueTrue(component) || this.isSubTemplate(component)) &&
                component?.children &&
                this.isRecurentComponent(component)
                    ? component.children.map((child, index) => this.renderRecursiveChild(child, index, indexStep, activeStep, true))
                    : null}
            </React.Fragment>
        ) : null;
    }
    isDescriptionHidden(type) {
        return ['SUM', 'POINT', 'POINT_REF', 'HELP'].find((t) => t === type);
    }

    isSubTemplate(component) {
        return component.type === 'SELECT_TEMPLATE';
    }
    isBooleanValueTrue(component) {
        return (
            this.state.element.valuesMap[component.uuid]?.booleanValue &&
            this.state.element.valuesMap[component.uuid]?.booleanValue === true
        );
    }

    isRecurentComponent(component) {
        return component?.type !== 'DYNAMIC_LIST';
    }
    renderDynamicList(component, indexStep, activeStep) {
        return (
            <React.Fragment>
                <div className={`dynamic-list ${this.state.previewVisible ? 'expanded' : 'collapsed'}`}>
                    {/* <div className='row' /> */}
                    <DivContainer className='col-12'>
                        <h5>{component.label}</h5>
                    </DivContainer>
                    {this.renderDynamicListItems(component, indexStep, activeStep)}
                </div>
            </React.Fragment>
        );
    }

    renderDynamicListItems(component, indexStep, activeStep) {
        return component?.dynamicRows?.map((row, rowIndex) => {
            return (
                <React.Fragment>
                    <div className='dynamic-list-child'>
                        {row.children.map((child, index) => {
                            return this.renderRecursiveChild(child, index, indexStep, activeStep, true);
                        })}
                        {rowIndex !== 0 && !this.isContractor ? (
                            <span className='delete-row-dynamic-list cursor-pointer'>
                                <ActionLink
                                    variant='blue'
                                    handleClick={this.handleRemove.bind(this, component, row, indexStep, activeStep)}
                                    className='pi pi-trash no-underline p-1'
                                    key={`remove-row-${row.rowUuid}`}
                                />
                            </span>
                        ) : null}
                    </div>
                    {rowIndex + 1 === component.dynamicRows.length && !this.isContractor ? (
                        <div className='row'>
                            <div className='add-child-button'>
                                <ActionButton
                                    label='Dodaj'
                                    className='float-right'
                                    key={'next-button'}
                                    handleClick={this.handleAdd.bind(this, component, row, indexStep, activeStep)}
                                />
                            </div>
                        </div>
                    ) : null}
                </React.Fragment>
            );
        });
    }
    handleRemove(component, row, indexStep, activeStep) {
        const modifiedDynamicRows = component.dynamicRows.filter((r) => r.rowUuid !== row.rowUuid);
        const valuesMapTemporary = this.state.element.valuesMap;

        this.removeRowSubelementsFromMap(row, valuesMapTemporary);
        this.setState(
            (prevState) => ({
                element: {
                    ...prevState.element,
                    valuesMap: valuesMapTemporary,
                },
            }),
            () => {
                this.unblockUi();
            }
        );
        this.handleChangeSetState(
            'dynamicRows',
            modifiedDynamicRows,
            () => {
                this.updateCountableResults(() => {
                    HtmlParserUtils.removeDynamicRow(row.rowUuid);
                    this.updateHtmlContent();
                });
            },
            component.stateField
        );
    }
    removeRowSubelementsFromMap(row, valuesMapTemporary) {
        if (row.children) {
            row.children.forEach((formElement) => {
                this.removeFormElementfromValuesMap(formElement, valuesMapTemporary);
            });
        }
    }
    removeFormElementfromValuesMap(formElement, valuesMapTemporary) {
        this.deleteSumElement(formElement.uuid, valuesMapTemporary);
        delete valuesMapTemporary[formElement.uuid];
        if (formElement.children) {
            formElement.children.forEach((child) => {
                this.removeFormElementfromValuesMap(child, valuesMapTemporary);
            });
        }
    }
    deleteSumElement(uuid, valuesMapTemporary) {
        this.iterateOverValuesMap((key, value) => {
            if (value.sumElementUuids && value.sumElementUuids?.length) {
                const flteredSumElements = value.sumElementUuids.filter((sumUUID) => sumUUID !== uuid);
                valuesMapTemporary[key].sumElementUuids = flteredSumElements;
            }
        });
    }
    handleAdd(component, row, indexStep, activeStep) {
        this.blockUi();
        const valuesMapTemporary = this.state.element.valuesMap;
        const modifiedDynamicRows = component.dynamicRows;

        const dynamicRowHtmlList = HtmlParserUtils.getDynamicRowContent(row.rowUuid);
        let copiedRowHtmlList = dynamicRowHtmlList.map((drh) => {
            return {html: drh};
        });
        const newRowUUID = uuidv4();
        const copiedRow = this.copyBranchAndAppendValuesMapChildren(copiedRowHtmlList, row, valuesMapTemporary, newRowUUID, []);
        modifiedDynamicRows.push({index: modifiedDynamicRows.length + 1, rowUuid: newRowUUID, children: copiedRow});
        copiedRowHtmlList = copiedRowHtmlList.map((crh) => {
            return {html: StringUtils.getReplacedString(crh.html, row.rowUuid, newRowUUID)};
        });

        this.setState(
            (prevState) => ({
                element: {
                    ...prevState.element,
                    valuesMap: valuesMapTemporary,
                },
            }),
            () => {
                this.unblockUi();
            }
        );
        this.handleChangeSetState(
            'dynamicRows',
            modifiedDynamicRows,
            () => {
                HtmlParserUtils.insertCopiedRows(copiedRowHtmlList, component.uuid);
                this.updateCountableResults(() => {
                    this.updateHtmlContent();
                });
            },
            component.stateField
        );
    }
    copyBranchAndAppendValuesMapChildren(rowHtml, dynamicRow, valuesMapTemporary, newRowUUID, rootList) {
        dynamicRow.children.forEach((currentFormElement) => {
            rootList.push(this.copyBranchAndAppendValuesMap(rowHtml, currentFormElement, valuesMapTemporary, newRowUUID));
        });
        return rootList;
    }

    copyBranchAndAppendValuesMap(rowHtmls, currentFormElement, valuesMapTemporary, newRowUUID) {
        const copied = this.copyElement(currentFormElement, newRowUUID);
        this.putCopiedElementToValuesMap(valuesMapTemporary, copied, currentFormElement);
        rowHtmls.forEach((rowHtml) => {
            rowHtml.html = StringUtils.getReplacedString(rowHtml.html, currentFormElement.uuid, copied.uuid);
        });
        if (currentFormElement.children) {
            copied.children = [];
            currentFormElement.children.forEach((child) =>
                copied.children.push(this.copyBranchAndAppendValuesMap(rowHtmls, child, valuesMapTemporary, null))
            );
        }
        return copied;
    }
    copyElement(originalElement, newRowUUID) {
        const componentStr = JSON.stringify(originalElement);
        const copied = JSON.parse(componentStr);
        copied.copiedTagUuid = originalElement?.copiedTagUuid ? originalElement?.copiedTagUuid : originalElement.uuid;
        const newUUID = uuidv4();
        copied.uuid = newUUID;
        copied.rowUuid = newRowUUID;
        return copied;
    }
    changeListElementUuidsToNewRowUuids(listName, newValueToMap) {
        if (newValueToMap[listName]) {
            const orignalFactors = newValueToMap[listName];
            const listWithCorrectUuids = [];

            orignalFactors.forEach((childUuid) => {
                this.iterateOverValuesMap((key, value) => {
                    if (
                        value.rowUuid === newValueToMap.rowUuid &&
                        (value.copiedTagUuid === childUuid || this.state.element.valuesMap[childUuid].copiedTagUuid === value.copiedTagUuid)
                    ) {
                        listWithCorrectUuids.push(key);
                    }
                });
            });
            newValueToMap[listName] = listWithCorrectUuids;
        }
    }
    attachNewColumnIntoParentSum(valuesMapTemporary, newValueToMap) {
        this.iterateOverValuesMap((key, value) => {
            if (value.sumElementUuids && value.sumElementUuids?.length) {
                value.sumElementUuids.forEach((sumElementUUID) => {
                    if (sumElementUUID === newValueToMap.copiedTagUuid) {
                        valuesMapTemporary[key].sumElementUuids.push(newValueToMap.uuid);
                    }
                });
            }
        });
    }
    putCopiedElementToValuesMap(valuesMapTemporary, copied, originalElement) {
        const currentElementInValuesMap = JSON.stringify(valuesMapTemporary[originalElement.uuid]);
        const newValueToMap = JSON.parse(currentElementInValuesMap);
        newValueToMap.id = null;
        newValueToMap.copiedTagUuid = originalElement?.copiedTagUuid ? originalElement?.copiedTagUuid : originalElement.uuid;
        newValueToMap.rowUuid = copied.rowUuid;
        newValueToMap.uuid = copied.uuid;
        this.changeListElementUuidsToNewRowUuids('factorUuids', newValueToMap);
        this.changeListElementUuidsToNewRowUuids('sumElementUuids', newValueToMap);
        this.attachNewColumnIntoParentSum(valuesMapTemporary, newValueToMap);
        valuesMapTemporary[copied.uuid] = newValueToMap;
    }

    renderMultiOptionsList(uuid, activeStep, indexStep, component) {
        return this.state.element.valuesMap[uuid]?.answerOptions?.length
            ? this.state.element.valuesMap[uuid].answerOptions.map((ao, index) => {
                  return (
                      <InputCheckboxComponent
                          id={`${uuid}-${index}-permission`}
                          name='selected'
                          label={ao.name}
                          labelOnRight
                          value={!!ao.selected}
                          validator={this.validator}
                          validators='not_required'
                          colClass='col-md-8 col-sm-12'
                          onChange={this.handleChange}
                          viewMode={this.props.viewMode}
                          stateField={`element.valuesMap[${uuid}].answerOptions[${index}]`}
                          positioning='col-md-12 form-group-checkbox-flex'
                          disabled={this.disabledComponent(component)}
                          onAfterStateChange={() => {
                              const selectedList = this.state.element.valuesMap[uuid].answerOptions.filter((ol) => !!ol.selected);
                              HtmlParserUtils.insertListToHtml(selectedList, uuid);
                              this.updateHtmlContent();
                          }}
                      />
                  );
              })
            : null;
    }
    getSunEditorInstance(suneditor) {
        this.editor.current = suneditor;
    }
    getAllStepsEditorInstance(suneditor) {
        this.allSteps.current = suneditor;
    }
    getBlurredClassName() {
        return !this.state?.paymentInfo?.paymentRequired ? '' : 'blurred-mode';
    }
    isLastStep() {
        return this.state?.element?.children?.length === Number(sessionStorage.getItem('current-index-step')) + 1;
    }
    handleResizeComponentWidth = () => {
        const inputsText = Array.from(document.getElementsByClassName(inputIdToCompareText));
        inputsText.forEach((el) => {
            const element = el.parentNode.parentNode.firstChild.firstChild.firstChild.children[1].firstChild;
            this.initiateInputWidth(element.id, inputIdToCompareText);
        });
    };
    registerResizeWidthEvent() {
        window.addEventListener('resize', this.handleResizeComponentWidth);
    }
    registerCloseDictionaryEvent() {
        setTimeout(() => {
            Array.from(document.getElementsByClassName('p-treeselect-clear')).forEach((el) => {
                el.addEventListener('click', () => this.handleRegisterCloseDictionary(el));
            });
        }, 100);
    }
    registerResizeDictionary() {
        window.addEventListener('resize', () => this.handleResizeDictionary());
    }
    handleResizeDictionary(maxSizeFromOutside, preVisible, clearOperation) {
        setTimeout(() => {
            let previewVisible = preVisible ? preVisible : this.state.previewVisible;
            Array.from(document.getElementsByClassName('p-treeselect')).forEach((el) => {
                const containsDynamicClass = el.parentNode.parentNode.parentNode.classList;
                const label = el.parentNode.children[0];
                const maxSize = maxSizeFromOutside ? maxSizeFromOutside : this.getMaxSizeDictionary(containsDynamicClass);
                let contractorBorder = el.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
                if (!contractorBorder.classList.contains('border-wrapper')) {
                    contractorBorder = undefined;
                }
                if (el.innerText === 'Wybierz') {
                    label.style.width = '100%';
                    return;
                }
                let length = this.textWidth(el.innerText, el, 70);
                if (length < 300) {
                    length = length * 1.65;
                } else if (length > 300 && length < 400) {
                    length = length * 1.35;
                } else if (length > 401 && length < 799) {
                    length = length * 1.25;
                } else if (length > 800) {
                    length = length * 1.2;
                }
                let needChanged = false;
                let changedLength = length; // bo tak
                let marginDetected = contractorBorder ? 120 : 40;
                if (changedLength > maxSize - marginDetected) {
                    const marginInside = contractorBorder ? 80 : 20;
                    changedLength = maxSize - marginInside;
                    needChanged = true;
                }
                const labelInsideTreeList = el.children[1].children[0].children[0].children[0].children[0];
                if (previewVisible) {
                    if (contractorBorder) {
                        if (needChanged) {
                            el.style.setProperty('width', changedLength - 0 + 'px', undefined);
                        } else {
                            el.style.setProperty('width', changedLength + 0 + 'px', 'important');
                        }
                        labelInsideTreeList.style.width = changedLength + 'px';
                        label.style.width = changedLength + 'px';

                        if (needChanged) {
                            contractorBorder.style.width = '';
                        } else {
                            contractorBorder.style.width = changedLength + 50 + 'px';
                        }
                    } else {
                        if (needChanged) {
                            el.style.setProperty('width', changedLength - 0 + 'px', undefined);
                        } else {
                            el.style.setProperty('width', changedLength + 0 + 'px', 'important');
                        }
                        labelInsideTreeList.style.width = changedLength + 'px';
                        label.style.width = changedLength + 'px';
                    }
                } else {
                    if (contractorBorder) {
                        if (isSidebarOpen()) {
                            // TODO: sprawdz ostatni raz LEXDOCS
                            if (maxSize - 20 > changedLength) changedLength = changedLength - 20;
                        }
                        labelInsideTreeList.style.width = changedLength + 'px';
                        // label.style.width = changedLength + 'px';
                        el.style.width = changedLength + 'px';
                        contractorBorder.style.width = changedLength + 50 + 'px';
                        // contractorBorder.style.width = changedLength + 50 + 'px';
                    } else {
                        if (isSidebarOpen()) {
                            // TODO: sprawdz ostatni raz LEXDOCS
                            if (maxSize - 20 > changedLength) changedLength = changedLength - 20;
                        }
                        labelInsideTreeList.style.width = changedLength + 'px';
                        // label.style.width = changedLength + 'px';
                        el.style.width = changedLength + 'px';
                    }
                }
            });
        }, 0);
    }
    handleRegisterCloseDictionary(el) {
        const dictioanry = el.parentNode.parentNode.parentNode.parentNode.parentNode;
        dictioanry.style.width = 100 + '%';
        // undefined, clearOperation
        let contractorBorder = dictioanry.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
        debugger;
        if (contractorBorder.classList.contains('border-wrapper')) {
            contractorBorder.style.width = '';
        }
        setTimeout(() => {
            document.getElementsByClassName('p-card-content')[0].click();
        }, 200);
    }
    getMaxSizeDictionary(isFromDynamicList) {
        return document.getElementsByClassName('collapsed-div-card-template').length === 0
            ? this.maxSizeDictionaryExpanded(isFromDynamicList)
            : this.maxSizeDictionaryCollapsed(isFromDynamicList);
    }
    maxSizeDictionaryCollapsed(isFromDynamicList) {
        const cardToCompare = document.getElementsByClassName('collapsed-div-card-template')[0];
        let sizeToCut = 40;
        if (isFromDynamicList) {
            sizeToCut = 55;
        }
        return cardToCompare.clientWidth - sizeToCut;
    }
    maxSizeDictionaryExpanded(isFromDynamicList) {
        const cardToCompare = document.getElementsByClassName('col-custom-div-card')[0];
        let sizeToCut = 40;
        if (isFromDynamicList) {
            sizeToCut = 140;
        }
        return cardToCompare.clientWidth - sizeToCut;
    }
    componentDidUpdate(prevProps, prevState) {
        let additionalMargin = 0;
        this.registerCloseDictionaryEvent();
        this.registerResizeDictionary();
        if (this.isLastStep()) {
            additionalMargin = 10;
        }
        window.editorFromAgreement = this.editor;
        const calculatedWidth = SunEditorParentWidthCounter.countWidth(
            this.sidebarVisible(),
            this.cardMargins(additionalMargin),
            this.device
        );
        const availableWidth = prevState.availableWidth;
        if (!availableWidth) {
            return;
        }
        if (availableWidth !== calculatedWidth && calculatedWidth !== this.state.availableWidth) {
            this.setState({
                availableWidth: calculatedWidth,
            });
        }
        super.componentDidUpdate();
    }

    cardColor() {
        return this.isLexyPortal() ? 'card-silver-color' : 'card-white-color';
    }
    unresize() {}

    renderStep(step, indexStep, activeStep) {
        const centeredStyle = !!this.state.previewVisible ? 'container-fluid' : '';
        return step.renderStep ? (
            step.renderStep()
        ) : (
            <React.Fragment>
                <div className={`${centeredStyle}`}>
                    <div className={`col-lg-12 `}>
                        <div
                            id='agreement-panel'
                            className={`row agreement-fullfilment ${
                                this.isContractor ? 'contractor' : 'no-contractor'
                            } ${this.cardColor()} ${this.getBlurredClassName()}`}
                        >
                            <div
                                id='div-card'
                                className={
                                    !!this.state.previewVisible
                                        ? `col-custom-div-card mt-4 card shadow-sm parameter-component preview-visible ${this.cardColor()}`
                                        : `col-12 collapsed-div-card-template ${this.cardColor()} `
                                }
                                style={{overflow: 'auto'}}
                            >
                                <Card
                                    className='col-12'
                                    header={
                                        (!this.state.previewVisible || this.isAgreementViewSplittedVertically()) &&
                                        window.location.href.includes('agreement-preview')
                                            ? this.renderExpandPreviewIcon(true)
                                            : null
                                    }
                                >
                                    <div className=''>
                                        {' '}
                                        {step?.children
                                            ? step.children.map((child, index) =>
                                                  this.renderRecursiveChild(child, index, indexStep, activeStep)
                                              )
                                            : null}
                                    </div>
                                </Card>
                            </div>
                            {!!this.state.previewVisible ? (
                                <StickyContainer id='sticky-container' className='col-custom-sticky-container sticky-container'>
                                    <Sticky disableStickyOnScroll={true} className='sticky-component'>
                                        {() => (
                                            <div className='col-12'>
                                                <div
                                                    className='agreement-content'
                                                    onClick={() => {
                                                        this.unresize();
                                                    }}
                                                    key={`scroll-panel-agreement-${step.isVisibile}`}
                                                >
                                                    {!this.isAgreementViewSplittedVertically() &&
                                                    window.location.href.includes('agreement-preview')
                                                        ? this.renderExpandPreviewIcon(false)
                                                        : null}
                                                    <SunEditor
                                                        key={`sun-editor-agreement-${this.state.indexStep}-${this.state.activeStep}`}
                                                        lang='pl'
                                                        setContents={step.html}
                                                        setOptions={{
                                                            height: 1098.12 - 32,
                                                            buttonList: [],
                                                            attributesWhitelist: {
                                                                all: 'help-tooltip',
                                                            },
                                                        }}
                                                        setDefaultStyle={this.defaultStyleForSunEditor()}
                                                        getSunEditorInstance={this.getSunEditorInstance}
                                                        onChange={(e) => {}}
                                                        onInput={() => {}}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Sticky>
                                </StickyContainer>
                            ) : null}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
    defaultStyleForSunEditor() {
        return this.device.type === 'mobile' ? 'padding: 25mm' : 'padding: 25mm';
    }
    isAgreementViewSplittedVertically() {
        return window.outerWidth >= 1200;
    }
    expandButton(vertically) {
        if (vertically) {
            return this.isLexyPortal() ? 'expand-to-the-right-leksy' : 'expand-to-the-right-lexdocs';
        } else {
            return this.isLexyPortal() ? 'view-hide-preview-eye-leksy' : 'view-hide-preview-eye-lexdocs';
        }
    }
    getExpanderIconId(previewVisible) {
        return previewVisible ? 'expander-icon-collapse' : 'expander-icon-expand';
    }
    renderExpandPreviewIcon(vertically) {
        const expanderIconId = this.getExpanderIconId(!!this.state.previewVisible);
        return (
            <div className='template-list-buttons' id='agreement-template-expander'>
                <MenuItemIcon
                    alt='podgląd'
                    className={`icon-svg ${!!this.state.previewVisible ? 'medium' : 'l'}`}
                    iconSvg={
                        !!this.state.previewVisible
                            ? this.expandButton(vertically)
                            : this.isLexyPortal()
                            ? 'preview-leksy'
                            : 'preview-lexdocs'
                    }
                    style={{cursor: 'pointer!important'}}
                    id={expanderIconId}
                    handleClick={() => {
                        this.expanderRef.click();
                    }}
                />
            </div>
        );
    }
    renderHeader() {
        return (
            <div className='template-list-buttons'>
                <div
                    ref={(input) => (this.expanderRef = input)}
                    onClick={() =>
                        this.setState({previewVisible: !this.state.previewVisible}, () => {
                            if (!this.isAgreementViewSplittedVertically()) {
                                const idToScroll = this.getExpanderIconId(!!this.state.previewVisible);
                                const expanderIcon = document.getElementById(idToScroll);

                                if (expanderIcon) {
                                    expanderIcon.scrollIntoView(true);
                                }
                            }
                        })
                    }
                ></div>
            </div>
        );
    }
    renderAllSteps() {
        return (
            <div
                className='agreement-content'
                onClick={() => {
                    this.unresize();
                }}
            >
                <SunEditor
                    key={`sun-editor-agreement all-steps-${this.state.flag}`}
                    lang='pl'
                    setContents={this.state.element.children.map((step) => step.html).join('')}
                    setOptions={{
                        buttonList: [],
                        attributesWhitelist: {
                            all: 'help-tooltip',
                        },
                    }}
                    getSunEditorInstance={this.getAllStepsEditorInstance}
                    onChange={() => {}}
                    onInput={() => {}}
                    readOnly
                />
            </div>
        );
    }
    render() {
        return (
            <React.Fragment>
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                    <div>
                        <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)}></CustomMessages>

                        {this.state.loading ? null : this.renderCard()}
                    </div>
                </BlockUi>
                {this.state.consultationDialogVisibile ? (
                    <LegalConsultationDialog
                        className='legal-consultation-dialog'
                        dialogRef={this.legalConsultationDialogRef}
                        key={`dialog-${this.state.element?.title}`}
                        tabViewRef={this.tabViewLegalRef}
                        currentUser={this.props.currentUser}
                        elementId={
                            !this.authService.loggedIn()
                                ? this.props.elementId
                                : this.getOrderPaidForParam()
                                ? this.props.elementId
                                : this.state.element.id
                                ? this.state.element.id
                                : this.props.elementId
                        }
                        agreementId={this.state.element.id}
                        onHide={(e) => {
                            this.closeConsultationDialog(e);
                        }}
                        visible={this.state.consultationDialogVisibile}
                        viewMode={'NEW'}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                        showMessage={this.showMessage}
                    />
                ) : null}
                {this.state.visibleMailingDialog ? (
                    <MailingDialog
                        elementId={Number(this.state?.elementId)}
                        scheduledConsultationId={this.state.scheduledConsultationId}
                        specializationId={this.state.specializationId}
                        onHide={() => {
                            this.setState(
                                {
                                    visibleMailingDialog: false,
                                },
                                () => {
                                    DialogResizer.removeMaskIfExistOneDialog();
                                }
                            );
                        }}
                        visible={this.state.visibleMailingDialog}
                        viewMode={'NEW'}
                        consultants={this.state.consultants}
                        afterSave={() => {
                            this.showSuccessMessage('Wysłano wiadomość');
                            this.setState(
                                {
                                    visibleMailingDialog: false,
                                },
                                () => {
                                    DialogResizer.removeMaskIfExistOneDialog();
                                }
                            );
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                        className='mailing-dialog'
                    />
                ) : null}
            </React.Fragment>
        );
    }
    renderCard() {
        return (
            <React.Fragment>
                {' '}
                {this.props.viewMode !== 'PRE_CREATE' && <BaseBreadCrumb className='narrow-bread-crumb' items={this.breadCrumbItems()} />}
                {this.state.dialogVisibile && (
                    <YesNoDialog
                        className={'confirmation-dialog'}
                        visible={this.state.dialogVisibile}
                        header='Ostrzeżenie'
                        name='dialogVisibile'
                        onChange={(type, x, target) => {
                            if (target.value) {
                                this.hideConfirmationBlankValueWithConfirmation(this.state.dialogValue);
                            } else {
                                this.hideConfirmationBlankValue(this.state.dialogValue);
                            }
                        }}
                        onHide={() => {
                            this.hideConfirmationBlankValue(this.state.dialogValue);
                            DialogResizer.removeMaskIfExistOneDialog();
                        }}
                        children={this.state.dialogValue?.requirementDialog}
                        yesButtonLabel={this.translate('agreement.confirmationDialogBlankField')}
                        noButtonLabel={this.translate('details.back')}
                    ></YesNoDialog>
                )}
                {this.state.versionDialogVisible && (
                    <YesNoDialog
                        className={'confirmation-dialog'}
                        visible={this.state.versionDialogVisible}
                        header='Ostrzeżenie'
                        name='versionDialogVisible'
                        onChange={() => {
                            this.closeVersionDialog();
                        }}
                        onHide={() => {
                            this.closeVersionDialog();
                            DialogResizer.removeMaskIfExistOneDialog();
                        }}
                        children={
                            'Wersja szablonu edytowanej umowy uległa zmianie. Sugerujemy utworzenie umowy jeszcze raz. Umowa, którą edytujesz może miec nieaktualne zapisy. Czy na pewno chcesz kontynuować?'
                        }
                        noButtonLabel={'OK'}
                        singleButton
                    ></YesNoDialog>
                )}
                {this.props.viewMode === 'PRE_CREATE' && this.state.element?.template?.price !== undefined ? (
                    <PreCreateStep
                        isPaid={
                            this.state?.paymentInfo?.paymentStatus === 'PAID' ||
                            this.state?.paymentInfo?.paymentStatus === 'PAID_BY_SUBSCRIPTION'
                        }
                        price={this.state.element?.template?.price}
                        categoryName={this.state.element?.template?.categoryName}
                        description={this.state.element?.template?.description}
                        title={this.state.element?.template?.name}
                        pdfBase64={this.state.pdfBase64}
                        handleCreate={() => {
                            const href = this.authService.loggedIn()
                                ? `/#/agreement/create/${this.props.elementId}`
                                : `/#/public/agreement/create/${this.props.elementId}`;
                            window.location.href = AppPrefixUtils.locationHrefUrl(href);
                        }}
                    ></PreCreateStep>
                ) : (
                    <Card
                        style={{width: `${this.state.availableWidth}px`}}
                        // className='details-card template-card '
                        className='template-card'
                        footer={this.renderFooter()}
                        header={this.renderHeader()}
                        id='width-fixed-element'
                    >
                        {this.renderDetails()}
                        {/* <form onSubmit={this.handleFormSubmit} noValidate>
                        </form> */}
                    </Card>
                )}
                {this.state.visibleSendToContractorDialog ? this.renderSendToContractorDialog() : null}
                {this.state.visibleUnregisterdClientDialog ? this.renderUnregisteredClientDialog() : null}
                {this.state.visibleChooseClientDialog ? this.renderChooseClientDialog() : null}
            </React.Fragment>
        );
    }

    renderSendToContractorDialog() {
        return (
            <YesNoDialog
                className={'send-to-contractor-dialog'}
                visible={this.state.visibleSendToContractorDialog}
                header='Wyślij do kontrahenta'
                name='visibleSendToContractorDialog'
                onChange={() => {
                    if (this.validator.allValid()) {
                        this.service
                            .sendToContractor(this.state.contractorEmail, this.state.element.id)
                            .then(() => {
                                const {templatesUrl} = this.props;
                                this.saveCookie(
                                    'user-template-list-container',
                                    JSON.stringify({
                                        severity: 'success',
                                        summary: '',
                                        detail: 'Wysłano do kontrahenta',
                                    })
                                );
                                window.location.href = AppPrefixUtils.locationHrefUrl(templatesUrl);
                            })
                            .catch(() => {
                                this.showErrorMessage('Problem z wysłaniem maila do kontrahenta.');
                            });

                        this.closeSendToContractorDialog();
                    } else {
                        this.validator.showMessages();
                        this.scrollToError = true;
                        this.forceUpdate();
                    }
                }}
                onHide={() => {
                    this.closeSendToContractorDialog();
                    DialogResizer.removeMaskIfExistOneDialog();
                }}
                noButtonLabel={'Wyślij'}
                singleButton
            >
                <InputTextComponent
                    id={`contractorEmail`}
                    name={`contractorEmail`}
                    label={`Email kontrahenta`}
                    colClass='col-md-12'
                    value={this.state.contractorEmail}
                    validator={this.validator}
                    stateField={''}
                    viewMode={'EDIT'}
                    validators='required|email'
                    onChange={this.handleChange}
                />
            </YesNoDialog>
        );
    }

    renderChooseClientDialog() {
        return (
            <PrimiDialog
                header='Wybierz klienta dla którego ma zostać wygenerowana umowa'
                visible={this.state.visibleChooseClientDialog}
                closable={true}
                onHide={() => {
                    // this.handleGoBack();
                    window.location.href = AppPrefixUtils.locationHrefUrl(this.props.templatesUrl);
                }}
                className='small-dialog'
                draggable={false}
            >
                <Card
                    footer={() => {
                        return (
                            <React.Fragment>
                                <hr className='line-footer' />
                                <div className='footer-dialog-button-container'>
                                    <ActionButton
                                        label='Wybierz'
                                        className='float-right'
                                        handleClick={() => {
                                            if (this.validator.allValid()) {
                                                this.setState({
                                                    visibleChooseClientDialog: false,
                                                });
                                            } else {
                                                this.validator.showMessages();
                                                this.scrollToError = true;
                                                this.forceUpdate();
                                            }
                                        }}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    }}
                >
                    <InputDropdownComponent
                        id='client'
                        name='client'
                        label='Klient'
                        optionLabel='fullName'
                        dataKey='id'
                        validator={this.validator}
                        validators='required'
                        filter
                        filterBy='fullName'
                        value={this.state.element.client}
                        options={this.state.clients}
                        colClass='col-12'
                        placeholder='Wybierz klienta'
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                        rendered
                        showClear={true}
                    />
                </Card>
            </PrimiDialog>
        );
    }

    renderUnregisteredClientDialog() {
        return this.state.visibleUnregisterdClientDialog ? (
            <YesNoDialog
                className={'payment-peronal-data-dialog'}
                visible={this.state.visibleUnregisterdClientDialog}
                header='Dane do przelewu płatniczego.'
                name='visibleSendToContractorDialog'
                onChange={() => {
                    if (this.validator.allValid()) {
                        this.startPaymentForAgreement();
                        this.closeUnregisteredClientDialog();
                    } else {
                        this.validator.showMessages();
                        this.scrollToError = true;
                        this.forceUpdate();
                    }
                }}
                onHide={() => {
                    this.closeUnregisteredClientDialog();
                    DialogResizer.removeMaskIfExistOneDialog();
                }}
                noButtonLabel={'Zapłać'}
                singleButton
            >
                <InputTextComponent
                    id={`email`}
                    name={`email`}
                    label={`Email`}
                    colClass='col-md-12'
                    value={this.state.unregisteredClient.email}
                    validator={this.validator}
                    stateField={'unregisteredClient'}
                    viewMode={'EDIT'}
                    validators='required|email'
                    onChange={this.handleChange}
                />
                <InputTextComponent
                    id={`firstName`}
                    name={`firstName`}
                    label={`Imię`}
                    colClass='col-md-12'
                    value={this.state.unregisteredClient.firstName}
                    validator={this.validator}
                    stateField={'unregisteredClient'}
                    viewMode={'EDIT'}
                    validators='required|alpha_space_dash|max:50'
                    onChange={this.handleChange}
                />
                <InputTextComponent
                    id={`lastName`}
                    name={`lastName`}
                    label={`Nazwisko`}
                    colClass='col-md-12'
                    value={this.state.unregisteredClient.lastName}
                    validator={this.validator}
                    stateField={'unregisteredClient'}
                    viewMode={'EDIT'}
                    validators='required|alpha_space_dash|max:50'
                    onChange={this.handleChange}
                />
            </YesNoDialog>
        ) : null;
    }

    renderDetails() {
        const {status} = this.state.element;

        return (
            <div className='agreemenet-base-container-details'>
                {this.state?.element?.children?.length > 0 ? (
                    <Stepper
                        onSave={() => {
                            this.handleFormSubmit();
                        }}
                        onConsultation={() => {
                            this.saveBeforeConsultationStart();
                        }}
                        tempalteStatus={status}
                        renderGenerateButton={this.renderGenerateButton}
                        isLexyPortal={this.isLexyPortal()}
                        headerComponent={this.renderDraftNameInput()}
                        renderStep={this.renderStep}
                        initializedIndex={this.state.selectedStep.stepIndex}
                        steps={this.state?.element?.children}
                        agreementId={this.state?.element?.id}
                        viewMode={this.props.viewMode}
                        visible={this.state.visibleStepper}
                        beforeStepChange={this.validateBeforeStepChange}
                        updateActiveStepUUID={this.updateActiveStepUUID}
                        actionBeforePunultimateStep={this.actionBeforePunultimateStep}
                        key={`stepper-key-${this.collapseFlag}`}
                    />
                ) : null}
            </div>
        );
    }

    initializedStepIndex() {
        const {viewMode} = this.props;
        const name = `active-step`;
        let index = 0;
        if (viewMode === 'EDIT') {
            const keyValuePair = SessionUtils.readSession(name);
            const value = parseInt(keyValuePair?.value);
            const key = parseInt(keyValuePair?.key);

            if (key) {
                if (key === this.state?.element?.id) {
                    this.setState({selectedStep: {stepIndex: value}});
                    return;
                } else SessionUtils.readSession(name);
            } else {
                SessionUtils.removeSession(name);
                if (this.lastStepEnabled()) {
                    this.service.getBase64(this.state.element.uuid).then((response) => {
                        this.setState({
                            selectedStep: {stepIndex: this.state.element.children.length - 1},
                            agreementReportBase64: response.base64,
                        });
                        return;
                    });
                }
            }
        } else SessionUtils.removeSession(name);
        this.setState({selectedStep: {stepIndex: index}});
    }

    lastStepEnabled() {
        return !this.authService.loggedIn() && !this.state.templatePinnedToConsultation && !this.isContractor;
    }

    afterAddSuccess(response) {
        const {templatesUrl} = this.props;
        this.blockUi();
        this.saveCookie(`agreement-draft-list-activeIndex`, 1);
        this.persistMessage('success', '', this.getAddSucces(response));
        window.location.href = AppPrefixUtils.locationHrefUrl(templatesUrl);
    }
    afterUpdateSuccess(response) {
        const {templatesUrl, publicTemplatesUrl} = this.props;
        this.blockUi();
        this.saveCookie(`agreement-draft-list-activeIndex`, 1);
        this.persistMessage('success', '', this.getUpdateSucces(response));
        window.location.href = AppPrefixUtils.locationHrefUrl(this.authService.loggedIn() ? templatesUrl : publicTemplatesUrl);
    }
    goBackUrl() {
        const {templatesUrl, publicTemplatesUrl} = this.props;
        this.saveCookie(`agreement-draft-list-activeIndex`, 0);
        //todo powrót z zakładki moje dokumenty
        return this.authService.loggedIn() ? templatesUrl : publicTemplatesUrl;
    }
    cancelButtonLabel() {
        return 'Powrót do listy';
    }
    openVersionDialog() {
        this.setState({versionDialogVisible: true});
    }
    closeVersionDialog() {
        this.setState({versionDialogVisible: false});
    }

    openSendToContractorDialog() {
        this.setState({visibleSendToContractorDialog: true});
    }
    closeSendToContractorDialog() {
        this.setState({visibleSendToContractorDialog: false});
    }
    openUnregisteredClientDialog() {
        this.setState({visibleUnregisterdClientDialog: true});
    }
    closeUnregisteredClientDialog() {
        this.setState({visibleUnregisterdClientDialog: false});
    }

    openConsultationDialog(e) {
        this.setState({
            consultationDialogVisibile: true,
        });
    }

    saveBeforeConsultationStart() {
        const {element} = this.state;
        this.scrollToTop();
        if (this._isMounted) {
            if (element.id === undefined || element.id === null) {
                this.service
                    .add(element)
                    .then((template) => {
                        this.updateElementAfterAgreementGeneration(template, () => {
                            this.openConsultationDialog();
                        });
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message, 10000);
                    });
            } else {
                this.openConsultationDialog();
            }
        }
    }

    closeConsultationDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({consultationDialogVisibile: false}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
            if (callback) callback();
        });
    }

    prepareFooterItems() {
        const {viewMode} = this.props;
        const {status} = this.state.element;
        const buttons = [];
        if (status !== 'GENERATED' && this.authService.loggedIn() && this.isLexyPortal()) {
            buttons.push({
                label: 'Zapisz',
                className: 'float-right',
                onClick: this.handleFormSubmit,
            });
        }
        if (status === 'SENT' && this.isContractor && !this.authService.loggedIn()) {
            buttons.push({
                label: 'Odeślij',
                className: 'float-right',
                onClick: this.sendBack,
            });
        }

        switch (viewMode) {
            case 'EDIT':
            case 'NEW':
                return buttons;
            case 'VIEW':
            default:
                return [];
        }
    }

    updateCountableResults(callback) {
        this.blockUi();
        this.recalculateCountable()
            .then(() => {
                this.unblockUi();
                if (callback) {
                    callback();
                }
            })
            .catch((err) => {
                console.log(err);
                this.showErrorMessage('Błąd');
                this.unblockUi();
                if (callback) {
                    callback();
                }
            });
    }
    recalculateCountable() {
        return new Promise((resolve, reject) => {
            try {
                const map = this.state.element.valuesMap;
                this.iterateOverValuesMap((key, value) => {
                    AgreementCalculationUtils.recalculateAndChangeHtml(key, value, map, this.translate);
                });
                this.setState(
                    (prevState) => ({
                        element: {
                            ...prevState.element,
                            valuesMap: map,
                        },
                    }),
                    () => {
                        resolve({});
                    }
                );
            } catch (err) {
                reject(err);
            }
        });
    }

    iterateOverValuesMap(itemFunction) {
        let newArray = Object.entries(this.state.element.valuesMap);
        let map = new Map(newArray);
        for (let [key, value] of map.entries()) {
            itemFunction(key, value);
        }
    }
    recalculatePointers() {
        const pointReferencesMap = {};

        this.iterateOverValuesMap((key, value) => {
            if (value.pointReferences) {
                pointReferencesMap[key] = value.pointReferences;
            }
        });

        HtmlParserUtils.recalculatePoints(pointReferencesMap, () => this.updateHtmlContent());
    }
}

AgreementBaseContainer.defaultProps = {
    backUrl: '/#/agreement-list',
    templatesUrl: '/#/template-list',
    publicNewAgreementUrl: '/#/public/agreement/create',
};
AgreementBaseContainer.propTypes = {};
export default AgreementBaseContainer;
