import React from 'react';
import BaseDialogContainer from '../baseContainers/BaseDialogContainer';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import DivContainer from '../components/DivContainer';
import SubscriptionService from '../services/SubscriptionService';
import {InputText} from 'primereact/inputtext';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import TemplateListComponent from '../components/TemplateListComponent';

class TemplatesTableDialog extends BaseDialogContainer {
    constructor(props) {
        super(props);
        this.state = {
            element: {
                id: '',
                package: undefined,
            },
            viewMode: this.props.viewMode,
            activeIndex: 0,
            globalFilterValue: '',
            filters: {
                global: {value: null, matchMode: FilterMatchMode.CONTAINS},
                name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
            },
        };
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.service = this.props.service;
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    onGlobalFilterChange(e) {
        const value = e.target.value;
        let filters = {...this.state.filters};
        filters['global'].value = value;
        this.setState({filters, globalFilterValue: value});
    }

    renderHeader() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>Dostępne szablony</label>
                </DivContainer>
            </DivContainer>
        );
    }

    initBeforeSetElement() {
        this.service
            .getSubscriptionWithTemplate(this.props.elementId)
            .then((result) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        package: result,
                    },
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message, 10000, true, 'Błąd przy pobieraniu pakietów ogólnych');
            });
    }

    renderDetails() {
        return this.state.element.package != null ? (
            <React.Fragment>
                <DivContainer colClass='row template-table'>
                    <TemplateListComponent templates={this.state.element.package.agreementTemplates}></TemplateListComponent>
                </DivContainer>
            </React.Fragment>
        ) : null;
    }

    onKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.unblockUi();
        }
    }

    renderDataTemplateHeader() {
        return (
            <div className='flex justify-content-between align-items-center'>
                <DivContainer className='col-lg-12'>
                    <span className='w-100 p-input-icon-left'>
                        <i className='pi pi-search' />
                        <InputText value={this.state.globalFilterValue} onChange={this.onGlobalFilterChange} placeholder='Tytuł szablonu' />
                    </span>
                </DivContainer>
            </div>
        );
    }
}

TemplatesTableDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
    service: new SubscriptionService(),
};

TemplatesTableDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
};

export default withTranslation()(TemplatesTableDialog);
