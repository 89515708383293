import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import SpecializationService from '../../services/SpecializationService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import YesNoDialog from '../../components/YesNoDialog';
import {t} from 'i18next';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
import {CookiesConstants} from '../../utils/CookiesConstants';
import DialogResizer from '../../components/DialogResizer';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';

class SpecializationContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new SpecializationService());
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.showExitConfirmationDialog = true;
        this.state = {
            loading: true,
            elementId: props.params.id,
            element: {
                id: undefined,
                name: '',
                videoChatFee: '',
                mailingFee: '',
                chatFee: '',
            },
            dialogVisibile: false,
            CategoryDefinition: {},
        };
        this.handleDeleteSpecialization = this.handleDeleteSpecialization.bind(this);
        this.handleEditSpecialization = this.handleEditSpecialization.bind(this);
        this.showDeleteSpecializationConfirmation = this.showDeleteSpecializationConfirmation.bind(this);
        this.hideDeleteSpecialziationConfirmation = this.hideDeleteSpecialziationConfirmation.bind(this);
    }

    getContainerListName() {
        return 'specialization-list-container';
    }

    updateElement(data) {
        this.setState(
            {
                element: {
                    id: data.id,
                    name: data.name,
                    videoChatFee: data.videoChatFee,
                    mailingFee: data.mailingFee,
                    chatFee: data.chatFee,
                },
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    prepareEditButton(rendered, label) {
        const {editUrl} = this.props;
        const {element} = this.state;
        return {
            label: label !== undefined ? label : 'Edytuj',
            type: 'BUTTON',
            variant: '',
            onClick: this.saveCookie(CookiesConstants.DETAILS_VIEW_MODE, 'EDIT'),

            href: AppPrefixUtils.locationHrefUrl(`${editUrl}/${element.id}`),
            rendered: rendered,
            // iconName: 'mdi-pencil',
            iconSide: 'left',
            iconSize: 'm',
        };
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('specialization.updateSuccessfull');
    }

    getRemoveSucces() {
        const {t} = this.props;
        return t('specialization.deleteSuccessfull');
    }

    handleEditSpecialization() {
        const {element} = this.state;
        const {backUrl} = this.props;
        this.scrollToTop();
        if (this._isMounted) {
            this.service
                .update(element)
                .then((response) => {
                    this.blockUi();
                    this.persistMessage('success', '', this.getUpdateSucces(response));
                    window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
                })
                .catch((err) => {
                    this.messages.show({
                        severity: 'error',
                        life: 30000,
                        summary: 'Błąd',
                        detail: err.message,
                    });
                });
        }
    }

    handleDeleteSpecialization() {
        const {element} = this.state;
        const {backUrl} = this.props;
        this.scrollToTop();
        if (this._isMounted) {
            this.service
                .remove(element)
                .then((response) => {
                    this.blockUi();
                    this.persistMessage('success', '', this.getRemoveSucces(response));
                    window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
                })
                .catch((err) => {
                    this.messages.show({
                        severity: 'error',
                        life: 30000,
                        summary: 'Błąd',
                        detail: err.message,
                    });
                });
        }
    }
    prepareHeaderItems() {
        const {viewMode} = this.props;
        return [
            {
                label: 'Usuń',
                variant: 'button cancel-button gray',
                onClick: this.showDeleteSpecializationConfirmation,
                rendered: viewMode === 'VIEW',
            },
            this.prepareEditButton(viewMode === 'VIEW'),
        ];
    }

    prepareFooterItems() {
        const {cancelUrl, viewMode} = this.props;
        const {elementId} = this.props;
        let goBackUrl = `${cancelUrl}/${elementId}`;

        return [
            {
                variant: 'accent',
                label: 'Zapisz',
                className: 'float-right',
                onClick: this.handleEditSpecialization,
                rendered: viewMode === 'NEW' || viewMode === 'EDIT',
            },
            {
                variant: 'button gray',
                label: 'Anuluj',
                href: AppPrefixUtils.locationHrefUrl(goBackUrl),
                rendered: viewMode === 'NEW' || viewMode === 'EDIT',
            },
        ];
    }

    getBackLabel() {
        return 'Wróć do listy specjalizacji';
    }

    showDeleteSpecializationConfirmation() {
        this.setState({
            dialogVisibile: true,
        });
    }

    hideDeleteSpecialziationConfirmation() {
        this.setState({
            dialogVisibile: false,
        });
    }

    render() {
        return (
            <React.Fragment>
                <YesNoDialog
                    className='small-dialog'
                    visible={this.state.dialogVisibile}
                    header={t('specialization.deleteHeader')}
                    name='visible'
                    onChange={(type, x, target) => {
                        if (target.value) {
                            this.handleDeleteSpecialization();
                            this.hideDeleteSpecialziationConfirmation();
                        } else {
                            this.hideDeleteSpecialziationConfirmation();
                        }
                    }}
                    onHide={() => {
                        this.hideDeleteSpecialziationConfirmation();
                        DialogResizer.removeMaskIfExistOneDialog();
                    }}
                >
                    Czy na pewno chcesz usunąć specjalizację?
                </YesNoDialog>

                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                {this.renderView()}
            </React.Fragment>
        );
    }
    breadCrumbItems() {
        return [
            {name: 'Specjalizacje', url: '/#/specialization-list'},
            {name: 'Specjalizacja', url: `/#/specialization/details/${this.state.element?.id}`},
        ];
    }
    renderDetails() {
        const {viewMode} = this.props;
        return (
            <React.Fragment>
                {this.renderProperStructureForDetail(
                    <>
                        <div className='row'>
                            <InputTextComponent
                                id='name'
                                name='name'
                                label={'Nazwa specjalizacji'}
                                //colClass='col-md-6'
                                value={this.state.element.name}
                                validator={this.validator}
                                onChange={this.handleChange}
                                viewMode={viewMode}
                            />
                            <InputNumberComponent
                                id='chatFee'
                                name='chatFee'
                                label={'Cena za czat'}
                                // colClass='col-md-6'
                                locale='pl-PL'
                                currency='PLN'
                                mode='currency'
                                value={this.state.element.chatFee}
                                validator={this.validator}
                                onChange={this.handleChange}
                                viewMode={viewMode}
                            />
                            <InputNumberComponent
                                id='videoChatFee'
                                name='videoChatFee'
                                label={'Cena za rozmowę wideo'}
                                // colClass='col-md-6'
                                locale='pl-PL'
                                currency='PLN'
                                mode='currency'
                                value={this.state.element.videoChatFee}
                                validator={this.validator}
                                onChange={this.handleChange}
                                viewMode={viewMode}
                            />
                            <InputNumberComponent
                                id='mailingFee'
                                name='mailingFee'
                                label={'Cena mailingu'}
                                // colClass='col-md-6'
                                locale='pl-PL'
                                currency='PLN'
                                mode='currency'
                                value={this.state.element.mailingFee}
                                validator={this.validator}
                                onChange={this.handleChange}
                                viewMode={viewMode}
                            />
                        </div>
                    </>
                )}
            </React.Fragment>
        );
    }
}

SpecializationContainer.defaultProps = {
    backUrl: '/#/specialization-list',
    editUrl: '/#/specialization/edit',
    detailUrl: '/#/specialization/details',
    cancelUrl: '/#/specialization/details',
};

SpecializationContainer.propTypes = {
    backUrl: PropTypes.string,
};

export default withTranslation()(SpecializationContainer);
