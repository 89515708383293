import {FilterMatchMode} from 'primereact/api';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {ActionButton} from '../../components/ActionButton';
import CustomDataTable from '../../components/CustomDataTable';
import CustomLabel from '../../components/CustomLabel';
import {CustomMessages} from '../../components/CustomMessages';
import DivContainer from '../../components/DivContainer';
import TemplateService from '../../services/TemplateService';
import DialogResizer from '../../components/DialogResizer';
import PrimiAccordion from '../../components/inputs/PrimiAccordion';

class DuplicateTemplateDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new TemplateService());
        this.state = {
            viewMode: this.props.viewMode,
            activeIndex: 0,
            filterName: '',
            filterCategory: '',
            filterSpecialization: '',
            filters: {
                global: {value: null, matchMode: FilterMatchMode.CONTAINS},
                name: {value: null, matchMode: FilterMatchMode.CONTAINS},
                'category.name': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'specialization.name': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
        };
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button gray'}
                    handleClick={this.props.onHide}
                    rendered={this.state.activeIndex === 0}
                />

                <ActionButton
                    label={
                        this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TEMPLATE_MANAGE')
                            ? 'Utwórz'
                            : 'Utwórz propozycję'
                    }
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                />
            </div>
        );
    }
    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        //todo paździerz
        if (this.props.visible) {
            DialogResizer.recalculateDialog();
        }
    }
    renderHeader() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>Wybierz szablon</label>
                </DivContainer>
            </DivContainer>
        );
    }
    initAfterSetElement() {
        setTimeout(function () {
            DialogResizer.recalculateMask();
        }, 1000);

        this.unblockUi();
    }
    initBeforeSetElement() {
        if (this.props.agreementTemplateType) {
            this.service
                .getAllForDuplication(this.props.agreementTemplateType)
                .then((templates) => {
                    this.setState({
                        templates,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.showErrorMessage('Nie udało się pobrać szablonów');
                });
        }
    }
    getContainerListName() {
        return 'subscription-list';
    }

    getAddSucces() {
        return 'Pakiet został dodany';
    }

    getUpdateSucces() {
        return 'Pakiet został zaktualizowany';
    }

    handleFormSubmit() {
        const {selectedTemplate} = this.state;

        if (!selectedTemplate) {
            this.showErrorMessage('Nie wybrano szablonu');
            return;
        } else {
            if (this.validator.allValid()) {
                if (this.props._isMounted) {
                    this.service
                        .duplicateTemplate(selectedTemplate.id)
                        .then((response) => {
                            if (this.props.afterSave) {
                                this.props.afterSave(response);
                            }
                        })
                        .catch((err) => {
                            if (this.props.afterError) {
                                this.props.afterError(err.message);
                            }
                        });
                }
            } else {
                this.validator.showMessages();
                // this.scrollToError = true;
                this.forceUpdate();
            }
        }
    }

    handleChangeStep(e, backStep) {
        /** backStep może zawierac obiekt, dlatego tutaj sprawdzenie backStep===true */
        if (backStep === true) {
            e.index--;
            this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
        } else {
            if (e.index === 1) {
                let typesValidation = this.showResponseFromCustomValidator();
                if (typesValidation) return;
                if (this.state.element?.packageType?.enumValue === 'INDIVIDUAL' && this.state.subscribe.enumValue === undefined) {
                    this.showErrorMessage('Wybierz subskrypcję');
                    return;
                }
            }

            e.index++;

            if (this.validator.allValid()) {
                this.validator.hideMessages();
                this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
            } else {
                this.validator.showMessages();
                // this.scrollToError = true;
                this.forceUpdate();
            }
        }
    }

    renderDetails() {
        return (
            <div>
                {this.renderThirdStep()}
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)}></CustomMessages>
            </div>
        );
    }

    onGlobalFilterChange(e, type) {
        const value = e.target.value;
        let filters = {...this.state.filters};
        filters[type].value = value;
        switch (type) {
            case 'name':
                this.setState({filters, filterName: value});
                break;
            case 'category.name':
                this.setState({filters, filterCategory: value});
                break;
            case 'specialization.name':
                this.setState({filters, filterSpecialization: value});
                break;
            default:
                console.log(`no opt`);
        }
    }

    renderDataTemplateHeader() {
        return (
            <PrimiAccordion
                body={
                    <div className='col-12 p-2 '>
                        <div className='row' style={{justifyContent: 'space-evenly'}}>
                            <InputText
                                className='col-lg-4'
                                value={this.state.filterName}
                                onChange={(e) => this.onGlobalFilterChange(e, 'name')}
                                placeholder='Wpisz nazwę '
                            />
                            <InputText
                                className='col-lg-4'
                                value={this.state.filterCategory}
                                onChange={(e) => this.onGlobalFilterChange(e, 'category.name')}
                                placeholder='Wpisz kategorię'
                            />
                            <InputText
                                className='col-lg-4'
                                value={this.state.filterSpecialization}
                                onChange={(e) => this.onGlobalFilterChange(e, 'specialization.name')}
                                placeholder='Wpisz specjalizację'
                            />
                        </div>
                    </div>
                }
            ></PrimiAccordion>
        );
    }

    onSelectionChange(event) {
        const value = event.value;
        this.setState({
            selectedTemplate: value,
        });
    }

    renderThirdStep() {
        return (
            <React.Fragment>
                <CustomLabel colClass='col-12' label='Dostępne szablony' />
                <div className='datatable-responsive horizontal-table'>
                    {this.renderDataTemplateHeader()}
                    <CustomDataTable
                        rows={4}
                        paginator
                        value={this.state.templates ? this.state.templates : null}
                        selection={this.state.selectedTemplate}
                        onSelectionChange={this.onSelectionChange}
                        emptyMessage='Brak rekordów do wyświetlenia'
                        globalFilterFields={['name', 'category.name', 'specialization.name']}
                        dataKey='id'
                        currentPageReportTemplate={this.state.selectedTemplate}
                        loading={this.state.loading}
                        filters={this.state.filters}
                        filterDisplay='menu'
                        className='p-datatable-responsive'
                    >
                        <Column selectionMode='single' />
                        <Column field='id' header='ID' />
                        <Column field='name' header='Tytuł szablonu' />
                        <Column field='description' header='Opis' />
                        <Column field='category.name' header='Kategoria' />
                        <Column field='specialization.name' header='Specjalizacja' />
                    </CustomDataTable>
                </div>
            </React.Fragment>
        );
    }
}

DuplicateTemplateDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
};

DuplicateTemplateDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
};

export default withTranslation()(DuplicateTemplateDialog);
