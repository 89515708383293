import PropTypes from 'prop-types';
import React from 'react';
import InputNumberComponent from '../InputNumberComponent';

class InputConsultantComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.props.rendered) {
            return (
                <React.Fragment>
                    <InputNumberComponent
                        id='chatFee'
                        name='chatFee'
                        label={'Godzinowa stawka za konsultacje czatu'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                        locale='pl-PL'
                        currency='PLN'
                        mode='currency'
                        value={this.props.element.chatFee}
                        validator={this.props.validator}
                        validators='required|max:4'
                        placeholder={'Wpisz stawke'}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputNumberComponent
                        id='videoChatFee'
                        name='videoChatFee'
                        locale='pl-PL'
                        currency='PLN'
                        mode='currency'
                        label={'Godzinowa stawka za konsultacje wideo'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                        value={this.props.element.videoChatFee}
                        placeholder={'Wpisz stawke'}
                        validator={this.props.validator}
                        validators='required|max:4'
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputNumberComponent
                        id='mailingFee'
                        name='mailingFee'
                        locale='pl-PL'
                        placeholder={'Wpisz stawke'}
                        currency='PLN'
                        mode='currency'
                        label={!this.props.dialogMode && 'Stawka za konsultacje mailingu'}
                        customLabel={
                            this.props.dialogMode && (
                                <label className='easy_label p-label'>
                                    {' Stawka za konsultacje mailingu'} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </label>
                            )
                        }
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                        value={this.props.element.mailingFee}
                        validator={this.props.validator}
                        validators='required|max:4'
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                    />
                </React.Fragment>
            );
        } else {
            return <React.Fragment />;
        }
    }
}

InputConsultantComponent.defaultProps = {
    rendered: true,
    dialogMode: true,
};

InputConsultantComponent.propTypes = {
    element: PropTypes.object.isRequired,
    rendered: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    dialogMode: PropTypes.bool,
};

export default InputConsultantComponent;
