import {Checkbox} from 'primereact/checkbox';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import MenuItemIcon from '../MenuItemIcon';
import {withTranslation} from 'react-i18next';
import {Tooltip} from 'primereact/tooltip';

class InputCheckboxComponent extends Component {
    renderView() {
        const {
            colClass,
            id,
            insideTable,
            label,

            negated,
            objectId,
            publicMode,
            showLabel,
            titleElement,
            titleElementClass,
            titleElementShowId,
            value,
            validateViewMode,
            validator,
            validators,
            labelClassName,
            showDescription,
            description,
            descriptionClassName,
            iconName,
            iconSide,
            iconSize,
            iconSvg,
            renderLabel,
        } = this.props;
        return publicMode ? (
            <div className='input_easy_label row pl-0'>
                {renderLabel ? (
                    renderLabel()
                ) : label !== undefined && showLabel ? (
                    <label id={`${id}-label-id`} className='easy_label col-lg-2 col-md-3' htmlFor={id}>
                        {iconSide === 'left' && iconName !== undefined ? <i alt='' className={`icon mdi ${iconName} ${iconSize}`} /> : null}
                        {iconSide === 'left' && iconSvg !== undefined ? (
                            <MenuItemIcon id={`${iconSvg}`} alt='' className={`icon-svg ${iconSize} ${iconSide}`} iconSvg={iconSvg} />
                        ) : null}
                        {label}
                        {iconSide === 'right' && iconName !== undefined ? (
                            <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                        ) : null}
                        {iconSide === 'right' && iconSvg !== undefined ? (
                            <MenuItemIcon id={iconSvg} alt='' className={`icon-svg ${iconSize} ${iconSide}`} iconSvg={iconSvg} />
                        ) : null}
                    </label>
                ) : null}
                {description !== undefined && showDescription ? (
                    <div>
                        <label htmlFor={`${id}-input-${description}`} className={descriptionClassName}>
                            {description}
                        </label>
                    </div>
                ) : null}
                <div className='col-md-5'>
                    <span
                        aria-label={label}
                        aria-labelledby={`${id}-label-id`}
                        className={`p-inputtext-view${titleElement ? ' title-element' : ''}`}
                    >
                        {negated ? (!value ? 'TAK' : 'NIE') : value ? 'TAK' : 'NIE'}
                    </span>
                    {titleElement && titleElementShowId ? <span className='p-inputtext-view'>{` ID: ${objectId}`}</span> : null}
                    {validateViewMode && validator ? validator.message(id, label, negated ? !value : value, validators) : null}
                </div>
            </div>
        ) : (
            <div className={insideTable ? '' : titleElement ? titleElementClass : colClass}>
                <div className={insideTable ? '' : 'row'}>
                    <div className={insideTable ? '' : 'col-md-12 form-group checkbox'}>
                        {renderLabel ? (
                            renderLabel()
                        ) : label !== undefined && showLabel && !titleElement ? (
                            <label
                                id={`${id}-label-id`}
                                className={
                                    labelClassName && labelClassName !== ''
                                        ? `${labelClassName} p-checkbox-label p-label`
                                        : 'p-checkbox-label p-label'
                                }
                                htmlFor={id}
                                style={{width: '100%'}}
                            >
                                {iconSide === 'left' && iconName !== undefined ? (
                                    <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                                ) : null}
                                {iconSide === 'left' && iconSvg !== undefined ? (
                                    <MenuItemIcon id={iconSvg} alt='' className={`icon-svg ${iconSize} ${iconSide}`} iconSvg={iconSvg} />
                                ) : null}
                                {label}
                                {iconSide === 'right' && iconName !== undefined ? (
                                    <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                                ) : null}
                                {iconSide === 'right' && iconSvg !== undefined ? (
                                    <MenuItemIcon id={iconSvg} alt='' className={`icon-svg ${iconSize} ${iconSide}`} iconSvg={iconSvg} />
                                ) : null}
                            </label>
                        ) : null}
                        {description !== undefined && showDescription ? (
                            <div>
                                <label htmlFor={`${id}-input-${description}`} className={descriptionClassName}>
                                    {description}
                                </label>
                            </div>
                        ) : null}
                        <span
                            aria-label={label}
                            aria-labelledby={`${id}-label-id`}
                            className={`p-inputtext-view${titleElement ? ' title-element' : ''}`}
                        >
                            {negated ? (!value ? 'TAK' : 'NIE') : value ? 'TAK' : 'NIE'}
                        </span>
                        {titleElement && titleElementShowId ? <span className='p-inputtext-view'>{` ID: ${objectId}`}</span> : null}
                        {validateViewMode && validator ? validator.message(id, label, negated ? !value : value, validators) : null}
                    </div>
                </div>
            </div>
        );
    }

    renderEdit() {
        return this.renderNew();
    }

    renderNew() {
        const {
            colClass,
            disabled,
            id,
            insideTable,
            label,
            renderRequired,
            labelOnRight,
            labelLeftStyle,
            name,
            negated,
            onAfterStateChange,
            onChange,
            publicMode,
            showLabel,
            stateField,
            validator,
            overideLabelClassName,
            validators,
            value,
            labelClassName,
            showDescription,
            description,
            descriptionClassName,
            transparentBorder,
            renderLink,
            grayBorder,
            iconName,
            iconSide,
            iconSize,
            iconSvg,
            renderLabel,
            tooltip,
        } = this.props;
        const required = validators !== undefined && validators.includes('required') && !validators.includes('not_required');
        return publicMode ? (
            <div className='input_easy_label row pl-0'>
                {renderLabel ? (
                    renderLabel()
                ) : label !== undefined && showLabel ? (
                    <label id={`${id}-label-id`} className='easy_label col-lg-2 col-md-3' htmlFor={`${id}-input`}>
                        {iconSide === 'left' && iconName !== undefined ? <i alt='' className={`icon mdi ${iconName} ${iconSize}`} /> : null}
                        {iconSide === 'left' && iconSvg !== undefined ? (
                            <div>
                                <MenuItemIcon id={iconSvg} alt='' className={`icon-svg ${iconSize} ${iconSide}`} iconSvg={iconSvg} />
                            </div>
                        ) : null}
                        {label}
                        {iconSide === 'right' && iconName !== undefined ? (
                            <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                        ) : null}
                        {iconSide === 'right' && iconSvg !== undefined ? (
                            <MenuItemIcon id={iconSvg} alt='' className={`icon-svg ${iconSize} ${iconSide}`} iconSvg={iconSvg} />
                        ) : null}
                    </label>
                ) : null}
                {description !== undefined && showDescription ? (
                    <div>
                        <label htmlFor={`${id}-input-${description}`} className={descriptionClassName}>
                            {description}
                        </label>
                    </div>
                ) : null}
                <div className='col-md-5'>
                    <Checkbox
                        aria-label={label}
                        aria-labelledby={label === undefined && showLabel ? `${id}-label-id` : undefined}
                        key={id}
                        id={id}
                        inputId={`${id}-input`}
                        name={name}
                        onChange={(e) => (onChange ? onChange('CHECKBOX', [negated], e, onAfterStateChange, stateField) : null)}
                        checked={negated ? !value : value}
                        disabled={disabled || this.viewLikeDisabledRendering()}
                        required={required}
                    />
                    <div aria-live='assertive'>{validator ? validator.message(id, label, negated ? !value : value, validators) : null}</div>
                </div>
            </div>
        ) : (
            <div className={insideTable ? '' : colClass}>
                {/* {tooltip ? <Tooltip target={`#${id}-label-id`} /> : null} */}
                <div className={insideTable ? '' : 'row'}>
                    <div className={insideTable ? '' : 'col-md-12 form-group checkbox'}>
                        {renderLabel ? (
                            renderLabel()
                        ) : label !== undefined && showLabel && !labelOnRight ? (
                            <label
                                id={`${id}-label-id`}
                                className={
                                    labelClassName && labelClassName !== ''
                                        ? `${labelClassName} p-checkbox-label p-label`
                                        : 'p-checkbox-label p-label'
                                }
                                htmlFor={`${id}-input`}
                                style={{width: '100%'}}
                                data-pr-tooltip={tooltip ? tooltip : ''}
                            >
                                {iconSide === 'left' && iconName !== undefined ? (
                                    <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                                ) : null}
                                {iconSide === 'left' && iconSvg !== undefined ? (
                                    <MenuItemIcon id={iconSvg} alt='' className={`icon-svg ${iconSize} ${iconSide}`} iconSvg={iconSvg} />
                                ) : null}
                                {label}
                                {iconSide === 'right' && iconName !== undefined ? (
                                    <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                                ) : null}
                                {iconSide === 'right' && iconSvg !== undefined ? (
                                    <MenuItemIcon id={iconSvg} alt='' className={`icon-svg ${iconSize} ${iconSide}`} iconSvg={iconSvg} />
                                ) : null}
                            </label>
                        ) : null}
                        <div className='checkbox-wrapper'>
                            <Checkbox
                                aria-label={label}
                                aria-labelledby={label === undefined && showLabel ? `${id}-label-id` : undefined}
                                key={id}
                                id={id}
                                inputId={`${id}-input`}
                                name={name}
                                onChange={(e) => (onChange ? onChange('CHECKBOX', [negated], e, onAfterStateChange, stateField) : null)}
                                checked={negated ? !value : value}
                                disabled={disabled || this.viewLikeDisabledRendering()}
                                required={required}
                            />

                            {label !== undefined && showLabel && labelOnRight ? (
                                <label
                                    id={`${id}-label-id`}
                                    htmlFor={`${id}-input`}
                                    className={
                                        overideLabelClassName
                                            ? overideLabelClassName
                                            : labelClassName && labelClassName !== ''
                                            ? `${labelClassName} p-checkbox-label p-label ${
                                                  transparentBorder ? 'transparent-border' : ''
                                              } ${grayBorder ? 'gray-border' : ''}`
                                            : `p-checkbox-label p-label ${transparentBorder ? 'transparent-border' : ''} ${
                                                  grayBorder ? 'gray-border' : ''
                                              }`
                                    }
                                    style={labelLeftStyle}
                                    data-pr-tooltip={tooltip ? tooltip : ''}
                                >
                                    {iconSide === 'left' && iconName !== undefined ? (
                                        <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                                    ) : null}
                                    {iconSide === 'left' && iconSvg !== undefined ? (
                                        <MenuItemIcon
                                            id={iconSvg}
                                            alt=''
                                            className={`icon-svg ${iconSize} ${iconSide}`}
                                            iconSvg={iconSvg}
                                        />
                                    ) : null}
                                    {renderLink !== undefined ? (
                                        <div style={{marginTop: '-17px', position: 'absolute'}}>
                                            <a href={renderLink} target='_blank' rel='noopener noreferrer'>
                                                {' '}
                                                {renderRequired === true ? (
                                                    <span>
                                                        {' '}
                                                        {label}
                                                        <span className='text-danger'>* </span>
                                                    </span>
                                                ) : (
                                                    label
                                                )}{' '}
                                            </a>
                                        </div>
                                    ) : (
                                        <div>
                                            {renderRequired === true ? (
                                                <span>
                                                    {' '}
                                                    {label} <span className='text-danger'>*</span>
                                                    <br></br>
                                                </span>
                                            ) : (
                                                label
                                            )}
                                        </div>
                                    )}

                                    {iconSide === 'right' && iconName !== undefined ? (
                                        <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                                    ) : null}
                                    {iconSide === 'right' && iconSvg !== undefined ? (
                                        <MenuItemIcon
                                            id={iconSvg}
                                            alt=''
                                            className={`icon-svg ${iconSize} ${iconSide}`}
                                            iconSvg={iconSvg}
                                        />
                                    ) : null}
                                </label>
                            ) : null}
                        </div>
                        {description !== undefined && showDescription ? (
                            <div>
                                <label htmlFor={`${id}-input-${description}`} className={descriptionClassName}>
                                    {description}
                                </label>
                            </div>
                        ) : null}
                        <div aria-live='assertive'>
                            {validator ? validator.message(id, label, negated ? !value : value, validators) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    viewLikeDisabledRendering() {
        const {viewLikeDisabled, viewMode} = this.props;
        return viewLikeDisabled && viewMode === 'VIEW';
    }
    render() {
        const {rendered, viewMode, viewLikeDisabled} = this.props;
        if (rendered) {
            switch (viewMode) {
                case 'NEW':
                    return this.renderNew();
                case 'EDIT':
                    return this.renderEdit();
                case 'VIEW':
                    return !!viewLikeDisabled ? this.renderEdit() : this.renderView();
                default:
                    return this.renderView();
            }
        } else {
            return null;
        }
    }
}

InputCheckboxComponent.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    disabled: false,
    insideTable: false,
    labelOnRight: false,
    objectId: '',
    publicMode: false,
    rendered: true,
    showLabel: true,
    stateField: 'element',
    titleElement: false,
    titleElementClass: 'col-md-12',
    titleElementShowId: false,
    validateViewMode: false,
    validators: 'required|boolean',
    viewMode: 'VIEW',
    labelClassName: '',
    overideLabelClassName: undefined,
    showDescription: false,
    renderRequired: false,
    renderLink: undefined,
    descriptionClassName: 'p-checkbox-label',
    transparentBorder: false,
    grayBorder: false,
    iconSide: 'left',
    viewLikeDisabled: false,
};

InputCheckboxComponent.propTypes = {
    colClass: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    insideTable: PropTypes.bool,
    label: PropTypes.string,
    labelOnRight: PropTypes.bool,
    labelLeftStyle: PropTypes.object,
    name: PropTypes.string.isRequired,
    negated: PropTypes.bool,
    objectId: PropTypes.string,
    onAfterStateChange: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    publicMode: PropTypes.bool,
    rendered: PropTypes.bool,
    showLabel: PropTypes.bool,
    stateField: PropTypes.string,
    titleElement: PropTypes.bool,
    titleElementClass: PropTypes.string,
    titleElementShowId: PropTypes.bool,
    validateViewMode: PropTypes.bool,
    validator: PropTypes.object.isRequired,
    validators: PropTypes.string,
    value: PropTypes.bool,
    renderRequired: PropTypes.bool,
    viewMode: PropTypes.string,
    labelClassName: PropTypes.string,
    overideLabelClassName: PropTypes.string,
    description: PropTypes.string,
    showDescription: PropTypes.bool,
    renderLink: PropTypes.string,
    descriptionClassName: PropTypes.string,
    iconName: PropTypes.string,
    iconSide: PropTypes.string,
    iconSize: PropTypes.string,
    iconSvg: PropTypes.string,
};

export default withTranslation()(InputCheckboxComponent);
