import BaseServiceCrud from './BaseServiceCrud';

export default class EventCalendarService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'event-calendar';
    }

    getCriteria(criteria) {
        return this.objToQueryString({
            status: criteria.status?.enumValue,
            first_result: criteria.firstResult,
            max_results: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            consultant: criteria.consultant,
        });
    }

    add(element) {
        return this.fetch(`${this.domain}/${this.path}/addConsultation/`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    update(element) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/updateConsultation/${element.id}`, {
            method: 'PUT',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getEventList(date, periodType) {
        return this.fetch(`${this.domain}/${this.path}/events-by-date/${date}/${periodType}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getConsultationAvailabilityBySettingsValues() {
        return this.fetch(`${this.domain}/${this.path}/consultation-availability-by-settings-values`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getEventsForClient(userIds, specializationId, date) {
        return this.fetch(`${this.domain}/${this.path}/confirmed-events-for-client/${userIds}/${specializationId}/${date}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getEventListByUserId(id, date, periodType) {
        return this.fetch(`${this.domain}/${this.path}/events-by-userId/${id}/${date}/${periodType}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getAllConsultationsByUserId(id) {
        return this.fetch(`${this.domain}/${this.path}/required-approvals-by-userId/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    acceptCheckedCosnultations(ids) {
        return this.fetch(`${this.domain}/${this.path}/accept-checked`, {
            method: 'POST',
            body: JSON.stringify(ids),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    addEvents(element) {
        return this.fetch(`${this.domain}/${this.path}/events/`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    changeStatusAndSendMessage(element) {
        return this.fetch(`${this.domain}/${this.path}/reject-message`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
