import {Link} from 'react-router-dom';
import ActionLink from '../../components/ActionLink';
import {useEffect, useState} from 'react';
import ReminderContainer from '../ReminderContainer';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import $ from 'jquery';

export default function CollapsableLexyNavbar(props) {
    const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 1200);
    const [isCollapsedShow, setCollapsedShow] = useState(false);

    useEffect(() => {
        return window.addEventListener(
            'resize',
            () => {
                const isCollapsedCurrent = window.innerWidth < 1200;
                if (!isCollapsedCurrent && isCollapsedShow) setCollapsedShow(false);
                if (isCollapsed !== isCollapsedCurrent) setIsCollapsed(isCollapsedCurrent);
            },
            false
        );
    }, [isCollapsed]);

    useEffect(() => {
        const handleClick = (e) => {
            if (!$(e.target).hasClass('navbar-toggler') && !$(e.target).hasClass('navbar-nav.mobile')) {
                setCollapsedShow(false);
            }
        };
        $('body').on('click', handleClick);

        return () => {
            $('body').off('click', handleClick);
        };
    }, [setCollapsedShow]);

    return (
        <nav className='navbar '>
            <div className='navbar-brand'>
                <img className='img-fluid img-lexy' src={`https://leksy.pl/wp-content/uploads/2023/09/Group_1@2x1.png`} alt='Logo Lexy' />
            </div>
            {!!props.loggedIn ? (
                <div
                    className={`navbar-collapse-custom dual-collapse order-0 ${
                        isCollapsed ? `collapse ${isCollapsedShow ? 'show' : ''}` : ''
                    }`}
                >
                    <ul className='navbar-nav ml-auto'>
                        <li className='nav-item show-collapsed-row'>
                            <Link className='nav-link' to={`/manage-account/details/${props.userId}`}>
                                <i alt='' className={`icon mdi mdi-account`} />
                            </Link>
                            <Link className='nav-link reminder-link'>
                                <ReminderContainer
                                    rel='noopener noreferrer'
                                    className='header-link'
                                    hideNavbar={() => {
                                        setCollapsedShow(!isCollapsedShow);
                                    }}
                                />
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <ActionLink className='nav-link' href={`${props.cmsUrl}/Kontakt`} label='Kontakt'></ActionLink>
                        </li>
                        <li className='nav-item'>
                            <ActionLink
                                className='nav-link'
                                href={AppPrefixUtils.locationHrefUrl('/#/order-list')}
                                label='Zamówienia'
                            ></ActionLink>
                        </li>
                        <li className='nav-item'>
                            <ActionLink
                                className='nav-link'
                                href={AppPrefixUtils.locationHrefUrl('/#/scheduled-event-calendar')}
                                label='Zaplanowane konsultacje'
                            ></ActionLink>
                        </li>
                    </ul>
                </div>
            ) : (
                <div
                    className={`navbar-collapse-custom dual-collapse order-0 ${
                        isCollapsed ? `collapse ${isCollapsedShow ? 'show' : ''}` : ''
                    }`}
                >
                    <ul className='navbar-nav ml-auto' style={{justifyContent: 'space-evenly'}}>
                        <li className='nav-item'>
                            <ActionLink className='nav-link' href={`${props.cmsUrl}/`} label='Strona główna'></ActionLink>
                        </li>
                        <li className='nav-item '>
                            <Link className='nav-link' to='/public/template-list'>
                                Kreator dokumentów
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <ActionLink className='nav-link' href={`${props.cmsUrl}/faq`} label='FAQ'></ActionLink>
                        </li>
                        <li className='nav-item'>
                            <ActionLink className='nav-link' href={`${props.cmsUrl}/blog`} label='Blog'></ActionLink>
                        </li>

                        <li className='nav-item'>
                            <ActionLink className='nav-link' href={`${props.cmsUrl}/Kontakt`} label='Kontakt'></ActionLink>
                        </li>
                        <li className='nav-item '>
                            <Link className='nav-link nav-link-login' to={`/login`}>
                                <img src='https://leksy.pl/wp-content/uploads/2023/09/Component-9-%E2%80%93-15-1.svg' alt=''></img>
                            </Link>
                        </li>
                        {/* {/* </li> */}
                        {/* <div style={{float: 'right', justifyContent: 'space-evednly'}}>ds</div> */}
                    </ul>
                </div>
            )}
            {!!props.loggedIn && (
                <div
                    className={`navbar-collapse-custom dual-collapse order-1 ${
                        isCollapsed ? `collapse ${isCollapsedShow ? 'show' : ''}` : ''
                    }`}
                >
                    <ul className='navbar-nav mr-auto justify-content-end'>
                        <li className='nav-item '>
                            <Link className='nav-link' to='/template-list'>
                                Szablony
                            </Link>
                        </li>
                        {props.generatedDocumentAvailable ? (
                            <li className='nav-item'>
                                <Link className='nav-link' to='/agreement-list'>
                                    Moje dokumenty
                                </Link>
                            </li>
                        ) : null}
                        <li className='nav-item'>
                            <Link className='nav-link' to='' onClick={props.logout}>
                                Wyloguj
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
            <button
                className={`navbar-toggler ${isCollapsed ? 'collapsed' : ''}`}
                type='button'
                data-toggle='collapse'
                data-target='.dual-collapse'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
                onClick={(e) => {
                    setCollapsedShow(!isCollapsedShow);
                    e.stopPropagation();
                }}
            >
                <span className='navbar-toggler-icon unlogged'>
                    <i id='hamburger' className='fa fa-navicon'></i>
                </span>
            </button>
            {isCollapsedShow && !!props.loggedIn ? (
                <ul className={`navbar-nav mobile ${!!isCollapsed && !!isCollapsedShow ? 'show' : ''}`}>
                    <li className='nav-item show-collapsed-row'>
                        <Link
                            className='nav-link'
                            to={`/manage-account/details/${props.userId}`}
                            onClick={() => setCollapsedShow(!isCollapsedShow)}
                        >
                            <i alt='' className={`icon mdi mdi-account`} /> <span>Profil</span>
                        </Link>
                        <Link className='nav-link reminder-link'>
                            <ReminderContainer
                                rel='noopener noreferrer'
                                className='header-link'
                                showTitle
                                titleClassName='navbar-title'
                                hideNavbar={() => {
                                    setCollapsedShow(!isCollapsedShow);
                                }}
                            />
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link className='nav-link' to='/contact' onClick={() => setCollapsedShow(!isCollapsedShow)}>
                            Kontakt
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link className='nav-link' to={'/order-list'} onClick={() => setCollapsedShow(!isCollapsedShow)}>
                            Zamówienia
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link className='nav-link' to={'/scheduled-event-calendar'} onClick={() => setCollapsedShow(!isCollapsedShow)}>
                            Zaplanowane konsultacje
                        </Link>
                    </li>
                    <li className='nav-item '>
                        <Link className='nav-link main-color' to='/template-list' onClick={() => setCollapsedShow(!isCollapsedShow)}>
                            Szablony
                        </Link>
                    </li>
                    {props.generatedDocumentAvailable ? (
                        <li className='nav-item'>
                            <Link className='nav-link' to='/agreement-list' onClick={() => setCollapsedShow(!isCollapsedShow)}>
                                Moje dokumenty
                            </Link>
                        </li>
                    ) : null}

                    <li className='nav-item'>
                        <Link className='nav-link ' to='' onClick={props.logout} onClick={() => setCollapsedShow(!isCollapsedShow)}>
                            Wyloguj
                        </Link>
                    </li>
                </ul>
            ) : (
                <ul className={`navbar-nav mobile ${!!isCollapsed && !!isCollapsedShow ? 'show' : ''}`}>
                    <li className='nav-item'>
                        <ActionLink className='nav-link' href={`${props.cmsUrl}/`} label='Strona główna'></ActionLink>
                    </li>
                    <li className='nav-item'>
                        <ActionLink className='nav-link' href={'#/public/template-list'} label='Kreator dokumentów'></ActionLink>
                    </li>

                    <li className='nav-item'>
                        <ActionLink className='nav-link' href={`${props.cmsUrl}/faq`} label='FAQ'></ActionLink>
                    </li>

                    <li className='nav-item'>
                        <ActionLink className='nav-link' href={`${props.cmsUrl}/blog`} label='Blog'></ActionLink>
                    </li>

                    <li className='nav-item'>
                        <ActionLink className='nav-link' href={`${props.cmsUrl}/kontakt`} label='Kontakt'></ActionLink>
                    </li>
                    <li className='nav-item '>
                        <Link className='nav-link ' to='/login' onClick={() => setCollapsedShow(!isCollapsedShow)}>
                            Zaloguj się
                        </Link>
                    </li>
                    <li className='nav-item '>
                        <Link className='nav-link ' to='/registration' onClick={() => setCollapsedShow(!isCollapsedShow)}>
                            Zarejestruj się
                        </Link>
                    </li>
                    {props.footer()}
                </ul>
            )}
        </nav>
    );
}
