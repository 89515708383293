class HtmlParserUtils {
    static insertListToHtml(selectedList, htmlId) {
        const olNode = this.createOlNodeFromList(selectedList);
        HtmlParserUtils.appendChildByAllIds(olNode, htmlId);
    }
    static createOlNodeFromList(list) {
        const olNode = document.createElement('ol');
        if (list?.length) {
            list.forEach((item, index) => {
                const liNode = document.createElement('li');
                const liText = document.createTextNode(item.name);
                liNode.appendChild(liText);
                olNode.appendChild(liNode);
            });
        }
        return olNode;
    }
    static innerHtmlById(textToAdd, htmlId) {
        const elms = document.querySelectorAll(`[id='${htmlId}']`);
        if (elms) {
            for (var i = 0; i < elms.length; i++) {
                elms[i].innerHTML = textToAdd;
            }
        }
    }
    static appendChildByAllIds(node, htmlId) {
        const elms = document.querySelectorAll(`[id='${htmlId}']`);
        if (elms) {
            for (var i = 0; i < elms.length; i++) {
                const element = elms[i];
                element.innerHTML = '';
                element.insertAdjacentHTML('beforeend', node.outerHTML);
            }
        }
    }
    static activateOrHideElement(shoudActivate, htmlId) {
        const elms = document.querySelectorAll(`[id='${htmlId}']`);
        if (elms) {
            for (var i = 0; i < elms.length; i++) {
                if (shoudActivate) {
                    elms[i].classList.add(this.activeClassName());
                    elms[i].classList.remove(this.hiddenClassName());
                } else {
                    elms[i].classList.remove(this.activeClassName());
                    elms[i].classList.add(this.hiddenClassName());
                }
            }
        }
    }
    static insertCopiedRows(htmlRows, parentId) {
        const elms = document.querySelectorAll(`[id='${parentId}']`);
        if (elms) {
            let rowIndex = 0;
            for (var i = 0; i < elms.length; i++) {
                rowIndex = htmlRows.length > i ? i : 0;
                const element = elms[i];
                if(htmlRows?.length> rowIndex && htmlRows[rowIndex]){
                    element.insertAdjacentHTML('beforeend', htmlRows[rowIndex].html);
                }
            }
        }
    }
    static getDynamicRowContent(rowUuid) {
        const elements = document.getElementsByClassName(this.getDynamicRowClassName(rowUuid));
        const result = [];
        if (elements) {
            for (var i = 0; i < elements.length; i++) {
                result.push(elements[i].outerHTML);
            }
        }
        return result;
    }
    static getDynamicRowClassName(rowUuid) {
        return `dynamic-row-${rowUuid}`;
    }
    static removeDynamicRow(rowUuid) {
        document.querySelectorAll(`.${this.getDynamicRowClassName(rowUuid)}`).forEach((el) => el.remove());
    }
    static getCustomTagClassName() {
        return 'se-custom-tag';
    }
    static getCustomTagStepClassName() {
        return 'step-custom-class';
    }
    static inlineCustomTagClassName() {
        return 'custom-tag-inline';
    }
    static hiddenClassName() {
        return 'tag-hidden';
    }
    static activeClassName() {
        return 'tag-active';
    }
    static pointClassNames() {
        return '.se-custom-tag.POINT';
    }
    static isBlockLevelElement(el) {
        var blockRegex =
            /^(address|blockquote|body|br|center|dir|div|dl|fieldset|form|h[1-6]|hr|isindex|menu|noframes|noscript|ol|p|pre|table|ul|dd|dt|frameset|li|tbody|td|tfoot|th|thead|tr|html)$/i;
        return blockRegex.test(el.nodeName?.toLowerCase());
    }
    static removeCustomClassWithTooltipByUUID( htmlId) {
        const elms = document.querySelectorAll(`[id='${htmlId}']`);

        if (elms) {
            for (var i = 0; i < elms.length; i++) {
                elms[i].removeAttribute(HtmlParserUtils.getTooltipAttributeForTemplate());
                elms[i].classList.remove(this.getCustomTagStepClassName());
                elms[i].classList.remove(this.getCustomTagClassName());
            }
        }
    }
    static recalculatePoints(pointReferencesMap, onFinish) {
        const points = this.getAllPointElementFromAllInactiveStepsSection();
        if (points) {
            let pointCounter = 1;
            for (var i = 0; i < points.length; i++) {
                const pointElement = points[i];
                const pointElementId = pointElement.id;
                pointElement.innerHTML = pointCounter;

                this.updatePointReferences(pointReferencesMap, pointElementId, pointCounter);
                if (this.isElementVisible(pointElement)) {
                    pointCounter++;
                }
            }
        }
        onFinish();
    }
    static isElementHidden(node) {
        const isHidden = [...document.querySelectorAll(`.${this.hiddenClassName()}`)].find((el) => el.contains(node));
        return isHidden;
    }

    static isElementVisible(node) {
        const isVisible = !this.isElementHidden(node);
        return isVisible;
    }
    static getAllPointElementFromAllInactiveStepsSection() {
        const allEvenInactiveSteps = [document.querySelectorAll(`.inactive-steps`)];
        return allEvenInactiveSteps[0][0].querySelectorAll(this.pointClassNames());
    }
    static updatePointReferences(pointReferencesMap, pointElementId, pointCounterToSet) {
        const currentPointReferences = pointReferencesMap[pointElementId];
        if (currentPointReferences?.length > 0) {
            for (let j = 0; j < currentPointReferences.length; j++) {
                this.innerHtmlById(pointCounterToSet, currentPointReferences[j]);
            }
        }
    }
    static getTooltipAttributeForTemplate(){
        return 'data-title-tooltip';
    }
    static getParameterRowClassName(uuid){
        return `parameter-row-${uuid}`
    }
    static getHighlightParameterRowClassName(){
        return `parameter-row-highlight`
    }
    static eachParameterRow(){
        return 'parameter-row'
    }
}

export default HtmlParserUtils;
