import $ from 'jquery';
import HtmlParserUtils from './htmlParser/HtmlParserUtils';
import TooltipUtils from './TooltipUtils';
import StringUtils from './StringUtils';
class AgreementTagHighlightUtils {
    static registerHighlightFunction() {
        const highlightFunction = function (e) {
            const currentID = e?.currentTarget?.id;
            const highlightClassName = HtmlParserUtils.getHighlightParameterRowClassName();

            const allRows = [...document.querySelectorAll(`.${HtmlParserUtils.eachParameterRow()}`)];
            allRows.forEach((row) => {
                if (row.classList.contains(HtmlParserUtils.getParameterRowClassName(currentID))) {
                    row.classList.add(highlightClassName);

                    const positionFromTopOfScrollableDiv = row.offsetTop;
                    const scrollableTableElement = [...document.querySelectorAll(`.p-datatable-wrapper`)];
                    if (scrollableTableElement.length > 0) {
                        scrollableTableElement[0].scrollTop = positionFromTopOfScrollableDiv;
                        row.scrollIntoView();
                    }
                } else {
                    row.classList.remove(highlightClassName);
                }
            });
            if (e && !StringUtils.isBlank(currentID)) {
                document.querySelectorAll(`.${TooltipUtils.helpTooltipClassName()}`).forEach((el) => el.remove());
                e.stopPropagation();
            }
        };
        $(function () {
            $('body').on('click', `.${HtmlParserUtils.getCustomTagClassName()}`, function (e) {
                highlightFunction(e);
            });
            $('body').on('click', `.${HtmlParserUtils.getCustomTagStepClassName()}`, function (e) {
                highlightFunction(e);
            });
        });
    }
}
export default AgreementTagHighlightUtils;
