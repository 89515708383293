import PropTypes from 'prop-types';
import BaseContainer from '../../baseContainers/BaseContainer';
import ActionLink from '../../components/ActionLink';
import {ChatHeader} from './Chat';
import UserVideoComponent from './UserVideoComponent';

class Video extends BaseContainer {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.state = {
            minimalized: false,
        };
    }

    handleClose(event, consultationEnded) {
        consultationEnded
            ? this.props.handleCloseAction(this.props.conversationId)
            : this.props.showEndConversationDialog(this.props.conversationId);
    }

    render() {
        return (
            <div className='fixed-bottom'>
                <div className={`${this.isLexyPortal() ? 'lexy' : 'lexdocs'} wrapperVideoChat`}>
                    <ChatHeader
                        onClose={this.handleClose}
                        onMinimalize={(state) => this.setState({minimalized: state})}
                        showReminderDialog={(reminderType) => {
                            if (this.props.type === 'CLIENT') {
                                this.props.showReminderDialog(this.props.conversationId, reminderType);
                            }
                        }}
                        endTime={this.props.endTime}
                        stopTimer={this.props.stopTimer}
                        className='videoChatHeader'
                    />

                    {!this.state.minimalized && (
                        <div>
                            <div className='stream-container col-md-6 col-xs-6 streamcomponent'>
                                <UserVideoComponent streamManager={this.props.publisher} className='smallVideo' />
                                <UserVideoComponent streamManager={this.props.subscriber} className='largeVideo' />
                            </div>

                            {this.props.publisher ? (
                                <div>
                                    <div className='videoBar'>
                                        <ActionLink
                                            iconName='mdi-video-off-outline'
                                            iconSize='mdi-24px'
                                            rendered={!this.props.publisherVideoOn}
                                            handleClick={() => this.props.changePublisherVideoState(true)}
                                        />
                                        <ActionLink
                                            iconName='mdi-video-outline'
                                            iconSize='mdi-24px'
                                            rendered={this.props.publisherVideoOn}
                                            handleClick={() => this.props.changePublisherVideoState(false)}
                                        />
                                        <ActionLink
                                            iconName='mdi-volume-off'
                                            iconSize='mdi-24px'
                                            rendered={!this.props.publisherAudioOn}
                                            handleClick={() => this.props.changePublisherAudioState(true)}
                                        />
                                        <ActionLink
                                            iconName='mdi-volume-high'
                                            iconSize='mdi-24px'
                                            rendered={this.props.publisherAudioOn}
                                            handleClick={() => this.props.changePublisherAudioState(false)}
                                        />
                                        <ActionLink
                                            iconName='mdi-phone-off'
                                            iconSize='mdi-24px'
                                            rendered={this.props.publisherAudioOn}
                                            handleClick={() => this.handleClose()}
                                        />
                                        <ActionLink
                                            rendered={this.props.agreementUuid !== undefined}
                                            label={'Wróć do szablonu'}
                                            key={'back-template-button'}
                                            handleClick={() => {
                                                this.props.backToTemplate(this.props.agreementUuid);
                                            }}
                                            className='pb-2 back-to-template '
                                        />

                                        <div className='text-danger p-2'>
                                            <b className='desc-chat-video-header'>
                                                Konsultacja wideo wymaga udostępnienia kamerki oraz mikrofonu w oknie przeglądarki.
                                            </b>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

Video.defaultProps = {
    publisherVideoOn: true,
    publisherAudioOn: true,
    subscriberVideoOn: true,
    subscriberAudioOn: true,
};

Video.propTypes = {
    handleCloseAction: PropTypes.func,
    publisher: PropTypes.object,
    subscriber: PropTypes.object,
    publisherVideoOn: PropTypes.bool,
    publisherAudioOn: PropTypes.bool,
    subscriberVideoOn: PropTypes.bool,
    subscriberAudioOff: PropTypes.bool,

    changePublisherVideoState: PropTypes.func.isRequired,
    changePublisherAudioState: PropTypes.func.isRequired,

    maxTime: PropTypes.string,
    stopTimer: PropTypes.bool,
};

export default Video;
