import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseContainer from '../baseContainers/BaseContainer';
import {CustomMessages} from '../components/CustomMessages';
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';
import RegistrationService from '../services/RegistrationService';
import BlockUi from '../components/waitPanel/BlockUi';
import {Galleria} from 'primereact/galleria';
import AppPrefixUtils from '../utils/AppPrefixUtils';

class StartLawyerContainer extends BaseContainer {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.registrationService = new RegistrationService();
        this.authService = new AuthService();
        this.userService = new UserService();
        this.templateUrl = '';
        this.templateDescription = '';
        this.state = {
            images: [
                {
                    itemImageSrc: AppPrefixUtils.locationHrefUrl('images/start-images/signing.jpg'),
                    thumbnailImageSrc: AppPrefixUtils.locationHrefUrl('images/start-images/signing.jpg'),
                    alt: 'Description for Image 1',
                    title: 'Title 1',
                },
                {
                    itemImageSrc: AppPrefixUtils.locationHrefUrl('images/start-images/startImage.jpg'),
                    thumbnailImageSrc: AppPrefixUtils.locationHrefUrl('images/start-images/startImage.jpg'),
                    alt: 'Description for Image 1',
                    title: 'Title 1',
                },
                {
                    itemImageSrc: AppPrefixUtils.locationHrefUrl('images/start-images/man-signing2.jpg'),
                    thumbnailImageSrc: AppPrefixUtils.locationHrefUrl('images/start-images/man-signing2.jpg'),
                    alt: 'Description for Image 1',
                    title: 'Title 1',
                },
                {
                    itemImageSrc: AppPrefixUtils.locationHrefUrl('images/start-images/teamwork.jpg'),
                    thumbnailImageSrc: AppPrefixUtils.locationHrefUrl('images/start-images/teamwork.jpg'),
                    alt: 'Description for Image 1',
                    title: 'Title 1',
                },
                {
                    itemImageSrc: AppPrefixUtils.locationHrefUrl('images/start-images/man-signing.jpg'),
                    thumbnailImageSrc: AppPrefixUtils.locationHrefUrl('images/start-images/man-signing.jpg'),
                    alt: 'Description for Image 1',
                    title: 'Title 1',
                },
            ],
        };

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 5,
            },
            {
                breakpoint: '768px',
                numVisible: 3,
            },
            {
                breakpoint: '560px',
                numVisible: 1,
            },
        ];
    }
    itemTemplate(item) {
        return <img src={AppPrefixUtils.locationHrefUrl(item.itemImageSrc)} alt={item.text} style={{width: '100%', display: 'block'}} />;
    }

    thumbnailTemplate(item) {
        return (
            <img src={AppPrefixUtils.locationHrefUrl(`${item.previewImageSrc}`)} alt={item.alt} style={{width: '100%', display: 'block'}} />
        );
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    getContainerListName() {
        return 'start-container';
    }

    render() {
        const {t} = this.props;
        return (
            <React.Fragment>
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                    <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)}></CustomMessages>
                    <div id='start-page '>
                        <div className='start-container lawyer'>
                            <div className='row gallery-description'>
                                <div className='col-12'>
                                    <Galleria
                                        ref={(el) => (this.galleria3 = el)}
                                        value={this.state.images}
                                        responsiveOptions={this.responsiveOptions}
                                        numVisible={3}
                                        style={{maxWidth: 'fit-content', maxHeight: '100%', position: 'static'}}
                                        activeIndex={this.state.activeIndex}
                                        onItemChange={(e) => this.setState({activeIndex: e.index})}
                                        circular
                                        //showItemNavigators
                                        showThumbnails={false}
                                        item={this.itemTemplate}
                                        thumbnail={this.thumbnailTemplate}
                                        className='col-12'
                                        autoPlay
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </BlockUi>
            </React.Fragment>
        );
    }
}
StartLawyerContainer.propTypes = {
    onAfterLogin: PropTypes.func,
};
export default withTranslation()(StartLawyerContainer);
