import {saveAs} from 'file-saver';
import $ from 'jquery';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import {Menu} from 'primereact/menu';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ActionLink from '../components/ActionLink';
import {CommonReportUtils} from './CommonReportUtils';
import {poppinsRegular} from './Poppins-Regular-normal.js';
import {parse} from 'json2csv';
import XLSX from 'xlsx';
/**
 * @param report.validations Array with validations
 * @param report.id Id of a document
 * @param raport.verificationDate Date of autoverification
 */

export class PrintButtonComponent extends Component {
    constructor(props) {
        super(props);
        const items = [];
        if (props.pdf === true) {
            items.push({
                label: 'PDF',
                icon: 'pi',
                key: 'pdf',
                command: (e) => this.handlePrint('pdf', e),
            });
        }
        if (props.xlsx === true) {
            items.push({
                label: 'XLSX',
                icon: 'pi',
                key: 'xlsx',
                command: (e) => this.handlePrint('xlsx', e),
            });
        }
        if (props.csv === true) {
            items.push({
                label: 'CSV',
                icon: 'pi',
                key: 'csv',
                command: (e) => this.handlePrint('csv', e),
            });
        }
        if (props.ods === true) {
            items.push({
                label: 'ODS',
                icon: 'pi',
                key: 'ods',
                command: (e) => this.handlePrint('ods', e),
            });
        }
        this.state = {
            items,
        };
        this.menuRef = React.createRef();
        this.handlePrint = this.handlePrint.bind(this);
    }

    componentDidMount() {
        // eslint-disable-next-line no-undef
        $('a.p-menuitem-link').removeAttr('href');
    }

    onButtonClick(event) {
        if (event) {
            event.preventDefault();
        }
        this.menuRef.current.toggle(event);
    }

    handlePrint(format, event) {
        if (event) {
            event.originalEvent.preventDefault();
        }
        const {reportFileNameBase, generateReport} = this.props;
        if (generateReport) {
            generateReport(format).then((blob) => {
                const now = moment().format('YYYYMMDD_HHmmss');
                const fileName = `${reportFileNameBase}_${now}.${format}`;
                saveAs(blob, fileName);
            });
        }
    }

    render() {
        return (
            <div className=''>
                <Menu model={this.state.items} popup ref={this.menuRef} appendTo={document.body} />

                <ActionLink
                    label={this.props.label ? this.props.label : 'Pobierz'}
                    className={`${this.props.className} header-item download-header-link`}
                    handleClick={this.onButtonClick.bind(this)}
                    disabled={this.props.disabled}
                    iconSvg='ico_download_link_icon'
                    iconSide='right'
                    key={this.props.id}
                />
            </div>
        );
    }
}

PrintButtonComponent.defaultProps = {
    csv: true,
    pdf: true,
    xlsx: true,
    ods: true,
    reportFileNameBase: 'raport',
};

PrintButtonComponent.propTypes = {
    csv: PropTypes.bool,
    generateReport: PropTypes.func.isRequired,
    label: PropTypes.string,
    pdf: PropTypes.bool,
    reportFileNameBase: PropTypes.string,
    xml: PropTypes.bool,
};

function footer(doc, t) {
    for (let i = 0; i < doc.internal.getNumberOfPages(); i++) {
        doc.setPage(i);
        doc.setFontSize(10);

        doc.setFont('poppins');
        const pageHeight = doc.internal.pageSize.height;
        const pageWidth = doc.internal.pageSize.width;
        const footerY = pageHeight - CommonReportUtils.footerHeigth;
        const footerText = t('report.footer');
        const width = doc.getTextWidth(footerText);
        console.log(width);
        doc.text(
            footerText,
            // margin / 2,
            pageWidth / 2 - width / 2,
            footerY
            // 'center'
        );
    }
}
function callAddFontToDoc(font) {
    var callAddFont = function () {
        this.addFileToVFS('poppins-normal.ttf', font);
        this.addFont('poppins-normal.ttf', 'poppins', 'normal');
    };
    jsPDF.API.events.push(['addFonts', callAddFont]);
}
export const baseReportCsv = (list, fields) => {
    const opts = {fields};
    const csv = parse(list, opts);
    return new Blob([csv], {type: 'application/csv'});
};
export const baseXLSXReport = (request, format = 'xlsx') => {
    var wb = XLSX.utils.book_new();
    const table = [];
    table.push(request.columns.map((c) => c.header));
    request.list.forEach((item, index) => {
        const currentItemList = [];
        request.columns.forEach((column, colIndex) => {
            if (column.getColFunction) {
                currentItemList.push(column.getColFunction(item));
            } else {
                currentItemList.push(item[`${column.field}`]);
            }
        });

        table.push(currentItemList);
    });
    var ws = XLSX.utils.aoa_to_sheet(table);

    XLSX.utils.book_append_sheet(wb, ws, request.sheetName);

    return new Promise((resolve) => {
        resolve(new Blob([XLSX.write(wb, {type: 'array', bookType: `${format}`})], {type: `application/${format}`}));
    });
};
export const currencyFormat = (fieldName, t, rowData) => {
    if (rowData && fieldName && rowData[`${fieldName}`]) {
        let result = rowData[`${fieldName}`].toFixed(2);
        return `${result} ${t('currency.pln')}`;
    } else return `0,00 ${t('currency.pln')}`;
};
export const amountNet = (withCurrency, t, rowData) => {

    let result = '';
    if (rowData.sapDocument) {
        result = rowData.amountNetto?.toFixed(2);
    } else {
        result = (rowData.amount - rowData.amountVat)?.toFixed(2);
    }
    if (isNaN(result)) {
        result = 0;
        result = result.toFixed(2);
    }
    return !!withCurrency ? `${result} ${t('currency.pln')}` : parseFloat(result);
};
export const baseReportPdf = (request, t) => {
    callAddFontToDoc(poppinsRegular);

    const doc = !request.landscape
        ? new jsPDF()
        : new jsPDF({
              orientation: 'landscape',
          });
    const margin = 15;
    const lineHeight = 5;
    let currentY = 15;

    doc.setFont('poppins', 'normal');
    doc.setFontSize(14);
    const title = request.title;
    currentY += CommonReportUtils.createRectWithText(
        title,
        margin / 2,
        margin / 2 + 25,
        !request.landscape ? 195 : 283,
        12,
        doc,
        CommonReportUtils.COLORS.BASIC,
        CommonReportUtils.COLORS.WHITE
    );
    doc.setFontSize(12);
    currentY += lineHeight * 1;

    const colStyles = {};
    request.columns.forEach((col, index) => {
        colStyles.index = {cellWidth: col.width};
    });
    const table = [];

    request.list.forEach((item, index) => {
        const currentItemList = [];
        request.columns.forEach((column, colIndex) => {
            if (column.getColFunction) {
                currentItemList.push(column.getColFunction(item));
            } else {
                currentItemList.push(item[`${column.field}`]);
            }
        });

        table.push(currentItemList);
    });

    doc.autoTable({
        startY: currentY,
        rowPageBreak: 'avoid',
        head: [request.columns.map((c) => c.header)],
        margin: {left: margin / 2, right: margin / 2, bottom: 40},
        body: table,
        styles: {font: 'poppins', halign: 'left'},
        headStyles: {fontStyle: 'poppins', fillColor: CommonReportUtils.COLORS.BASIC},
    });
    currentY = doc.autoTable.previous.finalY + 10;

    footer(doc, t);
    return new Promise((resolve) => {
        resolve(new Blob([doc.output('blob')], {type: 'application/pdf'}));
    });
};
