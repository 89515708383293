import {Link} from 'react-router-dom';
import ActionLink from '../../components/ActionLink';
import {useEffect, useState} from 'react';
import ReminderContainer from '../ReminderContainer';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import $ from 'jquery';

export default function CollapsableLexyNavbar(props) {
    const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 1200);
    const [isCollapsedShow, setCollapsedShow] = useState(false);

    useEffect(() => {
        return window.addEventListener(
            'resize',
            () => {
                const isCollapsedCurrent = window.innerWidth < 1200;
                if (!isCollapsedCurrent && isCollapsedShow) setCollapsedShow(false);
                if (isCollapsed !== isCollapsedCurrent) setIsCollapsed(isCollapsedCurrent);
            },
            false
        );
    }, [isCollapsed]);

    useEffect(() => {
        const handleClick = (e) => {
            // e.stopPropagation();
            if (!$(e.target).hasClass('navbar-toggler') && !$(e.target).hasClass('navbar-nav.mobile')) {
                setCollapsedShow(false);
            }
        };
        $('body').on('click', handleClick);

        return () => {
            $('body').off('click', handleClick);
        };
    }, [setCollapsedShow]);

    return (
        <nav className='navbar lexy-logged'>
            <div className='navbar-brand'>
                <img className='img-fluid img-lexy' src={`https://leksy.pl/wp-content/uploads/2023/09/Group_1@2x1.png`} alt='Logo Lexy' />
            </div>
            <div
                className={`navbar-collapse-custom dual-collapse order-0 ${isCollapsed ? `collapse ${isCollapsedShow ? 'show' : ''}` : ''}`}
            >
                <ul className='navbar-nav ml-auto' style={{justifyContent: 'space-evenly'}}>
                    {props.generatedDocumentAvailable ? (
                        <li className='nav-item'>
                            <Link className='nav-link' to='/agreement-list'>
                                Moje dokumenty
                            </Link>
                        </li>
                    ) : null}

                    <li className='nav-item '>
                        <Link className='nav-link' to='/template-list'>
                            Kreator dokumentów
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <ActionLink
                            className='nav-link'
                            href={AppPrefixUtils.locationHrefUrl('/#/order-list')}
                            label='Zamówienia'
                        ></ActionLink>
                    </li>
                    <li className='nav-item'>
                        <ActionLink
                            className='nav-link'
                            href={AppPrefixUtils.locationHrefUrl('/#/scheduled-event-calendar')}
                            label='Zaplanowane konsultacje'
                        ></ActionLink>
                    </li>
                    <li className='nav-item'>
                        <ActionLink
                            className='nav-link'
                            href={AppPrefixUtils.locationHrefUrl('/#/consultation-history-list')}
                            label='Historia konsultacji'
                        ></ActionLink>
                    </li>
                    <li className='nav-item show-collapsed-row'>
                        <Link className='nav-link reminder-link'>
                            <ReminderContainer
                                rel='noopener noreferrer'
                                className='header-link'
                                hideNavbar={() => {
                                    setCollapsedShow(!isCollapsedShow);
                                }}
                            />
                        </Link>
                    </li>
                    <li className='nav-item '>
                        <Link className='nav-link nav-link-login' to={`/manage-account/details/${props.userId}`}>
                            <img src='https://leksy.pl/wp-content/uploads/2023/09/Component-9-%E2%80%93-15-1.svg'></img>
                        </Link>
                    </li>
                </ul>
            </div>

            <button
                className={`navbar-toggler ${isCollapsed ? 'collapsed' : ''}`}
                type='button'
                style={{position: 'absolute', right: '10px'}}
                data-toggle='collapse'
                data-target='.dual-collapse'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
                onClick={(e) => {
                    setCollapsedShow(!isCollapsedShow);
                    e.stopPropagation();
                }}
            >
                <span className='navbar-toggler-icon logged'>
                    <i id='hamburger' className='fa fa-navicon'></i>
                </span>
            </button>
            {isCollapsedShow && (
                <ul className={`navbar-nav mobile ${!!isCollapsed && !!isCollapsedShow ? 'show' : ''}`}>
                    <li className='nav-item'>
                        <Link className='nav-link' to={'/order-list'} onClick={() => setCollapsedShow(!isCollapsedShow)}>
                            Zamówienia
                        </Link>
                    </li>
                    {props.generatedDocumentAvailable ? (
                        <li className='nav-item'>
                            <Link className='nav-link ' to='/agreement-list' onClick={() => setCollapsedShow(isCollapsedShow)}>
                                Moje dokumenty
                            </Link>
                        </li>
                    ) : null}

                    <li className='nav-item '>
                        <Link className='nav-link ' to='/template-list' onClick={() => setCollapsedShow(!isCollapsedShow)}>
                            Kreator dokumentów
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link className='nav-link' to={'/scheduled-event-calendar'} onClick={() => setCollapsedShow(!isCollapsedShow)}>
                            Zaplanowane konsultacje
                        </Link>
                    </li>
                    <li className='nav-item '>
                        <Link className='nav-link ' to='/consultation-history-list' onClick={() => setCollapsedShow(!isCollapsedShow)}>
                            Historia konsultacji
                        </Link>
                    </li>

                    <li className='nav-item show-collapsed-row'>
                        <Link
                            className='nav-link'
                            to={`/manage-account/details/${props.userId}`}
                            onClick={() => setCollapsedShow(!isCollapsedShow)}
                        >
                            <span>Profil</span>
                        </Link>
                    </li>
                    {props.footer()}
                </ul>
            )}
        </nav>
    );
}
