import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import ActionLink from '../../components/ActionLink';
import AgreementTemplateForUserService from '../../services/AgreementTemplateForUserService';
import AuthService from '../../services/AuthService';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CategoryService from '../../services/CategoryService';
import LegalConsultationDialog from './LegalConsultationDialog';
import {CustomMessages} from '../../components/CustomMessages';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import YesNoDialog from '../../components/YesNoDialog';
import DialogResizer from '../../components/DialogResizer';

class AgreementDataDraftListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new AgreementTemplateForUserService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            dialogVisibile: false,
            statusOptions: [],
            roleOptions: [],
            categories: [],
            selectedList: [],
        };
        this.editAgreementTemplate = this.editAgreementTemplate.bind(this);
        this.authService = new AuthService();
        this.categoryService = new CategoryService();
        this.authService = new AuthService();
        this.openDialog = this.openDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.openRemoveDialog = this.openRemoveDialog.bind(this);
        this.closeRemoveDialog = this.closeRemoveDialog.bind(this);

        window.legalConsultationDialogRef = this.props.legalConsultationDialogRef;
        window.tabViewLegalRef = this.props.tabViewLegalRef;
    }
    getDataKey() {
        return 'id';
    }

    getListTitle() {
        return 'template.listTitle';
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    updateSearchCriteria(criteria) {
        return {
            text: criteria.text,
            categoryId: criteria.categoryId,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            text: '',
            categoryId: undefined,
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: false,
        };
    }

    initializeFromBackend() {
        this.categoryService
            .getAll()
            .then((categories) =>
                this.setState({
                    categories,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });
    }

    getCriteriaName() {
        return 'template-list-sc';
    }

    getContainerListName() {
        return 'template-list-container';
    }

    getContainerListName() {
        return 'user-template-list-container';
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='text-sc'
                    name='text'
                    label={'Tytuł szablonu'}
                    placeholder='Wpisz tytuł...'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.text}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
                <CriteriaDropdownComponent
                    id='category-sc'
                    name='category'
                    showClear
                    label={'Kategoria'}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.category}
                    options={this.state.categories}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='name'
                />
            </div>
        );
    }

    prepareColumns() {
        const {t} = this.props;

        return [
            {selectionMode: 'multiple'},
            {field: 'id', header: t('agreement.listHeaders.id'), sortable: true},
            {field: 'template.name', header: t('agreement.listHeaders.templateName'), sortable: true},
            {field: 'draftName', header: 'Nazwa dokumentu', sortable: true},
            {field: 'category', header: 'Kategoria'},
            {field: 'contractorEmail', header: 'Wysłane do', sortable: true},
            {key: 'actions', header: '', body: this.editAgreementTemplate, className: 'table-column-action'},
        ];
    }

    prepareHeaderItems() {
        const {t} = this.props;
        const {selectedList} = this.state;
        const isDisabled = selectedList.length === 0;
        return this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE', 'ROLE_USER_LEKSY_MANAGE')
            ? [
                  {type: 'LABEL', className: 'float-left'},
                  {
                      label: t('Usuń zaznaczone'),
                      type: 'BUTTON',
                      className: 'red-button',
                      variant: '',
                      iconSide: 'left',
                      disabled: isDisabled,
                      customRenderFunction: () => {
                          if (this.isLexyPortal()) {
                              return (
                                  <ActionLink
                                      disabled={isDisabled}
                                      handleClick={this.openRemoveDialog}
                                      key={'remove-cancel-button'}
                                      className={'remove-button right-custom-buttom pi pi-trash no-underline'}
                                  />
                              );
                          }

                          return (
                              <div width={75} onClick={this.openRemoveDialog} className='cursor-pointer'>
                                  <img src={this.iconDeleteUrlForLexDocS(isDisabled)} alt=''></img>
                              </div>
                          );
                      },
                  },
              ]
            : [];
    }
    iconDeleteUrlForLexDocS(isDisabled) {
        return isDisabled
            ? AppPrefixUtils.locationHrefUrl(`/images/lexdocs/delete_silver.svg`)
            : AppPrefixUtils.locationHrefUrl(`/images/lexdocs/delete_red.svg`);
    }
    handleGoToDetail(href, e) {
        e.preventDefault();
        this.blockUi();
        const {criteria} = this.state;
        if (!this.props.hideFilters) {
            this.saveCookie(this.getCriteriaName(), JSON.stringify(criteria));
        }
        window.location.href = AppPrefixUtils.locationHrefUrl(href);
    }

    openDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true});
    }
    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: false}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
            if (callback) callback();
        });
    }
    openRemoveDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({removeDialogVisibile: true});
    }
    closeRemoveDialog(callback) {
        this.setState({removeDialogVisibile: false}, () => {
            if (callback) callback();
        });
    }

    editAgreementTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.uuid}`;
        return this.isLexyPortal() ? (
            <React.Fragment>
                <ActionLink handleClick={this.handleGoToDetail.bind(this, href)} label='Edytuj' variant='blue' key={'view-button'} />
            </React.Fragment>
        ) : (
            <img
                src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/edit_blue_24dp.svg`)}
                alt=''
                onClick={this.handleGoToDetail.bind(this, href)}
            />
        );
    }

    render() {
        return (
            <div className='container-fluid'>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                {this.renderView()}
                {this.state.dialogVisibile ? (
                    <LegalConsultationDialog
                        className='legal-consultation-dialog'
                        dialogRef={this.props.legalConsultationDialogRef}
                        tabViewRef={this.props.tabViewLegalRef}
                        key={`dialog-${this.state.element?.title}`}
                        currentUser={this.props.currentUser}
                        onHide={(e) => {
                            this.handleCloseDialog(e);
                        }}
                        visible={this.state.dialogVisibile}
                        author={this.state.author}
                        email={this.state.email}
                        viewMode={'NEW'}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}
                {this.state.removeDialogVisibile ? (
                    <YesNoDialog
                        visible={this.state.removeDialogVisibile}
                        header={'Usuwanie dokumentów'}
                        name='visible'
                        onChange={(type, x, target) => {
                            if (target.value) {
                                this.service
                                    .removeSelectedAgreementData({ids: this.state.selectedList.map((i) => i.id)})
                                    .then((response) => {
                                        this.closeRemoveDialog(() => {
                                            this.setState({
                                                selectedList: [],
                                            });
                                            this.showSuccessMessage('Dokumenty zostały usunięte');
                                            this.refreshTable();
                                        });
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        this.closeRemoveDialog(() => {
                                            this.showErrorMessage(err.message);
                                        });
                                    });
                            } else {
                                this.closeRemoveDialog();
                            }
                        }}
                        onHide={() => {
                            this.closeRemoveDialog();
                            DialogResizer.removeMaskIfExistOneDialog();
                        }}
                        yesButtonLabel='Tak'
                        yesButtonClassName=''
                    >
                        <label>Czy na pewno chcesz usunąć wybrane dokumenty?</label>
                    </YesNoDialog>
                ) : null}
            </div>
        );
    }
}

AgreementDataDraftListContainer.defaultProps = {
    detailUrl: '/#/agreement/edit',
    newUrl: '/#/template/create',
    lazy: true,
};

AgreementDataDraftListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(AgreementDataDraftListContainer);
