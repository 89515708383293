import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {ActionButton} from '../../components/ActionButton';
import DivContainer from '../../components/DivContainer';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import HierarchicalDictionaryModel from '../../model/HierarchicalDictionaryModel';
import HierarchicalDictionaryService from '../../services/HierarchicalDictionaryService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import DialogResizer from '../../components/DialogResizer';

class HierarchicalDictionaryDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new HierarchicalDictionaryService());
        this.editableOptions = ['Odczyt i zapis', 'Odczyt'];
        this.state = {
            element: new HierarchicalDictionaryModel(),
            viewMode: this.props.viewMode,
            activeIndex: 0,
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChangeStep = this.handleChangeStep.bind(this);
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'>
                    {this.state.viewMode === 'EDIT' && !this.detectSelfEdition() ? (
                        <ActionButton
                            label={t('hierarchicalDictionary.deleteButton')}
                            variant='button cancel-button gray'
                            buttonType={'PREV'}
                            handleClick={(e) => {
                                if (e) {
                                    e.preventDefault();
                                }
                                this.setState({viewMode: 'DELETE', closable: false});
                            }}
                        />
                    ) : null}
                </div>
                <ActionButton
                    buttonType={'NEXT'}
                    label={this.state.viewMode === 'NEW' ? t('hierarchicalDictionary.addButton') : t('details.saveChanges')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                    rendered={this.state.viewMode !== 'DELETE'}
                />
                <ActionButton
                    buttonType={'NEXT'}
                    label={t('details.deleteConfirmationButton')}
                    variant='button save-button fullred'
                    handleClick={this.handleDelete}
                    rendered={this.state.viewMode === 'DELETE'}
                />
            </div>
        );
    }
    isThereSecondStep() {
        return this.state.element?.role?.name !== 'Administrator';
    }
    handleDelete(e) {
        if (e) {
            e.preventDefault();
        }
        const {t} = this.props;
        this.blockUi();

        DialogResizer.removeMaskIfExistOneDialog();
        this.service
            .remove(this.state.element)
            .then((response) => {
                this.blockUi();
                this.persistMessage('success', '', t('user.deleteUserSuccess'));
                if (this.props.afterSave) {
                    this.props.afterSave();
                }
            })
            .catch((err) => {
                this.persistMessage('error', '', err.message);
                if (this.props.afterSave) {
                    this.props.afterSave();
                }
            });
    }
    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('hierarchicalDictionary.addHeader')}`
                            : this.state.viewMode === 'EDIT'
                            ? `${t('hierarchicalDictionary.editHeader')}`
                            : `${t('hierarchicalDictionary.deleteHeader')}`}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }
    updateElement(data) {
        this.setState(
            {
                element: HierarchicalDictionaryModel.copy(data),
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    getContainerListName() {
        return 'hierarchical-dictionary-list-container';
    }

    getAddSucces() {
        const {t} = this.props;
        return t('hierarchicalDictionary.addSuccess');
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('hierarchicalDictionary.addUpdate');
    }
    createOrUpdate() {
        const {element} = this.state;
        this.scrollToTop();
        if (this.props._isMounted) {
            if (element.id === undefined || element.id === null) {
                this.service
                    .add(element)
                    .then((response) => {
                        this.blockUi();
                        this.persistMessage('success', '', this.getAddSucces(response));
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                        DialogResizer.removeMaskIfExistOneDialog();
                    })
                    .catch((err) => {
                        console.log(err);
                        this.persistMessage('error', '', err.message);
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                        DialogResizer.removeMaskIfExistOneDialog();
                    });
            } else {
                this.service
                    .update(element)
                    .then((response) => {
                        this.blockUi();
                        this.persistMessage('success', '', this.getUpdateSucces(response));
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                        DialogResizer.removeMaskIfExistOneDialog();
                    })
                    .catch((err) => {
                        this.persistMessage('error', '', err.message);
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                        DialogResizer.removeMaskIfExistOneDialog();
                    });
            }
        }
    }
    handleChangeStep(e) {
        if (this.validator.allValid()) {
            this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // this.scrollToError = true;
            this.forceUpdate();
        }
    }
    renderDetails() {
        const {t} = this.props;
        return this.state.viewMode === 'DELETE' ? (
            <DivContainer>
                <label id={`delete-user-label-id`} className='p-label' htmlFor={'delete-user'} style={{width: '100%'}}>
                    {t('details.deleteConfirmation')}
                </label>
                <span
                    aria-label={t('details.deleteConfirmation')}
                    aria-labelledby={`delete-user-label-id`}
                    className={`p-inputtext-view icon_text element-to-remove`}
                >
                    {this.state.element.firstName} {this.state.element.lastName}
                </span>
            </DivContainer>
        ) : (
            this.renderFirstStep()
        );
    }
    detectSelfEdition() {
        const user = this.authService.getProfile();
        return !!(user.userId && this.state.element && this.state.element.id && parseInt(user.userId) === parseInt(this.state.element.id));
    }
    handleRedirect() {
        window.location.href = AppPrefixUtils.locationHrefUrl(this.props.changePasswordUrl);
    }

    renderFirstStep() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='name'
                        name='name'
                        label={'Nazwa'}
                        colClass='col-md-6'
                        value={this.state.element.name}
                        validator={this.validator}
                        validators='required|alpha_space|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />

                    <InputTextComponent
                        id='code'
                        name='code'
                        label={'Kod'}
                        colClass='col-md-6'
                        value={this.state.element.code}
                        validator={this.validator}
                        validators='required|alpha_space_dash|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
}

HierarchicalDictionaryDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
};

HierarchicalDictionaryDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
};

export default withTranslation()(HierarchicalDictionaryDialog);
