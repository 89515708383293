import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import TemplateService from '../../services/TemplateService';
import ActionLink from '../../components/ActionLink';
import AgreementTemplateForUserService from '../../services/AgreementTemplateForUserService';
import AuthService from '../../services/AuthService';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CategoryService from '../../services/CategoryService';
import LegalConsultationDialog from './LegalConsultationDialog';
import {CustomMessages} from '../../components/CustomMessages';
import CategoryPublicService from '../../services/public/CategoryPublicService';
import TemplatePublicService from '../../services/public/TemplatePublicService';
import OrderService from '../../services/OrderService';
import MailingDialog from './MailingDialog';
import ScheduledConsultationService from '../../services/ScheduledConsultationService';
import ScheduledConsultationPublicService from '../../services/public/ScheduledConsultationService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import DialogResizer from '../../components/DialogResizer';
import EventCalendarPublicService from '../../services/public/EventCalendarPublicService';
import EventCalendarService from '../../services/EventCalendarService';
import TemplatePointList from '../../components/TemplatePointList';
import {InputText} from 'primereact/inputtext';
import ActionButton from '../../components/ActionButton';
import StringUtils from '../../utils/StringUtils';
import {device} from '../../baseContainers/BaseContainer';

class TemplateListContainer extends BaseListContainer {
    constructor(props) {
        super(props, props.embedded ? new AgreementTemplateForUserService() : new TemplateService());
        this.state = {
            scheduledConsultationId: undefined,
            specializationId: undefined,
            list: [],
            visibleMailingDialog: false,
            consultants: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            dialogVisibile: false,
            statusOptions: [],
            roleOptions: [],
            categories: [],
        };
        this.newAgreementTemplate = this.newAgreementTemplate.bind(this);
        this.renderPointerView = this.renderPointerView.bind(this);
        this.renderDataTable = this.renderDataTable.bind(this);
        this.authService = new AuthService();
        this.templatePointRef = new React.createRef();
        this.countingRef = new React.createRef();
        this.orderService = new OrderService();
        this.scheduledConsultationService = this.authService.loggedIn()
            ? new ScheduledConsultationService()
            : new ScheduledConsultationPublicService();
        this.consultationService = this.authService.loggedIn() ? new EventCalendarService() : new EventCalendarPublicService();
        this.service = this.authService.loggedIn() ? this.service : new TemplatePublicService();
        this.categoryService = this.authService.loggedIn() ? new CategoryService() : new CategoryPublicService();
        this.openDialog = this.openDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        window.legalConsultationDialogRef = this.props.legalConsultationDialogRef;
        window.tabViewLegalRef = this.props.tabViewLegalRef;
    }
    getDataKey() {
        return 'id';
    }

    getListTitle() {
        return 'template.listTitle';
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    updateSearchCriteria(criteria) {
        return {
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            text: criteria.text,
            category: criteria.category,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            dateFrom: undefined,
            dateTo: undefined,
            text: '',
            category: undefined,
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: false,
            subscriptionId: this.authService.isUserInRole('ROLE_USER_LEX_DOCS_MANAGE') ? this.props?.subscriptionId : undefined,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.initializeFromBackend();
        if (this.defaultLoading) {
            this.readCriteriaFromCookie();
        }
        this.readMessage();
        this.orderPaidFor();
    }

    onKeyDown(e) {
        console.log(e);
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }
    renderDataTable(columns, list, size) {
        if (list === null || list === undefined) {
            list = this.state.list;
        }
        if (size === null || size === undefined) {
            size = this.state.size;
        }
        this._isMounted = true;

        const {preNewAgreementUrl, prePublicNewAgreementUrl} = this.props;
        const detailUrl = this.authService.loggedIn() ? `${preNewAgreementUrl}/` : `${prePublicNewAgreementUrl}/`;
        const leksySizeBtn = this.isMobile() ? 's-14' : 's-21';
        if (
            this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE') ||
            !this.authService.loggedIn() ||
            (device.type === 'mobile' && this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE'))
        ) {
            if (!Array.isArray(list)) {
                return (
                    <div>
                        <TemplatePointList
                            templatePointRef={this.templatePointRef}
                            items={list}
                            showCounts={(counts) => {
                                const searchIsEmpty = StringUtils.isBlank(this.state.criteria.text);
                                const countingRef = this.countingRef?.current;

                                if (searchIsEmpty) {
                                    countingRef.innerText = '';
                                    return;
                                }

                                if (counts === 0) {
                                    countingRef.innerText = 'Nie znaleziono rekordów';
                                } else {
                                    countingRef.innerText = 'Znaleziono: ' + counts + ' dokumentów ';
                                }
                            }}
                            detailUrl={detailUrl}
                            criteriaTextField={this.state.criteria.text}
                        />
                        {!this.isLexyPortal() ? <div className={`mb-4`}></div> : ''}
                        <div className='container'>
                            {this.isMobileLexDocS() ? (
                                <div id='consultation-panel' className='m-2 margin-top-40'>
                                    <div className='col-12 cursor-pointer' onClick={() => this.openDialog()}>
                                        <span className='text'> Skonsultuj </span>
                                        <img src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/headset_mic_blue.svg')} alt='' />
                                    </div>
                                </div>
                            ) : (
                                <ActionButton
                                    className={`height-75 ${leksySizeBtn} header-item white  p-button-text-only`}
                                    label='Konsultacja prawna'
                                    handleClick={this.openDialog}
                                />
                            )}
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        } else {
            return super.renderDataTable(columns, list, size);
        }
    }

    renderPointerView() {}

    getList() {
        const {criteria} = this.state;
        this.service
            .getList(criteria)
            .then((data) => {
                if (this._isMounted) {
                    this.setState(
                        {
                            list: this.updateListElements(data.content),
                            loading: false,
                            size: data.totalElements,
                        },
                        () => this.onAfterGetList(data)
                    );
                }
            })
            .catch((err) => {
                if (this._isMounted) {
                    this.setState(
                        {
                            list: [],
                            loading: false,
                            size: 0,
                        },
                        () => {
                            this.afterGetListError();
                        }
                    );
                }
            });
    }
    orderPaidFor() {
        const urlStr = window.location.toString().replace('/#', '');
        const url = new URL(urlStr);
        const orderPaidFor = url.searchParams.get('orderPaidFor');
        if (orderPaidFor) {
            this.scheduledConsultationService.getByOrderUuid(orderPaidFor).then((sc) => {
                if (sc.paymentStatus === 'PAID') {
                    this.setState({
                        specializationId: sc.specialization.id,
                        scheduledConsultationId: sc.id,
                        visibleMailingDialog: true,
                        consultants: sc.consultants,
                    });
                }
            });
        }
    }

    initializeFromBackend() {
        this.categoryService
            .getAll()
            .then((categories) =>
                this.setState({
                    categories,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });
    }

    getCriteriaName() {
        return 'template-list-sc';
    }

    getContainerListName() {
        return 'template-list-container';
    }

    renderFilter() {
        if (
            this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE') ||
            !this.authService.loggedIn() ||
            (device.type === 'mobile' && this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE'))
        ) {
            return this.renderCustomCriteria();
        }
        return super.renderFilter();
    }
    prepareHeaderItems() {
        const {t} = this.props;
        const headerItems = this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE', 'ROLE_USER_LEKSY_MANAGE')
            ? [{type: 'LABEL', className: 'float-left'}]
            : [];

        if (!this.isLexyPortal() && device.type !== 'mobile' && this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE')) {
            headerItems.push({
                label: t('Konsultacja prawna'),
                onClick: this.openDialog,
                type: 'BUTTON',
                variant: 'white',
                iconSide: 'left',
            });
        }
        return headerItems;
    }
    renderCriteria() {
        return (
            <div className='lex-custom-criteria'>
                <div className='row'>
                    <CriteriaTextComponent
                        id='text-sc'
                        name='text'
                        label={'Tytuł szablonu'}
                        colClass='col-lg-4 col-sm-6'
                        placeholder='Wpisz tytuł...'
                        value={this.state.criteria.text}
                        onChange={this.handleChangeSc}
                        validator={this.validator}
                    />
                    <CriteriaDropdownComponent
                        id='category-sc'
                        name='category'
                        showClear
                        label={'Kategoria'}
                        colClass='col-lg-4 col-sm-6'
                        value={this.state.criteria.category}
                        options={this.state.categories}
                        placeholder='Wszystkie'
                        onChange={this.handleChangeSc}
                        validator={this.validator}
                        optionLabel='name'
                    />
                </div>
            </div>
        );
    }

    prepareColumns() {
        const {t} = this.props;

        return [
            {field: 'name', header: t('agreement.listHeaders.title'), sortable: true},
            {field: 'category.name', header: 'Kategoria'},
            {key: 'actions', header: '', body: this.newAgreementTemplate, className: 'table-column-action'},
        ];
    }

    consultationButton(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <div className='container'>
                <ActionLink
                    rendered={this.backLinkRendered}
                    className='p-link'
                    handleClick={this.handleGoToDetail.bind(this, href)}
                    variant='blue'
                    iconName='mdi-eye'
                    iconSide='left'
                    iconSize='xs'
                    iconColor='blue'
                />
            </div>
        );
    }

    handleGoToDetail(href, e) {
        e.preventDefault();
        this.blockUi();
        const {criteria} = this.state;
        if (!this.props.hideFilters) {
            this.saveCookie(this.getCriteriaName(), JSON.stringify(criteria));
        }
        window.location.href = AppPrefixUtils.locationHrefUrl(href);
    }

    openDialog(e) {
        this.consultationService
            .getConsultationAvailabilityBySettingsValues()
            .then((consultationAvailability) => {
                const anyAvailability = consultationAvailability.find((el) => el.availability);

                if (e) {
                    e.preventDefault();
                }
                if (!anyAvailability) {
                    this.showErrorMessage('Konsultacje nie są w tej chwili dostępne');
                    return;
                }

                this.setState({dialogVisibile: true});
            })
            .catch(() => {
                this.showErrorMessage('Problem z pobraniem dostępności konsultacji');
            });
    }
    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: false}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
            if (callback) callback();
        });
    }

    isNotMobileLexDocS() {
        return !(!this.isLexyPortal() && device.type === 'mobile');
    }
    isMobileLexDocS() {
        return !this.isLexyPortal() && device.type === 'mobile';
    }

    renderCustomCriteria() {
        const templateContainer = this.isMobile() ? '' : 'container';

        return (
            <div className='container'>
                <div id='template-list-header' className={`${templateContainer}`}>
                    {this.isNotMobileLexDocS() && (
                        <>
                            <div id={`tempalte-list-header-title`} className={`${!this.isLexyPortal() ? 'mt-4' : ''}`}>
                                <p>Setki dokumentów w jednym miejscu</p>
                            </div>
                            <div id='tempalte-list-header-description'>
                                <p>Wybierz jeden z wielu dostepnych szablonów pism, umów i porozumień dopasowanych pod Twoje potrzeby.</p>
                                <p>
                                    Wypełnij krótki formularz i zobacz jak dokument powstaje na Twoich oczach. Gotowe pismo będziesz mógł/a
                                    wygenerować.
                                </p>
                            </div>
                        </>
                    )}
                    <span className='p-input-icon-right col-12'>
                        <div className=''>
                            {this.isLexyPortal() ? (
                                <img
                                    src={AppPrefixUtils.locationHrefUrl(`/images/lexy/search_leksy.svg`)}
                                    alt=''
                                    className='image-search-leksy'
                                />
                            ) : (
                                <img
                                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                                    alt=''
                                    className='image-search-lexdocs'
                                />
                            )}
                        </div>
                        {/* <i className='pi pi-search' onClick={() => {}} /> */}
                        <InputText
                            className='col-12 custom-input-text'
                            value={this.state.criteria.text}
                            placeholder='Nazwa szablonu...'
                            onChange={(el) => {
                                this.setState((prevState) => ({
                                    criteria: {
                                        ...prevState.criteria,
                                        text: el.target.value,
                                    },
                                }));
                            }}
                        />
                    </span>
                    <div className='col-12 '>
                        <b>
                            <label ref={this.countingRef} style={{fontSize: '14px'}}></label>
                        </b>
                    </div>
                    {/* {this.isNotMobileLexDocS() && !this.isLexyPortal() && (
                        <div className='col-12'>
                            <ActionButton
                                className='mt-3  s-21 header-item white  p-button-text-only'
                                label='Konsultacja prawna'
                                handleClick={this.openDialog}
                            />
                        </div>
                    )} */}
                </div>
            </div>
        );
    }

    newAgreementTemplate(rowData) {
        const {preNewAgreementUrl, prePublicNewAgreementUrl} = this.props;
        const href = this.authService.loggedIn() ? `${preNewAgreementUrl}/${rowData?.id}` : `${prePublicNewAgreementUrl}/${rowData?.id}`;

        return !this.isLexyPortal() ? (
            <img
                src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/edit_blue_24dp.svg`)}
                alt=''
                onClick={this.handleGoToDetail.bind(this, href)}
            />
        ) : (
            <React.Fragment>
                <ActionLink
                    handleClick={this.handleGoToDetail.bind(this, href)}
                    label='Utwórz dokument'
                    variant='blue'
                    key={'view-button'}
                />
            </React.Fragment>
        );
    }
    renderView() {
        return this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE') || !this.authService.loggedIn() ? (
            <div className='card-unstylled'>{super.renderView()}</div>
        ) : (
            super.renderView()
        );
    }

    render() {
        return (
            <div className='container-fluid'>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />

                {!this.props.embedded ? <BreadcrumbsItem to='/template-list'>{'Konfiguratory'}</BreadcrumbsItem> : null}
                {this.renderView()}
                {this.state.visibleMailingDialog ? (
                    <MailingDialog
                        key={'mailing-dialog'}
                        elementId={Number(this.state?.elementId)}
                        scheduledConsultationId={this.state.scheduledConsultationId}
                        specializationId={this.state.specializationId}
                        onHide={() => {
                            this.setState(
                                {
                                    visibleMailingDialog: false,
                                },
                                () => {
                                    DialogResizer.removeMaskIfExistOneDialog();
                                }
                            );
                        }}
                        visible={this.state.visibleMailingDialog}
                        viewMode={'NEW'}
                        consultants={this.state.consultants}
                        afterSave={() => {
                            this.showSuccessMessage('Wysłano wiadomość');
                            this.setState(
                                {
                                    visibleMailingDialog: false,
                                },
                                () => {
                                    DialogResizer.removeMaskIfExistOneDialog();
                                }
                            );
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                        className='mailing-dialog'
                    />
                ) : null}

                {this.state.dialogVisibile ? (
                    <LegalConsultationDialog
                        className='legal-consultation-dialog'
                        dialogRef={this.props.legalConsultationDialogRef}
                        key={`dialog-${this.state.element?.title}`}
                        tabViewRef={this.props.tabViewLegalRef}
                        currentUser={this.props.currentUser}
                        onHide={(e) => {
                            this.handleCloseDialog(e);
                        }}
                        visible={this.state.dialogVisibile}
                        author={this.state.author}
                        email={this.state.email}
                        viewMode={'NEW'}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                        showMessage={this.showMessage}
                    />
                ) : null}
            </div>
        );
    }
}

TemplateListContainer.defaultProps = {
    detailUrl: '/#/template/edit',
    newUrl: '/#/template/create',
    newAgreementUrl: '/#/agreement/create',
    publicNewAgreementUrl: '/#/public/agreement/create',
    preNewAgreementUrl: '/#/agreement/pre-create',
    prePublicNewAgreementUrl: '/#/public/agreement/pre-create',
    lazy: true,
};

TemplateListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(TemplateListContainer);
