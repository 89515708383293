import {TabPanel, TabView} from 'primereact/tabview';
import React from 'react';
import {withTranslation} from 'react-i18next';
import UserContainer from './UserContainer';
import PropTypes from 'prop-types';
import BaseDetailsContainer from '../../../baseContainers/BaseDetailsContainer';
import SimpleUserTypeModel from '../../../model/SimpleUserTypeModel';
import InternalUserService from '../../../services/InternalUserService';
import UserPackageContainer from './UserPackageContainer';
import BaseBreadCrumb from '../../../baseContainers/BaseBreadCrumb';
class UserManageNavContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.state = {
            activeIndex: 0,
            element: {},
        };
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    handleTabChange(e) {
        this.saveCookie(`user-details-list`, e.index);
        this.setState({
            activeIndex: e.index,
        });
    }

    componentDidMount() {
        let activeIndex = this.readCookie(`user-details-list`);

        this.service
            .getUserType(this.props.elementId)
            .then((response) => {
                if (activeIndex === undefined || activeIndex === null || activeIndex === '' || response.userRoleName !== 'LexDocS') {
                    activeIndex = 0;
                    this.saveCookie(`user-details-list`, 0);
                }
                this.setState({
                    element: new SimpleUserTypeModel.copy(response),
                    activeIndex: parseInt(activeIndex),
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    breadCrumbItems() {
        return [{name: 'Użytkownicy', url: '/#/users-list'}, {name: 'Klient'}];
    }
    render() {
        const {element} = this.state;
        return (
            <React.Fragment>
                {element.userRoleName === 'Leksy' ? (
                    <React.Fragment>
                        <BaseBreadCrumb items={this.breadCrumbItems()} />
                        <TabView activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange}>
                            <TabPanel header={'Szczegóły użytkownika'} key={`user-details-list`}>
                                <UserContainer
                                    params={this.props.params}
                                    currentUser={this.props.currentUser}
                                    elementId={this.props.elementId}
                                    mainRef={this.props.mainRef}
                                    user={this.props.user}
                                    viewMode={this.props.viewMode}
                                    history={this.props.history}
                                    embedded
                                />
                            </TabPanel>
                        </TabView>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BaseBreadCrumb items={this.breadCrumbItems()} />
                        <TabView activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange}>
                            <TabPanel header={'Szczegóły użytkownika'} key={`user-details-list`}>
                                <UserContainer
                                    params={this.props.params}
                                    currentUser={this.props.currentUser}
                                    elementId={this.props.elementId}
                                    mainRef={this.props.mainRef}
                                    user={this.props.user}
                                    viewMode={this.props.viewMode}
                                    history={this.props.history}
                                    embedded
                                />
                            </TabPanel>
                            <TabPanel
                                activeIndex={this.state.activeIndex}
                                disabled={element.userRoleName !== 'LexDocS'}
                                header={'Pakiet'}
                                key={`package-list`}
                            >
                                <UserPackageContainer
                                    params={this.props.params}
                                    currentUser={this.props.currentUser}
                                    elementId={this.props.elementId}
                                    mainRef={this.props.mainRef}
                                    user={this.props.user}
                                    backUrl={this.props.backUrl}
                                    viewMode={this.props.viewMode}
                                    history={this.props.history}
                                    embedded
                                />
                            </TabPanel>
                        </TabView>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

UserManageNavContainer.defaultProps = {
    backUrl: '/#/user/details',
    cancelUrl: '/#/user/details',
    editUrl: '/#/user/edit',
};

UserManageNavContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withTranslation()(UserManageNavContainer);
