import InternalUserService from './InternalUserService';

export default class LawyerService extends InternalUserService {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'user-manage';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			text: criteria.text,
			role: criteria.role?.id,
			assignedUserId: criteria.assignedUserId,
			active: criteria.active,
			not_blocked: criteria.notBlocked,
			status: criteria.status?.enumValue,
			first_result: criteria.firstResult,
			max_result: criteria.maxResults,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}

    getList(criteria) {
        const queryString = this.getCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/lawyer/list${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
	
	addLawyer(body,idLawOffice) {
        return this.fetch(`${this.domain}/${this.path}/lawyer/${idLawOffice}`, {
            method: 'POST',
			body: JSON.stringify(body),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

}
