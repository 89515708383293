import {TabPanel, TabView} from 'primereact/tabview';
import React from 'react';
import {withTranslation} from 'react-i18next';
import AuthService from '../services/AuthService';
import ConfiguratorTemplateListContainer from './ConfiguratorTemplateListContainer';
import BaseBreadCrumb from '../baseContainers/BaseBreadCrumb';
import {CustomMessages} from '../components/CustomMessages';
import BaseContainer from '../baseContainers/BaseContainer';
import DivContainer from '../components/DivContainer';
import BlockUi from '../components/waitPanel/BlockUi';

class ConfiguratorNavContainer extends BaseContainer {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.authService = new AuthService();
        this.readMessage = this.readMessage.bind(this);
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    getActiveIndexCookieName() {
        return `configurator-nav-activeIndex`;
    }
    componentDidMount() {
        this.readActiveIndexFromCookie();
    }
   
    getContainerListName() {
        return 'configurator-template-list-container';
    }
    removeCookie(cookieName) {
        return sessionStorage.removeItem(cookieName);
    }
    showMessage(severity, summary, detail, life = 5000, closable = true, errMsg) {
        if (this.props.showMessage !== undefined && this.props.showMessage instanceof Function) {
            this.props.showMessage(severity, summary, detail, life, closable, errMsg);
        } else if (this.messages !== undefined && this.messages !== null) {
            this.messages.show({
                severity,
                summary,
                detail,
                life,
                closable,
            });
        } else {
            if (errMsg !== undefined) {
                console.log('this.messages === undefined', errMsg);
            } else {
                console.log('this.messages === undefined');
            }
        }
    }
    readMessage() {
        const messageFromStorage = this.readCookie(this.getContainerListName());
        if (messageFromStorage !== undefined && messageFromStorage != null) {
            const message = JSON.parse(messageFromStorage);
            this.removeCookie(this.getContainerListName());
            if (
                message.severity !== undefined &&
                message.severity != null &&
                message.summary !== undefined &&
                message.summary != null &&
                message.detail !== undefined &&
                message.detail != null
            ) {
                this.showMessage(message.severity, message.summary, message.detail);
            }
        }
    }
    readActiveIndexFromCookie() {
        let activeIndex = this.readCookie(this.getActiveIndexCookieName());
        if (activeIndex === undefined || activeIndex === null || activeIndex === '') {
            activeIndex = 0;
        }
        this.setState(
            {
                activeIndex: parseInt(activeIndex),
            },
            () => {
                this.saveCookie(this.getActiveIndexCookieName(), this.state.activeIndex);
                this.readMessage();
            }
        );
    }

    handleTabChange(e) {
        this.setState(
            {
                activeIndex: e.index,
            },
            () => {
                this.saveCookie(this.getActiveIndexCookieName(), this.state.activeIndex);
            }
        );
    }
    readCookie(cookieName) {
        return sessionStorage.getItem(cookieName);
    }
    saveCookie(cookieName, cookieValue) {
        sessionStorage.setItem(cookieName, cookieValue);
    }
    breadCrumbItems() {
        return [];
    }
    customBlockUi = () =>{
        this.setState({
            loading:true
        }, ()=>{
            setTimeout(()=>{
                this.setState({
                    loading:false
                })
            },100)
              
        })
    }
    render() {
        return (
            
            <DivContainer colClass=''>
            <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} rendered={!this.props.embedded} />
            
            <BlockUi
                tag='div'
                blocking={this.state.loading}
                loader={this.loader}
                rendered={true}
            >
                 <React.Fragment>
                <BaseBreadCrumb className='configurator-nav-breadcrumb' items={this.breadCrumbItems()} />
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} rendered={!this.props.embedded} />
                <TabView activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} className='configurator-nav'>
                    <TabPanel header={'Szablony'} key={`configurator-template-list${this.state.activeIndex}`}>
                        <ConfiguratorTemplateListContainer
                            current={true}
                            embedded={true}
                            handleTabChange={this.handleTabChange}
                            readMessage={this.readMessage}
                            customBlockUi = {this.customBlockUi}
                        />
                    </TabPanel>
                    {!this.authService.isUserInRole('ROLE_ADMIN_PACKAGE_MANAGE') ? (
                        <TabPanel
                            header={
                                this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TEMPLATE_MANAGE')
                                    ? 'Szablony do akceptacji'
                                    : 'Moje propozycje'
                            }
                            key={`configurator-template-list-to-accept-or-my-proposal-${this.state.activeIndex}`}
                        >
                            <ConfiguratorTemplateListContainer
                                current={false}
                                embedded={true}
                                handleTabChange={this.handleTabChange}
                                readMessage={this.readMessage}
                                customBlockUi = {this.customBlockUi}
                            />
                        </TabPanel>
                    ) : null}
                </TabView>
            </React.Fragment>
            </BlockUi>
        </DivContainer>


         
        );
    }
}
export default withTranslation()(ConfiguratorNavContainer);
