export default function Footer(props) {
    const {isMobile} =  props;
    const marginTop = isMobile ? {marginTop:"60px"} : {};
    return (
        <footer class='et-l et-l--footer root-child-when-footer'>
            <div class='et_builder_inner_content et_pb_gutters3'>
                <div class='et_pb_section et_pb_section_0_tb_footer et_pb_with_background et_section_regular'>
                    <div class='et_pb_row et_pb_row_0_tb_footer et_pb_gutters1 et_pb_row_col row'>
                        <div className='col-xl-3 col-lg-4 col-md-4 col-sm-12' style={marginTop} >
                            {' '}
                            <b>
                                <ul style={{color: '#2E3541'}}>
                                    <li>
                                        <span className='header-ul-wp content-ul-wp content-ul-wp'>Informacje prawne</span>
                                    </li>
                                    <li className='content-ul-wp content-li-small-wp'>
                                        {' '}
                                        <a
                                            href='https://leksy.pl/wp-content/uploads/2023/12/Regulamin-Leksy.pl_.pdf'
                                            style={{color: '#2e3541'}}
                                            className='unstyled-link'
                                        >
                                            Regulamin serwisu
                                        </a>
                                    </li>
                                    <li className='content-ul-wp content-li-small-wp'>
                                        {' '}
                                        <a
                                            href='https://leksy.pl/wp-content/uploads/2023/12/Polityka-prywatnosci-Leksy.pl_.pdf'
                                            style={{color: '#2e3541'}}
                                            className='unstyled-link'
                                        >
                                            Polityka prywatności
                                        </a>
                                    </li>
                                    <li className='content-ul-wp content-li-small-wp'>
                                        {' '}
                                        <a
                                            href='https://leksy.pl/wp-content/uploads/2023/12/Polityka-cookies.pdf'
                                            style={{color: '#2e3541'}}
                                            className='unstyled-link'
                                        >
                                            Regulamin cookies
                                        </a>
                                    </li>
                                    <li className='content-ul-wp content-li-small-wp'>
                                        {' '}
                                        <a
                                            href='https://leksy.pl/wp-content/uploads/2023/12/Informacja-o-zagrozeniach-Leksy-.pdf'
                                            style={{color: '#2e3541'}}
                                            className='unstyled-link'
                                        >
                                            Informacja o zagrożeniach
                                        </a>
                                    </li>
                                </ul>
                            </b>
                        </div>{' '}
                        <div className='col-xl-3 col-lg-4 col-md-4 col-sm-12' style={marginTop}>
                            {' '}
                            <b>
                                <ul style={{color: '#2E3541'}}>
                                    <li>
                                        <span className='header-ul-wp content-ul-wp content-ul-wp'>Obserwuj</span>
                                    </li>
                                    <li className='content-ul-wp content-li-small-wp'>
                                        <a
                                            href='https://www.facebook.com/profile.php?id=61554661228822'
                                            style={{
                                                color: '#2e3541',
                                            }}
                                            className='unstyled-link'
                                        >
                                            Facebook
                                        </a>
                                    </li>
                                    <li className='content-ul-wp content-li-small-wp'>
                                        <a
                                            style={{
                                                color: '#2e3541',
                                            }}
                                            href='https://www.linkedin.com/company/leksy/'
                                        >
                                            Linkedin
                                        </a>{' '}
                                    </li>
                                </ul>
                            </b>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-md-4 col-sm-12' style={marginTop}>
                            {' '}
                            <b>
                                <ul style={{color: '#2E3541'}}>
                                    <li>
                                        <span className='header-ul-wp content-ul-wp content-ul-wp'>Kontakt</span>
                                    </li>
                                    <li className='content-ul-wp content-li-small-wp'>biuro@leksy.pl</li>
                                    <li className='content-ul-wp content-li-small-wp'>tel. (32) 400 89 91</li>
                                    <li className='content-ul-wp content-li-small-wp'>tel. kom. 572 940 078</li>
                                </ul>
                            </b>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-md-4 col-sm-12' style={marginTop}>
                            {' '}
                            <b>
                                <ul style={{color: '#2E3541'}}>
                                    <li>
                                        <span className='header-ul-wp content-ul-wp content-ul-wp'>Adres</span>
                                    </li>
                                    <li className='content-ul-wp content-li-small-wp'>Plac Wolności 3 lok. 3A</li>
                                    <li className='content-ul-wp content-li-small-wp'>40-078 Katowice</li>
                                </ul>
                            </b>
                        </div>
                    </div>
                </div>
                <div className='container-fluid' id='copyright-footer'>
                    <div className='row et_pb_row_4col'>
                        <div class='col-12'>
                            <div class='float-start'>© Copyright by Leksy 2023</div>
                        </div>
                        <div class='col-12 '>
                            <div class='float-end'></div>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
