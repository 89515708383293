import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import ActionLink from '../../components/ActionLink';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import HierarchicalDictionaryService from '../../services/HierarchicalDictionaryService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import HierarchicalDictionaryDialog from './HierarchicalDictionaryDialog';
import DialogResizer from '../../components/DialogResizer';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';

class HierarchicalDictionaryListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new HierarchicalDictionaryService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }
    updateSearchCriteria(criteria) {
        return {
            name: criteria.name,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }
    getCleanSearchCriteria() {
        return {
            name: '',
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    getDataKey() {
        return 'id';
    }
    getListTitle() {
        return 'hierarchicalDictionary.listTitle';
    }

    getCriteriaName() {
        return 'hierarchical-dictionary-list-sc';
    }

    getContainerListName() {
        return 'hierarchical-dictionary-list-container';
    }
    datableClassname() {
        return 'narrow-list';
    }
    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name-sc'
                    name='name'
                    label='Nazwa słownika'
                    colClass='col-xl-6 col-lg-6 col-md-12 col-sm-12'
                    value={this.state.criteria.name}
                    placeholder='Wyszukaj...'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
            </div>
        );
    }

    prepareHeaderItems() {
        const {t} = this.props;
        return this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE')
            ? [
                  {type: 'LABEL', className: 'float-left'},
                  {
                      label: t('hierarchicalDictionary.addButton'),
                      onClick: this.handleOpenNewDialog,
                      type: 'BUTTON',
                      className: 'float-left',
                      variant: 'white',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                  },
              ]
            : [];
    }
    handleOpenDialog(rowData, e) {
        console.log(rowData, ' ', e);
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, elementId: rowData.id, dialogViewMode: 'EDIT'});
    }
    handleOpenNewDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, dialogViewMode: 'NEW'});
    }
    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: false, elementId: undefined}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
            if (callback) callback();
        });
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    prepareColumns() {
        return [
            {field: 'id', header: 'ID', sortable: true, width: '6em'},
            {field: 'name', header: 'Nazwa', sortable: true},
            {field: 'code', header: 'Kod', sortable: true},
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }
    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const detailHref = `${detailUrl}/${rowData.id}`;
        return (
            <div className='float-center'>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                    onClick={this.handleGoToDetail.bind(this, detailHref)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        );
    }
    breadCrumbItems() {
        return [{name: 'Słowniki', url: '/#/hierarchical-dictionary-list'}];
    }
    handleGoToDetail(href, e) {
        e.preventDefault();
        this.blockUi();
        const {criteria} = this.state;
        if (!this.props.hideFilters) {
            this.saveCookie(this.getCriteriaName(), JSON.stringify(criteria));
        }
        window.location.href = AppPrefixUtils.locationHrefUrl(href);
    }

    render() {
        return (
            <div className='container-fluid narrow-list'>
                {this.state.dialogVisibile ? (
                    <HierarchicalDictionaryDialog
                        key={`driver-dialog-${this.state.element?.pesel}`}
                        statusOptions={this.state.statusOptions}
                        roleOptions={this.state.roleOptions}
                        elementId={this.state.elementId}
                        onHide={(e) => {
                            this.handleCloseDialog(e);
                        }}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}
                        afterSave={(e) =>
                            this.handleCloseDialog(e, () => {
                                this.readMessage();
                                this.refreshTable();
                                this.unblockUi();
                            })
                        }
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                        className='hierarchical-dictionary-dialog'
                    />
                ) : null}
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                <React.Fragment>{this.renderView()}</React.Fragment>
            </div>
        );
    }
}

HierarchicalDictionaryListContainer.defaultProps = {
    detailUrl: '/#/hierarchical-dictionary/details',
    newUrl: '/#/hierarchical-dictionary/new',
    lazy: true,
};

HierarchicalDictionaryListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(HierarchicalDictionaryListContainer);
