import {classicNameResolver} from 'typescript';
import HtmlParserUtils from '../../utils/htmlParser/HtmlParserUtils';
import {v4 as uuidv4} from 'uuid';

// ex) A command plugin to add "Range format element(util.isRangeFormatElement)" to selection
var pluginAlignLeft = {
    // @Required @Unique
    // plugin name
    name: 'plugin_clear_blur',
    // @Required
    // data display
    display: 'command',

    // @Options
    buttonClass: '',

    add: function (core, targetElement) {},

    active: function (element) {
        return false;
    },

    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
        const clearBlur = function (sel, callback, blockingAction) {
            var range = sel.getRangeAt(0);
            let nodeToClear = range?.commonAncestorContainer;
            while (nodeToClear) {
                if (nodeToClear.nodeType === 3) {
                    nodeToClear = nodeToClear.parentNode;
                }
                let classListArray = undefined;
                if (nodeToClear?.classList.length) {
                    classListArray = Array.from(nodeToClear?.classList);
                } else if (nodeToClear?.offsetParent.classList.length) {
                    classListArray = Array.from(nodeToClear?.offsetParent.classList);
                    nodeToClear = nodeToClear?.offsetParent;
                }
                if (!classListArray) {
                    break;
                }
                if (classListArray?.includes('BLUR')) {
                    nodeToClear.classList.remove(HtmlParserUtils.getCustomTagClassName());
                    nodeToClear.removeAttribute(HtmlParserUtils.getTooltipAttributeForTemplate());
                    nodeToClear.removeAttribute('id');
                    nodeToClear.classList.remove('BLUR');
                }
                break;
            }
            callback();
        };
        if (window.getSelection()) {
            clearBlur(
                window.getSelection(),
                this.context?.options?.insertTag ? this.context?.options?.insertTag : () => {},
                this.context?.options?.blockingAction ? this.context?.options?.blockingAction : () => {}
            );
            this.history.push();
        }

        this.history.push();
    },
};
export default pluginAlignLeft;
