import React from 'react';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import DivContainer from '../../components/DivContainer';
import {ActionButton} from '../../components/ActionButton';
import InputCalendarComponent from '../../components/inputs/InputCalendarComponent';
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';
import {SelectButton} from 'primereact/selectbutton';
import {InputText} from 'primereact/inputtext';
import UserService from '../../services/UserService';
import ReportService from '../../services/ReportService';
import ReportWriteModel from '../../model/ReportWriteModel';
import UnregisteredUserService from '../../services/UnregisteredUserService';
import DialogResizer from '../../components/DialogResizer';

class ReportListDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new ReportService());
        this.state = {
            element: new ReportWriteModel(),
            viewMode: this.props.viewMode,
            activeIndex: 0,
            users: [],
        };
        this.handleChangePerson = this.handleChangePerson.bind(this);
        this.userType = [
            {
                name: 'Klient',
                enumValue: 'CLIENT',
            },
            {
                name: 'Niezarejestrowani',
                enumValue: 'UNREGISTERED_CLIENT',
            },
            {
                name: 'Prawnik',
                enumValue: 'LAWYER',
            },
        ];
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            let {element} = this.state;

            element.personType = element.personType.enumValue;

            this.scrollToTop();
            if (this._isMounted) {
                this.service
                    .add(element)
                    .then((response) => {
                        this.startDownloading(response.id, response.name, response.personType);
                    })
                    .catch((err) => {
                        this.persistMessage('error', '', err.message);
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                    });
            }
        } else {
            this.validator.showMessages();
            // this.scrollToError = true;
            this.forceUpdate();
        }
    }

    prepareFooter() {
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton
                    buttonType={'NEXT'}
                    label={'Generuj raport'}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                />
            </div>
        );
    }

    startDownloading(id, name, personType) {
        this.service
            .startDownloading({enumValue: 'xls'}, {id: id, personType: personType})
            .then((res) => {
                DialogResizer.removeMaskIfExistOneDialog();
                this.blockUi(undefined, 'Generowanie pliku rozpoczęte, proszę czekać...');
                this.intervalId = setInterval(this.getReportResult.bind(this, res.uuid, name), 100);
            })
            .catch((err) => {
                this.unblockUi(this.showErrorMessage(err.message));
            });
    }

    getReportResult(request, name) {
        this.service
            .getReportResult(request)
            .then((data) => {
                this.setState({showPrintDialog: false}, () => {
                    this.service.export(this.authService.getToken(), `${name}.xls`, this.showErrorMessage, 'xls', data.uuid);
                    clearInterval(this.intervalId);
                    this.unblockUi();
                });

                this.persistMessage('success', '', 'Zapisano raport');
                if (this.props.afterSave) {
                    this.props.afterSave();
                }
            })
            .catch((err) => {
                // status 422 zarezerwowany dla sytuacji gdy jeszcze nie ma wyniku;
                // w pozostałych sytaucjach błąd ma spowodować przerwanie zapytywania
                if (err.errorCode && err.errorCode !== 'REPORT_UNFINISHED') {
                    this.unblockUi();
                    this.showErrorMessage(err.message);
                    clearInterval(this.intervalId);
                }
            });
    }

    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>{t('reports.generateReport')}</label>
                </DivContainer>
            </DivContainer>
        );
    }

    getContainerListName() {
        return 'report-list-container';
    }

    getAddSucces() {
        const {t} = this.props;
        return t('report.addSuccessfull');
    }

    renderDetails() {
        return this.renderView();
    }

    handleChangePerson(e) {
        if (e.value?.enumValue === 'UNREGISTERED_CLIENT') {
            new UnregisteredUserService()
                .getAll()
                .then((response) => {
                    this.setState((prevState) => ({
                        element: {
                            ...prevState.element,
                            personType: e.value,
                            users: [],
                        },
                        users: response,
                    }));
                })
                .catch((err) => {
                    this.showErrorMessage('error', '', err.message);
                });
        } else {
            new UserService()
                .getByBasicPersonType(e.value?.enumValue)
                .then((response) => {
                    this.setState((prevState) => ({
                        element: {
                            ...prevState.element,
                            personType: e.value,
                            users: [],
                        },
                        users: response,
                    }));
                })
                .catch((err) => {
                    this.showErrorMessage('error', '', err.message);
                });
        }
    }

    initBeforeSetElement() {
        new UserService()
            .getByBasicPersonType(this.userType[0].enumValue)
            .then((response) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        personType: this.userType[0],
                    },
                    users: response,
                }));
            })
            .catch((err) => {
                this.showErrorMessage('error', '', err.message);
            });
    }
    prepareMinDateForDateFrom() {
        return new Date();
    }
    prepareMinDateForDateTo() {
        if (!this.state.element.dateFrom) {
            return this.nowPlus1Days;
        } else {
            let dateFrom = this.state.element.dateFrom;
            dateFrom.setHours(0, 0, 0, 0);
            return new Date(dateFrom.getTime() + 1 * 24 * 60 * 60 * 1000);
        }
    }
    renderDataTemplateHeader() {
        return (
            <div className='flex justify-content-between align-items-center'>
                <DivContainer className='col-lg-12'>
                    <span className='w-100 p-input-icon-left'>
                        <i className='pi pi-search' />
                        <InputText
                            value={this.state.globalFilterValue}
                            onChange={this.onGlobalFilterChange}
                            placeholder='Znajdź użytkownika'
                        />
                    </span>
                </DivContainer>
            </div>
        );
    }

    prepareValidationForDateTo() {
        if (!this.state.element.dateFrom) {
            return moment(this.nowPlus1Days).format('DD-MM-YYYY');
        } else {
            let dateFrom = this.state.element.dateFrom;
            dateFrom.setHours(0, 0, 0, 0);
            return moment(new Date(dateFrom.getTime() + 0 * 24 * 60 * 60 * 1000)).format('DD-MM-YYYY');
        }
    }

    renderView() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <SelectButton
                        id='userType'
                        value={this.state.element?.personType}
                        viewMode={'EDIT'}
                        optionLabel='name'
                        options={this.userType}
                        colClass='col-md-6 col-lg-6 '
                        onChange={this.handleChangePerson}
                    />

                    <InputCalendarComponent
                        id='dateFrom'
                        name='dateFrom'
                        label={'Data od'}
                        value={this.state.element.dateFrom}
                        validator={this.validator}
                        showIcon
                        colClass='col-md-12 col-sm-12'
                        validators={`required`}
                        onChange={(a, b, c) => {
                            this.setState((prevState) => ({
                                element: {
                                    ...prevState.element,
                                    dateFrom: c.value,
                                },
                            }));
                        }}
                        viewMode={this.state.viewMode}
                        yearRange={'2020:2090'}
                    />
                    <InputCalendarComponent
                        id='dateTo'
                        name='dateTo'
                        label={'Data do'}
                        value={this.state.element.dateTo}
                        validator={this.validator}
                        showIcon
                        colClass='col-md-12 col-sm-12'
                        validators={'required|after:' + this.prepareValidationForDateTo()}
                        onChange={(a, b, c) => {
                            this.setState((prevState) => ({
                                element: {
                                    ...prevState.element,
                                    dateTo: c.value,
                                },
                            }));
                        }}
                        yearRange={'2020:2090'}
                        viewMode={this.state.viewMode}
                    />

                    <InputMultiSelectComponent
                        id='users'
                        label='Użytkownicy'
                        name='users'
                        colClass='col-12'
                        value={this.state.element.users}
                        dataKey='id'
                        optionLabel='fullName'
                        filter
                        filterBy='fullName'
                        options={this.state.users}
                        optionValue='fullName'
                        validator={this.validator}
                        validators={'required|array_required'}
                        placeholder='Wybierz użytkownika'
                        viewMode={'EDIT'}
                        onChange={this.handleChange}
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
}

ReportListDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
};

ReportListDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
};

export default withTranslation()(ReportListDialog);
