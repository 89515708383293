import {InputText} from 'primereact/inputtext';
import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {FilterMatchMode} from 'primereact/api';
import MenuItemIcon from '../MenuItemIcon';

export const CriteriaTextComponent = (props) => {
    const {
        colClass,
        disabled,
        id,
        label,
        name,
        onChange,
        onAfterStateChange,
        placeholder,
        showLabel,
        validator,
        validators,
        value,
        refreshFromBackend,
        iconName,
        iconSide,
        iconSize,
        iconColor,
        iconSvg,
        global
    } = props;
    const required = validators !== undefined && validators.includes('required') && !validators.includes('not_required');
    return (
        <div className={colClass}>
            <div className='row'>
                <div className='col-md-12 form-group'>
                    {label !== undefined && showLabel ? (
                        // eslint-disable-next-line react/jsx-max-props-per-line
                        <label id={`${id}-label-id`} className='p-label' htmlFor={id} style={{width: '100%'}}>
                            {label}
                        </label>
                    ) : null}
                    <span className={`p-inputtext-view icon_text  ${iconName !== undefined ? iconColor : ''}`}>
                            {iconSide === 'left' && iconName !== undefined ? <i className={`icon mdi ${iconName} ${iconSize}`} /> : null}
                            {iconSide === 'left' && iconSvg !== undefined ? (
                                <MenuItemIcon alt='' className={`icon-svg ${iconSize}`} iconSvg={iconSvg} />
                            ) : null}

                            {iconSide === 'right' && iconName !== undefined ? <i className={`icon mdi ${iconName} ${iconSize}`} /> : null}
                        </span>
                    <InputText
                        aria-label={label}
                        aria-describedby={`${id}-error`}
                        aria-labelledby={label === undefined && showLabel ? `${id}-label-id` : undefined}
                        key={id}
                        id={id}
                        name={name}
                        type='text'
                        style={{width: '100%'}}
                        value={value}
                        placeholder={placeholder}
                        onChange={(e) =>
                            onChange
                                ? onChange('TEXT', !!global ?['global'] : undefined, e, FilterMatchMode.CONTAINS, refreshFromBackend, onAfterStateChange)
                                : null
                        }
                        disabled={disabled}
                        required={required}
                    />
                    <div aria-live='assertive'>{validator ? validator.message(id, label, value, validators) : null}</div>
                </div>
            </div>
        </div>
    );
};

CriteriaTextComponent.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    disabled: false,
    placeholder: '',
    showLabel: true,
    validators: 'not_required',
    refreshFromBackend: true,
    iconSide: 'left',
    global: false
};

CriteriaTextComponent.propTypes = {
    colClass: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onAfterStateChange: PropTypes.func,
    placeholder: PropTypes.string,
    showLabel: PropTypes.bool,
    validator: PropTypes.object.isRequired,
    validators: PropTypes.string,
    value: PropTypes.string,
    refreshFromBackend: PropTypes.bool,
    iconColor: PropTypes.string,
    iconName: PropTypes.string,
    iconSide: PropTypes.string,
    iconSize: PropTypes.string,
    iconSvg: PropTypes.string,
};

export default withTranslation()(CriteriaTextComponent);
