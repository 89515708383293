import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';

export default class AgreementService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'agreement-data';
    }

    getCriteria(criteria) {
        return this.objToQueryString({
            date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            date_to: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            text: criteria.text,
            categoryId: criteria.category?.id,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    getList(criteria) {
        const queryString = this.getCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/list-agreements${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getAgreementTemplateList(id) {
        return this.fetch(`${this.domain}/${this.path}/agreement-template-list/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    removeSelectedAgreementData(toRemove) {
        return this.fetch(`${this.domain}/${this.path}/remove-items`, {
            method: 'DELETE',
            body: JSON.stringify(toRemove),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
