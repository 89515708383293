import {t} from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../baseContainers/BaseListContainer';
import ActionLink from '../components/ActionLink';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import EmailService from '../services/EmailService';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import BaseBreadCrumb from '../baseContainers/BaseBreadCrumb';

class EmailListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new EmailService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            filters: {},
            emailTypeOptions: [],
        };
    }

    updateSearchCriteria(criteria) {
        return {
            emailType: criteria.emailType,
            description: criteria.description,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            emailType: undefined,
            description: '',
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }
    getDataKey() {
        return 'id';
    }
    getListTitle() {
        return 'email.listTitle';
    }
    getCriteriaName() {
        return 'email-list-sc';
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('EmailType', this.translate)
            .then((emailTypeOptions) => {
                this.setState({
                    emailTypeOptions,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy typów wiadomości');
            });
    }

    getContainerListName() {
        return 'email-list-container';
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaDropdownComponent
                    id='emailType-sc'
                    name='emailType'
                    showClear
                    label={t('email.type')}
                    colClass='col-xl-6 col-lg-6 col-md-12 col-sm-12'
                    value={this.state.criteria.emailType}
                    options={this.state.emailTypeOptions}
                    onAfterStateChange={() => console.log(this.state)}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
                <CriteriaTextComponent
                    id='description-sc'
                    name='description'
                    label={'Opis'}
                    placeholder='Opis...'
                    colClass='col-xl-3 col-lg-6 col-md-12 col-sm-12'
                    value={this.state.criteria.description}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
            </div>
        );
    }

    prepareColumns() {
        return [
            // { field: 'id', header: 'ID', sortable: true, width: '90px' },
            {
                field: 'emailType',
                header: 'Typ wiadomości',
                sortable: true,
                body: this.enumTemplate.bind(this, 'EmailType', 'emailType'),
            },
            {field: 'description', header: 'Opis', sortable: true},
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }
    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const detailHref = `${detailUrl}/${rowData.id}`;
        return (
            <div className='float-center'>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                    onClick={this.handleGoToDetail.bind(this, detailHref)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        );
    }
    handleGoBack(e) {
        e.preventDefault();
        this.blockUi();
        const {backUrl} = this.props;
        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
    }

    prepareHeaderItems() {
        return [];
    }
    breadCrumbItems() {
        return [{name: 'Szablony e-mail', url: '/#/email-template-list'}];
    }
    render() {
        return (
            <div className='container-fluid'>
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                {this.renderView()}
            </div>
        );
    }
}

EmailListContainer.defaultProps = {
    detailUrl: '/#/email-template/details',
    newUrl: '/#/email-template/create',
    editUrl: '/#/email-template/edit',
    lazy: true,
};

EmailListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(EmailListContainer);
