import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import ActionLink from '../../components/ActionLink';
import CriteriaCheckboxComponent from '../../components/criteria/CriteriaCheckboxComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import YesNoDialog from '../../components/YesNoDialog';
import EnumService from '../../services/EnumService';
import SubscriptionService from '../../services/SubscriptionService';
import SubscriptionDialog from './SubscriptionDialog';
import DialogResizer from '../../components/DialogResizer';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';

class SubscriptionListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new SubscriptionService());
        this.enumService = new EnumService();
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            packageId: undefined,
            activationDialogVisibile: false,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: ['Aktywny', 'Nieaktywny'],
            roleOptions: [],
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleOpenActivationDialog = this.handleOpenActivationDialog.bind(this);
        this.handleCloseActivationDialog = this.handleCloseActivationDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    handleOpenActivationDialog(e, id) {
        this.setState({activationDialogVisibile: true, packageId: id});
    }
    handleCloseActivationDialog(e) {
        this.setState({activationDialogVisibile: false});
    }

    getDataKey() {
        return 'id';
    }

    getListTitle() {
        return 'subscription.listTitle';
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    updateSearchCriteria(criteria) {
        return {
            name: criteria.name,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            onlyActive: criteria.onlyActive,
        };
    }

    getCleanSearchCriteria() {
        return {
            onlyActive: true,
            name: '',
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: false,
        };
    }

    getCriteriaName() {
        return 'subscription-list-sc';
    }

    getContainerListName() {
        return 'subscription-list';
    }
    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name-sc'
                    name='name'
                    label={'Nazwa'}
                    colClass='col-lg-4 col-sm-6'
                    placeholder='Wpisz nazwę pakietu'
                    value={this.state.criteria.name}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
                <CriteriaCheckboxComponent
                    id='onlyActive'
                    name='onlyActive'
                    label='Pokaż tylko aktywne'
                    value={this.state.criteria.onlyActive}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    colClass='col-lg-4 col-sm-6'
                    refreshFromBackend
                />
            </div>
        );
    }

    prepareColumns() {
        return [
            {field: 'id', header: 'ID', sortable: true},
            {field: 'name', header: 'Nazwa', sortable: true},
            {field: 'counter', header: 'Ilość subskrypcji', sortable: true},
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <div className='float-center'>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                    onClick={this.handleGoToDetail.bind(this, href)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        );
    }
    //override
    prepareHeaderItems() {
        const {t} = this.props;
        return this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE', 'ROLE_ADMIN_PACKAGE_MANAGE')
            ? [
                  {type: 'LABEL', className: 'float-left'},
                  {
                      label: t('subscription.addButton'),
                      onClick: this.handleOpenNewDialog,
                      type: 'BUTTON',
                      variant: 'white',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                  },
              ]
            : [];
    }
    handleOpenDialog(rowData, e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, elementId: rowData.id, dialogViewMode: 'EDIT'});
    }
    handleOpenNewDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, dialogViewMode: 'NEW'});
    }
    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: false, elementId: undefined}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
            if (callback) callback();
        });
    }
    breadCrumbItems() {
        return [{name: 'Pakiety abonamentowe', url: '/#/subscription-list'}];
    }
    render() {
        return (
            <div className='container-fluid'>
                {this.state.dialogVisibile && (
                    <SubscriptionDialog
                        key={`driver-dialog-${this.state.element?.pesel}`}
                        statusOptions={this.state.statusOptions}
                        roleOptions={this.state.roleOptions}
                        elementId={this.state.elementId}
                        handleOpenActivationDialog={this.handleOpenActivationDialog}
                        onHide={(e) => {
                            this.handleCloseDialog(e);
                        }}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}
                        afterSave={(e) =>
                            this.handleCloseDialog(e, () => {
                                this.readMessage();
                                this.refreshTable();
                                this.unblockUi();
                            })
                        }
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                        className={'subscription-dialog'}
                    />
                )}

                {this.state.activationDialogVisibile && (
                    <YesNoDialog
                        visible={this.state.activationDialogVisibile}
                        header='Aktywacja pakietu'
                        onChange={(type, x, target) => {
                            if (target.value) {
                                this.service
                                    .activateSubscription(this.state.packageId)
                                    .then(() => {
                                        this.refreshTable();
                                    })
                                    .catch(() => {
                                        this.showErrorMessage('Pakiet został utowrzony ale nie można go aktywować');
                                    });
                                this.setState({
                                    activationDialogVisibile: false,
                                });
                            } else {
                                this.handleCloseActivationDialog(this.state.dialogValue);
                            }
                        }}
                        onHide={() => {
                            this.handleCloseActivationDialog(this.state.activationDialogVisibile);
                            DialogResizer.removeMaskIfExistOneDialog();
                        }}
                        yesButtonLabel={'Tak'}
                        noButtonLabel={'Nie'}
                    >
                        Czy chcesz aktywować pakiet?
                    </YesNoDialog>
                )}
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                {this.renderView()}
            </div>
        );
    }
}

SubscriptionListContainer.defaultProps = {
    detailUrl: '/#/subscription/details',
    newUrl: '/#/subsctiption/create',
    lazy: true,
};

SubscriptionListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(SubscriptionListContainer);
