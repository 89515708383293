import ScheduledConsultationService from '../ScheduledConsultationService';

export default class ScheduledConsultationPublicService extends ScheduledConsultationService {
    constructor() {
        super();
        this.path = 'public/scheduledConsultations';
    }

    getScheduledEventsByClientId(id) {
        return this.fetch(`${this.domain}/${this.path}/get-newest-consultation/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
