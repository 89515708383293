import PropTypes from 'prop-types';
import React from 'react';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import {device} from '../baseContainers/BaseContainer';

export const AgreementConfiguratorTemplateList = (props) => {
    const {list, editClickEvent} = props;

    const renderElement = (element) => {
        console.log(element);
        const colName = device.type === 'mobile' ? 'col-8' : 'col-6';
        const colId = device.type === 'mobile' ? 'col-4' : 'col-2';
        const colDate = device.type === 'mobile' ? 'col-6' : 'col-2';
        const colStatus = device.type === 'mobile' ? 'col-6' : 'col-2';
        const colCategoryName = device.type === 'mobile' ? 'col-12' : 'col-6';
        const colEdit = device.type === 'mobile' ? 'col-12' : 'col-6';

        return (
            <div className='configurator-template-list-view'>
                <div className='row'>
                    <div className={`name        ${colName}`}>{element?.name}</div>
                    <div className={`id          ${colId}`}> ID:[{element?.id}] </div>
                    <div className={`date        ${colDate}`}>{truncateDateString(element?.createDate)}</div>
                    <div className={`status      ${colStatus}`}>{statuImageSelector(element?.status)}</div>
                </div>
                <div className='row mt-2'>
                    <div className={`categoryName    ${colCategoryName}`}>
                        {element?.category?.name} | {typeTranslateSelector(element?.agreementTemplateType)} | {element?.author}
                    </div>
                    <div className={`edit            ${colEdit}`}>
                        <img
                            className='cursor-pointer'
                            onClick={() => editClickEvent(element)}
                            src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/edit.svg')}
                            alt=''
                        />
                    </div>
                </div>
            </div>
        );
    };

    const truncateDateString = (date) => {
        const separator = 'T';
        const parts = date.split(separator);
        return parts.shift();
    };

    const typeTranslateSelector = (type) => {
        switch (type) {
            case 'TEMPLATE':
                return 'Szablon';
            case 'PARTIAL_TEMPLATE':
                return 'Subszablon';
            default:
                return '';
        }
    };

    const statuImageSelector = (status) => {
        const link = AppPrefixUtils.locationHrefUrl('/images/lexdocs/');
        switch (status) {
            case 'ACCEPTED':
                return <img alt='Zaakceptowany' className='status-img' src={link + 'done_green.svg'} />;
            case 'NEW':
                return <img alt='' className='status-img' src={link + 'hourglass.svg'} />;
            case 'REJECTED':
                return <img alt='' className='status-img' src={link + '.svg'} />;
            default:
                return <img alt='' className='status-img' src={link + 'done_green.svg'} />;
        }
    };

    const renderElements = (list) => {
        return list.map((el) => {
            return renderElement(el);
        });
    };

    React.useEffect(() => {}, []);
    return <div className='container-fluid'>{renderElements(list)}</div>;
};

AgreementConfiguratorTemplateList.defaultProps = {};

AgreementConfiguratorTemplateList.propTypes = {
    content: PropTypes.object,
};

export default React.memo(AgreementConfiguratorTemplateList);
