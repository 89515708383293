import BaseServiceCrud from './BaseServiceCrud';

export default class HierarchicalDictionaryItemService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'hierarchical/items';
	}

	update(element) {
		// Get a token from api server using the fetch api
		return this.fetch(`${this.domain}/${this.path}/edit/${element.id}`, {
			method: 'PUT',
			body: JSON.stringify(element),
		}).then((res) => {
			return Promise.resolve(res);
		});
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			code: criteria.code,
			first_result: criteria.firstResult,
			max_results: criteria.maxResults,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
}
