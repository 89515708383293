import HtmlParserUtils from '../../utils/htmlParser/HtmlParserUtils';
import {v4 as uuidv4} from 'uuid';

// ex) A command plugin to add "Range format element(util.isRangeFormatElement)" to selection
var pluginAlignLeft = {
    // @Required @Unique
    // plugin name
    name: 'plugin_blur',
    // @Required
    // data display
    display: 'command',

    // @Options
    title: 'Add range tag',
    buttonClass: '',

    add: function (core, targetElement) {
       
    },
    active: function (element) {
        
        return false;
    },

    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
        const isSelectionInsideList = function (selectionNode) {
            return (
                [...document.querySelectorAll('ol')].find((el) => el.contains(selectionNode)) ||
                [...document.querySelectorAll('ul')].find((el) => el.contains(selectionNode))
            );
        };
        const insertCustomTagIntoSelection = function (sel, callback, blockingAction) {
            let children;
            let nodes = [];
            var range = sel.getRangeAt(0);
            children = function (parent) {
                var child, nodes;
                nodes = [];
                child = parent.firstChild;

                while (child) {
                    if (HtmlParserUtils.isBlockLevelElement(child)) {
                        nodes.push(child);
                    }
                    nodes = nodes.concat(children(child));
                    child = child.nextSibling;
                }

                return nodes;
            };

            nodes = children(range.commonAncestorContainer);
            const multilineTag = nodes?.length > 0;

            const newNode = window.document.createElement(multilineTag ? 'div' : 'span');
            if (multilineTag && isSelectionInsideList(range.commonAncestorContainer)) {
                blockingAction();
                return;
            }
            newNode.className = `${HtmlParserUtils.getCustomTagClassName()} BLUR`;
            newNode.setAttribute(HtmlParserUtils.getTooltipAttributeForTemplate(), 'zamazanie');
            newNode.id = uuidv4();
            newNode.appendChild(range.extractContents());
            range.insertNode(newNode);
            //todo czy na pewno multiline?
            if (multilineTag) {
                const separatorParagraph = window.document.createElement('p');
                separatorParagraph.appendChild(window.document.createElement('br'));
                newNode.parentNode.insertBefore(separatorParagraph, newNode.nextSibling);
            }
            callback();
        };
        if (window.getSelection()) {
            insertCustomTagIntoSelection(
                window.getSelection(),
                this.context?.options?.insertTag ? this.context?.options?.insertTag : () => {},
                this.context?.options?.blockingAction ? this.context?.options?.blockingAction : () => {}
            );
            this.history.push();
        }

        this.history.push();
    },
};
export default pluginAlignLeft;
