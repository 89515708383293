import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import BaseContainer from '../../baseContainers/BaseContainer';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import SimpleReactValidator from '../../components/validator';
import ActionButton from './../../components/ActionButton';
import ActionLink from './../../components/ActionLink';
import {ReminerType} from './../../containers/chat/ChatContainer';
import './chat.scss';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import HandleChangeUtils from '../../components/utils/HandleChangeUtils';

let stopScroling = false;

export class ChatHeader extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            minimalized: false,
            conversationCloseDialog: false,
            consultationEnded: false,
            isNotification15minBeforeEndRead: true,
            isNotification5minBeforeEndRead: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.endTime) {
            this.interval = setInterval(() => {
                if (this.props.stopTimer) {
                    clearInterval(this.interval);
                }

                const diff = moment.duration(this.props.endTime.diff(moment()));
                let remainingTime = moment.duration(diff).format('mm:ss');
                if (remainingTime < 0) {
                    remainingTime = 0;
                }
                if (this._isMounted) {
                    this.setState({remainingTime});
                }

                const stopTime = moment('9999-01-01T00:00:00.000Z').format('mm:ss');

                const reminderTwentyMinutesBeforeEnd = moment('9999-01-01T00:20:00.000Z').format('mm:ss');
                const reminderFifteenMinutesBeforeEnd = moment('9999-01-01T00:15:00.000Z').format('mm:ss');

                if (remainingTime === reminderTwentyMinutesBeforeEnd) {
                    this.props.showReminderDialog(ReminerType.TWENTY_MINUTES);
                }
                if (remainingTime === reminderFifteenMinutesBeforeEnd) {
                    this.props.showReminderDialog(ReminerType.FIFTEEN_MINUTES);
                }

                if (remainingTime < stopTime) {
                    this.setState({
                        consultationEnded: true,
                    });
                    this.close();
                }

                //console.log('@@@', this.props.stopTimer, this.props.endTime);
            }, 1000);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    minimalize() {
        this.setState(
            {
                minimalized: !this.state.minimalized,
            },
            () => this.props.onMinimalize(this.state.minimalized)
        );
    }

    close(event) {
        clearInterval(this.interval);
        this.props.onClose(event, this.state.consultationEnded);
    }

    render() {
        return (
            <div className={`chat-header ${this.props.className}`}>
                <div className='d-flex bd-highlight mb-3'>
                    <div className='mr-auto p-2 bd-highlight'>
                        <label> {this.state.remainingTime ? '(pozostało ' + this.state.remainingTime + ')' : ''}</label>
                    </div>
                    <div className='buttons-videoHeader bd-highlight'>
                        {this.props.type !== 'CLIENT' && (
                            <span>
                                <ActionLink
                                    id={'minimize-button'}
                                    label=''
                                    handleClick={this.minimalize.bind(this)}
                                    iconName='mdi-window-minimize'
                                    iconColor='white'
                                    rendered={!this.state.minimalized}
                                />
                                <ActionLink
                                    id={'maximize-button'}
                                    label=''
                                    handleClick={this.minimalize.bind(this)}
                                    iconName='mdi-window-maximize'
                                    iconColor='white'
                                    rendered={this.state.minimalized}
                                />
                            </span>
                        )}
                        <span>
                            <img
                                src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/close.svg')}
                                alt=''
                                id='close-button'
                                onClick={this.close.bind(this)}
                                className='cursor-pointer'
                            />
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

ChatHeader.defaultProps = {
    stopTimer: false,
};

ChatHeader.propTypes = {
    endTime: PropTypes.object,
    stopTimer: PropTypes.bool,
    onMinimalize: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
};

class Chat extends BaseContainer {
    constructor(props) {
        super(props);
        this.messagesEnd = undefined;
        this.inputMessage = undefined;
        this.renderChatWindow = this.renderChatWindow.bind(this);

        this.conversationId = props.conversationId;
        this.state = {
            nextPrevent: this.authService.loggedIn() ? false : true,
            element: {
                name: '',
                message: '',
            },
        };
    }

    componentDidMount() {
        super.componentDidMount();
        moment.locale('pl');
        this.scrollToBottom();
    }

    renderMessage() {
        return (
            <span>
                <div className='text-left p-2'>
                    <span>{this.props.message}</span>
                </div>
            </span>
        );
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom = () => {
        if (stopScroling) {
            return;
        }
        if (this.messagesEnd) {
            let scroll = document.getElementsByClassName('form-elegant')[0];
            scroll.scrollTop = scroll.scrollHeight;
        }
    };

    handleChange(inputType, parameters, event, onAfterStateChange, stateField) {
        stopScroling = true;
        HandleChangeUtils.handleChange(inputType, parameters, event, onAfterStateChange, stateField, this.handleChangeSetState, this.state);
    }

    renderChatWindow() {
        var msgs = this.props.messages;
        var convId = this.conversationId;
        if (!msgs) {
            msgs = [];
        }
        msgs = msgs.map((m) => {
            let myMessage = false;
            if (this.props.type === 'CLIENT') {
                if (m.sentByConsultant === false) {
                    myMessage = true;
                }
            } else {
                if (m.sentByConsultant === true) {
                    myMessage = true;
                }
            }
            m.sender = myMessage;
            return m;
        });

        msgs = msgs.map((item, id) => (
            <div className={`answer ${item.sender ? 'right' : 'left'}`} key={this.props.conversationId + '-' + id}>
                <div className='name'>{item.senderName}</div>
                {item.type === 'error' ? (
                    <div className='badge rounded-pill bg-danger text-break'>{item.text}</div>
                ) : item.type === 'warning' ? (
                    <div className='badge rounded-pill bg-warning-chat text-break'>{item.text}</div>
                ) : (
                    <div className='text text-break'>{item.text}</div>
                )}
                <div className='time'>{moment(item.time, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}</div>
            </div>
        ));
        return (
            <span>
                <div id={convId} className='content container-fluid bootstrap snippets'>
                    <div>
                        <section className='form-elegant scrollbar-light-blue'>
                            <div className='col-inside-lg decor-default'>
                                <div className='chat-body'>
                                    <div id='chat-messages'>
                                        {msgs}
                                        <div
                                            style={{
                                                float: 'left',
                                                clear: 'both',
                                            }}
                                            ref={(el) => {
                                                this.messagesEnd = el;
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div>
                    <form
                        className='chat-form'
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                console.log(' submit message on enter:', this.state.element.message);
                                e.preventDefault();
                                this.setState({
                                    element: {
                                        message: '',
                                    },
                                });
                                if (this.state.element.message) {
                                    this.props.handleSendAction(e, this.state.element.message, this.props.conversationId);
                                }
                            }
                        }}
                    >
                        {(this.props.viewMode === 'CHAT_WINDOW' || this.props.viewMode === 'BLOCKED') && (
                            <InputTextareaComponent
                                id='msg'
                                name='message'
                                label={'Wiadomość'}
                                colClass='col-md-12'
                                value={this.state.element.message}
                                validator={this.validator}
                                validators='required'
                                onAfterStateChange={() => {
                                    stopScroling = false;
                                }}
                                onChange={this.handleChange}
                                viewMode={'EDIT'}
                            />
                        )}

                        <ActionButton
                            label={'Wyślij'}
                            key={'exit-button'}
                            disabled={this.props.viewMode === 'BLOCKED' || this.props.viewMode === 'USER_DISCONNECTED'}
                            handleClick={(event) => {
                                if (this.state.element.message) {
                                    this.props.handleSendAction(event, this.state.element.message, this.props.conversationId);
                                    this.setState({
                                        element: {
                                            message: '',
                                        },
                                    });
                                }
                            }}
                            className='float-right mb-4 '
                        />
                        <ActionButton
                            rendered={this.props.agreementUuid !== undefined}
                            label={'Wróć do szablonu'}
                            key={'back-template-button'}
                            handleClick={() => {
                                this.props.backToTemplate(this.props.agreementUuid);
                            }}
                            className='float-left mb-1 cancel-button gray'
                        />
                    </form>
                    <span className='text-danger'>{this.props.message}</span>
                </div>
            </span>
        );
    }

    render() {
        return (
            <div className='fixed-bottom'>
                <div className={`wrapperChat ${this.isLexyPortal() ? 'lexy' : 'lexdocs'}`}>
                    <ChatHeader
                        onMinimalize={(state) => {
                            this.setState({minimalized: state});
                        }}
                        showReminderDialog={(reminderType) => {
                            if (this.props.type === 'CLIENT') {
                                this.props.showReminderDialog(this.props.conversationId, reminderType);
                            }
                        }}
                        onClose={(event, consultationEnded) => {
                            if (consultationEnded) {
                                if (this.props.type === 'CLIENT') {
                                    this.props.handleCloseAction(this.props.conversationId);
                                    this.showSuccessMessage('Zakończono kosnultacje.');
                                }
                            } else if (this.props.viewMode !== 'USER_DISCONNECTED') {
                                this.props.showEndConversationDialog(this.props.conversationId);
                            }
                            if (event) {
                                if (this.props.viewMode === 'USER_DISCONNECTED') {
                                    this.props.handleCloseAction(this.props.conversationId);
                                    return;
                                }
                                this.props.showEndConversationDialog(this.props.conversationId);
                            }
                        }}
                        endTime={this.props.endTime}
                        stopTimer={this.props.stopTimer}
                    />
                    {!this.state.minimalized && <React.Fragment>{this.renderChatWindow()}</React.Fragment>}
                </div>
            </div>
        );
    }
}

Chat.defaultProps = {
    element: undefined,
    viewMode: 'VIEW',
    publicRegistration: false,
    showAccountType: true,
    type: 'CLIENT',
};

Chat.propTypes = {
    element: PropTypes.object,
    viewMode: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSendAction: PropTypes.func.isRequired,
    handleCloseAction: PropTypes.func,
    message: PropTypes.string,
    messages: PropTypes.array,
    validator: PropTypes.instanceOf(SimpleReactValidator).isRequired,
    type: PropTypes.string,
    conversationId: PropTypes.string,
    maxTime: PropTypes.string,
    stopTimer: PropTypes.bool,
};

export default Chat;
