export default class AgreementUtils {
    static cleanAnswers(copied) {
        copied.booleanValue = true;
        copied.dateValue = undefined;
        copied.stringValue = '';
        copied.hierarchicalDictionary = undefined;
        copied.objectValueStr = '';
        copied.objectValue = undefined;
        return copied;
    }

    static isContractor(loggedIn, viewMode) {
        const urlStr = window.location.toString().replace('/#', '');
        const url = new URL(urlStr);
        const contractor = url.searchParams.get('contractor');
        return !loggedIn && contractor === 'true' && viewMode === 'EDIT';
    }

    static stepperVisibility(loggedIn, isContractor, documentStatus) {
        if (loggedIn !== true) {
            if (isContractor) {
                if (documentStatus !== 'SENT') return false;
            } else if (documentStatus) {
                if (documentStatus !== 'BEFORE_GENERATE') {
                    return false;
                } else if (documentStatus === 'GENERATED') {
                    return false;
                }
            }
        }
        return true;
    }

    static isEmptyValue(value, validator) {
        switch (value.parameterType) {
            case 'TEXT':
                return validator.helpers.isBlank(value.stringValue) && validator.helpers.isBlank(value.stringValue);
            case 'DATE':
                return validator.helpers.isBlank(value.dateValue);
            case 'DICTIONARY':
                return validator.helpers.isBlank(value.hierarchicalDictionary);
            case 'CHECKBOX':
                return value.booleanValue === false;
            case 'SELECT_ONE':
                return validator.helpers.isBlank(value.objectValue);
            case 'MULTI_LIST':
                return !(Array.isArray(value.answerOptions) && value.answerOptions.filter((ao) => !!ao.selected).length > 0);
            case 'NUMBER':
                return validator.helpers.isBlank(value.numberValue);
            default:
                return false;
        }
    }
    static backToOldValue(component, valuesMap, oldValuesMap) {
        switch (component.type) {
            case 'TEXT':
                valuesMap[component.uuid].stringValue = oldValuesMap[component.uuid].stringValue;
                break;
            case 'DATE':
                valuesMap[component.uuid].dateValue = oldValuesMap[component.uuid].dateValue;
                break;
            case 'DICTIONARY':
                valuesMap[component.uuid].hierarchicalDictionary = oldValuesMap[component.uuid].hierarchicalDictionary;
                break;
            case 'CHECKBOX':
                valuesMap[component.uuid].booleanValue = oldValuesMap[component.uuid].booleanValue;
                break;
            case 'SELECT_ONE':
                valuesMap[component.uuid].objectValue = oldValuesMap[component.uuid].objectValue;
                break;
            case 'MULTI_LIST':
                valuesMap[component.uuid].answerOptions = structuredClone(oldValuesMap[component.uuid].answerOptions);
                break;
            case 'NUMBER':
                valuesMap[component.uuid].numberValue = oldValuesMap[component.uuid].numberValue;
                valuesMap[component.uuid].answerCurrency = oldValuesMap[component.uuid].answerCurrency;
                break;
            default:
                console.log('no component with this parameterType');
                break;
        }
        valuesMap[component.uuid].changed = false;
        return valuesMap;
    }
    static answerIsInActiveStep(answer, currentStepUUID) {
        const result = answer.stepUuids && answer.stepUuids.filter((a) => a === currentStepUUID).length > 0;
        return result;
    }
}
