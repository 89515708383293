import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import ReportService from '../../services/ReportService';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import ActionLink from '../../components/ActionLink';
import ReportListDialog from './ReportListDialog';
import moment from 'moment';
import AuthService from '../../services/AuthService';
import DialogResizer from '../../components/DialogResizer';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';

class ReportListContainer extends BaseListContainer {
    constructor(props) {
        super(props);
        this.service = new ReportService();
        this.state = {
            name: '',
            selectedList: [],
            loading: true,
            size: 0,
            dialogVisibile: false,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.generateReports = this.generateReports.bind(this);
        this.authService = new AuthService();
    }

    getCleanSearchCriteria() {
        return {
            firstResult: 0,
            maxResults: 10,
            sortField: 'name',
            sortAsc: false,
        };
    }
    updateSearchCriteria(criteria) {
        return {
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getDataKey() {
        return 'id';
    }

    getListTitle() {
        return 'reports.listTitle';
    }

    getCriteriaName() {
        return 'report-list-scc';
    }

    getContainerListName() {
        return 'report-list-container';
    }

    prepareHeaderItems() {
        const {t} = this.props;
        let {selectedList} = this.state;
        let isDisabled = true;
        if (selectedList.length) {
            isDisabled = false;
        }

        return this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_PAYMENT_MANAGE')
            ? [
                  {type: 'LABEL', className: 'float-left'},
                  {
                      label: t('Generuj raport'),
                      onClick: this.handleOpenNewDialog,
                      type: 'BUTTON',
                      variant: 'white',
                      iconName: 'mdi-plus',
                      rendered: true,
                      customClassName: 'custom-list-btn',
                      iconRight: 'add_blue.svg',
                      iconSide: 'left',
                  },
                  {
                      label: 'Pobierz',
                      onClick: () => {
                          this.generateReports(selectedList);
                      },
                      type: 'BUTTON',
                      rendered: true,
                      variant: 'yellow',
                      disabled: isDisabled,
                      customClassName: 'custom-list-btn',
                      iconRight: 'download.svg',
                      iconSide: 'right',
                      iconColor: 'white',
                  },
              ]
            : [];
    }

    generateReports(reports) {
        this.service.downloadZip(this.authService.getToken(), reports);
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name'
                    name='name'
                    label='Nazwa'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.name}
                    placeholder='Wyszukaj...'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
            </div>
        );
    }

    handleOpenNewDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            dialogVisibile: true,
            dialogViewMode: 'NEW',
        });
    }

    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState(
            {
                dialogVisibile: false,
                elementId: undefined,
            },

            () => {
                if (callback) callback();

                DialogResizer.removeMaskIfExistOneDialog();
            }
        );
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    prepareColumns() {
        return [
            {
                field: 'createDate',
                header: 'Data wygenerownia',
                sortable: true,
                body: this.dateTemplate.bind(this, 'createDate', 'DD.MM.YYYY'),
            },
            {
                field: 'name',
                header: 'Nazwa raportu',
                sortable: true,
            },
        ];
    }

    dateTemplate(field, format, rowData) {
        if (rowData[field] && moment(rowData[field]).isValid()) {
            return moment(rowData[field]).format(format);
        } else {
            return '';
        }
    }
    getCleanElement() {
        this.setState({
            element: {
                dateFrom: undefined,
                dateTo: undefined,
                columns: [],
            },
        });
    }

    generateReport(element) {
        const {t} = this.props;
        this.blockUi();
        this.service
            .generateReport(element)
            .then(() => {
                this.setState({showDialog: false, showSecondDialog: false});
                this.refreshTable();
                this.unblockUi();
            })
            .catch(() => {
                this.setState({showDialog: false, showSecondDialog: false});
                this.showErrorMessage(t('error.reportsGenerateError'));
                this.unblockUi();
            });
    }

    handleOpenDialog(rowData, e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            dialogVisibile: true,
            elementId: rowData.id,
            name: rowData.name,
            dialogViewMode: 'EDIT',
        });
    }
    handleValidForm() {
        this.unblockUi();
        this.setState({showSecondDialog: true});
    }
    breadCrumbItems() {
        return [{name: 'Raporty płatności', url: '/#/report-list'}];
    }
    renderFilter() {}
    render() {
        return (
            <React.Fragment>
                <div className='container-fluid'>
                    {this.state.dialogVisibile ? (
                        <ReportListDialog
                            className='report-list-dialog'
                            statusOptions={this.state.statusOptions}
                            elementId={this.state.elementId}
                            onHide={(e) => {
                                this.handleCloseDialog(e);
                            }}
                            visible={this.state.dialogVisibile}
                            viewMode={this.state.dialogViewMode}
                            afterSave={(e) =>
                                this.handleCloseDialog(e, () => {
                                    this.readMessage();
                                    this.refreshTable();
                                    this.unblockUi();
                                })
                            }
                            blockUi={this.blockUi}
                            unblockUi={this.unblockUi}
                            _isMounted={this._isMounted}
                        />
                    ) : null}
                    <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                    {this.renderView()}
                </div>
            </React.Fragment>
        );
    }
}

ReportListContainer.defaultProps = {
    detailUrl: '/#/category/details',
    newUrl: '/#/category/new',
    editUrl: '/#/category/edit',
    disableSelection: false,
    selectionMode: 'multiple',
    lazy: true,
};

ReportListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(ReportListContainer);
