import {Messages} from 'primereact/messages';
import React from 'react';
import {Link} from 'react-router-dom';
import BaseContainer from '../../baseContainers/BaseContainer';
import ActionButton from '../../components/ActionButton';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import BlockUi from '../../components/waitPanel/BlockUi';
import UserService from '../../services/UserService';
import {withTranslation} from 'react-i18next';

class ResetPasswordContainer extends BaseContainer {
    constructor() {
        super();
        this.state = {
            loginOrEmail: '',
        };
    }

    componentWillMount() {
        this.setState({
            valid: true,
            loginOrEmail: '',
        });
        this.readMessage();
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    readMessage() {
        const messageFromStorage = this.readCookie('password-reset');
        if (messageFromStorage !== undefined && messageFromStorage != null) {
            const message = JSON.parse(messageFromStorage);
            this.removeCookie('password-reset');
            if (
                message.severity !== undefined &&
                message.severity != null &&
                message.summary !== undefined &&
                message.summary != null &&
                message.detail !== undefined &&
                message.detail != null
            ) {
                this.messages.show({
                    severity: message.severity,
                    summary: message.summary,
                    detail: message.detail,
                });
            }
        }
    }

    getContainerListName() {
        return 'login-page';
    }

    handleFormSubmit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.blockUi();
            new UserService()
                .sendLinkForGenerateNewPassword(this.state.loginOrEmail)
                .then(() => {
                    this.showSuccessMessage(
                        'Jeśli wprowadziłeś poprawne dane to na skrzynce pocztowej otrzymasz maila z linkiem do zresetowania hasła.'
                    );
                    this.validator.hideMessages();
                    this.forceUpdate();
                    this.unblockUi();
                })
                .catch((err) => {
                    this.showErrorMessage(err.message);
                    this.unblockUi();
                });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.setState({
                valid: false,
            });
            this.forceUpdate();
        }
    }

    render() {
        let valid = this.state.valid;
        return (
            <React.Fragment>
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                    <div id='main-login'>
                        <Messages id='custom-messages' ref={(el) => (this.messages = el)} />

                        <div className='container'>
                            <div className=''>
                                <div className='col-sm-12 col-md-10 col-lg-6 mx-auto'>
                                    <div className='card card-signin my-5'>
                                        <div className='card-body'>
                                            <div className='float-right'>
                                                <Link to='/'>
                                                    <span className='p-button-text p-c'>{'Wróć'}</span>
                                                </Link>
                                            </div>
                                            <h5 className='card-title text-align-left'>{'Resetowanie hasła'}</h5>
                                            <form className='form-signin' onSubmit={this.handleFormSubmit}>
                                                <InputTextComponent
                                                    id='loginOrEmail'
                                                    name='loginOrEmail'
                                                    label='Email'
                                                    classLabel='black-label'
                                                    value={this.state.loginOrEmail}
                                                    stateField=''
                                                    onChange={this.handleChange}
                                                    className={valid ? 'form-control' : 'form-control invalid'}
                                                    type='text'
                                                    validators='required|email'
                                                    validator={this.validator}
                                                    viewMode='NEW'
                                                    colClass='col-xl-12 col-lg-12 col-md-12 col-sm-12'
                                                    placeholder='Podaj e-mail konta, którego hasło ma być zresetowane'
                                                />
                                                <ActionButton label='Resetuj' variant='login-button' handleClick={this.handleFormSubmit} />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BlockUi>
            </React.Fragment>
        );
    }
}
export default withTranslation()(ResetPasswordContainer);
