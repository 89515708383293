import BaseServiceCrud from './BaseServiceCrud';

export default class UnregisteredUserService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'public/unregisteredUser';
    }

    getCriteria(criteria) {
        return this.objToQueryString({
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    getAll() {
        return this.fetch(`${this.domain}/${this.path}/all`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
