import {exclude} from 'query-string';
import {Component} from 'react';

const excludedFromUpdating = [
    'p-dialog p-component payment-peronal-data-dialog p-dialog-enter-done',
    'p-dialog p-component send-to-contractor-dialog p-dialog-enter-done',
];

class DialogResizer {
    static maskId = 'dialog-mask-id';
    static recalculateDialog() {
        setTimeout(() => {
            return new Promise((resolve, reject) => {
                const dialogs = Array.from(document.getElementsByClassName('p-dialog'));
                dialogs.forEach((dialog) => {
                    const responseObj = {dialog};
                    if (dialog) {
                        if (!dialog.getAttribute('fixed-top')) {
                            const computedStyle = window.getComputedStyle(dialog);
                            const marginTop = parseInt(computedStyle.marginTop);
                            const top = dialog.offsetTop + marginTop;
                            dialog.setAttribute('fixed-top', DialogResizer.changeTop(top));
                        }
                        const dialogsContent = Array.from(document.getElementsByClassName('p-dialog-content'));
                        const di = dialogsContent.find((el) => {
                            return el.id.includes(dialog.id);
                        });
                        if (di.scrollHeight > di.clientHeight) {
                            const missing = di.scrollHeight - di.clientHeight;
                            const toSet = `${dialog.clientHeight + missing}px`;

                            dialog.style.minHeight = toSet;
                            responseObj.fixedHeight = parseInt(dialog.getAttribute('fixed-top')) + missing / 2;
                            dialog.setAttribute('fixed-top', responseObj.fixedHeight);
                            di.style.overflowY = 'hidden';
                        } else {
                            const dialogChildren = [...di.children];
                            let childrenHeight = 0;
                            dialogChildren.forEach((sh) => (childrenHeight += sh.scrollHeight));

                            if (childrenHeight < di.scrollHeight) {
                                const toRemove = di.scrollHeight - childrenHeight;
                                const toSet = `${dialog.clientHeight - toRemove}px`;

                                dialog.style.minHeight = toSet;
                                responseObj.fixedHeight = parseInt(dialog.getAttribute('fixed-top')) - toRemove / 2;
                                dialog.setAttribute('fixed-top', responseObj.fixedHeight);
                            }
                        }
                    }
                    return resolve(responseObj);
                });
            })
                .then((response) => {
                    const dialogs = Array.from(document.getElementsByClassName('p-dialog'));
                    if (dialogs.length === 0 || dialogs.length === 1) {
                        if (Array.from(response.dialog.classList).find((className) => className === 'p-dialog-exit')) {
                            DialogResizer.removeMask();
                            return;
                        }
                    }
                    if (response) {
                        DialogResizer.recalculateMask();
                    }
                })
                .catch((err) => console.log(err));
        }, 0);
    }

    // UWAŻAĆ!!!
    static forceCreateMask() {
        DialogResizer.removeMask();
        const rootElement = document.getElementById('root');
        const mask = document.createElement('div');
        mask.style.position = 'absolute';
        mask.id = DialogResizer.maskId;
        mask.style.top = '0';
        mask.style.left = '0';
        mask.style.width = '100%';
        mask.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        mask.style.zIndex = '1101';
        const maxElementHeight = rootElement?.scrollHeight;
        const minHeight = maxElementHeight ? maxElementHeight : '100%';
        mask.style.height = `${minHeight}px`;
        rootElement.appendChild(mask);
    }

    static recalculateMask() {
        DialogResizer.removeMask();
        const rootElement = document.getElementById('root');
        const mask = document.createElement('div');
        mask.style.position = 'absolute';
        mask.id = DialogResizer.maskId;
        mask.style.top = '0';
        mask.style.left = '0';
        mask.style.width = '100%';
        mask.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        mask.style.zIndex = '1101';
        const dialogs = Array.from(document.getElementsByClassName('p-dialog'));
        if (dialogs.length !== 0) {
            let dialogHeight = 0;
            dialogs.forEach((dialog) => {
                if (dialogHeight < dialog.offsetHeight + dialog.offsetTop) {
                    dialogHeight = dialog.offsetHeight + dialog.offsetTop;
                }
            });
            const maxElementHeight = dialogHeight > rootElement?.scrollHeight ? dialogHeight : rootElement?.scrollHeight;
            const minHeight = maxElementHeight ? maxElementHeight : '100%';
            mask.style.height = `${minHeight}px`;
            rootElement.appendChild(mask);
        } else {
            this.removeMaskIfExistOneDialog();
        }
    }
    static removeMask() {
        const oldMask = document.getElementById(DialogResizer.maskId);
        if (oldMask) {
            oldMask.remove();
        }
    }
    static removeMaskIfExistOneDialog() {
        const dialogs = Array.from(document.getElementsByClassName(`p-dialog`));

        if (dialogs.length === 1) {
            if (excludedFromUpdating.includes(dialogs[0]?.className)) {
                return;
            }
        }

        const oldMask = document.getElementById(DialogResizer.maskId);
        if (dialogs.length === 0 || dialogs.length === 1) {
            if (oldMask) {
                oldMask.remove();
            }
        }
    }
    static changeTop(inputTop) {
        if (window.innerHeight < 400) {
            inputTop = 150;
        }
        return inputTop;
    }
}

export default DialogResizer;
