import React from 'react';
import StringUtils from '../utils/StringUtils';
import {InputText} from 'primereact/inputtext';
import PropTypes from 'prop-types';

export const TemplateListComponent = (props) => {
    const {templates, showPrice} = props;
    const [valueFilter, setValueFilter] = React.useState('');

    React.useEffect(() => {}, [props]);

    const renderTemplate = (template) => {
        const className = template?.active ? 'template-active template-tekst-titile' : 'template-tekst-titile';
        console.log(template);
        const colForTemplate = showPrice ? 'col-10' : 'col-12';
        return (
            <div className='row'>
                <div id={`template-${template.id}`} className={`template-point-list-name  ${colForTemplate} p-2`}>
                    <div className={className}>{template.name}</div>
                </div>
                <div className='col-2 p-2'>{showPrice && <b> {template.price}zł</b>}</div>
            </div>
        );
    };
    const renderView = () => {
        return (
            <div className='container'>
                <InputText
                    className='col-12 custom-input-text mb-4'
                    value={valueFilter}
                    placeholder='Nazwa szablonu...'
                    onChange={(el) => {
                        setValueFilter(el.target.value);
                    }}
                />
                {templates
                    .filter((el) => {
                        if (StringUtils.isBlank(valueFilter)) {
                            return true;
                        } else {
                            return el.name.includes(valueFilter);
                        }
                    })
                    .map((element) => {
                        return renderTemplate(element);
                    })}
            </div>
        );
    };

    return <div className='container-fluid d-flex justify-content-center'>{renderView()}</div>;
};

TemplateListComponent.defaultProps = {
    showPrice: false,
    templates: [],
};

TemplateListComponent.propTypes = {
    showPrice: PropTypes.bool,
    templates: PropTypes.array,
};

export default TemplateListComponent;
