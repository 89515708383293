import AppPrefixUtils from '../utils/AppPrefixUtils';
import ActionLink from '../components/ActionLink';
import {device} from './BaseContainer';

const isLexDocs = process.env.REACT_APP_PORTAL_TYPE === 'LEXDOCS';

export default function BaseBreadCrumb(props) {
    function getLabel(item, index) {
        if (!isLexDocs) {
            return item.name;
        }
        if (item?.name.type === 'b') {
            return ' ' + item.name.props.children[1] + (index > 0 && device.type === 'mobile' && isLexDocs ? ` | &nbsp` : ' ');
        }
        const result = ' ' + item.name + ' ' + (index > 0 && device.type === 'mobile' && isLexDocs ? `  ` : ' ');
        return result;
    }

    return (
        props.items &&
        props.items?.length > 0 && (
            <div className={`bread-crumb-wrapper ${props.className ? props.className : ''}`}>
                <div className='bread-crumb-wrapper-inside '>
                    {props.items.map((item, index) => (
                        <span>
                            {' '}
                            {index > 0 ? <span className='breacrumb-separator'>|</span> : ' '}
                            <ActionLink
                                handleClick={() => {
                                    if (index !== props.items?.length - 1) {
                                        window.location.href = AppPrefixUtils.locationHrefUrl(item.url);
                                    }
                                }}
                                variant='blue'
                                className='bread-crumb-item cursor-pointer'
                                key={`bread-crumb-item-${index}`}
                                variant='blue'
                                iconName='mdi'
                                iconSide='right'
                                iconSize='xs'
                                iconColor='blue'
                                label={getLabel(item, index)}
                            />
                        </span>
                    ))}
                </div>
            </div>
        )
    );
}
