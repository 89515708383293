import BaseServiceCrud from './BaseServiceCrud';
export default class PurchasedService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'purchased';
    }

    getPurchasedList(elementId) {
        return this.fetch(`${this.domain}/${this.path}/list/${elementId}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            active: criteria.active,
            name: criteria.name,
            first_result: criteria.firstResult,
            max_results: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    getPurchasedSubscriptionByUserId(id) {
        return this.fetch(`${this.domain}/${this.path}/all-by-user-id/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    cancelSubscription(id) {
        return this.fetch(`${this.domain}/${this.path}/cancel-subscription/${id}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    deactivationSubscription(id) {
        return this.fetch(`${this.domain}/${this.path}/deactivation-subscription/${id}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    changeUserSubscriptions(body, idUser) {
        return this.fetch(`${this.domain}/${this.path}/change-user-subscriptions/${idUser}`, {
            method: 'PUT',
            body: JSON.stringify(body),
        }).catch((err) => {
            throw err;
        });
    }
}
