/* eslint-disable max-len */
import { RadioButton } from 'primereact/radiobutton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import equal from 'react-fast-compare';
import { withTranslation } from 'react-i18next';

class InputRadioButtonComponent extends Component {
    renderView() {
        const {
            colClass,
            id,
            insideTable,
            label,
            publicMode,
            showLabel,
            value,
            valueLabel,
            validateViewMode,
            validator,
            validators,
            showDescription,
            description,
            descriptionClassName,
            renderValue,
        } = this.props;
        return publicMode ? (
            <div className='input_easy_label row pl-0'>
                {label !== undefined && showLabel ? (
                    <label id={`${id}-label-id`} className='easy_label col-lg-2 col-md-3' htmlFor={id}>
                        {label}
                    </label>
                ) : null}
                {description !== undefined && showDescription ? (
                    <label id={`${id}-description-id`} className={descriptionClassName} htmlFor={id} style={{width: '100%'}}>
                        {description}
                    </label>
                ) : null}
                {renderValue ? (
                    renderValue()
                ) : (
                    <div className='col-md-5'>
                        <span aria-label={label} aria-labelledby={`${id}-label-id`} className='p-inputtext-view'>
                            {value ? (valueLabel ? value[valueLabel] : value) : null}
                            {description ? description : null}
                        </span>

                        {validateViewMode && validator ? validator.message(id, label, value, validators) : null}
                    </div>
                )}
            </div>
        ) : (
            <div className={insideTable ? '' : colClass}>
                <div className={insideTable ? '' : 'row'}>
                    <div className={insideTable ? '' : 'col-md-12 form-group radiobutton'}>
                        {label !== undefined && showLabel ? (
                            <label id={`${id}-label-id`} className='p-label' htmlFor={id} style={{width: '100%'}}>
                                {label}
                            </label>
                        ) : null}
                        {description !== undefined && showDescription ? (
                            <label id={`${id}-description-id`} className={descriptionClassName} htmlFor={id} style={{width: '100%'}}>
                                {description}
                            </label>
                        ) : null}
                        {renderValue ? (
                            renderValue()
                        ) : (
                            <span aria-label={label} aria-labelledby={`${id}-label-id`} className='p-inputtext-view'>
                                {value ? (valueLabel ? value[valueLabel] : value) : null}
                                {description ? description : null}
                            </span>
                        )}
                        {validateViewMode && validator ? validator.message(id, label, value, validators) : null}
                    </div>
                </div>
            </div>
        );
    }

    renderEdit() {
        return this.renderNew();
    }

    renderNew() {
        const {
            colClass,
            compareByOneProperty,
            disabled,
            id,
            insideTable,
            itemColClass,
            label,
            labelOnRight,
            name,
            onAfterStateChange,
            onChange,
            options,
            publicMode,
            showLabel,
            stateField,
            validator,
            validators,
            value,
            valueKey,
            valueLabel,
            showDescription,
            description,
            descriptionClassName,
            tooltip
        } = this.props;
        const required = validators !== undefined && validators.includes('required') && !validators.includes('not_required');
        return publicMode ? (
            <div className='input_easy_label row pl-0'>
                {label !== undefined && showLabel ? (
                    <label id={`${id}-label-id`} className='easy_label col-lg-2 col-md-3' htmlFor={id}>
                        {label}
                    </label>
                ) : null}
                <div aria-label={label} aria-labelledby={`${id}-label-id`} className='col-md-5'>
                    {options && options.length > 0
                        ? options.map((val) => {
                              return (
                                  <div
                                      className={`radio-button-opt ${itemColClass}`}
                                      key={`div-${valueKey ? val[valueKey] : valueLabel ? val[valueLabel] : val}`}
                                  >
                                      <RadioButton
                                          aria-label={`${label} ${valueLabel ? val[valueLabel] : val}`}
                                          key={`${id}-${valueKey ? val[valueKey] : valueLabel ? val[valueLabel] : val}`}
                                          id={`${id}-${valueKey ? val[valueKey] : valueLabel ? val[valueLabel] : val}`}
                                          inputId={`${id}-input-${valueKey ? val[valueKey] : valueLabel ? val[valueLabel] : val}`}
                                          name={name}
                                          onChange={(e) =>
                                              onChange ? onChange('RADIOBUTTON', [valueKey, compareByOneProperty], e, onAfterStateChange, stateField) : null
                                          }
                                          value={val}
                                          checked={
                                              value
                                                  ? valueKey
                                                      ? compareByOneProperty
                                                          ? value[valueKey] === val[valueKey]
                                                          : value === val[valueKey]
                                                      : equal(value, val)
                                                  : false
                                          }
                                          disabled={disabled}
                                          required={required}
                                      />
                                      {labelOnRight ? (
                                          <label
                                              key={`label-${valueKey ? val[valueKey] : valueLabel ? val[valueLabel] : val}`}
                                              htmlFor={`${id}-input-${valueKey ? val[valueKey] : valueLabel ? val[valueLabel] : val}`}
                                              className='p-checkbox-label p-label'
                                          >
                                              {valueLabel ? val[valueLabel] : val}
                                          </label>
                                      ) : null}
                                      {showDescription ? (
                                          <label
                                              key={`label-${description ? val[description] : description ? val[description] : val}`}
                                              htmlFor={`${id}-input-${val[description]}`}
                                              className={descriptionClassName}
                                          >
                                              {val['description']}
                                          </label>
                                      ) : null}
                                  </div>
                              );
                          })
                        : null}
                    <div aria-live='assertive'>{validator ? validator.message(id, label, value, validators) : null}</div>
                </div>
            </div>
        ) : (
            <div className={insideTable ? '' : colClass} id={id}>
                {/* {tooltip ? <Tooltip target={`#${id}-label-id`} /> : null} */}
                <div className={insideTable ? '' : 'row'}>
                    <div className={insideTable ? '' : 'col-md-12 form-group radiobutton'}>
                        {label !== undefined && showLabel ? (
                            <label id={`${id}-label-id`} className='p-label' htmlFor={id} style={{width: '100%'}} data-pr-tooltip={tooltip ? tooltip : ''}>
                                {label}
                            </label>
                        ) : null}
                        <div aria-label={label} aria-labelledby={`${id}-label-id`} className='row'>
                            {options && options.length > 0
                                ? options.map((val) => {
                                      return (
                                          <div
                                              className={`${itemColClass} radiobutton-wrapper`}
                                              key={`div-${valueKey ? val[valueKey] : valueLabel ? val[valueLabel] : val}`}
                                          >
                                              <RadioButton
                                                  aria-describedby={`${id}-error`}
                                                  aria-label={`${label} ${valueLabel ? val[valueLabel] : val}`}
                                                  key={`${id}-${valueKey ? val[valueKey] : valueLabel ? val[valueLabel] : val}`}
                                                  id={`${id}-${valueKey ? val[valueKey] : valueLabel ? val[valueLabel] : val}`}
                                                  inputId={`${id}-input-${valueKey ? val[valueKey] : valueLabel ? val[valueLabel] : val}`}
                                                  name={name}
                                                  onChange={(e) =>
                                                      onChange
                                                          ? onChange('RADIOBUTTON', [valueKey, compareByOneProperty], e, onAfterStateChange, stateField)
                                                          : null
                                                  }
                                                  value={val}
                                                  checked={
                                                      value
                                                          ? valueKey
                                                              ? compareByOneProperty
                                                                  ? value[valueKey] === val[valueKey]
                                                                  : value === val[valueKey]
                                                              : equal(value, val)
                                                          : false
                                                  }
                                                  disabled={disabled}
                                                  required={required}
                                              />
                                              {labelOnRight ? (
                                                  <label
                                                      key={`label-${valueKey ? val[valueKey] : valueLabel ? val[valueLabel] : val}`}
                                                      htmlFor={`${id}-input-${
                                                          valueKey ? val[valueKey] : valueLabel ? val[valueLabel] : val
                                                      }`}
                                                      className='p-checkbox-label p-label'
                                                  >
                                                      {valueLabel ? val[valueLabel] : val}
                                                  </label>
                                              ) : null}
                                              {showDescription ? (
                                                  <label
                                                      key={`label-${val[description]}`}
                                                      htmlFor={`${id}-input-${val[description]}`}
                                                      className={descriptionClassName}
                                                  >
                                                      {val['description']}
                                                  </label>
                                              ) : null}
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                        <div aria-live='assertive'>{validator ? validator.message(id, label, value, validators) : null}</div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {rendered, viewMode} = this.props;
        if (rendered) {
            switch (viewMode) {
                case 'NEW':
                    return this.renderNew();
                case 'EDIT':
                    return this.renderEdit();
                case 'VIEW':
                default:
                    return this.renderView();
            }
        } else {
            return null;
        }
    }
}

InputRadioButtonComponent.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    compareByOneProperty: false,
    disabled: false,
    insideTable: false,
    itemColClass: 'col-xl-12 col-lg-12 col-md-12 col-sm-12',
    labelOnRight: false,
    publicMode: false,
    rendered: true,
    showLabel: true,
    stateField: 'element',
    validateViewMode: false,
    validators: 'array_required',
    viewMode: 'VIEW',
    showDescription: false,
    descriptionClassName: 'p-checkbox-label p-label',
};

InputRadioButtonComponent.propTypes = {
    colClass: PropTypes.string,
    compareByOneProperty: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    insideTable: PropTypes.bool,
    itemColClass: PropTypes.string,
    label: PropTypes.string,
    labelOnRight: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onAfterStateChange: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    publicMode: PropTypes.bool,
    rendered: PropTypes.bool,
    renderValue: PropTypes.func,
    showLabel: PropTypes.bool,
    stateField: PropTypes.string,
    validateViewMode: PropTypes.bool,
    validator: PropTypes.object.isRequired,
    validators: PropTypes.string,
    value: PropTypes.any,
    valueKey: PropTypes.string,
    valueLabel: PropTypes.string.isRequired,
    viewMode: PropTypes.string,
    description: PropTypes.string,
    showDescription: PropTypes.bool,
    descriptionClassName: PropTypes.string,
};

export default withTranslation()(InputRadioButtonComponent);
