import {FilterMatchMode} from 'primereact/api';
import {Column} from 'primereact/column';
import {ToggleButton} from 'primereact/togglebutton';
import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {ActionButton} from '../../components/ActionButton';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import CustomDataTable from '../../components/CustomDataTable';
import CustomLabel from '../../components/CustomLabel';
import {CustomMessages} from '../../components/CustomMessages';
import CustomValidatorLabel from '../../components/CustomValidatorLabel';
import DialogResizer from '../../components/DialogResizer';
import DivContainer from '../../components/DivContainer';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
import InputSelectButtonComponent from '../../components/inputs/InputSelectButtonComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import SubscriptionModel from '../../model/SubscriptionModel';
import CategoryService from '../../services/CategoryService';
import EnumService from '../../services/EnumService';
import InternalUserService from '../../services/InternalUserService';
import SettingService from '../../services/SettingService';
import SpecializationService from '../../services/SpecializationService';
import SubscriptionService from '../../services/SubscriptionService';
import TemplateService from '../../services/TemplateService';
import CustomValidator from '../../utils/CustomValidator';
import InputTextEditorComponent from '../../components/inputs/InputTextEditorComponent';
import {DataTable} from 'primereact/datatable';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import StringUtils from '../../utils/StringUtils';

class SubscriptionDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new SubscriptionService());
        this.enumService = new EnumService();
        this.settingsService = new SettingService();
        this.internalUserService = new InternalUserService();
        this.templateService = new TemplateService();
        this.categoryService = new CategoryService();
        this.editableOptions = ['Odczyt i zapis', 'Odczyt'];
        this.state = {
            element: new SubscriptionModel(),
            criteria: this.getCleanSearchCriteria(),
            categories: [],
            viewMode: this.props.viewMode,
            activeIndex: 0,
            users: [],
            subscribe: {
                enumValue: undefined,
                isSelected: false,
            },
            maxPaymentAmount: 15000,
            selectedUsers: [],
            subscriptionType: [],
            isIndividual: false,
            filterName: '',
            filterCategory: '',
            filterSpecialization: '',
            filters: {
                global: {value: null, matchMode: FilterMatchMode.CONTAINS},
                name: {value: null, matchMode: FilterMatchMode.CONTAINS},
                category: {value: null, matchMode: FilterMatchMode.EQUALS},
                'specialization.name': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            packageTypeOptions: [
                {
                    label: 'Ograniczony',
                    enumValue: 'LIMITED',
                },
                {
                    label: 'Indywidualny',
                    enumValue: 'INDIVIDUAL',
                },
                {
                    label: 'Premium',
                    enumValue: 'PREMIUM',
                },
            ],
            planTypeOptions: [
                {
                    label: 'Kwartalny',
                    enumValue: 'QUARTERLY',
                },
                {
                    label: 'Półroczny',
                    enumValue: 'SEMI_ANNUAL',
                },
                {
                    label: 'Roczny',
                    enumValue: 'ANNUAL',
                },
            ],
        };
        this.handleChangeStep = this.handleChangeStep.bind(this);
        this.showResponseFromCustomValidator = this.showResponseFromCustomValidator.bind(this);
        this.customValidator = new CustomValidator();
        this.selectAll = this.selectAll.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onChangeSubscription = this.onChangeSubscription.bind(this);
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
    }
    getCleanSearchCriteria() {
        return {
            dateFrom: undefined,
            dateTo: undefined,
            text: '',
            category: undefined,
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: false,
            current: this.props.current,
            showSubTemplate: true,
        };
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        const packageType = this.state.element?.packageType?.enumValue;
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton
                    label={t('global.backStepBtn')}
                    buttonType={'PREV'}
                    variant={'button cancel-button gray'}
                    handleClick={this.handleChangeStep.bind(this, {index: this.state.activeIndex}, true)}
                    rendered={this.state.activeIndex === 1 || this.state.activeIndex === 2}
                />

                <ActionButton
                    label={this.state.viewMode === 'NEW' ? t('subscription.addButton') : t('details.saveChanges')}
                    variant='button save-button blue'
                    buttonType={'NEXT'}
                    handleClick={(e) => this.handleFormSubmit(e)}
                    rendered={this.state.activeIndex === 2 || (this.state.activeIndex === 1 && packageType === 'PREMIUM')}
                />

                <ActionButton
                    label={t('details.next')}
                    variant='button save-button blue'
                    buttonType={'NEXT'}
                    disabled={this.state.activeIndex === 2}
                    handleClick={this.handleChangeStep.bind(this, {index: this.state.activeIndex})}
                    rendered={
                        this.state.activeIndex !== 2 &&
                        packageType !== undefined &&
                        !(packageType === 'PREMIUM' && this.state.activeIndex === 1)
                    }
                />
            </div>
        );
    }
    onHide() {
        this.props.onHide();
        DialogResizer.removeMaskIfExistOneDialog();
    }
    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('subscription.addHeader')}`
                            : this.state.viewMode === 'EDIT'
                            ? `${t('subscription.editHeader')}`
                            : `${t('subscription.deleteHeader')}`}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }

    initBeforeSetElement() {
        this.settingsService
            .getPaymentMaxAmount()
            .then((resp) =>
                this.setState({
                    maxPaymentAmount: resp['PAYMENT_MAX_AMOUNT'],
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać maksymalnej kwoty transakcji');
            });
        this.enumService
            .getEnumList('SubscriptionType', this.translate)
            .then((subscriptionType) =>
                this.setState({
                    subscriptionType,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy typów wiadomości');
            });
        this.templateService
            .getAll()
            .then((templates) => {
                this.setState({
                    templates,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać szablonów');
            });
        this.enumService
            .getEnumList('SubscriptionType', this.translate)
            .then((types) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        active: false,
                        types: types,
                    },
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });

        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                packageType: this.state.packageTypeOptions[0],
            },
        }));
        this.internalUserService
            .getUsersByRoleName('LexDocS')
            .then((users) => {
                this.setState({
                    users: users,
                });
            })
            .catch(() => {
                this.showErrorMessage('Błąd przy pobieraniu listy użytkowników');
            });

        this.categoryService
            .getAll()
            .then((categories) =>
                this.setState({
                    categories,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });
        new SpecializationService()
            .getAllSpecializations()
            .then((specializations) => {
                this.setState({specializations});
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });
    }

    getContainerListName() {
        return 'subscription-list';
    }

    getAddSucces() {
        return 'Pakiet został dodany';
    }

    getUpdateSucces() {
        return 'Pakiet został zaktualizowany';
    }

    showResponseFromCustomValidator() {
        const response = this.customValidator.atLeastOneOptionRequired(this.state.element.types, '')?.response;
        this.setState({
            reponseFromCustomValidator: response,
        });
        return response;
    }

    handleFormSubmit(e) {
        const {element} = this.state;
        if (this.state.activeIndex !== 0) {
            if (element.agreementTemplates === undefined && element.packageType?.enumValue !== 'PREMIUM') {
                this.showErrorMessage('Nie wybrano szablonów');
                return;
            } else {
                let model = new SubscriptionModel.copy(element);
                if (this.validator.allValid()) {
                    DialogResizer.removeMaskIfExistOneDialog();
                    if (this.props._isMounted) {
                        this.service
                            .add(model)
                            .then((subscription) => {
                                this.blockUi();
                                this.persistMessage('success', '', this.getAddSucces(subscription));
                                if (this.props.afterSave) {
                                    this.props.afterSave();
                                }
                                this.props.handleOpenActivationDialog(this, subscription.id);
                            })
                            .catch((err) => {
                                this.persistMessage('error', '', err.message);
                                if (this.props.afterSave) {
                                    this.props.afterSave();
                                }
                            });
                    }
                } else {
                    this.validator.showMessages();
                    // this.scrollToError = true;
                    this.forceUpdate();
                }
            }
        }
    }

    handleChangeStep(e, backStep) {
        /** backStep może zawierac obiekt, dlatego tutaj sprawdzenie backStep===true */
        if (backStep === true) {
            e.index--;
            this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
        } else {
            if (e.index === 1) {
                let typesValidation = this.showResponseFromCustomValidator();
                if (typesValidation) return;
                if (this.state.element?.packageType?.enumValue === 'INDIVIDUAL' && this.state.subscribe.enumValue === undefined) {
                    this.showErrorMessage('Wybierz subskrypcję');
                    return;
                }
            }
            e.index++;
            if (this.validator.allValid()) {
                this.validator.hideMessages();
                this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
            } else {
                this.validator.showMessages();
                // this.scrollToError = true;
                this.forceUpdate();
            }
        }
    }

    renderDetails() {
        return (
            <div>
                {this.state.activeIndex === 0 ? this.renderFirstStep() : null}
                {this.state.activeIndex === 1 ? this.renderSecondStep() : null}
                {this.state.activeIndex === 2 && this.state.element?.packageType?.enumValue !== 'PREMIUM' ? this.renderThirdStep() : null}
                {this.state.activeIndex === 1 ? (
                    <CustomValidatorLabel
                        colClass='col-md-12'
                        label={this.state.reponseFromCustomValidator?.message}
                        rendered={this.state.reponseFromCustomValidator?.visible}
                    />
                ) : null}
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)}></CustomMessages>
            </div>
        );
    }

    onGlobalFilterChange(e, type) {
        const value = e.target.value;
        let filters = {...this.state.filters};
        filters[type].value = value;
        switch (type) {
            case 'name':
                this.setState({filters, filterName: value});
                break;
            case 'category.name':
                this.setState({filters, filterCategory: value});
                break;
            case 'specialization.name':
                this.setState({filters, filterSpecialization: value});
                break;
            default:
                console.log(`no opt`);
        }
    }

    filterTempaltesByCriteria() {
        const filters = this.state.filters;
        let templates = this.state.templates;
        const specializationName = filters['specialization.name'].value;
        const category = filters['category'].value;
        const global = filters['global'].value;
        const name = filters['name'].value;
        if (!StringUtils.isBlank(specializationName)) {
            templates = templates.filter((el) => el.specialization.name === specializationName);
        }
        if (!StringUtils.isBlank(category)) {
            templates = templates.filter((el) => el.category === category);
        }
        if (!StringUtils.isBlank(global)) {
            templates = templates.filter((el) => el.global.includes(global));
        }
        if (!StringUtils.isBlank(name)) {
            templates = templates.filter((el) => el.name.includes(name));
        }
        return templates;
    }

    selectAll(allSelected) {
        let templates = this.filterTempaltesByCriteria();

        if (!allSelected) {
            templates = [];
        }
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                agreementTemplates: templates,
            },
        }));
    }
    onSelectionChange(event) {
        const value = event.value;
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                agreementTemplates: value,
            },
        }));
    }

    onChangeSubscription(enumValue, event) {
        let subscribedType = this.state.element.types.filter((el) => el.enumValue === enumValue);
        this.setState((prevState) => ({
            subscribe: {
                enumValue: enumValue,
                isSelected: true,
            },
            element: {
                ...prevState.element,
                subscribedType: subscribedType[0],
            },
        }));
    }
    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='text-sc'
                    name='name'
                    label={'Tytuł szablonu'}
                    placeholder='Wpisz tytuł...'
                    colClass='col-lg-4 col-md-4 col-sm-12'
                    value={this.state.filters['name'].value}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    refreshFromBackend={false}
                />
                <CriteriaDropdownComponent
                    id='category-sc'
                    name='category'
                    showClear
                    label={'Kategoria'}
                    colClass='col-lg-4 col-md-4 col-sm-12'
                    value={this.state.criteria.category}
                    options={this.state.categories}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='name'
                    refreshFromBackend={false}
                    stateField='filters'
                />
                <CriteriaDropdownComponent
                    id='specialization-sc'
                    name='specialization'
                    showClear
                    label={'Specjalizacja'}
                    colClass='col-lg-4 col-md-4 col-sm-12'
                    value={this.state.criteria.specialization}
                    options={this.state.specializations}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='name'
                    refreshFromBackend={false}
                    stateField='filters'
                />
            </div>
        );
    }
    selectAllComponent(text, selectAll) {
        return (
            <button
                className='lexdocs-classic-btn-outlined flaot-right'
                onClick={(e) => {
                    this.selectAll(selectAll);
                }}
            >
                <label className='cursor-pointer'>{text}</label>{' '}
                <img src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/done_all.svg`)} alt=''></img>
            </button>
        );
    }
    renderThirdStep() {
        return (
            <React.Fragment>
                <CustomLabel colClass='col-12' label='Dostępne szablony' />
                {this.renderCriteria()}
                <div className='datatable-responsive'>
                    {this.state.element.agreementTemplates?.length === 0
                        ? this.selectAllComponent('Zaznacz wszystko', true)
                        : this.selectAllComponent('Odznacz wszystko', false)}

                    <CustomDataTable
                        rows={4}
                        selectionMode='checkbox'
                        value={this.state.templates ? this.state.templates : null}
                        selection={this.state.element.agreementTemplates}
                        onSelectionChange={this.onSelectionChange}
                        emptyMessage='Brak rekordów do wyświetlenia'
                        globalFilterFields={['name', 'category.name', 'specialization.name']}
                        dataKey='id'
                        currentPageReportTemplate={this.state.element.agreementTemplates}
                        loading={this.state.loading}
                        filters={this.state.filters}
                        filterDisplay='menu'
                        className='p-datatable-responsive'
                        paginator
                    >
                        <Column selectionMode='multiple' headerStyle={{width: '3em'}} />
                        <Column field='id' header='ID' />
                        <Column field='name' header='Tytuł szablonu' style={{minWidth: '15rem'}} filter />
                        <Column field='description' header='Opis' style={{minWidth: '15rem'}} />
                        <Column field='category.name' header='Kategoria' style={{minWidth: '15rem'}} filter />
                        <Column field='specialization.name' header='Specjalizacja' style={{minWidth: '15rem'}} filter />
                    </CustomDataTable>
                </div>
            </React.Fragment>
        );
    }

    renderSecondStep() {
        return (
            <div>
                <InputSelectButtonComponent
                    id='planType'
                    name='planType'
                    colClass='col-md-12'
                    value={this.state.element.planType}
                    options={this.state.planTypeOptions}
                    optionLabel='label'
                    optionValue='enumValue'
                    onChange={this.handleChange}
                    viewMode={'EDIT'}
                    validator={this.validator}
                    validators='required'
                    validateViewMode
                />
                {this.state.element.types
                    ? this.state.element.types.map((i, index) => {
                          return (
                              <div class='row' style={{width: '100%'}}>
                                  <InputCheckboxComponent
                                      id={`${index}-types`}
                                      name='selected'
                                      label={i.label}
                                      labelOnRight
                                      value={!!i.selected}
                                      validator={this.validator}
                                      validators='not_required'
                                      colClass='col-md-6'
                                      onAfterStateChange={this.showResponseFromCustomValidator}
                                      onChange={this.handleChange}
                                      viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
                                      disabled={this.props.viewMode === 'VIEW'}
                                      stateField={`element.types[${index}]`}
                                      positioning='col-md-12 form-group-checkbox-flex'
                                  />
                                  <div className='row'>
                                      <DivContainer rendered={this.state.element?.packageType?.enumValue === 'INDIVIDUAL' && !!i.selected}>
                                          <ToggleButton
                                              id={`${index}-types`}
                                              checked={this.state.subscribe.isSelected && this.state.subscribe.enumValue === i.enumValue}
                                              onChange={this.onChangeSubscription.bind(this, i.enumValue)}
                                              onLabel='Subskrybuj'
                                              className='mt-2 col-lg-2 h-7 '
                                              offLabel='Subskrybuj'
                                              aria-label='Confirmation'
                                          />
                                      </DivContainer>

                                      <InputNumberComponent
                                          id={`${index}-types-price`}
                                          name='price'
                                          label={'Cena'}
                                          colClass='col-md-5'
                                          value={i.price}
                                          validator={this.validator}
                                          validators={`required|number_max:${this.state.maxPaymentAmount}|number_min:1`}
                                          onChange={this.handleChange}
                                          viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
                                          disabled={this.props.viewMode === 'VIEW'}
                                          stateField={`element.types[${index}]`}
                                          rendered={i.selected ? true : false}
                                          mode='currency'
                                          currency='PLN'
                                          locale='pl-PL'
                                      />
                                      <InputNumberComponent
                                          id={`${index}-types-consultation`}
                                          name='consultation'
                                          label={'Konsultacje'}
                                          colClass='col-md-5'
                                          validators='required|number_max:999'
                                          value={i.consultation}
                                          validator={this.validator}
                                          onChange={this.handleChange}
                                          viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
                                          disabled={this.props.viewMode === 'VIEW'}
                                          stateField={`element.types[${index}]`}
                                          rendered={i.selected ? true : false}
                                      />
                                  </div>
                              </div>
                          );
                      })
                    : null}
            </div>
        );
    }

    renderFirstStep() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputSelectButtonComponent
                        id='packageType'
                        name='packageType'
                        colClass='col-md-12'
                        value={this.state.element.packageType}
                        options={this.state.packageTypeOptions}
                        optionLabel='label'
                        optionValue='enumValue'
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                        validator={this.props.validator}
                        validators='required'
                        validateViewMode
                    />
                    <InputTextComponent
                        id='name'
                        name='name'
                        label={'Nazwa'}
                        colClass='col-md-12'
                        value={this.state.element.name}
                        validator={this.validator}
                        validators='required|alpha_space|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />

                    <InputTextEditorComponent
                        id='description-package'
                        name='description'
                        colClass='col-12'
                        border={true}
                        value={this.state.element.description}
                        label='Opis'
                        validator={this.validator}
                        validators={`not-required|max:499`}
                        rows='4'
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                        customStyle={{width: '100%', height: '100px', border: 'unset'}}
                    />

                    <InputMultiSelectComponent
                        id='users'
                        name='users'
                        colClass='col-md-12'
                        label='Użytkownicy'
                        value={this.state.element.users}
                        validator={this.validator}
                        onChange={this.handleChange}
                        rendered={this.state.element?.packageType?.enumValue === 'INDIVIDUAL'}
                        viewMode={this.props.viewMode}
                        options={this.state.users}
                        dataKey='id'
                        validators={'required|array_required'}
                        optionLabel='fullName'
                        filter
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
}

SubscriptionDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
};

SubscriptionDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
};

export default withTranslation()(SubscriptionDialog);
