import 'bootstrap/dist/css/bootstrap.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import React, {Suspense} from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import {ThroughProvider} from 'react-through';
import './App.scss';
import './assets/css/mdi.scss';
import './assets/quill/quill.bubble.scss';
import './assets/quill/quill.core.scss';
import './assets/quill/quill.snow.scss';
import './i18n';
import * as serviceWorker from './serviceWorker';
import App from './app.tsx';

const theApp = (
    <ThroughProvider>
        <Suspense
            fallback={
                <div className='App'>
                    <div>loading...</div>
                </div>
            }
        >
            <App />
        </Suspense>
    </ThroughProvider>
);

ReactDOM.render(theApp, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
