import $ from 'jquery';
import StringUtils from './StringUtils';
class TooltipAgreementUtils {
    static registerTooltipFunction(attributeName) {
        $(function () {
            $('body').on('click', `[${attributeName}]`, function (e) {
                if (!StringUtils.isBlank($(this).attr(attributeName))) {
                    document.querySelectorAll(`.${TooltipAgreementUtils.helpTooltipClassName()}`).forEach((el) => el.remove());

                    const heightPosition = TooltipAgreementUtils.heightPositionClassname(e.pageY);
                    const widthPosition = TooltipAgreementUtils.widthPositionClassname(e.pageX);

                    if (e?.target && !StringUtils.isBlank($(this).attr(attributeName))) {
                        e.preventDefault();
                        $(this).parent().css('position', 'relative');
                        $(this).append(
                            `<span class='agreement-tooltip ${heightPosition} ${widthPosition}'>${$(this).attr(
                                attributeName
                            )}<span class="close-icon">&times;</span></span>
                            `
                        );
                        e.stopPropagation();
                    }
                }
            });
            $('body').on('click', function (e) {
                if (!$(this).attr(attributeName)) {
                    document.querySelectorAll(`.${TooltipAgreementUtils.helpTooltipClassName()}`).forEach((el) => el.remove());
                }
            });

            $('body').on('click', `.close-icon`, function (e) {
                document.querySelectorAll(`.${TooltipAgreementUtils.helpTooltipClassName()}`).forEach((el) => el.remove());
                e.stopPropagation();
            });
        });
    }
    static registerTooltipClassnameFunction(classname, contentMessage) {
        $(function () {
            $('body').on('click', `.${classname}`, function (e) {
                if (!StringUtils.isBlank(contentMessage)) {
                    document.querySelectorAll(`.${TooltipAgreementUtils.helpTooltipClassName()}`).forEach((el) => el.remove());

                    const heightPosition = TooltipAgreementUtils.heightPositionClassname(e.pageY);
                    const widthPosition = TooltipAgreementUtils.widthPositionClassname(e.pageX);

                    if (e?.target && !StringUtils.isBlank(contentMessage)) {
                        e.preventDefault();
                        $(this).parent().css('position', 'relative');
                        $(this).after(
                            `<span class='agreement-tooltip ${heightPosition} ${widthPosition}'><span class="close-icon">&times;</span>${contentMessage}</span>`
                        );
                        e.stopPropagation();
                    }
                }
            });
            $('body').on('click', function (e) {
                if (!$(e.target).hasClass(classname)) {
                    document.querySelectorAll(`.${TooltipAgreementUtils.helpTooltipClassName()}`).forEach((el) => el.remove());
                }
            });

            $('body').on('click', `.close-icon`, function (e) {
                document.querySelectorAll(`.${TooltipAgreementUtils.helpTooltipClassName()}`).forEach((el) => el.remove());
                e.stopPropagation();
            });
        });
    }

    static helpTooltipClassName() {
        return 'agreement-tooltip';
    }
    static closeButton(heightPosition, widthPosition) {
        return `<span class='agreement-tooltip-close-button ${heightPosition} ${widthPosition}'></span>`;
    }
    static heightPositionClassname(pageY) {
        var pageHeigth = $('div.agreement-fullfilment').height();
        var halfPageHeight = pageHeigth / 2;
        var position = pageY;
        let heightPosition = '';
        if (position >= halfPageHeight) {
            heightPosition = 'tooltipTop';
        } else {
            heightPosition = 'tooltipBottom';
        }
        return heightPosition;
    }
    static widthPositionClassname(pageX) {
        var pageWidth = $('div.agreement-fullfilment').width();
        var halfPageWidth = pageWidth / 2;
        var positionWidth = pageX;
        let widthPosition = '';
        if (positionWidth >= halfPageWidth) {
            widthPosition = 'tooltipRight';
        } else {
            widthPosition = 'tooltipLeft';
        }
        return widthPosition;
    }
}
export default TooltipAgreementUtils;
