import AddressModel from './AddressModel';

export default class UserModel {
    constructor() {
        this.id = undefined;
        this.version = 0;
        this.login = undefined;
        this.firstName = undefined;
        this.lastName = undefined;
        this.email = undefined;
        this.phoneNumber = undefined;
        this.role = undefined;
        this.fakeStatus = 'ACTIVE';
        this.status = undefined;
        this.address = new AddressModel();
        this.portal = 'LEX_DOCS';
        this.person = undefined;
        this.industry = undefined;
        this.permissions = [];
        this.specializations = [];
        this.blocked = false;
        this.companyName = undefined;
        this.nip = undefined;
        this.videoChatFee = undefined;
        this.chatFee = undefined;
        this.mailingFee = undefined;
        this.bankAccountNumber = undefined;
        this.group = undefined;
    }
    static copy(from) {
        const to = new UserModel();
        UserModel.assign(from, to);
        return to;
    }

    static assign(from, to) {
        if (from) {
            to.id = from.id;
            to.version = from.version;
            to.login = from.login;
            to.firstName = from.firstName;
            to.lastName = from.lastName;
            to.email = from.email;
            to.role = from.role;
            to.industry = from.industry;
            to.phoneNumber = from.phoneNumber;
            to.status = from.status === undefined ? from.fakeStatus : from.status;
            to.address = from.address;
            to.person = from.person?.enumValue;
            to.videoChatFee = from.videoChatFee;
            to.chatFee = from.chatFee;
            to.mailingFee = from.mailingFee;
            to.bankAccountNumber = from.bankAccountNumber;
            to.companyName = from.companyName;
            to.nip = from.nip;
            to.portal = from.portal;
            to.permissions = from.permissions;
            to.group = from.group;
            to.specializations = from.specializations;
            to.blocked = from.blocked;
        } else {
            to = new UserModel();
        }
    }
}
