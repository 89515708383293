import $ from 'jquery';
import moment from 'moment';
import {Button} from 'primereact/button';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import equal from 'react-fast-compare';
import ActionButton from '../components/ActionButton';
import ActionLink from '../components/ActionLink';
import DivContainer from '../components/DivContainer';
import SimpleReactValidator from '../components/validator';
import AuthService from '../services/AuthService';
import EnumService from '../services/EnumService';
import Constants from '../utils/constants';
import HandleChangeUtils from '../components/utils/HandleChangeUtils';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import cloneDeep from 'lodash.clonedeep';
import {UAParser} from 'ua-parser-js';

export const {device} = UAParser(navigator.userAgent);
export const isLexDocs = process.env.REACT_APP_PORTAL_TYPE === 'LEXDOCS';
export const isMobile = device.type === 'mobile';

class BaseContainer extends React.Component {
    constructor(props, service) {
        super(props);
        this.service = service;
        this.authService = new AuthService();
        this.enumService = new EnumService();
        this.readCookie = this.readCookie.bind(this);
        this.removeCookie = this.removeCookie.bind(this);
        this.saveCookie = this.saveCookie.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.scrollToFirstError = this.scrollToFirstError.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSetState = this.handleChangeSetState.bind(this);
        this.handleChangeSc = this.handleChangeSc.bind(this);
        this.handleChangeScSetState = this.handleChangeScSetState.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleValidForm = this.handleValidForm.bind(this);
        this.prepareFooter = this.prepareFooter.bind(this);
        this.prepareHeader = this.prepareHeader.bind(this);
        this.prepareHeaderItems = this.prepareHeaderItems.bind(this);
        this.prepareFooterItems = this.prepareFooterItems.bind(this);
        this.setWaitPanelLabel = this.setWaitPanelLabel.bind(this);
        this.blockUi = this.blockUi.bind(this);
        this.unblockUi = this.unblockUi.bind(this);
        this.loader = this.loader.bind(this);
        this.onUploladError = this.onUploladError.bind(this);
        this.showMessage = this.showMessage.bind(this);
        this.showSuccessMessage = this.showSuccessMessage.bind(this);
        this.showInfoMessage = this.showInfoMessage.bind(this);
        this.showWarningMessage = this.showWarningMessage.bind(this);
        this.renderBorderTabForLexdocs = this.renderBorderTabForLexdocs.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
        this.translate = this.translate.bind(this);
        this.validator = new SimpleReactValidator({}, this.translate);
        this.validatorDraft = cloneDeep(this.validator);
        this.validatorDraft.options = {
            ignoreList: ['required'],
        };
        this._isMounted = false;
        this.jwtRefreshBlocked = false;
        this.scrollToError = false;
        this.scrollToTopOnMount = true;
        this.labelHeader = this.labelHeader.bind(this);
    }

    renderBorderTabForLexdocs(title) {
        if (!this.isLexyPortal()) {
            return (
                <div className='container'>
                    <div className='lexdocs-border-tab '>
                        <span className='title'> {title} </span>
                    </div>
                </div>
            );
        }
        return <React.Fragment />;
    }
    isLexyPortal() {
        return process.env.REACT_APP_PORTAL_TYPE === 'LEXY';
    }
    isMobile() {
        return device.type === 'mobile';
    }
    componentDidMount() {
        window.addEventListener('beforeunload', function () {});
        this._isMounted = true;
        if (!this.jwtRefreshBlocked && this.authService.loggedIn()) {
            this.jwtRefreshBlocked = true;
            this.authService
                .refresh()
                .then(() => {
                    this.jwtRefreshBlocked = false;
                })
                .catch(() => {
                    this.jwtRefreshBlocked = false;
                });
        }
        this.scrollToError = false;
        if (this.scrollToTopOnMount) {
            this.scrollToTop();
        }
        // eslint-disable-next-line no-undef
        $(window).off('beforeunload');
        // eslint-disable-next-line no-undef
        $(window).unbind();
    }

    componentDidUpdate() {
        this.refreshJwtToken();
        if (this.scrollToError) {
            this.scrollToError = false;
            this.scrollToFirstError();
        }
    }

    refreshJwtToken() {
        if (!this.jwtRefreshBlocked && this.authService.loggedIn() && this.authService.isTokenValidForRefresh()) {
            this.jwtRefreshBlocked = true;
            this.authService
                .refresh()
                .then(() => {
                    this.jwtRefreshBlocked = false;
                })
                .catch(() => {
                    this.jwtRefreshBlocked = false;
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onUploladError(errMsg) {
        this.showErrorMessage(errMsg);
    }

    showSuccessMessage(detail, life = Constants.SUCCESS_MSG_LIFE, summary = '') {
        this.showMessage('success', summary, detail, life, undefined);
    }
    getSuccessMessage(detail, life = Constants.SUCCESS_MSG_LIFE, summary = '') {
        return {
            severity: 'success',
            summary,
            detail,
            life,
            undefined,
        };
    }
    getInfoMessage(detail, life = Constants.SUCCESS_MSG_LIFE, summary = 'Informacja') {
        return {
            severity: 'info',
            summary,
            detail,
            life,
            undefined,
        };
    }
    getWarningMessage(detail, life = Constants.ERROR_MSG_LIFE, summary = 'Ostrzeżenie') {
        return {
            severity: 'warning',
            summary,
            detail,
            life,
            undefined,
        };
    }
    getErrorMessage(errMsg, life = Constants.ERROR_MSG_LIFE, closable = true, summary = 'Błąd') {
        return {
            severity: 'error',
            summary,
            errMsg,
            life,
            closable,
            detail: errMsg,
        };
    }

    showInfoMessage(detail, life = Constants.SUCCESS_MSG_LIFE, summary = 'Informacja') {
        this.showMessage('info', summary, detail, life, undefined);
    }

    showWarningMessage(detail, life = Constants.ERROR_MSG_LIFE, summary = 'Ostrzeżenie') {
        this.showMessage('warn', summary, detail, life, undefined);
    }

    showErrorMessage(errMsg, life = Constants.ERROR_MSG_LIFE, closable = true, summary = 'Błąd') {
        this.showMessage('error', summary, errMsg, life, closable, errMsg);
    }
    showMessagesList(list, errorsList) {
        if (this.props.showMessage !== undefined && this.props.showMessage instanceof Function) {
            this.props.showMessagesList(list);
        } else if (this.messages !== undefined && this.messages !== null) {
            // this.messages.clear();
            this.messages.show(list);
        } else {
            if (errorsList !== undefined) {
                console.log('this.messages === undefined', errorsList);
            } else {
                console.log('this.messages === undefined');
            }
        }
    }
    showMessage(severity, summary, detail, life = 5000, closable = true, errMsg) {
        if (this.props.showMessage !== undefined && this.props.showMessage instanceof Function) {
            this.props.showMessage(severity, summary, detail, life, closable, errMsg);
        } else if (this.messages !== undefined && this.messages !== null) {
            this.messages.show({
                severity,
                summary,
                detail,
                life,
                closable,
            });
        } else {
            if (errMsg !== undefined) {
                console.log('this.messages === undefined', errMsg);
            } else {
                console.log('this.messages === undefined');
            }
        }
    }

    scrollToTop(topOfContainer = false) {
        if (
            !topOfContainer &&
            this.props.mainRef !== undefined &&
            this.props.mainRef.current !== undefined &&
            ReactDOM.findDOMNode(this.props.mainRef.current) !== undefined &&
            ReactDOM.findDOMNode(this.props.mainRef.current) !== null
        ) {
            ReactDOM.findDOMNode(this.props.mainRef.current).scrollIntoView();
        } else if (
            topOfContainer &&
            this !== undefined &&
            ReactDOM.findDOMNode(this) !== undefined &&
            ReactDOM.findDOMNode(this) !== null
        ) {
            ReactDOM.findDOMNode(this).scrollIntoView();
        } else {
            //console.log('scrollToTop ', this, ReactDOM.findDOMNode(this));
        }
    }

    scrollToFirstError() {
        if (this !== undefined && ReactDOM.findDOMNode(this) !== undefined && ReactDOM.findDOMNode(this) !== null) {
            const errors = ReactDOM.findDOMNode(this).getElementsByClassName('srv-validation-message');
            if (errors && errors.length > 0) {
                if (errors[0]?.parentNode?.parentNode) {
                    errors[0].parentNode.parentNode.scrollIntoView();
                } else if (errors[0]?.parentNode) {
                    errors[0].parentNode.scrollIntoView();
                }
            }
        } else {
            console.log('scrollToFirstError ', this, ReactDOM.findDOMNode(this));
        }
    }

    isUserInRole(role) {
        return this.authService.isUserInRole(role);
    }

    isUserInAnyRole(...rolesToFind) {
        return this.authService.isUserInAnyRole(rolesToFind);
    }

    saveCookie(cookieName, cookieValue) {
        sessionStorage.setItem(cookieName, cookieValue);
    }

    readCookie(cookieName) {
        return sessionStorage.getItem(cookieName);
    }

    removeCookie(cookieName) {
        return sessionStorage.removeItem(cookieName);
    }

    isEqual(objA, objB) {
        return equal(objA, objB);
    }

    enumTemplate(enumType, field, rowData) {
        if (rowData[field]) {
            return this.translate(`${enumType}.${rowData[field]}`);
        }
        return '';
    }

    enumWithStyleTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return <span className={rowData[field].styleClass}>{rowData[field].label}</span>;
        } else if (rowData[field]) {
            return <span>{rowData[field]}</span>;
        }
        return '';
    }

    enumWithIconTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return (
                <span className={`icon_text p-button-text p-c ${rowData[field].iconName !== undefined ? rowData[field].iconColor : ''}`}>
                    {rowData[field].iconName !== undefined ? <i className={`icon mdi ${rowData[field].iconName}`} /> : null}
                    {rowData[field].label}
                </span>
            );
        } else if (rowData[field]) {
            return <span>{rowData[field]}</span>;
        }
        return '';
    }

    dateTemplate(field, format, rowData) {
        if (rowData[field] && moment(rowData[field]).isValid()) {
            return moment(rowData[field]).format(format);
        } else {
            return '';
        }
    }

    timeTemplate(field, format, rowData) {
        const today = new Date();
        if (rowData[field] && moment(new Date(`${moment(today).format('YYYY-MM-DD').toString()}T${rowData[field]}`)).isValid()) {
            return moment(new Date(`${moment(today).format('YYYY-MM-DD').toString()}T${rowData[field]}`)).format(format);
        } else {
            return '';
        }
    }

    dateRangeTemplate(fieldFrom, fieldTo, format, rowData) {
        let dateFrom = undefined;
        let dateTo = undefined;
        if (rowData[fieldFrom] && moment(rowData[fieldFrom]).isValid()) {
            dateFrom = moment(rowData[fieldFrom]).format(format);
        }
        if (rowData[fieldTo] && moment(rowData[fieldTo]).isValid()) {
            dateTo = moment(rowData[fieldTo]).format(format);
        }
        if (dateFrom || dateTo) {
            return `${dateFrom ? dateFrom : ''} - ${dateTo ? dateTo : ''}`;
        } else {
            return '';
        }
    }

    objectTemplate(field, objField, rowData) {
        if (rowData[field]) {
            return rowData[field][objField];
        } else {
            return '';
        }
    }

    objectJoinTemplate(fieldArray, separator, rowData) {
        if (separator === undefined) {
            separator = ', ';
        }
        const arrayOfFields = [];
        fieldArray.forEach((field) => {
            if (field.includes('.')) {
                const fieldSplitted = field.split('.');
                let valueObj = rowData[fieldSplitted[0]];
                for (let i = 1; i < fieldSplitted.length; i++) {
                    valueObj = valueObj[fieldSplitted[i]];
                }
                arrayOfFields.push(valueObj);
            } else {
                if (rowData[field]) {
                    arrayOfFields.push(rowData[field]);
                }
            }
        });
        const result = arrayOfFields.join(separator);
        return result;
    }

    objectArrayTemplate(field, objField, rowData) {
        if (rowData[field] && rowData[field].map) {
            return rowData[field] && rowData[field].length > 0 ? rowData[field].map((v) => v[objField]).join(', ') : '';
        } else {
            return '';
        }
    }

    handleChangeSetState(varName, varValue, onAfterStateChange, stateField, optionValue, enumMode) {
        if (stateField && stateField !== '') {
            const stateFieldArray = stateField.split('.');
            let stateFieldValue = undefined;
            stateFieldValue = HandleChangeUtils.getValueInObjPath(stateFieldArray[0], this.state);
            if (this._isMounted) {
                if (optionValue && !enumMode) {
                    let varValueArray;
                    if (varValue instanceof Array) {
                        varValueArray = varValue.map((el) => el[optionValue]);
                    } else {
                        varValueArray = varValue[optionValue];
                    }
                    HandleChangeUtils.setValueInObjPath(stateFieldValue, varValue, `${varName}Obj`, stateFieldArray);
                    HandleChangeUtils.setValueInObjPath(stateFieldValue, varValueArray, varName, stateFieldArray);
                    this.setState(
                        {
                            [stateFieldArray[0]]: stateFieldValue,
                        },
                        () => (onAfterStateChange ? onAfterStateChange() : null)
                    );
                } else {
                    HandleChangeUtils.setValueInObjPath(stateFieldValue, varValue, varName, stateFieldArray);
                    this.setState(
                        {
                            [stateFieldArray[0]]: stateFieldValue,
                        },
                        () => (onAfterStateChange ? onAfterStateChange() : null)
                    );
                }
            } else {
                console.log("component isn't mounted");
            }
        } else {
            if (this._isMounted) {
                if (optionValue && !enumMode) {
                    let varValueArray;
                    if (varValue instanceof Array) {
                        varValueArray = varValue.map((el) => el[optionValue]);
                    } else {
                        varValueArray = varValue[optionValue];
                    }
                    this.setState(
                        {
                            [varName]: varValueArray,
                            [`${varName}Obj`]: varValue,
                        },
                        () => (onAfterStateChange ? onAfterStateChange() : null)
                    );
                } else {
                    this.setState(
                        {
                            [varName]: varValue,
                        },
                        () => (onAfterStateChange ? onAfterStateChange() : null)
                    );
                }
            } else {
                console.log("component isn't mounted");
            }
        }
    }

    handleChange(inputType, parameters, event, onAfterStateChange, stateField) {
        HandleChangeUtils.handleChange(inputType, parameters, event, onAfterStateChange, stateField, this.handleChangeSetState, this.state);
    }

    handleChangeScSetState(criteria, filters, refreshFromBackend, onAfterStateChange) {
        this.setState({criteria, filters}, () => {
            // if (refreshFromBackend) {
            //     this.refreshTable();
            // }
            if (onAfterStateChange) {
                onAfterStateChange();
            }
        });
    }

    handleChangeSc(inputType, parameters, event, matchMode, refreshFromBackend, onAfterStateChange) {
        HandleChangeUtils.handleChangeSc(
            inputType,
            parameters,
            event,
            matchMode,
            refreshFromBackend,
            onAfterStateChange,
            this.handleChangeScSetState,
            this.state,
            this._isMounted
        );
    }

    handleValidForm() {}

    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            this.blockUi(this.handleValidForm);
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.scrollToError = true;
            this.forceUpdate();
        }
    }

    renderFooter() {
        const footerItems = this.prepareFooterItems();
        if (footerItems !== undefined && footerItems.length > 0 && footerItems.filter((f) => f.rendered !== false)?.length > 0) {
            return <React.Fragment>{this.prepareFooter(footerItems)}</React.Fragment>;
        } else {
            return null;
        }
    }

    prepareFooterItems() {
        return [];
    }
    prepareFooter(items) {
        const customHeaderItemsClass = this.isCustomHeaderItems ? 'custom-header-items' : '';

        const leftItems = [];
        const rightItems = [];
        if (items !== undefined && items.length > 0) {
            items.forEach((item) => {
                if (item.className !== undefined && item.className.includes('float-right')) {
                    item.className = item.className.replace('float-right', '');
                    rightItems.push(item);
                } else {
                    leftItems.push(item);
                }
            });
        }
        return (
            <div className={`footer-dialog-button-container ${customHeaderItemsClass} `}>
                <div className='left-footer-dialog-button-container'>
                    {leftItems && leftItems.length > 0
                        ? leftItems.map((item, index) => {
                              let iconRightUrl = '';
                              let renderIconRight = false;
                              if (item.iconRight) {
                                  iconRightUrl = AppPrefixUtils.locationHrefUrl(`/images/lexdocs/${item.iconRight}`);
                                  renderIconRight = true;
                              }
                              return item.customRenderFunction instanceof Function
                                  ? item.customRenderFunction()
                                  : this.renderItem(item, index, iconRightUrl, renderIconRight);
                          })
                        : null}
                </div>
                {rightItems && rightItems.length > 0
                    ? rightItems.map((item, index) =>
                          item.customRenderFunction instanceof Function ? item.customRenderFunction() : this.renderItem(item, index)
                      )
                    : null}
            </div>
        );
    }

    prepareHeaderItems() {
        return [];
    }

    translate(key) {
        return key;
    }

    renderHeader() {
        const headerItems = this.prepareHeaderItems();
        if (headerItems !== undefined && headerItems.length > 0 && headerItems.filter((f) => f.rendered !== false)?.length > 0) {
            return this.prepareHeader(headerItems);
        } else {
            return null;
        }
    }

    labelHeader(item, index) {
        return (
            <h2 className={`pageheader-title ${item.className}`} key={item.label + index}>
                {item.iconSvg ? (
                    <img className='card-icon' alt={item.label} src={AppPrefixUtils.locationHrefUrl(`/images/${item.iconSvg}.svg`)} />
                ) : null}
                {item.label}
            </h2>
        );
    }
    classUndefined(item) {
        return !item?.customClassName;
    }
    renderItem(item, index, imageCustomUrl, areImageWithButtonTogether) {
        if (this.classUndefined(item)) {
            item.customClassName = 'classic-btn';
        }
        const disabledStyle = item.disabled && 'disabled';
        if (item.type === 'LABEL') {
            return this.labelHeader(item, index);
        } else if (item.type === 'LINK') {
            return (
                <ActionLink
                    label={item.label}
                    className={`${item.className} header-item`}
                    // eslint-disable-next-line react/jsx-handler-names
                    handleClick={item.onClick instanceof Function ? item.onClick : undefined}
                    href={item.href}
                    rendered={item.rendered}
                    disabled={item.disabled}
                    iconColor={item.iconColor}
                    iconName={item.iconName}
                    iconSize={item.iconSize}
                    iconSide={item.iconSide}
                    variant={item.variant}
                    size={item.size}
                    key={item.label + index}
                />
            );
        } else if (item.type === 'SUBMIT') {
            return (
                <button
                    value={item.label}
                    // eslint-disable-next-line max-len
                    className={`p-button p-component p-button-text-only header-item ${item.className} ${item.variant} ${item.size}`}
                    type='submit'
                    rendered={item.rendered}
                    disabled={item.disabled}
                    key={item.label + index}
                >
                    <span className='p-button-text p-c'>{item.label}</span>
                </button>
            );
        } else if (item.type === 'BADGE') {
            return (
                <Button
                    label={item.label}
                    onClick={item.onClick instanceof Function ? item.onClick : undefined}
                    badge={item.badge}
                    badgeClassName='p-badge-danger'
                    rendered={item.rendered}
                    disabled={item.disabled}
                    icon={item.iconName}
                    variant={item.variant}
                    size={item.size}
                    key={item.label + index}
                />
            );
        } else {
            return (
                <div>
                    {areImageWithButtonTogether && imageCustomUrl && item.rendered ? (
                        <button onClick={item.disabled ? null : item.onClick} className={`${item.customClassName} ${disabledStyle}`}>
                            {item.label}
                            <img src={imageCustomUrl} alt='' className='' />
                        </button>
                    ) : (
                        imageCustomUrl &&
                        item.rendered && (
                            <a href={item.href} className='cursor-pointer'>
                                <img
                                    src={imageCustomUrl}
                                    alt=''
                                    // onClick={item.onClick}
                                    key={`btn-header-custom` + index}
                                    className='custom-headers-btns '
                                />
                            </a>
                        )
                    )}
                    {!(areImageWithButtonTogether && imageCustomUrl) && (
                        <>
                            <ActionButton
                                label={item.label}
                                className={`${item.className} header-item`}
                                // eslint-disable-next-line react/jsx-handler-names
                                handleClick={item.onClick instanceof Function ? item.onClick : undefined}
                                href={item.href}
                                rendered={item.rendered}
                                disabled={item.disabled}
                                iconColor={item.iconColor}
                                iconName={item.iconName}
                                iconSize={item.iconSize}
                                iconSide={item.iconSide}
                                variant={item.variant}
                                size={item.size}
                                key={item.label + index}
                                iconSvg={item.iconSvg}
                            ></ActionButton>
                        </>
                    )}
                </div>
            );
        }
    }

    prepareHeader(items) {
        const customHeaderItemsClass = this.isCustomHeaderItems ? 'custom-header-items' : '';
        const leftItems = [];
        const rightItems = [];
        const rightSide = [];
        if (items !== undefined && items.length > 0) {
            items.forEach((item) => {
                if (item.className !== undefined && item.className.includes('float-right')) {
                    item.className = item.className.replace('float-right', '');
                    rightItems.push(item);
                } else if (item.className !== undefined && item.className.includes('right-side')) {
                    item.className = item.className.replace('right-side', '');
                    rightSide.push(item);
                } else {
                    leftItems.push(item);
                }
            });
        }
        return (
            <DivContainer colClass={`p-card-header-minheight `}>
                <DivContainer colClass={`header-items ${customHeaderItemsClass}`}>
                    {leftItems && leftItems.length > 0
                        ? leftItems.map((item, index) => {
                              let imageCustomUrl = undefined;
                              let areImageWithButtonTogether = undefined;
                              if (item.iconRight) {
                                  imageCustomUrl = AppPrefixUtils.locationHrefUrl(`/images/lexdocs/` + item.iconRight);
                                  areImageWithButtonTogether = true;
                              }
                              return (
                                  <div>
                                      {' '}
                                      {item.customRenderFunction instanceof Function
                                          ? item.customRenderFunction()
                                          : this.renderItem(item, index, imageCustomUrl, areImageWithButtonTogether)}{' '}
                                  </div>
                              );
                          })
                        : null}
                    <div className='header-item float-right'>
                        {rightItems && rightItems.length > 0
                            ? rightItems.map((item, index) =>
                                  item.customRenderFunction instanceof Function ? item.customRenderFunction() : this.renderItem(item, index)
                              )
                            : null}
                    </div>
                    <div className='header-item-right float-right'>
                        {rightSide && rightSide.length > 0
                            ? rightSide.map((item, index) =>
                                  item.customRenderFunction instanceof Function ? item.customRenderFunction() : this.renderItem(item, index)
                              )
                            : null}
                    </div>
                </DivContainer>
            </DivContainer>
        );
    }

    setWaitPanelLabel(waitPanelLabel, callBack) {
        this.setState({waitPanelLabel}, () => (callBack !== undefined && callBack instanceof Function ? callBack() : null));
    }

    blockUi(callBack, waitPanelLabel) {
        if (this.props.blockUi !== undefined && this.props.blockUi instanceof Function) {
            this.props.blockUi(undefined, waitPanelLabel);
        }
        if (waitPanelLabel !== undefined) {
            this.setState({blocking: true, waitPanelLabel}, () =>
                callBack !== undefined && callBack instanceof Function ? callBack() : null
            );
        } else {
            this.setState({blocking: true}, () => (callBack !== undefined && callBack instanceof Function ? callBack() : null));
        }
    }

    unblockUi(callBack) {
        if (this.props.unblockUi !== undefined && this.props.unblockUi instanceof Function) {
            this.props.unblockUi();
        }
        this.setState({blocking: false}, () => (callBack !== undefined && callBack instanceof Function ? callBack() : null));
    }

    loader() {
        const {waitPanelLabel} = this.state;
        let label = 'Operacja w toku, proszę czekać...';
        if (waitPanelLabel !== undefined && waitPanelLabel !== null) {
            label = waitPanelLabel;
        }
        return (
            <div id='cover-spin-container'>
                <i id='cover-spin' class='fa fa-spinner fa-spin fa-3x fa-fw'></i>
                <div id='cover-spin-text'>
                    <p>{label}</p>
                </div>
            </div>
        );
    }
    breadCrumbItems() {
        return [];
    }
}

BaseContainer.defaultProps = {
    embedded: false,
    viewMode: 'VIEW',
};

BaseContainer.propTypes = {
    embedded: PropTypes.bool,
    blockUi: PropTypes.func,
    mainRef: PropTypes.any,
    unblockUi: PropTypes.func,
    showMessage: PropTypes.func,
    viewMode: PropTypes.string,
};

export default BaseContainer;
