import {t} from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';

import {withTranslation} from 'react-i18next';
import BaseListContainer from '../baseContainers/BaseListContainer';
import ActionLink from '../components/ActionLink';
import CriteriaCalendarRangeComponent from '../components/criteria/CriteriaCalendarRangeComponent';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import AgreementService from '../services/AgreementService';
import AgreementTemplateForUserService from '../services/AgreementTemplateForUserService';
import AuthService from '../services/AuthService';
import qs from 'qs';
import StorageUtils from '../utils/StorageUtils';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import YesNoDialog from '../components/YesNoDialog';
import DialogResizer from '../components/DialogResizer';
import DivContainer from '../components/DivContainer';
import {Card} from 'react-bootstrap';
import {PaginationControl} from 'react-bootstrap-pagination-control';
import {InputText} from 'primereact/inputtext';
import InputTextComponent from '../components/inputs/InputTextComponent';
import InputTextareaComponent from '../components/inputs/InputTextareaComponent';
import BaseBreadCrumb from '../baseContainers/BaseBreadCrumb';
import {CustomMessages} from '../components/CustomMessages';
import {device} from '../baseContainers/BaseContainer';
import UserLexyDocumentsCardComponent from '../components/UserLexyDocumentsCardComponent';
import UserLexDocSDocumentsCardComponent from '../components/UserLexDocSDocumentsCardComponent';

class AgreementList extends BaseListContainer {
    constructor(props) {
        super(props, new AgreementService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            roleOptions: [],
            selectedList: [],
            showConfirtmationMessage: false,
            visibleSendToContractorDialog: false,
            emailSendAgreementData: {
                agreementId: null,
                emailTo: null,
            },
            paymentStatuses: [
                {
                    enumValue: 'PAID',
                    name: 'Opłacony',
                },
                {
                    enumValue: 'PAID_BY_SUBSCRIPTION',
                    name: 'Opłacony z subskrypcji',
                },
                {
                    enumValue: 'UNPAID',
                    name: 'Nieopłacony',
                },
            ],
            statuses: [
                {
                    enumValue: 'SENT',
                    name: 'Wysłano',
                },
                {
                    enumValue: 'GENERATED',
                    name: 'Wygenerowano',
                },
                {
                    enumValue: 'FOR_APPROVAL',
                    name: 'Do akceptacji',
                },
            ],
        };
        this.authService = new AuthService();
        this.agreementDataService = new AgreementTemplateForUserService();
        this.openRemoveDialog = this.openRemoveDialog.bind(this);
        this.renderCardComponent = this.renderCardComponent.bind(this);
        this.clearInputsMail = this.clearInputsMail.bind(this);
        this.redirectToEdit = this.redirectToEdit.bind(this);
        this.closeRemoveDialog = this.closeRemoveDialog.bind(this);
        this.clearBorderFromAgreements = this.clearBorderFromAgreements.bind(this);
    }
    getDataKey() {
        return 'id';
    }

    getListTitle() {
        return 'agreement.listTitle';
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    updateSearchCriteria(criteria) {
        return {
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            text: criteria.text,
            name: criteria.name,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            client: criteria.client,
            sortAsc: criteria.sortAsc,
            status: criteria.status,
            paymentStatus: criteria.paymentStatus,
        };
    }

    getCleanSearchCriteria() {
        return {
            dateFrom: undefined,
            dateTo: undefined,
            text: '',
            name: '',
            client: '',
            status: undefined,
            paymentStatus: undefined,
            id: null,
            firstResult: 0,
            maxResults: 5,
            sortField: 'id',
            sortAsc: false,
        };
    }
    openRemoveDialog(e) {
        const selectedList = this.state.selectedList;
        if (e) {
            e.preventDefault();
        }
        if (selectedList.length === 0) {
            return;
        }
        this.setState({removeDialogVisibile: true});
    }
    closeRemoveDialog(callback) {
        this.setState({removeDialogVisibile: false}, () => {
            if (callback) callback();
        });
    }
    prepareHeaderItems() {
        const {t} = this.props;
        const {selectedList} = this.state;
        const isDisabled = selectedList.length === 0;
        return this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE') && device.type !== 'mobile'
            ? [
                  {
                      label: t('Usuń zaznaczone'),
                      type: 'BUTTON',
                      className: 'red-button ',
                      variant: '',
                      iconSide: 'left',
                      disabled: isDisabled,
                      customRenderFunction: () => (
                          <div width={50} className='cursor-pointer' onClick={this.openRemoveDialog}>
                              <img src={this.iconDeleteUrlForLexDocS(isDisabled)} alt='' className='cursor-pointer'></img>
                          </div>
                      ),
                  },
              ]
            : [];
    }

    initializeFromBackend() {}

    getCriteriaName() {
        return 'agreement-list-sc';
    }

    getContainerListName() {
        return 'agreement-list-container';
    }
    readMessage() {
        const paidFlag = qs.parse(this.props.history.location.search, {ignoreQueryPrefix: true}).paid;
        if (paidFlag) {
            const urlStr = window.location.toString().replace('/#', '');
            const url = new URL(urlStr);
            const agreeementUuid = url.searchParams.get('uuid');
            this.service.templatePaid(agreeementUuid).then((res) => {
                if (res) {
                    this.setState({
                        showConfirtmationMessage: true,
                    });
                } else {
                    this.showErrorMessage('Dokument nie został opłacony');
                }
            });
        }
        super.readMessage();
    }
    renderCriteria() {
        let userCriteriaStatus = (
            <CriteriaDropdownComponent
                id='status-sc'
                name='status'
                showClear
                label={'Status'}
                colClass='col-lg-6 col-md-6 col-sm-4'
                value={this.state.criteria.status}
                options={this.state.statuses}
                placeholder='Wszystkie'
                onChange={this.handleChangeSc}
                validator={this.validator}
                optionLabel='name'
            />
        );

        let adminCriteriaStatus = (
            <CriteriaDropdownComponent
                id='payment-status-sc'
                name='paymentStatus'
                showClear
                label={'Status płatności'}
                colClass='col-lg-6 col-md-6 col-sm-6'
                value={this.state.criteria.paymentStatus}
                options={this.state.paymentStatuses}
                placeholder='Wszystkie'
                onChange={this.handleChangeSc}
                validator={this.validator}
                optionLabel='name'
            />
        );
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='text-sc'
                    name='text'
                    label={'Tytuł szablonu'}
                    placeholder='Wpisz tytuł...'
                    colClass='col-xl-4 col-lg-6 col-sm-6'
                    value={this.state.criteria.text}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
                <CriteriaTextComponent
                    id='document-name-sc'
                    name='name'
                    label={'Nazwa dokumentu'}
                    placeholder='Wpisz nazwe...'
                    colClass='col-xl-4 col-lg-6 col-sm-6'
                    value={this.state.criteria.name}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />

                <CriteriaTextComponent
                    id='client-sc'
                    name='client'
                    label={'Klient'}
                    colClass='col-xl-4 col-lg-6 col-sm-6'
                    placeholder='Wpisz klienta...'
                    value={this.state.criteria.client}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
                <CriteriaCalendarRangeComponent
                    id='date-sc'
                    idFrom='dateFrom-sc'
                    idTo='dateTo-sc'
                    name='date'
                    nameFrom='dateFrom'
                    nameTo='dateTo'
                    label={'Data zakupu'}
                    colClass='col-xl-6 col-lg-12 col-sm-12'
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    refreshFromBackend
                    showButtonBar
                    sharedLabel
                    renderDash
                    maxDateInfinite
                    placeholderFrom={t('details.from')}
                    placeholderTo={t('details.to')}
                    inputsClassName='col-6'
                    renderDash={false}
                />

                {this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_PAYMENT_MANAGE')
                    ? adminCriteriaStatus
                    : userCriteriaStatus}
            </div>
        );
    }
    prepareColumns() {
        const {t} = this.props;

        let columns = [
            {field: 'template.name', header: t('agreement.listHeaders.templateName'), sortable: true},
            {field: 'draftName', header: 'Nazwa dokumentu', sortable: true},
            {
                field: 'dateOfPurchase',
                header: 'Data zakupu',
                body: this.dateTemplate.bind(this, 'dateOfPurchase', 'DD-MM-YYYY'),
                sortable: true,
            },
        ];

        const columnsUser = [
            {field: 'status', header: 'Status', body: this.enumTemplate.bind(this, 'AgreementDataStatus', 'status')},
            {
                key: 'actions',
                header: '',
                size: '600px',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];

        const columnsAdmin = [
            {field: 'fullName', header: 'Klient', sortable: true},
            {field: 'paymentStatus', header: 'Status', body: this.enumTemplate.bind(this, 'AgreementDataPaymentStatus', 'paymentStatus')},
            {
                key: 'actions',
                width: '130px',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];

        if (this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_PAYMENT_MANAGE')) {
            return columns.concat(columnsAdmin);
        } else {
            const userCols = columns.concat(columnsUser);
            userCols.unshift({selectionMode: 'multiple'});
            return userCols;
        }
    }

    enumTemplate(enumType, field, rowData) {
        if (this.isLexyPortal() || enumType !== 'AgreementDataPaymentStatus') {
            if (rowData[field]) {
                return this.translate(`${enumType}.${rowData[field]}`);
            }
            return '';
        }
        let imageSrc = AppPrefixUtils.locationHrefUrl(`/images/lexdocs/`);

        switch (rowData[field]) {
            case 'PAID':
                return <img src={imageSrc + 'done_blue.svg'} alt='' />;
            case 'UNPAID':
                return <img src={imageSrc + 'close.svg'} alt='' />;
            default:
                return <img src={imageSrc + 'done_green.svg'} alt='' />;
        }
    }

    redirectToEdit(url) {
        window.location.href = url;
    }

    actionTemplate(rowData) {
        return (
            <div className=''>
                {!this.isLexyPortal() ? (
                    this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE') ? (
                        <img
                            src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/edit_blue_24dp.svg`)}
                            alt=''
                            className='cursor-pointer'
                            onClick={() => this.redirectToEdit(AppPrefixUtils.locationHrefUrl(`/#/agreement/edit/${rowData.uuid}`))}
                        />
                    ) : null
                ) : (
                    <ActionLink
                        rendered={this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE')}
                        label={this.props.label ? this.props.label : 'Edytuj'}
                        className='p-link float-left'
                        href={AppPrefixUtils.locationHrefUrl(`/#/agreement/edit/${rowData.uuid}`)}
                    />
                )}

                {!this.isLexyPortal() ? (
                    <img
                        src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/download.svg`)}
                        alt=''
                        className='cursor-pointer'
                        onClick={this.generatePdf.bind(this, rowData)}
                    />
                ) : (
                    <ActionLink
                        className='p-link flex-end'
                        label={this.props.label ? this.props.label : this.isLexyPortal() ? 'Wygeneruj' : ''}
                        key={this.props.id}
                        handleClick={this.generatePdf.bind(this, rowData)}
                    />
                )}
            </div>
        );
    }
    generatePdf(rowData, e) {
        if (e) {
            e.preventDefault();
        }
        this.service
            .generatePdf(rowData.id)
            .then((response) => {
                const fileName = `${rowData?.template?.name}.pdf`;
                StorageUtils.downloadBase64(response.base64, fileName);
                this.refreshTable();
            })
            .catch((err) => {
                this.showErrorMessage(err.message, 10000);
            });
    }
    breadCrumbItems() {
        if (this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE') || !this.authService.loggedIn()) {
            return [];
        }
        if (this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE') || !this.authService.loggedIn()) {
            return [{name: 'Moje dokumenty', url: ''}];
        }

        return [
            {
                name: this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_PAYMENT_MANAGE')
                    ? 'Dokumenty użytkowników'
                    : '',
                url: '/#/agreement-list',
            },
        ];
    }

    handleOnSelectionChange(id) {
        if (this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE') || this.isMobileLexDocS()) {
            const elementFromList = this.state.list.find((el) => el.id === id);
            let selectedList = this.state.selectedList;
            const elementFromSelectedList = selectedList.find((el) => el.id === id);
            if (elementFromSelectedList) {
                selectedList = selectedList.filter((el) => el.id !== id);
            } else {
                selectedList.push(elementFromList);
            }
            this.setState({
                selectedList,
            });
        } else {
            this.setState({
                selectedList: id.value,
            });
        }
    }

    renderFilter() {
        return this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE') || !this.authService.loggedIn() || this.isMobileLexDocS()
            ? this.renderCustomCriteria()
            : super.renderFilter();
    }

    renderCustomCriteria() {
        const {selectedList} = this.state;
        const isDisabled = selectedList.length === 0;

        return this.isLexyPortal() ? (
            <div>
                <div id='template-list-header' className='container'>
                    <div id='tempalte-list-header-title'>
                        <p>Moje dokumenty</p>
                    </div>
                    <div id='tempalte-list-header-description'></div>
                    <div className='row col-12'>
                        <CriteriaDropdownComponent
                            id='status-sc'
                            name='status'
                            showClear
                            colClass='col-xl-6 col-lg-6 col-sm-12'
                            value={this.state.criteria.status}
                            options={this.state.statuses}
                            placeholder='Status'
                            onChange={this.handleChangeSc}
                            onAfterStateChange={this.refreshTable}
                            validator={this.validator}
                            optionLabel='name'
                        />
                        <span className='p-input-icon-right col-xl-6 col-lg-6 col-sm-12 mt-1'>
                            <i className='pi pi-search' onClick={this.refreshTable} style={{marginRight: '20px', marginTop: '-12px'}} />
                            <InputText
                                className='col-6 custom-input-text-border'
                                value={this.state.criteria.name}
                                placeholder='Nazwa dokumentu...'
                                onChange={(el) => {
                                    this.setState((prevState) => ({
                                        criteria: {
                                            ...prevState.criteria,
                                            name: el.target.value,
                                        },
                                    }));
                                }}
                            />
                        </span>
                    </div>
                    {this.authService.loggedIn() && (
                        <div width={75} onClick={this.openRemoveDialog} className='cursor-pointer'>
                            <img src={this.iconDeleteUrlForLexDocS(isDisabled)} alt=''></img>
                        </div>
                    )}
                </div>
            </div>
        ) : (
            <div width={50} onClick={this.openRemoveDialog}>
                <img src={this.iconDeleteUrlForLexDocS(isDisabled)} alt=''></img>
            </div>
        );
    }
    iconDeleteUrlForLexDocS(isDisabled) {
        return isDisabled
            ? AppPrefixUtils.locationHrefUrl(`/images/lexdocs/delete_silver.svg`)
            : AppPrefixUtils.locationHrefUrl(`/images/lexdocs/delete_red.svg`);
    }
    isMobileLexDocS() {
        return this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE') && device.type === 'mobile';
    }
    renderCardComponent(list, size) {
        if (list?.length !== 0) {
            return (
                <div className=''>
                    <div className='container-centered row mt-2'>
                        {list?.map((rowData) => {
                            return this.isLexyPortal() ? (
                                <UserLexyDocumentsCardComponent
                                    onSend={() => {
                                        this.setState((prevState) => ({
                                            visibleSendToContractorDialog: true,
                                            emailSendAgreementData: {
                                                ...prevState.emailSendAgreementData,
                                                agreementId: rowData.id,
                                            },
                                        }));
                                    }}
                                    onChangeEdit={() => {
                                        window.location.href = AppPrefixUtils.locationHrefUrl(`/#/agreement/edit/${rowData.uuid}`);
                                    }}
                                    onSelectAgreement={(id) => {
                                        this.handleOnSelectionChange(id);
                                    }}
                                    onChangeGenerate={this.generatePdf.bind(this, rowData)}
                                    btnGenerateVisible
                                    index={rowData.id}
                                    btnEditVisible={this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE')}
                                    documentName={rowData.draftName}
                                    documentTitle={rowData?.template?.description}
                                    statusName={t(`AgreementDataStatus.${rowData.status}`)}
                                    status={rowData.status}
                                    buyDate={rowData.dateOfPurchase}
                                />
                            ) : (
                                <UserLexDocSDocumentsCardComponent
                                    onSend={() => {
                                        this.setState((prevState) => ({
                                            visibleSendToContractorDialog: true,
                                            emailSendAgreementData: {
                                                ...prevState.emailSendAgreementData,
                                                agreementId: rowData.id,
                                            },
                                        }));
                                    }}
                                    onChangeEdit={() => {
                                        window.location.href = AppPrefixUtils.locationHrefUrl(`/#/agreement/edit/${rowData.uuid}`);
                                    }}
                                    onSelectAgreement={(id) => {
                                        this.handleOnSelectionChange(id);
                                    }}
                                    onChangeGenerate={this.generatePdf.bind(this, rowData)}
                                    btnGenerateVisible
                                    index={rowData.id}
                                    btnEditVisible={this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE')}
                                    documentName={rowData.draftName}
                                    documentTitle={rowData?.template?.description}
                                    statusName={t(`AgreementDataStatus.${rowData.status}`)}
                                    status={rowData.status}
                                    buyDate={rowData.dateOfPurchase}
                                />
                            );
                        })}

                        <PaginationControl
                            page={this.calculateCurrentPage()}
                            between={4}
                            total={this.calculateTotalPages(size)}
                            limit={1}
                            changePage={(page) => {
                                this.changePage(page);
                            }}
                            ellipsis={2}
                        />
                    </div>
                </div>
            );
        }
    }

    changePage = (page) => {
        const firstCount = this.state.criteria.maxResults * (page - 1);
        const cardDocuments = Array.from(document.getElementsByClassName('card-documents'));

        cardDocuments.forEach((el) => {
            el.classList.remove('selected-agreement');
        });

        this.setState(
            (prevState) => ({
                selectedList: [],
                loading: this.props.lazy,
                first: firstCount,
                criteria: {
                    ...prevState.criteria,
                    firstResult: firstCount,
                },
            }),
            () => (this.props.lazy ? this.refreshTable() : null)
        );
    };

    handleSendFormMail() {
        if (this.validator.allValid()) {
            this.blockUi(this.handleValidForm);
            this.setState(
                {
                    visibleMailSendDialog: false,
                },
                () => {
                    this.showSuccessMessage('Wysłano maila');
                    this.clearInputsMail();
                }
            );
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.scrollToError = true;
            this.forceUpdate();
        }
    }
    handleSendValidFormMail() {
        this.unblockUi();

        if (this.validator.allValid()) {
            const visibleMailSendDialog = this.state.visibleMailSendDialog;
            this.setState({
                visibleMailSendDialog: false,
            });
            if (visibleMailSendDialog) {
                this.service
                    .sendEmailAgreementData(this.state.emailSendAgreementData)
                    .then(() => {
                        this.showSuccessMessage('Wysłano maila z umową');
                        this.refreshTable();
                        this.clearInputsMail();
                    })
                    .catch(() => {
                        this.showErrorMessage('Błąd wysyłki maila');
                        // this.clearInputsMail();
                    });
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.scrollToError = true;
            this.forceUpdate();
        }
    }
    clearInputsMail() {
        this.setState({
            emailSendAgreementData: {
                emailTo: undefined,
                subject: undefined,
                description: undefined,
            },
        });
    }
    openSendToContractorDialog() {
        this.setState({visibleSendToContractorDialog: true});
    }
    closeSendToContractorDialog() {
        this.setState({visibleSendToContractorDialog: false});
    }
    renderView() {
        return this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE') ? (
            <div className='card-unstylled'>
                {this.state.showConfirtmationMessage && (
                    <YesNoDialog
                        className={'confirmation-dialog'}
                        visible={true}
                        header='Gotowe!'
                        onChange={() => {
                            this.setState({
                                showConfirtmationMessage: false,
                            });
                        }}
                        onHide={() => {
                            this.setState({
                                showConfirtmationMessage: false,
                            });
                        }}
                        singleButton={true}
                        noButtonLabel={'Zamknij'}
                    >
                        Możesz teraz wygenerować dokument.
                    </YesNoDialog>
                )}
                {this.state.visibleSendToContractorDialog && (
                    <YesNoDialog
                        className={'send-to-contractor-dialog'}
                        visible={this.state.visibleSendToContractorDialog}
                        header='Wyślij do kontrahenta'
                        name='visibleSendToContractorDialog'
                        onChange={() => {
                            if (this.validator.allValid()) {
                                this.service
                                    .sendToContractor(this.state.contractorEmail, this.state.emailSendAgreementData.agreementId)
                                    .then(() => {
                                        this.showSuccessMessage('Wysłano do kontrahenta');
                                        this.refreshTable();
                                    })
                                    .catch(() => {
                                        this.showErrorMessage('Problem z wysłaniem maila do kontrahenta.');
                                    });

                                this.closeSendToContractorDialog();
                            } else {
                                this.validator.showMessages();
                                this.scrollToError = true;
                                this.forceUpdate();
                            }
                        }}
                        onHide={() => {
                            this.closeSendToContractorDialog();
                            DialogResizer.removeMaskIfExistOneDialog();
                        }}
                        noButtonLabel={'Wyślij'}
                        singleButton
                    >
                        <InputTextComponent
                            id={`contractorEmail`}
                            name={`contractorEmail`}
                            label={`Email kontrahenta`}
                            colClass='col-md-12'
                            value={this.state.contractorEmail}
                            validator={this.validator}
                            stateField={''}
                            viewMode={'EDIT'}
                            validators='required|email'
                            onChange={this.handleChange}
                        />
                    </YesNoDialog>
                )}
                <DivContainer colClass=''>
                    <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />

                    <Card className='list-card'>
                        <DivContainer colClass='row'>{this.renderFilter()}</DivContainer>
                        <DivContainer colClass='row'>{this.renderHeader()}</DivContainer>
                        {this.renderDataTable(this.prepareColumns())}
                    </Card>
                </DivContainer>
            </div>
        ) : (
            super.renderView()
        );
    }
    renderDataTable(columns, list, size) {
        if (list === null || list === undefined) {
            list = this.state.list;
        }
        if (size === null || size === undefined) {
            size = this.state.size;
        }
        return this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE') || this.isMobileLexDocS() ? (
            <React.Fragment>{this.renderCardComponent(list, size)}</React.Fragment>
        ) : (
            super.renderDataTable(columns, list, size)
        );
    }

    calculateCurrentPage() {
        const maxResults = this.state.criteria.maxResults;
        const firstResult = this.state.criteria.firstResult;
        const result = firstResult / maxResults + 1;
        const numberText = result.toString();
        if (numberText.includes('.')) {
            return Math.ceil(result) + 1;
        } else {
            return result;
        }
    }
    calculateTotalPages(size) {
        const maxResults = this.state.criteria.maxResults;
        const result = size / maxResults;
        const numberText = result.toString();
        if (numberText.includes('.')) {
            return Math.ceil(result);
        } else {
            return result;
        }
    }

    clearBorderFromAgreements() {
        const cards = Array.from(document.getElementsByClassName('card-documents'));
        cards.forEach((el) => {
            el.classList.remove('selected-agreement');
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.removeDialogVisibile ? (
                    <YesNoDialog
                        visible={this.state.removeDialogVisibile}
                        header={'Usuwanie dokumentów'}
                        name='visible'
                        onChange={(type, x, target) => {
                            if (target.value) {
                                this.agreementDataService
                                    .removeSelectedAgreementData({ids: this.state.selectedList.map((i) => i.id)})
                                    .then(() => {
                                        this.closeRemoveDialog(() => {
                                            this.showSuccessMessage('Dokumenty zostały usunięte');
                                            this.setState({selectedList: []});
                                            this.clearBorderFromAgreements();
                                            this.refreshTable();
                                        });
                                    })
                                    .catch((err) => {
                                        this.closeRemoveDialog(() => {
                                            this.showErrorMessage(err.message);
                                        });
                                    });
                            } else {
                                this.closeRemoveDialog();
                            }
                        }}
                        onHide={() => {
                            this.closeRemoveDialog();
                            DialogResizer.removeMaskIfExistOneDialog();
                        }}
                        yesButtonLabel='Tak'
                        yesButtonClassName=''
                    >
                        <label>Czy na pewno chcesz usunąć wybrane dokumenty?</label>
                    </YesNoDialog>
                ) : null}
                <div className='container-fluid'>
                    {/* {!this.props.embedded ?  : null} */}
                    <BaseBreadCrumb items={this.breadCrumbItems()}>{'Konfiguratory'}</BaseBreadCrumb>
                    {this.renderView()}
                </div>
            </React.Fragment>
        );
    }
}

AgreementList.defaultProps = {
    detailUrl: '/#/agreement/edit',
    newUrl: '/#/agreement/create',
    lazy: true,
};

AgreementList.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(AgreementList);
