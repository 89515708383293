export default class EventModel {
    constructor() {
        this.id = undefined;
        this.end = undefined;
        this.consultationId = undefined;
        this.consultantId = undefined;
        this.clientId = undefined;
        this.start = undefined;
        this.specialization = undefined;
        this.title = undefined;
        this.status = undefined;
        this.user = undefined;
        this.version = undefined;
        this.backgroundColor = undefined;
        this.textColor = undefined;
        this.isViewed = true;
        this.isSelected = false;
    }

    static assign(from, to) {
        if (from) {
            to.id = from.id;
            to.end = from.end;
            to.start = from.start;
            to.specialization = from.specialization;
            to.consultationId = from.id;
            to.consultantId = from.user?.id;
            to.title = from.title;
            to.status = from.status;
            to.user = from.user;
            to.version = from.version;
            to.backgroundColor = from.backgroundColor;
            to.textColor = from.textColor;
            to.isSelected = from.isSelected;
            to.isViewed = from.isViewed === undefined ? true : from.isViewed;
        } else {
            to = new EventModel();
        }
    }

    static copy(from) {
        const to = new EventModel();
        EventModel.assign(from, to);
        return to;
    }
}
