import {Button} from 'primereact/button';
import PropTypes from 'prop-types';
import React from 'react';
import {t} from 'i18next';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import {withTranslation} from 'react-i18next';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import InputTextEditorComponent from '../components/inputs/InputTextEditorComponent';
import EmailService from '../services/EmailService';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import InputFileUploadComponent from '../components/inputs/InputFileUploadComponent';
import AuthService from '../services/AuthService';
import FileUploadService from '../services/FileUploadService';
import {CookiesConstants} from '../utils/CookiesConstants';
import YesNoDialog from '../components/YesNoDialog';
import {UAParser} from 'ua-parser-js';
import DialogResizer from '../components/DialogResizer';

class EmailContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new EmailService());
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.showExitConfirmationDialog = true;
        const {device} = UAParser(navigator.userAgent);
        this.device = device;
        this.state = {
            loading: true,
            elementId: props.params.id,
            element: {
                id: undefined,
                content: '',
                description: '',
                subject: '',
                emailType: undefined,
                attachments: [],
                photos: [],
            },
            emailTypeOptions: [],
            parameters: [],
        };
        this.authService = new AuthService();
        this.editorRef = React.createRef();
        this.fileUploadService = new FileUploadService();
    }
    componentDidMount() {
        super.componentDidMount();
        window.addEventListener(
            'resize',
            () => {
                setTimeout(() => {
                    this.setState({availableWidth: window.innerWidth});
                }, 500);
            },
            false
        );
    }
    updateElement(data) {
        this.setState(
            {
                element: {
                    id: data.id,
                    active: data.active,
                    content: data.content,
                    attachments: data.attachments ? data.attachments : [],
                    photos: data.photos ? data.photos : [],
                    description: data.description,
                    subject: data.subject,
                    emailType: data.emailType,
                },
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    translate(key) {
        return t(key);
    }

    initBeforeSetElement() {
        this.enumService
            .getEnumList('EmailType', this.translate)
            .then((emailTypeOptions) =>
                this.setState({
                    emailTypeOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy typów wiadomości');
            });
    }
    initAfterSetElement() {
        if (this.state.element.emailType) {
            this.service
                .getParameters(this.state.element.emailType)
                .then((res) => {
                    this.setState({parameters: res});
                })
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy parametrów');
                });
        }
        super.initAfterSetElement();

        this.hideToolbar();
    }

    prepareFooterItems() {
        const {backUrl, cancelUrl, viewMode} = this.props;
        let goBackUrl;
        if (viewMode === 'NEW') {
            goBackUrl = backUrl;
        } else {
            goBackUrl = cancelUrl + '/' + this.props.elementId;
        }
        return [
            {
                label: 'Anuluj',
                href: AppPrefixUtils.locationHrefUrl(goBackUrl),
                className: 'gray',
                rendered: viewMode === 'NEW' || viewMode === 'EDIT',
            },
            {
                label: 'Zapisz',
                className: 'float-right',
                onClick: this.handleFormSubmit,
                rendered: viewMode === 'NEW' || viewMode === 'EDIT',
            },
        ];
    }

    prepareHeaderItems() {
        const {viewMode} = this.props;
        return [this.prepareEditButton(viewMode === 'VIEW')];
    }
    prepareEditButton(rendered, label) {
        const {editUrl} = this.props;
        const {element} = this.state;

        return {
            label: label !== undefined ? label : 'Edytuj',
            type: 'BUTTON',
            variant: '',
            onClick: (e) => {
                this.saveCookie(CookiesConstants.DETAILS_VIEW_MODE, 'EDIT');
            },
            href: AppPrefixUtils.locationHrefUrl(`${editUrl}/${element.id}`),
            rendered: rendered,
            // iconName: 'mdi-pencil',
            iconSide: 'left',
            iconSize: 'm',
        };
    }

    getBackLabel() {
        return 'Wróć do listy';
    }

    tachmentRemove(e, i) {
        if (this._isMounted) {
            const modifiedList = this.state.element.attachments;
            modifiedList.splice(i, 1);
            this.setState((prevState) => ({
                element: {
                    ...prevState.element,
                    attachments: modifiedList,
                },
            }));
        }
    }

    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/email-template-list' className='p-link'>
                    {'Szablony wiadomości'}
                </BreadcrumbsItem>
                {this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/email-template/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
                {this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/email-template/edit'>{'Edycja'}</BreadcrumbsItem> : null}
                {this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/email-template/create'>{'Nowy'}</BreadcrumbsItem> : null}
                {this.device?.type && (this.device.type === 'mobile' || this.device.type === 'tablet') ? (
                    <YesNoDialog
                        className={'confirmation-dialog invalid-operation'}
                        visible={true}
                        header='Strona niedostępna'
                        name='dialogVisibile'
                        onChange={(type, x, target) => {
                            window.location.href = AppPrefixUtils.locationHrefUrl(this.props.backUrl);
                        }}
                        onHide={() => {
                            window.location.href = AppPrefixUtils.locationHrefUrl(this.props.backUrl);
                            DialogResizer.removeMaskIfExistOneDialog();
                        }}
                        children={
                            <div>
                                <label>Ta strona jest niedostępna dla urządzenia</label>
                            </div>
                        }
                        singleButton
                        noButtonLabel={'Wróć do listy szablonów'}
                    ></YesNoDialog>
                ) : this.state.loading ? null : (
                    this.renderView()
                )}
            </div>
        );
    }
    getContainerListName() {
        return 'email-list-container';
    }

    getUpdateSucces() {
        return 'Szablon wiadomości został zaktualizowany';
    }
    hideToolbar() {
        if (document.getElementsByClassName('ql-toolbar')?.length > 0) {
            document.getElementsByClassName('ql-toolbar')[0].style.display = 'none';
        }
    }
    breadCrumbItems() {
        return [
            {name: 'Szablony e-mail', url: '/#/email-template-list'},
            {name: 'Szablon e-mail', url: `/#/email-template/details/${this.state.element?.id}`},
        ];
    }

    renderDetails() {
        let parameterButtonDisabled = !(this.state.parameter != null && this.state.parameter !== undefined);

        return (
            <React.Fragment>
                {this.renderProperStructureForDetail(
                    <>
                        <div class='row'>
                            <div class='col-md-6 col-sm-12'>
                                <InputDropdownComponent
                                    id='emailType-sc'
                                    name='emailType'
                                    label={'Typ wiadomości'}
                                    colClass='col-md-12'
                                    value={this.translate(`EmailType.${this.state.element.emailType}`)}
                                    validator={this.validator}
                                    options={this.state.emailTypeOptions}
                                    onChange={this.handleChange}
                                    viewMode={this.props.viewMode === 'EDIT' ? 'VIEW' : this.props.viewMode}
                                    optionLabel='label'
                                    dataKey='value'
                                    valueView={this.state.element.emailType ? this.state.element.emailType.label : ''}
                                />
                                <InputTextComponent
                                    id='description'
                                    name='description'
                                    label={'Opis'}
                                    colClass='col-md-12'
                                    value={this.state.element.description}
                                    validator={this.validator}
                                    validators='required|max:255'
                                    onChange={this.handleChange}
                                    viewMode={this.props.viewMode}
                                />
                                <InputTextComponent
                                    id='subject'
                                    name='subject'
                                    label={'Temat'}
                                    colClass='col-md-12'
                                    value={this.state.element.subject}
                                    validator={this.validator}
                                    validators='required|max:255'
                                    onChange={this.handleChange}
                                    viewMode={this.props.viewMode}
                                />
                                {this.props.viewMode !== 'VIEW' ? (
                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                        <div className='row'>
                                            <InputDropdownComponent
                                                id='parameter'
                                                name='parameter'
                                                label='Parametr'
                                                optionLabel='label'
                                                dataKey='enumValue'
                                                validator={this.validator}
                                                validators='not_required'
                                                value={this.state.parameter}
                                                options={this.state.parameters}
                                                colClass='col-lg-6 col-md-12 col-sm-12'
                                                onChange={this.handleChange}
                                                viewMode={this.props.viewMode}
                                                rendered
                                                stateField=''
                                                showClear={false}
                                                valueView={this.state.parameter}
                                            />
                                            <div className='col-lg-6 col-md-6 col-sm-4'>
                                                <Button
                                                    className='insert-button p-component p-button-text-only float-right p-button-text-only col-lg-6 '
                                                    style={{
                                                        maxHeight: '34px',
                                                        padding: '17px 20px',
                                                        width: '100%',
                                                        borderRadius: '50px',
                                                        fontWeight: '550',
                                                    }}
                                                    label='Wstaw parametr'
                                                    disabled={parameterButtonDisabled}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        const quill = this.editorRef.current.quill;
                                                        const range = quill.getSelection();
                                                        const position = range ? range.index : 0;
                                                        quill.insertText(position, this.state.parameter.code);
                                                    }}
                                                >
                                                    <img src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/add_blue.svg`)} alt=''></img>
                                                </Button>
                                            </div>
                                        </div>{' '}
                                        <div></div>
                                    </div>
                                ) : null}
                            </div>
                            <InputTextEditorComponent
                                colClass='col-xl-12'
                                id='content'
                                name='content'
                                value={this.state.element.content === 'null' ? '' : this.state.element?.content}
                                label='Treść odpowiedzi'
                                placeholder='Treść odpowiedzi...'
                                validator={this.validator}
                                validators={`max:2048|required`}
                                rows='10'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                                autoResize
                                editorRef={this.editorRef}
                                externalPhotos={true}
                                photos={this.state.element.photos}
                                photoUrl={`${this.service.getPath()}/photo/${this.props.elementId}`}
                                photoUploadUrl={`${this.fileUploadService.getPath()}/`}
                                photoLabel='fileName'
                                photoName='uuid'
                                handleError={this.onUploladError}
                                restrictExt={false}
                                token={this.authService.getToken()}
                                photoVarName='photos'
                            />

                            <InputFileUploadComponent
                                messages={this.messages}
                                id='attachments'
                                name='attachments'
                                label={'Załączniki'}
                                file={this.state.element.attachments}
                                showLabel
                                multiple={true}
                                fileList={this.state.element.attachments}
                                restApiUrl={`${this.service.getPath()}/${this.state.element.id}/attachment`}
                                maxFileSize={50000000}
                                onChange={this.handleChange}
                                itemLabel='fileName'
                                itemName='uuid'
                                colClass='col-xl-12'
                                viewMode={this.props.viewMode}
                                style={{width: '100%'}}
                                handleError={this.onUploladError}
                                token={this.authService.getToken()}
                                validator={this.validator}
                                restrictExt={false}
                                validators='not_required'
                                onAfterStateChange={() =>
                                    this.setState({
                                        validationResult: undefined,
                                    })
                                }
                            />
                        </div>
                    </>
                )}
            </React.Fragment>
        );
    }
}

EmailContainer.defaultProps = {
    backUrl: '/#/email-template-list',
    cancelUrl: '/#/email-template/details',
    editUrl: '/#/email-template/edit',
    // editUrl: '/#/email-template/edit',
    lazy: true,
};

EmailContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withTranslation()(EmailContainer);
