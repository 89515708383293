import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../baseContainers/BaseListContainer';
import ActionLink from '../components/ActionLink';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import CriteriaCheckboxComponent from '../components/criteria/CriteriaCheckboxComponent';
import EventLogService from '../services/EventLogService';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import BaseBreadCrumb from '../baseContainers/BaseBreadCrumb';

class EventLogListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new EventLogService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            filters: {},
            eventTypeOptions: [],
            logTypeOptions: [],
        };
    }

    updateSearchCriteria(criteria) {
        return {
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            eventType: criteria.eventType,
            logType: criteria.logType,
            userEmail: criteria.userEmail,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            onlyLogOut: criteria.onlyLogOut,
            objectType: criteria.objectType,
            objectId: criteria.objectId,
        };
    }

    getCleanSearchCriteria() {
        return {
            dateFrom: undefined,
            dateTo: undefined,
            eventType: undefined,
            logType: undefined,
            objectType: undefined,
            objectId: undefined,
            userEmail: '',
            firstResult: 0,
            maxResults: 10,
            sortField: 'date',
            sortAsc: false,
            onlyLogOut: false,
        };
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('EventType', this.translate)
            .then((eventTypeOptions) =>
                this.setState({
                    eventTypeOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy typów zdarzeń');
            });
        this.enumService
            .getEnumList('EventLogType', this.translate)
            .then((logTypeOptions) =>
                this.setState({
                    logTypeOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy typów logów');
            });
        this.enumService
            .getEnumList('EventLogObjectType', this.translate)
            .then((objectTypes) => this.setState({objectTypes}))
            .catch(() => {
                this.showErrorMessage('Nie można pobrać listy typów obiektów');
            });
    }
    getDataKey() {
        return 'id';
    }
    getListTitle() {
        return 'eventLog.listTitle';
    }
    getCriteriaName() {
        return 'event-log-list-sc';
    }

    getContainerListName() {
        return 'event-log-list-container';
    }
    renderCriteria() {
        return (
            <div>
                <div className='row'>
                    <CriteriaDropdownComponent
                        id='eventTypeSc'
                        name='eventType'
                        showClear
                        colClass='col-lg-3 col-sm-12'
                        label={'Typ zdarzenia'}
                        value={this.state.criteria.eventType}
                        options={this.state.eventTypeOptions}
                        placeholder='Wszystkie'
                        onChange={this.handleChangeSc}
                        validator={this.validator}
                        filter
                    />
                    <CriteriaTextComponent
                        id='userEmail-sc'
                        name='userEmail'
                        label={'Login'}
                        placeholder='Wpisz login...'
                        colClass='col-lg-3 col-sm-12'
                        value={this.state.criteria.userEmail}
                        onChange={this.handleChangeSc}
                        validator={this.validator}
                    />
                    <CriteriaDropdownComponent
                        id='objectType-sc'
                        name='objectType'
                        showClear
                        label={'Typ obiektu'}
                        colClass='col-lg-3 col-sm-12'
                        value={this.state.criteria.objectType}
                        options={this.state.objectTypes}
                        placeholder='Wszystkie'
                        onChange={this.handleChangeSc}
                        validator={this.validator}
                        filter
                    />
                    <CriteriaTextComponent
                        id='objectId-sc'
                        name='objectId'
                        label={'ID obiektu'}
                        placeholder='ID...'
                        colClass='col-lg-3 col-sm-12'
                        value={this.state.criteria.objectId}
                        onChange={this.handleChangeSc}
                        validator={this.validator}
                        validators='numeric'
                    />
                </div>
                <div className='row'>
                    <CriteriaCheckboxComponent
                        id='onlyLogOut'
                        name='onlyLogOut'
                        label='Pokaż akcje niezalogowanych'
                        value={this.state.criteria.onlyLogOut}
                        onChange={this.handleChangeSc}
                        validator={this.validator}
                    />
                </div>
            </div>
        );
    }
    //

    prepareColumns() {
        return [
            {field: 'id', header: 'ID', sortable: true, width: '90px'},
            {field: 'date', header: 'Data', sortable: true, body: this.dateTemplate.bind(this, 'date', 'YYYY-MM-DD HH:mm:ss')},
            {field: 'userEmail', header: 'Login', sortable: true},
            {field: 'eventType', header: 'Typ zdarzenia', sortable: true, body: this.enumTemplate.bind(this, 'EventType', 'eventType')},
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }
    handleGoBack(e) {
        e.preventDefault();
        this.blockUi();
        const {backUrl} = this.props;
        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
    }
    prepareHeaderItems() {
        return [];
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const detailHref = `${detailUrl}/${rowData.id}`;
        return (
            <div className='float-center'>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                    onClick={this.handleGoToDetail.bind(this, detailHref)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        );
    }
    breadCrumbItems() {
        return [{name: 'Dziennik zdarzeń', url: '/#/event-log-list'}];
    }
    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/event-log-list'>{'Dziennik zdarzeń'}</BreadcrumbsItem>
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                {this.renderView()}
            </div>
        );
    }
}

EventLogListContainer.defaultProps = {
    detailUrl: '/#/event-log/details',
    lazy: true,
};

EventLogListContainer.propTypes = {
    detailUrl: PropTypes.string,
};

export default withTranslation()(EventLogListContainer);
