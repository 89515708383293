export default class RolesUtils {
    static allRoles() {
        return [
            'ROLE_ADMIN_SUPER_MANAGE',
            'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
            'ROLE_LAW_OFFICE_EDITOR_MANAGE',
            'ROLE_USER_LEKSY_MANAGE',
            'ROLE_USER_LEX_DOCS_MANAGE',
            'ROLE_ADMIN_SETTING_MANAGE',
            'ROLE_ADMIN_PAYMENT_MANAGE',
            'ROLE_ADMIN_MANAGE_USER_MANAGE',
            'ROLE_ADMIN_PACKAGE_MANAGE',
            'ROLE_ADMIN_CONTENT_MANAGE',
            'ROLE_ADMIN_USER_MANAGE',
            'ROLE_ADMIN_TEMPLATE_MANAGE',
            'ROLE_ADMIN_TERM_ACCEPT_MANAGE',
            'ROLE_ADMIN_MANAGE_CONTENT_MANAGE',
            'ROLE_ADMIN_DOCUMENT_CHANGE_ACCEPT_MANAGE',
        ];
    }
}
