import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../baseContainers/BaseDialogContainer';
import {ActionButton} from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import EditableDataTableExt from '../components/EditableDataTableExt';
import InputNumberPropertiesComponent from '../components/inputs/ePrawnik/InputNumberPropertiesComponent';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputNumberComponent from '../components/inputs/InputNumberComponent';
import InputTextareaComponent from '../components/inputs/InputTextareaComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import AgreementTagModel from '../model/AgreementTagModel';
import InternalUserService from '../services/InternalUserService';
import InputMultiSelectComponent from '../components/inputs/InputMultiSelectComponent';
import DialogResizer from '../components/DialogResizer';
import moment from 'moment/moment';
import InputCalendarRangeComponent from '../components/inputs/InputCalendarRangeComponent';
import InputCheckboxComponent from '../components/inputs/InputCheckboxComponent';

class ParametersDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.requirementTypes = [
            {enumValue: 'REQUIRED', label: props.t('RequirementType.REQUIRED')},
            {enumValue: 'NOT_REQUIRED', label: props.t('RequirementType.NOT_REQUIRED')},
            {enumValue: 'NOT_REQUIRED_DIALOG', label: props.t('RequirementType.NOT_REQUIRED_DIALOG')},
        ];
        this.state = {
            element: new AgreementTagModel(),
            requirementType: 'REQUIRED',
            modifiedList: new Map(),
            addedList: [],
        };
        this.createNewElement = this.createNewElement.bind(this);
        this.resetNumberPropertiesWhenRomanConverted = this.resetNumberPropertiesWhenRomanConverted.bind(this);
    }
    createNewElement() {
        const newObject = {
            index: this.state.element?.answerOptions?.length + 1,
            name: '',
            editable: true,
            viewMode: this.props.viewMode,
        };
        return newObject;
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        if ((prevProps.visible === true && this.props.visible === false) || prevProps.element !== this.props.element) {
            this.setElement();
        }
    }
    updateElement(data) {
        this.setState(
            {
                element: AgreementTagModel.copy(data, this.translate),
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    setElement() {
        if (this.props.element) {
            this.updateElement(this.props.element);
        } else {
            this.initAfterSetElement();
        }
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton
                    label={t('details.cancel')}
                    variant={this.state.viewMode === 'DELETE' ? 'button cancel-button gray' : 'button cancel-button gray'}
                    handleClick={this.props.onHide}
                />
                <ActionButton
                    label={!this.props.element ? t('parameter.addButton') : t('details.save')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                />
            </div>
        );
    }
    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            //todo block ui!!!
            DialogResizer.removeMaskIfExistOneDialog();
            this.handleValidForm();
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // this.scrollToError = true;
            this.forceUpdate();
        }
    }
    createOrUpdate() {
        this.props.handleAddParameter(this.state.element);
        DialogResizer.removeMaskIfExistOneDialog();
    }
    resetNumberPropertiesWhenRomanConverted(numberProperties) {
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                numberProperties: numberProperties,
            },
        }));
    }
    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>{t('parameter.parameterDialog')}</label>
                </DivContainer>
            </DivContainer>
        );
    }
    isRequirementTypeSelectionHidden(type) {
        return ['STEP', 'SUB_TEMPLATE', 'POINT', 'POINT_REF', 'SUM', 'HELP', 'MULTIPLICATION', 'FAKE_BLURRED_TEXT'].find((t) => t === type);
    }
    canBeBelongingToDynamicList(type) {
        return !['STEP', 'HELP', 'POINT', 'POINT_REF', 'HELP'].find((t) => t === type);
    }
    renderSubtemplateOption(type) {
        return ['SUB_TEMPLATE', 'SELECT_TEMPLATE'].find((t) => t === type);
    }

    getAnswerOptionsTableColumns(type) {
        console.log(type, 'TYPE');
        let optionColumns = [
            {
                field: 'name',
                header: 'Nazwa',
                editor: 'TEXT',
                validators: 'required|max:255',
            },
        ];
        if (type === 'SELECT_TEMPLATE') {
            optionColumns = optionColumns.concat({
                field: 'subTemplate',
                header: 'Subszablon',
                editor: 'DROPDOWN',
                optionLabel: 'name',
                dataKey: 'id',
                options: this.props.subTemplates,
                editable: true,
                validators: 'required',
                filter: true,
                filterBy: 'name',
            });
        }
        return optionColumns;
    }

    dateMinMaxCanBeShown() {
        return this.state.element.type === 'DATE' && this.state.element.dateLimitRequired;
    }
    requirementDialogRendered(){
        if(this.state.element.requirementType === 'NOT_REQUIRED_DIALOG'){
            if(this.state.element.type=== 'FAKE_BLURRED_TEXT'){
                return false;
            }
            return true;
        }
        return false;
    }
    renderDetails() {
        const {t} = this.props;
        const optionColumns = this.getAnswerOptionsTableColumns(this.state?.element?.type);
        return (
            <DivContainer>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='field'
                        name='field'
                        label={t('parameter.field')}
                        colClass='col-md-12'
                        value={this.state.element.field}
                        validator={this.validator}
                        validators='required|max:255'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='label'
                        name='label'
                        label={t('parameter.label')}
                        colClass='col-md-12'
                        value={this.state.element.label}
                        validator={this.validator}
                        validators='required|max:255'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        rendered={this.state.element.type !== 'FAKE_BLURRED_TEXT'}
                    />
                    <InputTextareaComponent
                        id='description'
                        name='description'
                        label={t('parameter.description')}
                        colClass='col-md-12'
                        value={this.state.element.description}
                        validator={this.validator}
                        validators='not_required|max:2000'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        rendered={this.state.element.type !== 'FAKE_BLURRED_TEXT'}
                    />
                    <InputDropdownComponent
                        id='type'
                        name='type'
                        label={t('parameter.type')}
                        colClass='col-md-12'
                        value={this.state.element.type}
                        validator={this.validator}
                        options={this.props.parameterTypes}
                        itemTemplate={this.permissionTemplate}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        dataKey='enumValue'
                        validators='required'
                        optionLabel='label'
                        optionValue='enumValue'
                        enumMode
                        enumName='ParameterType'
                    />

                    <InputCheckboxComponent
                        id={`dateLimitRequired`}
                        name='dateLimitRequired'
                        label={'Wprowadź limity dat'}
                        labelOnRight
                        value={this.state.element.dateLimitRequired}
                        validator={this.validator}
                        colClass='col-12'
                        validators='not_required'
                        rendered={this.state.element.type === 'DATE'}
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                        disabled={this.props.viewMode === 'VIEW'}
                    />
                    <InputCalendarRangeComponent
                        key={'dates'}
                        idFrom={'minDate'}
                        idTo={'maxDate'}
                        nameTo={'maxDate'}
                        nameFrom={'minDate'}
                        label='' //{t('action.activityRange')}
                        labelFrom={'Minimalna data'}
                        labelTo={'Maksymalna data'}
                        colClass='col-12'
                        from={this.state.element.minDate ? this.state.element.minDate : new Date()}
                        to={this.state.element.maxDate ? this.state.element.maxDate : new Date()}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        showTime={false}
                        showIcon
                        validatorsTo={`required|date|after_or_equal~${moment(
                            this.state.element.maxDate ? this.state.element.maxDate : new Date()
                        )
                            .format('DD-MM-YYYY')
                            .toString()}`}
                        placeholderFrom='dd-mm-rrrr'
                        placeholderTo='dd-mm-rrrr'
                        validateViewMode
                        rendered={this.dateMinMaxCanBeShown()}
                        dateFormatMoment='DD-MM-YYYY'
                        validatorsToType='after_or_equal'
                    />
                    <InputDropdownComponent
                        id='requirementType'
                        name='requirementType'
                        label={t('parameter.requirementType')}
                        colClass='col-md-12'
                        value={this.state.element.requirementType}
                        validator={this.validator}
                        options={this.requirementTypes}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        dataKey='enumValue'
                        validators='required'
                        optionLabel='label'
                        optionValue='enumValue'
                        enumMode
                        enumName='RequirementType'
                        rendered={!this.isRequirementTypeSelectionHidden(this.state.element.type)}
                    />
                    <InputDropdownComponent
                        id='dictionary'
                        name='dictionary'
                        label={t('parameter.dictionary')}
                        colClass='col-md-12'
                        value={this.state.element.dictionary}
                        validator={this.validator}
                        options={this.props.dictionaries}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        dataKey='id'
                        validators='required'
                        optionLabel='name'
                        rendered={this.state.element.type === 'DICTIONARY'}
                    />
                    <InputTextareaComponent
                        id='requirementDialog'
                        name='requirementDialog'
                        label={t('parameter.requirementDialog')}
                        colClass='col-md-12'
                        value={this.state.element.requirementDialog}
                        validator={this.validator}
                        validators='required|max:1024'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        rendered={this.requirementDialogRendered()}
                    />
                    <InputNumberComponent
                        id='minText'
                        name='minText'
                        label={t('parameter.minText')}
                        colClass='col-md-12'
                        value={this.state.element.minText}
                        validator={this.validator}
                        validators='required'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        rendered={this.state.element.type === 'TEXT'}
                        min={1}
                    />
                    <InputNumberComponent
                        id='maxText'
                        name='maxText'
                        label={t('parameter.maxText')}
                        colClass='col-md-12'
                        value={this.state.element.maxText}
                        validator={this.validator}
                        validators='required'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        rendered={this.state.element.type === 'TEXT'}
                        min={1}
                    />
                    <EditableDataTableExt
                        id={`answerOptions`}
                        name='answerOptions'
                        label='Opcje odpowiedzi'
                        showLabel={true}
                        columns={optionColumns}
                        addedListName='addedList'
                        addedList={this.state.addedList}
                        modifiedListName='modifiedList'
                        modifiedList={this.state.modifiedList}
                        validator={this.validator}
                        viewMode={this.props.viewMode}
                        onChange={this.handleChange}
                        createNewElement={this.createNewElement.bind(this)}
                        editable
                        showAddButton={this.props.viewMode !== 'VIEW'}
                        showDeleteButton={this.props.viewMode !== 'VIEW'}
                        addButtonLabel='Dodaj kolejną opcję'
                        stateField={'element'}
                        dataKey='uuid'
                        showDeleteButtonFunctionLabel='Nieaktywny'
                        className='answer-area-table'
                        tableClassName='answer-options-table'
                        value={this.state.element.answerOptions}
                        rendered={
                            this.state.element.type === 'MULTI_LIST' ||
                            this.state.element.type === 'SELECT_ONE' ||
                            this.state.element.type === 'SELECT_TEMPLATE'
                        }
                        validators='array_required'
                        permanentDelete
                        addButtonVariant=''
                        addButtonClassName='text-12'
                    />
                    <InputDropdownComponent
                        id='parentTag'
                        name='parentTag'
                        label={t('parameter.parentTag')}
                        colClass='col-md-12'
                        value={this.state.element.parentTag}
                        validator={this.validator}
                        options={this.props.parentTags}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        dataKey='uuid'
                        validators='not_required'
                        optionLabel='label'
                        showClear
                        rendered={
                            this.props.parentTags?.length > 0 &&
                            this.canBeBelongingToDynamicList(this.state.element.type) &&
                            !(this.props.viewMode === 'VIEW' && !this.state.element.parentTag)
                        }
                    />
                    <InputDropdownComponent
                        id='subTemplate'
                        name='subTemplate'
                        label={t('parameter.subTemplate')}
                        colClass='col-md-12'
                        value={this.state.element.subTemplate}
                        validator={this.validator}
                        options={this.props.subTemplates}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        dataKey='id'
                        validators='required'
                        optionLabel='visibleName'
                        rendered={this.state.element.type === 'SUB_TEMPLATE'}
                    />
                    <InputNumberPropertiesComponent
                        rendered={this.state.element.type === 'NUMBER'}
                        dialogMode
                        viewMode={this.props.viewMode}
                        element={this.state.element}
                        handleChange={this.handleChange}
                        stateField={'element.numberProperties'}
                        resetNumberPropertiesWhenRomanConverted={this.resetNumberPropertiesWhenRomanConverted}
                        validator={this.validator}
                        id={this.state.element.uuid}
                    />
                    <InputMultiSelectComponent
                        id='sumElements'
                        name='sumElements'
                        colClass='col-md-12'
                        label='Składniki sumy'
                        value={this.state.element.sumElements}
                        validator={this.validator}
                        onChange={this.handleChange}
                        rendered={this.state.element.type === 'SUM'}
                        viewMode={this.props.viewMode}
                        options={this.props.sumElementsOptions}
                        dataKey='uuid'
                        validators={'required|array_required'}
                        optionLabel='field'
                        filter
                    />
                    <InputMultiSelectComponent
                        id='factors'
                        name='factors'
                        colClass='col-md-12'
                        label='Czynniki iloczynu'
                        value={this.state.element.factors}
                        validator={this.validator}
                        onChange={this.handleChange}
                        rendered={this.state.element.type === 'MULTIPLICATION'}
                        viewMode={this.props.viewMode}
                        options={this.props.factorOptions}
                        dataKey='uuid'
                        validators={'required|array_required'}
                        optionLabel='field'
                        filter
                    />
                    <InputDropdownComponent
                        id='point'
                        name='point'
                        label={t('parameter.point')}
                        colClass='col-md-12'
                        value={this.state.element.point}
                        validator={this.validator}
                        options={this.props.points}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        dataKey='id'
                        validators='required'
                        optionLabel='label'
                        rendered={this.state.element.type === 'POINT_REF'}
                    />
                </DivContainer>
            </DivContainer>
        );
    }
}

export default withTranslation()(ParametersDialog);
