import PropTypes from 'prop-types';
import React from 'react';
import InputCheckboxComponent from '../InputCheckboxComponent';
import InputNumberComponent from '../InputNumberComponent';
class InputNumberPropertiesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    showRomanTrue() {
        return this.props.element?.numberProperties?.convertToRoman === true;
    }
    showCurrencyTrue() {
        return this.props.element?.numberProperties?.showCurrency === true;
    }
    setFieldsAfterRomanConvertedTrue() {
        const numberProperties = JSON.parse(JSON.stringify(this.props.element.numberProperties));
        numberProperties.showPercent = false;
        numberProperties.showCurrency = false;
        numberProperties.decimalPlaces = 0;
        this.props.resetNumberPropertiesWhenRomanConverted(numberProperties);
    }
    setFieldsWhenCurrency() {
        const numberProperties = JSON.parse(JSON.stringify(this.props.element.numberProperties));
        numberProperties.showPercent = false;
        numberProperties.decimalPlaces = 2;
        this.props.resetNumberPropertiesWhenRomanConverted(numberProperties);
    }
    getMaxNumber(number) {
        if (number === null || number === undefined) {
            return 999999999;
        } else {
            return number;
        }
    }
    getMinNumber(number) {
        if (number === null || number === undefined) {
            return -999999999;
        } else {
            return number;
        }
    }
    render() {
        const {dialogMode} = this.props;
        if (this.props.rendered) {
            return (
                <React.Fragment>
                    <InputNumberComponent
                        id={`${this.props.id}-numberMin`}
                        name='numberMin'
                        label='Minimalna liczba'
                        colClass={dialogMode ? 'col-12' : 'col-md-4'}
                        value={this.props.element?.numberProperties?.numberMin}
                        validator={this.props.validator}
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        validators={`required|numericNegative|number_max:999999999|number_min:${-999999999}`}
                        max={this.getMaxNumber(this.props.element?.numberProperties?.numberMax)}
                        min={-999999999}
                        maxFractionDigits={this.showRomanTrue() ? 0 : this.showCurrencyTrue() ? 2 : 10}
                    />
                    <InputNumberComponent
                        id={`${this.props.id}-numberMax`}
                        name='numberMax'
                        label='Maksymalna liczba'
                        colClass={dialogMode ? 'col-12' : 'col-md-4'}
                        value={this.props.element?.numberProperties?.numberMax}
                        validator={this.props.validator}
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        validators={`required|numericNegative|number_max:999999999|number_min:${-999999999}`}
                        min={this.getMinNumber(this.props.element?.numberProperties?.numberMin)}
                        max={999999999}
                        maxFractionDigits={this.showRomanTrue() ? 0 : this.showCurrencyTrue() ? 2 : 10}
                    />
                    <InputCheckboxComponent
                        id={`${this.props.id}-showPercent`}
                        name='showPercent'
                        label={'Pokaż procent'}
                        labelOnRight
                        value={this.props.element?.numberProperties?.showPercent}
                        validators='not_required'
                        colClass={dialogMode ? 'col-12' : 'col-md-4'}
                        valueLabel='label'
                        validator={this.props.validator}
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        disabled={this.showRomanTrue() || this.showCurrencyTrue()}
                        viewLikeDisabled
                    />
                    <InputCheckboxComponent
                        id={`${this.props.id}-showDescription`}
                        name='showDescription'
                        label={'Pokaż opis słowny'}
                        labelOnRight
                        value={this.props.element?.numberProperties?.showDescription}
                        validators='not_required'
                        colClass={dialogMode ? 'col-12' : 'col-md-4'}
                        valueLabel='label'
                        validator={this.props.validator}
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        viewLikeDisabled
                    />
                    <InputCheckboxComponent
                        id={`${this.props.id}-showCurrency`}
                        name='showCurrency'
                        label={'Waluta'}
                        labelOnRight
                        value={this.props.element?.numberProperties?.showCurrency}
                        validators='not_required'
                        colClass={dialogMode ? 'col-12' : 'col-md-4'}
                        valueLabel='label'
                        validator={this.props.validator}
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        disabled={this.showRomanTrue()}
                        onAfterStateChange={() => {
                            if (this.showCurrencyTrue()) {
                                this.setFieldsWhenCurrency();
                            }
                        }}
                        viewLikeDisabled
                    />
                    <InputCheckboxComponent
                        id={`${this.props.id}-convertToRoman`}
                        name='convertToRoman'
                        label={'Konwertuj na notację rzymską'}
                        labelOnRight
                        value={this.props.element?.numberProperties?.convertToRoman}
                        validators='not_required'
                        colClass={dialogMode ? 'col-12' : 'col-md-4'}
                        valueLabel='label'
                        validator={this.props.validator}
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        onAfterStateChange={() => {
                            if (this.showRomanTrue()) {
                                this.setFieldsAfterRomanConvertedTrue();
                            }
                        }}
                        viewLikeDisabled
                    />
                    <InputNumberComponent
                        id={`${this.props.id}-decimalPlaces`}
                        name='decimalPlaces'
                        label='Liczba miejsc po przecinku'
                        colClass={dialogMode ? 'col-12' : 'col-md-4'}
                        value={this.props.element?.numberProperties?.decimalPlaces}
                        validator={this.props.validator}
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        validators='required|max:10'
                        min={0}
                        max={10}
                        disabled={this.showRomanTrue()}
                        onAfter
                    />
                </React.Fragment>
            );
        } else {
            return <React.Fragment />;
        }
    }
}

InputNumberPropertiesComponent.defaultProps = {
    rendered: true,
    dialogMode: false,
};

InputNumberPropertiesComponent.propTypes = {
    element: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    rendered: PropTypes.bool,
    stateField: PropTypes.object.isRequired,
};

export default InputNumberPropertiesComponent;
