import React from 'react';
import {withTranslation} from 'react-i18next';

function Loader() {
    return (
        <div id='cover-spin-container'>
            <i id='cover-spin' class='fa fa-spinner fa-spin fa-3x fa-fw'></i>
            <div id='cover-spin-text'>
                <p>Operacja w toku, proszę czekać...</p>
            </div>
        </div>
    );
}

export default withTranslation()(Loader);
