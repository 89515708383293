import HtmlParserUtils from './htmlParser/HtmlParserUtils';
class CopyUtils {
    static handleCopy = (e) => {
        const text_only = document.getSelection().toString();
        const clipdata = e.clipboardData || window.clipboardData;
        clipdata.setData('text/plain', text_only);
        clipdata.setData('text/html', text_only);
        e.preventDefault();
    };
    static handleCut = (event) => {
        if (event.target) {
            CopyUtils.removeClassFromNode(event.target, HtmlParserUtils.getCustomTagClassName());
            CopyUtils.removeClassFromNode(event.target, HtmlParserUtils.getCustomTagStepClassName());
            CopyUtils.removeStyleFromNode(event.target);
        }
        const selection = document.getSelection();
        const text_only = selection.toString();
        const clipdata = event.clipboardData || window.clipboardData;
        clipdata.setData('text/plain', text_only);
        clipdata.setData('text/html', text_only);

        const range = selection.getRangeAt(0); 
        range.extractContents();
        event.preventDefault();
    };
    static removeClassFromNode(node, className) {
        node.querySelectorAll(`.${className}`).forEach((el) => el.classList.remove(className));
    }
    static removeStyleFromNode(node, className) {
        node.querySelectorAll(`[style]`).forEach((el) => el.removeAttribute('style'));
    }
}
export default CopyUtils;
