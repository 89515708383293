export default class SubscriptionModel {
    constructor() {
        this.id = undefined;
        this.types = undefined;
        this.version = 0;
        this.name = '';
        this.selected = undefined;
        this.value = '';
        this.description = '';
        this.counter = 0;
        this.subscribedType = undefined;
        this.agreementTemplates = [];
        this.packageType = undefined;
        this.planType = undefined;
        this.active = false;
        this.users = [];
    }

    static assign(from, to) {
        if (from) {
            to.types = from.types;
            to.name = from.name;
            to.value = from.value;
            to.id = from.id;
            to.version = from.version;
            to.description = from.description;
            to.active = from.active;
            to.selected = from.selected;
            to.counter = from.counter;
            to.packageType = from.packageType?.enumValue;
            to.planType = from.planType?.enumValue;
            to.users = from.users;
            to.subscribedType = from.subscribedType;
            to.agreementTemplates = from.agreementTemplates;
        } else {
            to = new SubscriptionModel();
        }
    }

    static copy(from) {
        const to = new SubscriptionModel();
        SubscriptionModel.assign(from, to);
        return to;
    }
}
