import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import CategoryService from '../../services/CategoryService';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import CategoryListDialog from './CategoryListDialog';
import DialogResizer from '../../components/DialogResizer';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';

class CategoryListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new CategoryService());
        this.state = {
            name: '',
            list: [],
            loading: true,
            size: 0,
            dialogVisibile: false,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            dictionaryParameterTypeOptions: [],
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    getCleanSearchCriteria() {
        return {
            active: true,
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
            name: undefined,
        };
    }

    getDataKey() {
        return 'id';
    }
    getListTitle() {
        return 'category.listTitle';
    }

    getCriteriaName() {
        return 'category-list';
    }

    getContainerListName() {
        return 'category-list-container';
    }
    breadCrumbItems() {
        return [{name: 'Kategorie', url: '/#/category-list'}];
    }
    prepareHeaderItems() {
        const {t} = this.props;
        return this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE')
            ? [
                  {type: 'LABEL', className: 'float-left'},
                  {
                      label: t('category.addCategory'),
                      onClick: this.handleOpenNewDialog,
                      type: 'BUTTON',
                      variant: 'white',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                  },
              ]
            : [];
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name'
                    name='name'
                    label='Nazwa'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.name}
                    options={this.state.dictionaryParameterTypeOptions}
                    placeholder='Wyszukaj...'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
            </div>
        );
    }

    handleOpenNewDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            dialogVisibile: true,
            dialogViewMode: 'NEW',
        });
    }

    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState(
            {
                dialogVisibile: false,
                elementId: undefined,
            },

            () => {
                DialogResizer.removeMaskIfExistOneDialog();
                if (callback) callback();
            }
        );
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    prepareColumns() {
        return [
            {field: 'id', header: 'ID', sortable: true, width: '6em'},
            {field: 'name', header: 'Nazwa', sortable: true},
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }
    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const detailHref = `${detailUrl}/${rowData.id}`;
        return (
            <div className='float-center'>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                    onClick={this.handleGoToDetail.bind(this, detailHref)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        );
    }

    handleOpenDialog(rowData, e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            dialogVisibile: true,
            elementId: rowData.id,
            name: rowData.name,
            dialogViewMode: 'EDIT',
        });
    }
    datableClassname() {
        return 'narrow-list';
    }
    render() {
        return (
            <React.Fragment>
                <div className='container-fluid narrow-list'>
                    {this.state.dialogVisibile ? (
                        <CategoryListDialog
                            statusOptions={this.state.statusOptions}
                            elementId={this.state.elementId}
                            onHide={(e) => {
                                this.handleCloseDialog(e);
                            }}
                            visible={this.state.dialogVisibile}
                            viewMode={this.state.dialogViewMode}
                            afterSave={(e) =>
                                this.handleCloseDialog(e, () => {
                                    this.readMessage();
                                    this.refreshTable();
                                    this.unblockUi();
                                })
                            }
                            blockUi={this.blockUi}
                            unblockUi={this.unblockUi}
                            _isMounted={this._isMounted}
                            className='category-dialog'
                        />
                    ) : null}
                    <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                    {this.renderView()}
                </div>
            </React.Fragment>
        );
    }
}

CategoryListContainer.defaultProps = {
    detailUrl: '/#/category/details',
    newUrl: '/#/category/new',
    editUrl: '/#/category/edit',
    lazy: true,
};

CategoryListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(CategoryListContainer);
