import PropTypes from 'prop-types';
import React from 'react';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import StringUtils from '../utils/StringUtils';
import {isLexDocs, isMobile} from '../baseContainers/BaseContainer';

export const TemplatePointList = (props) => {
    const {items, detailUrl, criteriaTextField} = props;
    const [itemsMap, setItemsMap] = React.useState(() => []);
    // const [foundedCounts, setFoundedCounts] = React.useState([]);
    let foundedCounts = React.useRef(0);
    const [expandedElements, setExpandedElements] = React.useState([]);
    const [expandedCriteriaField, setExpandedCriteriaField] = React.useState(false);

    React.useEffect(() => {
        const map = Object.entries(items);
        setItemsMap(map);
        foundedCounts.current = 0;
        const expandedCriteriaField = criteriaTextField !== '';
        setExpandedCriteriaField(expandedCriteriaField);
    }, [props, items, expandedElements, criteriaTextField]);

    const onClick = (rowTemplateId) => {
        const filteredElements = expandedElements.filter((el) => el !== rowTemplateId);

        if (filteredElements.length === 0 && expandedElements.length === 0 && criteriaTextField !== '') {
            return;
        }
        if (filteredElements.length === expandedElements.length) {
            filteredElements.push(rowTemplateId);
        }
        setExpandedCriteriaField(false);
        setExpandedElements(filteredElements);
    };

    const renderPoint = (point) => {
        const rowTemplateId = `row-template-${point[0]}`;
        const foundedElement = expandedElements.filter((el) => el === rowTemplateId);

        let show = false;

        if (expandedCriteriaField) {
            let foundAny = false;
            point[1].forEach((element) => {
                let active = false;
                if (element.title.toLowerCase().includes(criteriaTextField.toLowerCase())) {
                    active = true;
                    show = true;
                    foundAny = true;
                    foundedCounts.current++;
                }
                element.active = active;
            });
            if (!foundAny) {
                if (expandedElements.length !== 0) {
                    setExpandedElements([]);
                }
            }
        } else {
            point[1].forEach((element) => {
                element.active = false;
            });
        }

        props.showCounts(foundedCounts.current);

        let iconUlr =
            foundedElement.length !== 0 || show
                ? isLexDocs
                    ? 'Przycisk_Góra_Duży_Granat.svg'
                    : 'Przycisk_Góra_Duży_Żółty.svg'
                : 'Przycisk_Dół_Duży_Granat.svg';

        return (
            <div key={`template-point-list-${rowTemplateId}`} className='template-point-list'>
                <div className='row'>
                    <div
                        className='template-pint-list-title col-10'
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onClick(`row-template-${point[0]}`);
                        }}
                    >
                        {point[0]}
                    </div>
                    <div className='template-print-list-btn col-2'>
                        <img
                            src={AppPrefixUtils.locationHrefUrl('/images/' + iconUlr)}
                            alt=''
                            className='cursor-pointer'
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onClick(`row-template-${point[0]}`);
                            }}
                        ></img>
                    </div>
                </div>
                {
                    <div id={rowTemplateId} className='row'>
                        {(foundedElement.length === 1 || show) &&
                            point[1].map((element) => {
                                return renderTemplate(element);
                            })}
                    </div>
                }
            </div>
        );
    };

    const renderTemplate = (template) => {
        const className = template?.active ? 'template-active template-tekst-titile' : 'template-tekst-titile';
        const showTemplate = template.active || StringUtils.isBlank(criteriaTextField);
        return (
            showTemplate && (
                <div
                    id={`template-${template.id}`}
                    className='template-point-list-name'
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        window.location.href = AppPrefixUtils.locationHrefUrl(detailUrl + template.id);
                    }}
                >
                    <div className={className}>{template.title}</div>
                </div>
            )
        );
    };

    const renderView = () => {
        return (
            <div className='container' ref={props.templatePointRef}>
                <div className='msg-template-counts'></div>
                {itemsMap?.map((element) => {
                    return renderPoint(element);
                })}
            </div>
        );
    };

    return <div className='container-fluid d-flex justify-content-center'>{renderView()}</div>;
};

TemplatePointList.defaultProps = {};

TemplatePointList.propTypes = {
    content: PropTypes.object,
};

export default React.memo(TemplatePointList);
