import BaseServiceCrud from './BaseServiceCrud';

export default class ApprovalContentService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'approval-content';
    }

    getCriteria(criteria) {
        let required = null;
        if (criteria.required != null) {
            required = criteria.required === 'Tak' ? true : false;
        }
        return this.objToQueryString({
            content: criteria.content,
            name: criteria.name,
            required: required,
            id: criteria.id,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    getContent(type) {
        return this.fetch(`${this.domain}/${this.path}/content/` + type, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
