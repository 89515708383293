import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';

export default class ReminderService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'reminder';
    }

    getCriteria(criteria) {
        return this.objToQueryString({
            text_sc: criteria.textSc,
            statuses: criteria.statuses ? criteria.statuses.map((s) => s.enumValue) : undefined,
            sent_from: criteria.sentFrom ? moment(criteria.sentFrom).format(Constants.DATE_TIME_FORMAT) : undefined,
            sent_to: criteria.sentTo ? moment(criteria.sentTo).format(Constants.DATE_TIME_FORMAT) : undefined,
            category_id: criteria.category?.id,
            subcategory_id: criteria.subcategory?.id,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }
    getReminder() {
        const url = new URL(`${this.domain}/${this.path}`);
        console.log(JSON.stringify(url));
        return fetch(url, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    markAsRead(id) {
        return this.fetch(`${this.domain}/${this.path}/read/${id}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
