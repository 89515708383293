import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';
import {device} from '../baseContainers/BaseContainer';
import UrlUtils from '../utils/UrlUtils';

export default class TemplateService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'template';
    }
    getAll() {
        return this.fetch(`${this.domain}/${this.path}/all`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getUrlList(criteria) {
        const queryString = this.getCriteria(criteria);
        const partOfUrl =
            this.auth.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE') ||
            !this.auth.loggedIn() ||
            (device.type === 'mobile' && this.auth.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE') && !UrlUtils.isPackageOffer())
                ? 'points'
                : 'list';
        return `${this.domain}/${this.path}/${partOfUrl}${queryString}`;
    }

    getList(criteria) {
        return this.fetch(this.getUrlList(criteria), {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getCriteria(criteria) {
        return this.objToQueryString({
            date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            date_to: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            text: criteria.text,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            subscriptionId: criteria.subscriptionId,
            category: criteria.category?.id,
            current: criteria.current,
            show_sub_template: criteria.showSubTemplate,
        });
    }

    changeStatusAndSendMessage(element) {
        return this.fetch(`${this.domain}/${this.path}/reject-message/`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getAcceptedSubtemplates() {
        return this.fetch(`${this.domain}/${this.path}/all-sub-templates/`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getActiveAndAcceptedSubtemplates() {
        return this.fetch(`${this.domain}/${this.path}/all-active-sub-templates/`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    activateTemplate(id) {
        return this.fetch(`${this.domain}/${this.path}/activate-template/${id}`, {
            method: 'POST',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    deactivateTemplate(id) {
        return this.fetch(`${this.domain}/${this.path}/deactivate-template/${id}`, {
            method: 'POST',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    savePreviewVersion(element) {
        return this.fetch(`${this.domain}/${this.path}/save-preview/${element.id}`, {
            method: 'PUT',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    duplicateTemplate(idToCopy) {
        return this.fetch(`${this.domain}/${this.path}/duplicate-template/${idToCopy}`, {
            method: 'POST',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    deleteTemplate(templateId) {
        return this.fetch(`${this.domain}/${this.path}/${templateId}`, {
            method: 'DELETE',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getAllForDuplication(agreementTemplateType) {
        return this.fetch(`${this.domain}/${this.path}/all-for-duplication/${agreementTemplateType}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    saveDraftVersion(element) {
        return this.fetch(`${this.domain}/${this.path}/save-draft${element.id ? `/${element.id}` : `-new`}`, {
            method: element.id ? 'PUT' : 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
