import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';

export default class AgreementService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'agreement-data';
    }

    getCriteria(criteria) {
        return this.objToQueryString({
            date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            date_to: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            text: criteria.text,
            name: criteria.name,
            client: criteria.client,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            status: criteria.status?.enumValue,
            paymentStatus: criteria.paymentStatus?.enumValue,
        });
    }

    getTemplateTags(templateId) {
        return this.fetch(`${this.domain}/${this.path}/get-tags/${templateId}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    templatePaid(agreementUuid) {
        return this.fetch(`${this.domain}/${this.path}/template-paid/${agreementUuid}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    update(element) {
        return this.fetch(`${this.domain}/${this.path}/update/${element.uuid}`, {
            method: 'PUT',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    isPaymentForTemplateRequired(request) {
        const queryString = this.objToQueryString({template_id: request.templateId, agreement_id: request.agreementId});
        return this.fetch(`${this.domain}/${this.path}/is-payment-required${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    createAndGenerate(element) {
        return this.fetch(`${this.domain}/${this.path}/create-and-generate`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    generateEmptyTemplate(templateId) {
        return this.fetch(`${this.domain}/${this.path}/generate-empty-template/${templateId}`, {
            method: 'GEt',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    updateAndGenerate(element) {
        return this.fetch(`${this.domain}/${this.path}/update-and-generate/${element.uuid}`, {
            method: 'PUT',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    startPaymentForAgreement(id, unregisteredUser) {
        return this.fetch(`${this.domain}/${this.path}/start-payment/${id}`, {
            method: 'POST',
            body: JSON.stringify(unregisteredUser),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    generatePdf(id) {
        return this.fetch(`${this.domain}/${this.path}/generate/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    sendToContractor(email, agreementId) {
        return this.fetch(`${this.domain}/${this.path}/send-to-contractor/${email}/${agreementId}`, {
            method: 'POST',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    get(uuid) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/get-by-uuid/${uuid}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    sendEmailAgreementData(element) {
        return this.fetch(`${this.domain}/${this.path}/send-email-agreement-data`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getBase64(uuid) {
        return this.fetch(`${this.domain}/${this.path}/get-base64/${uuid}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    add(element) {
        return this.fetch(`${this.domain}/${this.path}/add-agreement/`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
