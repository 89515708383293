import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import {withTranslation} from 'react-i18next';
import InputDropdownComponent from '../../../components/inputs/InputDropdownComponent';
import InputTextComponent from '../../../components/inputs/InputTextComponent';
import InternalUserService from '../../../services/InternalUserService';
import UserRoleService from '../../../services/UserRoleService';
import AddressModel from '../../../model/AddressModel';
import {Card} from 'primereact/card';
import {t} from 'i18next';
import BaseUserDetailsContainer from '../../../baseContainers/BaseUserDetailsContainer';
import InputAddressComponent from '../../../components/inputs/ePrawnik/InputAddressComponent';
import {CustomMessages} from '../../../components/CustomMessages';
import AppPrefixUtils from '../../../utils/AppPrefixUtils';

class UserContainer extends BaseUserDetailsContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.state = {
            loading: true,
            elementId: props.elementId,
            element: {},
            selectedSpecializations: [],
            showConfirmBlockDialog: false,
            showConfirmUnblockDialog: false,
            statusOptions: [],
            renderCheckbox: false,
            renderSpecializations: false,
            roleOptions: [],
        };
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    getBackLabel() {
        return 'Wróć do listy';
    }

    getUpdateSucces() {
        return 'Użytkownik został zaktualizowany';
    }

    updateElement(data) {
        this.setState(
            (prevState) => ({
                element: {
                    ...prevState.element,
                    id: data.id,
                    nip: data.nip,
                    industry: data.industry,
                    email: data.email,
                    person: data.person,
                    firstName: data?.firstName,
                    lastName: data?.lastName,
                    password: data.password,
                    address: AddressModel.copy(data.address),
                    companyName: data?.companyName,
                    role: data.role,
                    roleName: data.role.name,
                    portal: data.portal,
                    status: data.status,
                },
            }),
            () => {
                this.initAfterSetElement();
            }
        );
    }

    editButton() {
        const {viewMode} = this.props;
        return this.prepareEditButton(viewMode === 'VIEW');
    }

    initBeforeSetElement() {
        new UserRoleService()
            .getRolesByNames([t('Roles.ROLE_LEX_DOCS'), t('Roles.ROLE_LEKSY')])
            .then((roles) => {
                this.setState({
                    roleOptions: roles,
                });
            })
            .catch((err) => {
                this.showErrorMessage('error', 'Nie udało się pobrać listy ról', err.message);
            });
    }

    initAfterSetElement() {
        new UserRoleService()
            .getPermissionsByUserAndRole(this.state.element.id, this.state.element.role.id)
            .then((permissions) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        permissions: permissions,
                    },
                }));
            })
            .catch((err) => {
                this.showErrorMessage('error', 'Nie udało sie pobrać listy uprawnień', err.message);
            });
    }

    getContainerListName() {
        return 'user-list-container';
    }

    handleFormSubmit() {
        if (this.validator.allValid()) {
            this.blockUi();
            const {backUrl} = this.props;
            let {element} = this.state;

            if (element.person === 'PERSON' || this.state.element.person === 'LAWYER') {
                element.address = undefined;
            }
            this.service
                .update(element)
                .then((response) => {
                    this.persistMessage('success', '', this.getUpdateSucces(response));
                    window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                    this.unblockUi();
                })
                .catch((err) => {
                    this.showErrorMessage(err.message);
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                });
        } else {
            this.validator.showMessages();
            this.scrollToError = true;
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div className='container-fluid'>
                {this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/user/edit'>{'Edycja'}</BreadcrumbsItem> : null}
                {this.renderView()}
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
            </div>
        );
    }

    renderDetails() {
        return (
            <React.Fragment>
                {this.renderProperStructureForDetail(
                    <>
                        <InputTextComponent
                            id='firstName'
                            name='firstName'
                            label={'Imię'}
                            rendered={this.state.element.person === 'PERSON'}
                            placeholder='Wprowadź imię'
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            value={this.state.element.firstName}
                            validator={this.validator}
                            validators='required|alpha_space_dash|max:100'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        <InputTextComponent
                            id='lastName'
                            name='lastName'
                            label={'Nazwisko'}
                            placeholder='Wpisz nazwisko'
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            rendered={this.state.element.person === 'PERSON'}
                            value={this.state.element.lastName}
                            validator={this.validator}
                            validators='required|alpha_space_dash|max:100'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />

                        <InputTextComponent
                            id='email'
                            name='email'
                            label={'Email'}
                            wordBreak
                            placeholder='Wpisz email'
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            value={this.state.element.email}
                            validator={this.validator}
                            validators='required|email|max:100'
                            onChange={this.handleChange}
                            viewMode={'VIEW'}
                        />
                        <InputTextComponent
                            id='companyName'
                            name='companyName'
                            label={'Nazwa firmy'}
                            placeholder='Wpisz nazwę firmy'
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            rendered={this.state.element.person === 'COMPANY'}
                            value={this.state.element.companyName}
                            validator={this.validator}
                            validators='required|max:50'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        <InputTextComponent
                            id='industry'
                            name='industry'
                            label={'branża'}
                            placeholder='Wpisz branżę'
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            rendered={this.state.element.person === 'COMPANY'}
                            value={this.state.element.industry}
                            validator={this.validator}
                            validators='required|max:50'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        <InputTextComponent
                            id='nip'
                            name='nip'
                            label={'Numer NIP'}
                            placeholder='Wprowadź numer NIP'
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            rendered={this.state.element.person === 'COMPANY'}
                            value={this.state.element.nip}
                            validator={this.validator}
                            validators='required|nip'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />

                        <InputAddressComponent
                            element={this.state.element}
                            validator={this.validator}
                            rendered={this.state.element.person === 'COMPANY'}
                            handleChange={this.handleChange}
                            stateField={'element.address'}
                            viewMode={this.props.viewMode}
                        />

                        <InputDropdownComponent
                            id='role'
                            name='role'
                            label={'Rola'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            value={this.state.element.role}
                            validator={this.validator}
                            options={this.state.roleOptions}
                            onChange={this.handleChange}
                            viewMode={'VIEW'}
                            optionLabel='name'
                            dataKey='id'
                            validators='required'
                            valueView={this.state.element.role ? this.state.element.role.name : ''}
                            validateViewMode
                        />
                        <InputDropdownComponent
                            id='status'
                            name='status'
                            label={'Status'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            value={this.translate(`UserActivityStatus.${this.state.element.status}`)}
                            validator={this.validator}
                            options={this.state.statusOptions}
                            viewMode={'VIEW'}
                            optionLabel='label'
                            dataKey='enumValue'
                            validators='not_required'
                            rendered={this.props.viewMode}
                            valueView={this.state.element.status ? this.state.element.status.label : ''}
                        />
                    </>,
                    true
                )}
            </React.Fragment>
        );
    }
}

UserContainer.defaultProps = {
    backUrl: '/#/users-list',
    cancelUrl: '/#/user/details',
    editUrl: '/#/user/edit',
};

UserContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withTranslation()(UserContainer);
