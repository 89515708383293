import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import ActionLink from '../../components/ActionLink';
import {CriteriaDropdownComponent} from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import EnumService from '../../services/EnumService';
import EventCalendarService from '../../services/EventCalendarService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';

class ConsultationListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new EventCalendarService());
        this.enumService = new EnumService();
        this.state = {
            list: [],
            loading: true,
            selectedList: [],
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }
    getDataKey() {
        return 'id';
    }

    getListTitle() {
        return 'consultationProposal.title';
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    updateSearchCriteria(criteria) {
        return {
            status: criteria.status,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            consultant: criteria.consultant,
        };
    }
    initializeFromBackend() {
        this.enumService
            .getEnumList('ConsultationStatus', this.translate)
            .then((statusOptions) => {
                this.setState({
                    statusOptions,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
    }

    getCleanSearchCriteria() {
        return {
            status: undefined,
            firstResult: 0,
            maxResults: 10,
            sortField: 'start',
            sortAsc: false,
            consultant: '',
        };
    }

    prepareHeaderItems() {
        const {selectedList} = this.state;
        const isDisabled = selectedList.length === 0;

        return [
            {
                label: 'Zatwierdź konsultację',
                onClick: (e) => {
                    this.approveConsultations(e);
                },
                type: 'BUTTON',
                variant: 'yellow',
                disabled: isDisabled,
                iconSide: 'right',
                iconColor: 'white',
            },
        ];
    }

    approveConsultations() {
        const {selectedList} = this.state;
        const ids = selectedList
            .filter((consultation) => consultation.status === 'REQUIRES_APPROVAL')
            .map((consultation) => consultation.id);
        if (ids.length === 0) {
            this.showErrorMessage('Zaznaczone konsultacje zostały już zaakceptowane lub odrzucone');
        } else {
            this.service
                .acceptCheckedCosnultations(ids)
                .then(() => {
                    this.showSuccessMessage('Zaakceptowano wybrane konsulatacje');
                    this.refreshTable();
                })
                .catch(() => {
                    this.showErrorMessage('Nie udało się zatwierdzić wybranych konsultacji');
                });
        }
    }

    getCriteriaName() {
        return 'consultation-list-sc';
    }

    getContainerListName() {
        return 'consultation-list';
    }
    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    label={'Status'}
                    colClass='col-xl-3 col-lg-6 col-md-12 col-sm-12'
                    value={this.state.criteria.status}
                    options={this.state.statusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
                <CriteriaTextComponent
                    id='consultant-sc'
                    name='consultant'
                    label={'Konsultant'}
                    placeholder='Wyszukaj konsultanta...'
                    colClass='col-xl-4 col-lg-6 col-md-12 col-sm-12'
                    value={this.state.criteria.consultant}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
            </div>
        );
    }

    prepareColumns() {
        return [
            {field: 'id', header: 'ID', sortable: true, width: '6em'},
            {field: 'title', header: 'Konsultant'},
            {field: 'start', header: 'Data rozpoczęcia', sortable: true, body: this.dateTemplate.bind(this, 'start', 'DD-MM-YYYY HH:mm')},
            {field: 'end', header: 'Data zakończenia', sortable: true, body: this.dateTemplate.bind(this, 'end', 'DD-MM-YYYY HH:mm')},
            {field: 'status', header: 'Status', body: this.enumTemplate.bind(this, 'ConsultationStatus', 'status')},
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }
    enumTemplate(enumType, field, rowData) {
        if (this.isLexyPortal() || enumType !== 'ConsultationStatus') {
            if (rowData[field]) {
                return this.translate(`${enumType}.${rowData[field]}`);
            }
            return '';
        }
        const imageSrc = AppPrefixUtils.locationHrefUrl(`/images/lexdocs/`);
        switch (rowData[field]) {
            case 'CONFIRMED':
                return <img src={imageSrc + 'done_green.svg'} alt='' />;
            case 'REJECTED':
                return <img src={imageSrc + 'close.svg'} alt='' />;
            default:
                return <img src={imageSrc + 'hourglass.svg'} alt='' />;
        }
    }
    //override
    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <div className='float-center'>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                    onClick={this.handleGoToDetail.bind(this, href)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        );
    }

    getUpdateSucces() {
        return 'Konsultacja została zatwierdzona';
    }

    handleOpenDialog(rowData, e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, elementId: rowData.id, dialogViewMode: 'EDIT'});
    }
    handleOpenNewDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, dialogViewMode: 'NEW'});
    }
    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: false, elementId: undefined}, () => {
            if (callback) callback();
        });
    }
    breadCrumbItems() {
        return [{name: 'Propozycje terminów', url: '/#/consultation-list'}];
    }
    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/subscription-list'>{'Pakiety abonamentowe'}</BreadcrumbsItem>
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                {this.renderView()}
            </div>
        );
    }
}

ConsultationListContainer.defaultProps = {
    detailUrl: '/#/consultation/details',
    newUrl: '/#/consultation/create',
    editUrl: '/#/consultation/edit',
    lazy: true,
    disableSelection: false,
    selectionMode: 'multiple',
};

ConsultationListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(ConsultationListContainer);
