export const buttons = [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    [
        {
            name: 'plugin_blur',
            dataCommand: 'plugin_blur',
            buttonClass: 'se-btn-list se-btn-align LEFT_ALIGN se-btn-custom',
            title: 'Zamazanie',
            dataDisplay: 'command',
            innerHTML:
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M18.154 19.568A9 9 0 0 1 5.432 6.846L1.393 2.808l1.415-1.415 19.799 19.8-1.415 1.414-3.038-3.04zM6.847 8.262a7 7 0 0 0 9.891 9.89l-9.89-9.89zM20.414 16.2l-1.599-1.599a6.995 6.995 0 0 0-1.865-6.55L12 3.1 9.657 5.443 8.243 4.03 12 .272l6.364 6.364a9.002 9.002 0 0 1 2.05 9.564z"/> </g> </svg>',
        },
        {
            name: 'plugin_clear_blur',
            dataCommand: 'plugin_clear_blur se-btn-custom',
            buttonClass: 'se-btn-list',
            title: 'Wyczyść zamazanie',
            dataDisplay: 'command',
            innerHTML:
                '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16"> <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/> <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/> </svg>',
        },
    ],
    ['removeFormat'],
    '/',
    ['fontColor', 'hiliteColor'],
    ['outdent', 'indent'],
    [
        {
            name: 'plugin_align_left',
            dataCommand: 'plugin_align_left',
            buttonClass: 'se-btn-list se-btn-align LEFT_ALIGN',
            title: 'Do lewej',
            dataDisplay: 'command',
            innerHTML:
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.74 13.77"><g><path d="M4.41,4.74v2H20.15v-2H4.41Zm11.8,3.94H4.41v2H16.22v-2Zm-11.8,5.9H18.18v-2H4.41v2Zm0,3.93h9.84v-2H4.41v2Z" transform="translate(-4.41 -4.74)"></path></g></svg>',
        },
        {
            name: 'plugin_align_center',
            dataCommand: 'plugin_align_center',
            buttonClass: 'se-btn-list se-btn-align CENTER_ALIGN',
            title: 'Do środka',
            dataDisplay: 'command',
            innerHTML:
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.74 13.77"><g><path d="M4.41,4.74v2H20.15v-2H4.41Zm2,3.94v2H18.18v-2H6.37Zm-1,5.9H19.16v-2H5.39v2Zm2,3.93H17.2v-2H7.36v2Z" transform="translate(-4.41 -4.74)"></path></g></svg>',
        },
        {
            name: 'plugin_align_right',
            dataCommand: 'plugin_align_right',
            buttonClass: 'se-btn-list se-btn-align RIGHT_ALIGN',
            title: 'Do prawej',
            dataDisplay: 'command',
            innerHTML:
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.74 13.77"><g><path d="M4.41,4.74v2H20.15v-2H4.41Zm3.93,5.9H20.15v-2H8.34v2Zm-2,3.94H20.14v-2H6.37v2Zm3.94,3.93h9.84v-2H10.31v2Z" transform="translate(-4.41 -4.74)"></path></g></svg>',
        },
        {
            name: 'plugin_align_justify',
            dataCommand: 'plugin_align_justify',
            buttonClass: 'se-btn-list se-btn-align JUSTIFY_ALIGN',
            title: 'Wyjustuj',
            dataDisplay: 'command',
            innerHTML:
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.74 13.77"><g><path d="M4.41,4.74v2H20.15v-2H4.41Zm0,5.9H20.15v-2H4.41v2Zm0,3.94H20.15v-2H4.41v2Zm0,3.93h7.87v-2H4.41v2Z" transform="translate(-4.41 -4.74)"></path></g></svg>',
        },
        'list',
    ],
    ['link'],
    ['fullScreen', 'showBlocks', 'codeView'],
    ['preview'],
    ['save'],
    [
        {
            name: 'custom_plugin',
            dataCommand: 'custom_plugin',
            buttonClass: 'custom-button-class',
            title: 'Wskaż parametr, który ma być użyty',
            dataDisplay: 'submenu',
            innerHTML: '<span>Wstaw pole</span>',
        },
    ],
];
export default buttons;
