import BaseServiceCrud from './BaseServiceCrud';

export default class RecordingService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'recordings';
    }

    getAudioFromRecording(recordId) {
        const url = new URL(`${this.domain}/${this.path}/get-audio-from-recording/${recordId}`);
        const headers = {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cahce',
        };
        headers['Authorization'] = this.auth.getToken();
        const options = {method: 'GET'};

        return new Promise((resolve, reject) => {
            fetch(url, {
                headers,
                ...options,
            })
                .then((response) => {
                    response.headers.forEach(function (val, key) {
                        console.log(key + ' -> ' + val);
                    });
                    if (response.ok) {
                        return resolve(response.blob());
                    }
                    return reject(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
