import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import {t} from 'i18next';
import InternalUserService from '../../services/InternalUserService';
import CustomLabel from '../../components/CustomLabel';
import SpecializationService from '../../services/SpecializationService';
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import UserRoleService from '../../services/UserRoleService';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InputConsultantComponent from '../../components/inputs/ePrawnik/InputConsultantComponent';
import BaseUserDetailsContainer from '../../baseContainers/BaseUserDetailsContainer';
import InputPhoneComponent from '../../components/inputs/ePrawnik/InputPhoneComponent';
import InputAddressComponent from '../../components/inputs/ePrawnik/InputAddressComponent';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import {CookiesConstants} from '../../utils/CookiesConstants';

class LawOfficeContainer extends BaseUserDetailsContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.showExitConfirmationDialog = true;
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.state = {
            loading: true,
            elementId: props.params.id,
            element: {
                status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            },
            dialogVisibile: false,
            lawyerDialogVisibile: false,
            backUrl: '',
        };

        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.clearConsultantCompontents = this.clearConsultantCompontents.bind(this);
    }

    getContainerListName() {
        return 'law-office-list-container';
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('lawOffice.updateSuccessfull');
    }

    getBackLabel() {
        return 'Wróć do listy kancelarii';
    }

    clearConsultantCompontents() {
        if (!this.isConsultantSelected()) {
            this.state.element.chatFee = undefined;
            this.state.element.mailingFee = undefined;
            this.state.element.videoChatFee = undefined;
        }
    }

    prepareEditButton(rendered, label) {
        const {editUrl} = this.props;
        const {element} = this.state;
        this.saveCookie(CookiesConstants.DETAILS_VIEW_MODE, 'EDIT');
        return {
            label: label !== undefined ? label : 'Edytuj',
            type: 'BUTTON',
            variant: '',
            onClick: this.saveCookie(CookiesConstants.DETAILS_VIEW_MODE, 'EDIT'),
            href: AppPrefixUtils.locationHrefUrl(`${editUrl}/${element.id}`),
            rendered: rendered,
            // iconName: 'mdi-pencil',
            iconSide: 'left',
            iconSize: 'm',
        };
    }

    updateElement(data) {
        this.setState(
            (prevState) => ({
                element: {
                    ...prevState.element,
                    id: data.id,
                    firstName: data.firstName,
                    email: data.email,
                    lastName: data.lastName,
                    status: data.status,
                    specializations: data.specializations,
                    phoneNumber: data.phoneNumber,
                    bankAccountNumber: data.bankAccountNumber,
                    portal: data.portal,
                    person: data.person,
                    role: data.role,
                    chatFee: data.chatFee,
                    mailingFee: data.mailingFee,
                    videoChatFee: data.videoChatFee,
                },
            }),
            () => {
                this.initAfterSetElement(data);
            }
        );
    }
    initBeforeSetElement() {
        this.saveCookie(`law-office-manager-id`, this.props.elementId);

        new SpecializationService()
            .getAllSpecializations()
            .then((specializations) => {
                this.setState({specializations});
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });
    }

    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            this.clearConsultantCompontents();
            this.blockUi(this.handleValidForm);
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.scrollToError = true;
            this.forceUpdate();
        }
        this.unblockUi();
    }

    initAfterSetElement(data) {
        if (data.person === 'LAW_OFFICE') {
            this.setState((prevState) => ({
                element: {
                    ...prevState.element,
                    nip: data.nip,
                    companyName: data.companyName,
                    address: {
                        city: data?.address?.city,
                        postalCode: data?.address?.postalCode,
                        houseNumber: data?.address?.houseNumber,
                        streetName: data?.address?.streetName,
                        streetNumber: data?.address?.streetNumber,
                    },
                },
            }));
        } else {
            this.setState((prevState) => ({
                element: {
                    ...prevState.element,
                    ownedLawOffice: {
                        nip: data.ownedLawOffice?.nip,
                        name: data.ownedLawOffice?.name,
                        address: {
                            city: data?.ownedLawOffice?.address?.city,
                            postalCode: data?.ownedLawOffice.address?.postalCode,
                            houseNumber: data?.ownedLawOffice.address?.houseNumber,
                            streetName: data?.ownedLawOffice.address?.streetName,
                            streetNumber: data?.ownedLawOffice.address?.streetNumber,
                        },
                    },
                },
            }));
        }
        new UserRoleService()
            .getPermissionsByUserAndRole(data.id, data.role.id)
            .then((permissions) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        permissions: permissions,
                    },
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });
        super.initAfterSetElement();
    }

    handleOpenDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            lawyerDialogVisibile: true,
            dialogViewMode: 'NEW',
        });
    }

    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState(
            {
                lawyerDialogVisibile: false,
                elementId: undefined,
            },
            () => {
                if (callback) callback();
            }
        );
    }
    editButton() {
        const {viewMode} = this.props;
        return this.prepareEditButton(
            viewMode === 'VIEW' && this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_USER_MANAGE')
        );
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    renderSpecializations() {
        return (
            <InputMultiSelectComponent
                id='specializations'
                name='specializations'
                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 '
                label='Specjalizacje'
                value={this.state.element.specializations}
                validator={this.validator}
                onChange={this.handleChange}
                viewMode={this.props.viewMode}
                rendered={true}
                options={this.state.specializations}
                validators={'required|array_required'}
                dataKey='id'
                optionLabel='name'
                filter
                filterBy='name'
            />
        );
    }

    renderPermisssions() {
        return this.state.element.permissions
            ? this.state.element.permissions.map((i, index) => {
                  return (
                      <div class='row' style={{width: '100%'}}>
                          <InputCheckboxComponent
                              id={`${index}-permission`}
                              name='selected'
                              label={i.description}
                              labelOnRight
                              value={!!i.selected}
                              validator={this.validator}
                              validators='not_required'
                              colClass='col-md-6'
                              onChange={this.handleChange}
                              viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
                              disabled={this.props.viewMode === 'VIEW'}
                              stateField={`element.permissions[${index}]`}
                              positioning='col-md-6 form-group-checkbox-flex'
                          />
                      </div>
                  );
              })
            : null;
    }

    render() {
        return this.renderView();
    }
    isConsultantSelected() {
        let permission = this.state?.element?.permissions;
        return permission?.find((el) => el.permissionGroup === 'ROLE_LAW_OFFICE_CONSULTANT' && el.selected === true) ? true : false;
    }
    renderDetails() {
        const {element} = this.state;
        const {person} = this.state.element;
        return (
            <React.Fragment>
                {this.renderProperStructureForDetail(
                    <>
                        <div className='row'>
                            <CustomLabel label={'Zarządzający kancelarią'} colClass='col-12' />
                        </div>
                        <div className='row'>
                            <InputTextComponent
                                id='firstName'
                                name='firstName'
                                label={'Imię'}
                                placeholder='Wprowadź imię'
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                value={element.firstName}
                                validator={this.validator}
                                validators='required|alpha_space_dash|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                            <InputTextComponent
                                id='lastName'
                                name='lastName'
                                label={'Nazwisko'}
                                placeholder='Wpisz nazwisko'
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                value={element.lastName}
                                validator={this.validator}
                                validators='required|alpha_space_dash|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                            <InputTextComponent
                                id='email'
                                name='email'
                                wordBreak
                                label={'Email'}
                                placeholder='Wpisz email'
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                value={element.email}
                                validator={this.validator}
                                validators='required|email|max:100'
                                onChange={this.handleChange}
                                viewMode={'VIEW'}
                            />
                            <InputTextComponent
                                id='bankAccountNumber'
                                name='bankAccountNumber'
                                placeholder='Wpisz numer konta bankowego'
                                label='Numer konta bankowego'
                                value={this.state.element.bankAccountNumber}
                                onChange={this.handleChange}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                validators='required|numeric|min:26|max:26'
                                validator={this.validator}
                                viewMode={this.props.viewMode}
                            />

                            <InputPhoneComponent
                                id='phoneNumber'
                                name='phoneNumber'
                                label='Numer telefonu'
                                value={this.state.element.phoneNumber}
                                onChange={this.handleChange}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                validators='required|phone|max:9|min:9'
                                viewMode={this.props.viewMode}
                                validator={this.validator}
                            />

                            <InputDropdownComponent
                                id='status'
                                name='status'
                                label={'Status'}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                value={this.translate(`UserActivityStatus.${this.state.element.status}`)}
                                validator={this.validator}
                                options={this.state.statusOptions}
                                viewMode={'VIEW'}
                                optionLabel='label'
                                dataKey='enumValue'
                                validators='not_required'
                                rendered={this.props.viewMode}
                                valueView={this.state.element.status ? this.state.element.status.label : ''}
                            />

                            {this.renderSpecializations()}
                            <CustomLabel label={'Uprawnienia'} colClass='col-12' />
                            {this.renderPermisssions()}

                            <InputConsultantComponent
                                dialogMode={false}
                                element={this.state.element}
                                validator={this.validator}
                                rendered={this.isConsultantSelected()}
                                handleChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                        </div>
                    </>
                )}
                {this.renderProperStructureForDetail(
                    <>
                        {' '}
                        <div className='row'>
                            <CustomLabel label={'Dane firmowe'} colClass='col-12' />
                        </div>
                        <div className='row'>
                            <InputTextComponent
                                id='person'
                                label={'Typ kancelarii'}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                value={t(`PersonType.${person}`)}
                                validator={this.validator}
                                validators='required|alpha|max:100'
                                onChange={this.handleChange}
                                viewMode={'VIEW'}
                            />
                            {person === 'LAW_OFFICE' ? (
                                <React.Fragment>
                                    <InputTextComponent
                                        id='companyName'
                                        name='companyName'
                                        label='Nazwa kancelarii'
                                        placeholder='Wpisz nazwę kancelarii'
                                        value={element.companyName}
                                        onChange={this.handleChange}
                                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                        validators='required|max:50'
                                        validator={this.validator}
                                        viewMode={this.props.viewMode}
                                        rendered={person === 'LAW_OFFICE'}
                                    />

                                    <InputTextComponent
                                        id='nip'
                                        name='nip'
                                        label='Numer NIP'
                                        placeholder='Wprowadź numer NIP'
                                        value={element.nip}
                                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                        onChange={this.handleChange}
                                        validators={`required|nip`}
                                        validator={this.validator}
                                        viewMode={this.props.viewMode}
                                    />

                                    <InputAddressComponent
                                        element={this.state.element}
                                        validator={this.validator}
                                        rendered={true}
                                        handleChange={this.handleChange}
                                        stateField={'element.address'}
                                        viewMode={this.props.viewMode}
                                    />
                                    <InputDropdownComponent
                                        id='status'
                                        name='status'
                                        label={'Status'}
                                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                        value={this.translate(`UserActivityStatus.${this.state.element.status}`)}
                                        validator={this.validator}
                                        options={this.state.statusOptions}
                                        viewMode={'VIEW'}
                                        optionLabel='label'
                                        dataKey='enumValue'
                                        validators='not_required'
                                        rendered={this.props.viewMode}
                                        valueView={this.state.element.status ? this.state.element.status.label : ''}
                                    />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <InputTextComponent
                                        id='name'
                                        name='name'
                                        label='Nazwa kancelarii'
                                        placeholder='Wpisz nazwę kancelarii'
                                        value={element.ownedLawOffice?.name}
                                        stateField={'element.ownedLawOffice'}
                                        onChange={this.handleChange}
                                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                        validators='required|max:50'
                                        validator={this.validator}
                                        viewMode={this.props.viewMode}
                                    />

                                    <InputTextComponent
                                        id='nip'
                                        name='nip'
                                        label='NIP'
                                        placeholder='Wpisz nazwę kancelarii'
                                        value={element.ownedLawOffice?.nip}
                                        stateField={'element.ownedLawOffice'}
                                        onChange={this.handleChange}
                                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                        validators='required|max:50'
                                        validator={this.validator}
                                        viewMode={this.props.viewMode}
                                    />

                                    <InputAddressComponent
                                        element={this.state.element?.ownedLawOffice}
                                        validator={this.validator}
                                        rendered={true}
                                        handleChange={this.handleChange}
                                        stateField={'element.ownedLawOffice.address'}
                                        viewMode={this.props.viewMode}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </>
                )}
            </React.Fragment>
        );
    }
}

LawOfficeContainer.defaultProps = {
    backUrl: '/#/law-office-list',
    editUrl: '/#/law-office/edit',
    detailUrl: '/#/law-office/details',

    cancelUrl: '/#/law-office/details',
};

LawOfficeContainer.propTypes = {
    backUrl: PropTypes.string,
    editUrl: PropTypes.string,
    detailUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
};

export default withTranslation()(LawOfficeContainer);
