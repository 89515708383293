import $ from 'jquery';

class CreatedCopyHighlightUtils {
    static highlightGivenRow(rowId) {
        const rowToHighlight = $(`.p-selectable-row td:contains(${`${rowId}`})`).parent();
        if (rowToHighlight) {
            rowToHighlight.addClass('p-highlight p-selectable-row');
            rowToHighlight.trigger('focus');
        }
    }
}
export default CreatedCopyHighlightUtils;
