import BaseServiceCrud from './BaseServiceCrud';


export default class LawOfficeService extends BaseServiceCrud {
	constructor() {
		super();
		this.path = 'law-office';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			id: criteria.id,
			first_result: criteria.firstResult,
			max_results: criteria.maxResults,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
			name: criteria.name,
		});
	}

	
	getLawOfficeByUserId(id) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/law-office-by-user-id/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
	

}
