import {Column} from 'primereact/column';
import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import {withTranslation} from 'react-i18next';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import CustomDataTable from '../components/CustomDataTable';
import CustomLabel from '../components/CustomLabel';
import DivContainer from '../components/DivContainer';
import InputCalendarComponent from '../components/inputs/InputCalendarComponent';
import InputTextareaComponent from '../components/inputs/InputTextareaComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import EventLogService from '../services/EventLogService';
import StringUtils from '../utils/StringUtils';
import BaseBreadCrumb from '../baseContainers/BaseBreadCrumb';
class EventLogContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new EventLogService());
        this.state = {
            loading: true,
            elementId: props.elementId,
            element: {
                changeLog: [],
                date: new Date(),
                details: undefined,
                eventType: undefined,
                logType: undefined,
                login: undefined,
                referenceId: undefined,
                referenceDescription: undefined,
                userEmail: undefined,
            },
        };
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    updateElement(data) {
        this.setState(
            {
                element: {
                    id: data.id,
                    changeLog: data.changeLog,
                    changes: data.changes,
                    date: new Date(data.date),
                    details: data.details,
                    eventType: data.eventType,
                    logType: data.logType,
                    userEmail: data.userEmail,
                    referenceId: data.referenceId,
                    referenceDescription: data.referenceDescription,
                },
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    prepareFooterItems() {
        return [];
    }

    prepareHeaderItems() {
        return [];
    }

    getBackLabel() {
        return 'Wróć do listy';
    }

    render() {
        return (
            <div className='container-fluid'>
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                {this.renderView()}
            </div>
        );
    }
    operationLabel(value) {
        if (value) {
            switch (value) {
                case 'ADD':
                    return ' [Dodano]';
                case 'EDIT':
                    return ' [Zmieniono]';
                case 'DELETE':
                    return ' [Usunięto]';
                default:
                    return value;
            }
        } else {
            return '';
        }
    }
    changeValueTemplate(field, rowData) {
        if (rowData && rowData[field]) {
            const value = rowData[field];
            if (value) {
                return (
                    <div>
                        {value.map((c) => {
                            if (c.values && Object.keys(c.values).length > 0) {
                                return (
                                    <div>
                                        {Object.keys(c.values).map((f) => {
                                            return (
                                                <div className='col-12'>
                                                    {f} : {c.values[f]} {this.operationLabel(c.operation)}
                                                </div>
                                            );
                                        })}
                                        <div className='col-12'>&nbsp;</div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className='col-12'>
                                        {c.value ? c.value : '<brak>'} {this.operationLabel(c.operation)}
                                    </div>
                                );
                            }
                        })}
                    </div>
                );
            } else {
                return '';
            }
        } else {
            return '';
        }
    }
    breadCrumbItems() {
        return [
            {name: 'Dziennik zdarzeń', url: '/#/event-log-list'},
            {name: 'Zdarzenie', url: `/#/event-log/details/${this.state.element?.id}`},
        ];
    }
    renderDetails() {
        const showChanges = this.state.element.changes !== undefined && this.state.element.changes.length > 0;
        const columns = [
            {field: 'fieldName', header: 'Nazwa pola', sortable: false},
            {field: 'oldValue', header: 'Stara wartość', sortable: false},
            {field: 'newValue', header: 'Nowa wartość', sortable: false},
        ];
        let changeLogColumns = [];
        columns.forEach((col) => {
            const column = (
                <Column
                    key={col.key ? col.key : col.field}
                    field={col.field}
                    header={col.header}
                    body={col.body}
                    className={col.className}
                    sortable={col.sortable}
                    style={col.width !== undefined ? {width: col.width} : null}
                />
            );
            changeLogColumns.push(column);
        });
        return (
            <React.Fragment>
                {this.renderProperStructureForDetail(
                    <>
                        <InputTextComponent
                            id='logType'
                            name='logType'
                            label={'Typ logu'}
                            colClass='col-md-4'
                            value={this.translate(`logType.${this.state.element.logType}`)}
                            // value={this.state.element.logType}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        <InputTextComponent
                            id='eventType'
                            name='eventType'
                            label={'Typ zdarzenia'}
                            colClass='col-md-4'
                            value={this.translate(`EventType.${this.state.element.eventType}`)}
                            // value={this.state.element.eventType}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        <InputCalendarComponent
                            id='date'
                            name='date'
                            label={'Data'}
                            dateFormatMoment='YYYY-MM-DD HH:mm:ss'
                            colClass='col-md-4'
                            value={this.state.element.date}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        <InputTextareaComponent
                            id='referenceDescription'
                            name='referenceDescription'
                            label={'Szczegóły'}
                            colClass='col-md-4'
                            value={this.state.element.referenceDescription}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        <InputTextComponent
                            id='userEmail'
                            name='userEmail'
                            label={'Login'}
                            colClass='col-md-4'
                            value={this.state.element?.userEmail}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        <InputTextareaComponent
                            id='details'
                            name='details'
                            label={'Opis'}
                            colClass='col-12'
                            value={this.state.element.details}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                            render={!StringUtils.isBlank(this.state.element.details)}
                        />
                        <CustomLabel colClass='col-md-12' label='Lista zmian' rendered={showChanges} />
                        <DivContainer rendered={showChanges}>
                            <CustomDataTable
                                value={this.state.element.changes ? this.state.element.changes : null}
                                emptyMessage='Brak rekordów do wyświetlenia'
                                responsive
                            >
                                <Column field='fieldName' header='Nazwa pola' />
                                <Column header='Poprzednia wartość' body={this.changeValueTemplate.bind(this, 'oldValue')} />
                                <Column header='Nowa wartość' body={this.changeValueTemplate.bind(this, 'newValue')} />
                            </CustomDataTable>
                        </DivContainer>
                    </>
                )}
            </React.Fragment>
        );
    }
}

EventLogContainer.defaultProps = {
    backUrl: '/#/event-log-list',
    cancelUrl: '/#/event-log/details',
};

EventLogContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
};

export default withTranslation()(EventLogContainer);
