import PropTypes from 'prop-types';
import React from 'react';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import InternalUserService from '../../services/InternalUserService';
import UserRoleService from '../../services/UserRoleService';
import {withTranslation} from 'react-i18next';
import UserDialog from './UserDialog';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import DialogResizer from '../../components/DialogResizer';

class UserListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.roleService = new UserRoleService();
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            roleOptions: [],
        };
        this.handleOpenNewUserDialog = this.handleOpenNewUserDialog.bind(this);
        this.handleOpenNewOfficeDialog = this.handleOpenNewOfficeDialog.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
        this.handleCloseUserDialog = this.handleCloseUserDialog.bind(this);
        this.handleCloseOfficeDialog = this.handleCloseOfficeDialog.bind(this);
    }
    getDataKey() {
        return 'id';
    }

    getListTitle() {
        return 'user.listTitle';
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    updateSearchCriteria(criteria) {
        return {
            text: criteria.text,
            role: criteria.role,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            roles: criteria.roles,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            text: '',
            role: undefined,
            id: null,
            firstResult: 0,
            roles: ['Leksy', 'LexDocS'],
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    initializeFromBackend() {
        this.roleService
            .getRoles()
            .then((roles) => {
                let rolesClient = roles.filter((role) => role.name === 'Leksy' || role.name === 'LexDocS');
                this.setState({
                    roleOptions: rolesClient,
                    roleUser: rolesClient,
                });
            })
            .catch((err) => {
                this.messages.show({
                    severity: 'error',
                    summary: 'Błąd',
                    detail: err.message,
                });
            });
    }

    getCriteriaName() {
        return 'user-list-sc';
    }

    getContainerListName() {
        return 'user-list-container';
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='text-sc'
                    name='text'
                    label={'Email, imię, nazwisko...'}
                    placeholder={'Email, imię, nazwisko'}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.text}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
                <CriteriaDropdownComponent
                    id='roleType-sc'
                    name='role'
                    showClear
                    label={'Rola'}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.role}
                    options={this.state.roleOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='name'
                />
            </div>
        );
    }

    prepareColumns() {
        return [
            {field: 'email', header: 'Email', sortable: true},
            {field: 'firstName', header: 'Imię', sortable: true},
            {field: 'lastName', header: 'Nazwisko', sortable: true},
            {field: 'role.name', header: 'Rola', sortable: true},
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }
    breadCrumbItems() {
        return [{name: 'Użytkownicy', url: '/#/users-list'}];
    }
    prepareHeaderItems() {
        const {t} = this.props;
        return this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE')
            ? [
                  {
                      label: t('user.addButton'),
                      onClick: this.handleOpenNewUserDialog,
                      type: 'BUTTON',
                      className: 'float-left',
                      variant: 'white',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                  },
              ]
            : [];
    }

    handleRedirect(rowData) {
        window.location.href = AppPrefixUtils.locationHrefUrl(this.props.history.replace('/forgot-password'));
    }

    actionTemplate(rowData) {
        const {detailsUrl} = this.props;
        const href = `${detailsUrl}/${rowData?.id}`;
        return (
            <div className='float-center'>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                    onClick={this.handleGoToDetail.bind(this, href)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        );
    }

    handleOpenNewOfficeDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogOfficeVisibile: true, dialogViewMode: 'NEW'});
    }
    handleCloseOfficeDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogOfficeVisibile: false, elementId: undefined}, () => {
            if (callback) callback();
        });
    }

    handleOpenNewUserDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, dialogViewMode: 'NEW'});
    }

    handleCloseUserDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState(
            {dialogVisibile: false, elementId: undefined},
            () => {
                if (callback) callback();
            },
            () => {
                DialogResizer.removeMaskIfExistOneDialog();
            }
        );
    }

    render() {
        return (
            <div className='container-fluid'>
                {this.state.dialogVisibile ? (
                    <UserDialog
                        className='new-user-dialog'
                        roleOptions={this.state.roleUser}
                        elementId={this.state.elementId}
                        onHide={(e) => this.handleCloseUserDialog(e)}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}
                        afterSave={(e) =>
                            this.handleCloseUserDialog(e, () => {
                                this.readMessage();
                                this.refreshTable();
                                this.unblockUi();
                            })
                        }
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                {this.renderView()}
            </div>
        );
    }
}

UserListContainer.defaultProps = {
    editUrl: '/#/user/edit',
    newUrl: '/#/user/create',
    detailsUrl: '/#/user/details',
    lazy: true,
};

UserListContainer.propTypes = {
    editUrl: PropTypes.string,
    detailsUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(UserListContainer);
