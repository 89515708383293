import $ from 'jquery';
import StringUtils from './StringUtils';
class TooltipUtils {
    static registerTooltipFunction(attributeName) {
        $(function () {
            $('body').on('click', `[${attributeName}]`, function (e) {
                var pageHeigth = $('div.sun-editor').height();
                var halfPageHeight = pageHeigth / 2;
                var position = $(this).position().top;
                let heightPosition = '';
                if (position >= halfPageHeight) {
                    heightPosition = 'tooltipTop';
                } else {
                    heightPosition = 'tooltipBottom';
                }

                var pageWidth = $('div.sun-editor').width();
                var halfPageWidth = pageWidth / 2;
                var positionWidth = $(this).position().left;
                let widthPosition = '';
                if (positionWidth >= halfPageWidth) {
                    widthPosition = 'tooltipRight';
                } else {
                    widthPosition = 'tooltipLeft';
                }
                if (e?.target && !StringUtils.isBlank(e.target.id)) {
                    $(this).append(
                        $(
                            `<span class='${TooltipUtils.helpTooltipClassName()} ${heightPosition} ${widthPosition}'>${$(this).attr(
                                attributeName
                            )}<span class="close-icon">&times;<span/></span>`
                        )
                    );
                }
            });
            $('body').on('click', function (e) {
                if (!$(e.target).hasClass('HELP')) {
                    document.querySelectorAll(`.${TooltipUtils.helpTooltipClassName()}`).forEach((el) => el.remove());
                }
            });

            $('body').on('click', `.close-icon`, function (e) {
                document.querySelectorAll(`.${TooltipUtils.helpTooltipClassName()}`).forEach((el) => el.remove());
                e.stopPropagation();
            });
        });
    }
    static helpTooltipClassName() {
        return 'help-tooltip';
    }
}
export default TooltipUtils;
