import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'primereact/dropdown';
import {withTranslation} from 'react-i18next';
import {FilterMatchMode} from 'primereact/api';

export const CriteriaDropdownComponent = (props) => {
    const {
        appendTo,
        colClass,
        dataKey,
        disabled,
        filter,
        filterBy,
        id,
        itemTemplate,
        label,
        name,
        onChange,
        onAfterStateChange,
        optionLabel,
        optionValue,
        filterOptionValue,
        options,
        placeholder,
        showClear,
        validator,
        validators,
        value,
        valueTemplate,
        refreshFromBackend,
    } = props;
    const required = validators !== undefined && validators.includes('required') && !validators.includes('not_required');
    return (
        <div className={colClass}>
            <div className='row'>
                <div className='col-md-12 form-group'>
                    {label !== undefined ? (
                        // eslint-disable-next-line react/jsx-max-props-per-line
                        <label id={`${id}-label-id`} className='p-label' htmlFor={`${id}-input`} style={{width: '100%'}}>
                            {label}
                        </label>
                    ) : null}
                    <Dropdown
                        appendTo={appendTo}
                        aria-describedby={`${id}-error`}
                        aria-label={label}
                        aria-labelledby={label === undefined ? `${id}-label-id` : undefined}
                        key={id}
                        id={id}
                        itemTemplate={itemTemplate}
                        inputId={`${id}-input`}
                        name={name}
                        style={{width: '100%'}}
                        value={value}
                        options={options}
                        optionLabel={optionLabel}
                        dataKey={dataKey}
                        onChange={(e) =>
                            onChange
                                ? onChange(
                                      'DROPDOWN',
                                      [optionValue, filterOptionValue, optionLabel],
                                      e,
                                      FilterMatchMode.EQUALS,
                                      refreshFromBackend,
                                      onAfterStateChange
                                  )
                                : null
                        }
                        placeholder={placeholder}
                        filter={filter}
                        filterBy={filterBy}
                        showClear={showClear}
                        disabled={disabled}
                        required={required}
                        valueTemplate={valueTemplate}
                    />
                    <div aria-live='assertive'>{validator ? validator.message(id, label, value, validators) : null}</div>
                </div>
            </div>
        </div>
    );
};

CriteriaDropdownComponent.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    disabled: false,
    filter: false,
    filterBy: 'label',
    placeholder: 'Wybierz',
    showClear: false,
    validators: 'not_required',
    refreshFromBackend: true,
    filterOptionValue: 'enumValue',
};

CriteriaDropdownComponent.propTypes = {
    appendTo: PropTypes.any,
    colClass: PropTypes.string,
    dataKey: PropTypes.string,
    disabled: PropTypes.bool,
    filter: PropTypes.bool,
    filterBy: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onAfterStateChange: PropTypes.func,
    optionLabel: PropTypes.string,
    optionValue: PropTypes.string,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    showClear: PropTypes.bool,
    validator: PropTypes.object.isRequired,
    validators: PropTypes.string,
    value: PropTypes.any,
    refreshFromBackend: PropTypes.bool,
};

export default withTranslation()(CriteriaDropdownComponent);
