import PropTypes from 'prop-types';
import React from 'react';
import InputTextComponent from '../InputTextComponent';

class InputAddressComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.props.rendered) {
            return (
                <React.Fragment>
                    <InputTextComponent
                        id='postalCode'
                        name='postalCode'
                        label={'Kod pocztowy'}
                        placeholder={'Wpisz kod pocztowy'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                        value={this.props.element?.address?.postalCode}
                        validator={this.props.validator}
                        validators='required|zipcode'
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='city'
                        name='city'
                        label={'Miejscowość'}
                        placeholder={'Wpisz miejscowość'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                        value={this.props.element?.address?.city}
                        validator={this.props.validator}
                        validators='required|alpha_space_dash|max:50'
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='streetName'
                        name='streetName'
                        label={'Ulica'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                        placeholder={'Wpisz ulicę'}
                        value={this.props.element?.address?.streetName}
                        validator={this.props.validator}
                        validators='required|max:50'
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='streetNumber'
                        name='streetNumber'
                        label={'Numer ulicy'}
                        placeholder={'Wpisz numer ulicy'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                        value={this.props.element?.address?.streetNumber}
                        validator={this.props.validator}
                        validators='required|max:5'
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='houseNumber'
                        name='houseNumber'
                        label={'Numer lokalu'}
                        placeholder={'Wpisz numer lokalu'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                        value={this.props.element?.address?.houseNumber}
                        validators='required|max:5'
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                    />
                </React.Fragment>
            );
        } else {
            return <React.Fragment />;
        }
    }
}

InputAddressComponent.defaultProps = {
    rendered: true,
};

InputAddressComponent.propTypes = {
    element: PropTypes.object.isRequired,
    rendered: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    stateField: PropTypes.object.isRequired,
};

export default InputAddressComponent;
