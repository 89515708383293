import AgreementService from '../AgreementService';

export default class AgreementPublicService extends AgreementService {
    constructor() {
        super();
        this.path = 'public/agreement-data';
    }

    update(element) {
        return this.fetch(`${this.domain}/${this.path}/update-by-contractor/${element.id}`, {
            method: 'PUT',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    add(element) {
        return this.fetch(`${this.domain}/${this.path}/addByUnregistered/`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getByUuidForContractor(uuid) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/get-for-contractor/${uuid}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
