import {t} from 'i18next';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {Card} from 'primereact/card';
import React from 'react';
import Split from 'react-split';
import {withTranslation} from 'react-i18next';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import plugins from 'suneditor/src/plugins';
import {UAParser} from 'ua-parser-js';
import {v4 as uuidv4} from 'uuid';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import {CustomMessages} from '../../components/CustomMessages';
import EditableDataTableExt from '../../components/EditableDataTableExt';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import buttons from '../../components/textEditorButtons';
import CurrencyUtils from '../../components/utils/CurrencyUtils';
import BlockUi from '../../components/waitPanel/BlockUi';
import YesNoDialog from '../../components/YesNoDialog';
import AgreementTagModel from '../../model/AgreementTagModel';
import AuthService from '../../services/AuthService';
import CategoryService from '../../services/CategoryService';
import EnumService from '../../services/EnumService';
import HierarchicalDictionaryService from '../../services/HierarchicalDictionaryService';
import SettingService from '../../services/SettingService';
import SpecializationService from '../../services/SpecializationService';
import TemplateService from '../../services/TemplateService';
import AgreementTagHighlightUtils from '../../utils/AgreenentTagHighlightUtils';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import {CookiesConstants} from '../../utils/CookiesConstants';
import CopyUtils from '../../utils/CopyUtils';
import HtmlParserUtils from '../../utils/htmlParser/HtmlParserUtils';
import SunEditorParentWidthCounter from '../../utils/SunEditorParentWidthCounter';
import TemplateUtils from '../../utils/TemplateUtils';
import TooltipUtils from '../../utils/TooltipUtils';
import pluginSubmenu from './../customPlugin';
import ParameterDialog from './../ParameterDialog';
import pluginBlur from './blurPlugin';
import pluginClearBlur from './clearBlurPlugin';
import pluginAlignCenter from './customTextAlignCenter';
import pluginAlignJustify from './customTextAlignJustify';
import pluginAlignLeft from './customTextAlignLeft';
import pluginAlignRight from './customTextAlignRight';
import TemplateDialog from './TemplateDialog';
import DialogResizer from '../../components/DialogResizer';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';
import {AgreementConfiguratorTemplate} from '../../components/AgreementConfiguratorTemplate';

const editLabel = 'Edytuj';

class TemplateContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new TemplateService());
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.showExitConfirmationDialog = true;
        this.editor = React.createRef();
        this.accordionRef = React.createRef();
        this.templateRef = React.createRef();
        this.enumService = new EnumService();
        this.categoryService = new CategoryService();
        this.authService = new AuthService();
        this.specializationService = new SpecializationService();
        this.hierarchicalDictionaryService = new HierarchicalDictionaryService();
        this.settingsService = new SettingService();
        const isSubtemplate = props?.params?.subtemplate === 'subtemplate';
        this.isSubtemplate = !!isSubtemplate;
        const {device} = UAParser(navigator.userAgent);
        this.device = device;
        this.device.templateView = true;
        this.state = {
            elementId: props.elementId,
            element: {tags: [], text: '', agreementTemplateType: !!isSubtemplate ? 'PARTIAL_TEMPLATE' : 'TEMPLATE'},
            dialogVisibile: false,
            showVerticalModeBtn: true,
            addParameterActionProccessed: false,
            showToolBar: false,
            rejectDialogVisible: false,
            verticalMode: false,
            redirectLink: undefined,
            deleteTemplateDialogVisible: false,
            categories: [],
            activeAccordionIndex: [0],
            activeAccordionIndexSplit: [0],
            availabilities: [
                {
                    id: 1,
                    checked: undefined,
                    label: 'Leksy',
                    enumValue: 'LEKSY',
                },
                {
                    id: 2,
                    label: 'LexDocS',
                    checked: undefined,
                    enumValue: 'LEX_DOCS',
                },
            ],
            maxPaymentAmount: 15000,
            specializations: [],
            subTemplates: [],
            invalidOperationDialog: false,
            showExitEditTemplateConfirmation: false,
            removingWarningDialog: false,
            showPrevalidationDialog: false,
            afterPrevalidationFunction: () => {},
            removingDialogFunction: () => {},
            isAssignedToDynamicList: false,
            viewMode: this.props.viewMode,
            availableWidth: SunEditorParentWidthCounter.countWidth(true, this.cardMargins(), this.device),
        };
        let parameters = [
            {enumValue: 'TEXT', label: props.t('ParameterType.TEXT')},
            {enumValue: 'DATE', label: props.t('ParameterType.DATE')},
            {enumValue: 'DICTIONARY', label: props.t('ParameterType.DICTIONARY')},
            {enumValue: 'CHECKBOX', label: props.t('ParameterType.CHECKBOX')},
            {enumValue: 'SELECT_ONE', label: props.t('ParameterType.SELECT_ONE')},
            {enumValue: 'MULTI_LIST', label: props.t('ParameterType.MULTI_LIST')},
            {enumValue: 'DYNAMIC_LIST', label: props.t('ParameterType.DYNAMIC_LIST')},
            {enumValue: 'SUB_TEMPLATE', label: props.t('ParameterType.SUB_TEMPLATE')},
            {enumValue: 'NUMBER', label: props.t('ParameterType.NUMBER')},
            {enumValue: 'SUM', label: props.t('ParameterType.SUM')},
            {enumValue: 'HELP', label: props.t('ParameterType.HELP')},
            {enumValue: 'MULTIPLICATION', label: props.t('ParameterType.MULTIPLICATION')},
            {enumValue: 'FAKE_BLURRED_TEXT', label: props.t('ParameterType.FAKE_BLURRED_TEXT')},
        ];
        this.parameterTypes = !!isSubtemplate
            ? parameters
            : [
                  {enumValue: 'STEP', label: props.t('ParameterType.STEP')},
                  {enumValue: 'POINT', label: props.t('ParameterType.POINT')},
                  {enumValue: 'POINT_REF', label: props.t('ParameterType.POINT_REF')},
                  {enumValue: 'SELECT_TEMPLATE', label: props.t('ParameterType.SELECT_TEMPLATE')},
              ].concat(parameters);
        this.updateText = this.updateTextAfterInsertingCustomTag.bind(this);
        this.getSunEditorInstance = this.getSunEditorInstance.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.windowResizer = this.windowResizer.bind(this);
        this.forceHideTools = this.forceHideTools.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.handleAddParameter = this.handleAddParameter.bind(this);
        this.hasUserEditorPermission = this.hasUserEditorPermission.bind(this);
        this.showParameterDialog = this.showParameterDialog.bind(this);
        this.showParameterDetails = this.showParameterDetails.bind(this);
        this.onAfterRemoveElement = this.onAfterRemoveElement.bind(this);
        this.calculatedAmountBodyTemplate = this.calculatedAmountBodyTemplate.bind(this);
        this.openRejectDialog = this.openRejectDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.customOnKeyDown = this.customOnKeyDown.bind(this);
        this.openInvalidOperationDialog = this.openInvalidOperationDialog.bind(this);
        this.closeInvalidOperationDialog = this.closeInvalidOperationDialog.bind(this);
        this.closePrevalidationDialog = this.closePrevalidationDialog.bind(this);
        this.handleBeforeRemoveElement = this.handleBeforeRemoveElement.bind(this);
        this.showRemovingWarningDialog = this.showRemovingWarningDialog.bind(this);
        this.hideRemovingWarningDialog = this.hideRemovingWarningDialog.bind(this);
        this.removeAndHideDialog = this.removeAndHideDialog.bind(this);
        this.handlePreview = this.handlePreview.bind(this);
        this.generatePreview = this.generatePreview.bind(this);
        this.showDeleteConfirmationDialog = this.showDeleteConfirmationDialog.bind(this);
        this.closeDeleteConfirmationDialog = this.closeDeleteConfirmationDialog.bind(this);
        this.availabilityChanged = this.availabilityChanged.bind(this);
        this.saveDraft = this.saveDraft.bind(this);
    }
    initAfterSetElement() {
        const {deviceType} = this.device;

        if (this.hasUserEditorPermission()) {
            this.setState({
                viewMode: this.props.viewMode === 'EDIT' ? 'VIEW' : this.props.viewMode,
            });
        } else {
            this.setActiveViewMode(this.state.element.active);
        }
        if (this.accordionRef?.current) {
            this.accordionRef.current.props.onTabChange();
        }
        this.setState({flag: true}, () => this.unblockUi());
        if (deviceType) {
            if (deviceType === 'mobile' || deviceType === 'tablet') {
                return;
            }
        }
        if (window.innerWidth <= 1000) {
            return;
        }
    }
    cardMargins() {
        return 25;
    }

    componentDidUpdate(prevProps, prevState) {
        let {showVerticalModeBtn} = this.state;
        const calculatedWidth = SunEditorParentWidthCounter.countWidth(true, this.cardMargins(), this.device);
        const availableWidth = prevState.availableWidth;
        if (calculatedWidth < 1189 && showVerticalModeBtn === true) {
            this.setState({showVerticalModeBtn: false, verticalMode: false});
        } else if (calculatedWidth >= 1189 && showVerticalModeBtn === false) {
            this.setState({showVerticalModeBtn: true});
        }
        if (!availableWidth) {
            return;
        }
        if (calculatedWidth === availableWidth) {
            return;
        }
        if (availableWidth !== calculatedWidth && calculatedWidth !== this.state.availableWidth) {
            this.setState({
                availableWidth: calculatedWidth,
            });
        }
    }
    componentDidMount() {
        window.templateRef = this.editor;

        TooltipUtils.registerTooltipFunction(HtmlParserUtils.getTooltipAttributeForTemplate());
        AgreementTagHighlightUtils.registerHighlightFunction();
        SunEditorParentWidthCounter.countWidthWhenResize(
            (calculatedWidth) => {
                if (calculatedWidth !== this.state.availableWidth) {
                    this.setState({
                        availableWidth: calculatedWidth,
                    });
                }
            },
            true,
            this.cardMargins(),
            this.device
        );
        super.componentDidMount();
    }

    windowResizer() {
        const calculatedWidth = SunEditorParentWidthCounter.countWidth(true, this.cardMargins(), this.device);
        if (calculatedWidth !== this.state.availableWidth) {
            this.setState({
                availableWidth: calculatedWidth,
            });
        }
    }

    setActiveViewMode(active) {
        const viewModeIfNotNew = active && this.props.viewMode === 'EDIT' ? 'VIEW' : active ? this.props.viewMode : 'VIEW';
        this.setState({
            viewMode: this.props.viewMode === 'NEW' ? 'NEW' : viewModeIfNotNew,
        });
    }

    viewMode() {
        if (this.hasUserAdminPackagePermission()) {
            if (this.authService.isUserInRole('ROLE_ADMIN_TEMPLATE_MANAGE') || this.authService.isUserInRole('ROLE_ADMIN_SUPER_MANAGE')) {
                return this.state.viewMode;
            } else {
                return 'VIEW';
            }
        } else return this.state.viewMode;
    }

    getAgreementTemplateTranslatedType() {
        return t(`AgreementTemplateType.${this.state.element.agreementTemplateType}`);
    }

    getAddSucces() {
        return `${this.getAgreementTemplateTranslatedType()} został utworzony`;
    }

    getUpdateSucces() {
        return this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TEMPLATE_MANAGE', 'ROLE_ADMIN_PACKAGE_MANAGE')
            ? `${this.getAgreementTemplateTranslatedType()} został zaktualizowany`
            : 'Propozycja została wysłana';
    }

    getRemoveSucces() {
        return `${this.getAgreementTemplateTranslatedType()} został usunięty`;
    }
    breadCrumbItems() {
        return [
            {name: 'Szablony', url: '/#/configurator-template-list'},
            {name: this.getAgreementTemplateTranslatedType(), url: `/#/template/edit/${this.state.element?.id}`},
        ];
    }

    updateElement(data) {
        this.availabilityChanged(data.availability, true);

        this.setState(
            {
                element: {
                    id: data.id,
                    originalTemplateId: data.originalTemplateId,
                    version: data.version,
                    createDate: data.createDate,
                    description: data.description,
                    category: data.category,
                    specialization: data.specialization,
                    price: data.price,
                    name: data.name,
                    status: data.status,
                    active: data.active,
                    tags: data.tags,
                    author: data.author,
                    availability: data.availability,
                    text: data.text,
                    numberOfGeneratedDocuments: data.numberOfGeneratedDocuments,
                    agreementTemplateType: data.agreementTemplateType,
                },
                email: data.createLogin,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    getContainerListName() {
        return 'configurator-template-list-container';
    }
    handleTextChange(content) {
        this.handleChange('TEXT', undefined, {target: {value: content, name: 'text'}}, () => {}, 'element');
    }
    onAfterRemoveElement(deletedRowData) {
        if (deletedRowData) {
            HtmlParserUtils.removeCustomClassWithTooltipByUUID(deletedRowData.uuid);
            this.setState(
                (prevState) => ({
                    flag: !this.state.flag,
                }),
                () => {
                    const text = this.editor.current.getContents();
                    this.setState(
                        (prevState) => ({
                            flag: !this.state.flag,
                            element: {
                                ...prevState.element,
                                text,
                            },
                        }),
                        () => {
                            this.unblockUi();
                        }
                    );
                }
            );
        }
    }

    onCancel() {
        this.setState({dialogVisibile: false}, () => DialogResizer.removeMaskIfExistOneDialog());
    }

    onAfterParameterEdit() {
        const currentFlag = this.state.flag;
        this.setState(
            {
                flag: !currentFlag,
                dialogVisibile: false,
            },
            () => DialogResizer.removeMaskIfExistOneDialog()
        );
    }

    hasUserEditorPermission() {
        return this.authService.isUserInAnyRole('ROLE_LAW_OFFICE_EDITOR_MANAGE');
    }
    hasUserAdminPackagePermission() {
        return this.authService.isUserInRole('ROLE_ADMIN_PACKAGE_MANAGE');
    }

    prepareHeaderItems() {
        const headerItems = [
            {
                label: this.state.element.active === false ? 'Aktywuj' : 'Dezaktywuj',
                onClick: (e) => {
                    e.preventDefault();
                    this.changeActiveState(!this.state.element.active);
                    this.removeCookie(CookiesConstants.DETAILS_VIEW_MODE);
                },
                rendered: this.renderActivationButton() && this.state.viewMode !== 'EDIT',
                type: 'BUTTON',
                className: `${this.state.element.active === false ? 'cancel-button gray' : ''}`,
                variant: 'white',
            },
            {
                label: 'Anuluj',
                onClick: (e) => {
                    this.forceHideTools();
                    e.preventDefault();
                    this.setState({viewMode: 'VIEW'});
                    this.removeCookie(CookiesConstants.DETAILS_VIEW_MODE);
                    this.service.get(this.state.element.id).then((res) => {
                        this.updateElement(res);
                    });
                },
                rendered: this.state.viewMode === 'EDIT',
                type: 'BUTTON',
                className: `${this.state.element.active === false ? 'cancel-button gray' : ''}`,
                variant: 'white',
            },
            {
                label: 'Edytuj',
                onClick: (e) => {
                    e.preventDefault();
                    this.setState({viewMode: 'EDIT', flag: !this.state.flag});
                    this.saveCookie(CookiesConstants.DETAILS_VIEW_MODE, 'EDIT');
                },
                rendered:
                    (this.renderActivationButton() ||
                        this.state.element.status === 'DUPLICATED_BY_EDITOR' ||
                        this.state.element.status === 'DRAFT') &&
                    this.state.viewMode !== 'EDIT' &&
                    this.state.element.active === false,
                type: 'BUTTON',
                className: `${this.state.element.active === false ? 'cancel-button gray' : ''}`,
                variant: 'white',
                iconRight: 'edit.svg',
            },
            {
                label: 'Usuń',
                onClick: (e) => {
                    e.preventDefault();
                    this.showDeleteConfirmationDialog();
                    this.removeCookie(CookiesConstants.DETAILS_VIEW_MODE);
                },
                rendered: !this.state.element.active && this.state.viewMode !== 'NEW',
                type: 'BUTTON',
                iconRight: 'close.svg',
                variant: 'white',
            },
        ];
        return headerItems;
    }

    renderActivationButton() {
        if (this.isSubtemplate && this.hasUserAdminPackagePermission()) {
            return false;
        } else {
            return this.state.viewMode !== 'NEW' && !this.hasUserEditorPermission();
        }
    }

    changeActiveState(active) {
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                active: active,
            },
        }));
        const {elementId} = this.state;

        if (active) {
            this.service
                .activateTemplate(elementId)
                .then((element) => {
                    this.updateAfterActivationChanged(element, true);
                })
                .catch((ex) => {
                    this.showErrorMessage(ex.message);
                });
        } else {
            this.service
                .deactivateTemplate(elementId)
                .then((element) => {
                    this.updateAfterActivationChanged(element, false);
                })
                .catch((ex) => {
                    this.showErrorMessage(ex.message);
                });
        }
    }

    deleteTemplate(templateId) {
        this.service
            .deleteTemplate(templateId)
            .then((res) => {
                this.afterDeleteSuccess(res);
            })
            .catch(() => {
                this.showErrorMessage('Użytkownik nie ma uprawnień do usunięcia szablonu!');
            });
    }

    updateAfterActivationChanged(element, active) {
        let msg = this.getAgreementTemplateTranslatedType() + ' został ' + (active ? 'aktywowany' : 'dezaktywowany');
        this.setState({element}, () => this.setActiveViewMode(active));
        this.showSuccessMessage(msg);
    }

    handlePreview(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValidWithAccordionCollapsed()) {
            this.blockUi(this.generatePreview);
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.scrollToError = true;
            this.forceUpdate();
        }
    }

    generatePreview() {
        const {element} = this.state;
        element.isPreValidated = this.state.isPreValidated;
        this.scrollToTop();
        if (this._isMounted) {
            this.service
                .savePreviewVersion(element)
                .then((response) => {
                    this.setState({isPreValidated: false}, () => {
                        window.open(
                            AppPrefixUtils.locationHrefUrl(`/#/agreement-preview/${response.id}`),
                            '',
                            'width=1000,height=400,left=200,top=200,overflow-y:auto'
                        );
                    });
                })
                .catch((err) => {
                    if (err.errorCode === 'AGREEMENT_TEMPLATE_VALIDATION_ERROR') {
                        this.setState((prevState) => ({
                            showPrevalidationDialog: true,
                            prevalidationMessage: err.message,
                            afterPrevalidationFunction: this.generatePreview,
                        }));
                    } else {
                        this.showErrorMessage(err.message, 10000);
                    }
                });
        }
    }
    handleFormFunc(event, func) {
        if (event !== undefined && event !== null) {
            event.preventDefault();
        }
        if (this.validator.allValidWithAccordionCollapsed()) {
            this.blockUi(func);
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.scrollToError = true;
            this.forceUpdate();
        }
    }

    saveDraft() {
        const {element} = this.state;
        element.isPreValidated = this.state.isPreValidated;
        this.scrollToTop();
        if (this._isMounted) {
            this.service
                .saveDraftVersion(element)
                .then(() => {
                    this.setState(
                        {
                            isPreValidated: false,
                        },
                        () => {
                            this.saveCookie(`configurator-nav-activeIndex`, 1);
                            this.persistMessage('success', '', 'Zapisano wersję roboczą');
                            window.location.href = AppPrefixUtils.locationHrefUrl(this.props.backUrl);
                        }
                    );
                })
                .catch((err) => {
                    if (err.errorCode === 'AGREEMENT_TEMPLATE_VALIDATION_ERROR') {
                        this.setState((prevState) => ({
                            showPrevalidationDialog: true,
                            prevalidationMessage: err.message,
                            afterPrevalidationFunction: this.saveDraft,
                        }));
                    } else {
                        this.showErrorMessage(err.message, 10000);
                    }
                });
        }
    }

    prepareFooterItems() {
        const saveLabel = this.authService.isUserInRole('ROLE_LAW_OFFICE_EDITOR_MANAGE') ? 'Wyślij propozycję zmiany' : 'Zapisz';
        const {viewMode} = this.state;
        switch (viewMode) {
            case 'EDIT':
                return [
                    {label: 'Anuluj', href: AppPrefixUtils.locationHrefUrl(this.goBackUrl()), className: 'cancel-button gray'},
                    {
                        label: saveLabel,
                        className: 'float-right',
                        onClick: (e) => this.handleFormFunc(e, this.handleValidForm),
                    },
                    {
                        label: 'Podgląd',
                        className: 'float-right',
                        onClick: this.handlePreview,
                        rendered: !this.isSubtemplate,
                    },
                    {
                        label: 'Zapisz wersję roboczą',
                        className: 'float-right',
                        rendered: this.authService.isUserInRole('ROLE_LAW_OFFICE_EDITOR_MANAGE'),
                        onClick: (e) => this.handleFormFunc(e, this.saveDraft),
                    },
                ];
            case 'NEW':
                return [
                    {label: 'Anuluj', href: AppPrefixUtils.locationHrefUrl(this.goBackUrl()), className: 'cancel-button gray'},
                    {
                        label: saveLabel,
                        className: 'float-right',
                        onClick: (e) => this.handleFormFunc(e, this.handleValidForm),
                    },
                    {
                        label: 'Zapisz wersje roboczą',
                        className: 'float-right',
                        rendered: this.authService.isUserInRole('ROLE_LAW_OFFICE_EDITOR_MANAGE'),
                        onClick: (e) => this.handleFormFunc(e, this.saveDraft),
                    },
                ];
            case 'VIEW':
                return this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TEMPLATE_MANAGE')
                    ? [
                          {
                              label: 'Odrzuć zmiany',
                              className: 'button cancel-button gray',
                              onClick: this.openRejectDialog,
                              rendered: this.state.element.status === 'NEW',
                          },
                          {
                              label: 'Akceptuj zmiany',
                              className: 'float-right',
                              onClick: (e) => this.handleFormFunc(e, this.handleValidForm),
                              rendered: this.state.element.status === 'NEW',
                          },
                          {
                              label: 'Podgląd',
                              className: 'float-right',
                              onClick: this.handlePreview,
                              rendered: !this.isSubtemplate,
                          },
                      ]
                    : [];
            default:
                return [];
        }
    }

    openRejectDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({rejectDialogVisible: true});
    }
    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState(
            {rejectDialogVisible: false},
            () => {
                if (callback) callback();
            },
            () => {
                DialogResizer.removeMaskIfExistOneDialog();
            }
        );
    }

    handleAddParameter(element) {
        const modifiedList = this.state.element.tags;
        if (!element.uuid) {
            element.uuid = uuidv4();
            modifiedList.push(element);
        } else {
            let index = -1;
            modifiedList.forEach((el, i) => {
                if (el['uuid'] === element.uuid) {
                    index = i;
                }
            });
            modifiedList[index] = element;
        }
        this.setState(
            (prevState) => ({
                element: {
                    ...prevState.element,
                    tags: modifiedList,
                    addParameterActionProccessed: true,
                },
            }),
            () => this.onAfterParameterEdit()
        );
    }
    showParameterDialog() {
        this.setState({dialogVisibile: true, parameterDialogElement: new AgreementTagModel()});
    }
    showParameterDetails(rowData) {
        this.setState({dialogVisibile: true, parameterDialogElement: rowData});
    }
    showDeleteConfirmationDialog() {
        this.setState({deleteTemplateDialogVisible: true});
    }
    closeDeleteConfirmationDialog() {
        this.setState(
            {
                deleteTemplateDialogVisible: false,
            },
            () => {
                DialogResizer.removeMaskIfExistOneDialog();
            }
        );
    }
    initBeforeSetElement() {
        if (!this.isSubtemplate) {
            this.settingsService
                .getPaymentMaxAmount()
                .then((resp) =>
                    this.setState({
                        maxPaymentAmount: resp['PAYMENT_MAX_AMOUNT'],
                    })
                )
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać maksymalnej kwoty transakcji');
                });
            this.categoryService
                .getAll()
                .then((categories) =>
                    this.setState({
                        categories,
                    })
                )
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy kategorii');
                });
            this.specializationService
                .getAllSpecializations()
                .then((specializations) =>
                    this.setState({
                        specializations,
                    })
                )
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy specjalizacji');
                });
            if (this.state.viewMode === 'NEW') {
                if (!this.hasUserAdminPackagePermission()) {
                    this.service
                        .getActiveAndAcceptedSubtemplates()
                        .then((res) => {
                            const subTemplates = TemplateUtils.setVisibleNameForSubTemplates(res);
                            this.setState({
                                subTemplates,
                            });
                        })
                        .catch(() => {
                            this.showErrorMessage('Nie udało się pobrać listy subszablonów');
                        });
                }
            } else {
                if (!this.hasUserAdminPackagePermission()) {
                    this.service
                        .getAcceptedSubtemplates()
                        .then((res) => {
                            const subTemplates = TemplateUtils.setVisibleNameForSubTemplates(res);
                            this.setState({
                                subTemplates,
                            });
                        })
                        .catch(() => {
                            this.showErrorMessage('Nie udało się pobrać listy subszablonów');
                        });
                }
            }
        }

        this.hierarchicalDictionaryService
            .getListForTemplate()
            .then((dictionaries) =>
                this.setState({
                    dictionaries,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy słowników');
            });
    }
    isSummableParameterType(type) {
        return ['NUMBER', 'MULTIPLICATION'].find((t) => t === type);
    }
    isNumber(type) {
        return ['NUMBER'].find((t) => t === type);
    }
    render() {
        return (
            <React.Fragment>
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                    <BaseBreadCrumb items={this.breadCrumbItems()} />
                    <div id='container-fluid'>
                        <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)}></CustomMessages>
                        {this.state.dialogVisibile && (
                            <ParameterDialog
                                visible={this.state.dialogVisibile}
                                className='col-xl-5 col-lg-6 col-md-6 col-sm-12 parameter-dialog'
                                key={`parameter-dialog`}
                                onHide={this.onCancel}
                                blockUi={this.blockUi}
                                unblockUi={this.unblockUi}
                                viewMode={this.state.viewMode}
                                _isMounted={this._isMounted}
                                handleAddParameter={this.handleAddParameter}
                                element={this.state.parameterDialogElement}
                                dictionaries={this.state.dictionaries}
                                parentTags={this.state.element.tags?.filter((t) => t.type === 'DYNAMIC_LIST')}
                                parameterTypes={this.parameterTypes}
                                subTemplates={this.state.subTemplates}
                                sumElementsOptions={this.state.element.tags?.filter((t) => this.isSummableParameterType(t.type))}
                                factorOptions={this.state.element.tags?.filter((t) => this.isNumber(t.type))}
                                points={this.state.element.tags?.filter((p) => p.type === 'POINT')}
                            />
                        )}
                        {this.state.invalidOperationDialog && (
                            <YesNoDialog
                                className={'confirmation-dialog invalid-operation small-dialog'}
                                visible={this.state.invalidOperationDialog}
                                header='Ostrzeżenie'
                                name='dialogVisibile'
                                onChange={() => {
                                    this.closeInvalidOperationDialog();
                                }}
                                onHide={() => {
                                    this.closeInvalidOperationDialog();
                                }}
                                children={'Operacja niedozwolona. Proszę przeformatować umowę.'}
                                noButtonLabel={'OK'}
                                singleButton={true}
                                noButtonVariant='cancel-button'
                            ></YesNoDialog>
                        )}
                        {this.renderExitConfirmationDialog()}
                        {this.state.deleteTemplateDialogVisible && (
                            <YesNoDialog
                                className={'confirmation-dialog invalid-operation small-dialog'}
                                visible={this.state.deleteTemplateDialogVisible}
                                header='Ostrzeżenie'
                                name='dialogVisibile'
                                onChange={() => {
                                    this.deleteTemplate(this.state.element.id);
                                }}
                                onHide={() => {
                                    this.closeDeleteConfirmationDialog();
                                }}
                                onCancel={() => {
                                    this.closeDeleteConfirmationDialog();
                                }}
                                children={'Czy na pewno chcesz usunąć szablon?'}
                                noButtonLabel={'Tak'}
                                singleButton={true}
                                noButtonVariant='cancel-button'
                            ></YesNoDialog>
                        )}
                        {this.state.removingWarningDialog && (
                            <YesNoDialog
                                className={'confirmation-dialog invalid-operation small-dialog'}
                                visible={this.state.removingWarningDialog}
                                header='Ostrzeżenie'
                                name='dialogVisibile'
                                onChange={(type, x, target) => {
                                    if (target.value) {
                                        this.removeAndHideDialog();
                                    } else {
                                        this.hideRemovingWarningDialog();
                                    }
                                }}
                                onHide={() => {
                                    this.hideRemovingWarningDialog();
                                }}
                                children={
                                    !!this.state.isAssignedToDynamicList
                                        ? 'Parametr, który usuwasz jest przypisany do listy dynamicznej, która do prawidłowgo działania wymaga co najmniej jednego parametru. Czy chcesz mimo wszystko go usunąć?'
                                        : 'Czy na pewno chcesz usunąć komponent?'
                                }
                                noButtonLabel={'Anuluj'}
                            ></YesNoDialog>
                        )}
                        {this.state.showPrevalidationDialog && (
                            <YesNoDialog
                                className={'confirmation-dialog invalid-operation small-dialog'}
                                visible={this.state.showPrevalidationDialog}
                                header='Ostrzeżenie'
                                name='dialogVisibile'
                                onChange={(type, x, target) => {
                                    console.log(this.state);
                                    if (target.value) {
                                        this.setState(
                                            {isPreValidated: true},
                                            () => {
                                                this.handleFormFunc(null, this.state.afterPrevalidationFunction);
                                            },
                                            () => {
                                                DialogResizer.removeMaskIfExistOneDialog();
                                            }
                                        );
                                    }
                                    this.closePrevalidationDialog();
                                }}
                                onHide={() => {
                                    this.closePrevalidationDialog();
                                }}
                                children={
                                    <div>
                                        <label>{this.state.prevalidationMessage}</label>
                                        <label>Czy mimo to chcesz kontynuować?</label>
                                    </div>
                                }
                                noButtonLabel={'Anuluj'}
                                yesButtonLabel={'Kontynuuj'}
                            ></YesNoDialog>
                        )}
                        {this.device?.type && (this.device.type === 'mobile' || this.device.type === 'tablet') ? (
                            <YesNoDialog
                                className={'confirmation-dialog invalid-operation small-dialog'}
                                visible={true}
                                header='Strona niedostępna'
                                name='dialogVisibile'
                                onChange={(type, x, target) => {
                                    DialogResizer.removeMaskIfExistOneDialog();
                                    window.location.href = AppPrefixUtils.locationHrefUrl(this.props.backUrl);
                                }}
                                onHide={() => {
                                    DialogResizer.removeMaskIfExistOneDialog();
                                    window.location.href = AppPrefixUtils.locationHrefUrl(this.props.backUrl);
                                }}
                                children={
                                    <div>
                                        <label>Ta strona jest niedostępna dla urządzenia</label>
                                    </div>
                                }
                                singleButton
                                noButtonLabel={'Wróć do listy szablonów'}
                            ></YesNoDialog>
                        ) : this.state.loading ? null : (
                            this.renderCard()
                        )}
                    </div>
                </BlockUi>
            </React.Fragment>
        );
    }
    updateTextAfterInsertingCustomTag() {
        const text = this.editor.current.getContents();
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                text: text,
            },
        }));
    }

    goBackUrl() {
        return this.props.backUrl;
    }
    getSunEditorInstance(suneditor) {
        this.editor.current = suneditor;
    }
    createNewTag() {
        const element = {field: '', label: '', description: '', type: undefined};
        return element;
    }
    renderCard() {
        return (
            <Card
                style={{width: `${this.state.availableWidth}px`}}
                className='details-card '
                footer={this.renderFooter()}
                header={this.renderHeader()}
            >
                <form onSubmit={this.handleFormSubmit} noValidate>
                    {this.renderDetails()}
                </form>
            </Card>
        );
    }
    createOrUpdate() {
        const {element} = this.state;
        element.isPreValidated = this.state.isPreValidated;
        const {backUrl} = this.props;
        this.scrollToTop();
        if (this._isMounted) {
            if (element.id === undefined || element.id === null) {
                this.service
                    .add(element)
                    .then((response) => {
                        this.setState({isPreValidated: false}, () => {
                            this.afterAddSuccess(response);
                        });
                    })
                    .catch((err) => {
                        if (err.errorCode === 'AGREEMENT_TEMPLATE_VALIDATION_ERROR') {
                            this.setState((prevState) => ({
                                showPrevalidationDialog: true,
                                prevalidationMessage: err.message,
                                afterPrevalidationFunction: this.createOrUpdate,
                            }));
                        } else {
                            this.showErrorMessage(err.message, 10000);
                        }
                    });
            } else {
                this.service
                    .update(element)
                    .then((response) => {
                        this.setState({isPreValidated: false}, () => {
                            if (response.status === 'NEW') {
                                this.blockUi();
                                this.persistMessage('success', '', this.getUpdateSucces(response));
                                window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
                            } else {
                                this.afterUpdateSuccess(response);
                            }
                        });
                    })
                    .catch((err) => {
                        if (err.errorCode === 'AGREEMENT_TEMPLATE_VALIDATION_ERROR') {
                            this.setState((prevState) => ({
                                showPrevalidationDialog: true,
                                prevalidationMessage: err.message,
                                afterPrevalidationFunction: this.createOrUpdate,
                                element: {
                                    ...prevState.element,
                                    isPreValidated: true,
                                },
                            }));
                        } else {
                            this.showErrorMessage(err.message, 10000);
                        }
                    });
            }
        }
    }

    afterUpdateSuccess(response) {
        this.blockUi();
        this.showSuccessMessage(this.getUpdateSucces(response));
        this.updateElement(response);
    }

    longTextTemplate(rowData) {
        if (rowData?.description?.length > 100) {
            return rowData.description.slice(0, 100).concat('...');
        } else return rowData.description;
    }

    calculatedAmountBodyTemplate(rowData) {
        return CurrencyUtils.currency(rowData.price);
    }

    forceHideTools() {
        if (document.getElementsByClassName('se-toolbar-cover')) {
            this.setState(
                {
                    showToolBar: false,
                },
                () => {
                    // zmiana prio
                    setTimeout(function () {
                        if (document.getElementsByClassName('se-toolbar-cover')[0]) {
                            document.getElementsByClassName('se-toolbar-cover')[0].style.display = 'none';
                            document.getElementsByClassName('se-toolbar')[0].style.display = 'none';
                        }
                    }, 1);
                }
            );
        }
    }

    availabilityChanged(name, checked) {
        const FULL = 'FULL';
        let availabilities = this.state.availabilities;
        availabilities.forEach((el) => {
            if (el.enumValue === name) {
                el.checked = checked;
            } else if (name === FULL) {
                el.checked = true;
            }
        });

        this.setState(
            {
                availabilities,
            },
            () => {
                let checkedAvailabilities = availabilities.filter((el) => el.checked);
                let isFullAccess = checkedAvailabilities.length === 2;
                const availability = isFullAccess ? FULL : checkedAvailabilities[0]?.enumValue;
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        availability,
                    },
                }));
            }
        );
    }

    renderAvalabilities() {
        return this.state.availabilities
            ? this.state.availabilities.map((i, index) => {
                  return (
                      <div class='row' style={{width: '100%'}}>
                          <InputCheckboxComponent
                              id={`${index}-availability`}
                              name={i.label}
                              label={i.label}
                              labelOnRight
                              value={!!i.checked}
                              insideTable
                              validator={this.validator}
                              validators='not_required'
                              colClass='col-md-12'
                              disabled={this.state.viewMode === 'VIEW' || this.state.element.active}
                              onChange={(a, b, e) => {
                                  this.availabilityChanged(i.enumValue, e.target.checked);
                              }}
                              viewMode={'EDIT'}
                              positioning='col-md-6 form-group-checkbox-flex'
                          />
                      </div>
                  );
              })
            : null;
    }

    rowClassName(data) {
        return `${HtmlParserUtils.getParameterRowClassName(data?.uuid)} ${HtmlParserUtils.eachParameterRow()}`;
    }

    componentWillUnmount() {
        const {deviceType} = this.device;
        super.componentWillUnmount();
        if (deviceType === 'mobile' || deviceType === 'tablet') {
            return;
        }
        // imo to nie potrzebne ale na wypadek zostawiamy
        const navbarTogglerLaptop = document.getElementById('navbar-toggler-laptop');
        if (!navbarTogglerLaptop) {
            return;
        }
        const sidebar = document.getElementsByClassName('pro-sidebar')[0];
        if (sidebar.style.display === 'none') {
            sidebar.style.display = 'flex';
        }
    }

    scrollEventHandler() {
        window.addEventListener('scroll', () => {
            const stickyDummy = document.getElementsByClassName('se-toolbar-sticky-dummy')[0];
            const seToolbar = document.getElementsByClassName('se-toolbar')[0];
            if (!seToolbar) {
                return;
            }
            if (Array.from(seToolbar.classList).includes('se-toolbar-sticky')) {
                seToolbar.classList.remove('se-toolbar-sticky');
                stickyDummy.style.display = 'none';
                stickyDummy.style.height = '0px';
            }
        });
    }

    renderDetails() {
        const plgn = plugins;
        plgn.pluginSubmenu = pluginSubmenu;
        plgn.pluginAlignLeft = pluginAlignLeft;
        plgn.pluginAlignRight = pluginAlignRight;
        plgn.pluginAlignCenter = pluginAlignCenter;
        plgn.pluginAlignJustify = pluginAlignJustify;
        plgn.pluginBlur = pluginBlur;
        plgn.pluginClearBlur = pluginClearBlur;
        this.scrollEventHandler();
        const viewMode = this.viewMode();
        return (
            <React.Fragment>
                {viewMode !== 'VIEW' && (
                    <div className='row'>
                        {/* <InputTextComponent
                            id='author'
                            name='author'
                            label={t('agreement.author')}
                            colClass='col-md-12'
                            value={this.state.element.author}
                            validator={this.validator}
                            validators='required|alpha_space|max:100'
                            onChange={this.handleChange}
                            viewMode={'VIEW'}
                            rendered={this.props.viewMode === 'VIEW'}
                        /> */}
                    </div>
                )}
                {this.state.verticalMode ? this.verticalMode(plgn) : this.horizontalMode(plgn)}
                {this.state.rejectDialogVisible ? (
                    <TemplateDialog
                        key={`driver-dialog-${this.state.element?.title}`}
                        elementId={this.state.element.id}
                        onHide={(e) => {
                            this.handleCloseDialog(e);
                        }}
                        visible={this.state.rejectDialogVisible}
                        author={this.state.element.author}
                        email={this.state.email}
                        viewMode={'NEW'}
                        afterSave={(e) =>
                            this.handleCloseDialog(e, () => {
                                this.readMessage();
                                this.refreshTable();
                                this.unblockUi();
                            })
                        }
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                        className='template-proposition-dialog'
                    />
                ) : null}{' '}
            </React.Fragment>
        );
    }

    verticalMode(plgn) {
        return (
            <React.Fragment>
                <Accordion
                    multiple
                    onTabChange={(event) => {
                        this.toolbarChange(event);
                        const index = event?.index ? event.index : [-1];
                        this.setState({
                            activeAccordionIndex: index,
                        });
                    }}
                    activeIndex={this.state.activeAccordionIndex}
                    ref={this.accordionRef}
                >
                    {this.accordionTabDetailsComponents()}
                </Accordion>
                <Split
                    onDrag={(e) => {
                        const component = document.getElementById('components-vertical-mode');
                        const tableVerticalStyle = 'table-vetrical-style';
                        if (e[1] < 42) {
                            if (!Array.from(component.classList).find((el) => el === tableVerticalStyle))
                                component.classList.add(tableVerticalStyle);
                        } else {
                            if (Array.from(component.classList).find((el) => el === tableVerticalStyle))
                                component.classList.remove(tableVerticalStyle);
                        }
                    }}
                    className='split mt-1'
                    minSize={[925, 500]}
                >
                    <div>
                        <Accordion
                            multiple
                            onTabChange={(event) => {
                                let index = event?.index ? event.index : [-1];
                                if (index.length === 0) {
                                    event.index = [2];
                                }
                                if (index.length === 2) {
                                    index = [0];
                                }
                                console.log(index, 'INDEX');
                                this.toolbarChange(event);
                                this.setState({
                                    activeAccordionIndexSplit: index,
                                });
                            }}
                            activeIndex={this.state.activeAccordionIndexSplit}
                            ref={this.accordionRef}
                        >
                            {this.state.viewMode !== 'VIEW' && <AccordionTab header={'Narzędzia edytora'}></AccordionTab>}
                        </Accordion>
                        {this.suneditorComponent(plgn)}
                    </div>
                    <div id='components-vertical-mode'>
                        <div className='m-2'>
                            <p>
                                <b>Komponenty</b>
                            </p>
                            {this.componentsComponent()}
                        </div>
                    </div>
                </Split>
            </React.Fragment>
        );
    }

    horizontalMode(plgn) {
        return (
            <React.Fragment>
                <Accordion
                    multiple
                    onTabChange={(event) => {
                        this.toolbarChange(event);
                        const index = event?.index ? event.index : [-1];
                        this.setState({
                            activeAccordionIndex: index,
                        });
                    }}
                    activeIndex={this.state.activeAccordionIndex}
                    ref={this.accordionRef}
                >
                    {this.accordionTabDetailsComponents()}
                    <AccordionTab header={'Komponenty'}>{this.componentsComponent()}</AccordionTab>
                    {this.state.viewMode !== 'VIEW' && <AccordionTab header={'Narzędzia edytora'}></AccordionTab>}
                </Accordion>
                {this.suneditorComponent(plgn)}
            </React.Fragment>
        );
    }

    accordionTabDetailsComponents() {
        const viewMode = this.viewMode();
        return (
            <AccordionTab header={'Szczegóły'} className='mt-2'>
                {viewMode === 'VIEW' ? (
                    <div>
                        <AgreementConfiguratorTemplate element={this.state.element} />
                    </div>
                ) : (
                    <div>
                        <div className='row'>
                            <InputTextComponent
                                id='name'
                                name='name'
                                label={t('agreement.title')}
                                colClass='col-md-12'
                                value={this.state.element.name}
                                validator={this.validator}
                                validators='required|max:100'
                                onChange={this.handleChange}
                                viewMode={this.viewMode()}
                            />
                        </div>
                        <div className='row'>
                            <InputTextareaComponent
                                id='description'
                                name='description'
                                label={t('agreement.description')}
                                colClass='col-md-12'
                                value={this.state.element.description}
                                validator={this.validator}
                                validators='required|max:1000'
                                onChange={this.handleChange}
                                viewMode={this.viewMode()}
                                rows={3}
                            />
                        </div>
                        {!this.isSubtemplate ? (
                            <React.Fragment>
                                <div className='row'>
                                    <InputDropdownComponent
                                        id='category'
                                        name='category'
                                        label={'Kategoria'}
                                        colClass='col-md-12'
                                        value={this.state.element.category}
                                        validator={this.validator}
                                        options={this.state.categories}
                                        onChange={this.handleChange}
                                        viewMode={this.viewMode()}
                                        optionLabel='name'
                                        validators='required'
                                    />
                                </div>
                                <div className='row'>
                                    <InputDropdownComponent
                                        id='specialization'
                                        name='specialization'
                                        label={'Specjalizacja'}
                                        colClass='col-md-12'
                                        value={this.state.element.specialization}
                                        validator={this.validator}
                                        options={this.state.specializations}
                                        onChange={this.handleChange}
                                        viewMode={this.viewMode()}
                                        optionLabel='name'
                                        validators='required'
                                    />
                                </div>
                                <div className='row'>
                                    <InputNumberComponent
                                        id='price'
                                        name='price'
                                        label={t('agreement.price')}
                                        colClass='col-md-12'
                                        value={this.state.element.price}
                                        validator={this.validator}
                                        validators={`required|number_max:${this.state.maxPaymentAmount}`}
                                        onChange={this.handleChange}
                                        viewMode={this.state.viewMode}
                                        body={this.calculatedAmountBodyTemplate}
                                        mode='currency'
                                        currency='PLN'
                                        locale='pl'
                                    />
                                </div>
                                <div className='row'>{this.renderAvalabilities()}</div>
                            </React.Fragment>
                        ) : null}
                    </div>
                )}
            </AccordionTab>
        );
    }

    componentsComponent() {
        return (
            <EditableDataTableExt
                name='tags'
                label='Komponenty'
                showLabel={false}
                columns={[
                    {
                        field: 'id',
                        header: t('parameter.id'),
                        type: 'TEXT',
                        sortable: true,
                    },
                    {
                        field: 'field',
                        header: t('parameter.field'),
                        type: 'TEXT',
                        validators: 'required|max:50',
                        sortable: true,
                    },
                    {
                        field: 'label',
                        header: t('parameter.label'),
                        type: 'TEXT',
                        validators: 'not_required|max:100',
                        sortable: true,
                    },
                    {
                        field: 'description',
                        header: t('parameter.description'),
                        type: 'TEXT',
                        validators: 'not_required|max:2000',
                        body: this.longTextTemplate,
                        sortable: true,
                    },
                    {
                        field: 'type',
                        header: t('parameter.type'),
                        type: 'DROPDOWN',
                        width: '220px',
                        validators: 'not_required|max:500',
                        optionLabel: 'label',
                        dataKey: 'label',
                        options: this.parameterTypes,
                        body: this.enumTemplate.bind(this, 'ParameterType', 'type'),
                        sortable: true,
                    },
                ]}
                addedListName='addedList'
                addedList={this.state.addedList}
                modifiedListName='modifiedList'
                modifiedList={this.state.modifiedList}
                validator={this.props.validator}
                viewMode={this.viewMode()}
                onChange={this.handleChange}
                createNewElement={this.createNewTag.bind(this)}
                editable
                showAddButton={this.viewMode() !== 'VIEW'}
                showDeleteButton={this.viewMode() !== 'VIEW'}
                addButtonLabel='Dodaj kolejny komponent'
                dataKey='uuid'
                ref={this.editableListRef}
                className='datatable-responsive'
                tableClassName='agreement-tags-table'
                value={this.state.element.tags}
                publicMode
                validators='array_required'
                permanentDelete
                stateField={'element'}
                addButtonIconName='mdi-plus'
                deleteButtonClassName='float-left pi pi-trash no-underline'
                showDeleteButtonLabel={false}
                handleAddElement={this.showParameterDialog}
                onAfterRemoveElement={this.onAfterRemoveElement}
                showDetails={this.showParameterDetails}
                showDetailsButton
                beforeRemoveElement={this.handleBeforeRemoveElement}
                rowClassName={this.rowClassName}
            />
        );
    }

    suneditorComponent(plgn) {
        return (
            <SunEditor
                key={`sun-editor-${this.state.flag}`}
                lang='pl'
                defaultValue={this.state.element.text}
                setDefaultStyle={'font-family: Times new Roman'}
                onCopy={CopyUtils.handleCopy}
                onCut={CopyUtils.handleCut}
                onResizeEditor={(e) => {
                    this.windowResizer();
                }}
                setOptions={{
                    height: 700,
                    buttonList: buttons,
                    plugins: plgn,
                    tagOptions: this.state.element.tags,
                    insertTag: this.updateTextAfterInsertingCustomTag.bind(this),
                    blockingAction: this.openInvalidOperationDialog.bind(this),
                    attributesWhitelist: {
                        all: HtmlParserUtils.getTooltipAttributeForTemplate(),
                    },
                    font: ['Times new Roman', 'Monteserrat', 'Manrope', 'Calibri'],
                }}
                onLoad={() => {
                    if (!this.state.showToolBar) {
                        const toolbars = document.getElementsByClassName('se-toolbar');
                        if (toolbars && toolbars?.length > 0) {
                            const toolbar = toolbars[0];
                            if (this.state.addParameterActionProccessed) {
                                this.setState({
                                    addParameterActionProccessed: false,
                                });
                                return;
                            }
                            if (toolbar) {
                                toolbar.style.display = 'none';
                            }
                        }
                    }
                }}
                getSunEditorInstance={this.getSunEditorInstance}
                onChange={this.handleTextChange}
                onInput={this.handleInput}
                disable={
                    this.state.viewMode === 'VIEW' ||
                    (this.hasUserAdminPackagePermission() &&
                        !this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TEMPLATE_MANAGE'))
                        ? true
                        : false
                }
                onKeyDown={this.customOnKeyDown}
            />
        );
    }
    // Jak klika pierwzy raz w vertical mode i potem w narzediza
    isInitializeExpandToolsVerticalModeClicked(event, toolbar) {
        return event.index.length === 2 && this.isVerticalMode() && this.isCollapsed(toolbar);
    }
    isVerticalMode() {
        return this.state.verticalMode;
    }
    isExpanded(element) {
        return element.style.display === 'inline-block';
    }

    isCollapsed(element) {
        return element.style.display === 'none';
    }

    toolbarChange(e) {
        const toolbar = document.getElementsByClassName('se-toolbar')[0];
        if (toolbar) {
            if (!e) {
                toolbar.style.display = 'none';
                return;
            }
            if (this.isInitializeExpandToolsVerticalModeClicked(e, toolbar)) {
                toolbar.style.display = 'inline-block';
            } else {
                if (this.isVerticalMode()) {
                    if (this.isExpanded(toolbar)) {
                        toolbar.style.display = 'none';
                    } else {
                        toolbar.style.display = 'inline-block';
                    }
                } else {
                    toolbar.style.display = e?.index?.includes(2) ? 'inline-block' : 'none';
                }
            }
            setTimeout(function () {
                const accordionContent = document.getElementsByClassName('p-accordion-content');

                Array.from(accordionContent).forEach((el) => {
                    if (el.parentElement.parentElement.outerText.toUpperCase() === 'NARZĘDZIA EDYTORA') {
                        el.style.display = 'none';
                    }
                });
            }, 0);
        }
    }
    customOnKeyDown(e) {
        if (e.key === 'Enter') {
            TemplateUtils.enterHandler(e, () => this.openInvalidOperationDialog());
        } else if (e.code.toUpperCase() === 'SPACE') {
            TemplateUtils.spaceHandler(e);
        }
    }
    openInvalidOperationDialog() {
        this.setState({invalidOperationDialog: true});
    }
    closeInvalidOperationDialog() {
        this.setState({invalidOperationDialog: false}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
        });
    }
    closePrevalidationDialog() {
        this.setState(
            (prevState) => ({
                showPrevalidationDialog: false,
                element: {
                    ...prevState.element,
                    isPreValidated: false,
                },
            }),
            () => {
                DialogResizer.removeMaskIfExistOneDialog();
            }
        );
    }
    handleBeforeRemoveElement(rowData, deleted, permanent, handleRemove) {
        const isAssignedToDynamicList = this.isAssignedToDynamicList(rowData);
        this.showRemovingWarningDialog(handleRemove, isAssignedToDynamicList);
    }
    isAssignedToDynamicList(rowData) {
        return rowData.parentTag;
    }
    showRemovingWarningDialog(handleRemove, isAssignedToDynamicList) {
        this.setState({removingWarningDialog: true, isAssignedToDynamicList, removingDialogFunction: () => handleRemove()});
    }
    hideRemovingWarningDialog() {
        this.setState({removingWarningDialog: false, isAssignedToDynamicList: false, removingDialogFunction: () => {}}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
        });
    }
    removeAndHideDialog() {
        this.blockUi();
        const handleRemove = this.state.removingDialogFunction;
        this.setState({removingWarningDialog: false, isAssignedToDynamicList: false, removingDialogFunction: () => {}}, () => {
            handleRemove();
            DialogResizer.removeMaskIfExistOneDialog();
        });
    }
}
TemplateContainer.defaultProps = {
    backUrl: '/#/configurator-template-list',
    editUrl: '/#/template/edit',
};
TemplateContainer.propTypes = {};
export default withTranslation()(TemplateContainer);
