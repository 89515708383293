import AgreementCalculationUtils from './AgreementCalculationUtils';
import polishToWords from './numberToPolishWords';

class StringUtils {
    static getReplacedString(original, toRemove, toInsert) {
        return String(original.replace(new RegExp(this.escapeRegExp(toRemove), 'g'), toInsert));
    }
    static escapeRegExp(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    static formatInsertedNumberValue(numberValue, numberProperties, selectedCurrency, translate) {
        if (StringUtils.isBlank(numberValue)) {
            numberValue = 0;
        }
        if (typeof numberValue === 'number') {
            numberValue = numberValue.toFixed(AgreementCalculationUtils.getDecimalPlaces(numberProperties));
        }
        let result = !StringUtils.isBlank(numberValue) ? numberValue.toLocaleString('pl') : '';
        if (numberProperties.convertToRoman) {
            result = this.convertToRoman(numberValue);
        }
        if (numberProperties.showPercent) {
            result += ' %';
        }
        if (numberProperties.showCurrency && selectedCurrency) {
            result += ` ${selectedCurrency.symbol}`;
        }
        if (numberProperties.showDescription) {
            let numberParts = '' + numberValue;
            numberParts = numberParts.split('.');
            const integerPart = numberParts[0];
            const fractionPart = numberParts[1]?.length === 1 ? numberParts[1] + '0' : numberParts[1];
            if (numberProperties.showCurrency && selectedCurrency && this.isCurrencyKnown(selectedCurrency)) {
                //dla liczb powyżej 21 przekazywać ostatnią cyfrę
                const integerPartForTranslate = parseFloat(integerPart) > 21 ? integerPart[integerPart.length - 1] : integerPart;
                const fractionPartForTranslate = parseFloat(fractionPart) > 21 ? fractionPart[fractionPart.length - 1] : fractionPart;

                result += ` (sł. ${!isNaN(integerPart) ? polishToWords(integerPart) : 0} 
                ${translate(`currencies.${selectedCurrency.currency}.integerPart`, {
                    postProcess: 'interval',
                    count: parseInt(integerPartForTranslate),
                })}
                 ${
                     !isNaN(fractionPart)
                         ? `${polishToWords(fractionPart)} ${translate(`currencies.${selectedCurrency.currency}.fractalPart`, {
                               postProcess: 'interval',
                               count: parseInt(fractionPartForTranslate),
                           })}`
                         : ''
                 })`;
            } else {
                result += ` (sł. ${!isNaN(integerPart) ? polishToWords(integerPart) : 0} ${
                    !isNaN(fractionPart) ? `przecinek ${polishToWords(fractionPart)}` : ''
                } ${numberProperties.showPercent ? 'procent' : ''} ${
                    numberProperties.showCurrency && selectedCurrency ? selectedCurrency.symbol : ''
                })`;
            }
        }
        return result;
    }
    static isCurrencyKnown(selectedCurrency) {
        const supportedCurrencies = ['PLN', 'EUR', 'USD', 'GBP'];
        return supportedCurrencies.filter((c) => c === selectedCurrency.currency)?.length > 0;
    }
    static convertToRoman(num) {
        const roman = {
            M: 1000,
            CM: 900,
            D: 500,
            CD: 400,
            C: 100,
            XC: 90,
            L: 50,
            XL: 40,
            X: 10,
            IX: 9,
            V: 5,
            IV: 4,
            I: 1,
        };
        let str = '';

        for (let i of Object.keys(roman)) {
            let q = Math.floor(num / roman[i]);
            num -= q * roman[i];
            str += i.repeat(q);
        }

        return str;
    }
    static isBlank(value) {
        let areSpacesOnly = false;
        if (value !== undefined && value !== null && typeof value === 'string') {
            if (value.trim().length === 0) {
                areSpacesOnly = true;
            }
        }
        return typeof value === 'undefined' || value === null || value === '' || areSpacesOnly;
    }
}
export default StringUtils;
