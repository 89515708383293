import React from 'react';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import DivContainer from '../../components/DivContainer';
import {ActionButton} from '../../components/ActionButton';
// import CategoryModel from '../../model/CategoryModel';
import CategoryService from '../../services/CategoryService';
import DialogResizer from '../../components/DialogResizer';

class CategoryListDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new CategoryService());
        this.state = {
            element: {
                id: '',
                name: '',
            },
            viewMode: this.props.viewMode,
            activeIndex: 0,
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton
                    label={this.state.viewMode === 'NEW' ? t('Dodaj') : t('Edytuj')}
                    variant='button save-button blue'
                    buttonType={'NEXT'}
                    handleClick={this.handleFormSubmit}
                />
            </div>
        );
    }

    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('category.addHeader')}`
                            : this.state.viewMode === 'EDIT'
                            ? `${t('category.editHeader')}`
                            : null}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }
    updateElement(data) {
        this.setState(
            {
                element: {
                    name: data.name,
                    id: data.id,
                },
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    getContainerListName() {
        return 'category-list-container';
    }

    getAddSucces() {
        const {t} = this.props;
        return t('category.addSuccessfull');
    }

    renderDetails() {
        return this.renderView();
    }

    handleFormSubmit() {
        const {t} = this.props;
        if (this.validator.allValid()) {
            new CategoryService()
                .add({
                    name: this.state.element.name,
                })
                .then((response) => {
                    this.persistMessage('success', '', t('category.addSuccessfull'));
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                })
                .catch((err) => {
                    this.persistMessage('error', '', err.message);
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                });
        } else {
            this.validator.showMessages();
            // this.scrollToError = true;
            this.forceUpdate();
        }
    }
    renderView() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='name'
                        name='name'
                        label={'Nazwa kategorii'}
                        colClass='col-md-12'
                        value={this.state.element.name}
                        validator={this.validator}
                        placeholder='Wpisz'
                        validators={'required|max:100'}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
}

CategoryListDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
};

CategoryListDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
};

export default withTranslation()(CategoryListDialog);
