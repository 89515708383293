import React, {Component} from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import HeaderComponent from '../components/layouts/HeaderComponent';
import {isLexDocs} from '../baseContainers/BaseContainer';

export default function withoutAuth(
    WithoutAuthComponent,
    viewMode,
    label,
    whiteBackgroundStyle = 'default-white-background',
    backUrl,
    hideHeader = false,
    afterRenderComponent,
    setActiveMenu,
    menuName
) {
    return class WithoutAuthWrapped extends Component {
        constructor() {
            super();
            this.state = {};
            this.mainRef = React.createRef();
        }

        containerStyle() {
            if (window.location.href.includes('agreement/edit') || window.location.href.includes('agreement/create')) {
                // if(isLexDocs ? )
                return 'container-custom';
            }
            if (process.env.REACT_APP_PORTAL_TYPE === 'LEXDOCS') {
                return 'container-fluid';
            } else {
                return 'container-custom';
            }
        }
        containsUrl(array) {
            let contains = false;
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                if (window.location.href.includes(element)) {
                    contains = true;
                }
            }
            return contains;
        }

        componentDidMount() {
            if (afterRenderComponent) {
                afterRenderComponent(true);
            }
            if (setActiveMenu) {
                setActiveMenu(menuName);
            }
        }
        // TODO: wymaga aby id elementId byl jako number a nie string dlaczego? do zastanoweiania
        isUUID(uuid) {
            let s = '' + uuid;
            s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
            if (s === null) {
                return false;
            }
            return true;
        }
        render() {
            const isUUID = this.isUUID(this.props?.match.params.id);
            const containerStyle = this.containerStyle();

            return (
                <React.Fragment>
                    <div className={`header-white-background ${whiteBackgroundStyle}`} />
                    <div ref={this.mainRef} className={`${containerStyle} ${whiteBackgroundStyle}`} style={{position: 'relative'}}>
                        <BreadcrumbsItem icon='account-box' to='/agreement-list' className='p-link'>
                            {/* Strona główna */}
                        </BreadcrumbsItem>
                        <HeaderComponent label={label} />
                        {/* backUrl={backUrl} */}
                        {/* <BreadcrumbsComponent /> */}
                        <WithoutAuthComponent
                            mainRef={this.mainRef}
                            viewMode={viewMode}
                            history={this.props.history}
                            elementId={isUUID ? this.props?.match.params.id : parseInt(this.props?.match.params.id)}
                            backUrl={backUrl}
                            publicMode={true}
                            {...this.state}
                        />
                    </div>
                </React.Fragment>
            );
        }
    };
}
