import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from './ActionButton';
import {withTranslation} from 'react-i18next';
import {Card} from 'primereact/card';
import PrimiDialog from './PrimiDialog';
import DialogResizer from './DialogResizer';

export const YesNoDialog = (props) => {
    const {
        children,
        className,
        header,
        id,
        key,
        ref,
        name,
        noButtonClassName,
        noButtonLabel,
        noButtonVariant,
        onAfterNoStateChange,
        onAfterYesStateChange,
        onChange,
        style,
        forceRemoveMask,
        onHide,
        stateField,
        visible,
        yesButtonClassName,
        yesButtonLabel,
        yesButtonVariant,
        singleButton,
    } = props;
    return (
        <PrimiDialog
            id={id}
            ref={ref}
            ariaCloseIconLabel='Zamknij okno dialogowe'
            header={header}
            style={style}
            visible={visible}
            className={className}
            key={`${key ? key : id ? id : name}-dialog`}
            onHide={() => {
                if (onHide) {
                    new Promise((resolve, reject) => {
                        onHide();
                        resolve(() => DialogResizer.removeMaskIfExistOneDialog());
                    }).then((fnc) => {
                        fnc();
                    });
                } else {
                    return null;
                }
            }}
            draggable={false}
        >
            <Card
                footer={() => {
                    return (
                        <React.Fragment>
                            <hr className='line-footer' />
                            <div className={`footer-dialog-button-container centered`}>
                                <ActionButton
                                    label={noButtonLabel}
                                    variant={noButtonVariant}
                                    className={'ml-2 ' + noButtonClassName}
                                    handleClick={(e) => {
                                        if (onChange) {
                                            onChange('YES_NO_DIALOG', undefined, {name, value: false}, onAfterNoStateChange, stateField);
                                            if (forceRemoveMask) {
                                                DialogResizer.removeMask();
                                                return;
                                            }
                                            DialogResizer.removeMaskIfExistOneDialog();
                                        } else {
                                            return null;
                                        }
                                    }}
                                    key={`${key ? key : id ? id : name}-no-button`}
                                />
                                {!singleButton ? (
                                    <ActionButton
                                        label={yesButtonLabel}
                                        variant={yesButtonVariant}
                                        className={`${yesButtonClassName} float-right`}
                                        handleClick={(e) => {
                                            if (onChange) {
                                                onChange(
                                                    'YES_NO_DIALOG',
                                                    undefined,
                                                    {name, value: true},
                                                    onAfterYesStateChange,
                                                    stateField
                                                );
                                                if (forceRemoveMask) {
                                                    DialogResizer.removeMask();
                                                    return;
                                                }
                                                DialogResizer.removeMaskIfExistOneDialog();
                                            } else {
                                                return null;
                                            }
                                        }}
                                        key={`${key ? key : id ? id : name}-yes-button`}
                                    />
                                ) : null}
                            </div>
                        </React.Fragment>
                    );
                }}
            >
                <div style={{marginBottom: '20px'}} className='col-12'>
                    {children ? children : 'Czy jesteś pewien?'}
                </div>
            </Card>
        </PrimiDialog>
    );
};

YesNoDialog.defaultProps = {
    noButtonVariant: 'accent gray',
    noButtonLabel: 'Nie',
    stateField: 'element',
    yesButtonVariant: 'dark',
    style: undefined,
    yesButtonLabel: 'Tak',
    singleButton: false,
    // floatRightClassName:false,
    forceRemoveMask: false, // imo niepotrzebne ale an wypadek
};

YesNoDialog.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    header: PropTypes.string.isRequired,
    id: PropTypes.string,
    ref: PropTypes.object,
    key: PropTypes.string,
    name: PropTypes.string.isRequired,
    noButtonClassName: PropTypes.string,
    noButtonLabel: PropTypes.string,
    noButtonVariant: PropTypes.string,
    onAfterNoStateChange: PropTypes.func,
    onAfterYesStateChange: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onHide: PropTypes.func,
    style: PropTypes.object,
    stateField: PropTypes.string,
    visible: PropTypes.bool.isRequired,
    yesButtonClassName: PropTypes.string,
    yesButtonLabel: PropTypes.string,
    yesButtonVariant: PropTypes.string,
    singleButton: PropTypes.bool,
    forceRemoveMask: PropTypes.bool,
};

export default withTranslation()(YesNoDialog);
