import BaseServiceRead from './BaseServiceRead';

export default class BaseServiceCrud extends BaseServiceRead {
    // Initializing important variables
    constructor(domain) {
        super(domain);
        this.add = this.add.bind(this);
        this.update = this.update.bind(this);
    }

    add(element) {
        
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    update(element) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/${element.id}`, {
            method: 'PUT',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    remove(element) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/${element.id}`, {
            method: 'DELETE',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    fetchPost(url, body, headers) {
        // const method = options !== undefined ? options.method : undefined;
        const options = {method: 'POST', body: JSON.stringify(body)};
        this.counter += 1;
        console.log(options, 'options')
        return new Promise((resolve, reject) => {
            fetch(url, {
                headers,
                ...options,
            })
                .then((response) => {
                    response.headers.forEach(function (val, key) {
                        console.log(key + ' -> ' + val);
                    });
                    if (response.ok) {
                        return resolve(response);
                    }
                    return reject(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
