import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseContainer from '../baseContainers/BaseContainer';
import ActionButton from '../components/ActionButton';
import {CustomMessages} from '../components/CustomMessages';
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';
import RegistrationService from '../services/RegistrationService';
import BlockUi from '../components/waitPanel/BlockUi';
import {Galleria} from 'primereact/galleria';
import AppPrefixUtils from '../utils/AppPrefixUtils';

class StartLexdocsContainer extends BaseContainer {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.registrationService = new RegistrationService();
        this.authService = new AuthService();
        this.userService = new UserService();
        this.templateUrl = '';
        this.templateDescription = '';
        this.instructions = [
            {
                title: 'zakup pakiet',
                url: AppPrefixUtils.locationHrefUrl('/#/package-offer'),
                description:
                    'Pakiet - Donec rutrum congue leo eget malesuada. Nulla porttitor accumsan tincidunt. Donec sollicitudin molestie malesuada. Nulla porttitor accumsan tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posu Donec rutrum congue leo eget malesuada.',
                imageSrc: AppPrefixUtils.locationHrefUrl('/images/lexdocs-startpage/pricelist_lexdocs.png'),
            },
            {
                title: 'Wybierz szablon',
                url: AppPrefixUtils.locationHrefUrl('/#/template-list'),
                description:
                    'Donec rutrum congue leo eget malesuada. Nulla porttitor accumsan tincidunt. Donec sollicitudin molestie malesuada. Nulla porttitor accumsan tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posu Donec rutrum congue leo eget malesuada.',
                imageSrc: AppPrefixUtils.locationHrefUrl('/images/lexdocs-startpage/wybierzszablon_lexdocs.png'),
            },
            {
                title: 'Umów konsultację',
                url: '/#/scheduled-event-calendar',
                description:
                    'Donec rutrum congue leo eget malesuada. Nulla porttitor accumsan tincidunt. Donec sollicitudin molestie malesuada. Nulla porttitor accumsan tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posu Donec rutrum congue leo eget malesuada.',
                imageSrc: AppPrefixUtils.locationHrefUrl('/images/lexdocs-startpage/consultation_lexdocs-1.png'),
            },
            {
                title: 'Uruchom kreator',
                url: AppPrefixUtils.locationHrefUrl('/#/template-list'),
                description:
                    'Donec rutrum congue leo eget malesuada. Nulla porttitor accumsan tincidunt. Donec sollicitudin molestie malesuada. Nulla porttitor accumsan tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posu Donec rutrum congue leo eget malesuada.',
                imageSrc: AppPrefixUtils.locationHrefUrl('/images/lexdocs-startpage/uruchomkreator_lexdocs.png'),
            },
            {
                title: 'Omów z kontrahentem',
                url: AppPrefixUtils.locationHrefUrl('/#/template-list'),
                description:
                    'Donec rutrum congue leo eget malesuada. Nulla porttitor accumsan tincidunt. Donec sollicitudin molestie malesuada. Nulla porttitor accumsan tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posu Donec rutrum congue leo eget malesuada.',
                imageSrc: AppPrefixUtils.locationHrefUrl('/images/lexdocs-startpage/kontrahent_lexdocs.png'),
            },
            {
                title: 'Wygeneruj dokument',
                url: AppPrefixUtils.locationHrefUrl('/#/agreement-list'),
                description:
                    'Donec rutrum congue leo eget malesuada. Nulla porttitor accumsan tincidunt. Donec sollicitudin molestie malesuada. Nulla porttitor accumsan tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posu Donec rutrum congue leo eget malesuada.',
                imageSrc: AppPrefixUtils.locationHrefUrl('/images/lexdocs-startpage/wygenerujdocument_lexdocs.png'),
            },
        ];
        this.state = {
            activeIndex: 0,
            images: [
                {
                    itemImageSrc: AppPrefixUtils.locationHrefUrl('/images/start-images/signing.jpg'),
                    thumbnailImageSrc: AppPrefixUtils.locationHrefUrl('/images/start-images/signing.jpg'),
                    alt: 'Description for Image 1',
                    title: 'Title 1',
                },
                {
                    itemImageSrc: AppPrefixUtils.locationHrefUrl('/images/start-images/startImage.jpg'),
                    thumbnailImageSrc: AppPrefixUtils.locationHrefUrl('/images/start-images/startImage.jpg'),
                    alt: 'Description for Image 1',
                    title: 'Title 1',
                },
                {
                    itemImageSrc: AppPrefixUtils.locationHrefUrl('/images/start-images/man-signing2.jpg'),
                    thumbnailImageSrc: AppPrefixUtils.locationHrefUrl('/images/start-images/man-signing2.jpg'),
                    alt: 'Description for Image 1',
                    title: 'Title 1',
                },
                {
                    itemImageSrc: AppPrefixUtils.locationHrefUrl('/images/start-images/teamwork.jpg'),
                    thumbnailImageSrc: AppPrefixUtils.locationHrefUrl('/images/start-images/teamwork.jpg'),
                    alt: 'Description for Image 1',
                    title: 'Title 1',
                },
                {
                    itemImageSrc: AppPrefixUtils.locationHrefUrl('/images/start-images/man-signing.jpg'),
                    thumbnailImageSrc: AppPrefixUtils.locationHrefUrl('/images/start-images/man-signing.jpg'),
                    alt: 'Description for Image 1',
                    title: 'Title 1',
                },
            ],
            instruction: this.instructions[0],
        };

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 5,
            },
            {
                breakpoint: '768px',
                numVisible: 3,
            },
            {
                breakpoint: '560px',
                numVisible: 1,
            },
        ];
    }
    itemTemplate(item) {
        return <img src={AppPrefixUtils.locationHrefUrl(item.itemImageSrc)} alt={item.text} style={{width: '100%', display: 'block'}} />;
    }

    thumbnailTemplate(item) {
        return (
            <img src={AppPrefixUtils.locationHrefUrl(`${item.previewImageSrc}`)} alt={item.alt} style={{width: '100%', display: 'block'}} />
        );
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    getContainerListName() {
        return 'start-container';
    }

    render() {
        const imageCol = 'col-xl-3 col-lg-4 col-md-4 col-sm-12';
        return (
            <React.Fragment>
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                    <div id='start-page'>
                        <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)}></CustomMessages>
                        <div className='start-container'>
                            <div className='row gallery-description'>
                                <div className='col-lg-6 col-md-12'>
                                    <Galleria
                                        ref={(el) => (this.galleria3 = el)}
                                        value={this.state.images}
                                        responsiveOptions={this.responsiveOptions}
                                        numVisible={3}
                                        style={{maxWidth: 'fit-content', maxHeight: '100%', position: 'static'}}
                                        activeIndex={this.state.activeIndex}
                                        onItemChange={(e) => this.setState({activeIndex: e.index})}
                                        circular
                                        //showItemNavigators
                                        showThumbnails={false}
                                        item={this.itemTemplate}
                                        thumbnail={this.thumbnailTemplate}
                                        className='col-12'
                                        autoPlay
                                    />
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <div>
                                        <div className='start-description-title'>
                                            <h1>Jak to działa?</h1>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='start-description'>
                                            <p>{this.state.instruction.description}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ActionButton
                                            href={AppPrefixUtils.locationHrefUrl(this.state.instruction.url)}
                                            label='Wypróbuj już dziś!'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='start-icons row'>
                                    {this.instructions.map((instruction) => {
                                        return (
                                            <div
                                                className={imageCol}
                                                onClick={() =>
                                                    this.setState({
                                                        instruction,
                                                    })
                                                }
                                            >
                                                <div className=''>
                                                    <div className='instruction-content'>
                                                        <div className='instruction-content-image'>
                                                            <span class='start-image-wrapper'>
                                                                <img
                                                                    src={AppPrefixUtils.locationHrefUrl(instruction.imageSrc)}
                                                                    alt=''
                                                                    sizes='(max-width: 200px) 100vw, 200px'
                                                                ></img>
                                                            </span>
                                                        </div>
                                                        <div className='instruction-content-description'>
                                                            <h4 class='title'>
                                                                <span>{instruction.title}</span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </BlockUi>
            </React.Fragment>
        );
    }
}
StartLexdocsContainer.propTypes = {
    onAfterLogin: PropTypes.func,
};
export default withTranslation()(StartLexdocsContainer);
