import BaseServiceCrud from './BaseServiceCrud';

export default class RegisterService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.registerUser = this.registerUser.bind(this);
        this.getTokenInformation = this.getTokenInformation.bind(this);
        this.path = 'registration';
    }
    

    getCriteria(criteria) {
        return this.objToQueryString({
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    getSubscriptionWithTemplate(id){
		return this.fetch(`${this.domain}/${this.path}/subscription-with-templates/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
	}

    registerUser(body) {
        return this.fetch(`${this.domain}/registration`, {
            method: 'POST',
            body: JSON.stringify(body)
        }).then((res) => {
            return res;
        });
    }


    getTokenInformation(token) {
        return this.fetch(`${this.domain}/registration/confirm?token=${token}`, {
            method: 'GET',
        }).then((res) => {
            return res;
        });
    }

    getAllApprovals(){
        return this.fetch(`${this.domain}/${this.path}/approval/all`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });

    } 
    
    getAllActiveSubscriptions(){
        return this.fetch(`${this.domain}/${this.path}/subscription-active/all`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });

    }

    isEmailExist(email) {
        return this.fetch(`${this.domain}/${this.path}/email-exist/${email}`, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    } 
}
