import React from 'react';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import DivContainer from '../../components/DivContainer';
import {ActionButton} from '../../components/ActionButton';
import SpecializationService from '../../services/SpecializationService';
import UserRoleService from '../../services/UserRoleService';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import UserModel from '../../model/UserModel';
import {CustomMessages} from '../../components/CustomMessages';
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';
import InternalUserService from '../../services/InternalUserService';
import LawOfficeService from '../../services/LawOfficeService';
import InputConsultantComponent from '../../components/inputs/ePrawnik/InputConsultantComponent';
import InputPhoneComponent from '../../components/inputs/ePrawnik/InputPhoneComponent';
import AppPrefixUtils from '../../utils/AppPrefixUtils';

class LawyerDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.state = {
            element: new UserModel(),
            viewMode: this.props.viewMode,
            activeIndex: 0,
        };
        this.userRoleService = new UserRoleService();
        this.specializationService = new SpecializationService();
        this.lawOfficeService = new LawOfficeService();
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton
                    buttonType={'PREV'}
                    label={t('global.backStepBtn')}
                    variant={'button cancel-button gray'}
                    handleClick={this.handleChangeStep.bind(this, {index: this.state.activeIndex}, true)}
                    rendered={this.state.activeIndex === 1}
                />

                <ActionButton
                    buttonType={'NEXT'}
                    label={this.state.viewMode === 'NEW' ? t('user.addButton') : t('details.saveChanges')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                    rendered={true}
                />
            </div>
        );
    }

    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('lawyer.addHeader')}`
                            : this.state.viewMode === 'EDIT'
                            ? `${t('lawyer.editHeader')}`
                            : null}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }

    handleChangeStep(e, backStep) {
        if (backStep === true) {
            e.index--;
            this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
        } else {
            e.index++;
            if (this.validator.allValid()) {
                this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
            } else {
                this.validator.showMessages();
                // this.scrollToError = true;
                this.forceUpdate();
            }
        }
    }

    handleValidForm() {
        const {element} = this.state;
        this.scrollToTop();
        if (this.props._isMounted) {
            this.service
                .add(element)
                .then((user) => {
                    this.persistMessage('success', '', 'Utworzono użytkownika oraz wysłano link do ustanowienia hasła');
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                })
                .catch((err) => {
                    this.persistMessage('error', '', err.message);
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                });
        }
        this.unblockUi();
    }

    initBeforeSetElement() {
        this.specializationService
            .getAllSpecializations()
            .then((specializations) => {
                this.setState(() => ({
                    specializations: specializations,
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });

        this.userRoleService
            .getPermissionsByRoleName('Kancelaria partnerska')
            .then((permissions) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        permissions: permissions,
                    },
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });

        this.lawOfficeService
            .getLawOfficeByUserId(this.props.elementId)
            .then((lawOffice) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        lawOffice: lawOffice,
                    },
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });

        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                portal: 'LEX_DOCS',
                status: 'INACTIVE',
                person: 'LAWYER',
                address: undefined,
            },
        }));
    }

    getContainerListName() {
        return 'lawyer-list-container';
    }

    getAddSucces() {
        const {t} = this.props;
        return t('lawyer.addSuccessfull');
    }

    afterAddSuccess(response) {
        const backUrl = this.props.backUrl + '/' + this.props.elementId;
        this.blockUi();
        this.persistMessage('success', '', this.getAddSucces(response));
        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
    }
    isConsultantSelected() {
        let permission = this.state?.element?.permissions;
        return permission?.find((el) => el.permissionGroup === 'ROLE_LAW_OFFICE_CONSULTANT' && el.selected === true) ? true : false;
    }

    renderSpecializations() {
        return (
            <InputMultiSelectComponent
                id='specializations'
                name='specializations'
                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                label='Specjalizacje'
                value={this.state.element.specializations}
                validator={this.validator}
                validators={'required|array_required'}
                onChange={this.handleChange}
                rendered={true}
                viewMode={'EDIT'}
                options={this.state.specializations}
                dataKey='id'
                optionLabel='name'
                filter
                filterBy='name'
            />
        );
    }

    renderFirstStep() {
        return (
            <React.Fragment>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='email'
                        name='email'
                        label={'Email'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                        placeholder={'Wpisz email'}
                        value={this.state.element.email}
                        validator={this.validator}
                        validators='required|email|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode === 'EDIT' ? 'VIEW' : this.props.viewMode}
                    />
                    <InputTextComponent
                        id='firstName'
                        name='firstName'
                        label={'Imię'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                        value={this.state.element.firstName}
                        placeholder={'Wpisz imię'}
                        validator={this.validator}
                        validators='required|alpha_space_dash|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='lastName'
                        name='lastName'
                        label={'Nazwisko'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                        placeholder={'Wpisz nazwisko'}
                        value={this.state.element.lastName}
                        validator={this.validator}
                        validators='required|alpha_space_dash|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />

                    <InputTextComponent
                        id='bankAccountNumber'
                        name='bankAccountNumber'
                        label='Numer konta bankowego'
                        placeholder={'Numer konta bankowego'}
                        value={this.state.element.bankAccountNumber}
                        onChange={this.handleChange}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                        validators='required|numeric|min:26|max:26'
                        validator={this.validator}
                        viewMode={this.props.viewMode}
                    />
                    <InputPhoneComponent
                        id='phoneNumber'
                        name='phoneNumber'
                        label='Numer telefonu'
                        placeholder={'Wpisz numer telefonu'}
                        value={this.state.element.phoneNumber}
                        onChange={this.handleChange}
                        validators='required|phone|max:9|min:9'
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                        validator={this.validator}
                        viewMode={this.props.viewMode}
                    />
                    {this.renderSpecializations()}
                    {this.renderPermissions()}
                    <InputConsultantComponent
                        element={this.state.element}
                        validator={this.validator}
                        rendered={this.isConsultantSelected()}
                        handleChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                </DivContainer>
            </React.Fragment>
        );
    }

    renderPermissions() {
        return this.state.element.permissions.map((i, index) => {
            return (
                <div class='row' style={{width: '100%'}}>
                    <InputCheckboxComponent
                        id={`${index}-permission`}
                        name='selected'
                        label={i.description}
                        labelOnRight
                        value={!!i.selected}
                        validator={this.validator}
                        validators='not_required'
                        colClass='col-md-12'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
                        stateField={`element.permissions[${index}]`}
                        positioning='col-md-12 form-group-checkbox-flex'
                    />
                </div>
            );
        });
    }

    renderDetails() {
        return <div>{this.renderFirstStep()}</div>;
    }
}

LawyerDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
    backUrl: '/#/law-office/details',
};

LawyerDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
};

export default withTranslation()(LawyerDialog);
