import PropTypes from 'prop-types';
import React from 'react';
import BaseDetailsContainer from '../../../baseContainers/BaseDetailsContainer';
import UserService from '../../../services/UserService';
import SubscriptionService from '../../../services/SubscriptionService';
import {t} from 'i18next';
import PurchasedService from '../../../services/PurchasedService';
import PackageContainer from '../../PackagesContainer';
import AuthService from '../../../services/AuthService';
import YesNoDialog from '../../../components/YesNoDialog';
import AppPrefixUtils from '../../../utils/AppPrefixUtils';
import BaseBreadCrumb from '../../../baseContainers/BaseBreadCrumb';
import DialogResizer from '../../../components/DialogResizer';

class UserChangePackageContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new UserService());

        this.state = {
            viewMode: props.viewMode,
            element: {
                packages: [],
            },
            dialogVisibile: false,
        };
        this.handleChangePackage = this.handleChangePackage.bind(this);
        this.hideConfirmDialog = this.hideConfirmDialog.bind(this);
        this.backLinkRendered = false;
        this.authService = new AuthService();
    }

    hideConfirmDialog() {
        this.setState({
            dialogVisibile: false,
        });
    }

    renderFooter() {
        return null;
    }

    goBackUrl() {
        const {elementId, backUrl} = this.props;
        let goBackUrl = backUrl;
        if (elementId !== undefined) {
            goBackUrl = goBackUrl + '/' + elementId;
        }
        return goBackUrl;
    }

    getContainerListName() {
        switch (this.props.backUrl) {
            case this.props.packageOfferUrl:
                return 'package-offer';
            case this.props.userDetailsUrl:
            case this.props.manageAcountUrl:
                return 'user-package';
            default:
                return 'change-package';
        }
    }

    handleGoBack() {
        window.location.href = AppPrefixUtils.locationHrefUrl(this.goBackUrl());
    }

    translate(key) {
        return t(key);
    }

    handleChangePackage(e, selectedPackagesMap) {
        if (selectedPackagesMap.size === 0) {
            this.showErrorMessage('Nie wybrano pakietu.');
            return;
        }

        this.setState({
            dialogVisibile: true,
            selectedPackagesMap,
        });
    }
    handleFormSubmit() {
        let selectedPackagesMap = this.state.selectedPackagesMap;
        if (this.validator.allValid()) {
            let arrayOfTypes = [];
            [...selectedPackagesMap].map(([id, value]) => arrayOfTypes.push(value.type));
            let userId = this.props.elementId;
            if (this.props.elementId === undefined) {
                userId = this.props.user.userId;
            }
            new PurchasedService()
                .changeUserSubscriptions(arrayOfTypes, userId)
                .then((res) => {
                    if (this.authService.isUserInRole('ROLE_USER_LEX_DOCS_MANAGE')) {
                        res.forEach((el) => {
                            window.open(el.url + '/' + el.token, 'Płatność za pakiet' + el.token);
                        });
                    }

                    this.afterChangePackage('Pakiet został zmieniony');
                })
                .catch((err) => {
                    this.showErrorMessage(err.message, 10000, true, 'Błąd ');
                });
        }
    }

    afterChangePackage(msg) {
        const backUrl = this.goBackUrl();
        this.blockUi();
        this.persistMessage('success', '', msg);
        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
    }

    isAdminWithValidPermission() {
        return this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE');
    }

    initBeforeSetElement() {
        let userId = this.props.elementId;
        if (!userId) {
            userId = this.props.user.userId;
        }
        new SubscriptionService()
            .getAllSubscriptionForUser(userId)
            .then((packages) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        packages: packages,
                    },
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message, 10000, true, 'Błąd przy pobieraniu pakietów ogólnych');
            });
    }
    breadCrumbItems() {
        return [{name: 'Cennik', url: '/#/package-offer'}];
    }
    renderChangeSubscriptions() {
        const packages = this.state.element?.packages;
        return packages.filter((p) => p.visible).length ? (
            <PackageContainer
                colClass='col-12'
                handleChangePackage={this.handleChangePackage}
                handleTranslate={this.translate}
                viewMode={'EDIT'}
                style={{minWidth: '12em'}}
                service={new SubscriptionService()}
                packages={packages}
                showHeader={true}
                showTemplates={true}
                changePackageUrl={this.props.changePackageUrl}
                footerItems={[
                    {
                        label: 'Wróć',
                        className: 'float-left gray',
                        onClick: this.handleGoBack.bind(this),
                    },
                    {
                        label: 'Zapisz',
                        className: 'float-right',
                    },
                ]}
            />
        ) : null;
    }

    render() {
        return (
            <React.Fragment>
                <BaseBreadCrumb items={this.breadCrumbItems()} />

                {this.renderViewWithoutCard()}

                <YesNoDialog
                    className={'confirmation-dialog-change-package'}
                    visible={this.state.dialogVisibile}
                    header={'Czy chcesz dokonać zmiany pakietu?'}
                    name='dialogVisibile'
                    onChange={(type, x, target) => {
                        if (target.value) {
                            this.handleFormSubmit();
                            this.hideConfirmDialog(this.state.dialogValue);
                        } else {
                            this.hideConfirmDialog(this.state.dialogValue);
                        }
                    }}
                    onHide={() => {
                        this.hideConfirmDialog();
                        DialogResizer.removeMaskIfExistOneDialog();
                    }}
                    yesButtonLabel={'Tak'}
                    noButtonLabel={'Nie'}
                >
                    <div> Zmiana na inny pakiet spowoduje brak możliwości korzystania z pakietów, które zostały odznaczone.</div>
                </YesNoDialog>
            </React.Fragment>
        );
    }

    renderDetails() {
        return (
            <React.Fragment>
                <div className=''>{this.renderChangeSubscriptions()}</div>
            </React.Fragment>
        );
    }
}

UserChangePackageContainer.defaultProps = {
    cancelUrl: '/#/manage-account/details',
    detailUrl: '/#/manage-account/details',
    editUrl: '/#/manage-account/edit',
    packageTemplatesUrl: '/#/agreement-list/package',
    backUrl: '/#/manage-account/details',
    changePackageUrl: '/#/change-package',
    packageType: 'GENERAL',
    userDetailsUrl: '/#/user/details',
    packageOfferUrl: '/#/package-offer',
    manageAcountUrl: '/#/manage-account/details',
};

UserChangePackageContainer.propTypes = {
    cancelUrl: PropTypes.string,
    detailUrl: PropTypes.string,
    editUrl: PropTypes.string,
    changePackageUrl: PropTypes.string,
    packageType: PropTypes.string,
};

export default UserChangePackageContainer;
