export default class AddressModel {
    constructor() {
        this.postalCode = '';
        this.city = '';
        this.streetName = '';
        this.streetNumber = '';
        this.houseNumber = '';
    }

    static assign(from, to) {
        if (from) {
            to.postalCode = from.postalCode ? from.postalCode : '';
            to.city = from.city ? from.city : '';
            to.streetName = from.streetName ? from.streetName : '';
            to.streetNumber = from.streetNumber ? from.streetNumber : '';
            to.houseNumber = from.houseNumber ? from.houseNumber : '';
        } else {
            to = new AddressModel();
        }
    }

    static copy(from) {
        const to = new AddressModel();
        AddressModel.assign(from, to);
        return to;
    }
}
