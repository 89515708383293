export default class ReportWriteModel {
    constructor() {
        this.id = undefined;
        this.name = undefined;
        this.dateFrom = undefined;
        this.dateTo = undefined;
        this.personType = undefined;
        this.selusers = undefined;
        this.format = 'xls';
    }

    static assign(from, to) {
        if (from) {
            to.id = from.id;
            to.name = from.name;
            to.dateFrom = from.dateFrom;
            to.dateTo = from.dateTo;
            to.personType = from.personType.enumValue;
            to.selusers = from.selusers;
            to.format = from.format;
        } else {
            to = new ReportWriteModel();
        }
    }

    static copy(from) {
        const to = new ReportWriteModel();
        ReportWriteModel.assign(from, to);
        return to;
    }
}
