import {Card} from 'primereact/card';
//import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import DialogResizer from '../components/DialogResizer';
import PrimiDialog from '../components/PrimiDialog';
import BaseDetailsContainer from './BaseDetailsContainer';
import AppPrefixUtils from '../utils/AppPrefixUtils';

class BaseDialogContainer extends BaseDetailsContainer {
    constructor(props, service) {
        super(props);
        this.service = service;
        this.state = {closable: true};
        this.customClassName = this.customClassName.bind(this);
        this.onHide = this.onHide.bind(this);
        this.scrollToTopOnMount = false;
    }

    prepareFooterItems() {
        const {viewMode} = this.props;
        switch (viewMode) {
            case 'EDIT':
            case 'NEW':
                return [
                    {label: 'Anuluj', onClick: this.onHide},
                    {
                        label: 'Zapisz',
                        className: 'float-right',
                        onClick: this.handleFormSubmit,
                    },
                ];
            case 'VIEW':
            default:
                return [];
        }
    }
    onHide() {
        this.props?.onHide();
        DialogResizer.removeMaskIfExistOneDialog();
    }
    afterAddSuccess(response) {
        const {backUrl} = this.props;

        this.blockUi(() => DialogResizer.removeMaskIfExistOneDialog());
        this.persistMessage('success', '', this.getAddSucces(response));

        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
    }
    afterUpdateSuccess(response) {
        const {backUrl} = this.props;
        new Promise((resolve, reject) => {
            this.blockUi();
            resolve(() => DialogResizer.removeMaskIfExistOneDialog());
        }).then((fnc) => {
            fnc();
            this.persistMessage('success', '', this.getUpdateSucces(response));
            window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
        });
    }

    afterDeleteSuccess(response) {
        const {backUrl} = this.props;
        this.blockUi(() => DialogResizer.removeMaskIfExistOneDialog());
        this.persistMessage('success', '', this.getRemoveSucces(response));
        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
    }
    setElement() {
        const {viewMode, elementId} = this.props;
        if (elementId && (viewMode === 'EDIT' || viewMode === 'VIEW')) {
            this.blockUi();
            this.service
                .get(elementId, viewMode)
                .then((data) => {
                    this.setState({loading: false}, () => this.updateElement(data));
                })
                .catch((err) => {
                    this.handleGetDetailsError(err);
                });
        } else {
            this.setState({loading: false}, this.initAfterSetElement());
        }
    }
    renderDetails() {
        return;
    }
    prepareHeaderItems() {
        return [];
    }
    onKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleFormSubmit();
        }
    }

    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            this.blockUi(this.handleValidForm);
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // this.scrollToError = true;
            this.forceUpdate();
        }
    }

    blockUi(callBack, waitPanelLabel) {
        this.props.blockUi(callBack, waitPanelLabel);
    }
    unblockUi(callBack) {
        this.props.unblockUi(callBack);
    }
    customClassName() {
        return this.props.className;
    }
    keyFunction() {
        return this.props.key;
    }
    render() {
        const {className, id, key, name, onHide, visible, style} = this.props;
        return (
            // <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
            // <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
            <PrimiDialog
                ref={this.props.dialogRef}
                ariaCloseIconLabel={this.translate('dialog.closeDialog')}
                header={this.renderHeader()}
                visible={visible}
                className={this.customClassName()}
                style={style}
                key={`${this.keyFunction() ? this.keyFunction() : id ? id : name}-dialog`}
                onHide={() => {
                    if (onHide) {
                        new Promise((resolve, reject) => {
                            onHide();
                            resolve(() => DialogResizer.removeMaskIfExistOneDialog());
                        }).then((fnc) => {
                            fnc();
                        });
                    }
                }}
                closable={this.state.closable}
                draggable={false}
            >
                {/* <div style={{ marginBottom: '20px' }} className='row col-12'>
                    {this.renderHeader()}
                </div>					 */}
                {this.state.loading ? null : (
                    <Card footer={this.renderFooter()}>
                        <form onSubmit={this.handleFormSubmit} noValidate onKeyDown={(e) => this.onKeyDown(e)}>
                            {this.renderDetails()}
                        </form>
                    </Card>
                )}
            </PrimiDialog>
            // </BlockUi>
        );
    }
}
BaseDialogContainer.defaultProps = {
    currentUser: undefined,
    viewMode: 'VIEW',
};

BaseDialogContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    style: PropTypes.object,
};

export default BaseDialogContainer;
