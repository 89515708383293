/* eslint-disable react/jsx-handler-names */
import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import BaseListContainer from '../baseContainers/BaseListContainer';
import DivContainer from '../components/DivContainer';
import {OverlayPanel} from 'primereact/overlaypanel';
import ReminderService from '../services/ReminderService';
import '../assets/css/reminder.scss';
import AuthService from '../services/AuthService';
import PropTypes from 'prop-types';
import ActionLink from '../components/ActionLink';
import AppPrefixUtils from '../utils/AppPrefixUtils';

class ReminderContainer extends BaseListContainer {
    intervalId = undefined;

    constructor(props) {
        super(props, new ReminderService());
        this.authService = new AuthService();
        this.state = {
            size: 0,
            list: [],
            criteria: this.getCleanSearchCriteria(),
        };
        this.jwtRefreshBlocked = true;

        this.showReminders = this.showReminders.bind(this);
        this.markAsRead = this.markAsRead.bind(this);
        this.readReminders = this.readReminders.bind(this);
    }
    componentDidMount() {
        super.componentDidMount();
        if (
            this.authService.isUserInAnyRole(
                'ROLE_USER_LEX_DOCS_MANAGE',
                'ROLE_USER_LEKSY_MANAGE',
                'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                'ROLE_ADMIN_SUPER_MANAGE'
            )
        ) {
            this.readReminders();
            this.intervalId = setInterval(this.readReminders, 60000);
            this.componentWillUnmount();
        }
    }

    getList() {
        return null;
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    getCleanSearchCriteria() {
        return {
            maxResults: 10,
            firstResult: 0,
            sortField: 'sendDate',
            sortAsc: false,
        };
    }

    render() {
        return <div>{this.renderView()}</div>;
    }

    showReminders(e) {
        this.op.toggle(e);
    }
    refreshJwtToken() {}
    readReminders() {
        this.service
            .getList(this.getCleanSearchCriteria())
            .then((data) => {
                if (this._isMounted) {
                    this.setState({
                        list: data.content,
                        loading: false,
                        size: data.totalElements,
                    });
                }
            })
            .catch(() => {
                this.showErrorMessage('Wystąpił błąd systemu. Nie udało się pobrać listy.', 10000);
            });
    }

    handleRedirect(e, reminder) {
        const {scheduledConsultationUrl, templateDetailUrl} = this.props;
        let href;
        if (reminder.type === 'TEMPLATE_TO_ACCEPTANCE') {
            href = `${templateDetailUrl}/${reminder.refId}`;
        } else {
            href = `${scheduledConsultationUrl}`;
        }
        this.markAsRead(e, reminder);
        if (this.props.hideNavbar) {
            this.props.hideNavbar();
        }
        window.location.href = AppPrefixUtils.locationHrefUrl(href);
    }

    markAsRead(e, reminder) {
        e.preventDefault();
        this.service.markAsRead(reminder.id).then(() => {
            this.readReminders();
            this.redirectLink(reminder);
            super.componentDidMount();
        });
    }

    redirectLink(reminder) {
        const type = reminder.type;
        switch (type) {
            case 'INCIDENT':
                break;
            case 'CONSULTATIONS':
                break;
            default:
                console.log(``);
        }
    }

    renderReminders() {
        let reminders;
        if (this.state.size > 0) {
            reminders = this.state.list.map((reminder) => {
                return (
                    <div className='list-group row'>
                        <ActionLink
                            label={reminder.note}
                            iconName='mdi-book-multiple'
                            iconSide='left'
                            iconSize='xs'
                            tabIndex='0'
                            handleClick={(e) => {
                                this.handleRedirect(e, reminder);
                            }}
                        />
                    </div>
                );
            });
        } else {
            reminders = 'Brak powiadomień';
        }

        return <ul className='reminder-note'>{reminders}</ul>;
    }

    renderView() {
        const fontSize = this.props.fontSize;
        return (
            <span className={`${this.props.className} ${this.props.showTitle ? 'display-inline' : ''}`}>
                <button
                    style={{textDecoration: 'none', fontSize: fontSize}}
                    tabIndex='0'
                    className={`reminderButton ${this.state.size > 0 ? 'reminder-button-not-empty' : ''}`}
                    onClick={(e) => {
                        e.preventDefault();
                        this.showReminders(e);
                    }}
                >
                    {this.isLexyPortal() ? (
                        <img src={AppPrefixUtils.locationHrefUrl('/images/lexy/notifications.svg')} alt='' />
                    ) : (
                        <img src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/notifications.svg')} alt='' className='mt-2' />
                    )}
                    {this.state.size > 0 ? <span className={this.props.numberCircleClass}> {this.state.size} </span> : null}{' '}
                    {this.props?.showTitle ? <span className={this.props.titleClassName}>{'Powiadomienia'}</span> : ''}
                </button>
                <OverlayPanel ref={(el) => (this.op = el)} className='reminder-overlay'>
                    <DivContainer colClass='col-lg-12 col-md-12'>
                        <DivContainer colClass='row'>{this.renderReminders()}</DivContainer>
                    </DivContainer>
                </OverlayPanel>
            </span>
        );
    }
}

ReminderContainer.defaultProps = {
    fontSize: '14px',
    showTitle: false,
    numberCircleClass: 'numberCircle-xl',
    scheduledConsultationUrl: '/#/scheduled-event-calendar',
    templateDetailUrl: '/#/template/details',
};

ReminderContainer.propTypes = {
    className: PropTypes.string,
    numberCircleClass: PropTypes.string,
    fontSize: PropTypes.string,
    showTitle: PropTypes.bool,
    scheduledConsultationUrl: PropTypes.string,
};

export default ReminderContainer;
