import React from 'react';
import $ from 'jquery';
import BlockUi from '../../components/waitPanel/BlockUi';
import BaseContainer from '../../baseContainers/BaseContainer';
import SettingService from '../../services/SettingService';
import CollapsableLexyNavbar from './CollapsableLexyNavbar';
import CollapsableLexDocsLoggedNavbar from './CollapsableLexdocsLoggedNavbar';
import CollapsableLexyLoggedNavbar from './CollapsableLexyLoggedNavbar';
import ConfirmExitComponentsUtils from '../../utils/ConfirmExitComponentsUtils';
import AppPrefixUtils from '../../utils/AppPrefixUtils';

class NavBar extends BaseContainer {
    constructor(props) {
        super(props);
        this.service = new SettingService();
        this.jwtRefreshBlocked = true;
        this.state = {
            loading: false,
            blocking: false,
            cmsUrl: '',
        };
    }

    redirectOrShowConfirmationDialog = (redirect) => {
        const exist = ConfirmExitComponentsUtils.URLS.find((el) => {
            return window.location.href.includes(el);
        });

        if (typeof redirect === 'function') {
            this.props.editConfirmation(redirect);
            return;
        }

        exist
            ? this.props.editConfirmation(() => this.props.handleRedirect(redirect))
            : (window.location.href = AppPrefixUtils.locationHrefUrl(redirect));
    };

    componentDidMount() {
        const parameterName = this.isLexyPortal() ? 'CMS_LEXY_URL' : 'CMS_LEX_DOCS_URL';
        this.blockUi();
        this.service
            .getCmsUrl()
            .then((resp) => {
                this.setState({cmsUrl: resp[parameterName]}, () => this.unblockUi());
            })
            .catch(() => {
                this.showErrorMessage("Nie udało się pobrać adresu url CMS'a");
                this.unblockUi();
            });
    }
    footer = () => {
        return (
            <div className='navbar-footer'>
                <p>
                    <span id='navbar-footer-contact'>Kontakt</span>
                    <div className='navbar-footer-contact'>
                        <div>Plac wolności 3 lok 3A </div>
                        <div>40-078 Katowice </div>
                        <div>NIP 629-166-70-24</div>
                        <div>tel:(32) 400-89-91</div>
                        <div>tel. kom: 572-940-078</div>
                        <div>biuro@leksy.pl</div>
                    </div>
                </p>
            </div>
        );
    };

    render() {
        $(document).on('click', '.nav-item', function (e) {
            $('li').each(function (index) {
                $(this).removeClass('active');
            });
            $(this).addClass('active').siblings().removeClass('active');
        });
        return (
            <div className={`${this.props.footerVisible ? 'root-child-when-footer' : ''}`}>
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                    <React.Fragment>
                        {this.isLexyPortal() ? (
                            !!this.props.loggedIn ? (
                                <CollapsableLexyLoggedNavbar
                                    cmsUrl={this.state.cmsUrl}
                                    redirectOrShowConfirmationDialog={(redirect) => this.redirectOrShowConfirmationDialog(redirect)}
                                    logout={this.props.logout}
                                    loggedIn={this.props.loggedIn}
                                    userId={this.authService.getUserId()}
                                    generatedDocumentAvailable={this.props.generatedDocumentAvailable}
                                    footer={this.footer}
                                />
                            ) : (
                                <CollapsableLexyNavbar
                                    redirectOrShowConfirmationDialog={(redirect) => this.redirectOrShowConfirmationDialog(redirect)}
                                    cmsUrl={this.state.cmsUrl}
                                    logout={this.props.logout}
                                    loggedIn={this.props.loggedIn}
                                    userId={this.authService.getUserId()}
                                    generatedDocumentAvailable={this.props.generatedDocumentAvailable}
                                    footer={this.footer}
                                />
                            )
                        ) : !!this.props.loggedIn ? (
                            <CollapsableLexDocsLoggedNavbar
                                redirectOrShowConfirmationDialog={(redirect) => this.redirectOrShowConfirmationDialog(redirect)}
                                cmsUrl={this.state.cmsUrl}
                                logout={this.props.logout}
                                loggedIn={this.props.loggedIn}
                                userId={this.authService.getUserId()}
                                showContact={this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE')}
                                handleToggleSidebar={this.props.handleToggleSidebar}
                            />
                        ) : null}
                    </React.Fragment>
                </BlockUi>
            </div>
        );
    }
}

NavBar.defaultProps = {};

NavBar.propTypes = {};

export default NavBar;
