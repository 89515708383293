import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import ActionLink from '../../components/ActionLink';
import OrderService from '../../services/OrderService';
import CurrencyUtils from '../../utils/CurrencyUtils';
import ScheduledConsultationService from '../../services/ScheduledConsultationService';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';
import AppPrefixUtils from '../../utils/AppPrefixUtils';

class OrderListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new OrderService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            dialogVisibile: false,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            dictionaryParameterTypeOptions: [],
        };
        this.scheduledConsultationService = new ScheduledConsultationService();
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
        this.handlePaymentForOrder = this.handlePaymentForOrder.bind(this);
        this.calculatedAmountTemplate = this.calculatedAmountTemplate.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    getCleanSearchCriteria() {
        return {
            active: true,
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: false,
        };
    }

    getDataKey() {
        return 'id';
    }
    getListTitle() {
        return 'order.listTitle';
    }

    getCriteriaName() {
        return 'order-list';
    }

    getContainerListName() {
        return 'order-list-container';
    }

    prepareHeaderItems() {
        return [];
    }

    renderFilter() {
        return null;
    }

    handleOpenNewDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            dialogVisibile: true,
            dialogViewMode: 'NEW',
        });
    }

    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState(
            {
                dialogVisibile: false,
                elementId: undefined,
            },

            () => {
                if (callback) callback();
            }
        );
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    calculatedAmountTemplate(rowData) {
        return CurrencyUtils.currency(rowData.price);
    }

    prepareColumns() {
        let columns = [
            {field: 'id', header: 'Numer zamówienia', sortable: true, width: '15em'},
            {field: 'orderType', header: 'Typ zamówienia', sortable: true, body: this.enumTemplate.bind(this, 'OrderType', 'orderType')},
            {field: 'price', header: 'Cena', body: this.calculatedAmountTemplate},
            {
                field: 'paymentStatus',
                header: 'Status',
                sortable: true,
                width: '10em',
                body: this.enumTemplate.bind(this, 'PaymentStatus', 'paymentStatus'),
            },
        ];

        if (this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE')) {
            columns.push({
                key: 'actions',
                header: '',
                body: this.actionTemplate,
                className: 'table-column-action',
            });
        }
        return columns;
    }
    enumTemplate(enumType, field, rowData) {
        if (this.isLexyPortal() || enumType !== 'PaymentStatus') {
            if (rowData[field]) {
                return this.translate(`${enumType}.${rowData[field]}`);
            }
            return '';
        }
        let imageSrc = AppPrefixUtils.locationHrefUrl(`/images/lexdocs/`);

        switch (rowData[field]) {
            case 'PAID':
                return <img src={imageSrc + 'done_blue.svg'} alt='' title='Opłacony z abonamentu' />;
            case 'UNPAID':
                return <img src={imageSrc + 'close.svg'} alt='' title='Nieopłacony' />;
            default:
                return <img src={imageSrc + 'done_green.svg'} alt='' title='Opłacony' />;
        }
    }
    actionTemplate(rowData) {
        return rowData.paymentStatus === 'UNPAID' ? (
            <div>
                <ActionLink
                    rendered={this.backLinkRendered}
                    className='p-link'
                    handleClick={this.handlePaymentForOrder.bind(this, rowData)}
                    variant='blue'
                    label='Zapłać'
                />
            </div>
        ) : (
            []
        );
    }

    handlePaymentForOrder(order) {
        if (order.orderType === 'CONSULTATION') {
            this.scheduledConsultationService
                .canBePaid(order.refId, order.uuid)
                .then((res) => {
                    if (res) {
                        this.paymentForOrder(order);
                    } else {
                        this.showErrorMessage('Konsultacji nie można już opłacić.');
                    }
                })
                .catch(() => {
                    this.showErrorMessage('błąd wygenerownaia linku do płatności');
                });
        } else {
            this.paymentForOrder(order);
        }
    }

    paymentForOrder(order) {
        this.service
            .paymentForOrder(order.id)
            .then((res) => {
                window.location.href = res.url + '/' + res.token;
            })
            .catch((ex) => {
                this.showErrorMessage('błąd wygenerownaia linku do płatności');
            });
    }

    handleOpenDialog(rowData, e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            dialogVisibile: true,
            elementId: rowData.id,
            name: rowData.name,
            dialogViewMode: 'EDIT',
        });
    }
    breadCrumbItems() {
        return [{name: 'Zamówienia', url: '/#/order-list'}];
    }
    render() {
        return (
            <React.Fragment>
                <BaseBreadCrumb items={this.breadCrumbItems()}> </BaseBreadCrumb>
                <div className='container-fluid'>{this.renderView()}</div>
            </React.Fragment>
        );
    }
}

OrderListContainer.defaultProps = {
    detailUrl: '/#/order/details',
    newUrl: '/#/order/new',
    editUrl: '/#/order/edit',
    lazy: true,
};

OrderListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(OrderListContainer);
