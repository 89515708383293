export default class CardBackgroundUtil {
    static colorSelector(planType) {
        switch (planType) {
            case 'ANNUAL':
                return 'annual-background';
            case 'SEMI_ANNUAL':
                return 'semi-annual-background';
            case 'QUARTERLY':
            default:
                return 'quarterly-background';
        }
    }
}
