/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-handler-names */
import {Accordion, AccordionTab} from 'primereact/accordion';
import {Card} from 'primereact/card';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from 'primereact/dropdown';
import {Ripple} from 'primereact/ripple';
import {classNames} from 'primereact/utils';
import PropTypes from 'prop-types';
import React from 'react';
import ActionButton from '../components/ActionButton';
import ActionLink from '../components/ActionLink';
import {CustomMessages} from '../components/CustomMessages';
import DivContainer from '../components/DivContainer';
import BlockUi from '../components/waitPanel/BlockUi';
import BaseContainer, {device} from './BaseContainer';
import {PrintButtonComponent} from '../report/ReportUtils';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import {UAParser} from 'ua-parser-js';

let stopExpand = false;

class BaseListContainer extends BaseContainer {
    constructor(props, service) {
        super(props);
        this.service = service;
        this.actionTemplate = this.actionTemplate.bind(this);
        this.booleanTemplate = this.booleanTemplate.bind(this);
        this.handleGoToDetail = this.handleGoToDetail.bind(this);
        this.handleGoToNew = this.handleGoToNew.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSortResponsive = this.handleSortResponsive.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.readCriteriaFromCookie = this.readCriteriaFromCookie.bind(this);
        this.readMessage = this.readMessage.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
        this.renderDataTable = this.renderDataTable.bind(this);
        this.renderCriteria = this.renderCriteria.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.prepareColumns = this.prepareColumns.bind(this);
        this.getList = this.getList.bind(this);
        this.renderView = this.renderView.bind(this);
        this.getCleanSearchCriteria = this.getCleanSearchCriteria.bind(this);
        this.getCleanSearchCriteriaPrototype = this.getCleanSearchCriteriaPrototype.bind(this);
        this.updateSearchCriteria = this.updateSearchCriteria.bind(this);
        this.updateSearchCriteriaPrototype = this.updateSearchCriteriaPrototype.bind(this);
        this.onAfterGetList = this.onAfterGetList.bind(this);
        this.handleChangeLimitObj = this.handleChangeLimitObj.bind(this);
        this.cleanSearchCriteria = this.cleanSearchCriteria.bind(this);
        this.handleOnSelectionChange = this.handleOnSelectionChange.bind(this);
        this.getPageLinkSize = this.getPageLinkSize.bind(this);
        this.updateListElements = this.updateListElements.bind(this);
        this.defaultLoading = true;
        this.dataKey = this.getDataKey();
        if (service !== undefined && service !== null) {
            this.service.setUiMethods(this.blockUi, this.unblockUi);
        }
        this.state = {
            filterActiveIndex: this.props.filterActiveIndex,
        };
        this.labelHeader = this.labelHeader.bind(this);
        this.renderPrintButton = this.renderPrintButton.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.initializeFromBackend();
        if (this.defaultLoading) {
            this.readCriteriaFromCookie();
        }
        this.readMessage();
    }

    readCriteriaFromCookie() {
        const criteria =
            this.readCookie(this.getCriteriaName()) !== undefined &&
            this.readCookie(this.getCriteriaName()) != null &&
            !this.props.hideFilters
                ? this.updateSearchCriteriaPrototype(JSON.parse(this.readCookie(this.getCriteriaName())))
                : this.getCleanSearchCriteriaPrototype();
        this.removeCookie(this.getCriteriaName());
        if (this._isMounted) {
            criteria.maxResults = criteria.maxResults ? criteria.maxResults : 10;
            this.setState(
                {
                    loading: true,
                    criteria,
                    first: criteria.firstResult,
                },
                () => {
                    this.refreshTable();
                }
            );
        }
    }

    refreshTable() {
        this.getList();
    }

    onAfterGetList(res) {}

    updateListElements(list) {
        return list;
    }
    renderPrintButton() {
        const {t} = this.props;
        return (
            <PrintButtonComponent
                reportFileNameBase={t(this.getListTitle())}
                generateReport={this.generateReport.bind(this)}
                disabled={!this.state.selectedList.length > 0}
            />
        );
    }
    generateReport() {}
    getList() {
        const {criteria} = this.state;
        this.service
            .getList(criteria)
            .then((data) => {
                if (this._isMounted) {
                    this.setState(
                        {
                            list: this.updateListElements(data.content),
                            loading: false,
                            size: data.totalElements,
                        },
                        () => this.onAfterGetList(data)
                    );
                }
            })
            .catch((err) => {
                if (this._isMounted) {
                    this.setState(
                        {
                            list: [],
                            loading: false,
                            size: 0,
                        },
                        () => {
                            this.afterGetListError();
                        }
                    );
                }
            });
    }
    afterGetListError() {
        this.showErrorMessage('Wystąpił błąd systemu. Nie udało się pobrać listy.', 10000);
    }

    getCleanSearchCriteria() {
        return {
            firstResult: 0,
            maxResults: 10,
            maxResultsObj: {value: 10},
            sortField: 'id',
            sortAsc: true,
        };
    }

    getCleanSearchCriteriaPrototype() {
        const criteria = this.getCleanSearchCriteria();
        criteria.maxResultsObj = {value: criteria.maxResults};
        return criteria;
    }

    cleanSearchCriteria() {
        if (this._isMounted) {
            const cleanCriteria = this.getCleanSearchCriteriaPrototype();
            cleanCriteria.maxResults = cleanCriteria.maxResults ? cleanCriteria.maxResults : 10;
            this.setState(
                {
                    loading: true,
                    criteria: cleanCriteria,
                    first: cleanCriteria.firstResult,
                },
                () => {
                    this.handleFormSubmit();
                }
            );
        }
    }

    updateSearchCriteria(criteria) {
        return {
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            maxResultsObj: {value: criteria.maxResults},
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    updateSearchCriteriaPrototype(criteria) {
        criteria = this.updateSearchCriteria(criteria);
        criteria.maxResultsObj = {value: criteria.maxResults};
        return criteria;
    }

    getCriteriaName() {
        return 'list-sc';
    }

    initializeFromBackend() {}

    handleSort(event) {
        if (this._isMounted) {
            this.setState(
                (prevState) => ({
                    loading: this.props.lazy,
                    criteria: {
                        ...prevState.criteria,
                        sortField: event.sortField,
                        sortAsc: event.sortOrder > 0,
                    },
                }),
                () => (this.props.lazy ? this.refreshTable() : null)
            );
        }
    }
    handleSortResponsive(sortField, sortAsc) {
        if (this._isMounted) {
            this.setState(
                (prevState) => ({
                    loading: this.props.lazy,
                    criteria: {
                        ...prevState.criteria,
                        sortField,
                        sortAsc,
                    },
                }),
                () => (this.props.lazy ? this.refreshTable() : null)
            );
        }
    }

    handlePage(event) {
        if (this._isMounted) {
            this.setState(
                (prevState) => ({
                    loading: this.props.lazy,
                    first: event.first,
                    criteria: {
                        ...prevState.criteria,
                        firstResult: event.first,
                        maxResults: event.rows,
                        maxResultsObj: {value: event.rows},
                    },
                }),
                () => (this.props.lazy ? this.refreshTable() : null)
            );
        }
    }

    handleValidForm() {
        this.setState(
            (prevState) => ({
                blocking: false,
                loading: true,
                first: 0,
                criteria: {
                    ...prevState.criteria,
                    firstResult: 0,
                },
            }),
            () => {
                if (this.props.lazy) {
                    this.refreshTable();
                }
            }
        );
    }

    getContainerListName() {
        return 'list-container';
    }

    readMessage() {
        const messageFromStorage = this.readCookie(this.getContainerListName());
        if (messageFromStorage !== undefined && messageFromStorage != null) {
            const message = JSON.parse(messageFromStorage);
            this.removeCookie(this.getContainerListName());
            if (
                message.severity !== undefined &&
                message.severity != null &&
                message.summary !== undefined &&
                message.summary != null &&
                message.detail !== undefined &&
                message.detail != null
            ) {
                this.showMessage(message.severity, message.summary, message.detail);
            }
        }
    }

    handleGoToDetail(href, e) {
        e.preventDefault();
        this.blockUi();
        const {criteria} = this.state;
        if (!this.props.hideFilters) {
            this.saveCookie(this.getCriteriaName(), JSON.stringify(criteria));
        }
        window.location.href = AppPrefixUtils.locationHrefUrl(href);
    }

    handleGoToNew(e) {
        e.preventDefault();
        this.blockUi();
        const {newUrl} = this.props;
        const {criteria} = this.state;
        if (!this.props.hideFilters) {
            this.saveCookie(this.getCriteriaName(), JSON.stringify(criteria));
        }
        window.location.href = AppPrefixUtils.locationHrefUrl(newUrl);
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <ActionLink
                handleClick={this.handleGoToDetail.bind(this, href)}
                variant='blue'
                className='p-link float-right'
                key={'view-button'}
                iconName='mdi-eye'
                iconSide='right'
                iconSize='xs'
                iconColor='blue'
            />
        );
    }

    booleanTemplate(field, rowData) {
        if (rowData && rowData[field] !== null && rowData[field] !== undefined && rowData[field] === true) {
            return 'TAK';
        } else return 'NIE';
    }

    addButton() {
        const {newUrl} = this.props;
        return <ActionButton label={'Dodaj'} handleClick={this.handleGoToDetail.bind(this, newUrl)} key={'add-button'} />;
    }

    handleChangeLimitObj(v) {
        this.setState(
            (prevState) => ({
                first: 0,
                criteria: {
                    ...prevState.criteria,
                    firstResult: 0,
                    maxResults: v.value,
                    maxResultsObj: {value: v.value},
                },
            }),
            () => (this.props.lazy ? this.refreshTable() : null)
        );
    }

    handleOnSelectionChange(e) {
        this.setState({
            selectedList: e.value,
        });
    }
    getListTitle() {
        return 'list.title';
    }
    getPageLinkSize() {
        let totalPages = this.state.size / this.state.criteria.maxResults;
        let currentPage = this.state.first / this.state.criteria.maxResults;
        if (totalPages <= 5) {
            return 5;
        } else if (totalPages === 6 || currentPage < 3 || currentPage >= totalPages - 3) {
            return 6;
        } else {
            return 7;
        }
    }
    getDataKey() {
        return 'id';
    }

    prepareHeaderItems() {
        return [];
    }
    labelHeader(item, index) {
        return null;
    }
    globalFilterFields() {}

    sortableTemplate = (sortColumn, valueMode) => {
        console.log(sortColumn, valueMode);
        if (sortColumn) {
            return (
                <div class=''>
                    <span class=''>{sortColumn.header}</span>
                    {this.state.criteria.sortField !== sortColumn.field ? (
                        <span
                            onClick={(e) => {
                                this.handleSortResponsive(sortColumn.field, true);
                                if (e) {
                                    e.stopPropagation();
                                }
                            }}
                            class='p-sortable-column-icon pi pi-fw pi-sort-alt'
                        ></span>
                    ) : !!this.state.criteria.sortAsc ? (
                        <span
                            onClick={(e) => {
                                this.handleSortResponsive(sortColumn.field, false);
                                if (e) {
                                    e.stopPropagation();
                                }
                            }}
                            class='p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt'
                        ></span>
                    ) : (
                        <span
                            onClick={(e) => {
                                this.handleSortResponsive(sortColumn.field, true);
                                if (e) {
                                    e.stopPropagation();
                                }
                            }}
                            class='p-sortable-column-icon pi pi-fw pi-sort-amount-down'
                        ></span>
                    )}

                    <div class='p-column-filter p-fluid p-column-filter-menu'></div>
                </div>
            );
        }
        if (!!valueMode && !sortColumn) {
            return <span>Wybierz</span>;
        }
    };
    valueTemplate = (val) => {
        return this.sortableTemplate(val);
    };
    datableClassname() {
        return 'datatable-responsive base-table';
    }
    twoPagesForwardAndBackward(options) {
        return options.currentPage + 2 <= options.page || options.currentPage >= options.page + 2;
    }
    renderDataTable(columns, list, size, renderSort) {
        const {criteria, first, loading} = this.state;
        if (list === null || list === undefined) {
            list = this.state.list;
        }
        if (size === null || size === undefined) {
            size = this.state.size;
        }
        const dynamicColumns = columns.map((col) => {
            return (
                <Column
                    selectionMode={col.selectionMode}
                    key={col.key ? col.key : col.field}
                    field={col.field}
                    filter
                    filterField={col.field}
                    showFilterMenu={false}
                    header={col.header}
                    body={col.body}
                    bodyClassName={col.bodyClassName}
                    headerClassName={col.headerClassName}
                    headerStyle={col.className?.includes('table-column-action') ? {textAlign: 'center'} : undefined}
                    className={col.className}
                    sortable={col.sortable}
                    sortField={col.sortField !== undefined ? col.sortField : col.field}
                    style={col.width !== undefined ? {width: col.width} : null}
                />
            );
        });
        const sortableColumns = columns?.filter((d) => d.sortable === true);
        const rowsPerPageOptions = [5, 10, 20, 50, 100];
        return (
            <React.Fragment>
                <DivContainer colClass='row '>
                    <div className='responsive-sortfield'>
                        {renderSort !== false && (
                            <div className='row'>
                                <CriteriaDropdownComponent
                                    id={`sortField-sc-${this.state.criteria.sortField}`}
                                    name='sortFieldObj'
                                    label={'Sortuj'}
                                    colClass='col-lg-6 col-md-12'
                                    value={this.state.criteria.sortFieldObj}
                                    options={sortableColumns}
                                    placeholder='Wszystkie'
                                    onChange={this.handleChangeSc}
                                    onAfterStateChange={() =>
                                        this.handleSortResponsive(this.state.criteria.sortFieldObj.field, this.state.criteria.sortAsc)
                                    }
                                    validator={this.validator}
                                    optionLabel='header'
                                    valueTemplate={(v) => this.sortableTemplate(this.state.criteria.sortFieldObj, true)}
                                    itemTemplate={(opt) => this.sortableTemplate(opt, false)}
                                />
                            </div>
                        )}
                    </div>
                    <div className={this.datableClassname()}>
                        <DataTable
                            id='dataTable'
                            key='data-table'
                            emptyMessage={this.translate('list.empty')}
                            className='p-datatable-responsive'
                            breakpoint='10px'
                            value={list}
                            paginator
                            globalFilterFields={this.globalFilterFields()}
                            filters={this.state.filters}
                            // filterDisplay='row'
                            rows={criteria.maxResults}
                            totalRecords={size}
                            first={first}
                            rowsPerPageOptions={rowsPerPageOptions}
                            sortField={criteria.sortField}
                            sortOrder={criteria.sortAsc ? 1 : -1}
                            loading={loading}
                            paginatorPosition='bottom'
                            currentPageReportTemplate={
                                size !== 0
                                    ? `Pozycje od ${first + 1} do ${
                                          first + criteria.maxResults > size ? size : first + criteria.maxResults
                                      } z ${size} łącznie`
                                    : '0 pozycji'
                            }
                            paginatorTemplate={{
                                layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
                                PrevPageLink: (options) => {
                                    if (criteria.firstResult === 0) {
                                        return null;
                                    } else {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={options.onClick}
                                                disabled={options.disabled}
                                            >
                                                <span className='p-paginator-icon pi pi-angle-left'></span>
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                                NextPageLink: (options) => {
                                    if (criteria.firstResult + criteria.maxResults >= size) {
                                        return null;
                                    } else {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={options.onClick}
                                                disabled={options.disabled}
                                            >
                                                <span className='p-paginator-icon pi pi-angle-right'></span>
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                                RowsPerPageDropdown: (options) => {
                                    return this.renderPagination(rowsPerPageOptions);
                                },
                                CurrentPageReport: (options) => {
                                    return <div className='p-paginator-dummy' />;
                                },
                                PageLinks: (options) => {
                                    if (device.type === 'mobile' && this.twoPagesForwardAndBackward(options)) {
                                        return;
                                    }
                                    if (options.view.startPage === options.page && options.view.startPage !== 0) {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={() => {
                                                    this.handlePage({first: 0, rows: this.state.criteria.maxResults});
                                                }}
                                            >
                                                {1}
                                                <Ripple />
                                            </button>
                                        );
                                    } else if (options.view.endPage === options.page && options.view.endPage !== options.totalPages - 1) {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={() => {
                                                    this.handlePage({
                                                        first: (options.totalPages - 1) * this.state.criteria.maxResults,
                                                        rows: this.state.criteria.maxResults,
                                                    });
                                                }}
                                            >
                                                {options.totalPages}
                                                <Ripple />
                                            </button>
                                        );
                                    } else if (
                                        (options.view.startPage + 1 === options.page && options.view.startPage !== 0) ||
                                        (options.view.endPage - 1 === options.page &&
                                            options.view.endPage !== options.totalPages - 1 &&
                                            options.page !== options.totalPages - 1)
                                    ) {
                                        const className = classNames(options.className, {'p-disabled': true});
                                        return (
                                            <span className={className} style={{userSelect: 'none'}}>
                                                ...
                                            </span>
                                        );
                                    } else {
                                        return (
                                            <button type='button' className={`${options.className}`} onClick={options.onClick}>
                                                {options.page + 1}
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                            }}
                            pageLinkSize={this.getPageLinkSize()}
                            lazy={this.props.lazy}
                            onPage={this.handlePage}
                            onSort={this.handleSort}
                            selectionMode={'checkbox'}
                            onSelectionChange={this.handleOnSelectionChange}
                            selection={this.state.selectedList}
                            dataKey={this.dataKey}
                            expandedRows={this.state.expandedRows}
                            onRowToggle={this.handleOnRowToggle}
                            onRowExpand={this.handleOnRowExpand}
                            onRowCollapse={this.handleOnRowCollapse}
                            rowExpansionTemplate={this.rowExpansionTemplate}
                            rowClassName={this.props.rowClassName}
                        >
                            {this.props.rowExpansionTemplate ? <Column expander headerStyle={{width: '3em'}}></Column> : null}
                            {this.props.disableSelection === true || !this.props.selectionMode ? null : (
                                <Column selectionMode={this.props.selectionMode} headerStyle={{width: '3em'}}></Column>
                            )}
                            {dynamicColumns}
                        </DataTable>
                    </div>
                </DivContainer>
            </React.Fragment>
        );
    }

    renderPagination(rowsPerPageOptions) {
        return (
            <div className='p-paginator-rows-per-page'>
                <Dropdown
                    aria-label={'Pokaż na stronie'}
                    key={'maxResults'}
                    id={'maxResults'}
                    inputId={'maxResults-input'}
                    name={'maxResults'}
                    onMouseDown={(e) => {
                        const {device} = UAParser(navigator.userAgent);
                        if (device.type === 'mobile') {
                            setTimeout(function () {
                                if (Array.from(document.getElementsByClassName('p-dropdown-items')).find((el) => el.role === 'listbox')) {
                                    const maxResults = document.getElementById('maxResults');
                                    const dropDown = document.getElementsByClassName('p-dropdown-panel')[0];
                                    dropDown.style.position = 'absolute';
                                    dropDown.style.top = maxResults.offsetTop + 50 + 'px';
                                }
                            }, 0);
                        }
                    }}
                    //style={{ width: '100%' }}
                    value={this.state.criteria.maxResults}
                    options={rowsPerPageOptions}
                    onChange={(e) => {
                        this.setState(
                            (prevState) => ({
                                criteria: {
                                    ...prevState.criteria,
                                    maxResults: e.target.value,
                                },
                            }),
                            () => this.handleChangeLimitObj(e)
                        );
                    }}
                    filter={false}
                    showClear={false}
                />
                <label id={'limit-label-id'} className='p-paginator-rows-per-page-label' htmlFor={'limit-input'}>
                    {this.translate('list.paginator')}
                </label>
            </div>
        );
    }

    prepareColumns() {
        return [];
    }

    renderCriteria() {
        return <DivContainer colClass='row' />;
    }

    onKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleFormSubmit();
        }
    }
    iconCriteriaSelector() {
        if (this.isLexyPortal()) {
            return AppPrefixUtils.locationHrefUrl(`/images/lexy/ico_lexy_filter.svg`);
        } else {
            const {filterActiveIndex} = this.state;
            return (filterActiveIndex === undefined || filterActiveIndex === 1 ? 0 : 1)
                ? AppPrefixUtils.locationHrefUrl(`/images/lexdocs/criteria_blue.svg`)
                : AppPrefixUtils.locationHrefUrl(`/images/lexdocs/criteria_white.svg`);
        }
    }
    changeFilterLabelColor() {
        const COLOR_TO_ADD = 'blue-color';
        if (!this.isLexyPortal()) {
            const filterBtn = document.getElementsByClassName('filter-btn');
            if (filterBtn) {
                const child = filterBtn[0].children[0];
                if (child.classList.contains(COLOR_TO_ADD)) {
                    child.classList.remove(COLOR_TO_ADD);
                } else {
                    child.classList.add(COLOR_TO_ADD);
                }
            }
        }
    }
    renderFilter() {
        return !this.props.hideFilters ? (
            <Accordion
                className='filter-accordion'
                onTabChange={() => {
                    const filterActiveIndex = this.state.filterActiveIndex === undefined || this.state.filterActiveIndex === 1 ? 0 : 1;
                    if (stopExpand) {
                        stopExpand = false;
                    } else {
                        this.setState({
                            filterActiveIndex,
                        });
                        this.changeFilterLabelColor();
                    }
                }}
                activeIndex={this.state.filterActiveIndex}
            >
                <AccordionTab
                    header={
                        <React.Fragment>
                            {!this.isLexyPortal() && (
                                <React.Fragment>
                                    <div className='right'>
                                        <ActionButton
                                            className='filter-btn'
                                            label={this.translate('list.filterAction')}
                                            handleClick={() => {
                                                stopExpand = true;
                                                if(this.props.customBlockUi){
                                                    this.props.customBlockUi();
                                                }
                                                this.handleFormSubmit();
                                            }}
                                        />
                                    </div>{' '}
                                </React.Fragment>
                            )}
                            <img className='card-icon' alt={this.translate('list.filter')} src={this.iconCriteriaSelector()} />
                            {this.isLexyPortal() && <span>{this.translate('list.filter')}</span>}
                            {!this.isLexyPortal() && (
                                <React.Fragment>
                                    <div className='right'>
                                        <ActionButton
                                            className='filter-btn'
                                            label={this.translate('list.clearFilters')}
                                            handleClick={() => {
                                                stopExpand = true;
                                                if(this.props.customBlockUi){
                                                    this.props.customBlockUi();
                                                }
                                                this.cleanSearchCriteria();
                                            }}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    }
                >
                    <form
                        className='form-search-criteria'
                        onSubmit={(e) => {
                            this.handleFormSubmit(e);
                        }}
                        onKeyDown={(e) => this.onKeyDown(e)}
                        //avoid setting red border just after the page has loaded (red border because of undefined required value in FF)
                        noValidate
                    >
                        <DivContainer colClass='col-12'>
                            {this.renderCriteria()}
                            {this.isLexyPortal() && (
                                <DivContainer colClass='row template-list-buttons'>
                                    <div className='right'>
                                        <ActionButton
                                            className='cancel-button gray'
                                            label={this.translate('list.clearFilters')}
                                            handleClick={this.cleanSearchCriteria}
                                        />
                                        <ActionButton
                                            className='button editButton white'
                                            label={this.translate('list.filterAction')}
                                            handleClick={this.handleFormSubmit}
                                        />
                                    </div>
                                </DivContainer>
                            )}
                        </DivContainer>
                    </form>
                </AccordionTab>
            </Accordion>
        ) : null;
    }
    renderView() {
        return (
            <DivContainer colClass=''>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} rendered={!this.props.embedded} />
                <BlockUi
                    tag='div'
                    blocking={this.state.blocking || this.state.loading}
                    loader={this.loader}
                    rendered={!this.props.embedded}
                >
                    <Card
                        className='list-card base-card'
                        // header={this.renderHeader()}
                    >
                        <DivContainer colClass='row'>{this.renderFilter()}</DivContainer>
                        <DivContainer colClass='row'>{this.renderHeader()}</DivContainer>
                        {this.renderDataTable(this.prepareColumns())}
                    </Card>
                </BlockUi>
            </DivContainer>
        );
    }
}

BaseListContainer.defaultProps = {
    roles: '',
    selectionMode: 'multiple',
    hideFilters: false,
    lazy: true,
    filterActiveIndex: 0,
};

BaseListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
    roles: PropTypes.string,
    hideFilters: PropTypes.bool,
    lazy: PropTypes.bool,
};

export default BaseListContainer;
