import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import SpecializationService from '../../services/SpecializationService';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import ActionLink from '../../components/ActionLink';
import SpecializationListDialog from './SpecializationListDialog';
import CurrencyUtils from '../../components/utils/CurrencyUtils';
import DialogResizer from '../../components/DialogResizer';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';

class SpecializationListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new SpecializationService());
        this.state = {
            name: '',
            list: [],
            loading: true,
            size: 0,
            dialogVisibile: false,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            specializationParameterTypeOptions: [],
        };
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.calculatedAmountChatBodyTemplate = this.calculatedAmountChatBodyTemplate.bind(this);
        this.calculatedAmountVideoChatBodyTemplate = this.calculatedAmountVideoChatBodyTemplate.bind(this);
        this.calculatedAmountMailingBodyTemplate = this.calculatedAmountMailingBodyTemplate.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    getCleanSearchCriteria() {
        return {
            active: true,
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
            name: undefined,
        };
    }

    getDataKey() {
        return 'id';
    }
    getListTitle() {
        return 'specialization.listTitle';
    }

    getCriteriaName() {
        return 'specialization-list';
    }

    getContainerListName() {
        return 'specialization-list-container';
    }

    prepareHeaderItems() {
        const {t} = this.props;
        return this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE')
            ? [
                  {
                      label: t('specialization.addSpecialization'),
                      onClick: this.handleOpenDialog,
                      type: 'BUTTON',
                      variant: 'white',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                  },
              ]
            : [];
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name'
                    name='name'
                    label='Nazwa'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.name}
                    options={this.state.specializationParameterTypeOptions}
                    placeholder='Wyszukaj...'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
            </div>
        );
    }

    handleOpenDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            dialogVisibile: true,
            dialogViewMode: 'NEW',
        });
    }

    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState(
            {
                dialogVisibile: false,
                elementId: undefined,
            },
            () => {
                DialogResizer.removeMaskIfExistOneDialog();
                if (callback) callback();
            }
        );
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    calculatedAmountChatBodyTemplate(rowData) {
        return CurrencyUtils.currency(rowData.chatFee);
    }
    calculatedAmountMailingBodyTemplate(rowData) {
        return CurrencyUtils.currency(rowData.mailingFee);
    }
    calculatedAmountVideoChatBodyTemplate(rowData) {
        return CurrencyUtils.currency(rowData.videoChatFee);
    }

    prepareColumns() {
        return [
            {field: 'id', header: 'ID', sortable: true, width: '6em'},
            {field: 'name', header: 'Nazwa', sortable: true},
            {
                field: 'videoChatFee',
                header: 'Czat wideo (cena)',
                sortable: true,
                body: this.calculatedAmountVideoChatBodyTemplate,
            },
            {
                field: 'chatFee',
                header: 'Czat (cena)',
                sortable: true,
                body: this.calculatedAmountChatBodyTemplate,
            },
            {
                field: 'mailingFee',
                header: 'Mailing (cena)',
                sortable: true,
                body: this.calculatedAmountMailingBodyTemplate,
            },
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const detailHref = `${detailUrl}/${rowData.id}`;
        return (
            <div className='float-center'>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                    onClick={this.handleGoToDetail.bind(this, detailHref)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        );
    }

    breadCrumbItems() {
        return [{name: 'Specjalizacje', url: '/#/specialization-list'}];
    }
    render() {
        return (
            <React.Fragment>
                <div className='container-fluid'>
                    {this.state.dialogVisibile ? (
                        <SpecializationListDialog
                            className='specialization-add-dialog'
                            statusOptions={this.state.statusOptions}
                            elementId={this.state.elementId}
                            onHide={(e) => {
                                this.handleCloseDialog(e);
                            }}
                            visible={this.state.dialogVisibile}
                            viewMode={this.state.dialogViewMode}
                            afterSave={(e) =>
                                this.handleCloseDialog(e, () => {
                                    this.readMessage();
                                    this.refreshTable();
                                    this.unblockUi();
                                })
                            }
                            blockUi={this.blockUi}
                            unblockUi={this.unblockUi}
                            _isMounted={this._isMounted}
                        />
                    ) : null}
                    <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                    {this.renderView()}
                </div>
            </React.Fragment>
        );
    }
}

SpecializationListContainer.defaultProps = {
    detailUrl: '/#/specialization/details',
    newUrl: '/#/specialization/new',
    editUrl: '/#/specialization/edit',
    lazy: true,
};

SpecializationListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(SpecializationListContainer);
