import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseContainer from '../baseContainers/BaseContainer';
import ActionButton from '../components/ActionButton';
import ActionLink from '../components/ActionLink';
import {CustomMessages} from '../components/CustomMessages';
import SimpleReactValidator from '../components/validator';
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';
import RegistrationService from '../services/RegistrationService';
import BlockUi from './../components/waitPanel/BlockUi';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import {Card} from 'primereact/card';
import SettingsPublicService from '../services/public/SettingsPublicService';
import RedirectWithHistory from '../components/RedirectWithHistory';

class LoginContainer extends BaseContainer {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.registrationService = new RegistrationService();
        this.handleTokenMessage = this.handleTokenMessage(props.confirmToken);
        this.authService = new AuthService();
        this.userService = new UserService();
        this.settingService = new SettingsPublicService();
        this.portalType = this.isLexyPortal() ? 'LEKSY' : 'LEX_DOCS';
        //this.jwtRefreshBlocked = true;
        this.state = {
            email: '',
            password: '',
            redirectToReferrer: true,
            authValid: true,
            showCaptcha: false,
            captchaPreventAction: false,
            invalidLoginCount: 2,
            loggedIn: false,
        };
        this.authValidValidator = new SimpleReactValidator(
            {
                validators: {
                    auth: {
                        // name the rule
                        message: ':attribute',
                        rule: (val, params, validator) => {
                            return this.state.authValid;
                        }, // optional
                        required: true,
                    },
                },
            },
            props.t
        );
    }

    handleTokenMessage(confirmToken) {
        if (confirmToken !== undefined) {
            let href = AppPrefixUtils.locationHrefUrl(window.location.href);
            var token = href.substring(href.indexOf('=') + 1);

            this.registrationService
                .getTokenInformation(token)
                .then((response) => {
                    this.showSuccessMessage('Witamy w systemie!');
                })
                .catch((err) => {
                    if (err.message === undefined || err.message == null || err.message === '') {
                        this.showSuccessMessage('Witamy w systemie!');
                    } else {
                        this.showErrorMessage(err.message, 10000, true, 'Błąd ');
                    }
                });
        }
    }

    paymentResponseMessage() {
        try {
            const urlStr = window.location.toString().replace('/#', '');
            const url = new URL(urlStr);
            const paid = url.searchParams.get('paid');
            if (paid === 'true') {
                this.showSuccessMessage(
                    'Dziękujemy za rejestrację. Jeżeli opłaciłeś pakiet, potwierdzenie rejestracji wyślemy na adres e-mail.'
                );
            }
        } catch (ex) {
            console.log('bad url');
        }
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    getContainerListName() {
        return 'login-container';
    }
    refreshJwtToken() {}
    componentDidMount() {
        super.componentDidMount();
        if (this.props.handlePreLogoutUser) {
            this.props.handlePreLogoutUser(() => {});
        }

        this.settingService
            .getInvalidLoginCount()
            .then((result) => {
                this.setState({invalidLoginCount: result.invalidLoginCount});
            })
            .catch((err) => {});
        const values = queryString.parse(this.props.location.search);
        this.targetLocation = values.location;
        this.readMessage();
        this.paymentResponseMessage();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth', // opcjonalnie, aby wykonać płynne przewijanie
        });
        //Messages.multiReadPersistMessage(['login-page'], this.messages);
    }

    readMessage() {
        const messageFromStorage = this.readCookie(this.getContainerListName());
        if (messageFromStorage !== undefined && messageFromStorage != null) {
            const message = JSON.parse(messageFromStorage);
            this.removeCookie(this.getContainerListName());
            if (
                message.severity !== undefined &&
                message.severity != null &&
                message.summary !== undefined &&
                message.summary != null &&
                message.detail !== undefined &&
                message.detail != null
            ) {
                this.showMessage(message.severity, message.summary, message.detail);
            }
        }
    }

    handleFormSubmit(e) {
        if (e !== undefined) {
            e.preventDefault();
        }
        let username = this.state.email;
        if (this.validator.allValid()) {
            this.blockUi();
            this.authService
                .login(username, this.state.password, this.portalType)
                .then(() => {
                    if (this.props.onAfterLogin) {
                        this.props.onAfterLogin();
                        this.setState({loggedIn: true});
                    }
                })
                .catch((err) => {
                    console.log('err', err);
                    if (err.status === 401 || err.status === 403) {
                        this.showErrorMessage('Błąd logowania', 10000, true, 'Błąd ');
                        // this.showErrorMessage('Błąd logowania',null, 'Błąd ');
                    } else {
                        this.showErrorMessage(err.message);
                    }
                    this.unblockUi();
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        if (!!this.state.loggedIn) {
            return this.renderAfterAuth();
        } else {
            return this.renderBeforeAuth();
        }
    }

    renderAfterAuth() {
        return (
            <RedirectWithHistory
                url={
                    this.targetLocation
                        ? this.targetLocation
                        : this.authService.isUserInAnyRole(
                              'ROLE_USER_LEKSY_MANAGE',
                              'ROLE_ADMIN_SUPER_MANAGE',
                              'ROLE_USER_LEX_DOCS_MANAGE',
                              'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                              'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                              'ROLE_ADMIN_PAYMENT_MANAGE'
                          )
                        ? '/agreement-list'
                        : '/template-list'
                }
            />
        );
    }

    renderBeforeAuth() {
        const {t} = this.props;
        return (
            <React.Fragment>
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                    <div id='main-login' className=''>
                        <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)}></CustomMessages>
                        <div className='login-background-container'>
                            <Card className='details-card no-border'>
                                <form
                                    className='login-container'
                                    onSubmit={(e) => {
                                        if (!this.state.captchaPreventAction) {
                                            this.handleFormSubmit(e);
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            if (!this.state.captchaPreventAction) {
                                                this.handleFormSubmit();
                                            }
                                        }
                                    }}
                                    noValidate
                                >
                                    <div className='p-2 mb-2'>
                                        <h2 className='title'>Zaloguj się</h2>
                                    </div>
                                    <div className='login-form-container'>
                                        <div className='login-label'>Adres Email</div>
                                        <div location='user_field'>
                                            <InputText
                                                className='login-input'
                                                aria-label={t('login.form.userName')}
                                                key={'email'}
                                                id={'email'}
                                                name={'email'}
                                                placeholder={'Wpisz...'}
                                                style={{width: '100%'}}
                                                value={this.state.email}
                                                onChange={(e) => this.handleChange('TEXT', undefined, e, undefined, '')}
                                                required={true}
                                                validator={this.validator}
                                                validators='required|max:50'
                                            />
                                            <div aria-live='assertive' className='login-form-validation'>
                                                {this.validator.message('email', t('login.form.userName'), this.state.email, 'required')}
                                            </div>
                                            <div className='login-label'>Hasło</div>
                                            <div location='pass_field'>
                                                <Password
                                                    inputClassName='login-input'
                                                    aria-label={t('login.form.password')}
                                                    key={'password'}
                                                    id={'password'}
                                                    name={'password'}
                                                    placeholder={'Wpisz hasło...'}
                                                    inputStyle={{width: '100%'}}
                                                    style={{width: '100%'}}
                                                    value={this.state.password}
                                                    onChange={(e) => this.handleChange('TEXT', undefined, e, undefined, '')}
                                                    promptLabel={t('login.form.password')}
                                                    feedback={false}
                                                    required={true}
                                                    toggleMask={true}
                                                    validator={this.authValidValidator}
                                                    validators='not_required'
                                                />
                                                <div aria-live='assertive' className='login-form-validation'>
                                                    {this.validator.message(
                                                        'password',
                                                        t('login.form.password'),
                                                        this.state.password,
                                                        'required'
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row login-links'>
                                            <ActionLink
                                                label={t('login.forget-password')}
                                                variant='forgot-password-button '
                                                href={AppPrefixUtils.locationHrefUrl('/#/forgot-password')}
                                            />
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <>
                                                    <ActionButton
                                                        label='Zarejestruj się'
                                                        colClass='col-lg-3'
                                                        className='col-lg-3'
                                                        variant='centered-button registration-button '
                                                        href={AppPrefixUtils.locationHrefUrl('/#/registration')}
                                                        history={null}
                                                    />
                                                    <ActionButton
                                                        label='Zaloguj się'
                                                        className='col-lg-3 float-right'
                                                        variant='login-button '
                                                        handleClick={this.handleFormSubmit}
                                                        disabled={this.state.captchaPreventAction}
                                                    />
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>
                </BlockUi>
            </React.Fragment>
        );
    }
}
LoginContainer.propTypes = {
    onAfterLogin: PropTypes.func,
};
export default withTranslation()(LoginContainer);
