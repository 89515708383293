export default class ConfirmExitComponentsUtils {
    static  URLS = [
    '#/template/edit/', 
    '#/specialization/edit', 
    '#/law-office/edit', 
    '#/manage-account/edit', 
    '#/sub-template/edit' , 
    '#/email-template/edit', 
    '#/email-template/create'];
}
