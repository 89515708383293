import PDFViewer from 'mgr-pdf-viewer-react/dist/mgr-pdf-viewer-react';
import PropTypes from 'prop-types';
import React from 'react';

export const PreCreateStep = (props) => {
    const {categoryName, title, description, handleCreate, price, isPaid} = props;

    const {pdfBase64} = props;
    const truncate = (input) => (input ? (input.length > 255 ? `${input.substring(0, 255)}...` : input) : '');
    React.useEffect(() => {}, [props]);

    return (
        <div id='pre-create-panel' className='container'>
            <h2 className='agreement-tile-top'>{title}</h2>
            <div id='agreement-card-detail' className='card'>
                <div id='agreement-info'>
                    <div className='row no-gutters row-pdf'>
                        <div className='col-xl-3 col-lg-4'>
                            {pdfBase64 && (
                                <div id='agreement-pdf-block'>
                                    <PDFViewer
                                        document={{
                                            base64: props.pdfBase64,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className='col-xl-9 col-lg-8 col-information'>
                            <div id='agreement-category'>{categoryName}</div>
                            <div className='agreement-description'>{truncate(description)}</div>
                            <div className='row no-gutters row-description w-50'>
                                <div id='price' className='col-6'>
                                    {!isPaid && `${price}zł`}{' '}
                                </div>
                            </div>
                            <div id='agrement-buttons' className='col-6'>
                                <button id='agreement-btn-create' className='cursor-pointer' onClick={handleCreate}>
                                    Wypełnij
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='agreement-footer-card'>
                    <h2 className='agreement-tile-top'>Opis</h2>
                    <div className='agreement-description padding-custom'>{description}</div>
                </div>
            </div>
        </div>
    );
};

PreCreateStep.defaultProps = {};

PreCreateStep.propTypes = {
    id: PropTypes.string,
    categoryName: PropTypes.string,
    handleCreate: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
};

export default PreCreateStep;
