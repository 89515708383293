import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseContainer from '../baseContainers/BaseContainer';
import ActionButton from '../components/ActionButton';
import InputPasswordComponent from '../components/inputs/InputPasswordComponent';
import SimpleReactValidator from '../components/validator';
import BlockUi from '../components/waitPanel/BlockUi';
import AuthService from '../services/AuthService';
import SettingService from '../services/SettingService';
import UserService from '../services/UserService';
import {CustomMessages} from '../components/CustomMessages';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import DialogResizer from '../components/DialogResizer';

class RemindPasswordContainer extends BaseContainer {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.authService = new AuthService();
        this.userService = new UserService();
        this.settingService = new SettingService();
        this.jwtRefreshBlocked = true;
        this.state = {
            username: '',
            password: '',
            redirectToReferrer: true,
            authValid: true,
            showCaptcha: false,
            captchaPreventAction: false,
            maxLength: 10,
            minLength: 8,
            lowerCaseCount: 1,
            upperCaseCount: 1,
            numbersCount: 1,
            specialCharactersCount: 1,
        };
        this.authValidValidator = new SimpleReactValidator(
            {
                validators: {
                    auth: {
                        // name the rule
                        message: ':attribute',
                        rule: (val, params, validator) => {
                            return this.state.authValid;
                        }, // optional
                        required: true,
                    },
                },
            },
            props.t
        );
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    refreshJwtToken() {}

    componentDidMount() {
        super.componentDidMount();
        if (this.props.handlePreLogoutUser) {
            this.props.handlePreLogoutUser(() => {});
        }
        const values = queryString.parse(this.props.location.search);
        this.targetLocation = values.location;
        this.settingService
            .getPasswordParamsPublic()
            .then((resp) => {
                this.setState(
                    {
                        maxLength: resp['PASSWORD_MAX_LENGTH'],
                        minLength: resp['PASSWORD_MIN_LENGTH'],
                        lowerCaseCount: resp['PASSWORD_LOWERCASE_COUNT'],
                        upperCaseCount: resp['PASSWORD_UPPERCASE_COUNT'],
                        numbersCount: resp['PASSWORD_NUMBERS_COUNT'],
                        specialCharactersCount: resp['PASSWORD_SPECIAL_CHARS_COUNT'],
                    },
                    () => this.unblockUi()
                );
            })
            .catch((err) => this.unblockUi());
    }
    persistMessage(severity, summary, detail) {
        this.saveCookie(
            'login-container',
            JSON.stringify({
                severity,
                summary,
                detail,
            })
        );
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }
    handleFormSubmit(e) {
        e.preventDefault();
        const {t} = this.props;
        if (this.authValidValidator.allValid()) {
            this.blockUi();
            this.userService
                .verifyTokenAndSendNewPassword(this.props?.match?.params?.token, {newPassword: this.state.newPassword})
                .then((res) => {
                    DialogResizer.removeMaskIfExistOneDialog();
                    this.blockUi();
                    this.persistMessage('success', '', t('password.setPasswordSuccess'));
                    window.location.href = AppPrefixUtils.locationHrefUrl('/#/');
                })
                .catch((err) => {
                    this.unblockUi();
                    this.showErrorMessage(t('error.setPasswordError'));
                });
        } else {
            this.authValidValidator.showMessages();
            // rerender to show messages for the first time
            this.setState({
                valid: false,
            });
            this.forceUpdate();
        }
    }

    render() {
        const {t} = this.props;
        return (
            <React.Fragment>
                <BlockUi
                    tag='div'
                    blocking={this.state.blocking || this.state.loading}
                    loader={this.loader}
                    rendered={!this.props.embedded}
                >
                    <div id='main-login'>
                        <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} rendered={!this.props.embedded} />
                        <div className='login-background-container'>
                            <form
                                className='login-container'
                                onSubmit={(e) => {
                                    if (!this.state.captchaPreventAction) {
                                        this.handleFormSubmit(e);
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        if (!this.state.captchaPreventAction) {
                                            this.handleFormSubmit();
                                        }
                                    }
                                }}
                                //avoid setting red border just after the page has loaded (red border because of undefined required value in FF)
                                noValidate
                            >
                                <div className='login-welcome-text-container'>
                                    <span className='login-welcome-text'>{t('password.setPasswordInstruction')}</span>
                                </div>
                                <div className='login-form-container'>
                                    {' '}
                                    <InputPasswordComponent
                                        id='newPassword'
                                        // eslint-disable-next-line max-len
                                        label={t('password.newPassword')}
                                        name='newPassword'
                                        value={this.state.newPassword}
                                        validator={this.authValidValidator}
                                        onChange={(a, b, c) => this.handleChange(c)}
                                        viewMode='EDIT'
                                        validators={`required|extended_password:${this.state.minLength}:${this.state.maxLength}:${this.state.lowerCaseCount}:${this.state.upperCaseCount}:${this.state.numbersCount}:${this.state.specialCharactersCount}`}
                                        colClass='col-12 '
                                        stateField=''
                                    />
                                    <InputPasswordComponent
                                        id='repeatPassword'
                                        label={t('password.repeatNewPassword')}
                                        name='repeatPassword'
                                        value={this.state.repeatPassword}
                                        validator={this.authValidValidator}
                                        onChange={(a, b, c) => this.handleChange(c)}
                                        viewMode='EDIT'
                                        validators={`required|equals:${this.state.newPassword},Nowe hasło`}
                                        colClass='col-12'
                                        stateField=''
                                        feedback={false}
                                    />
                                    <ActionButton
                                        label={t('password.send')}
                                        variant='login-button set-password-button blue'
                                        handleClick={this.handleFormSubmit}
                                        disabled={this.state.captchaPreventAction}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </BlockUi>
            </React.Fragment>
        );
    }
}
RemindPasswordContainer.propTypes = {
    onAfterLogin: PropTypes.func,
};
export default withTranslation()(RemindPasswordContainer);
