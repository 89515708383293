import BaseServiceCrud from './BaseServiceCrud';

export default class OrderService extends BaseServiceCrud {
    constructor() {
        super();
        this.path = 'orders';
    }

    getCriteria(criteria) {
        return this.objToQueryString({
            id: criteria.id,
            first_result: criteria.firstResult,
            max_results: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            name: criteria.name,
        });
    }

    paymentForOrder(orderId) {
        return this.fetch(`${this.domain}/${this.path}/payment-for-order/${orderId}`, {
            method: 'POST',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    isOrderPaid(uuid) {
        return this.fetch(`${this.domain}/${this.path}/is-order-paid?uuid=${uuid}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
