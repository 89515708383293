import PropTypes from 'prop-types';
import React from 'react';
import {Card} from 'primereact/card';
import SubscriptionModel from '../model/SubscriptionModel';
import {t} from 'i18next';
import ActionButton from '../components/ActionButton';
import SubscriptionService from '../services/SubscriptionService';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import TemplatesTableDialog from './TemplatesTableDialog';
import TypesModel from '../model/TypesModel';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import DialogResizer from '../components/DialogResizer';
import {ToggleButton} from 'primereact/togglebutton';
import StringUtils from '../utils/StringUtils';
import CardBackgroundUtil from '../utils/CardBackgroundUtil';

class PackageContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props);
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.state = {
            selectedPackagesMap: new Map(),
            billingCycleMap: new Map(),
            dialogVisibile: false,
            packageId: undefined,
            addButton: this.props.addButton,
        };
        this.service = this.props.service;
        this.handleChangePackage = this.handleChangePackage.bind(this);
        this.showDialog = this.showDialog.bind(this);
        this.handleUncheckPackage = this.handleUncheckPackage.bind(this);
        this.backLinkRendered = false;
        this.handleChoosePackage = this.handleChoosePackage.bind(this);
    }

    addButton() {
        return this.props?.addButton ? (
            <React.Fragment>
                <hr className='line-footer' />
                <div className='parent-with-centered-item'>
                    <ActionButton
                        className={this.props.addButton.className}
                        label={this.props.addButton.label}
                        handleClick={() => {
                            this.handleChangePackage();
                        }}
                    />
                </div>
            </React.Fragment>
        ) : null;
    }

    renderHeaderCard(pack) {
        return (
            <div className=''>
                <div className='package-type'>
                    <img src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/package_logo.svg`)} height={100} alt='' />
                    <div className=''>
                        <div>
                            <label>{`${pack.name}`}</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    showDialog(packageId) {
        this.setState({
            dialogVisibile: true,
            packageId: packageId,
        });
    }
    prepareHeaderItems() {
        const {changePackageUrl} = this.props;

        return [
            {
                label: 'Zmień pakiet',
                rendered: this.props.viewMode === 'VIEW',
                onClick: () => (window.location.href = AppPrefixUtils.locationHrefUrl(`${changePackageUrl}`)),
            },
        ];
    }
    renderSubsriptionCard(billingCycleMap, pack, index, isChosenPackage) {
        return (
            <div className={''}>
                {this.props.viewMode === 'EDIT' ? (
                    <div className='row'>
                        <div className='col-lg-12 col-sm-12'>
                            <div className='row'>
                                <div className='col-lg-12 billing-information-dropdown'>
                                    <InputDropdownComponent
                                        id={`${index}-billing-info`}
                                        name='billing'
                                        label={''}
                                        colClass='col-2'
                                        insideTable
                                        placeholder='Cykl rozliczeniowy'
                                        validator={this.validator}
                                        value={billingCycleMap?.get(index)}
                                        options={pack.types}
                                        onChange={(a, b, e) => {
                                            billingCycleMap.set(index, e.target.value);
                                            this.handleUncheckPackage(billingCycleMap?.get(index));
                                            this.forceUpdate();
                                            setTimeout(function () {
                                                DialogResizer.recalculateDialog();
                                            }, 1);
                                        }}
                                        viewMode={this.props.viewMode}
                                        optionLabel='label'
                                        dataKey='label'
                                    />
                                </div>
                                <div className='col-lg-12'>{this.renderBillingInformation(billingCycleMap, index)}</div>
                                <div className='text-center '>
                                    <ToggleButton
                                        className='mt-2 w-100 toggler-package'
                                        onLabel='Wybieram'
                                        offLabel='Wybieram'
                                        checked={isChosenPackage ? this.state.selectedPackagesMap.get(pack.id).selected : false}
                                        onChange={(e) => this.handleChoosePackage(billingCycleMap.get(index), pack, e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {this.props.viewMode === 'VIEW'
                    ? pack.types.map((p) => {
                          return this.renderBillingInformations(p);
                      })
                    : null}
            </div>
        );
    }

    initBeforeSetElement() {
        let packages = this.props.packages;
        let types = packages.map((pack) => pack.types);
        let {billingCycleMap} = this.state;
        let selectedPackagesMap = this.state.selectedPackagesMap;

        for (let x = 0; x < types.length; x++) {
            for (let y = 0; y < types[x].length; y++) {
                if (types[x][y].selected) {
                    billingCycleMap.set(x, types[x][y]);
                    const subscription = this.createSubscriptionModel(types[x][y], packages[x], true);
                    selectedPackagesMap.set(subscription.id, subscription);
                }
            }
        }
        this.forceUpdate();
    }

    createSubscriptionModel(type, pack, isSelected) {
        let subscriptionModel = SubscriptionModel.copy(pack);
        subscriptionModel.type = type;
        subscriptionModel.selected = isSelected;
        subscriptionModel.visible = pack.visible;
        subscriptionModel.packageType = pack.packageType;
        return subscriptionModel;
    }

    handleChoosePackage(type, subscription, e) {
        if (type === undefined) {
            this.showErrorMessage('Proszę wybrać cykl rozliczeniowy dla danego pakietu!');
        } else {
            let subscriptionModel = this.createSubscriptionModel(type, subscription, e.value);

            if (subscriptionModel.packageType === 'PREMIUM') {
                this.state.selectedPackagesMap.clear();
            } else {
                let premiumExist = [...this.state.selectedPackagesMap].find(([id, value]) => value.packageType === 'PREMIUM');
                if (premiumExist !== undefined) {
                    this.state.selectedPackagesMap.delete(premiumExist[1].id);
                }
            }
            if (e.value) {
                this.state.selectedPackagesMap.set(subscriptionModel.id, subscriptionModel);
            } else {
                this.state.selectedPackagesMap.delete(subscriptionModel.id);
            }
        }
        this.forceUpdate();
    }

    handleUncheckPackage(type) {
        let subscriptionId = type.subscription.id;
        this.state.selectedPackagesMap.delete(subscriptionId);
        this.forceUpdate();
    }

    renderFooter() {
        let onBackClickEvent = undefined;
        if (this.props?.footerItems?.length === 2) {
            onBackClickEvent = this.props.footerItems[0].onClick;
        }
        const packageFooterStyle = window.location.href.includes('users-list') && 'custom-package-footer';
        const result = window.location.href.includes('users-list') || window.location.href.includes('/change-package');
        return result ? (
            <div className={`row lexdocs-border-tab ${packageFooterStyle}`}>
                <div className='col-6'>
                    <button className='lexdocs-classic-btn ' onClick={onBackClickEvent}>
                        <img src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/chevron_left.svg`)} alt='' className='cursor-pointer ' />
                        Wstecz
                    </button>
                </div>
                <div className='col-6 float-right'>
                    <button
                        className='lexdocs-classic-btn '
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleChangePackage();
                        }}
                    >
                        <img
                            src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/chevron_right.svg`)}
                            alt=''
                            style={{marginTop: '2px'}}
                            className='cursor-pointer '
                        />
                        {window.location.href.includes('/change-package') ? 'Wybierz pakiet' : 'Utwórz użytkownika'}
                    </button>
                </div>
            </div>
        ) : null;
    }

    translate(key) {
        return t(key);
    }

    handleChangePackage() {
        this.props.handleChangePackage(this, this.state.selectedPackagesMap);
    }

    renderBillingInformation(billingCycleMap, index) {
        return billingCycleMap.get(index) !== undefined ? (
            <span className=''>{this.renderBillingInputs(billingCycleMap.get(index))}</span>
        ) : null;
    }

    renderBillingInformations(type) {
        return (
            <span>
                <span>{this.renderBillingInputs(type)}</span>
            </span>
        );
    }

    renderBillingInputs(subscription) {
        return (
            <div className='col-lg-12 col-sm-12'>
                <div id={'billing-information'}>
                    {subscription.consultation === 0 || StringUtils.isBlank(subscription.consultation) ? null : (
                        <label className='billing-information-label col-lg-12'>Liczba konsultacji: {subscription.consultation}</label>
                    )}
                    <label className='billing-information-label col-lg-12'>Cena pakietu: {subscription.price}zł</label>
                </div>
            </div>
        );
    }
    renderSubscriptions() {
        const packages = this.props.packages.filter((p) => p.visible);
        return packages.map((pack, index) => {
            this.transformPack(pack);
            let {billingCycleMap} = this.state;
            const isChosen = !!this.state.selectedPackagesMap.get(pack.id)?.selected;
            const background = CardBackgroundUtil.colorSelector(pack.planType);
            const descToHtml = {__html: pack.description};
            return (
                <div className='col-xl-4 col-lg-8 col-md-6 col-sm-12 d-flex justify-content-center '>
                    <Card
                        key={`package-card-${index}`}
                        header={<div style={{paddingLeft: '0px'}}>{this.renderHeaderCard(pack)}</div>}
                        title={''}
                        style={{padding: '0px'}}
                        subTitle={<div className={this.props.paddingForDetailsAndTemplateBtn} dangerouslySetInnerHTML={descToHtml}></div>}
                        className={`package-card ${background}  ${isChosen ? 'chosen' : 'not-chosen'} ${this.cardSize(packages.length)}`}
                        footer={() => (
                            <div className={`${background}`}>
                                <div className={`${this.props.paddingForDetailsAndTemplateBtn} available-template text-center `}>
                                    <ActionButton
                                        label='Dostępne szablony'
                                        rendered={this.props.showTemplates}
                                        handleClick={this.showDialog.bind(this, pack.id)}
                                    />
                                </div>
                            </div>
                        )}
                    >
                        {this.renderSubsriptionCard(billingCycleMap, pack, index, isChosen)}
                    </Card>
                </div>
            );
        });
    }

    cardSize() {
        return 'col-12';
    }

    transformPack(pack) {
        if (pack) {
            for (let index = 0; index < pack.types.length; index++) {
                let transformPack = TypesModel.copy(pack?.types[index]);
                switch (transformPack.enumValue) {
                    case 'MONTHLY':
                        transformPack.label = 'Miesięczny';
                        break;
                    case 'QUARTERLY':
                        transformPack.label = 'Kwartalny';
                        break;
                    default:
                        transformPack.label = 'Roczny';
                }
                pack.types[index] = transformPack;
            }
        }
        return pack;
    }

    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: false, packageId: undefined}, () => {
            if (callback) callback();
        });
    }

    render() {
        return this.renderView();
    }

    renderCard() {
        return (
            <Card className='details-card packages-card' footer={this.renderFooter()} header={this.renderHeader()}>
                <form onSubmit={this.handleFormSubmit} noValidate>
                    {this.renderDetails()}
                </form>
            </Card>
        );
    }
    renderDetails() {
        return (
            <React.Fragment>
                <div className=' row p-2 ' style={{justifyContent: 'space-evenly'}}>
                    {this.renderSubscriptions()}
                </div>
                {this.state.dialogVisibile ? (
                    <TemplatesTableDialog
                        elementId={this.state.packageId}
                        onHide={(e) => {
                            this.handleCloseDialog(e);
                            DialogResizer.removeMaskIfExistOneDialog();
                            setTimeout(function () {
                                DialogResizer.recalculateMask();
                            }, 1);
                        }}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogVisibile}
                        service={this.props.service}
                        afterSave={(e) =>
                            this.handleCloseDialog(e, () => {
                                this.readMessage();
                                this.refreshTable();
                                this.unblockUi();
                            })
                        }
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}
                {this.addButton()}
            </React.Fragment>
        );
    }
}

PackageContainer.defaultProps = {
    offerVisible: false,
    changeVisible: false,
    userVisible: false,
    changeView: false,
    showTemplates: false,
    startupCol: 'col-lg-8',
    paddingForDetailsAndTemplateBtn: '',
    service: new SubscriptionService(),
};

PackageContainer.propTypes = {
    offerVisible: PropTypes.bool,
    changeVisible: PropTypes.bool,
    changeView: PropTypes.bool,
    showTemplates: PropTypes.bool,
    userVisible: PropTypes.bool,
    handleChangePackage: PropTypes.func,
    packages: PropTypes.array,
    startupCol: PropTypes.string,
    paddingForDetailsAndTemplateBtn: PropTypes.string,
};

export default PackageContainer;
