class SessionUtils {
    static saveSession(itemName, object) {
        sessionStorage.setItem(itemName, JSON.stringify(object));
    }
    static readSession(itemName) {
        return JSON.parse(sessionStorage.getItem(itemName));
    }
    static removeSession(itemName) {
        sessionStorage.removeItem(itemName);
    }
}

export default SessionUtils;
