/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import MenuItemIcon from './MenuItemIcon';
import {withTranslation} from 'react-i18next';
import AppPrefixUtils from '../utils/AppPrefixUtils';

export const ActionButton = (props) => {
    const {
        className,
        colClass,
        disabled,
        downloadFile,
        handleClick,
        href,
        iconColor,
        iconName,
        iconSide,
        iconSize,
        iconSvg,
        id,
        inputLikeStyle,
        label,
        openInNewTab,
        buttonType,
        params,
        rendered,
        renderLabel,
        size,
        title,
        variant,
        description,
        descriptionClassName,
        showDescription,
    } = props;
    let ariaLabel = '';
    //todo translate
    if (openInNewTab) {
        ariaLabel = 'kliknięcie linku spowoduje otworzenie nowej karty w przeglądarce';
    }
    if (downloadFile) {
        if (ariaLabel) {
            ariaLabel = `${ariaLabel} kliknięcie linku spowoduje pobranie pliku`;
        } else {
            ariaLabel = 'kliknięcie linku spowoduje pobranie pliku';
        }
    }
    let aligment = undefined;
    let renderIconLeft = false;
    let renderIconRight = false;
    if (buttonType === 'NEXT') {
        aligment = 'float-right';
        renderIconRight = true;
    }
    if (buttonType === 'PREV') {
        renderIconLeft = true;
    }
    if (buttonType === 'ADD') {
        aligment = 'float-right';
    }
    if (rendered) {
        if (inputLikeStyle) {
            return (
                <div className={colClass}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <label className='p-label' style={{width: '100%', margin: '0px'}}>
                                {''}
                            </label>
                            <a
                                title={`${title ? title : ''}${ariaLabel}`}
                                tabIndex='0'
                                className={`p-button p-component p-button-text-only ${className !== undefined ? className : ''} ${
                                    variant !== undefined ? variant : ''
                                } ${size !== undefined ? size : ''} ${disabled ? 'p-disabled disabled' : ''}`}
                                href={disabled ? undefined : href ? href : undefined}
                                onClick={(e) => (disabled || !handleClick ? false : handleClick(e, params))}
                                id={id}
                                key={id === undefined ? `actionButton-${label}` : id}
                                target={openInNewTab ? '_blank' : undefined}
                                rel='noopener noreferrer'
                            >
                                {renderLabel ? (
                                    renderLabel()
                                ) : (
                                    <span
                                        className={`${iconName !== undefined ? 'icon_text' : ''} p-button-text p-c ${
                                            iconName !== undefined ? iconColor : ''
                                        }`}
                                    >
                                        {iconSide === 'left' && iconName !== undefined ? (
                                            <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                                        ) : null}
                                        {iconSide === 'left' && iconSvg !== undefined ? (
                                            <MenuItemIcon alt='' className={`icon-svg ${iconSize}`} iconSvg={iconSvg} />
                                        ) : null}
                                        {label}
                                        {iconSide === 'right' && iconName !== undefined ? (
                                            <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                                        ) : null}
                                        {iconSide === 'right' && iconSvg !== undefined ? (
                                            <MenuItemIcon alt='' className={`icon-svg ${iconSize}`} iconSvg={iconSvg} />
                                        ) : null}
                                    </span>
                                )}
                                {showDescription && description && description !== '' ? (
                                    <label htmlFor={`${id}-input-description`} className={descriptionClassName}>
                                        {description}
                                    </label>
                                ) : null}
                            </a>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <a
                        title={`${title ? title : ''}${ariaLabel}`}
                        tabIndex='0'
                        className={`p-button p-component p-button-text-only ${className !== undefined ? className : ''} ${
                            variant !== undefined ? variant : ''
                        } ${size !== undefined ? size : ''} ${disabled ? 'p-disabled disabled' : ''} ${aligment}`}
                        href={disabled ? undefined : href ? href : undefined}
                        onClick={(e) => (disabled || !handleClick ? false : handleClick(e, params))}
                        id={id}
                        key={id === undefined ? `actionButton-${label}` : id}
                        target={openInNewTab ? '_blank' : undefined}
                        rel='noopener noreferrer'
                    >
                        {buttonType === 'PREV' && <img src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/chevron_left.svg`)} alt='' />}
                        {renderLabel ? (
                            renderLabel()
                        ) : (
                            <span
                                className={`${iconName !== undefined ? 'icon_text' : ''}  p-button-text p-c ${
                                    iconName !== undefined ? iconColor : ''
                                }`}
                            >
                                {iconSide === 'left' && iconName !== undefined ? (
                                    <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                                ) : null}
                                {iconSide === 'left' && iconSvg !== undefined ? (
                                    <MenuItemIcon alt='' className={`icon-svg ${iconSize} ${iconSide}`} iconSvg={iconSvg} />
                                ) : null}
                                {label}
                                {iconSide === 'right' && iconName !== undefined ? (
                                    <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                                ) : null}
                                {iconSide === 'right' && iconSvg !== undefined ? (
                                    <MenuItemIcon alt='' className={`icon-svg ${iconSize} ${iconSide}`} iconSvg={iconSvg} />
                                ) : null}
                            </span>
                        )}
                        {showDescription && description && description !== '' ? (
                            <label key={`label-${description}`} htmlFor={`${id}-input-${description}`} className={descriptionClassName}>
                                {description}
                            </label>
                        ) : null}
                        {buttonType === 'NEXT' && (
                            <img
                                className='float-right '
                                src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/chevron_right.svg`)}
                                alt=''
                            />
                        )}
                        {buttonType === 'ADD' && (
                            <img className='float-right ' src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/add_blue.svg`)} alt='' />
                        )}
                    </a>
                </>
            );
        }
    } else {
        return null;
    }
};

ActionButton.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    downloadFile: false,
    iconSide: 'right',
    rendered: true,
    disabled: false,
    inputLikeStyle: false,
    openInNewTab: false,
    buttonType: undefined,
    params: {},
    size: 'normal',
    variant: 'dark',
    showDescription: false,
    descriptionClassName: 'p-checkbox-label p-label',
};

ActionButton.propTypes = {
    className: PropTypes.string,
    colClass: PropTypes.string,
    disabled: PropTypes.bool,
    buttonType: PropTypes.string,
    downloadFile: PropTypes.bool,
    handleClick: PropTypes.func,
    href: PropTypes.string,
    iconColor: PropTypes.string,
    iconLabel: PropTypes.string,
    iconName: PropTypes.string,
    iconSide: PropTypes.string,
    iconSize: PropTypes.string,
    id: PropTypes.string,
    inputLikeStyle: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    openInNewTab: PropTypes.bool,
    params: PropTypes.object,
    rendered: PropTypes.bool,
    renderLabel: PropTypes.func,
    size: PropTypes.string,
    title: PropTypes.string,
    variant: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    showDescription: PropTypes.bool,
    descriptionClassName: PropTypes.string,
};

export default withTranslation()(ActionButton);
