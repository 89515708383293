import styled from 'styled-components';
import HtmlParserUtils from '../../utils/htmlParser/HtmlParserUtils';

// mega antypattern ale taki jest suneditor :D
const customTextAligner = function (sel, side, callback, blockingAction) {
    console.log('---customTextAligner---');

    var range = sel.getRangeAt(0);
    let newNode = window.document.createElement('div');
    let extractedContents = range.extractContents();

    newNode.appendChild(extractedContents);
    const cls = ['custom-text-align-left', 'custom-text-align-right', 'custom-text-align-center'];

    let finalText = newNode.innerHTML;
    console.log('before format', finalText);

    const divAlignRightSemicolon = '<div style="text-align: right;">';
    const divAlignJusitfySemicolon = '<div style="text-align: justify;">';
    const divAlignCenterSemicolon = '<div style="text-align: center;">';
    const divAlignLeftSemicolon = '<div style="text-align: left;">';

    const divAlignRight = '<div style="text-align: right">';
    const divAlignCenter = '<div style="text-align: center">';
    const divAlignJusitfy = '<div style="text-align: justify">';
    const divAlignLeft = '<div style="text-align: left">';

    const divAlignClassCenter = '<div class="custom-text-align-center">';
    const divAlignClassLeft = '<div class="custom-text-align-left">';
    const divAlignClassJustify = '<div class="custom-text-align-justify">';
    const divAlignClassRight = '<div class="custom-text-align-right">';

    const divClose = '</div>';
    const divOpen = '<div';

    let exitLoop = false;

    while (
        finalText.includes(divAlignRight) ||
        finalText.includes(divAlignCenter) ||
        finalText.includes(divAlignLeft) ||
        finalText.includes(divAlignJusitfySemicolon) ||
        finalText.includes(divAlignJusitfy) ||
        finalText.includes(divAlignClassJustify) ||
        finalText.includes(divAlignClassCenter) ||
        finalText.includes(divAlignClassLeft) ||
        finalText.includes(divAlignRightSemicolon) ||
        finalText.includes(divAlignCenterSemicolon) ||
        finalText.includes(divAlignLeftSemicolon) ||
        finalText.includes(divAlignClassRight)
    ) {
        console.log('loop start');
        let divToReplace = '';
        if (finalText.includes(divAlignRight)) {
            divToReplace = divAlignRight;
        } else if (finalText.includes(divAlignCenter)) {
            divToReplace = divAlignCenter;
        } else if (finalText.includes(divAlignLeft)) {
            divToReplace = divAlignLeft;
        } else if (finalText.includes(divAlignClassCenter)) {
            divToReplace = divAlignClassCenter;
        } else if (finalText.includes(divAlignClassLeft)) {
            divToReplace = divAlignClassLeft;
        } else if (finalText.includes(divAlignClassRight)) {
            divToReplace = divAlignClassRight;
        } else if (finalText.includes(divAlignRightSemicolon)) {
            divToReplace = divAlignRightSemicolon;
        } else if (finalText.includes(divAlignCenterSemicolon)) {
            divToReplace = divAlignCenterSemicolon;
        } else if (finalText.includes(divAlignLeftSemicolon)) {
            divToReplace = divAlignLeftSemicolon;
        } else if (finalText.includes(divAlignJusitfySemicolon)) {
            divToReplace = divAlignJusitfySemicolon;
        } else if (finalText.includes(divAlignJusitfy)) {
            divToReplace = divAlignJusitfy;
        } else if (finalText.includes(divAlignClassJustify)) {
            divToReplace = divAlignClassJustify;
        }

        console.log('div to replace: ' + divToReplace);
        finalText = finalText.replace(new RegExp(divToReplace + '?'), '');

        let openDivIndex = 0;
        let closeDivIndex = 0;
        while (closeDivIndex <= openDivIndex || openDivIndex !== -1) {
            openDivIndex = finalText.indexOf(divOpen, openDivIndex);
            closeDivIndex = finalText.indexOf(divClose, closeDivIndex);
            // wyrzucamy zamkniety div jezeli ma on index mniejszy od nastepnego otwarcia div'a lub następne otwarcie diva nie istnieje, czyli jest  === -1
            if (closeDivIndex < openDivIndex || openDivIndex === -1) {
                const substringCloseDiv = finalText.substring(closeDivIndex);
                const firstFoundCloseDiv = substringCloseDiv.replace(new RegExp(divClose + '?'), '');
                // wycinka do final textu bez diva z aligmentem
                finalText = finalText.substring(0, closeDivIndex) + firstFoundCloseDiv;
                break;
            } else if (closeDivIndex === -1) {
                // teoretycznie nie może być takiego przypadku, że closeDiv index bedzie poza zakresem
                console.log('something goes wrong!!! unposibelelel :) ');
                // przerywamy petle (obydwie)
                exitLoop = true;
                break;
            }
            // rozmiar <div
            openDivIndex = openDivIndex + divOpen.length;
            // rozmiar <div/>
            closeDivIndex = closeDivIndex + divClose.length;
        }
        if (exitLoop) {
            finalText = newNode.innerHTML;
            break;
        }
    }

    console.log('after format', finalText);
    newNode.innerHTML = finalText;

    newNode.classList.remove(...cls);
    newNode.classList.add(`custom-text-align-${side}`);

    range.insertNode(newNode);
    const parent = newNode.parentNode;
    const classList = Array.from(parent.classList);

    if (classList.find((element) => element.includes('align'))) {
        const grandParent = parent.parentNode;
        const classList = Array.from(grandParent.classList);
        if (classList.find((element) => element.includes('align'))) {
            if (grandParent.children.length === 1) {
                grandParent.remove();
                range.insertNode(parent);
            }
        }
    }

    window.getSelection()?.removeAllRanges();
    callback();
    // myk na rozjezdzające się tootlipy w suneditorze
    document.querySelectorAll(`[data-command='codeView']`)[0].click();
    document.querySelectorAll(`[data-command='codeView']`)[0].click();
};

export default customTextAligner;
