import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import {ActionButton} from '../../components/ActionButton';
import CalendarScheduleComponent from '../../components/CalendarScheduleComponent';
import {CustomMessages} from '../../components/CustomMessages';
import DivContainer from '../../components/DivContainer';
import ConsultationModel from '../../model/ConsultatonModel';
import EventModel from '../../model/EventModel';
import AuthService from '../../services/AuthService';
import ConsultationProposalService from '../../services/EventCalendarService';
import SpecializationService from '../../services/SpecializationService';
import UserService from '../../services/UserService';
import {EventCalendarUtils} from '../../utils/EventCalendarUtils';
import EventCalendarDialog from './EventCalendarDialog';
import BlockUi from '../../components/waitPanel/BlockUi';
import {Card} from 'primereact/card';
import DialogResizer from '../../components/DialogResizer';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';

class EventCalendar extends BaseListContainer {
    constructor(props) {
        super(props, new ConsultationProposalService());

        this.specializationService = new SpecializationService();
        this.userService = new UserService();
        this.state = {
            periodType: 'MONTHS',
            today: undefined,
            startDate: undefined,
            endDate: undefined,
            dialogVisibile: false,
            element: new ConsultationModel(),
            list: [],
            loading: true,
            size: 0,
            first: 0,
            tempUser: undefined,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            specializations: [],
            users: [],
            eventsInizialize: [],
            events: [],
            onHide: false,
        };
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.afterSaveWithOperation = this.afterSaveWithOperation.bind(this);
        this.authService = new AuthService();
    }
    getDataKey() {
        return 'id';
    }

    getCriteriaName() {
        return 'event-calendar';
    }

    prepareColumns() {
        return [{type: 'LABEL', className: 'float-left'}];
    }

    getContainerListName() {
        return 'event-calendar';
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    updateSearchCriteria(criteria) {
        return {
            status: criteria.status,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            status: '',
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    initializeFromBackend() {
        if (this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE')) {
            this.specializationService
                .getAllSpecializations()
                .then((specializations) =>
                    this.setState({
                        specializations,
                    })
                )
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy specjalizacji');
                });
            this.enumService
                .getEnumList('ConsultationStatus', this.translate)
                .then((statusOptions) =>
                    this.setState({
                        statusOptions,
                    })
                )
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy typów wiadomości');
                });
        } else if (this.authService.isUserInAnyRole('ROLE_LAW_OFFICE_CONSULTANT_MANAGE')) {
            this.specializationService
                .getAllSpecializationsByUserId(this.props.currentUser.userId)
                .then((specializations) => {
                    this.setState((prevState) => ({
                        element: {
                            ...prevState.element,
                        },
                        specializations: specializations,
                    }));
                })
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy specjalizacji');
                });
        }
        this.getEvents(new Date(), this.state.periodType);
        this.setState({
            today: new Date(),
        });
    }

    getEvents(date, periodType) {
        if (this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE')) {
            this.service
                .getEventList(date, periodType)
                .then((events) => {
                    const convertedEvents = this.setStylesForEvent(events);
                    this.setState({
                        events: convertedEvents,
                    });
                })
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy wydarzeń');
                });
        } else {
            this.service
                .getEventListByUserId(this.props.currentUser.userId, date, periodType)
                .then((events) => {
                    const convertedEvents = this.setStylesForEvent(events);
                    this.setState({
                        events: convertedEvents,
                    });
                })
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy wydarzeń');
                });
        }
    }
    breadCrumbItems() {
        const url = window.location.href.includes('event-calendar') ? '/#/event-calendar' : '/#/scheduled-event-calendar';
        return [
            {name: 'Kalendarz', fontSize: '0px', url: url},
            {name: <b style={{fontSize: '20px'}}> {document.getElementsByClassName('fc-toolbar-title')[0]?.innerText}</b>, url: null},
        ];
    }

    setStylesForEvent(events) {
        let convertedEvents = [];
        for (let index = 0; index < events.length; index++) {
            let tempEvents = EventModel.copy(events[index]);
            if (events[index].status === 'CONFIRMED') {
                tempEvents.backgroundColor = EventCalendarUtils.GREEN;
            } else if (events[index].status === 'REJECTED') {
                tempEvents.backgroundColor = EventCalendarUtils.RED;
            } else {
                tempEvents.backgroundColor = EventCalendarUtils.BLUE;
            }
            convertedEvents.push(tempEvents);
        }
        return convertedEvents;
    }

    renderOperations() {
        return (
            <div className='row'>
                <ActionButton
                    className='cancel-button gray'
                    label={this.translate('list.clearFilters')}
                    handleClick={this.cleanSearchCriteria}
                />
            </div>
        );
    }

    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: false, elementId: undefined}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
            if (callback) callback();
        });
        this.initializeFromBackend();
    }

    afterSaveWithOperation(operation) {
        this.readMessage();
        this.initializeFromBackend();
        this.handleCloseDialog();
        this.unblockUi();
        if (operation === 'SAVE_ADD') {
            this.handleOpenDialog();
        }
    }

    handleOpenDialog(e) {
        this.setState({
            viewMode: 'NEW',
            dialogVisibile: true,
        });
    }

    handleOpenDetailsDialog(e) {
        this.setState({
            viewMode: 'VIEW',
            dialogVisibile: true,
            elementId: e.event._def.publicId,
            tempUser: e.event._def.extendedProps.user,
        });
    }

    legend() {
        return <DivContainer id='legend' colClass='row center-in-div'></DivContainer>;
    }

    render() {
        const headerToolbar = {
            right: 'today prev,next',
            left: 'title',
            center: 'addEvent dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        };
        return (
            <React.Fragment>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)}></CustomMessages>

                <BreadcrumbsItem to='/event-calendar'>{'Konsultacje'}</BreadcrumbsItem>
                <BlockUi
                    tag='div'
                    blocking={this.state.blocking || this.state.loading}
                    loader={this.loader}
                    rendered={!this.props.embedded}
                >
                    <BaseBreadCrumb items={this.breadCrumbItems()} />
                    <Card className='details-card card-padding-lexdocs calendar-component'>
                        <DivContainer colClass='col-12'>
                            <div style={{backgroundColor: '', padding: '0px'}} className=''>
                                <CalendarScheduleComponent
                                    headerToolbar={headerToolbar}
                                    initialView={'dayGridMonth'}
                                    select={() => {}}
                                    isLexdocs={!this.isLexyPortal()}
                                    addEvent={(e) => this.handleOpenDialog(e)}
                                    handleClickOperationButton={(date, periodType) => this.getEvents(date, periodType)}
                                    events={this.state.events}
                                    eventClick={(e) => this.handleOpenDetailsDialog(e)}
                                />
                                {this.authService.isUserInAnyRole(
                                    'ROLE_ADMIN_SUPER_MANAGE',
                                    'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                    'ROLE_ADMIN_TERM_MANAGE'
                                ) && this.legend()}
                                {this.state.dialogVisibile && (
                                    <EventCalendarDialog
                                        specializations={this.state.specializations}
                                        onHide={(e) => {
                                            this.handleCloseDialog(e);
                                        }}
                                        elementId={this.state.elementId}
                                        user={this.state.tempUser}
                                        afterSaveWithOperation={(e) => this.afterSaveWithOperation(e)}
                                        readMessage={this.readMessage}
                                        currentUser={this.props.currentUser}
                                        visible={this.state.dialogVisibile}
                                        viewMode={this.state.viewMode}
                                        afterSave={(e) =>
                                            this.handleCloseDialog(e, () => {
                                                this.readMessage();
                                                this.initializeFromBackend();
                                                this.unblockUi();
                                            })
                                        }
                                        blockUi={this.blockUi}
                                        unblockUi={this.unblockUi}
                                        _isMounted={this._isMounted}
                                        className='event-calendar-dialog'
                                    />
                                )}
                            </div>
                        </DivContainer>
                    </Card>
                </BlockUi>
            </React.Fragment>
        );
    }
}

EventCalendar.defaultProps = {
    detailUrl: '/#/consultation-proposal/details',
    newUrl: '/#/consultation-proposal/create',
    lazy: true,
};

EventCalendar.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(EventCalendar);
