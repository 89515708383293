/* eslint-disable no-script-url */
/* eslint-disable react/jsx-max-props-per-line */
import PropTypes from 'prop-types';
import React from 'react';
import AppPrefixUtils from '../utils/AppPrefixUtils';

export const MenuItemIcon = (props) => {
    const {alt, className, iconSvg, id, handleClick, iconName, portalSvg} = props;

    React.useEffect(() => {
        const {eventListeners} = props;
        if (eventListeners) {
            eventListeners.forEach((e) => {
                const element = document.getElementById(e.eventElementId);
                if (element) {
                    element.addEventListener(e.eventName, () => e.fnc());
                }
            });
            return () => {
                eventListeners.forEach((e) => {
                    const element = document.getElementById(e.eventElementId);
                    if (element) {
                        element.removeEventListener(e.eventName, () => e.fnc());
                    }
                });
            };
        }
    }, []);
    return iconName ? (
        <i id={id} onClick={handleClick} alt={alt} className={`icon mdi ${iconName}`} />
    ) : (
        <img
            id={id}
            className={className}
            alt={alt}
            src={AppPrefixUtils.locationHrefUrl(
                `${portalSvg ? (process.env.REACT_APP_PORTAL_TYPE === 'LEXY' ? 'lexy' : 'lexdocs') : ''}/images/${iconSvg}.svg`
            )}
            onClick={handleClick}
        />
    );
};

MenuItemIcon.defaultProps = {portalSvg: false};

MenuItemIcon.propTypes = {
    id: PropTypes.string,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    iconSvg: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    eventListeners: PropTypes.array,
};

export default MenuItemIcon;
