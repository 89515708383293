import PropTypes from 'prop-types';
import React from 'react';
import BaseDetailsContainer from '../../../baseContainers/BaseDetailsContainer';
import UserService from '../../../services/UserService';
import {Messages} from 'primereact/messages';
import InputCalendarComponent from '../../../components/inputs/InputCalendarComponent';
import PurchasedService from '../../../services/PurchasedService';
import {Card} from 'primereact/card';
import {t} from 'i18next';
import InputTextComponent from '../../../components/inputs/InputTextComponent';
import {YesNoDialog} from '../../../components/YesNoDialog';
import ActionButton from '../../../components/ActionButton';
import TemplatesTableDialog from '../../TemplatesTableDialog';
import InputNumberComponent from '../../../components/inputs/InputNumberComponent';
import AppPrefixUtils from '../../../utils/AppPrefixUtils';
import DialogResizer from '../../../components/DialogResizer';
import CardBackgroundUtil from '../../../utils/CardBackgroundUtil';

class UserPackageContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new UserService());
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.state = {
            viewMode: props.viewMode,
            element: {
                purchasedSubscription: [],
            },
            selectedPackagesMap: new Map(),
            billingCycleMap: new Map(),
            dialogVisibile: false,
            templatesDialogVisibile: false,
            subscription: '',
            packageId: undefined,
        };
        this.purchasedService = new PurchasedService();
        this.handleChangePackage = this.handleRedirect.bind(this);
        this.showConfirmationDialog = this.showConfirmationDialog.bind(this);
        this.hideConfirmationDialog = this.hideConfirmationDialog.bind(this);
        this.handleCancelSubscription = this.handleCancelSubscription.bind(this);
        this.handleDeactivationSubscription = this.handleDeactivationSubscription.bind(this);
        this.showTemplates = this.showTemplates.bind(this);
        this.hideTemplates = this.hideTemplates.bind(this);
        this.backLinkRendered = false;
    }

    getUpdateSucces() {
        return 'Konto zostało zaktualizowane';
    }

    prepareHeaderItems() {
        const {backUrl, elementId} = this.props;
        const href = `${backUrl}/${elementId}/change-package`;
        return [
            {
                label: 'Zmień pakiet',
                rendered: this.props.viewMode === 'VIEW',
                onClick: this.handleRedirect.bind(this, href),
            },
        ];
    }

    handleRedirect(href, e) {
        e.preventDefault();
        window.location.href = AppPrefixUtils.locationHrefUrl(href);
    }

    showTemplates(packageId) {
        this.setState({
            templatesDialogVisibile: true,
            packageId: packageId,
        });
    }

    hideTemplates() {
        this.setState(
            {
                templatesDialogVisibile: false,
            },
            () => DialogResizer.removeMaskIfExistOneDialog()
        );
    }

    translate(key) {
        return t(key);
    }

    readMessage() {
        const messageFromStorage = this.readCookie(this.getContainerListName());
        if (messageFromStorage !== undefined && messageFromStorage != null) {
            const message = JSON.parse(messageFromStorage);
            this.removeCookie(this.getContainerListName());
            if (
                message.severity !== undefined &&
                message.severity != null &&
                message.summary !== undefined &&
                message.summary != null &&
                message.detail !== undefined &&
                message.detail != null
            ) {
                this.showMessage(message.severity, message.summary, message.detail);
            }
        }
    }

    initPurchasedSubscription() {
        this.purchasedService
            .getPurchasedSubscriptionByUserId(this.props.elementId)
            .then((purchasedSubscription) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        purchasedSubscription: purchasedSubscription,
                    },
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message, 10000, true, 'Błąd przy pobieraniu pakietów ogólnych');
            });
    }

    getContainerListName() {
        return 'user-package';
    }

    initBeforeSetElement() {
        this.initPurchasedSubscription();
        this.readMessage();
    }

    render() {
        return (
            <React.Fragment>
                <div className='row'>{this.renderView()}</div>

                {this.state.templatesDialogVisibile ? (
                    <TemplatesTableDialog
                        elementId={this.state.packageId}
                        onHide={this.hideTemplates}
                        visible={this.state.templatesDialogVisibile}
                        viewMode={this.state.templatesDialogVisibile}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                        className='templates-table-dialog'
                    />
                ) : null}
            </React.Fragment>
        );
    }

    renderDetails() {
        return (
            <React.Fragment>
                <Messages id='custom-messages' ref={(el) => (this.messages = el)} />
                <div className='row'>{this.renderSubscriptions()}</div>
                <YesNoDialog
                    className={'confirmation-dialog'}
                    visible={this.state.dialogVisibile}
                    header={this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE') ? 'Dezaktywacja pakietu' : 'Anulowanie subskrypcji'}
                    name='dialogVisibile'
                    onChange={(type, x, target) => {
                        if (target.value) {
                            this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE')
                                ? this.handleDeactivationSubscription()
                                : this.handleCancelSubscription();
                            this.hideConfirmationDialog(this.state.dialogValue);
                        } else {
                            this.hideConfirmationDialog(this.state.dialogValue);
                        }
                    }}
                    onHide={() => {
                        this.hideConfirmationDialog(this.state.dialogValue);
                        DialogResizer.removeMaskIfExistOneDialog();
                    }}
                    children={this.state.dialogValue?.requirementDialog}
                    yesButtonLabel={'Tak'}
                    noButtonLabel={'Nie'}
                >
                    {this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE')
                        ? 'Czy na pewno chcesz dezaktywować pakiet?'
                        : 'Czy na pewno chcesz anulować subskrypcję?'}
                </YesNoDialog>
            </React.Fragment>
        );
    }

    showConfirmationDialog(subscription) {
        this.setState({
            subscription: subscription,
            dialogVisibile: true,
        });
    }

    hideConfirmationDialog() {
        this.setState({
            subscription: '',
            dialogVisibile: false,
        });
    }

    handleCancelSubscription() {
        this.purchasedService
            .cancelSubscription(this.state.subscription.id)
            .then(() => {
                this.showSuccessMessage('Anulowano subskrypcje!');
                this.initPurchasedSubscription();
            })
            .catch((err) => {
                this.showErrorMessage('Nie można anulować subsrypcji');
            });
    }
    handleDeactivationSubscription() {
        this.purchasedService
            .deactivationSubscription(this.state.subscription.id)
            .then(() => {
                this.showSuccessMessage('Dezaktywowano pakiet!');
                this.initPurchasedSubscription();
            })
            .catch((err) => {
                this.showErrorMessage('Nie można dezaktywować pakietu');
            });
    }

    renderFooterSubscription(purchasedSub) {
        if (purchasedSub === undefined) return;
        return (
            <div className='buttons-container'>
                {purchasedSub.status === 'ACTIVE' && (
                    <div className='col-12 parent-with-centered-item annuled'>
                        <ActionButton
                            label={this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE') ? 'Dezaktywuj pakiet' : 'Anuluj pakiet'}
                            handleClick={() => this.showConfirmationDialog(purchasedSub)}
                            className='p-button-text-only p-button-outlined p-button-danger'
                        />
                    </div>
                )}{' '}
                <div className='col-12 available-template color-blue parent-with-centered-item'>
                    <ActionButton
                        label='Dostępne szablony'
                        className='p-button-text-only'
                        handleClick={this.showTemplates.bind(this, purchasedSub.subscriptionTypeModel.subscription.id)}
                    />
                </div>
            </div>
        );
    }

    renderHeaderSubscription(purchasedSub) {
        if (purchasedSub === undefined) return;

        return (
            <div className=''>
                <div className=''>
                    <img src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/package_logo.svg`)} height={64} alt='' className='mt-4' />
                    <div className='package-type'>
                        <label>{purchasedSub.subscriptionTypeModel.subscription.name}</label>
                    </div>
                </div>
            </div>
        );
    }

    renderSubscriptions() {
        return this.state.element.purchasedSubscription.map((purchasedSub, index) => {
            const header = this.renderHeaderSubscription(purchasedSub);
            const footer = this.renderFooterSubscription(purchasedSub);
            const status = this.translate(`purchasedSusbcriptionType.${purchasedSub?.status}`);
            const background = CardBackgroundUtil.colorSelector(purchasedSub.subscriptionTypeModel.planType);
            const descToHtml = {__html: purchasedSub?.subscriptionTypeModel?.subscription?.description};
            return (
                <div className='container'>
                    <Card
                        subTitle={<div className='text-white mt-2' dangerouslySetInnerHTML={descToHtml}></div>}
                        className={`purchased-subscription-card  ${background}`}
                        header={header}
                        footer={footer}
                    >
                        <div>
                            <label className='text-white'></label>
                            <div id='status'>
                                <InputTextComponent
                                    id='status'
                                    name='status'
                                    label='Status pakietu'
                                    classLabel='black-label'
                                    type='text'
                                    value={status}
                                    viewMode='VIEW'
                                    colClass='col-12'
                                />
                            </div>
                            <div id='settlementPeriod'>
                                <InputTextComponent
                                    id='settlementPeriod'
                                    name='settlementPeriod'
                                    label={'Okres rozliczenia'}
                                    value={this.translate(`${'SubscriptionType.' + purchasedSub.subscriptionTypeModel.enumValue}`)}
                                    viewMode={'VIEW'}
                                    validator={this.validator}
                                    dateFormatMoment={'YYYY-MM-DD'}
                                    colClass='col-12'
                                />
                            </div>
                            <div id='lastDay'>
                                <InputCalendarComponent
                                    id='lastDay'
                                    name='lastDay'
                                    label={'Następne rozliczenie'}
                                    value={purchasedSub.lastDay}
                                    onChange={this.handleChange}
                                    viewMode={'VIEW'}
                                    validator={this.validator}
                                    dateFormatMoment={'YYYY-MM-DD'}
                                    colClass='col-12'
                                />
                            </div>
                            <div className='color-white'>
                                <InputNumberComponent
                                    id='price'
                                    name='price'
                                    label={'Cena pakietu'}
                                    colClass='col-md-6'
                                    value={purchasedSub.subscriptionTypeModel.price}
                                    locale='pl-PL'
                                    currency='PLN'
                                    viewMode={'VIEW'}
                                    validator={this.validator}
                                    mode='currency'
                                />
                            </div>
                            <div className='color-white'>
                                <InputNumberComponent
                                    id='consultation'
                                    name='consultation'
                                    label={'liczba konsultacji'}
                                    colClass='col-md-6'
                                    validator={this.validator}
                                    rendered={purchasedSub.numberOfConsultations != null}
                                    value={purchasedSub.numberOfConsultations}
                                    viewMode={'VIEW'}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            );
        });
    }
}

UserPackageContainer.defaultProps = {
    cancelUrl: '/#/manage-account/details',
    detailUrl: '/#/manage-account/details',
    editUrl: '/#/manage-account/edit',
    // backUrl: '/#/user/details',
    packageTemplatesUrl: '/#/template-list/package',
};

UserPackageContainer.propTypes = {
    cancelUrl: PropTypes.string,
    detailUrl: PropTypes.string,
    editUrl: PropTypes.string,
    changePackageUrl: PropTypes.string,
};

export default UserPackageContainer;
