import React from 'react';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import DivContainer from '../../components/DivContainer';
import {ActionButton} from '../../components/ActionButton';
import Constants from '../../utils/constants';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
import InputFileUploadComponent from '../../components/inputs/InputFileUploadComponent';
import ApprovalContentService from '../../services/ApprovalContentService';
import DialogResizer from '../../components/DialogResizer';

class ApprovalContentDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new ApprovalContentService());
        this.state = {
            loading: true,
            elementId: props.id,
            element: {
                content: '',
                required: true,
                order: undefined,
                attachments: [],
            },
            booleanOptions: Constants.BOOLEAN_OPTIONS,
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    getContainerListName() {
        return 'approval-content-list';
    }

    getAddSucces() {
        return 'Zgoda została utworzona';
    }

    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton
                    buttonType={'NEXT'}
                    label={t('approval.add')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                />
            </div>
        );
    }

    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>{t('approval.addHeader')}</label>
                </DivContainer>
            </DivContainer>
        );
    }
    onUploladError(errMsg) {
        if (this.props.showErrorMessage) {
            this.props.showErrorMessage(errMsg);
        }
    }
    renderDetails() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextareaComponent
                        autoResize
                        rows={2}
                        id='content'
                        name='content'
                        label={'Treść'}
                        placeholder='Wpisz treść'
                        colClass='col-md-12'
                        value={this.state.element.content}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validators={'required|max:1500'}
                    />

                    <InputNumberComponent
                        id='order'
                        name='order'
                        label={'Kolejność'}
                        colClass='col-xl-3 col-lg-3 col-md-3 col-sm-3'
                        min={0}
                        placeholder='Wpisz kolejność'
                        value={this.state.element.order}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validators={'naturalNumber'}
                    />

                    <InputDropdownComponent
                        id='required'
                        name='required'
                        dataKey='label'
                        label={'Obowiązkowa'}
                        colClass='col-xl-3 col-lg-3 col-md-3 col-sm-3'
                        value={this.state.element.required}
                        validator={this.validator}
                        onChange={() => {
                            this.setState((prevState) => ({
                                element: {
                                    ...prevState.element,
                                    required: !this.state.element.required,
                                },
                            }));
                        }}
                        viewMode={this.props.viewMode}
                        validators={'required'}
                        optionLabel='label'
                        options={this.state.booleanOptions}
                    />

                    <InputFileUploadComponent
                        messages={this.messages}
                        id='attachments'
                        name='attachments'
                        label={'Pliki'}
                        fileList={this.state.element.attachments}
                        showLabel
                        value={this.state.element.attachments}
                        restApiUrl={`${this.service.getPath()}/${this.state.element.id}/attachments`}
                        multiple
                        maxFileSize={50000000}
                        onChange={this.handleChange}
                        itemLabel='fileName'
                        itemName='uuid'
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-6'
                        viewMode={this.props.viewMode}
                        validator={this.validator}
                        validators='not_required'
                        style={{width: '50%'}}
                        restrictExt={true}
                        accept='.pdf,.jpg'
                        handleError={this.onUploladError}
                        token={this.authService.getToken()}
                        validateViewMode
                        maxFileCount={1}
                        overrideFileNotAcceptedMessage='Nieprawidłowy format pliku. Wspierane formaty: .pdf, .jpg'
                    />
                </DivContainer>
            </React.Fragment>
        );
    }

    handleFormSubmit() {
        const {t} = this.props;
        const {element} = this.state;
        if (this.validator.allValid()) {
            DialogResizer.removeMaskIfExistOneDialog();
            new ApprovalContentService()
                .add(element)
                .then(() => {
                    this.persistMessage('success', '', t('approval.addSuccessfull'));
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                })
                .catch((err) => {
                    this.persistMessage('error', '', err.message);
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                });
        } else {
            this.validator.showMessages();
            // this.scrollToError = true;
            this.forceUpdate();
        }
    }
}

ApprovalContentDialog.defaultProps = {
    backUrl: '/#/approval-content-list',
    cancelUrl: '/#/approval-content/details',
    editUrl: '/#/approval-content/edit',
};

ApprovalContentDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withTranslation()(ApprovalContentDialog);
