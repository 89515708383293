

export default class ConsultationHelper  {

    persistConsultationRequest(specjalizationName, consultationType, price) {
        const data = {
            specjalizationName, consultationType, price
        };
        sessionStorage.setItem('consultation-summary', JSON.stringify(data));
    }

    readConsultationRequestSpecializationName() {
        return JSON.parse(sessionStorage.getItem('consultation-summary')).specjalizationName;
    }
    readConsultationRequestConsultationType(uuid) {
        return JSON.parse(sessionStorage.getItem('consultation-summary')).consultationType;
    }

    readConsultationRequestPrice(uuid) {
        return JSON.parse(sessionStorage.getItem('consultation-summary')).price;
    }    

}