export default class SettingModel {
    constructor() {
        this.id = undefined;
        this.type ='';
        this.version = 0;
        this.name = '';
        this.value = '';
    }

    static assign(from, to) {
        if (from) {
            to.type = from.type
            to.name = from.name;
            to.value = from.value;
            to.id = from.id;
            to.version = from.version;
        } else {
            to = new SettingModel();
        }
    }

    static copy(from) {
        const to = new SettingModel();
        SettingModel.assign(from, to);
        return to;
    }
}
