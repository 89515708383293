import HtmlParserUtils from '../utils/htmlParser/HtmlParserUtils';

const pluginSubmenu = {
    // @Required @Unique
    // plugin name
    name: 'custom_plugin',

    // @Required
    // data display
    display: 'submenu',
    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
        // @Required
        // Registering a namespace for caching as a plugin name in the context object
        const context = core.context;
        context.customSubmenu = {
            targetButton: targetElement,
        };
        let listDiv = this.setSubmenu.call(core, core);

        var self = this;
        listDiv.querySelectorAll('.se-btn-list').forEach(function (btn) {
            btn.addEventListener('click', self.onClick.bind(core));
        });
        core.initMenuTarget(this.name, targetElement, listDiv);
    },

    setSubmenu: function (core) {
        const listDiv = core.util.createElement('DIV');
        // @Required
        // A "se-submenu" class is required for the top level element.
        listDiv.className = 'se-menu-container se-submenu se-list-layer';
        listDiv.innerHTML =
            '' +
            `<div class="se-list-inner se-list-font-size parameters-list"><ul class="se-list-basic">${core?.options?.tagOptions?.length === 0 ? 
                `<li><button id="empty-components-list" disabled="true" type="button" class="se-btn-list">Brak komponentów</button></li>` : 
                core?.options?.tagOptions
                ?.sort((a, b) => a.field > b.field ? 1 : -1).map((item, index) => {
                    let str = JSON.stringify(item);
                    str = str.replace(/"/g, "'");
                    return `<li><button id=${item.uuid} value="${str}" type="button" class="se-btn-list">${item.field}</button></li>`;
                })
                .join('')}</ul></div>`;

        return listDiv;
    },

    // @Override submenu
    // Called after the submenu has been rendered
    on: function () {
        console.log(this.context.customSubmenu);
        //  this.context.customSubmenu.textElement.focus();
    },

    onClick: function (e) {
        if (!e?.target?.value) {
            return;
        }
        e.preventDefault();

        let toUnparse = e.target.value.replace(/'/g, '"');
        const value = JSON.parse(toUnparse);

        const isSelectionInsideList = function (selectionNode) {
            return (
                [...document.querySelectorAll('ol')].find((el) => el.contains(selectionNode)) ||
                [...document.querySelectorAll('ul')].find((el) => el.contains(selectionNode))
            );
        };
        const insertCustomTagIntoSelection = function (sel, className, id, tagType, field, callback, blockingAction) {
            let children;
            let nodes = [];
            var range = sel.getRangeAt(0);
            children = function (parent) {
                var child, nodes;
                nodes = [];
                child = parent.firstChild;

                while (child) {
                    if (HtmlParserUtils.isBlockLevelElement(child)) {
                        nodes.push(child);
                    }
                    nodes = nodes.concat(children(child));
                    child = child.nextSibling;
                }

                return nodes;
            };

            nodes = children(range.commonAncestorContainer);
            const isOrderedListTag = tagType === 'DYNAMIC_LIST';
            const isCondition = tagType === 'CHECKBOX';
            const isMultiList = tagType === 'MULTI_LIST';
            const isSubTemplate = tagType === 'SUB_TEMPLATE';
            const isSelectTemplate = tagType === 'SELECT_TEMPLATE';
            const multilineTag = tagType === 'STEP' || isOrderedListTag || isCondition || isMultiList || isSubTemplate || isSelectTemplate || nodes?.length > 0;
            const newNode = window.document.createElement(multilineTag ? 'div' : 'span');
            if (multilineTag && isSelectionInsideList(range.commonAncestorContainer)) {
                blockingAction()
                return;
            }
            newNode.id = id;
            newNode.className = `${className} ${tagType} ${multilineTag ? '' : HtmlParserUtils.inlineCustomTagClassName()}`;
            newNode.setAttribute(HtmlParserUtils.getTooltipAttributeForTemplate(), field);

            newNode.appendChild(range.extractContents());
            range.insertNode(newNode);

            if (multilineTag) {
                const separatorParagraph = window.document.createElement('p');
                separatorParagraph.appendChild(window.document.createElement('br'));
                newNode.parentNode.insertBefore(separatorParagraph, newNode.nextSibling);
            }
            callback();
        };
        if (value.uuid) {
            if (window.getSelection) {
                insertCustomTagIntoSelection(
                    window.getSelection(),
                    value.type !== 'STEP' ? HtmlParserUtils.getCustomTagClassName() : HtmlParserUtils.getCustomTagStepClassName(),
                    value.uuid,
                    value.type,
                    value.field,
                    this.context?.options?.insertTag ? this.context?.options?.insertTag : () => {},
                    this.context?.options?.blockingAction ? this.context?.options?.blockingAction : () => {}
                );
                this.history.push();
            }
        }
        this.submenuOff();
    },
};
export default pluginSubmenu;
