import {isLexDocs} from './baseContainers/BaseContainer';
export const LEXDOCS_NAME_APP_BUILD_COOKIE = 'lexdocs-build-number';
export const LEXDOCS_APP_HAS_BEEN_UPDATED = 'lexdocs-updated';
export const LEKSY_NAME_APP_BUILD_COOKIE = 'leksy-build-number';
export const LEKSY_APP_HAS_BEEN_UPDATED = 'leksy-updated';

export const isSidebarOpen = () => {
    if (isLexDocs) {
        if (document.getElementById('small-sidebar')) {
            return false;
        }
        return true;
    }
    return false;
};
export const hardReload = () => {
    const currentUrl = window.location.href;
    const originUrl = window.location.origin + '/#/';
    if (window.caches) {
        try {
            caches.open(currentUrl).then(function (cache) {
                cache.delete(currentUrl).then(() => {
                    caches.open(originUrl).then(function (cache) {
                        cache.delete(originUrl).then(() => {
                            window.location.reload(true);
                        });
                    });
                });
            });
        } catch (ex) {
            // 99% imposible
            window.location.reload(true);
        }
    } else window.location.reload(true);
};

export const refreshAppAfterDetectedNewBuild = (saveAppHasBeenUpdatedCookie) => {
    const buildNumberFromEnv = process.env.REACT_APP_BUILD_NUMBER !== undefined ? process.env.REACT_APP_BUILD_NUMBER.toString() : '';
    const buildNumberFromCookie = isLexDocs
        ? localStorage.getItem(LEXDOCS_NAME_APP_BUILD_COOKIE)
        : localStorage.getItem(LEKSY_NAME_APP_BUILD_COOKIE);
    const storeCookieAndReload = () => {
        localStorage.setItem(isLexDocs ? LEXDOCS_NAME_APP_BUILD_COOKIE : LEKSY_NAME_APP_BUILD_COOKIE, buildNumberFromEnv);
        hardReload();
    };
    if (buildNumberFromCookie) {
        const isNotAppUpToDate = buildNumberFromEnv !== buildNumberFromCookie;
        if (isNotAppUpToDate) {
            if (saveAppHasBeenUpdatedCookie) {
                sessionStorage.setItem(isLexDocs ? LEXDOCS_APP_HAS_BEEN_UPDATED : LEKSY_APP_HAS_BEEN_UPDATED, true);
            }
            storeCookieAndReload();
        }
    } else storeCookieAndReload();
};
export const detectAppHasBeenUpdated = (callbackFunction) => {
    const appHasBeenUpdatedCookie = isLexDocs ? LEXDOCS_APP_HAS_BEEN_UPDATED : LEKSY_APP_HAS_BEEN_UPDATED;
    if (sessionStorage.getItem(appHasBeenUpdatedCookie)) {
        sessionStorage.removeItem(appHasBeenUpdatedCookie);
        if (typeof callbackFunction === 'function') callbackFunction();
    }
};
