export default class TypesModel {
    constructor() {
        this.id = undefined;
        this.consultation = undefined;
        this.enumValue = undefined;
        this.label = undefined;
        this.selected = undefined;
        this.price = undefined;
        this.subscription = undefined;
        this.version = undefined;
    }

    static assign(from, to) {
        if (from) {
            to.id = from.id;
            to.version = from.version;
            to.selected = from.selected;
            to.price = from.price;
            to.enumValue = from.enumValue;
            to.subscription = from.subscription;
            to.consultation = from.consultation;
            to.label = from.label;
        } else {
            to = new TypesModel();
        }
    }

    static copy(from) {
        const to = new TypesModel();
        TypesModel.assign(from, to);
        return to;
    }
}
