import $ from 'jquery';

class DisableCopyUtilsAgreement {
    static disableCopyElements(className) {
        $(function () {
            $('body').on('copy', `.${className}`, function (e) {
                e.preventDefault();
            });
            $('body').on('cut', `.${className}`, function (e) {
                e.preventDefault();
            });
        });
    }
}
export default DisableCopyUtilsAgreement;
