import {withTranslation} from 'react-i18next';
import AgreementBaseContainer from './AgreementBaseContainer';
import AgreementPreviewService from '../services/AgreementPreviewService';
import {CustomMessages} from '../components/CustomMessages';
import BlockUi from '../components/waitPanel/BlockUi';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputCheckboxComponent from '../components/inputs/InputCheckboxComponent';
class AgreementPreviewContainer extends AgreementBaseContainer {
    constructor(props) {
        super(props, AgreementPreviewContainer.getService());
        this.contractorButtonRendered = this.contractorButtonRendered.bind(this);
        this.initializedStepIndex = this.initializedStepIndex.bind(this);
        this.changeStepManually = this.changeStepManually.bind(this);
        this.translate = this.translate.bind(this);
        this.showOfficeConsultantDialog = this.showOfficeConsultantDialog.bind(this);
        this.renderDraftNameInput = this.renderDraftNameInput.bind(this);
        this.getBlurredClassName = this.getBlurredClassName.bind(this);
        this.device.previewMode = true;
    }
    initAfterSetElement() {
        super.initAfterSetElement();
        this.setState({showBlurred: true});
    }
    sidebarVisible() {
        return false;
    }
    cardMargins() {
        return 30;
    }
    breadCrumbItems() {
        return [];
    }

    static getService() {
        return new AgreementPreviewService();
    }
    prepareFooterItems() {
        return [];
    }
    getBlurredClassName() {
        return !!this.state.showBlurred ? 'blurred-mode' : '';
    }
    afterGenerateDocument() {
        this.unblockUi();
    }
    contractorButtonRendered() {
        console.log('contractorButtonRendered');
        return false;
    }
    showOfficeConsultantDialog() {}
    generateDocumentButtonRendered() {
        return true;
    }
    translate(key, plurals) {
        const {t} = this.props;
        if (plurals) {
            return t(key, {count: plurals.count, postProcess: plurals.postProcess});
        } else return t(key);
    }
    initializedStepIndex() {
        return this.state.selectedStep;
    }
    changeStepManually(inputType, options, e, onAfterStateChange, stateField) {
        if (e) {
            e.preventDefault();
        }
        if (e.value.stepIndex === this.state.element.children.length - 1) {
            this.saveBeforeLastStep(() => {
                this.handleChange(inputType, options, e, onAfterStateChange, stateField);
            });
        } else {
            this.handleChange(inputType, options, e, onAfterStateChange, stateField);
        }
    }
    renderDraftNameInput() {
        return null;
    }
    render() {
        return (
            <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                <div>
                    <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)}></CustomMessages>
                    <div className='jump-into-step'>
                        <InputDropdownComponent
                            id='selectedStep'
                            name='selectedStep'
                            label={'Przejdź do kroku'}
                            colClass='col-md-12'
                            value={this.state.selectedStep}
                            validator={this.validator}
                            options={this.state.element.children}
                            //itemTemplate={this.permissionTemplate}
                            onChange={this.changeStepManually}
                            viewMode={this.props.viewMode}
                            dataKey='stepIndex'
                            validators='not_required'
                            optionLabel='label'
                            stateField=''
                        />
                        <InputCheckboxComponent
                            id={`showBlurred`}
                            name='showBlurred'
                            label={'Pokaż zamazania'}
                            labelOnRight
                            value={this.state.showBlurred}
                            validator={this.validator}
                            colClass='col-md-12'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                            stateField={''}
                        />
                    </div>
                    {this.state.loading ? null : this.renderCard()}
                </div>
            </BlockUi>
        );
    }
}
AgreementPreviewContainer.defaultProps = {
    backUrl: '/#/agreement-list',
    templatesUrl: '/#/template-list',
    publicTemplatesUrl: '/#/public/template-list',
};
AgreementPreviewContainer.propTypes = {};
export default withTranslation()(AgreementPreviewContainer);
