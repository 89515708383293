import BaseServiceCrud from './BaseServiceCrud';

export default class SettingService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'settings';
        this.getValue = this.getValue.bind(this);
    }

    getCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,
            active: criteria.active,
            id: criteria.id,
            entity_uuid: criteria.entityUuid,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    getValue(key) {
        return this.fetch(`${this.domain}/${this.path}/key/` + key, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    }

    getCaptchaPublic() {
        return this.fetch(`${this.domain}/${this.path}/captcha`, {
            method: 'GET',
        })
            .then((res) => {
                return Promise.resolve(res);
            })
            .catch((err) => {
                throw err;
            });
    }

    getVersionInfo() {
        return this.fetch(`${this.domain}/${this.path}/version`, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    }
    getPasswordParamsPublic() {
        return this.fetch(`${this.domain}/${this.path}/passwords-params`, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    }
    getAppLinkUrl() {
        return this.fetch(`${this.domain}/${this.path}/app-url`, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    }
    getFuelRanges() {
        return this.fetch(`${this.domain}/${this.path}/fuel-ranges`, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    }
    getCmsUrl() {
        return this.fetch(`${this.domain}/${this.path}/cms-url`, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    }
    getInvalidLoginCount() {
        return this.fetch(`${this.domain}/${this.path}/invalid-login-count`, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    }

    getPaymentMaxAmount() {
        return this.fetch(`${this.domain}/${this.path}/payment-max-amount`, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    }
}
