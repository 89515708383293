/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Quill from 'quill';
export class Editor extends Component {
    constructor(props) {
        super(props);
        this.handleImage = this.handleImage.bind(this);
        this.upload = this.upload.bind(this);
    }

    componentDidMount() {
        let Font = Quill.import('formats/font');
        // console.log(Font, 'FOnt');
        // We do not add Sans Serif since it is the default
        Font.whitelist = ['tahoma', 'timesNewRoman', 'serif', 'segoeUi', 'verdena', 'arial', 'roboto'];
        Quill.register(Font, true);

        this.quill = new Quill(this.editorElement, {
            modules: {
                toolbar: this.toolbarElement,
                ...this.props.modules,
            },
            placeholder: this.props.placeholder,
            readOnly: this.props.readOnly,
            theme: this.props.theme,
            formats: this.props.formats,
        });
        if (this.props.value) {
            this.quill.pasteHTML(this.props.value);
        }

        this.quill.on('text-change', (delta, source) => {
            let html = this.editorElement.children[0].innerHTML;
            const text = this.quill.getText();
            if (html === '<p><br></p>') {
                html = null;
            }

            if (this.props.onTextChange) {
                this.props.onTextChange({
                    htmlValue: html,
                    textValue: text,
                    delta,
                    source,
                });
            }
        });

        this.quill.on('selection-change', (range, oldRange, source) => {
            if (this.props.onSelectionChange) {
                this.props.onSelectionChange({
                    range,
                    oldRange,
                    source,
                });
            }
        });

        if (this.props.externalPhotos) {
            this.quill.getModule('toolbar').addHandler('image', this.handleImage);
        }
    }

    handleImage() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();

        // Listen upload local image and save to server
        input.onchange = () => {
            const file = input.files[0];

            // file type is only image.
            if (/^image\//.test(file.type)) {
                this.upload(file);
            } else {
                console.warn('You could only upload images.');
            }
        };
    }

    upload(file) {
        this.setState({msgs: []});
        const xhr = new XMLHttpRequest();
        const formData = new FormData();

        if (this.props.onBeforeUpload) {
            this.props.onBeforeUpload({
                xhr,
                formData,
            });
        }
        formData.append('file', file, file.name);
        xhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
                this.setState({
                    progress: Math.round((event.loaded * 100) / event.total),
                });
            }

            if (this.props.onProgress) {
                this.props.onProgress({
                    originalEvent: event,
                    progress: this.progress,
                });
            }
        });

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this.setState({progress: 0});

                if (xhr.status >= 200 && xhr.status < 300) {
                    const range = this.quill.getSelection();
                    const position = range ? range.index : 0;
                    let xhrResponse = JSON.parse(xhr.response);
                    // this.quill.insertEmbed(position, 'img', `<img src="${this.props.photoUrl}/${xhrResponse.uuid}"></img>`);
                    this.quill.clipboard.dangerouslyPasteHTML(
                        position,
                        `<img src="${this.props.photoUrl}/${xhrResponse.uuid}"></img>`,
                        'silent'
                    );
                    this.quill.insertText(position, '');
                    if (this.props.onUpload) {
                        this.props.onUpload({xhr, files: this.files});
                    }
                } else {
                    if (this.props.onError) {
                        this.props.onError({xhr, files: this.files});
                    }
                }

                // this.clear();
            }
        };

        xhr.open('POST', this.props.photoUploadUrl, true);

        if (this.props.onBeforeSend) {
            this.props.onBeforeSend({
                xhr,
                formData,
            });
        }
        xhr.setRequestHeader('Authorization', this.props.token);
        xhr.withCredentials = this.props.withCredentials;

        xhr.send(formData);
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value && this.quill && !this.quill.hasFocus()) {
            if (this.props.value) this.quill.pasteHTML(this.props.value);
            else this.quill.setText('');
        }
    }

    render() {
        const containerClass = classNames(
            !!this.props.blackMode ? 'p-component p-editor-container-about' : 'p-component p-editor-container',
            this.props.className
        );
        let toolbarHeader = null;

        if (this.props.headerTemplate) {
            toolbarHeader = (
                <div ref={(el) => (this.toolbarElement = el)} className='p-editor-toolbar'>
                    {this.props.headerTemplate}
                </div>
            );
        } else {
            toolbarHeader = (
                <div ref={(el) => (this.toolbarElement = el)} className='p-editor-toolbar '>
                    <span className='ql-formats'>
                        <select className='ql-header' defaultValue='0'>
                            <option value='1'>Heading</option>
                            <option value='2'>Subheading</option>
                            <option value='0'>Normal</option>
                        </select>
                        <select className='ql-font'>
                            <option />
                            <option value='serif' />
                            <option value='tahoma' />
                            <option value='monospace' />
                            <option value='numito' />
                        </select>
                    </span>
                    <span className='ql-formats'>
                        <button type='button' className='ql-bold' aria-label='Bold' />
                        <button type='button' className='ql-italic' aria-label='Italic' />
                        <button type='button' className='ql-underline' aria-label='Underline' />
                    </span>
                    <span className='ql-formats'>
                        <select className='ql-color' />
                        <select className='ql-background' />
                    </span>
                    <span className='ql-formats'>
                        <button type='button' className='ql-list' value='ordered' aria-label='Ordered List' />
                        <button type='button' className='ql-list' value='bullet' aria-label='Unordered List' />
                        <select className='ql-align'>
                            <option defaultValue />
                            <option value='center' />
                            <option value='right' />
                            <option value='justify' />
                        </select>
                    </span>
                    <span className='ql-formats'>
                        <button type='button' className='ql-link' aria-label='Insert Link' />
                        <button type='button' className='ql-image' aria-label='Insert Image' />
                        <button type='button' className='ql-code-block' aria-label='Insert Code Block' />
                    </span>
                    <span className='ql-formats'>
                        <button type='button' className='ql-clean' aria-label='Remove Styles' />
                    </span>
                </div>
            );
        }

        const content = (
            <div
                ref={(el) => (this.editorElement = el)}
                className={!!this.props.blackMode ? 'p-editor-content-about' : 'p-editor-content'}
                style={this.props.style}
            />
        );

        return (
            <div id={this.props.id} className={containerClass}>
                {toolbarHeader}
                {content}
            </div>
        );
    }
}

Editor.defaultProps = {
    blackMode: false,
    id: null,
    value: null,
    style: null,
    externalPhotos: false,
    className: null,
    placeholder: null,
    readOnly: false,
    modules: null,
    formats: null,
    theme: 'snow',
    headerTemplate: null,
    onTextChange: null,
    onSelectionChange: null,
};

Editor.propTypes = {
    ariaDescribedBy: PropTypes.string,
    ariaLabel: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
    blackMode: PropTypes.bool,
    className: PropTypes.string,
    formats: PropTypes.array,
    externalPhotos: PropTypes.bool,
    headerTemplate: PropTypes.any,
    id: PropTypes.string,
    modules: PropTypes.object,
    onSelectionChange: PropTypes.func,
    onTextChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    style: PropTypes.object,
    theme: PropTypes.string,
    value: PropTypes.string,
};

export default Editor;
