import {TabPanel, TabView} from 'primereact/tabview';
import React from 'react';
import {withTranslation} from 'react-i18next';
import TemplateListContainer from './templates/TemplateListContainer';
import AuthService from '../services/AuthService';
import AgreementDataDraftListContainer from './templates/AgreementDataDraftListContainer';
import {CustomMessages} from '../components/CustomMessages';
import BaseContainer from '../baseContainers/BaseContainer';
import BaseBreadCrumb from '../baseContainers/BaseBreadCrumb';

window.legalConsultationDialogRef = undefined;
window.tabViewLegalRef = undefined;

class AgreementListContainer extends BaseContainer {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.authService = new AuthService();
        this.legalConsultationDialogRef = React.createRef();
        this.tabViewLegalRef = React.createRef();
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    handleTabChange(e) {
        this.setState(
            {
                activeIndex: e.index,
            },
            () => {
                this.saveCookie(`agreement-draft-list-activeIndex`, this.state.activeIndex);
            }
        );
    }
    readCookie(cookieName) {
        return sessionStorage.getItem(cookieName);
    }
    saveCookie(cookieName, cookieValue) {
        sessionStorage.setItem(cookieName, cookieValue);
    }
    componentDidMount() {
        let activeIndex = this.readCookie(`agreement-draft-list-activeIndex`);
        let msg = this.readCookie('user-template-list-container');
        if (activeIndex === undefined || activeIndex === null || activeIndex === '') {
            activeIndex = 0;
        }
        this.setState({activeIndex: parseInt(activeIndex)});
        if (msg !== null) {
            const message = JSON.parse(msg);
            this.showMessage(message.severity, message.summary, message.detail);
            this.removeCookie('user-template-list-container');
        }
    }
    breadCrumbItems() {
        return [];
    }

    cardStyle() {
        if (this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE') && this.state.activeIndex === 1) {
            return '';
        }
        return 'card-unstylled';
    }
    render() {
        const tabsLeksyMargin = this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE') ? 'tabs-leksy-margin' : '';
        return (
            <React.Fragment>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                <BaseBreadCrumb items={this.breadCrumbItems()} />
                {this.props?.publicMode ? (
                    <TemplateListContainer
                        key='template-list'
                        subscriptionId={this.props.elementId}
                        currentUser={this.props.currentUser}
                        legalConsultationDialogRef={this.legalConsultationDialogRef}
                        tabViewLegalRef={this.tabViewLegalRef}
                        filterActiveIndex={1}
                    />
                ) : (
                    <div className={`${this.cardStyle()} ${tabsLeksyMargin}`}>
                        <TabView headerClassName={`container`} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange}>
                            <TabPanel header={'Dostępne szablony'} key={`template-list-${this.state.activeIndex}`}>
                                <TemplateListContainer
                                    key='template-list-avalable'
                                    subscriptionId={this.props.elementId}
                                    currentUser={this.props.currentUser}
                                    legalConsultationDialogRef={this.legalConsultationDialogRef}
                                    tabViewLegalRef={this.tabViewLegalRef}
                                    showMessage={this.showMessage}
                                />
                            </TabPanel>
                            <TabPanel
                                disabled={this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE')}
                                header={'Moje szkice'}
                                key={`agreement-list-${this.state.activeIndex}`}
                            >
                                <AgreementDataDraftListContainer
                                    key='drafts-list'
                                    currentUser={this.props.currentUser}
                                    embedded
                                    legalConsultationDialogRef={this.legalConsultationDialogRef}
                                    tabViewLegalRef={this.tabViewLegalRef}
                                    showMessage={this.showMessage}
                                />
                            </TabPanel>
                        </TabView>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
export default withTranslation()(AgreementListContainer);
