import {useEffect}  from 'react';
import {useHistory} from 'react-router-dom';

export default function RedirectWithHistory(props) {
    const history = useHistory();

    useEffect(() => {
        history.push(props.url);
    }, []);

    return <></>
};
