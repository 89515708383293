import React from 'react';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import DivContainer from '../../components/DivContainer';
import {ActionButton} from '../../components/ActionButton';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InternalUserService from '../../services/InternalUserService';
import SpecializationService from '../../services/SpecializationService';
import UserModel from '../../model/UserModel';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import UserRoleService from '../../services/UserRoleService';
import SubscriptionService from '../../services/SubscriptionService';
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';
import UserService from '../../services/UserService';
import {CustomMessages} from '../../components/CustomMessages';
import DialogResizer from '../../components/DialogResizer';

class SystemUserDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new InternalUserService());
        // rest
        this.state = {
            element: new UserModel(),
            viewMode: this.props.viewMode,
            activeIndex: 0,
            tabActiveIndex: 0,
            selectAllPermissions: false,
            fakeStatus: 'ACTIVE',
            packages: [],
            specializations: [],
            personOptions: [
                {
                    label: 'Prawnik',
                    enumValue: 'LAWYER',
                },
                {
                    label: 'Osoba',
                    enumValue: 'PERSON',
                },
            ],
        };
        this.userRoleService = new UserRoleService();
        this.subscriptionService = new SubscriptionService();
        this.specializationService = new SpecializationService();
        this.userService = new UserService();
        this.handleChangeStep = this.handleChangeStep.bind(this);
        this.selectAllPersmissions = this.selectAllPersmissions.bind(this);
        this.fetchPermissionsOpt = this.fetchPermissionsOpt.bind(this);
        this.setPerson = this.setPerson.bind(this);
        this.isConsultantSelected = this.isConsultantSelected.bind(this);
        this.customClassName = this.customClassName.bind(this);
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton
                    label={t('global.backStepBtn')}
                    variant={'button cancel-button gray'}
                    buttonType='PREV'
                    handleClick={this.handleChangeStep.bind(this, {index: this.state.activeIndex}, true)}
                    rendered={this.state.activeIndex === 1}
                />
                <ActionButton
                    label={this.state.viewMode === 'NEW' ? t('user.addButton') : t('details.saveChanges')}
                    variant='button save-button blue'
                    buttonType='ADD'
                    handleClick={this.handleFormSubmit}
                    rendered={this.state.activeIndex === 1 || this.state.element.person?.enumValue === 'LAWYER'}
                />
                <ActionButton
                    isNextBtn={true}
                    label={t('details.next')}
                    buttonType='NEXT'
                    variant='button save-button blue '
                    handleClick={this.handleChangeStep.bind(this, {index: this.state.activeIndex}, false)}
                    rendered={!(this.state.activeIndex === 1) && this.state.element.person?.enumValue === 'PERSON'}
                />
            </div>
        );
    }

    isConsultantSelected() {
        let permission = this.state?.element?.permissions;
        return permission?.find((el) => el.permissionGroup === 'ROLE_LAW_OFFICE_CONSULTANT' && el.selected === true) ? true : false;
    }

    renderHeader() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label> Nowy użytkownik </label>
                </DivContainer>
            </DivContainer>
        );
    }

    getContainerListName() {
        return 'system-user-list-container';
    }

    getAddSucces() {
        const {t} = this.props;
        return t('user.addUserSuccess');
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('user.updateUserSuccess');
    }

    handleValidForm() {
        const {element} = this.state;
        let userModel = UserModel.copy(element);
        this.scrollToTop();
        if (this.props._isMounted) {
            DialogResizer.removeMaskIfExistOneDialog();
            this.service
                .add(userModel)
                .then(() => {
                    this.persistMessage('success', '', 'Utworzono użytkownika oraz wysłano link do ustanowienia hasła');
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                })
                .catch((err) => {
                    this.persistMessage('error', '', err.message);
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                });
        }
        this.unblockUi();
    }

    customClassName() {
        return `${this.props.className} ${this.state.element.role?.name === 'Pracownik kancelarii RPKP' ? 'lawyer' : 'admin'}`;
    }
    keyFunction() {
        return `${this.props.className} ${this.state.element.role?.name === 'Pracownik kancelarii RPKP' ? 'lawyer' : 'admin'}`;
    }

    handleChangeStep(e, backStep) {
        if (backStep === true) {
            e.index--;
            this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
        } else {
            if (this.validator.allValid()) {
                this.validator.hideMessages();
                if (e.index === 0) {
                    this.userService
                        .isEmailExist(this.state.element.email)
                        .then((emailExist) => {
                            if (emailExist) {
                                this.showErrorMessage('Podany email istnieje w systemie');
                                return;
                            } else {
                                e.index++;
                                this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
                            }
                            this.unblockUi();
                        })
                        .catch(() => {
                            this.showErrorMessage('Problem z pobieraniem informacji o mailach');
                            return;
                        });
                } else {
                    e.index++;
                    this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
                }
            } else {
                this.validator.showMessages();
                // this.scrollToError = true;
                this.forceUpdate();
            }
        }
    }

    setPerson(e, name) {
        let person = '';
        if (name === 'Pracownik kancelarii RPKP') {
            person = this.state.personOptions[0];
        } else {
            person = this.state.personOptions[1];
        }

        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                person: person,
            },
        }));
    }

    getSpecializations() {
        new SpecializationService()
            .getAllSpecializations()
            .then((specializations) => {
                this.setState({specializations});
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });
    }

    initBeforeSetElement() {
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                person: this.state.personOptions[1],
                address: undefined,
            },
        }));
    }

    renderDetails() {
        return (
            <div>
                {this.state.activeIndex === 0 ? this.renderFirstStep() : null}
                {this.state.activeIndex === 1 && this.state.element.person.enumValue === 'PERSON' ? this.renderSecondStep() : null}
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)}></CustomMessages>
            </div>
        );
    }

    fetchPermissionsOpt() {
        this.userRoleService
            .getPermissionsByRoleName(this.state.element?.role?.name)
            .then((permissions) => {
                this.setState(
                    (prevState) => ({
                        element: {
                            ...prevState.element,
                            permissions: permissions,
                        },
                    }),
                    () => super.initAfterSetElement()
                );
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
                super.initAfterSetElement();
            });
    }

    selectAllPersmissions(a, b, c) {
        let permissions = this.state.element.permissions;
        for (let index = 0; index < permissions.length; index++) {
            permissions[index].selected = c.checked;
        }
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                permissions: permissions,
            },
            selectAllPermissions: c.checked,
        }));
    }

    renderSecondStep() {
        return (
            <React.Fragment>
                <div>
                    <InputCheckboxComponent
                        label={'Zaznacz wszystkie'}
                        labelOnRight
                        value={this.state.selectAllPermissions}
                        validator={this.validator}
                        validators='not_required'
                        colClass='col-md-12'
                        rendered={this.state.element?.person?.enumValue === 'PERSON'}
                        onChange={(a, b, c) => this.selectAllPersmissions(a, b, c)}
                        viewMode={this.props.viewMode}
                        positioning='col-md-12 form-group-checkbox-flex'
                    />
                </div>
                <div className='row'>{this.renderPermissions()}</div>
            </React.Fragment>
        );
    }

    renderPermissions() {
        return this.state.element.permissions.map((i, index) => {
            return (
                <div>
                    <InputCheckboxComponent
                        id={`${index}-permission`}
                        name='selected'
                        label={i.description}
                        labelOnRight
                        overideLabelClassName={'p-checkbox-label h5_5'}
                        value={!!i.selected}
                        insideTable
                        validator={this.validator}
                        validators='not_required'
                        colClass='col-md-12'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        stateField={`element.permissions[${index}]`}
                        positioning='col-md-12 form-group-checkbox-flex'
                    />
                </div>
            );
        });
    }

    renderFirstStep() {
        const {person} = this.state.element;

        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='email'
                        placeholder='Wpisz email'
                        name='email'
                        label={'Email'}
                        colClass='col-md-4'
                        value={this.state.element.email}
                        validator={this.validator}
                        validators='required|email|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='firstName'
                        placeholder='Wpisz imię'
                        name='firstName'
                        label={'Imię'}
                        colClass='col-md-4'
                        value={this.state.element.firstName}
                        validator={this.validator}
                        validators='required|alpha_space_dash|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='lastName'
                        placeholder='Wpisz nazwisko'
                        name='lastName'
                        label={'Nazwisko'}
                        colClass='col-md-4'
                        value={this.state.element.lastName}
                        validator={this.validator}
                        validators='required|alpha_space_dash|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />

                    <InputDropdownComponent
                        id='role'
                        name='role'
                        label={'Rola'}
                        colClass='col-md-12'
                        value={this.state.element.role}
                        validator={this.validator}
                        options={this.props.roleOptions}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        optionLabel='name'
                        dataKey='id'
                        validators='required'
                        valueView={this.state.element.role ? this.state.element.role.name : ''}
                        onAfterStateChange={(e) => {
                            this.fetchPermissionsOpt(e);
                            this.setPerson(this, this.state.element.role.name);
                            this.getSpecializations(this);
                        }}
                        validateViewMode
                    />
                    <InputMultiSelectComponent
                        id='specializations'
                        name='specializations'
                        colClass='col-md-12'
                        label='Specjalizacje'
                        value={this.state.element.specializations}
                        validator={this.validator}
                        validators={'required|array_required'}
                        onChange={this.handleChange}
                        rendered={person?.enumValue === 'LAWYER'}
                        viewMode={'EDIT'}
                        options={this.state.specializations}
                        dataKey='id'
                        optionLabel='name'
                        filter
                        filterBy='name'
                    />

                    {person?.enumValue === 'LAWYER' ? this.renderSecondStep() : null}
                </DivContainer>
            </React.Fragment>
        );
    }
}

SystemUserDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
    changePackageUrl: '/#/change-package',
};

SystemUserDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    changePackageUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
};

export default withTranslation()(SystemUserDialog);
