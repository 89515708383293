import React, {useEffect} from 'react';
import PDFViewer from 'mgr-pdf-viewer-react';
import {PropTypes} from 'prop-types';
import 'babel-runtime/core-js/json/stringify';

const PDFPreviewComponent = (props) => {
    const {base64} = props;
    const isMobileSafari = navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/) ? true : false;
    return (
        <PDFViewer
            scale={isMobileSafari ? 0.5 : 4}
            document={{
                base64: base64,
            }}
        />
    );
};

PDFPreviewComponent.propTypes = {
    base64: PropTypes.string.isRequired,
};

export default PDFPreviewComponent;
