import HtmlParserUtils from './htmlParser/HtmlParserUtils';
class TemplateUtils {
    static spaceHandler = function (e) {
        console.log('spaceHandler');
        const selection = window.getSelection() ? window.getSelection() : document.selection;
        let range = selection.getRangeAt(0);
        const element = range.commonAncestorContainer;
        if (element?.data === element?.parentElement?.innerText) {
            const dataLength = element?.data.length;
            if (dataLength === range?.endOffset && element.parentElement.className.includes('se-custom-tag')) {
                e.preventDefault();
                const space = '&nbsp;';
                element.parentElement.insertAdjacentHTML('afterend', space);
                selection.modify('move', 'right', 'character');
                // TODO: czasami działa szasami nie? hmmm... moze jakis interval na 1ms trzeba by pokminic
                // element.parentElement.insertAdjacentText('afterend', ' ');
            }
        }
    };

    static enterHandler = function (e, dialogFunction) {
        console.log('enterHandler');
        const selection = window.getSelection() ? window.getSelection() : document.selection;
        let range = selection.getRangeAt(0);
        const element = range.commonAncestorContainer;
        if (element) {
            if (this.isSpanWithCustomClassOrLocatedInSpanCustomClass(element)) {
                e.preventDefault();
                dialogFunction();
                return;
            }

            if(this.isEmptyLiElement(element)){
                const node= element.parentNode;
                if(!node?.lastElementChild){
                    return;
                }
                if (node.nodeType === Node.ELEMENT_NODE) {
                    node.removeChild(node.lastElementChild); 
                }
            }
            //default editor enter behaviour always for li nodes (expect span custom tag)
            if (this.isInsideLiNode(element)) {
                return;
            }
            if (!this.isSpan) {
                let customClassClicked = this.findCustomClass(element.parentNode, false);
                if (customClassClicked) {
                    e.preventDefault();
                    const emptyParagraf = window.document.createElement('p');
                    emptyParagraf.appendChild(window.document.createElement('br'));
                    customClassClicked.classList.remove(HtmlParserUtils.inlineCustomTagClassName());
                    range.insertNode(emptyParagraf);
                }
            } else if (this.isSpanWithCustomClassOrLocatedInStepCustomClass) {
                e.preventDefault();
                const br = window.document.createElement('br');
                range.insertNode(br);
                selection.removeAllRanges();
                range = document.createRange();
                range.setStartAfter(br);
                range.setEndAfter(br);
                selection.addRange(range);
            }
        }
    };
    static isInsideLiNode(node) {
        return [...document.querySelectorAll('li')].some((el) => el.contains(node));
    }
    static isSpan = function (node) {
        return node.nodeName.toLowerCase() === 'span';
    };

    static setVisibleNameForSubTemplates(subTemplates) {
        subTemplates.forEach((subTemplate) =>
            subTemplate.active
                ? (subTemplate.visibleName = subTemplate.name)
                : (subTemplate.visibleName = subTemplate.name + ' (Nieaktywny)')
        );
        return subTemplates;
    }
    static isEmptyLiElement = function (element) {
        const node =   element.parentNode;
        if(node){
            const keyboardChars = /[a-zA-Z0-9!@#$%^&*()-=_+[\]{};':"\\|,.<>/?]/;
            return node.nodeName === "LI" && !element.data.match(keyboardChars) && element.data.length === 1
        }
        return false;
    };

    static isSpanWithCustomClassOrLocatedInSpanCustomClass = function (node) {
        return [...document.querySelectorAll(`.${HtmlParserUtils.getCustomTagClassName()}`)].find(
            (el) => this.isSpan(el) && el.contains(node)
        );
    };
    static isSpanWithCustomClassOrLocatedInStepCustomClass(node) {
        const isLocatedInSpanCustomClass = [...document.querySelectorAll(`.${HtmlParserUtils.getCustomTagStepClassName()}`)].find(
            (el) => el !== node && el.contains(node) && this.isSpan(el)
        );
        if (isLocatedInSpanCustomClass) {
            return isLocatedInSpanCustomClass;
        }
    }
    static findCustomClass = function (child, found) {
        if (found) {
            return child;
        } else if (child) {
            let containsCustomClass = this.isSelectedElementOrItsDirectParentInsideCustomTag(child);
            if (containsCustomClass && containsCustomClass.length > 0) {
                return child;
            } else if (child.children) {
                for (let i = 0; i < child.children.length; i++) {
                    return this.findCustomClass(child.children[i], found);
                }
            }
        }
        return false;
    };
    static isSelectedElementOrItsDirectParentInsideCustomTag(child) {
        const customTagClassNames = [HtmlParserUtils.getCustomTagStepClassName(), HtmlParserUtils.getCustomTagClassName()];
        return customTagClassNames.filter((c) => {
            return (
                (child.classList?.length && child.classList.contains(c)) ||
                (child.parentNode.classList?.length && child.parentNode.classList.contains(c))
            );
        });
    }
}
export default TemplateUtils;
