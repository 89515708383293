import React from 'react';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import DivContainer from '../../components/DivContainer';
import {ActionButton} from '../../components/ActionButton';
import InternalUserService from '../../services/InternalUserService';
import SpecializationService from '../../services/SpecializationService';
import UserModel from '../../model/UserModel';
import {Steps} from '../../components/StepsComponent';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import UserRoleService from '../../services/UserRoleService';
import SubscriptionService from '../../services/SubscriptionService';
import PurchasedService from '../../services/PurchasedService';
import InputSelectButtonComponent from '../../components/inputs/InputSelectButtonComponent';
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';
import ManagerUserModel from '../../model/ManagerUserModel';
import LawOfficeModel from '../../model/LawOfficeModel';
import LawOfficeService from '../../services/LawOfficeService';
import UserService from '../../services/UserService';
import {CustomMessages} from '../../components/CustomMessages';
import CustomValidatorLabel from '../../components/CustomValidatorLabel';
import CustomValidator from '../../utils/CustomValidator';
import InputConsultantComponent from '../../components/inputs/ePrawnik/InputConsultantComponent';
import InputAddressComponent from '../../components/inputs/ePrawnik/InputAddressComponent';
import InputPhoneComponent from '../../components/inputs/ePrawnik/InputPhoneComponent';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import InputRadioButtonComponent from '../../components/inputs/InputRadioButtonComponent';

class LawOfficeListDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.state = {
            option: 'Osoba',
            element: new UserModel(),
            viewMode: this.props.viewMode,
            activeIndex: 0,
            tabActiveIndex: 0,
            fakeStatus: 'ACTIVE',
            selectedPackagesMap: new Map(),
            reponseFromCustomValidator: undefined,
            billingCycleMap: new Map(),
            backStep: false,
            specializations: [],
            personOptions: [
                {
                    label: 'Jednoosobowa',
                    enumValue: 'LAW_OFFICE',
                },
                {
                    label: 'Wieloosobowa',
                    enumValue: 'LAW_OFFICE_MANAGER',
                },
            ],
        };
        this.purchasedService = new PurchasedService();
        this.userRoleService = new UserRoleService();
        this.customValidator = new CustomValidator();
        this.subscriptionService = new SubscriptionService();
        this.lawOfficeService = new LawOfficeService();
        this.specializationService = new SpecializationService();
        this.userService = new UserService();
        this.handleChangeStep = this.handleChangeStep.bind(this);
        this.clearConsultantCompontents = this.clearConsultantCompontents.bind(this);
        this.showResponseFromCustomValidator = this.showResponseFromCustomValidator.bind(this);
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton
                    label={t('global.backStepBtn')}
                    buttonType='PREV'
                    variant={'button cancel-button gray'}
                    handleClick={this.handleChangeStep.bind(this, {index: this.state.activeIndex}, true)}
                    rendered={this.state.activeIndex === 1}
                />
                <ActionButton
                    buttonType='NEXT'
                    label={'Dodaj'}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                    rendered={this.state.activeIndex === 1}
                />
                <ActionButton
                    label={t('details.next')}
                    buttonType='NEXT'
                    variant='button save-button blue'
                    handleClick={this.handleChangeStep.bind(this, {index: this.state.activeIndex}, false)}
                    rendered={!(this.state.activeIndex === 1) && this.state.element.person !== undefined}
                />
            </div>
        );
    }

    renderHeader() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>Dodaj kancelarię</label>
                </DivContainer>
            </DivContainer>
        );
    }

    getContainerListName() {
        return 'law-office-list-container';
    }

    getAddSucces() {
        return 'Utworzono kancelarię';
    }

    showResponseFromCustomValidator() {
        const response = this.customValidator.atLeastOneOptionRequired(this.state.element.permissions, '')?.response;
        this.setState({
            reponseFromCustomValidator: response,
        });
        this.clearConsultantCompontents();
        return response;
    }
    isConsultantSelected() {
        let permission = this.state?.element?.permissions;
        return permission?.find((el) => el.permissionGroup === 'ROLE_LAW_OFFICE_CONSULTANT' && el.selected === true) ? true : false;
    }

    clearConsultantCompontents() {
        if (!this.isConsultantSelected()) {
            this.state.element.chatFee = undefined;
            this.state.element.mailingFee = undefined;
            this.state.element.videoChatFee = undefined;
        }
    }

    handleValidForm() {
        const {element} = this.state;
        let model = '';
        let lawOfficeModel = '';
        if (element.person.enumValue === 'LAW_OFFICE') {
            model = UserModel.copy(element);
        } else {
            model = new ManagerUserModel.copy(element);
            lawOfficeModel = new LawOfficeModel.copy(element);
            lawOfficeModel.name = element.companyName;
            model.ownedLawOffice = lawOfficeModel;
        }
        this.scrollToTop();
        if (this.props._isMounted) {
            this.service
                .add(model)
                .then(() => {
                    this.persistMessage('success', '', this.getAddSucces());
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                })
                .catch((err) => {
                    this.persistMessage('error', '', err.message);
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                });
        }
        this.unblockUi();
    }

    handleChangeStep(e, backStep) {
        if (backStep === true) {
            e.index--;
            this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
        } else {
            let permissionValidation = this.showResponseFromCustomValidator();
            if (this.validator.allValid() && !permissionValidation) {
                if (e.index === 0) {
                    this.userService
                        .isEmailExist(this.state.element.email)
                        .then((emailExist) => {
                            if (emailExist) {
                                this.showErrorMessage('Podany email istnieje w systemie');
                                return;
                            } else {
                                e.index++;
                                this.validator.hideMessages();
                                this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
                            }
                            this.unblockUi();
                        })
                        .catch(() => {
                            this.showErrorMessage('Problem z pobieraniem informacji o mailach');
                            return;
                        });
                } else {
                    e.index++;
                    this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
                }
            } else {
                this.validator.showMessages();
                // this.scrollToError = true;
                this.forceUpdate();
            }
        }
    }

    initBeforeSetElement() {
        this.specializationService
            .getAllSpecializations()
            .then((specializations) => {
                this.setState(() => ({
                    specializations,
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });

        this.userRoleService
            .getPermissionsByRoleName('Kancelaria partnerska')
            .then((permissions) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        permissions,
                    },
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });

        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                person: this.state.personOptions[0],
            },
        }));
    }

    renderDetails() {
        return (
            <div>
                {this.state.activeIndex === 0 ? this.renderFirstStep() : null}
                {this.state.activeIndex === 1 ? this.renderSecondStep() : null}
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)}></CustomMessages>
            </div>
        );
    }

    renderSecondStep() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='companyName'
                        placeholder='Wpisz nazwę kancelarii'
                        name='companyName'
                        label='Nazwa kancelarii'
                        value={this.state.element.companyName}
                        onChange={this.handleChange}
                        colClass='col-xl-4  col-md-6 col-12'
                        validators='required|max:50'
                        validator={this.validator}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='nip'
                        name='nip'
                        label='Numer NIP'
                        value={this.state.element.nip}
                        colClass='col-xl-4  col-md-6 col-12'
                        placeholder='Wpisz NIP'
                        onChange={this.handleChange}
                        validators={`required|nip`}
                        validator={this.validator}
                        viewMode={this.props.viewMode === 'EDIT' ? 'VIEW' : this.props.viewMode}
                    />
                    <InputAddressComponent
                        element={this.state.element}
                        validator={this.validator}
                        rendered={true}
                        handleChange={this.handleChange}
                        stateField={'element.address'}
                        viewMode={this.props.viewMode}
                    />
                </DivContainer>
            </React.Fragment>
        );
    }

    renderPermissions() {
        return this.state.element.permissions
            ? this.state.element.permissions.map((i, index) => {
                  return (
                      <div class='row' style={{width: '100%'}}>
                          <InputCheckboxComponent
                              id={`${index}-permission`}
                              name='selected'
                              label={i.description}
                              labelOnRight
                              value={!!i.selected}
                              validator={this.validator}
                              validators='not_required'
                              onAfterStateChange={this.showResponseFromCustomValidator}
                              colClass='col-md-12'
                              onChange={this.handleChange}
                              viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
                              stateField={`element.permissions[${index}]`}
                              positioning='col-md-12 form-group-checkbox-flex'
                          />
                      </div>
                  );
              })
            : null;
    }

    renderSpecializations() {
        return (
            <InputMultiSelectComponent
                id='specializations'
                name='specializations'
                colClass='col-xl-4  col-md-6 col-12'
                label='Specjalizacje'
                value={this.state.element.specializations}
                validator={this.validator}
                validators={'required|array_required'}
                onChange={this.handleChange}
                rendered={true}
                viewMode={'EDIT'}
                options={this.state.specializations}
                dataKey='id'
                optionLabel='name'
                filter
                filterBy='name'
            />
        );
    }

    renderFirstStep() {
        return (
            <React.Fragment>
                <DivContainer>
                    <DivContainer colClass='row'>
                        <InputRadioButtonComponent
                            id='person'
                            name='person'
                            colClass='col-12'
                            value={this.state.element.person}
                            label='Rodzaj konsultacji'
                            viewMode={this.props.viewMode}
                            labelOnRight
                            options={this.state.personOptions}
                            valueLabel='label'
                            itemColClass={'col-6'}
                            valueKey='enumValue'
                            validators={'required'}
                            onChange={this.handleChange}
                            validator={this.props.validator}
                        />
                        <InputTextComponent
                            id='email'
                            name='email'
                            label={'Email'}
                            colClass='col-xl-4 col-md-6 col-12'
                            value={this.state.element.email}
                            validator={this.validator}
                            placeholder='Wpisz email'
                            validators='required|email|max:100'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        <InputTextComponent
                            id='firstName'
                            name='firstName'
                            label={'Imię'}
                            colClass='col-xl-4 col-md-6 col-12'
                            value={this.state.element.firstName}
                            validator={this.validator}
                            placeholder='Wpisz imię'
                            validators='required|alpha_space_dash|max:100'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        <InputTextComponent
                            id='lastName'
                            name='lastName'
                            label={'Nazwisko'}
                            colClass='col-xl-4 col-md-6 col-12'
                            value={this.state.element.lastName}
                            placeholder='Wpisz nazwisko'
                            validator={this.validator}
                            validators='required|alpha_space_dash|max:100'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />

                        <InputTextComponent
                            id='bankAccountNumber'
                            name='bankAccountNumber'
                            label='Numer konta bankowego'
                            placeholder='Numer konta bankowego'
                            value={this.state.element.bankAccountNumber}
                            onChange={this.handleChange}
                            colClass='col-xl-4 col-md-6 col-12'
                            validators='required|numeric|min:26|max:26'
                            validator={this.validator}
                            viewMode={this.props.viewMode}
                        />
                        <InputPhoneComponent
                            id='phoneNumber'
                            name='phoneNumber'
                            label='Numer telefonu'
                            placeholder=' Wpisz numer telefonu'
                            value={this.state.element.phoneNumber}
                            onChange={this.handleChange}
                            colClass='col-xl-4 col-md-6 col-12'
                            validators='required|phone|max:9|min:9'
                            viewMode={this.props.viewMode}
                            validator={this.validator}
                        />

                        {this.renderSpecializations()}
                        {this.renderPermissions()}

                        <CustomValidatorLabel
                            colClass='col-md-12'
                            label={this.state.reponseFromCustomValidator?.message}
                            rendered={this.state.reponseFromCustomValidator?.visible}
                        />
                        <InputConsultantComponent
                            element={this.state.element}
                            validator={this.validator}
                            rendered={this.isConsultantSelected()}
                            handleChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                    </DivContainer>
                </DivContainer>
            </React.Fragment>
        );
    }
}

LawOfficeListDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
    changePackageUrl: '/#/change-package',
};

LawOfficeListDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    changePackageUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
};

export default withTranslation()(LawOfficeListDialog);
