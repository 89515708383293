import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';
import queryString from 'query-string';
import React from 'react';
import {Link} from 'react-router-dom';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import DivContainer from '../../components/DivContainer';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import InputPasswordComponent from '../../components/inputs/InputPasswordComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import SimpleReactValidator from '../../components/validator';
import BlockUi from '../../components/waitPanel/BlockUi';
import RegisterService from '../../services/RegistrationService';
import {Card} from 'primereact/card';

class AbstractConfirmPasswordContainer extends BaseDetailsContainer {
    // ABSTRACT METHODS
    getPasswordParams = () => {};
    getService = () => {};
    handleCorrectPasswordToken = (wsRes) => {};
    handleErrorPasswordToken = (wsErr) => {};
    handleCorrectPasswordChange = (wsRes) => {};
    renderDetail = () => {};

    constructor() {
        super();
        this.handleChangeOpt = this.handleChangeOpt.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.state = {
            element: {
                approval: [],
            },
            newPassword: '',
            repeatPassword: '',
            maxLength: 15,
            minLength: 5,
            lowerCaseCount: 3,
            upperCaseCount: 4,
            numbersCount: 0,
            specialCharactersCount: 0,
            status: '',
        };
        this.jwtRefreshBlocked = true;
        this.registrationService = new RegisterService();
        this.validator = new SimpleReactValidator({}, this.translate);
        this.afterPreLogoutUser = this.afterPreLogoutUser.bind(this);
        this.scrollToTopOnMount = false;
    }

    afterPreLogoutUser() {
        this.blockUi();
        console.log('after pre logout na formatce');
        console.log(this.props);
        this.getPasswordParams();
        const token = queryString.parse(this.props.location?.search)?.token;
        const tokenType = this.props.type === 'CREATE' ? 'USER_REGISTER' : 'PASSWORD_RECOVERY';
        this.blockUi();
        this.getService()
            .checkResetPasswordToken(token, tokenType)
            .then(this.handleCorrectPasswordToken)
            .catch(this.handleCorrectPasswordToken);
    }

    initBeforeSetElement() {
        if (this.props.type === 'CREATE') {
            this.registrationService
                .getAllApprovals()
                .then((approval) => {
                    this.setState((prevState) => ({
                        element: {
                            ...prevState.element,
                            approvalContents: approval,
                        },
                    }));
                })
                .catch((err) => {
                    this.showErrorMessage(err.message, 10000, true, 'Błąd przy pobieraniu listy zgód ');
                });
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.props.handlePreLogoutUser(this.afterPreLogoutUser);
        this.initBeforeSetElement();
    }

    handleChangeOpt(e) {
        console.log(e);
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    handleFormSubmit(e) {
        if (e) {
            e.preventDefault();
        }

        if (this.props.type === 'CREATE') {
            let selectedApproval = this.state.element.approvalContents;
            for (let index = 0; index < selectedApproval.length; index++) {
                if (selectedApproval[index].required && selectedApproval[index].selected !== true) {
                    this.showErrorMessage('Zaznacz obowiązkowe zgody');
                    return;
                }
            }
        }

        if (this.validator.allValid()) {
            const values = queryString.parse(this.props.location.search);

            const body = {newPassword: this.state.newPassword};
            const token = values.token;
            const tokenType = this.props.type === 'CREATE' ? 'USER_REGISTER' : 'PASSWORD_RECOVERY';
            this.getService()
                .verifyTokenAndSendNewPassword(token, tokenType, body)
                .then(this.handleCorrectPasswordChange)
                .catch((err) => {
                    this.messages.show({
                        closable: false,
                        life: 30000,
                        severity: 'error',
                        summary: 'Błąd',
                        detail: err.message,
                    });
                });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.setState({
                valid: false,
            });
            this.forceUpdate();
        }
    }

    renderApproval() {
        return this.state.element?.approvalContents
            ?.sort((x, y) => x.order - y.order)
            .map((i, index) => {
                return (
                    <DivContainer className='row my-5'>
                        <InputCheckboxComponent
                            id={`${index}-approval`}
                            name='selected'
                            label={i.content}
                            labelOnRight
                            renderLink={i.hyperlink != null ? `${new RegisterService().getPath()}/${i.hyperlink}` : undefined}
                            renderRequired={i.required === true}
                            value={!!i.selected}
                            validator={this.validator}
                            colClass='col-11'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
                            stateField={`element.approvalContents[${index}]`}
                            positioning='col-md-6 form-group-checkbox-flex'
                        />
                    </DivContainer>
                );
            });
    }

    render() {
        return (
            <React.Fragment>
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                    <div id='main-login'>
                        <Messages id='custom-messages' ref={(el) => (this.messages = el)} />
                        <div className='container'>
                            <div className='row'>
                                <div className='col-sm-9 col-md-7 col-lg-4 mx-auto'>
                                    <div className='card card-signin my-5 confirm-reset-password'>{this.renderDetail()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BlockUi>
            </React.Fragment>
        );
    }

    renderForm = () => {
        return (
            <Card>
                <div className='card-body'>
                    <h5 className='card-title text-align-left'>
                        <label className='title'>
                            {this.props.type && this.props.type === 'CREATE' ? 'Nadanie hasła' : 'Resetowanie hasła'}
                        </label>
                    </h5>
                    <form
                        className='form-signin'
                        onSubmit={(e) => {
                            this.handleFormSubmit(e);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                this.handleFormSubmit();
                            }
                        }}
                        //avoid setting red border just after the page has loaded (red border because of undefined required value in FF)
                        noValidate
                    >
                        <div className='row'>
                            <InputTextComponent
                                id='email'
                                name='email'
                                label='Email'
                                classLabel='black-label'
                                value={this.state.email}
                                onChange={(a, b, c) => this.handleChangeOpt(c)}
                                className={this.state.email ? 'form-control' : 'form-control invalid'}
                                type='text'
                                validators='required|email'
                                validator={this.validator}
                                viewMode='VIEW'
                                colClass='col-xl-12 col-lg-12 col-md-12 col-sm-12'
                            />
                            <InputPasswordComponent
                                id='newPassword'
                                label='Nowe hasło'
                                classLabel='black-label'
                                labelForValidator='Nowe hasło'
                                name='newPassword'
                                value={this.state.newPassword}
                                validator={this.validator}
                                onChange={(a, b, c) => this.handleChangeOpt(c)}
                                viewMode='EDIT'
                                validators={`required|extended_password:${this.state.minLength}:${this.state.maxLength}:${this.state.lowerCaseCount}:${this.state.upperCaseCount}:${this.state.numbersCount}:${this.state.specialCharactersCount}`}
                                colClass='col-12'
                                classInput={'black-label'}
                            />
                            <InputPasswordComponent
                                id='repeatPassword'
                                label='Powtórz nowe hasło'
                                name='repeatPassword'
                                classLabel='black-label'
                                value={this.state.repeatPassword}
                                feedback={false}
                                validator={this.validator}
                                onChange={(a, b, c) => this.handleChangeOpt(c)}
                                viewMode='EDIT'
                                validators={`required|equals:${this.state.newPassword}:Nowe hasło`}
                                colClass='col-12'
                                classInput={'black-label'}
                            />
                            {this.props.type === 'CREATE' ? this.renderApproval() : null}
                        </div>

                        <div className='confirm-password-change'>
                            <Button className='' label={'Zapisz'} />
                        </div>
                    </form>
                </div>
            </Card>
        );
    };

    getPasswordLabel() {
        let message =
            'Pole nowe hasło musi się składać z od :min do :max znaków, zawierać co najmniej :lower i :upper, :number oraz :special spośród =!@#$%^&*(){}[]|:";\'<>?,./';
        message = message.replace(':min', this.state.minLength);
        message = message.replace(':max', this.state.maxLength);

        message = message.replace(':lower', this.validator.declination(this.state.lowerCaseCount, 2));
        message = message.replace(':upper', this.validator.declination(this.state.upperCaseCount, 3));
        message = message.replace(':number', this.validator.declination(this.state.numbersCount, 4));
        message = message.replace(':special', this.validator.declination(this.state.specialCharactersCount, 5));
        return message;
    }
}
export default AbstractConfirmPasswordContainer;
