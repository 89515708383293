import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import InternalUserService from '../../services/InternalUserService';
import UserRoleService from '../../services/UserRoleService';
import SpecializationService from '../../services/SpecializationService';
import {Messages} from 'primereact/messages';
import {t} from 'i18next';
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';
import BaseUserDetailsContainer from '../../baseContainers/BaseUserDetailsContainer';
import YesNoDialog from '../../components/YesNoDialog';

class SystemUserContainer extends BaseUserDetailsContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.state = {
            loading: true,
            elementId: props.elementId,
            element: {
                status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            },
            isRemovable: true,
            specializations: [],
            showConfirmBlockDialog: false,
            showConfirmDeleteDialog: false,
            showConfirmUnblockDialog: false,
            statusOptions: [],
            renderSpecializations: false,
            roleOptions: [],
        };
        this.fetchPermissionsOpt = this.fetchPermissionsOpt.bind(this);
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    getBackLabel() {
        return 'Wróć do listy';
    }
    getAddSucces() {
        return 'Użytkownik został utworzony';
    }

    getUpdateSucces() {
        return 'Użytkownik został zaktualizowany';
    }
    getRemoveSucces() {
        return 'Użytkownik został usunięty';
    }

    updateElement(data) {
        this.setState(
            (prevState) => ({
                element: {
                    ...prevState.element,
                    id: data.id,
                    email: data.email,
                    person: data.person,
                    videoChatFee: data?.videoChatFee,
                    chatFee: data?.chatFee,
                    firstName: data?.firstName,
                    specializations: data?.specializations,
                    lastName: data?.lastName,
                    password: data.password,
                    role: data.role,
                    roleName: data.role.name,
                    status: data.status,
                },
            }),
            () => {
                this.initAfterSetElement();
            }
        );
    }

    editButton() {
        const {viewMode} = this.props;
        return this.prepareEditButton(viewMode === 'VIEW');
    }

    initBeforeSetElement() {
        new UserRoleService()
            .getRolesByNames([t('Roles.ROLE_LAW_OFFICE'), t('Roles.ROLE_ADMIN')])
            .then((roles) => {
                this.setState({
                    roleOptions: roles,
                });
            })
            .catch((err) => {
                this.messages.show({
                    severity: 'error',
                    summary: 'Nie udało się pobrać listy ról',
                    detail: err.message,
                });
            });
    }

    initAfterSetElement() {
        new UserRoleService()
            .getPermissionsByUserAndRole(this.state.element.id, this.state.element.role.id)
            .then((permissions) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        permissions: permissions,
                    },
                }));
            })
            .catch((err) => {
                this.messages.show({
                    severity: 'error',
                    summary: 'Nie udało sie pobrać listy uprawnień',
                    detail: err.message,
                });
            });
        if (this.state.element.person === 'LAWYER') {
            new SpecializationService()
                .getAllSpecializations()
                .then((specializations) => {
                    this.setState(() => ({
                        specializations: specializations,
                    }));
                })
                .catch((err) => {
                    this.messages.show({
                        severity: 'error',
                        summary: 'Nie udało sie pobrać listy uprawnień',
                        detail: err.message,
                    });
                });
        }
        this.unblockUi();
    }

    fetchPermissionsOpt() {
        new UserRoleService()
            .getPermissionsByUserAndRole(this.state.element.id, this.state.element.role.id)
            .then((permissions) => {
                this.setState(
                    (prevState) => ({
                        element: {
                            ...prevState.element,
                            permissions: permissions,
                        },
                    }),
                    () => super.initAfterSetElement()
                );
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
                super.initAfterSetElement();
            });
    }

    getContainerListName() {
        return 'system-user-list-container';
    }
    breadCrumbItems() {
        return [{name: 'Użytkownicy systemowi', url: '/#/system-users-list'}, {name: 'Użytkownik'}];
    }
    renderPermisssions() {
        return this.state.element.permissions
            ? this.state.element.permissions.map((i, index) => {
                  if (!(i.permissionGroup === 'ROLE_ADMIN_SUPER')) {
                      return (
                          <div class='row' style={{width: '100%'}}>
                              <InputCheckboxComponent
                                  id={`${index}-permission`}
                                  name='selected'
                                  label={i.description}
                                  labelOnRight
                                  value={!!i.selected}
                                  validator={this.validator}
                                  validators='not_required'
                                  colClass='col-md-12'
                                  onChange={this.handleChange}
                                  viewMode={'EDIT'}
                                  disabled={this.props.viewMode !== 'EDIT' || this.detectSelfEdition()}
                                  stateField={`element.permissions[${index}]`}
                                  positioning='col-md-12 form-group-checkbox-flex'
                              />
                          </div>
                      );
                  } else return null;
              })
            : null;
    }

    renderSpecializations() {
        return (
            <InputMultiSelectComponent
                id='specializations'
                name='specializations'
                colClass='col-md-4'
                label='Specjalizacje'
                value={this.state.element.specializations}
                validator={this.validator}
                validators={'required|array_required'}
                onChange={this.handleChange}
                rendered={this.state.element.person === 'LAWYER'}
                viewMode={'EDIT'}
                options={this.state.specializations}
                dataKey='id'
                optionLabel='name'
                filter
                filterBy='name'
            />
        );
    }
    render() {
        return (
            <div className='container-fluid'>
                {this.renderView()}
                <YesNoDialog
                    className={'confirmation-dialog'}
                    visible={this.state.showConfirmDeleteDialog}
                    header='Usuwanie użytkownika'
                    name='dialogVisibile'
                    onChange={(type, x, target) => {
                        if (target.value) {
                            this.removeAccount();
                        }
                        this.hideRemoveConfirmation();
                    }}
                    onHide={() => {
                        this.hideRemoveConfirmation();
                    }}
                    yesButtonLabel={'Tak'}
                    noButtonLabel={'Nie'}
                >
                    Czy na pewno chcesz usunąć użytkownika?
                </YesNoDialog>
            </div>
        );
    }

    renderDetails() {
        return (
            <React.Fragment>
                <Messages id='custom-messages' ref={(el) => (this.messages = el)} />
                <div>
                    {this.renderProperStructureForDetail(
                        <React.Fragment>
                            <InputTextComponent
                                id='firstName'
                                name='firstName'
                                label={'Imię'}
                                placeholder='Wprowadź imię'
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.firstName}
                                validator={this.validator}
                                validators='required|alpha_space_dash|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                            <InputTextComponent
                                id='lastName'
                                name='lastName'
                                label={'Nazwisko'}
                                placeholder='Wpisz nazwisko'
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.lastName}
                                validator={this.validator}
                                validators='required|alpha_space_dash|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />

                            <InputTextComponent
                                id='email'
                                name='email'
                                label={'Email'}
                                placeholder='Wpisz email'
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.email}
                                validator={this.validator}
                                wordBreak
                                validators='required|email|max:100'
                                onChange={this.handleChange}
                                viewMode={'VIEW'}
                            />

                            <InputDropdownComponent
                                id='role'
                                name='role'
                                label={'Rola'}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.role}
                                validator={this.validator}
                                options={this.state.roleOptions}
                                onChange={this.handleChange}
                                disabled={
                                    this.state.element.roleName === 'Kancelaria' ||
                                    this.state.element.roleName === 'Prawnik' ||
                                    this.detectSelfEdition
                                }
                                viewMode={'VIEW'}
                                optionLabel='name'
                                dataKey='id'
                                validators='required'
                                valueView={this.state.element.role ? this.state.element.role.name : ''}
                                onAfterStateChange={(e) => this.fetchPermissionsOpt(e)}
                                validateViewMode
                            />
                            <InputDropdownComponent
                                id='status'
                                name='status'
                                label={'Status'}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.translate(`UserActivityStatus.${this.state.element.status}`)}
                                validator={this.validator}
                                options={this.state.statusOptions}
                                viewMode={'VIEW'}
                                optionLabel='label'
                                dataKey='enumValue'
                                validators='not_required'
                                rendered={this.props.viewMode}
                                valueView={this.state.element.status ? this.state.element.status.label : ''}
                            />
                        </React.Fragment>
                    )}

                    <div className={`row`}>
                        {this.renderBorderTabForLexdocs('Uprawnienia')}
                        {this.renderProperStructureForDetail(
                            <React.Fragment>
                                {this.renderPermisssions()}
                                <InputMultiSelectComponent
                                    id='specializations'
                                    name='specializations'
                                    colClass='col-md-12'
                                    label='Specjalizacje'
                                    value={this.state.element.specializations}
                                    validator={this.validator}
                                    validators={'required|array_required'}
                                    onChange={this.handleChange}
                                    rendered={this.state.element?.person === 'LAWYER'}
                                    viewMode={this.props.viewMode}
                                    options={this.state.specializations}
                                    dataKey='id'
                                    optionLabel='name'
                                    filter
                                    filterBy='name'
                                    validateViewMode
                                />
                            </React.Fragment>,
                            false
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

SystemUserContainer.defaultProps = {
    backUrl: '/#/system-users-list',
    cancelUrl: '/#/system-user/details',
    editUrl: '/#/system-user/edit',
};

SystemUserContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withTranslation()(React.memo(SystemUserContainer));
