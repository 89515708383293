import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../baseContainers/BaseDialogContainer';
import {ActionButton} from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import SettingModel from '../model/SettingModel';
import SettingService from '../services/SettingService';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import Constants from '../utils/constants';
import DialogResizer from '../components/DialogResizer';

class SettingDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new SettingService());
        this.state = {
            element: new SettingModel(),
            viewMode: this.props.viewMode,
            booleanOptions: Constants.BOOLEAN_OPTIONS,
        };
        this.onHide = this.onHide.bind(this);
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('Zapisz')}
                    buttonType={'NEXT'}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                />
            </div>
        );
    }

    onHide() {
        DialogResizer.removeMaskIfExistOneDialog();
        this.props.onHide();
    }

    afterUpdateSuccess(response) {
        DialogResizer.removeMaskIfExistOneDialog();
        const {backUrl} = this.props;
        this.blockUi();
        this.persistMessage('success', '', this.getUpdateSucces(response));
        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
        this.props.afterSave();
    }
    getUpdateSucces() {
        const {t} = this.props;
        return t('setting.updateSettingSuccess');
    }

    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>{t('setting.editHeader')}</label>
                </DivContainer>
            </DivContainer>
        );
    }
    updateElement(data) {
        if (this.isBooleanTypes(data.type)) {
            data.value = data.value === 'true';
        }
        this.setState(
            {
                element: SettingModel.copy(data),
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    getContainerListName() {
        return 'setting-list-container';
    }

    isBooleanTypes(type) {
        return [
            'TEMPLATE_SAVE_AFTER_GENERATE',
            'CONSULTATION_MAILING_AVAILABILITY_LEKSY',
            'CONSULTATION_CHAT_AVAILABILITY_LEKSY',
            'CONSULTATION_VIDEO_CHAT_AVAILABILITY_LEKSY',

            'CONSULTATION_MAILING_AVAILABILITY_LEXDOCS',
            'CONSULTATION_CHAT_AVAILABILITY_LEXDOCS',
            'CONSULTATION_VIDEO_CHAT_AVAILABILITY_LEXDOCS',
        ].find((t) => t === type);
    }

    renderDetails() {
        const {t} = this.props;
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='name'
                        name='name'
                        label={t('setting.name')}
                        colClass='col-md-6 col-sm-12'
                        value={this.state.element.name}
                        validator={this.validator}
                        validators='required|alpha_space_dash|max:100'
                        onChange={this.handleChange}
                        viewMode='VIEW'
                    />

                    {this.isBooleanTypes(this.state.element.type) ? (
                        <InputDropdownComponent
                            id='value'
                            name='value'
                            label={t('setting.value')}
                            colClass='col-md-6 col-sm-12'
                            value={this.state.element.value}
                            validator={this.validator}
                            validators='required|max:100'
                            onChange={() => {
                                this.setState((prevState) => ({
                                    element: {
                                        ...prevState.element,
                                        value: !this.state.element.value,
                                    },
                                }));
                            }}
                            optionLabel={'label'}
                            optionValue={'value'}
                            enumMode={false}
                            viewMode={this.props.viewMode}
                            options={this.state.booleanOptions}
                        />
                    ) : (
                        <InputTextComponent
                            id='value'
                            name='value'
                            label={t('setting.value')}
                            colClass='col-md-6 col-sm-12'
                            value={this.state.element.value}
                            validator={this.validator}
                            validators='required|max:100'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                    )}
                </DivContainer>
            </React.Fragment>
        );
    }
}

SettingDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
    backUrl: '/#/setting-list',
};

SettingDialog.propTypes = {
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
};

export default withTranslation()(SettingDialog);
