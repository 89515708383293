import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import './chat.scss';

export const ChatNotificationType = {
    newChat: 'NEW_CHAT',
    newVideoChat: 'NEW_VIDEO_CHAT',
    waitForPayment: 'WAIT_FOR_PAYMENT',
    waitForClient: 'WAIT_FOR_CLIENT',
};

class ChatNotification extends React.Component {
    render() {
        let message;
        switch (this.props.type) {
            case ChatNotificationType.newChat:
                message = 'Nowe zapytanie w komunikatorze, kliknij żeby rozpocząć czat tekstowy';
                break;
            case ChatNotificationType.newVideoChat:
                message = 'Nowe zapytanie w komunikatorze, kliknij żeby rozpocząć czat wideo';
                break;
            case ChatNotificationType.waitForPayment:
                message =
                    'Oczekiwanie na opłacenie konsultacji. Nie akceptuj żadnych konsultacji do czasu opłacenia konsultacji przez klienta.';
                break;
            case ChatNotificationType.waitForClient:
                message = `Nowa konsultacja o ${moment(this.props.startDate, 'YYYY-MM-DDTHH:mm:ss').format(
                    'HH:mm'
                )}. Oczekiwanie na połączenie klienta: ${this.props.clientName}`;
                break;
            default:
                message = '';
        }
        return (
            <React.Fragment>
                <div>
                    <button
                        type='button'
                        className='p-messages-close p-link'
                        onClick={() => {
                            if (this.props.handleOpenChatWindowAction) {
                                this.props.handleOpenChatWindowAction();
                            }
                        }}
                    >
                        {message}
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

ChatNotification.defaultProps = {
    type: ChatNotificationType.newQuery,
};

ChatNotification.propTypes = {
    handleOpenChatWindowAction: PropTypes.func,
    type: PropTypes.instanceOf(ChatNotificationType),
    clientName: PropTypes.string,
    startDate: PropTypes.string,
};

export default ChatNotification;
