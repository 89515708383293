import ReconnectingWebSocket from 'reconnecting-websocket';
import {v4 as uuidv4} from 'uuid';
import AuthService from './AuthService';

export const EventType = {
    DISCONNECT: 'DISCONNECT',
    CONNECT: 'CONNECT',
    REQUEST_START: 'REQUEST_START',
    REQUEST_END: 'REQUEST_END',
    MESSAGE: 'MESSAGE',
    USER_LOGGED: 'USER_LOGGED',
    USER_LOGOUT: 'USER_LOGOUT',
};

export const MessageType = {
    CLIENT_SETTINGS: 'CLIENT_SETTINGS',
    CANCEL_CONVERSATION: 'CANCEL_CONVERSATION',
    FEEDBACK_MESSAGE: 'FEEDBACK_MESSAGE',
    RESERVATION_CONFIRMED: 'RESERVATION_CONFIRMED',
    CONSULTANT_SETTINGS: 'CONSULTANT_SETTINGS',
    CHECK_CONSULTANT_AVAILABILITY: 'CHECK_CONSULTANT_AVAILABILITY',
    NEXT_PAYMENT_POSSIBILITY: 'NEXT_PAYMENT_POSSIBILITY',
    NEXT_PAYMENT: 'NEXT_PAYMENT',
    CONSULTANT_AVAILABILITY_STATUS: 'CONSULTANT_AVAILABILITY_STATUS',
    INCOMING_CONSULTATION: 'INCOMING_CONSULTATION',
    INCOMING_CONSULTATION_TIMEOUT: 'INCOMING_CONSULTATION_TIMEOUT',
    INCOMING_CONSULTATION_ALREADY_ACCEPTED: 'INCOMING_CONSULTATION_ALREADY_ACCEPTED',
    CONSULTATION_ACCEPTED_BY_CONSULTANT: 'CONSULTATION_ACCEPTED_BY_CONSULTANT',
    CONSULTATION_ACCEPTED_BY_CLIENT: 'CONSULTATION_ACCEPTED_BY_CLIENT',
    RESERVATION_BY_CLIENT: 'RESERVATION_BY_CLIENT',
    CONSULTATION_ESTABLISHED: 'CONSULTATION_ESTABLISHED',
    CONSULTATION_REJECTED_BY_CLIENT: 'CONSULTATION_REJECTED_BY_CLIENT',
    TEXT: 'TEXT',
    CLOSE_WINDOW: 'CLOSE_WINDOW',
    WINDOW_CLOSED_ON_OPPOSITE_SIDE: 'WINDOW_CLOSED_ON_OPPOSITE_SIDE',
    CONVERSATION_IN_PROGRESS: 'CONVERSATION_IN_PROGRESS',
    FETCH_MESSAGES: 'FETCH_MESSAGES',
    FETCH_MESSAGES_END: 'FETCH_MESSAGES_END',
    USER_DISCONNECTED: 'USER_DISCONNECTED',
    RELOAD_PAGE: 'RELOAD_PAGE',
    USER_CONNECTED: 'USER_CONNECTED',
    INVALIDATE_RESERVATION: 'INVALIDATE_RESERVATION',
    PAYMENT_CANCELLED: 'PAYMENT_CANCELLED',
    START_SCHEDULED_CONSULTATION: 'START_SCHEDULED_CONSULTATION',
    SCHEDULED_CONSULTATION_STARTED_TOO_EARLY: 'SCHEDULED_CONSULTATION_STARTED_TOO_EARLY',
    WAIT_FOR_CLIENT: 'WAIT_FOR_CLIENT',
    CONVERSATION_ALREADY_ESTABLISHED: 'CONVERSATION_ALREADY_ESTABLISHED',
};

export const SocketReadyState = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
};

export const ConsultationType = {
    CHAT: 'CHAT',
    VIDEO_CHAT: 'VIDEO_CHAT',
};

export default class ChatService {
    constructor() {
        if (ChatService.exists) {
            return ChatService.instance;
        }
        ChatService.exists = true;
        ChatService.instance = this;
        this.socket = undefined;
        this.connected = false;
        this.eventListener = undefined;
        this.authService = new AuthService();
        this.chatWindowsCountByUser = {};
        this.clientSettingsByUser = {};
        return this;
    }

    addEventListener(eventListener) {
        this.eventListener = eventListener;
    }

    onEvent(type, message) {
        if ((type === EventType.MESSAGE || type === EventType.REQUEST_START) && message) {
            switch (message.type) {
                case MessageType.CONSULTATION_ESTABLISHED:
                case MessageType.FETCH_MESSAGES_END:
                    this.registerChatWindow(message.conversationId);
                    break;
                case MessageType.WINDOW_CLOSED_ON_OPPOSITE_SIDE:
                case MessageType.CLOSE_WINDOW:
                    this.unregisterChatWindow(message.conversationId);
                    break;
                case MessageType.CLIENT_SETTINGS:
                    this.persistClientSettings(message);
                    break;
                default:
            }
        }

        if (this.eventListener) {
            this.eventListener(type, message);
        }
    }

    userLogged() {
        this.onEvent(EventType.USER_LOGGED);
    }

    userLogout() {
        this.onEvent(EventType.USER_LOGOUT);
    }

    connect(url) {
        console.log('socket: connecting...', url);
        this.close();
        this.socket = new ReconnectingWebSocket(url);
        this.socket.addEventListener('open', () => {
            console.log('this.socket connected!');
            this.connected = true;
            this.onEvent(EventType.CONNECT);
        });
        this.socket.addEventListener('open', () => {
            console.log('this.socket disconnected!');
            this.connected = false;
            this.onEvent(EventType.DISCONNECT);
        });
        this.socket.addEventListener('message', (m) => {
            const message = JSON.parse(m.data);

            this.onEvent(EventType.MESSAGE, message);
        });
    }

    close() {
        if (this.socket) {
            this.socket.close();
        }
    }

    validateSocketState() {
        if (!this.socket) {
            return false;
        }
        if (!this.socket.readyState || this.socket.readyState != SocketReadyState.OPEN) {
            this.onEvent(EventType.ERROR, 'Brak połączenia z serwerem konsultacji. Spróbuj ponownie później.');
            return false;
        }
        return true;
    }

    checkConsultantAvailability(specialization, consultationType, agreementUuid, unregisteredClient) {
        if (this.validateSocketState()) {
            const windowLimit = this.loadClientSettings()?.chatWindowsLimit;
            const windowCount = this.getChatWindowsCount();
            if (windowLimit <= windowCount) {
                this.onEvent(EventType.ERROR, `Maksymalna ilość równoczesnych otwartych sesji konsultacji wynosi ${windowLimit}.`);
                return;
            }

            let msg = {
                type: MessageType.CHECK_CONSULTANT_AVAILABILITY,
                uuid: uuidv4(),
                specialization: {
                    id: parseInt(specialization.id),
                    name: specialization.name,
                },
                consultationType: consultationType,
                agreementUuid: agreementUuid,
                unregisteredClient: unregisteredClient,
            };
            this.onEvent(EventType.REQUEST_START, msg);
            this.socket.send(JSON.stringify(msg));
            this.onEvent(EventType.REQUEST_END, msg);
        }
    }

    acceptByConsultant(conversationId, agreementUuid) {
        if (this.validateSocketState()) {
            let msg = {
                type: MessageType.CONSULTATION_ACCEPTED_BY_CONSULTANT,
                uuid: uuidv4(),
                conversationId,
                agreementUuid,
            };
            this.onEvent(EventType.REQUEST_START, msg);
            this.socket.send(JSON.stringify(msg));
            this.onEvent(EventType.REQUEST_END, msg);
        }
    }

    reservedByClient(event, consultationType) {
        let msg = {
            type: MessageType.RESERVATION_BY_CLIENT,
            uuid: uuidv4(),
            consultationId: event.consultationId,
            clientId: event.clientId,
            specializationId: event?.specialization?.id,
            consultantId: event.consultantId,
            agreementUuid: event?.agreementUuid?.toString(),
            consultationType: consultationType,
            startDate: event.start.getTime(),
        };

        this.onEvent(EventType.REQUEST_START, msg);
        this.socket.send(JSON.stringify(msg));
        this.onEvent(EventType.REQUEST_END, msg);
    }

    acceptByClient(conversationId, consultantAcceptanceRequired) {
        if (this.validateSocketState()) {
            let msg = {
                type: MessageType.CONSULTATION_ACCEPTED_BY_CLIENT,
                uuid: uuidv4(),
                conversationId,
                consultantAcceptanceRequired,
            };
            this.onEvent(EventType.REQUEST_START, msg);
            this.socket.send(JSON.stringify(msg));
            this.onEvent(EventType.REQUEST_END, msg);
        }
    }

    rejectedByClient(conversationId) {
        if (this.validateSocketState()) {
            let msg = {
                type: MessageType.CONSULTATION_REJECTED_BY_CLIENT,
                uuid: uuidv4(),
                conversationId,
            };
            this.onEvent(EventType.REQUEST_START, msg);
            this.socket.send(JSON.stringify(msg));
            this.onEvent(EventType.REQUEST_END, msg);
        }
    }

    sendTextMessage(conversationId, text) {
        if (this.validateSocketState()) {
            let msg = {
                type: MessageType.TEXT,
                uuid: uuidv4(),
                conversationId,
                text,
            };
            this.onEvent(EventType.REQUEST_START, msg);
            this.socket.send(JSON.stringify(msg));
            this.onEvent(EventType.REQUEST_END, msg);
        }
    }

    closeWindow(conversationId) {
        let msg = {
            type: MessageType.CLOSE_WINDOW,
            uuid: uuidv4(),
            conversationId,
        };
        this.onEvent(EventType.REQUEST_START, msg);
        this.socket.send(JSON.stringify(msg));
        this.onEvent(EventType.REQUEST_END, msg);
    }

    nextPaymentPossibility(conversationId) {
        let msg = {
            type: MessageType.NEXT_PAYMENT_POSSIBILITY,
            uuid: uuidv4(),
            conversationId,
        };
        this.onEvent(EventType.REQUEST_START, msg);
        this.socket.send(JSON.stringify(msg));
        this.onEvent(EventType.REQUEST_END, msg);
    }

    nextPayment(conversationId) {
        let msg = {
            type: MessageType.NEXT_PAYMENT,
            uuid: uuidv4(),
            conversationId,
        };
        this.onEvent(EventType.REQUEST_START, msg);
        this.socket.send(JSON.stringify(msg));
        this.onEvent(EventType.REQUEST_END, msg);
    }

    fetchMessages(conversationId) {
        let msg = {
            type: MessageType.FETCH_MESSAGES,
            uuid: uuidv4(),
            conversationId,
        };
        this.onEvent(EventType.REQUEST_START, msg);
        this.socket.send(JSON.stringify(msg));
        this.onEvent(EventType.REQUEST_END, msg);
    }

    invalidateReservation(conversationId) {
        let msg = {
            type: MessageType.INVALIDATE_RESERVATION,
            uuid: uuidv4(),
            conversationId,
        };
        this.onEvent(EventType.REQUEST_START, msg);
        this.socket.send(JSON.stringify(msg));
        this.onEvent(EventType.REQUEST_END, msg);
    }

    startScheduledConsultation(scheduledConsultationId) {
        if (this.validateSocketState()) {
            let msg = {
                type: MessageType.START_SCHEDULED_CONSULTATION,
                uuid: uuidv4(),
                scheduledConsultationId,
            };
            this.onEvent(EventType.REQUEST_START, msg);
            this.socket.send(JSON.stringify(msg));
            this.onEvent(EventType.REQUEST_END, msg);
        }
    }

    registerChatWindow(conversationId) {
        console.log('registerChatWindow: conversationId=' + conversationId);
        const currentUser = this.authService.getProfile();
        if (currentUser) {
            let conversations = this.chatWindowsCountByUser[currentUser.userId];
            if (!conversations) {
                conversations = [];
            }
            if (!conversations.includes(conversationId)) {
                conversations.push(conversationId);
            }
            this.chatWindowsCountByUser[currentUser.userId] = conversations;
        }
    }

    unregisterChatWindow(conversationId) {
        console.log('unregisterChatWindow: conversationId=' + conversationId);
        const currentUser = this.authService.getProfile();
        if (currentUser) {
            let conversations = this.chatWindowsCountByUser[currentUser.userId];
            if (!conversations) {
                conversations = [];
            }
            const index = conversations.indexOf(conversationId);
            if (index >= 0) {
                conversations.splice(index, 1);
            }
            this.chatWindowsCountByUser[currentUser.userId] = conversations;
        }
    }

    getChatWindowsCount() {
        console.log('getChatWindowsCount', this.chatWindowsCountByUser);
        const currentUser = this.authService.getProfile();
        if (currentUser) {
            const conversations = this.chatWindowsCountByUser[currentUser.userId];
            return conversations ? conversations.length : 0;
        }
        return 0;
    }

    persistClientSettings(settings) {
        const currentUser = this.authService.getProfile();
        if (currentUser) {
            this.clientSettingsByUser[currentUser.userId] = settings;
        }
    }

    loadClientSettings() {
        const currentUser = this.authService.getProfile();
        if (currentUser) {
            return this.clientSettingsByUser[currentUser.userId];
        }
        return 0;
    }

    testVideo() {
        let msg = {
            type: MessageType.CONSULTATION_ESTABLISHED,
            consultationType: ConsultationType.VIDEO_CHAT,
            uuid: uuidv4(),
        };
        this.onEvent(EventType.MESSAGE, msg);
    }
}
