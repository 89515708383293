import * as React from 'react';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useEffect} from 'react';
import DivContainer from './DivContainer';
import SessionUtils from '../utils/SessionUtils';
import AppPrefixUtils from '../utils/AppPrefixUtils';

export default function Stepper(props) {
    const steps = props.steps;
    const isLexyPortal = props.isLexyPortal;
    const agreementId = props.agreementId;
    const maxSteps = steps.length;
    const viewMode = props.viewMode;
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        if (isPenultimate()) {
            props.actionBeforePunultimateStep(() => {
                changeStep(true);
            });
        } else {
            props.beforeStepChange(() => {
                changeStep(true);
            });
        }
    };

    const changeStep = (nextStep) => {
        setActiveStep((prevActiveStep) => {
            const step = nextStep ? prevActiveStep + 1 : prevActiveStep - 1;
            if (viewMode === 'EDIT') {
                const activeStep = {
                    key: agreementId,
                    value: step,
                };
                SessionUtils.saveSession('active-step', activeStep);
            }
            return step;
        });
    };

    const handleBack = () => {
        changeStep(false);
    };
    const isPenultimate = () => {
        return activeStep === maxSteps - 2;
    };
    useEffect(() => {
        SessionUtils.saveSession('current-index-step', activeStep);
        props.updateActiveStepUUID(steps[activeStep]?.uuid);
    }, [activeStep]);

    const nextStepBtn = () => {
        if (isLexyPortal) {
            return (
                <i
                    key='nex-button'
                    onClick={handleNext}
                    className='icon mdi mdi-chevron-right'
                    style={{fontSize: '40px', padding: '0px', margin: '0px', cursor: 'pointer'}}
                ></i>
            );
        } else {
            return (
                <div className='next-prev-btns' onClick={handleNext}>
                    <span>Dalej</span>
                    <img src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/chevron_right.svg')} alt='' />
                </div>
            );
        }
    };
    const prevStepBtn = () => {
        if (isLexyPortal) {
            return (
                <i
                    key='back-button'
                    onClick={handleBack}
                    className='icon mdi mdi-chevron-left'
                    style={{fontSize: '40px', padding: '0px', margin: '0px', cursor: 'pointer'}}
                ></i>
            );
        } else {
            return (
                <div className='next-prev-btns' onClick={handleBack}>
                    <img src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/chevron_left.svg')} alt='' />
                    <span>Wstecz</span>
                </div>
            );
        }
    };
    const renderGenerateButton = () => {
        return props.renderGenerateButton();
    };
    useEffect(() => {
        setActiveStep(props.initializedIndex);
    }, [props.initializedIndex]);
    const buttonStyle = {
        borderRadius: '10px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '7px',
        paddingBottom: '7px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'Manrope',
        textTransform: 'none',
        borderColor: '#f0f0f0',
        borderWidth: '2px',
        borderStyle: 'solid',
        letterSpacing: '-0.14px',
        fontSize: '14px',
    };
    return (
        <React.Fragment>
            <div className='row' style={{boxShadow: 'none'}}>
                <p>{props.headerComponent}</p>
                <DivContainer rendered={props.visible} className='col-12 '>
                    {!isLexyPortal && (
                        <div id='consultation-panel' className='row col-12'>
                            <div className='col-6 cursor-pointer' onClick={() => props.onConsultation()}>
                                <span className='text'> Skonsultuj </span>
                                <img src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/headset_mic_blue.svg')} alt='' />
                            </div>
                            {props.status !== 'GENERATED' && (
                                <div className='col-6 float-right cursor-pointer' onClick={() => props.onSave()}>
                                    <img src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/save_consultation_blue.svg')} alt='' />
                                    <span className='text text-save'> Zapisz </span>
                                </div>
                            )}
                        </div>
                    )}
                    <Box key='box' sx={{boxShadow: 'none'}}>
                        <Paper
                            key='paper'
                            square
                            elevation={0}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                pl: 2,
                                bgcolor: 'background.default',
                                minWidth: '100%',
                            }}
                        >
                            <Typography key='typography'>
                                <span className='title'>{!steps[activeStep]?.hideLabel && steps[activeStep]?.label}</span>
                            </Typography>
                        </Paper>

                        {steps[activeStep]?.description ? (
                            <Box sx={{width: '100%', p: 2}}>
                                <div className='description-text'>{steps[activeStep]?.description}</div>
                            </Box>
                        ) : null}
                        {props ? props.renderStep(steps[activeStep], steps[activeStep]?.uuid, activeStep) : null}
                        <MobileStepper
                            id='mobile-stepper'
                            key='mobile-stepper'
                            variant='text'
                            steps={maxSteps}
                            position='static'
                            activeStep={activeStep}
                            nextButton={
                                activeStep === maxSteps - 1 ? (
                                    !isLexyPortal ? (
                                        renderGenerateButton()
                                    ) : (
                                        <React.Fragment />
                                    )
                                ) : (
                                    <div>{nextStepBtn()}</div>
                                )
                            }
                            backButton={activeStep !== 0 ? <div>{prevStepBtn()}</div> : <React.Fragment />}
                        />
                    </Box>
                </DivContainer>
            </div>
            <div className='inactive-steps'>
                {steps.map((step) => {
                    return step.uuid ? (
                        <div
                            className='agreement-content'
                            id={`agreement-content-${step.uuid}`}
                            dangerouslySetInnerHTML={{__html: step.html}}
                        />
                    ) : null;
                })}
            </div>
        </React.Fragment>
    );
}
