export class CommonReportUtils {
    // constants
    static margin = 15;
    static sectionSpacing = 45;
    static sectionSpacingLandscape = 80;
    static tFontSize = 11;
    static iFontSize = 13;
    static h1FontSize = 15;
    static h2FontSize = 12;
    static replacer = '-';
    static dFontSize = 9;
    static headerFontSize = 24;
    static lineHeight = 5;
    static footerHeigth = 10;

    static footer_x = 20;
    static footer_y = 175;

    static footer_landscape_x = 65;
    static footer_landscape_y = 175;

    static COLORS = {
        DARK_GRAY: '#2980ba',
        BLUE: '#2980ba',
        BASIC: '#1c3275',
        LIGHT: '#E8E8E8',
        RED: '#db1818',
        WHITE: '#FFFFFF',
    };

    // common methods
    static addPageIfContentToLong(y, contentHeight, doc) {
        const pageHeight = doc.internal.pageSize.height;
        if (y + contentHeight + CommonReportUtils.footerHeigth >= pageHeight) {
            console.log('Add new page from addPageIfContentToLong');
            doc.addPage();
            return CommonReportUtils.margin;
        }
        return y;
    }
    static newPage(doc) {
        doc.addPage();
        return CommonReportUtils.margin;
    }
    static createRectWithLongText(text, x, y, w, h, doc, color) {
        const pageHeight = doc.internal.pageSize.height;

        let addNewPage = y + h + CommonReportUtils.footerHeigth >= pageHeight;
        const textWidth = doc.getTextWidth(text);
        let textLines;
        console.log(textWidth);
        if (textWidth > w && w > 0) {
            textLines = Math.ceil(textWidth / w);
            h *= textLines;
            console.log(h);
        }
        try {
            console.log('%s %s %s %s %s %s %s', text, x, y, w, h, doc, color);
            // const lineLength = doc.internal.pageSize.getWidth() - (CommonReportUtils.margin * 2);

            if (addNewPage) {
                console.log('CreateRectWithText :: add new page');
                doc.addPage();
                y = CommonReportUtils.margin;
            }
            if (color == null) {
                color = CommonReportUtils.COLORS.BASIC;
            }
            doc.setFillColor(color);
            doc.rect(x, y, w, h, 'F');
            doc.setTextColor(1, 1, 1, 1);
            if (textWidth > w && w > 0) {
                console.log('&&&&&&&&&&& - display long string');
                const currentYExt = CommonReportUtils.displayLongStringExt(
                    text,
                    x + CommonReportUtils.margin * 0.1,
                    y + h / 5,
                    w,
                    h / textLines,
                    doc,
                    'center'
                );
                y = currentYExt.y;
                if (currentYExt.newPage === true) {
                    addNewPage = true;
                }
            } else {
                console.log('@@@@@ - doc text');
                doc.text(text, x + w / 2, y + (h / 5) * 3.3, 'center');
            }
            doc.setTextColor(0, 0, 0);
            return y;
        } catch (err) {
            console.error(`Error in createRectWithText: ${err}`);
            console.log('%s %s %s %s %s %s %s', text, x, y, w, h, doc, color);
            if (color == null) {
                color = CommonReportUtils.COLORS.BASIC;
            }
            doc.setFillColor(color);
            doc.rect(x, y, w, h, 'F');
            doc.setTextColor(1, 1, 1, 1);
            if (textWidth > w && w > 0) {
                console.log('&&&&&&&&&&& - display long string');
                const currentYExt = CommonReportUtils.displayLongStringExt(text, x, y, w, h, doc);
                y = currentYExt.y;
                if (currentYExt.newPage === true) {
                    addNewPage = true;
                }
            } else {
                console.log('@@@@@ - doc text');
                doc.text(text, x + w / 2, y + (h / 5) * 3.3, 'center');
            }
            doc.setTextColor(0, 0, 0);
            return y;
        }
    }

    static createRectWithText(text, x, y, w, h, doc, color, textColor) {
        try {
            console.log(w, 'wwww');
            console.log('%s %s %s %s %s %s %s', text, x, y, w, h, doc, color);
            // const lineLength = doc.internal.pageSize.getWidth() - (CommonReportUtils.margin * 2);
            const pageHeight = doc.internal.pageSize.height;
            const addNewPage = y + h + CommonReportUtils.footerHeigth >= pageHeight;
            if (addNewPage) {
                console.log('CreateRectWithText :: add new page');
                doc.addPage();
                y = CommonReportUtils.margin;
            }
            if (color == null) {
                color = CommonReportUtils.COLORS.BASIC;
            }
            doc.setFillColor(color);
            doc.rect(x, y, w, h, 'F');
            if (textColor == null) {
                doc.setTextColor(1, 1, 1, 1);
            } else {
                doc.setTextColor(textColor);
            }
            doc.text(text, x + w / 2, y + (h / 5) * 3.3, 'center');
            doc.setTextColor(0, 0, 0);
            return y;
        } catch (err) {
            console.error(`Error in createRectWithText: ${err}`);
            console.log('%s %s %s %s %s %s %s', text, x, y, w, h, doc, color);
            if (color == null) {
                color = CommonReportUtils.COLORS.BASIC;
            }
            doc.setFillColor(color);
            doc.rect(x, y, w, h, 'F');
            doc.setTextColor(1, 1, 1, 1);
            doc.text(text, x + w / 2, y + (h / 5) * 3.3, 'center');
            doc.setTextColor(0, 0, 0);
            return y;
        }
    }

    static createSubtitle(text, x, y, fontSize, doc) {
        try {
            const lineLength = doc.internal.pageSize.getWidth() - CommonReportUtils.margin * 2;
            let valueY = y + CommonReportUtils.lineHeight;
            const addNewPage = CommonReportUtils.checkIfAddNewPage(text, y, lineLength, CommonReportUtils.lineHeight, doc);
            if (addNewPage) {
                console.log('CreateSubtitle :: add new page');
                doc.addPage();
                valueY = CommonReportUtils.margin + CommonReportUtils.lineHeight;
            }
            doc.setFontSize(fontSize * 1.7);
            doc.setFont('Roboto-Bold');
            const currentY = CommonReportUtils.displayLongString(text, x, valueY, lineLength, CommonReportUtils.lineHeight, doc);
            doc.setFont('roboto');
            doc.setFontSize(fontSize);
            return currentY + CommonReportUtils.lineHeight * 0.5;
        } catch (err) {
            console.error(`Error in createSubtitle: ${err}`);
            doc.setFontSize(fontSize * 1.7);
            doc.setFont('Roboto-Bold');
            doc.text(text, x, y);
            doc.setFont('roboto');
            doc.setFontSize(fontSize);
            return y + CommonReportUtils.lineHeight * 0.5;
        }
    }

    static createSubSubtitle(text, x, y, fontSize, doc) {
        doc.setFontSize(this.tFontSize);
        doc.text(text, x, y);
        doc.setFont('roboto');
        doc.setFontSize(fontSize);
    }

    // deprecated
    static titleAndDetail(title, detail, lineHeight, lineLength, x, y, tFontSize, dFontSize, doc) {
        if (detail === undefined) detail = CommonReportUtils.replacer;
        doc.setFontSize(tFontSize);
        doc.text(title, x, y);
        doc.setFontSize(dFontSize);
        return CommonReportUtils.displayLongString(detail.toString(), x, y + lineHeight, lineLength, lineHeight, doc);
    }

    static createTitleAndDetail(label, value) {
        return {
            label,
            value,
        };
    }

    static createLabelOnly(labelText) {
        return {
            label: labelText,
            value: '-|creatEmptySpace|-',
        };
    }

    static creatEmptySpace() {
        return {
            label: '',
            value: '-|creatEmptySpace|-',
        };
    }

    static arrangeInHorizontalAutoProps(y, props, doc, ...args) {
        const argCount = args.length;
        const sectionSpacing = (doc.internal.pageSize.getWidth() - CommonReportUtils.margin * 2) / argCount;
        if (props === undefined) props = {};
        if (props.tFontSize === undefined) props.tFontSize = CommonReportUtils.tFontSize;
        if (props.dFontSize === undefined) props.dFontSize = CommonReportUtils.dFontSize;
        if (props.marginLeftValue === undefined) props.marginLeftValue = CommonReportUtils.margin;
        console.log(`arrangeInHorizontalAutoProps props ${JSON.stringify(props)}`);
        return CommonReportUtils.arrangeInHorizontal(y, doc, sectionSpacing, props.tFontSize, props.dFontSize, props.marginLeftValue, args);
    }

    static arrangeInHorizontalAuto(y, doc, ...args) {
        const argCount = args.length;
        const sectionSpacing = (doc.internal.pageSize.getWidth() - CommonReportUtils.margin * 2) / argCount;
        const tFontSize = CommonReportUtils.tFontSize;
        const dFontSize = CommonReportUtils.iFontSize;
        const marginLeftValue = CommonReportUtils.margin;
        return CommonReportUtils.arrangeInHorizontal(y, doc, sectionSpacing, tFontSize, dFontSize, marginLeftValue, args);
    }

    static arrangeInHorizontalColumn(y, columnNumber, doc, ...args) {
        const sectionSpacing = (doc.internal.pageSize.getWidth() - CommonReportUtils.margin * 2) / columnNumber;
        const tFontSize = CommonReportUtils.tFontSize;
        const dFontSize = CommonReportUtils.iFontSize;
        const marginLeftValue = CommonReportUtils.margin;
        return CommonReportUtils.arrangeInHorizontal(y, doc, sectionSpacing, tFontSize, dFontSize, marginLeftValue, args);
    }

    static arrangeInHorizontal(y, doc, sectionSpacing, labelFontSize, valueFontSize, marginLeftValue, args) {
        const argCount = args.length;
        const lineHeight = CommonReportUtils.lineHeight;
        const lineLength = sectionSpacing;

        const columnMarginH = 2;
        const columnMarginV = 2;
        let labelY = y;
        let valueY = labelY + lineHeight;
        // check if add new page
        for (let i = 0; i < argCount; i++) {
            const field = args[i];
            const label = field.label;
            const value = field.value;
            console.log(`ArrangeInHorizontal :: check for field: ${label}`);
            const addNewPage = CommonReportUtils.checkIfAddNewPage(value, valueY, lineLength - columnMarginH, lineHeight, doc);
            if (addNewPage) {
                console.log('ArrangeInHorizontal :: add new page');
                doc.addPage();
                labelY = CommonReportUtils.margin;
                valueY = CommonReportUtils.margin + lineHeight;
                break;
            }
        }
        // build layout
        let maxY = 0;
        for (let i = 0; i < argCount; i++) {
            const field = args[i];
            const label = field.label;
            let value = field.value;
            const x = marginLeftValue + lineLength * i;
            if (value === undefined) value = CommonReportUtils.replacer;
            doc.setFontSize(labelFontSize);
            const labelYExt = CommonReportUtils.displayLongStringExt(label, x, labelY, lineLength - columnMarginH, lineHeight, doc);
            console.log(labelYExt);
            valueY = labelYExt.y;
            // doc.text(label, x, labelY);
            doc.setFontSize(valueFontSize);
            // let currentY;
            let currentY;
            let newPage = false;
            if (Array.isArray(value)) {
                for (const v of value) {
                    const currentYExt = CommonReportUtils.displayLongStringExt(v, x, valueY, lineLength - columnMarginH, lineHeight, doc);
                    // currentY = CommonReportUtils.displayLongString(v, x, valueY, lineLength - columnMarginH, lineHeight, doc);
                    valueY = currentYExt.y;
                    if (currentYExt.newPage === true) {
                        newPage = true;
                    }
                    currentY = currentYExt.y;
                }
            } else {
                const currentYExt = CommonReportUtils.displayLongStringExt(value, x, valueY, lineLength - columnMarginH, lineHeight, doc);
                currentY = currentYExt.y;
                if (currentYExt.newPage === true) {
                    newPage = true;
                }
            }
            if (newPage === false && currentY > maxY) {
                maxY = currentY;
            } else if (newPage === true) {
                maxY = currentY;
            }
        }
        const result = maxY + columnMarginV;
        return result;
    }

    static checkIfAddNewPage(string, yTmp, lineLength, lineHeight, doc) {
        try {
            const pageHeight = doc.internal.pageSize.height;
            if (string !== '' && string !== '-|creatEmptySpace|-' && string !== undefined) {
                console.log(`CheckIfAddNewPage :: string value = ${string} yTmp = ${yTmp}`);
                const words = doc.splitTextToSize(`${string} `, lineLength);
                for (let i = 0; i < words.length; i++) {
                    if (yTmp + lineHeight + CommonReportUtils.footerHeigth >= pageHeight) {
                        return true;
                    }
                    if (yTmp === 0) {
                        yTmp = lineHeight;
                    }
                    yTmp += lineHeight;
                }
            }
        } catch (err) {
            console.error(`Error in checkIfAddNewPage = ${err}`);
        }
        return false;
    }

    static displayLongString(string, x, y, lineLength, lineHeight, doc) {
        let yTmp = y;
        if (string !== '' && string !== '-|creatEmptySpace|-' && string !== undefined) {
            const words = doc.splitTextToSize(`${string} `, lineLength);
            for (let i = 0; i < words.length; i++) {
                yTmp = CommonReportUtils.addPageIfContentToLong(yTmp, lineHeight, doc);
                if (yTmp === 0) {
                    yTmp = lineHeight;
                }
                doc.text(words[i], x, yTmp);
                yTmp += lineHeight;
            }
        } else if (string === '-|creatEmptySpace|-') {
            doc.text('', x, yTmp);
        } else {
            doc.text(CommonReportUtils.replacer, x, yTmp);
        }
        return yTmp;
    }

    static displayLongStringExt(string, x, y, lineLength, lineHeight, doc, center) {
        let yTmp = y;
        let newPage = false;
        const pageWidth = doc.internal.pageSize.width;
        if (string !== '' && string !== '-|creatEmptySpace|-' && string !== undefined) {
            const words = doc.splitTextToSize(`${string} `, lineLength);
            for (let i = 0; i < words.length; i++) {
                yTmp = CommonReportUtils.addPageIfContentToLong(yTmp, lineHeight, doc);
                if (CommonReportUtils.margin === yTmp) {
                    newPage = true;
                }
                if (yTmp === 0) {
                    yTmp = lineHeight;
                }
                console.log(words[i]);
                if (center) {
                    const wordWidth = doc.getTextWidth(words[i]);
                    doc.text(words[i], pageWidth / 2 - wordWidth / 2, yTmp);
                } else {
                    doc.text(words[i], x, yTmp);
                }
                yTmp += lineHeight;
            }
        } else if (string === '-|creatEmptySpace|-') {
            doc.text('', x, yTmp);
        } else {
            doc.text(CommonReportUtils.replacer, x, yTmp);
        }
        return {y: yTmp, newPage};
    }

    static newLine(cursor, startX, lineHeight) {
        if (cursor.x !== startX) {
            cursor.x = startX;
            cursor.y += lineHeight;
            console.log('-->> nowa linia', cursor);
        }
        return cursor;
    }

    static cutWord(word, text) {
        if (text) {
            const start = text.indexOf(word);
            if (start === 0) {
                const result = text.substring(word.length);
                return result.trim();
            }
        }
        return text;
    }

    // testuje, czy słowo jest całe (true), czy tylko kawałek (false)
    static isCompleteWord(word, text, index) {
        if (index && index > 90) {
            return true;
        }
        if (word) {
            word = word.trim();
        }
        if (text) {
            text = text.trim();
        }
        let result;
        if (word.length === text.length) {
            result = word === text;
        } else {
            result = text.indexOf(`${word} `) === 0;
        }
        console.log(`->>> isCompleteWord, word: [${word}], text:[${text}], result=${result}`);
        return result;
    }

    static replaceAll = function (target, search, replacement) {
        return target.replace(new RegExp(search, 'g'), replacement);
    };

    static isEmpty(obj) {
        if (obj === undefined || obj == null) {
            return true;
        }
        for (const i in obj) {
            if (obj.hasOwnProperty(i)) return false;
        }
        return true;
    }
}
export default CommonReportUtils;
