import PropTypes from 'prop-types';
import React from 'react';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import UserService from '../../services/UserService';
import {t} from 'i18next';
import SubscriptionService from '../../services/SubscriptionService';
import PackageContainer from '../PackagesContainer';
import {withTranslation} from 'react-i18next';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';
import TemplateListComponent from '../../components/TemplateListComponent';
import TemplateService from '../../services/TemplateService';
import {TabPanel, TabView} from 'primereact/tabview';

class PackageOfferContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new UserService());
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.state = {
            id: props.elementId,
            viewMode: props.viewMode,
            element: {
                packages: [],
            },
            templates: [],
            selectedPackagesMap: new Map(),
            visiblePackages: false,
            billingCycleMap: new Map(),
            dialogVisibile: false,
            isFirstFetchPackage: true,
        };
        this.templateService = new TemplateService();
        this.handleChangePackage = this.handleRedirectToChangePackage.bind(this);
        this.backLinkRendered = false;
    }

    getContainerListName() {
        return 'package-offer';
    }

    getTemplateList() {
        const criteria = {
            firstResult: 0,
            maxResults: 10000,
            sortField: 'id',
            sortAsc: false,
        };
        this.templateService.getList(criteria).then((res) => {
            this.setState({
                templates: res.content,
            });
        });
    }

    readMessage() {
        const messageFromStorage = this.readCookie(this.getContainerListName());
        if (messageFromStorage !== undefined && messageFromStorage != null) {
            const message = JSON.parse(messageFromStorage);
            this.removeCookie(this.getContainerListName());
            if (
                message.severity !== undefined &&
                message.severity != null &&
                message.summary !== undefined &&
                message.summary != null &&
                message.detail !== undefined &&
                message.detail != null
            ) {
                this.showMessage(message.severity, message.summary, message.detail);
            }
        }
    }

    handleRedirectToChangePackage(href, e) {
        window.location.href = AppPrefixUtils.locationHrefUrl(href);
    }

    translate(key) {
        return t(key);
    }

    initBeforeSetElement() {
        this.getTemplateList();
        this.readMessage();
        new SubscriptionService()
            .getAllSubscriptionForUser(this.props.user.userId)
            .then((packages) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        packages: packages,
                    },
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message, 10000, true, 'Błąd przy pobieraniu pakietów ');
            });
    }
    breadCrumbItems() {
        return [{name: 'Cennik', url: '/#/package-offer'}];
    }
    render() {
        return (
            <React.Fragment>
                <BaseBreadCrumb items={this.breadCrumbItems()} />
                {this.renderViewWithoutCard()}
            </React.Fragment>
        );
    }

    renderDetails() {
        return (
            <React.Fragment>
                <TabView headerClassName={`container`} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange}>
                    <TabPanel header={'Pakiety'} key={`template-list-${this.state.activeIndex}`}>
                        <div className=''>
                            {this.state.element.packages.length !== 0 ? (
                                <PackageContainer
                                    colClass='col-12'
                                    handleTranslate={this.translate}
                                    viewMode={'VIEW'}
                                    style={{minWidth: '12em'}}
                                    packages={this.state.element.packages}
                                    showTemplates={true}
                                    changePackageUrl={this.props.changePackageUrl}
                                />
                            ) : null}
                        </div>
                    </TabPanel>
                    <TabPanel
                        disabled={this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE')}
                        header={'Szablony'}
                        key={`agreement-list-${this.state.activeIndex}`}
                    >
                        {this.state.templates.length !== 0 && (
                            <div id='package-container-templates' className='container'>
                                {<TemplateListComponent showPrice templates={this.state.templates} />}
                            </div>
                        )}
                    </TabPanel>
                </TabView>
            </React.Fragment>
        );
    }
}

PackageOfferContainer.defaultProps = {
    cancelUrl: '/#/package-offer',
    detailUrl: '/#/package-offer',
    editUrl: '/#/package-offer/edit',
    backUrl: '/#/package-offer',
    changePackageUrl: '/#/package-offer/change-package',
};

PackageOfferContainer.propTypes = {
    cancelUrl: PropTypes.string,
    detailUrl: PropTypes.string,
    editUrl: PropTypes.string,
    changePackageUrl: PropTypes.string,
};

export default withTranslation()(PackageOfferContainer);
