import {t} from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../baseContainers/BaseListContainer';
import ActionLink from '../components/ActionLink';
import {CriteriaTextComponent} from '../components/criteria/CriteriaTextComponent';
import SettingModel from '../model/SettingModel';
import SettingService from '../services/SettingService';
import UserRoleService from '../services/UserRoleService';
import SettingDialog from './SettingDialog';
import DialogResizer from '../components/DialogResizer';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import BaseBreadCrumb from '../baseContainers/BaseBreadCrumb';

class SettingListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new SettingService());
        this.roleService = new UserRoleService();
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            settingParameterTypeOptions: [],
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    updateSearchCriteria(criteria) {
        return {
            name: criteria.name?.enumValue,
            active: criteria.active,
            id: criteria.id,
            entity_uuid: criteria.entityUuid,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        };
    }
    getCleanSearchCriteria() {
        return {
            name: '',
            role: undefined,
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }
    getDataKey() {
        return 'id';
    }
    getListTitle() {
        return 'setting.listTitle';
    }
    getCriteriaName() {
        return 'setting-list-sc';
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('UserActivityStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
    }

    getContainerListName() {
        return 'setting-list-container';
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name-sc'
                    name='name'
                    label={t('setting.name')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.name}
                    placeholder='Wpisz nazwę...'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
            </div>
        );
    }
    prepareColumns() {
        return [
            {field: 'id', header: 'ID', sortable: true, width: '90px'},
            {field: 'name', header: 'Nazwa', sortable: true},
            {field: 'value', header: 'Wartość', sortable: true, body: this.translateValue},
            {
                key: 'actions',
                header: 'Edytuj',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    translateValue(rowData) {
        if (
            [
                'TEMPLATE_SAVE_AFTER_GENERATE',
                'CONSULTATION_MAILING_AVAILABILITY_LEKSY',
                'CONSULTATION_CHAT_AVAILABILITY_LEKSY',
                'CONSULTATION_VIDEO_CHAT_AVAILABILITY_LEKSY',

                'CONSULTATION_VIDEO_CHAT_AVAILABILITY_LEXDOCS',
                'CONSULTATION_CHAT_AVAILABILITY_LEXDOCS',
                'CONSULTATION_MAILING_AVAILABILITY_LEXDOCS',
            ].find((t) => t === rowData.type)
        ) {
            return rowData.value === 'true' ? 'Tak' : 'Nie';
        } else {
            return rowData.value;
        }
    }

    // actionTemplate(rowData) {
    //     const {t} = this.props;
    //     return (
    //         <ActionLink
    //             label={t('details.editButton')}
    //             handleClick={this.handleOpenDialog.bind(this, rowData)}
    //             variant='blue'
    //             className='edit-blue-link'
    //             key={'view-button'}
    //         />
    //     );
    // }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const detailHref = `${detailUrl}/${rowData.id}`;
        return (
            <div className='float-center'>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/edit_blue_24dp.svg`)}
                    onClick={this.handleOpenDialog.bind(this, rowData)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        );
    }
    handleOpenDialog(rowData, e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: rowData, dialogViewMode: 'EDIT'});
    }
    handleOpenNewDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: new SettingModel(), dialogViewMode: 'NEW'});
    }
    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: false, element: undefined}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
            if (callback) callback();
        });
    }
    //override
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        }
        return '';
    } //override
    prepareEditButton(rendered, label) {
        const {viewMode} = this.props;
        return {
            label: label !== undefined ? label : 'Edytuj',
            type: 'BUTTON',
            variant: '',
            className: 'float-right',
            rendered: viewMode === 'VIEW' && this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_SETTING_MANAGE'),
            // iconName: 'mdi-pencil',
            iconSide: 'left',
            iconSize: 'm',
        };
    }

    updateElement(data) {
        this.setState(
            {
                element: {
                    name: data.name,
                    value: data.value,
                    id: data.id,
                    version: data.version,
                },
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    breadCrumbItems() {
        return [{name: 'Parametry Systemowe', url: '/#/setting-list'}];
    }
    render() {
        return (
            <div className='container-fluid'>
                {this.state.dialogVisibile ? (
                    <SettingDialog
                        key={`setting-dialog-${this.state.element?.id}`}
                        elementId={this.state.element?.id}
                        onHide={(e) => {
                            this.handleCloseDialog(e);
                        }}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}
                        afterSave={(e) =>
                            this.handleCloseDialog(e, () => {
                                this.readMessage();
                                this.refreshTable();
                                this.unblockUi();
                            })
                        }
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                        className={'setting-param-dialog'}
                    />
                ) : null}
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                {this.renderView()}
            </div>
        );
    }
}

SettingListContainer.defaultProps = {
    detailUrl: '/#/setting/details',
    newUrl: '/#/setting/create',
    lazy: true,
};

SettingListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(SettingListContainer);
