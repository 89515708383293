import AddressModel from "./AddressModel";
import UserModel from "./UserModel";

export default class LawOfficeModel {
    constructor() {
        this.id = undefined;
        this.version = 0;
        this.address = new AddressModel();
        this.assignedUser = new UserModel();
        this.name=undefined;
        this.nip=undefined;
    }

    static assign(from, to) {
        if (from) {
            to.id = from.id;
            to.version = from.version;
            to.address = from.address;
            to.name = from.companyName;
            to.nip = from.nip;
            to.assignedUser = from.assignedUser;
        } else {
            to = new LawOfficeModel();
        }
    }

    static copy(from) {
        const to = new LawOfficeModel();
        LawOfficeModel.assign(from, to);
        return to;
    }
}
