import PropTypes from 'prop-types';
import React from 'react';
import Constants from '../utils/constants';
import {device, isLexDocs} from '../baseContainers/BaseContainer';

export const CalendarTodayEventList = (props) => {
    const {events, authService} = props;
    React.useEffect(() => {}, [props]);

    const todayEventList = () => {
        if (window.location.href.includes('scheduled-event-calendar') && isLexDocs && device.type === 'mobile') {
            const todayEvents = getTodaysEvents();
            return (
                <div id='today-event-list'>
                    {getTodayTitle(todayEvents[0]?.start)}
                    {renderTodayEvents(todayEvents)}
                </div>
            );
        }
        return '';
    };
    const getTodayTitle = (date) => {
        if (date) {
            const today = new Date(date);
            const foundedMonth = Constants.monthOpt.find((el) => el.enumValue === parseInt(today.getMonth()));
            return <div id='dates'>{today.getDate() + ' ' + foundedMonth.label + ' ' + today.getFullYear()} </div>;
        }
        return '';
    };
    const renderTodayEvents = (todayEvents) => {
        return todayEvents.map((event) => {
            let conultationWith = event.client;
            if (authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE', 'ROLE_USER_LEKSY_MANAGE')) {
                conultationWith = event.consultant;
            }
            return <div>{rednerTodayEvent(event.startHour, event.endHour, conultationWith)} </div>;
        });
    };
    const rednerTodayEvent = (startDate, endDate, conultationWith) => {
        // const text = executed?
        return (
            <div className='content'>
                <div className='date'>{startDate + ' - ' + endDate}</div>
                <div className='incoming-text'>Nadchodząca konsultacja z:</div>
                <div className='consultation-with'>{conultationWith}</div>
            </div>
        );
    };

    const getTodaysEvents = () => {
        return events
            .filter((el) => {
                return isTodayDate(new Date(el.start));
            })
            .filter((el) => !el.executed);
    };
    const isTodayDate = (dateIn) => {
        const today = new Date();
        return (
            dateIn.getDate() === today.getDate() && dateIn.getMonth() === today.getMonth() && dateIn.getFullYear() === today.getFullYear()
        );
    };
    return todayEventList();
};

CalendarTodayEventList.defaultProps = {};

CalendarTodayEventList.propTypes = {
    events: PropTypes.object,
    authService: PropTypes.object,
};

export default CalendarTodayEventList;
