import {Card} from 'primereact/card';
import PropTypes from 'prop-types';
import React from 'react';
import ActionLink from '../components/ActionLink';
import {CustomMessages} from '../components/CustomMessages';
import DivContainer from '../components/DivContainer';
import BlockUi from '../components/waitPanel/BlockUi';
import BaseContainer, { device } from './BaseContainer';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import ActionButton from '../components/ActionButton';
import PrimiDialog from '../components/PrimiDialog';
import {CookiesConstants} from '../utils/CookiesConstants';
import DialogResizer from '../components/DialogResizer';

//import { withTranslation } from 'react-i18next';

window.exitConfirmationRefDialog = undefined;

class BaseDetailsContainer extends BaseContainer {
    constructor(props, service) {
        super(props);
        this.service = service;
        this.state = {
            callBackConfirmation: undefined,
        };

        this.exitConfirmationRefDialog = React.createRef();
        this.onUpload = this.onUpload.bind(this);
        this.addBackAndEditButton = this.addBackAndEditButton.bind(this);
        this.addCancelAndSaveButtons = this.addCancelAndSaveButtons.bind(this);
        this.prepareCancelButton = this.prepareCancelButton.bind(this);
        this.prepareBackLink = this.prepareBackLink.bind(this);
        this.renderExitConfirmationDialog = this.renderExitConfirmationDialog.bind(this);
        this.updateElement = this.updateElement.bind(this);
        this.createOrUpdate = this.createOrUpdate.bind(this);
        this.getAddSucces = this.getAddSucces.bind(this);
        this.getUpdateSucces = this.getUpdateSucces.bind(this);
        this.renderBackLink = this.renderBackLink.bind(this);
        this.getBackLabel = this.getBackLabel.bind(this);
        this.renderDetails = this.renderDetails.bind(this);
        this.getStyleBorderForLexDocS = this.getStyleBorderForLexDocS.bind(this);
        this.renderView = this.renderView.bind(this);
        this.handleFileCountExceeded = this.handleFileCountExceeded.bind(this);
        this.onRemoveAction = this.onRemoveAction.bind(this);
        this.showRemoveConfirmation = this.showRemoveConfirmation.bind(this);
        this.renderProperStructureForDetail = this.renderProperStructureForDetail.bind(this);
        this.hideRemoveConfirmation = this.hideRemoveConfirmation.bind(this);
        this.goBackUrl = this.goBackUrl.bind(this);
        this.backLinkRendered = true;
        if (service !== undefined && service !== null) {
            this.service.setUiMethods(this.blockUi, this.unblockUi);
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.blockUi();
        this.initBeforeSetElement();
        this.setElement();
    }

    showRemoveConfirmation() {
        this.setState({showConfirmRemoveDialog: true});
    }

    hideRemoveConfirmation() {
        this.setState({showConfirmRemoveDialog: false});
    }
    isMobile(){
        return device.type === 'mobile';
    }
    onRemoveAction(type, costam, target) {
        if (target.value) {
            this.blockUi();
            this.service
                .remove(this.state.element)
                .then(() => {
                    this.persistMessage('success', '', this.getRemoveSucces());
                    window.location.href = AppPrefixUtils.locationHrefUrl(this.props.backUrl);
                })
                .catch((err) => {
                    this.showErrorMessage(err.message);
                    this.hideRemoveConfirmation();
                });
        } else {
            this.hideRemoveConfirmation();
        }
    }

    setElement() {
        const {viewMode} = this.props;
        const {elementId} = this.state;

        if (elementId && (viewMode === 'EDIT' || viewMode === 'VIEW')) {
            this.blockUi();
            this.service
                .get(elementId, viewMode)
                .then((data) => {
                    this.setState({loading: false}, () => this.updateElement(data));
                })
                .catch((err) => {
                    this.handleGetDetailsError(err);
                });
        } else {
            this.setState({loading: false}, this.initAfterSetElement());
        }
    }

    handleGetDetailsError(err) {
        this.showErrorMessage('Błąd podczas pobrania szczegółów');
        if (this.props.backUrl) {
            window.location.href = AppPrefixUtils.locationHrefUrl(this.props.backUrl);
        } else {
            this.setState({loading: false}, () => this.unblockUi());
        }
    }

    updateElement(data) {
        if (this._isMounted) {
            this.setState(
                {
                    element: data,
                },
                () => this.initAfterSetElement()
            );
        }
    }

    initAfterSetElement() {
        this.unblockUi();
    }

    initBeforeSetElement() {}

    onUpload(event, varName) {
        const {element} = this.state;
        const varValue = JSON.parse(event.xhr.response);
        if (this._isMounted) {
            const modifiedList = element[varName].concat(varValue);
            this.setState((prevState) => ({
                element: {
                    ...prevState.element,
                    [varName]: modifiedList,
                },
            }));
        }
    }
    renderProperStructureForDetail(elements, withBorder) {
        let classNameForRow = this.getStyleBorderForLexDocS();
        if (withBorder === false) {
            classNameForRow = 'lexdocs-details';
        }
        return (
            <div className='container-fluid'>
                <div className={`row ${classNameForRow}`}>{elements}</div>
            </div>
        );
    }
    handleFileCountExceeded(max) {
        this.showInfoMessage(`Maksymanla ilość załączanych plików wynosi: ${max}!`, 10000);
        this.scrollToTop();
    }

    getAddSucces() {
        return 'Element został utworzony';
    }

    getUpdateSucces() {
        return 'Element został zaktualizowany';
    }

    getRemoveSucces() {
        return 'Element został usunięty';
    }

    getContainerListName() {
        return 'list-container';
    }

    persistMessage(severity, summary, detail) {
        this.saveCookie(
            this.getContainerListName(),
            JSON.stringify({
                severity,
                summary,
                detail,
            })
        );
    }

    handleValidForm() {
        DialogResizer.removeMaskIfExistOneDialog();
        this.createOrUpdate();
    }
    afterAddSuccess(response) {
        const {backUrl} = this.props;
        this.blockUi();
        this.persistMessage('success', '', this.getAddSucces(response));
        DialogResizer.removeMaskIfExistOneDialog();
        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
    }
    afterUpdateSuccess(response) {
        const {backUrl} = this.props;
        this.blockUi();
        this.persistMessage('success', '', this.getUpdateSucces(response));
        DialogResizer.removeMaskIfExistOneDialog();
        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
    }

    afterDeleteSuccess(response) {
        const {backUrl} = this.props;
        this.blockUi();
        this.persistMessage('success', '', this.getRemoveSucces(response));
        DialogResizer.removeMaskIfExistOneDialog();
        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
    }
    createOrUpdate() {
        const {element} = this.state;
        this.scrollToTop();
        if (this._isMounted) {
            if (element.id === undefined || element.id === null) {
                this.service
                    .add(element)
                    .then((response) => {
                        this.afterAddSuccess(response);
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message, 10000);
                        this.unblockUi();
                    });
            } else {
                this.service
                    .update(element)
                    .then((response) => {
                        this.afterUpdateSuccess(response);
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message, 10000);
                        this.unblockUi();
                    });
            }
        }
    }

    addBackAndEditButton(backLabel) {
        const {editUrl, viewMode} = this.props;
        const {element} = this.state;
        const buttons = [
            this.prepareBackLink(backLabel),
            {
                label: 'Edytuj',
                className: 'float-right',
                href: AppPrefixUtils.locationHrefUrl(`${editUrl}/${element.id}`),
                rendered: viewMode === 'VIEW',
            },
        ];
        return this.prepareHeader(buttons);
    }

    prepareBackLink(backLabel) {
        const {backUrl} = this.props;
        return {label: backLabel, href: AppPrefixUtils.locationHrefUrl(backUrl), type: 'LINK'};
    }

    prepareCancelButton() {
        const {backUrl, cancelUrl, viewMode} = this.props;
        const {element} = this.state;
        let goBackUrl;
        if (viewMode === 'NEW') {
            goBackUrl = backUrl;
        } else {
            goBackUrl = `${cancelUrl}/${element.id}`;
        }
        return {label: 'Anuluj', href: AppPrefixUtils.locationHrefUrl(goBackUrl), className: 'cancel-button gray'};
    }

    addCancelAndSaveButtons() {
        const buttons = [
            this.prepareCancelButton(),
            {
                label: 'Zapisz',
                className: 'float-right',
                onClick: this.handleFormSubmit,
            },
        ];
        return this.prepareFooter(buttons);
    }

    getBackLabel() {
        return 'Wróć';
    }

    handleGoBack(e) {
        e.preventDefault();
        this.blockUi();
        const {backUrl} = this.props;
        DialogResizer.removeMaskIfExistOneDialog();
        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
    }

    renderBackLink() {
        return (
            <ActionLink
                rendered={this.backLinkRendered}
                label={this.getBackLabel()}
                className='p-link back-blue-link float-right'
                handleClick={this.handleGoBack.bind(this)}
                variant='blue'
                iconName='mdi-arrow-left'
                iconSide='left'
                iconSize='xs'
                iconColor='blue'
            />
        );
    }

    prepareEditButton(rendered, label) {
        const {editUrl} = this.props;
        const {element} = this.state;
        return {
            label: label !== undefined ? label : 'Edytuj',
            type: 'BUTTON',
            variant: '',
            href: AppPrefixUtils.locationHrefUrl(`${editUrl}/${element.id}`),
            rendered: rendered,
            // iconName: 'mdi-pencil',
            iconSide: 'left',
            iconSize: 'm',
        };
    }

    goBackUrl() {
        const {backUrl, cancelUrl, viewMode} = this.props;
        const {element} = this.state;

        let goBackUrl;
        if (viewMode === 'NEW') {
            goBackUrl = backUrl;
        } else {
            goBackUrl = `${cancelUrl}/${element.id}`;
        }
        return goBackUrl;
    }
    cancelButtonLabel() {
        return 'Anuluj';
    }
    prepareFooterItems() {
        const {viewMode} = this.props;
        this.removeCookie(CookiesConstants.DETAILS_VIEW_MODE);

        switch (viewMode) {
            case 'EDIT':
            case 'NEW':
                return [
                    {
                        label: this.cancelButtonLabel(),
                        href: AppPrefixUtils.locationHrefUrl(this.goBackUrl()),
                        className: 'cancel-button gray',
                    },
                    {
                        label: 'Zapisz',
                        className: 'float-right',
                        onClick: this.handleFormSubmit,
                    },
                ];
            case 'VIEW':
            default:
                return [];
        }
    }

    renderDetails() {
        return null;
    }
    renderExitConfirmationDialog() {
        window.exitConfirmationRefDialog = this.exitConfirmationRefDialog;
        return (
            this.showExitConfirmationDialog && (
                <PrimiDialog
                    ref={this.exitConfirmationRefDialog}
                    className={'confirmation-exit-edit-dialog small-dialog'}
                    visible={this.state.showExitConfirmationDialog}
                    header='Ostrzeżenie'
                    name='versionDialogVisible'
                    onShow={(element, viewModeFromCookie) => {
                        new Promise((resolve, reject) => {
                            // myczek na dopasowanie czasu tworzenia maski do dialogu
                            DialogResizer.forceCreateMask();
                            resolve();
                        }).then(() => {
                            if (typeof element === 'string') {
                                this.setState({showExitConfirmationDialog: true, redirectLink: element, viewModeFromCookie});
                                sessionStorage.removeItem(CookiesConstants.DETAILS_VIEW_MODE);
                                return;
                            }
                            if (typeof element === 'function') {
                                viewModeFromCookie = window.location.href.includes('edit') ? 'EDIT' : undefined;
                                this.setState({showExitConfirmationDialog: true, callBackConfirmation: element, viewModeFromCookie});
                                return;
                            }
                        });
                    }}
                    onChange={() => {
                        if (this.state?.redirectLink) {
                            window.location.href = AppPrefixUtils.locationHrefUrl(this.state.redirectLink);
                        }
                        sessionStorage.removeItem(CookiesConstants.DETAILS_VIEW_MODE);
                        this.setState({showExitConfirmationDialog: false});
                    }}
                    onHide={() => {
                        const viewModeFromUrl = window.location.href.includes('edit') ? 'EDIT' : 'NEW';

                        sessionStorage.setItem(CookiesConstants.DETAILS_VIEW_MODE, viewModeFromUrl);
                        this.setState(
                            {
                                showExitConfirmationDialog: false,
                            },
                            () => {
                                DialogResizer.removeMaskIfExistOneDialog();
                            }
                        );
                    }}
                    singleButton
                    draggable={false}
                >
                    <Card
                        className='small-dialog-card'
                        footer={() => {
                            return (
                                <React.Fragment>
                                    <hr className='line-footer' />
                                    <div className='footer-dialog-button-container '>
                                        <ActionButton
                                            label={'Tak'}
                                            variant={'button save-button blue'}
                                            handleClick={() => {
                                                sessionStorage.removeItem(CookiesConstants.DETAILS_VIEW_MODE);
                                                DialogResizer.removeMaskIfExistOneDialog();
                                                this.setState({
                                                    showExitConfirmationDialog: false,
                                                });
                                                if (this.state.redirectLink) {
                                                    window.location.href = AppPrefixUtils.locationHrefUrl(this.state.redirectLink);
                                                } else {
                                                    this.state.callBackConfirmation();
                                                }
                                            }}
                                        />
                                        <ActionButton
                                            label={'Nie'}
                                            variant={'button cancel-button gray'}
                                            handleClick={() => {
                                                const viewModeFromUrl = window.location.href.includes('edit') ? 'EDIT' : 'NEW';
                                                sessionStorage.setItem(CookiesConstants.DETAILS_VIEW_MODE, viewModeFromUrl);
                                                this.setState(
                                                    {
                                                        showExitConfirmationDialog: false,
                                                    },
                                                    () => {
                                                        DialogResizer.removeMaskIfExistOneDialog();
                                                    }
                                                );
                                            }}
                                        />
                                    </div>
                                </React.Fragment>
                            );
                        }}
                    >
                        <div>
                            {this.state?.viewModeFromCookie === 'EDIT' ? (
                                <p>{'Czy na pewno chcesz zakończyć edycję?'}</p>
                            ) : (
                                <p>{'Czy na pewno chcesz wyjść z widoku dodawania?'}</p>
                            )}
                        </div>
                    </Card>
                </PrimiDialog>
            )
        );
    }

    renderView() {
        return (
            <DivContainer colClass=''>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} rendered={!this.props.embedded} />
                <BlockUi
                    tag='div'
                    blocking={this.state.blocking || this.state.loading}
                    loader={this.loader}
                    rendered={!this.props.embedded}
                >
                    {this.state.loading ? null : this.renderCard()}
                </BlockUi>
                {this.renderExitConfirmationDialog()}
            </DivContainer>
        );
    }
    getStyleBorderForLexDocS() {
        return this.isLexyPortal() ? '' : 'border-lexdocs-details';
    }
    renderCard() {
        return (
            <Card className='details-card' footer={this.renderFooter()} header={this.renderHeader()}>
                <form onSubmit={this.handleFormSubmit} noValidate>
                    {this.renderDetails()}
                </form>
            </Card>
        );
    }

    renderHeader() {
        const headerItems = this.prepareHeaderItems();
        if (headerItems !== undefined && headerItems.length > 0 && headerItems.filter((f) => f.rendered !== false)?.length > 0) {
            return <React.Fragment>{this.prepareHeader(headerItems)}</React.Fragment>;
        } else {
            return null;
        }
    }

    renderViewWithoutCard() {
        return <DivContainer colClass=''>{this.state.loading ? null : this.renderDetails()}</DivContainer>;
    }
}

BaseDetailsContainer.defaultProps = {
    currentUser: undefined,
    viewMode: 'VIEW',
};

BaseDetailsContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
};

export default BaseDetailsContainer;
