import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import DivContainer from '../../components/DivContainer';
import {TabPanel, TabView} from 'primereact/tabview';
import {Card} from 'primereact/card';
import SpecializationService from '../../services/SpecializationService';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
import ActionButton from '../../components/ActionButton';
import InternalUserService from '../../services/InternalUserService';
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';
import CalendarScheduleComponent from '../../components/CalendarScheduleComponent';
import EventCalendarService from '../../services/EventCalendarService';
import EventModel from '../../model/EventModel';
import ScheduledConsultationService from '../../services/ScheduledConsultationService';
import YesNoDialog from '../../components/YesNoDialog';
import ChatService, {ConsultationType} from '../../services/ChatService';
import ConsultationHelper from '../../services/ConsultationHelper';
import {v4 as uuidv4} from 'uuid';
import InputCalendarComponent from '../../components/inputs/InputCalendarComponent';
import AgreementService from '../../services/AgreementTemplateForUserService';
import SpecializationPublicService from '../../services/public/SpecializationPublicService';
import AgreementTeplateForUserPublicService from '../../services/public/AgreementTeplateForUserPublicService';
import InternalUserPublicService from '../../services/public/InternalUserPublicService';
import EventCalendarPublicService from '../../services/public/EventCalendarPublicService';
import {Dialog} from 'primereact/dialog';
import MailingDialog from './MailingDialog';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import ScheduledConsultationPublicService from '../../services/public/ScheduledConsultationService';
import InputRadioButtonComponent from '../../components/inputs/InputRadioButtonComponent';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import DialogResizer from '../../components/DialogResizer';
import PrimiDialog from '../../components/PrimiDialog';

class LegalConsultationDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new ScheduledConsultationService());
        this.state = {
            isDialogVisible: true,
            agreementUuid: undefined,
            scheduledConsultationId: undefined,
            showPaymentMessageForClientAdHoc: false,
            bookingEvent: undefined,
            showPaymentMessageForClientReservation: false,
            visiblePaymentSummaryDialog: false,
            shouldUserPay: true,
            visibleCannotBeScheduledDialog: false,
            choosenConsultationType: undefined,
            clientId: undefined,
            visibleMailingDialog: false,
            unregisteredClient: {
                email: undefined,
                firstName: undefined,
                lastName: undefined,
            },
            element: {
                id: this.props.elementId,
                specialization: undefined,
                endDate: undefined,
                paymentRequired: undefined,
                user: {
                    id: this.props?.currentUser?.userId,
                },
                startDate: undefined,
                consultation: undefined,
                consultationType: undefined,
            },
            viewMode: this.props.viewMode,
            specializations: [],
            dialogVisibile: false,
            activeIndex: 0,
            users: [],
            events: [],
            selectedEvent: undefined,
            copyEvents: [],
            consultationAvailability: undefined,
            consultationTypeOptions: [],
            bookingConsultationOptions: [
                {enumValue: 'CHAT', label: 'Czat'},
                {enumValue: 'VIDEO_CHAT', label: 'Rozmowa wideo'},
            ],
        };
        this.chatService = new ChatService();
        this.consultationHelper = new ConsultationHelper();
        this.getEventsForClient = this.getEventsForClient.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleReserveDate = this.handleReserveDate.bind(this);
        this.handlePreReserveDate = this.handlePreReserveDate.bind(this);
        this.renderChooseHourForScheduled = this.renderChooseHourForScheduled.bind(this);
        this.handleMoveToArrangeConsultation = this.handleMoveToArrangeConsultation.bind(this);
        this.closeCannotBeScheduledDialog = this.closeCannotBeScheduledDialog.bind(this);
        this.paymentMessageForClientReservation = this.paymentMessageForClientReservation.bind(this);
        this.shuldClientPayForAdHocConsultation = this.shuldClientPayForAdHocConsultation.bind(this);
        this.checkConsultantAvailability = this.checkConsultantAvailability.bind(this);
        this.renderBookOrPayAndBook = this.renderBookOrPayAndBook.bind(this);
        this.paymentMessageForClientAdHoc = this.paymentMessageForClientAdHoc.bind(this);
        this.selectEvent = this.selectEvent.bind(this);
        this.resetAllEvents = this.resetAllEvents.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.closePaymentSummaryDialog = this.closePaymentSummaryDialog.bind(this);
        this.consultationService = this.authService.loggedIn() ? new EventCalendarService() : new EventCalendarPublicService();
        this.service = this.authService.loggedIn() ? new ScheduledConsultationService() : new ScheduledConsultationPublicService();
        this.eventCalendarService = this.authService.loggedIn() ? new EventCalendarService() : new EventCalendarPublicService();
        this.internalUserService = this.authService.loggedIn() ? new InternalUserService() : new InternalUserPublicService();
        this.agreementService = this.authService.loggedIn() ? new AgreementService() : new AgreementTeplateForUserPublicService();
        this.specializationService = this.authService.loggedIn() ? new SpecializationService() : new SpecializationPublicService();
        this.renderConsultationFooter = this.renderConsultationFooter.bind(this);
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    renderFooter() {
        return null;
    }

    initBeforeSetElement() {
        if (this.props?.currentUser?.userId) {
            this.service.shouldUserPayForConsultation(this.props?.currentUser?.userId).then((shouldPay) => {
                this.setState({
                    shouldUserPay: shouldPay,
                });
            });
        }

        this.consultationService
            .getConsultationAvailabilityBySettingsValues()
            .then((consultationAvailability) => {
                let consultationTypeOptions = [];
                consultationAvailability.forEach((el) => {
                    let element = {
                        enumValue: el.type,
                        label: this.translate('consultationOptions.' + el.type.toLowerCase()),
                        availability: el.availability,
                    };
                    consultationTypeOptions.push(element);
                });
                this.setState({
                    consultationTypeOptions: consultationTypeOptions,
                });
            })
            .catch(() => {
                this.showErrorMessage('Problem z pobraniem dostępności konsultacji');
            });
        const elementId = this.props.agreementId ? this.props.agreementId : this.props.elementId;
        if (elementId) {
            this.agreementService
                .getAgreementTemplateList(elementId)
                .then((agreementData) => {
                    this.setState(
                        (prevState) => ({
                            agreementUuid: agreementData.uuid,
                            element: {
                                ...prevState.element,
                                specialization: agreementData.specialization,
                            },
                        }),
                        () => this.fetchUsersAndCalendarOpt()
                    );
                })
                .catch(() => {
                    this.showErrorMessage('Błąd przy pobieraniu specjalizacji');
                });
        } else {
            this.specializationService
                .getAllSpecializations()
                .then((specializations) => {
                    this.setState({
                        specializations: specializations,
                    });
                })
                .catch(() => {
                    this.showErrorMessage('Błąd przy pobieraniu specjalizacji');
                });
        }
        if (this.authService.loggedIn()) {
            this.service
                .shouldUserPayForConsultation(this.props?.currentUser?.userId)
                .then((res) => {
                    this.setState({
                        paymentRequired: res,
                    });
                })
                .catch(() => {
                    this.showErrorMessage('Błąd przy sprawdzaniu wymagalności płatności');
                });
        }
    }

    renderHeader() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>Konsultacja prawna</label>
                </DivContainer>
            </DivContainer>
        );
    }

    getContainerListName() {
        return 'template-list-container';
    }

    getAddSucces() {
        return 'Zmiana została odrzucona';
    }

    fetchUsersAndCalendarOpt() {
        this.internalUserService
            .getConsultantsBySpecializationId(this.state.element.specialization.id)
            .then((users) => {
                this.setState(
                    (prevState) => ({
                        element: {
                            ...prevState.element,
                            users: users,
                        },
                        users: users,
                    }),
                    () => this.initCalendarAfterSetUsers(users)
                );
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });
    }

    calendarUpdateByUserChanged() {
        let events = this.state.events;
        let users = this.state.element.users;
        let selectedEvent = this.state.selectedEvent;

        if (users.length === 0) {
            events.forEach((element) => {
                element.isViewed = false;
            });
        } else {
            let foundedEvent = events.find((event) => event.selected);
            if (foundedEvent) {
                let foundedUser = users.find((user) => user.id === foundedEvent?.user?.id);
                if (!foundedUser) {
                    events = this.state.copyEvents;
                    selectedEvent = undefined;
                }
            }
            for (let x = 0; x < events.length; x++) {
                for (let y = 0; y < users.length; y++) {
                    if (events[x].user.id === users[y].id) {
                        events[x].isViewed = true;
                        break;
                    }
                    events[x].isViewed = false;
                }
            }
        }

        this.setState({
            events: events,
            selectedEvent: selectedEvent,
        });
    }

    existSelected() {
        let events = this.state.events;
        return events.find((event) => event.selected);
    }

    initCalendarAfterSetUsers(users) {
        const ids = users.map((user) => user.id);
        if (ids.length === 0) {
            this.setState({
                events: [],
            });
            return;
        }
        this.getEventsForClient(new Date());
    }

    getEventsForClient(date) {
        const ids = this.state.users.map((user) => user.id);
        this.eventCalendarService
            .getEventsForClient(ids, this.state.element.specialization.id, date)
            .then((events) => {
                const convertedEvents = this.setStylesForEvent(events);
                this.setState({
                    events: convertedEvents,
                    copyEvents: structuredClone(convertedEvents),
                });
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });
    }

    hideDialog() {
        this.setState({
            dialogVisibile: false,
        });
    }

    closePaymentSummaryDialog() {
        this.setState(
            {
                visiblePaymentSummaryDialog: false,
            },
            () => {
                DialogResizer.removeMaskIfExistOneDialog();
            }
        );
    }
    closeCannotBeScheduledDialog() {
        this.setState(
            {
                visibleCannotBeScheduledDialog: false,
            },
            () => {
                DialogResizer.removeMaskIfExistOneDialog();
            }
        );
    }

    selectEvent(selectedEvent, choosedTerm) {
        choosedTerm.setSeconds(0);
        let dateStart = structuredClone(choosedTerm);
        let dateEnd = structuredClone(dateStart);
        dateEnd.setHours(dateStart.getHours() + 1);

        let allEvents = this.state.events;
        let eventToChange = allEvents.find((event) => event.id === selectedEvent.id);

        let newEvent = structuredClone(eventToChange);

        newEvent.start = dateStart.toISOString();
        newEvent.end = dateEnd.toISOString();
        newEvent.id = uuidv4();
        newEvent.selected = true;
        newEvent.backgroundColor = '#5c74ac';

        let newEvents = [];
        newEvents.push(newEvent);

        selectedEvent = structuredClone(newEvent);
        //TODO: musialbyc toIsoString() ...
        selectedEvent.end = new Date(dateEnd.getTime() - dateEnd.getTimezoneOffset() * 60000).toISOString();
        selectedEvent.start = new Date(dateStart.getTime() - dateStart.getTimezoneOffset() * 60000).toISOString();

        this.setState({
            events: newEvents,
            selectedEvent: selectedEvent,
        });
    }

    handleSelectEvent(e) {
        let events = this.state.events;
        let slectedEvent = undefined;

        for (let index = 0; index < events.length; index++) {
            if (events[index].id === e.event._def.publicId) {
                slectedEvent = events[index];
            } else {
                events[index].isSelected = false;
            }
        }

        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                startDate: slectedEvent.start,
                consultation: slectedEvent,
            },
            events: events,
            dialogVisibile: true,
        }));
    }

    handleTabChange(e) {
        if (this.authService.loggedIn()) {
            this.setState({
                activeIndex: e.index,
            });
        } else {
            this.setState({
                visibleCannotBeScheduledDialog: true,
            });
        }
    }

    handleMoveToArrangeConsultation(e) {
        this.setState({
            activeIndex: 1,
        });
    }

    onKeyDown(e) {
        console.log(e);
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    renderDetails() {
        return (
            <React.Fragment>
                <TabView
                    ref={this.props.tabViewRef}
                    activeIndex={this.state.activeIndex}
                    onTabChange={this.handleTabChange}
                    onMoveToArrange={this.handleMoveToArrangeConsultation}
                    className='default-tabview'
                >
                    <TabPanel header='Konsultacja'> {this.renderConsultation()}</TabPanel>
                    <TabPanel header='Umów konsultację'> {this.renderArrangeConsultations()}</TabPanel>
                </TabView>

                {this.state.activeIndex === 0 && <div>{this.renderConsultationFooter()}</div>}
                {this.state.activeIndex === 1 && this.state.element.specialization && <div>{this.renderBookOrPayAndBook()}</div>}
                {this.state.dialogVisibile ? this.renderChooseHourForScheduled() : null}
                {this.state.visiblePaymentSummaryDialog ? this.renderPaymentSummaryDialog() : null}
                {this.state.showPaymentMessageForClientAdHoc && this.paymentMessageForClientAdHoc()}
                {this.state.showPaymentMessageForClientReservation && this.paymentMessageForClientReservation()}
                {this.state.visibleCannotBeScheduledDialog ? this.renderCannotBeScheduledDialog() : null}
                {this.state.visibleMailingDialog ? (
                    <MailingDialog
                        elementId={Number(this.state?.elementId)}
                        scheduledConsultationId={this.state.scheduledConsultationId}
                        specializationId={this.state.element.specialization.id}
                        onHide={() => {
                            this.setState(
                                {
                                    visibleMailingDialog: false,
                                },
                                () => {
                                    DialogResizer.removeMaskIfExistOneDialog();
                                }
                            );
                        }}
                        visible={this.state.visibleMailingDialog}
                        viewMode={'NEW'}
                        consultants={this.state.users}
                        afterSave={() => {
                            this.showSuccessMessage('Wysłano wiadomość');
                            this.setState(
                                {
                                    visibleMailingDialog: false,
                                },
                                () => {
                                    DialogResizer.removeMaskIfExistOneDialog();
                                }
                            );
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                        className='mailing-dialog'
                    />
                ) : null}
            </React.Fragment>
        );
    }

    resetAllEvents() {
        let events = this.state.events;
        for (let index = 0; index < events.length; index++) {
            events[index].isViewed = false;
            events[index].backgroundColor = undefined;
            events[index].isSelected = false;
        }
        this.setState({
            events: events,
        });
    }

    handleOpenDialog(e) {
        let fixedDate = new Date(e.end);

        fixedDate.setDate(e.end.getDate());

        this.setState({
            viewMode: 'NEW',
            startDate: e.start,
            endDate: fixedDate,
        });
    }

    handleReserveDate(event, consultationType) {
        let selectedEvent = this.state.selectedEvent;
        if (event === undefined) {
            this.showErrorMessage('Prosze wybrać termin.');
            return;
        }
        if (this.validator.allValid()) {
            selectedEvent.clientId = this.props.currentUser.userId;
            selectedEvent.agreementUuid = this.state.agreementUuid;
            // TODO:  usuwanie zone z daty pewnie istnieje lepsze rozwiązanie.
            selectedEvent.start = selectedEvent.start.toString();
            selectedEvent.start = new Date(selectedEvent.start.replace('Z', ''));
            this.consultationHelper.persistConsultationRequest(
                selectedEvent.specialization.name,
                consultationType,
                this.state.element.specialization?.chatFee
            );
            this.chatService.reservedByClient(selectedEvent, consultationType);
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // this.scrollToError = true;
            this.forceUpdate();
        }
    }

    setStylesForEvent(events) {
        let convertedEvents = [];
        for (let index = 0; index < events.length; index++) {
            let tempEvents = EventModel.copy(events[index]);
            let generatedUuid = uuidv4();
            tempEvents.id = generatedUuid;
            convertedEvents.push(tempEvents);
        }
        return convertedEvents;
    }

    reserveMailing() {
        this.service
            .reserveMailing({
                client: this.state.element.user,
                specializationId: this.state.element.specialization.id,
                consultationType: 'MAILING',
            })
            .then((scheduledConsultation) => {
                const visibleMailingDialog =
                    scheduledConsultation.paymentStatus === 'PAID_BY_SUBSCRIPTION' || scheduledConsultation.paymentStatus === 'PAID';
                this.setState({
                    scheduledConsultationId: scheduledConsultation.id,
                    clientId: scheduledConsultation.user.id,
                    visibleMailingDialog,
                });
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });
    }

    reserveAndPayForMailing(client) {
        this.service
            .reserveAndPayForMailing({
                client: client,
                specializationId: this.state.element.specialization.id,
                consultationType: 'MAILING',
                templateId: this.props?.elementId,
                agreementUuid: this.authService.loggedIn() ? this.state?.agreementUuid : null,
            })
            .then((res) => {
                window.location.href = res.url + res.token;
            })
            .catch(() => {
                console.log('Problem dotyczący płatności');
            });
    }

    renderChooseHourForScheduled() {
        const foundedEvent = this.state.events.find((event) => event.selected);

        let consultation = this.state?.element?.consultation;
        let minDate = new Date(consultation?.start);
        let maxDate = new Date(consultation?.end);
        maxDate.setHours(new Date(consultation?.end).getHours() - 1);

        let endEventDate = new Date(this.state?.startEventDate);
        endEventDate?.setHours(endEventDate.getHours() + 1);

        // TODO: to wsyzstko mozna w inline
        let header = 'Planowanie konsultacji';
        let noButtonLabel = 'Anuluj';

        if (foundedEvent) {
            header = 'Anulowanie konsultacji';
            noButtonLabel = 'Anuluj termin';
        }

        return this.state.dialogVisibile ? (
            <YesNoDialog
                onChange={(type, x, target) => {
                    if (target.value) {
                        this.selectEvent(consultation, this.state.startEventDate ? this.state.startEventDate : minDate);
                        this.hideDialog();
                    } else {
                        if (foundedEvent) {
                            this.setState({
                                events: this.state.copyEvents,
                            });
                        }
                        this.hideDialog();
                    }
                }}
                yesButtonLabel='Zapisz'
                singleButton={foundedEvent}
                noButtonLabel={noButtonLabel}
                visible={this.state.dialogVisibile}
                header={header}
                name='visible'
                onHide={() => {
                    this.hideDialog();
                }}
                className='plan-or-cancel-consultation-dialog'
            >
                <div className='row col-12'>
                    <div className='row'>
                        <DivContainer rendered={!foundedEvent} colClass='col-12'>
                            {'Proszę wybrać godzinę konsultacji.'}
                        </DivContainer>
                        <InputCalendarComponent
                            id='startHour'
                            name='startHour'
                            showIcon={true}
                            minDate={minDate}
                            rendered={!foundedEvent}
                            maxDate={maxDate}
                            placeholder={'hh:mm'}
                            label={'Godzina rozpoczęcia'}
                            value={this.state.startEventDate ? this.state.startEventDate : minDate}
                            validator={this.validator}
                            colClass='col-md-12 col-sm-12'
                            validators={`required`}
                            timeOnly
                            hourFormat='24'
                            onChange={(a, b, e) => {
                                this.setState({
                                    startEventDate: e.value,
                                });
                            }}
                            viewMode={this.state.viewMode}
                        />
                        <DivContainer rendered={!foundedEvent}>
                            <div style={{fontSize: '0.7em', fontWeight: '600'}} className='col-12 text-danger'>
                                Czas trwania konsultacji to jedna godzina.
                            </div>
                        </DivContainer>
                    </div>
                </div>
            </YesNoDialog>
        ) : null;
    }

    renderedPrices() {
        return this.state.element.specialization !== undefined;
    }

    renderCannotBeScheduledDialog() {
        return (
            <PrimiDialog
                ariaCloseIconLabel='Zamknij okno dialogowe'
                header='Informacja'
                visible={this.state.visibleCannotBeScheduledDialog}
                onHide={() => {
                    this.closeCannotBeScheduledDialog();
                }}
                draggable={false}
            >
                <Card
                    footer={() => {
                        return (
                            <div className='template-list-buttons'>
                                <div className='left'>
                                    <ActionButton
                                        className='cancel-button gray'
                                        label='Anuluj'
                                        handleClick={() => this.closeCannotBeScheduledDialog()}
                                    />
                                </div>
                                <div className='right'>
                                    <ActionButton
                                        label='Zaloguj'
                                        rendered={!this.authService.loggedIn()}
                                        className='ml-2'
                                        handleClick={() => {
                                            this.closeCannotBeScheduledDialog();
                                            window.location.href = AppPrefixUtils.locationHrefUrl('/');
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    }}
                >
                    <div style={{marginBottom: '20px'}}>{'Załóż konto, żeby zaplanować konsultację z naszymi prawnikami.'}</div>
                </Card>
            </PrimiDialog>
        );
    }

    handlePreReserveDate(event) {
        this.setState({
            showPaymentMessageForClientReservation: true,
            bookingEvent: event,
        });
    }

    paymentMessageForClientReservation() {
        const clientMsg =
            ' W momencie rezerwacji wybranego terminu zostaniesz poproszony o dokonanie wpłaty. W przypadku anulowania bądź nie opłacenia będziesz miał/a jeszcze czas aby w zakładce "Zamówienia" ponownie spróbować dokonać wpłaty, jeśli tego nie zrobisz to konsultacja po upływie 12 minut automatycznie zostanie anulowana.';

        return (
            <YesNoDialog
                className={'confirmation-found-consultation-reservation'}
                visible={this.state.showPaymentMessageForClientReservation}
                header='Informacja'
                name='paymentMessageForClientReservation'
                onChange={() => {
                    this.handleReserveDate(this.state.bookingEvent, this.state.bookingConsultationType?.enumValue);
                    this.setState({
                        showPaymentMessageForClientReservation: false,
                    });
                }}
                onHide={() => {
                    this.setState({
                        showPaymentMessageForClientReservation: false,
                    });
                    DialogResizer.removeMaskIfExistOneDialog();
                }}
                noButtonLabel={'Zarezerwuj termin'}
                singleButton
            >
                {clientMsg}
            </YesNoDialog>
        );
    }

    paymentMessageForClientAdHoc() {
        let clientMsg = this.props.currentUser?.userId ? (
            <b>
                {
                    ' W momencie znalezienia konsultanta jest on rezerwowany na czas 12 minut. Następnie zostaniesz poproszony o dokonanie wpłaty. W przypadku nie opłacenia będziesz miał/a jeszcze czas aby w zakładce "Zamówienia" ponownie spróbować dokonać wpłaty, jeśli tego nie zrobisz to konsultacja po upływie 12 minut automatycznie zostanie anulowana.'
                }
            </b>
        ) : (
            <b>
                {
                    'W momencie znalezienia konsultanta jest on rezerwowany na czas 12 minut. Następnie zostaniesz poproszony o dokonanie wpłaty. W przypadku braku realizacji opłacenia konsultacji zostaje ona automatycznie anulowana po 12 minutach'
                }
            </b>
        );

        return (
            <YesNoDialog
                className={'confirmation-found-consultation-reservation'}
                visible={this.state.showPaymentMessageForClientAdHoc}
                header='Informacja'
                name='paymentMessageForClientAdHoc'
                onChange={() => {
                    this.checkConsultantAvailability(this.state.choosenConsultationType);
                    this.setState({
                        showPaymentMessageForClientAdHoc: false,
                    });
                }}
                onHide={() => {
                    this.setState({
                        showPaymentMessageForClientAdHoc: false,
                    });
                    DialogResizer.removeMaskIfExistOneDialog();
                }}
                noButtonLabel={'Znajdź konsultanta'}
                singleButton
            >
                {clientMsg}
            </YesNoDialog>
        );
    }

    shuldClientPayForAdHocConsultation(consultationType) {
        if (this.props?.currentUser?.userId) {
            this.service.shouldUserPayForConsultation(this.props.currentUser.userId).then((shouldPay) => {
                if (shouldPay) {
                    this.setState({
                        showPaymentMessageForClientAdHoc: true,
                        choosenConsultationType: consultationType,
                    });
                } else {
                    this.checkConsultantAvailability(consultationType);
                }
            });
        } else {
            this.setState({
                showPaymentMessageForClientAdHoc: true,
                choosenConsultationType: consultationType,
            });
        }
    }

    checkConsultantAvailability(consultationType) {
        let unregisteredClient = undefined;

        if (!this.authService.loggedIn()) {
            unregisteredClient = this.state.unregisteredClient;
        }

        this.chatService.checkConsultantAvailability(
            this.state.element.specialization,
            consultationType,
            this.state.agreementUuid,
            unregisteredClient
        );
    }

    renderConsultationInformation() {
        return (
            <React.Fragment>
                <InputNumberComponent
                    id='chatFee'
                    name='chatFee'
                    label={'Cena za czat'}
                    colClass='col-xl-4 col-lg-4 col-md-4 col-sm-4'
                    value={this.state.element.specialization?.chatFee}
                    validator={this.validator}
                    onChange={this.handleChange}
                    viewMode={'VIEW'}
                    rendered={this.renderedPrices()}
                    locale='pl-PL'
                    currency='PLN'
                    mode='currency'
                    validators='numeric|max:4'
                />
                <InputNumberComponent
                    id='videoChatFee'
                    name='videoChatFee'
                    label={'Cena za wideo'}
                    colClass='col-xl-4 col-lg-4 col-md-4 col-sm-4'
                    value={this.state.element.specialization?.videoChatFee}
                    validator={this.validator}
                    onChange={this.handleChange}
                    viewMode={'VIEW'}
                    rendered={this.renderedPrices()}
                    locale='pl-PL'
                    currency='PLN'
                    mode='currency'
                    validators='numeric|max:4'
                />
                <InputNumberComponent
                    id='mailingFee'
                    name='mailingFee'
                    label={'Cena za mailing'}
                    colClass='col-xl-4 col-lg-4 col-md-4 col-sm-4'
                    value={this.state.element.specialization?.mailingFee}
                    validator={this.validator}
                    onChange={this.handleChange}
                    viewMode={'VIEW'}
                    rendered={this.renderedPrices()}
                    locale='pl-PL'
                    currency='PLN'
                    mode='currency'
                    validators='numeric|max:4'
                />
            </React.Fragment>
        );
    }
    renderConsultationFooter() {
        return (
            <div className='col-md-12 form-group'>
                <hr className='line-footer' />
                <div className='booking-consultation-footer'>
                    <ActionButton
                        label={'Rozpocznij'}
                        variant={'button'}
                        handleClick={() => {
                            if (this.validator.allValid()) {
                                console.log(this.state.consultationType, 'consultationType');
                                if (!this.state.consultationType.availability) {
                                    this.showErrorMessage('Wybrany typ konsultacji nie jest dostępny');
                                    return;
                                }
                                if (this.state.consultationType?.enumValue === 'MAILING') {
                                    if (this.authService.loggedIn()) {
                                        this.service
                                            .shouldUserPayForConsultation(this.props.currentUser.userId)
                                            .then((res) => {
                                                this.setState(
                                                    {
                                                        visiblePaymentSummaryDialog: res,
                                                    },
                                                    () => {
                                                        if (!res) {
                                                            DialogResizer.removeMaskIfExistOneDialog();
                                                        }
                                                    }
                                                );
                                                if (!res) {
                                                    this.reserveMailing();
                                                }
                                            })
                                            .catch((err) => {
                                                this.showErrorMessage(err);
                                            });
                                    } else {
                                        this.setState({
                                            visiblePaymentSummaryDialog: true,
                                        });
                                    }
                                } else if (this.state.consultationType?.enumValue === 'CHAT') {
                                    this.consultationHelper.persistConsultationRequest(
                                        this.state.element.specialization.name,
                                        ConsultationType.CHAT,
                                        this.state.element.specialization?.chatFee
                                    );

                                    this.shuldClientPayForAdHocConsultation(ConsultationType.CHAT);
                                } else if (this.state.consultationType?.enumValue === 'VIDEO_CHAT') {
                                    this.consultationHelper.persistConsultationRequest(
                                        this.state.element.specialization.name,
                                        ConsultationType.VIDEO_CHAT,
                                        this.state.element.specialization?.videoChatFee
                                    );
                                    this.shuldClientPayForAdHocConsultation(ConsultationType.VIDEO_CHAT);
                                }
                            } else {
                                this.validator.showMessages();
                                // rerender to show messages for the first time
                                // this.scrollToError = true;
                                this.forceUpdate();
                            }
                        }}
                    />
                </div>
            </div>
        );
    }
    renderConsultation() {
        return (
            <DivContainer colClass='row'>
                {this.authService.loggedIn() ? null : (
                    <React.Fragment>
                        <InputTextComponent
                            id={`email`}
                            name={`email`}
                            label={`Email`}
                            colClass='col-md-12'
                            value={this.state.unregisteredClient.email}
                            validator={this.validator}
                            stateField={'unregisteredClient'}
                            viewMode={'EDIT'}
                            validators='required|email'
                            onChange={this.handleChange}
                        />
                        <InputTextComponent
                            id={`firstName`}
                            name={`firstName`}
                            label={`Imię`}
                            colClass='col-md-12'
                            value={this.state.unregisteredClient.firstName}
                            validator={this.validator}
                            stateField={'unregisteredClient'}
                            viewMode={'EDIT'}
                            validators='required|alpha_space_dash|max:50'
                            onChange={this.handleChange}
                        />
                        <InputTextComponent
                            id={`lastName`}
                            name={`lastName`}
                            label={`Nazwisko`}
                            colClass='col-md-12'
                            value={this.state.unregisteredClient.lastName}
                            validator={this.validator}
                            stateField={'unregisteredClient'}
                            viewMode={'EDIT'}
                            validators='required|alpha_space_dash|max:50'
                            onChange={this.handleChange}
                        />
                    </React.Fragment>
                )}

                <InputDropdownComponent
                    id='specialization'
                    name='specialization'
                    dataKey='id'
                    label={'Specjalizacja'}
                    colClass='col-md-12'
                    value={this.state.element.specialization}
                    validator={this.validator}
                    onChange={this.handleChange}
                    viewMode={this.props.elementId !== undefined ? 'VIEW' : 'EDIT'}
                    validators={'required'}
                    optionLabel='name'
                    onAfterStateChange={(e) => this.fetchUsersAndCalendarOpt(e)}
                    options={this.state.specializations}
                />
                {this.renderConsultationInformation()}
                <InputRadioButtonComponent
                    id='consultationType'
                    name='consultationType'
                    colClass='col-12'
                    value={this.state.consultationType}
                    label='Rodzaj konsultacji'
                    viewMode={this.props.viewMode}
                    labelOnRight
                    options={this.state.consultationTypeOptions}
                    valueLabel='label'
                    itemColClass={'col-12'}
                    valueKey='enumValue'
                    validators={'required'}
                    stateField={``}
                    onChange={this.handleChange}
                    validator={this.validator}
                    compareByOneProperty
                />
            </DivContainer>
        );
    }

    renderArrangeConsultations() {
        const initialView = 'timeGridFourDay';
        const views = {
            timeGridFourDay: {
                type: 'timeGrid',
                duration: {days: 4},
            },
        };

        let filteredEvents = this.state?.events.filter((event) => event.isViewed);

        const headerToolbar = {
            right: '',
            left: 'prev,next',
            center: '',
        };
        return (
            <DivContainer colClass='row'>
                <InputDropdownComponent
                    id='specialization'
                    name='specialization'
                    dataKey='id'
                    label={'Specjalizacja'}
                    colClass='col-md-6'
                    value={this.state.element.specialization}
                    validator={this.validator}
                    onChange={this.handleChange}
                    viewMode={this.props.elementId !== undefined ? 'VIEW' : 'EDIT'}
                    validators={'required'}
                    optionLabel='name'
                    options={this.state.specializations}
                    onAfterStateChange={(e) => this.fetchUsersAndCalendarOpt(e)}
                />
                <InputMultiSelectComponent
                    id='users'
                    name='users'
                    colClass='col-md-6'
                    label='Konsultanci'
                    value={this.state.element.users}
                    validator={this.validator}
                    validators='required'
                    onChange={this.handleChange}
                    viewMode={'EDIT'}
                    options={this.state.users}
                    dataKey='id'
                    optionLabel='fullName'
                    filter
                    filterBy='fullName'
                    rendered={this.state.element.specialization !== undefined}
                    onAfterStateChange={(e) => this.calendarUpdateByUserChanged(e)}
                />

                {this.renderConsultationInformation()}
                {this.state.element.specialization ? (
                    <React.Fragment>
                        <div className='row'>
                            <InputRadioButtonComponent
                                id='bookingConsultationType'
                                name='bookingConsultationType'
                                colClass='col-12'
                                value={this.state.bookingConsultationType}
                                label='Rodzaj konsultacji'
                                viewMode={this.props.viewMode}
                                labelOnRight
                                options={this.state.bookingConsultationOptions}
                                valueLabel='label'
                                itemColClass={'col-xl-4 col-lg-4 col-md-4 col-sm-4'}
                                valueKey='enumValue'
                                validators={'required'}
                                stateField={``}
                                onChange={this.handleChange}
                                validator={this.validator}
                                compareByOneProperty
                            />
                        </div>
                        <p>
                            <div className='legal-consultation-dialog-calendar'>
                                <CalendarScheduleComponent
                                    views={views}
                                    clientMode={true}
                                    headerToolbar={headerToolbar}
                                    initialView={initialView}
                                    handleClickOperationButton={(e) => this.getEventsForClient(e)}
                                    select={(e) => this.handleOpenDialog(e)}
                                    events={filteredEvents}
                                    eventClick={(e) => {
                                        this.handleSelectEvent(e);
                                    }}
                                />
                            </div>
                        </p>
                    </React.Fragment>
                ) : null}
            </DivContainer>
        );
    }

    renderBookOrPayAndBook() {
        return (
            <div className='booking-consultation-footer'>
                <ActionButton
                    colClass='col-md-4'
                    label={this.state?.shouldUserPay ? 'Umów i zapłać' : 'Umów'}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        if (this.state.shouldUserPay) {
                            this.handlePreReserveDate(e, this.state.bookingConsultationType?.enumValue, true);
                        } else {
                            this.handleReserveDate(e, this.state.bookingConsultationType?.enumValue);
                        }
                    }}
                    disabled={!this.existSelected() || !this.state.bookingConsultationType}
                />
            </div>
        );
    }

    renderPaymentSummaryDialog() {
        return (
            <PrimiDialog
                ariaCloseIconLabel='Zamknij okno dialogowe'
                header={'Podsumowanie płatności za konsultację'}
                visible={this.state.visiblePaymentSummaryDialog}
                onHide={() => {
                    this.closePaymentSummaryDialog();
                }}
                className='summary-consultation-payment-dialog'
                draggable={false}
            >
                <Card
                    className='small-dialog-card'
                    footer={() => {
                        return (
                            <React.Fragment>
                                <hr className='line-footer' />
                                <div className='footer-dialog-button-container'>
                                    <ActionButton label='Anuluj' handleClick={this.closePaymentSummaryDialog} className='gray ml-2' />
                                    <ActionButton
                                        label={'Zapłać za konsultację'}
                                        handleClick={() => {
                                            this.reserveAndPayForMailing(
                                                this.authService.loggedIn() ? this.state.element.user : this.state.unregisteredClient
                                            );
                                        }}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    }}
                >
                    <div className='row'>
                        <InputTextComponent
                            id='consultationTypeMailing'
                            name='consultationTypeMailing'
                            label='Typ konsultacji'
                            colClass='col-md-4 col-sm-12'
                            validator={this.validator}
                            value='Mailing'
                            viewMode={'VIEW'}
                        />
                        <InputTextComponent
                            id='specialization-payment'
                            name='consultationTypeMailing'
                            label='Specjalizacja'
                            colClass='col-md-4 col-sm-12'
                            validator={this.validator}
                            value={this.state.element.specialization.name}
                            viewMode={'VIEW'}
                        />
                        <InputNumberComponent
                            id='mailingFee'
                            name='mailingFee'
                            label={'Cena'}
                            validator={this.validator}
                            colClass='col-md-4 col-sm-12'
                            locale='pl-PL'
                            currency='PLN'
                            mode='currency'
                            value={this.state.element.specialization.mailingFee}
                            viewMode={'VIEW'}
                        />
                    </div>
                </Card>
            </PrimiDialog>
        );
    }
}

LegalConsultationDialog.defaultProps = {
    backUrl: '/#/template-list',
    currentUser: undefined,
    visible: false,
};

LegalConsultationDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    elementId: PropTypes.number,
};

export default withTranslation()(LegalConsultationDialog);
