import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import {t} from 'i18next';
import {Card} from 'primereact/card';
import ScheduledConsultationService from '../../services/ScheduledConsultationService';
import moment from 'moment';
import EditableDataTableExt from '../../components/EditableDataTableExt';
import {Rating} from 'primereact/rating';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import RecordingService from '../../services/RecordingService';
import ReactAudioPlayer from 'react-audio-player';
import {TabPanel, TabView} from 'primereact/tabview';
import ActionLink from '../../components/ActionLink';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';
import AppPrefixUtils from '../../utils/AppPrefixUtils';

class ConsultationHistoryContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new ScheduledConsultationService());
        this.state = {
            loading: true,
            elementId: props.params.id,
            element: {},
            messages: undefined,
            dialogVisibile: false,
            activeIndex: 0,
            recorderUrls: [],
        };
        this.showDeleteConfirmation = this.showDeleteConfirmation.bind(this);
        this.downloadConsultationMessages = this.downloadConsultationMessages.bind(this);
        this.hideDeleteConfirmation = this.hideDeleteConfirmation.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.recordingService = new RecordingService();
        this.updateTimeAfterGetList = this.updateTimeAfterGetList.bind(this);
        this.updateDateAfterGetList = this.updateDateAfterGetList.bind(this);
    }

    updateSearchCriteria(criteria) {
        return {
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }
    getCleanSearchCriteria() {
        return {
            firstResult: 0,
            maxResults: 10,
            sortField: 'time',
            sortAsc: true,
        };
    }

    getContainerListName() {
        return 'consultation-history-list';
    }

    updateTimeAfterGetList(element) {
        element.time = moment(element.time).format('HH:mm:ss').toString();
    }
    updateDateAfterGetList(element) {
        element.date = moment(element.date).format('HH:mm:ss').toString();
    }

    updateElement(data) {
        if (this._isMounted) {
            let price;
            const {specialization} = data.consultation;
            this.setState({
                element: {
                    executed: data.executed,
                    paymentStatus: data.paymentStatus,
                    startDate: moment(data.startDate).format('DD-MM-YYYY HH:mm:ss').toString(),
                    clientFullName: data.user?.fullName,
                    consultationType: data.consultationType,
                    clientEmail: data.user?.email,
                },
            });

            switch (data.consultationType) {
                case 'CHAT':
                    price = specialization.chatFee;
                    this.renderVideoOrChatComponents(data, price);
                    break;
                case 'VIDEO_CHAT':
                    price = specialization.videoChatFee;
                    this.renderVideoOrChatComponents(data, price);
                    break;
                case 'MAILING':
                    price = specialization.mailingFee;
                    this.renderMailingComponent(data, price);
                    break;
                default:
                    console.log('error');
            }
        }
    }

    createDuration(duration) {
        duration = moment.duration(duration / 1000, 'second').format('hh:mm:ss', {trim: false});
        return duration;
    }

    renderMailingComponent(data, price) {
        this.setState(
            (prevState) => ({
                element: {
                    ...prevState.element,
                    content: data.mailing?.content,
                    subject: data.mailing?.subject,
                    consultantFullName: data.mailing?.consultant?.fullName,
                    consultantEmail: data.mailing?.consultant?.email,
                    price: price,
                },
            }),
            () => this.initAfterSetElement()
        );
    }

    renderVideoOrChatComponents(data, price) {
        this.setState(
            (prevState) => ({
                element: {
                    ...prevState.element,
                    endDate: moment(data.endDate).format('DD-MM-YYYY HH:mm:ss').toString(),
                    clientFeedbackMessage: data.clientFeedbackMessage,
                    consultantFeedbackMessage: data.consultantFeedbackMessage,
                    recordings: data?.recordings,
                    clientRating: data.clientRating,
                    consultantRating: data.consultantRating,
                    duration: this.createDuration(data.duration),
                    realStartDate: data.realStartDate ? moment(data.realStartDate).format('HH:mm:ss').toString() : '',
                    startDateConsultant: data.startDateConsultant ? moment(data.startDateConsultant).format('HH:mm:ss').toString() : '',
                    startDateClient: data.startDateClient ? moment(data.startDateClient).format('HH:mm:ss').toString() : '',
                    consultantFullName: data.consultation.user.fullName,
                    consultantEmail: data.consultation.user.email,
                    price: price,
                },
            }),
            () => this.initAfterSetElement()
        );
    }

    initAfterSetElement() {
        if (this.state.element.consultationType === 'VIDEO_CHAT') {
            this.state.element?.recordings?.forEach((recording) => {
                this.recordingService
                    .getAudioFromRecording(recording.recordingId)
                    .then((res) => {
                        let url = window.URL.createObjectURL(res);
                        let recorderUrls = this.state.recorderUrls;
                        recorderUrls.push(url);
                        this.setState({
                            recorderUrls,
                        });
                    })
                    .catch(() => {});
            });
        }
        this.unblockUi();
    }

    getBackLabel() {
        return 'Wróć do listy historii konsultacji';
    }

    showDeleteConfirmation() {
        this.setState({
            dialogVisibile: true,
        });
    }
    handleTabChange(e) {
        this.setState({
            activeIndex: e.index,
        });
    }

    hideDeleteConfirmation() {
        this.setState({
            dialogVisibile: false,
        });
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    renderInfonormationsForConsultation() {
        const {consultationType} = this.state.element;
        return this.renderProperStructureForDetail(
            <div style={{marginTop: ''}}>
                {consultationType === 'CHAT' ? (
                    <TabView className='consultation-info' activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange}>
                        {!this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE') && (
                            <TabPanel
                                header={
                                    <>
                                        Dziennik zdarzeń <> </>
                                        <img
                                            src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/format_list.svg')}
                                            alt=''
                                            className='cursor-pointer'
                                            title='Historia'
                                        />
                                    </>
                                }
                                key={`log-history-${this.state.activeIndex}`}
                            >
                                {this.renderEventLog()}
                            </TabPanel>
                        )}
                        <TabPanel
                            header={
                                <>
                                    Historia <> </>
                                    <img
                                        src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/history_blue.svg')}
                                        alt=''
                                        className='cursor-pointer'
                                        title='Historia'
                                    />
                                </>
                            }
                            key={`message-history-${this.state.activeIndex}`}
                        >
                            {this.renderChatComponent()}
                        </TabPanel>
                    </TabView>
                ) : consultationType === 'MAILING' ? (
                    <Card className='details-card' footer={this.renderFooter()} header={this.renderHeader()}>
                        <React.Fragment>
                            <InputTextComponent
                                id='subject'
                                name='subject'
                                className='col-lg-12'
                                label={'Temat'}
                                value={this.state.element.subject}
                                validator={this.validator}
                                validators='required|max:100'
                                onChange={this.handleChange}
                                viewMode={'VIEW'}
                            />
                            <InputTextareaComponent
                                id='content'
                                name='content'
                                className='col-lg-12'
                                label={'Wiadomość'}
                                value={this.state.element.content}
                                validator={this.validator}
                                validators='required|max:500'
                                onChange={this.handleChange}
                                rows={10}
                                viewMode={'VIEW'}
                            />
                        </React.Fragment>
                    </Card>
                ) : consultationType === 'VIDEO_CHAT' ? (
                    <TabView activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange}>
                        <TabPanel header={'Historia'} key={`message-history-${this.state.activeIndex}`}>
                            {this.renderAudioComponent()}
                        </TabPanel>
                        {!this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE') && (
                            <TabPanel header={'Dziennik zdarzeń'} key={`log-history-${this.state.activeIndex}`}>
                                {this.renderEventLog()}
                            </TabPanel>
                        )}
                    </TabView>
                ) : null}
            </div>,
            false
        );
    }

    renderEventLog() {
        return (
            <EditableDataTableExt
                id='eventLogs'
                name='eventLogs'
                label='Wartości'
                showLabel={false}
                columns={[
                    {
                        field: 'logType',
                        header: 'Rola',
                        body: this.enumTemplate.bind(this, 'EventLogType', 'logType'),
                    },
                    {
                        field: 'date',
                        header: 'Data zdarzenia',
                    },
                    {
                        field: 'requestType',
                        header: 'Zapytanie',
                        body: this.enumTemplate.bind(this, 'ChatOperationReqestType', 'requestType'),
                    },
                    {
                        field: 'responseType',
                        header: 'Odpowiedź',
                        body: this.enumTemplate.bind(this, 'ChatOperationResponseType', 'responseType'),
                    },
                ]}
                validator={this.validator}
                viewMode={this.props.viewMode}
                onChange={this.handleChange}
                lazy
                paginator
                updateItemElementAfterGetList={this.updateDateAfterGetList}
                editable={false}
                objectId={this.state.elementId}
                addButtonLabel='Dodaj wartość'
                stateField=''
                getList={this.service.getEventLogs}
                dataKey='id'
                showDeleteButtonFunctionLabel='Nieaktywny'
                tableClassName='datatable-responsive base-table'
                className='datatable-responsive base-table'
            />
        );
    }

    downloadConsultationMessages() {
        const {elementId} = this.props;
        this.service.downloadConsultationMessages(elementId, (err) => this.showErrorMessage(err));
    }

    renderAudioComponent() {
        return this.state?.recorderUrls.map((url) => {
            return (
                <div class='row' style={{width: '100%'}}>
                    <ReactAudioPlayer src={url} autoPlay={false} controls={true} />
                </div>
            );
        });
    }

    renderChatComponent() {
        return (
            <div className='mt-2'>
                {this.isLexyPortal() ? (
                    <ActionLink
                        handleClick={this.downloadConsultationMessages}
                        downloadFile
                        variant='accent'
                        boldLabel
                        className='mt-4'
                        key='download-button'
                        iconName='mdi-download'
                        iconColor='accent'
                        iconSize='size-28'
                        iconSide='right'
                    />
                ) : (
                    <img
                        src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/download.svg')}
                        alt=''
                        onClick={this.downloadConsultationMessages}
                        className='cursor-pointer'
                        title='Pobierz historię'
                    />
                )}

                <EditableDataTableExt
                    id='messages'
                    name='messages'
                    label='Wartości'
                    showLabel={false}
                    columns={[
                        {field: 'senderFullName', header: 'Wysyłający'},
                        {
                            field: 'message',
                            header: 'Wiadomość',
                        },
                        {
                            field: 'time',
                            header: 'czas wysłania wiadomości',
                        },
                    ]}
                    validator={this.validator}
                    viewMode={this.props.viewMode}
                    onChange={this.handleChange}
                    lazy
                    paginator
                    updateItemElementAfterGetList={this.updateTimeAfterGetList}
                    editable={false}
                    objectId={this.state.elementId}
                    addButtonLabel='Dodaj wartość'
                    stateField=''
                    getList={this.service.getMessages}
                    dataKey='id'
                    showDeleteButtonFunctionLabel='Nieaktywny'
                    tableClassName='datatable-responsive base-table'
                    className='datatable-responsive base-table'
                />
            </div>
        );
    }

    isNotMailing() {
        const consultationType = this.state?.element?.consultationType;
        return consultationType !== 'MAILING';
    }
    breadCrumbItems() {
        return this.isLexyPortal()
            ? []
            : [
                  {name: 'Historia konsultacji', url: '/#/consultation-history-list'},
                  {name: 'Konsultacja', url: `/#/consultation-history/details/${this.state.element?.id}`},
              ];
    }

    render() {
        return (
            <React.Fragment>
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>

                {this.renderView()}
            </React.Fragment>
        );
    }
    renderCard() {
        return (
            <React.Fragment>
                <Card className='details-card' footer={this.renderFooter()} header={this.renderHeader()}>
                    <form onSubmit={this.handleFormSubmit} noValidate>
                        {this.renderDetails()} {this.renderInfonormationsForConsultation()}
                    </form>
                </Card>
                {/* <Card className='details-card' footer={this.renderFooter()} header={this.renderHeader()}> */}

                {/* </Card> */}
            </React.Fragment>
        );
    }
    paymentStatusSrc(paymentStatus) {
        const imageSrc = AppPrefixUtils.locationHrefUrl('/images/lexdocs/');

        switch (paymentStatus) {
            case 'CANCELLED':
                return <img src={imageSrc + 'block.svg'} alt='' title='Anulowana' />;
            case 'UNPAID':
                return <img src={imageSrc + 'close.svg'} alt='' title='Nieopłacona' />;
            case 'PAID':
                return <img src={imageSrc + 'done_green.svg'} alt='' title='Opłacona' />;
            case 'PAID_BY_SUBSCRIPTION':
                return <img src={imageSrc + 'done_blue.svg'} alt='' title='Opłacony z abonamentu' />;
            default:
                return undefined;
        }
    }
    renderDetails() {
        const {viewMode} = this.props;
        return (
            <React.Fragment>
                {this.renderProperStructureForDetail(
                    <>
                        <div className='row'>
                            <InputTextComponent
                                id='consultantFullName'
                                name='consultantFullName'
                                label={'Konsultant'}
                                //colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.consultantFullName}
                                validator={this.validator}
                                onChange={this.handleChange}
                                viewMode={viewMode}
                            />
                            <InputTextComponent
                                id='consultantEmail'
                                name='consultantEmail'
                                label={'email'}
                                // colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.consultantEmail}
                                validator={this.validator}
                                onChange={this.handleChange}
                                viewMode={viewMode}
                                wordBreak
                            />{' '}
                            <InputTextComponent
                                id='paymentStatus'
                                name='paymentStatus'
                                label={'Status płatności'}
                                //     colClass='col-md-3'
                                value={
                                    this.isLexyPortal()
                                        ? t(`PaymentStatus.${this.state.element.paymentStatus}`)
                                        : this.paymentStatusSrc(this.state.element.paymentStatus)
                                }
                                validator={this.validator}
                                onChange={this.handleChange}
                                viewMode={viewMode}
                            />
                        </div>
                        <div className='row'>
                            <InputTextComponent
                                id='clientFullName'
                                name='clientFullName'
                                label={'Klient'}
                                // colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.clientFullName}
                                validator={this.validator}
                                onChange={this.handleChange}
                                viewMode={viewMode}
                            />
                            <InputTextComponent
                                id='clientEmail'
                                name='clientEmail'
                                label={'email'}
                                //  colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.clientEmail}
                                validator={this.validator}
                                onChange={this.handleChange}
                                viewMode={viewMode}
                                wordBreak
                            />{' '}
                            {this.isLexyPortal() ? (
                                <InputTextComponent
                                    id='executed'
                                    name='executed'
                                    label={'Status kosnultacji'}
                                    colClass={this.isNotMailing() ? 'col-xl-4 col-lg-6 col-md-6 col-sm-12' : 'col-md-12'}
                                    value={this.state.element.executed ? 'Konsultacja się odbyła' : 'Konsultacja jeszcze się nie odbyła'}
                                    validator={this.validator}
                                    onChange={this.handleChange}
                                    viewMode={viewMode}
                                />
                            ) : (
                                <InputTextComponent
                                    id='executed'
                                    name='executed'
                                    label={'Status kosnultacji'}
                                    colClass={this.isNotMailing() ? 'col-xl-4 col-lg-6 col-md-6 col-sm-12' : 'col-md-12'}
                                    value={
                                        this.state.element.executed ? (
                                            <img
                                                src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/done_green.svg`)}
                                                alt='Konsultacja się odbyła'
                                                title='Konsultacja się odbyła'
                                            />
                                        ) : (
                                            <img
                                                src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/hourglass.svg`)}
                                                alt='Konsultacja jeszcze się nie odbyła'
                                                title='Konsultacja jeszcze się nie odbyła'
                                            />
                                        )
                                    }
                                    validator={this.validator}
                                    onChange={this.handleChange}
                                    viewMode={viewMode}
                                />
                            )}
                        </div>
                        <div className='row'>
                            <InputTextComponent
                                id='startDateConsultant'
                                name='startDateConsultant'
                                label={'Godzina rozpoczęcia konsultacji przez konsultanta'}
                                //     colClass='col-md-6'
                                value={this.state.element.startDateConsultant}
                                validator={this.validator}
                                onChange={this.handleChange}
                                rendered={this.isNotMailing() && this.state.element.startDateConsultant}
                                viewMode={viewMode}
                            />
                        </div>
                        <div className='row'>
                            <InputTextComponent
                                id='endDate'
                                name='endDate'
                                label={'Planowany termin'}
                                colClass='col-lg-4 col-sm-12'
                                value={
                                    <div>
                                        <div>{this.state.element.startDate}</div> <div>{this.state.element.endDate}</div>{' '}
                                    </div>
                                }
                                validator={this.validator}
                                onChange={this.handleChange}
                                rendered={this.isNotMailing()}
                                viewMode={viewMode}
                            />
                            <InputTextComponent
                                id='realStartDate'
                                name='realStartDate'
                                label={'Rzeczywisty termin'}
                                //    colClass='col-md-3'
                                value={
                                    <div>
                                        <div>{this.state.element.realStartDate}</div>
                                    </div>
                                }
                                validator={this.validator}
                                onChange={this.handleChange}
                                rendered={this.isNotMailing()}
                                viewMode={viewMode}
                            />
                            <InputTextComponent
                                id='duration'
                                name='duration'
                                label={'Czas trwania konsultacji'}
                                //     colClass='col-md-3'
                                value={this.state.element.duration}
                                validator={this.validator}
                                onChange={this.handleChange}
                                rendered={this.isNotMailing()}
                                viewMode={viewMode}
                            />
                            <InputTextComponent
                                id='consultationType'
                                name='consultationType'
                                label={'Typ konsultacji'}
                                className='float-right'
                                //     colClass='col-md-3'
                                value={t(`ConsultationType.${this.state.element.consultationType}`)}
                                validator={this.validator}
                                onChange={this.handleChange}
                                viewMode={viewMode}
                            />

                            <InputTextComponent
                                id='price'
                                name='price'
                                label={'Cena za konsultację'}
                                //     colClass='col-md-3'
                                value={this.state.element.price + 'zł'}
                                validator={this.validator}
                                onChange={this.handleChange}
                                viewMode={viewMode}
                            />
                            <InputTextComponent
                                id='startDateClient'
                                name='startDateClient'
                                label={'Godzina rozpoczęcia konsultacji przez klienta'}
                                //   colClass='col-md-6 mb-2'
                                value={this.state.element.startDateClient}
                                validator={this.validator}
                                onChange={this.handleChange}
                                rendered={this.isNotMailing() && this.state.element.startDateClient}
                                viewMode={viewMode}
                            />
                            <div className='mb-4'>
                                {this.state.element.clientRating ? (
                                    <React.Fragment>
                                        <InputTextComponent
                                            id='clientFeedbackMessage'
                                            name='clientFeedbackMessage'
                                            label={'Komentarz klienta'}
                                            colClass='col-md-6'
                                            className={'mt-2'}
                                            value={this.state.element.clientFeedbackMessage}
                                            validator={this.validator}
                                            onChange={this.handleChange}
                                            rendered={this.isNotMailing()}
                                            viewMode={viewMode}
                                        />
                                        {this.isNotMailing() && (
                                            <Rating value={this.state.element.clientRating} className='col-md-6 mt-2' cancel={false} />
                                        )}
                                    </React.Fragment>
                                ) : null}
                                {this.state.element.consultantRating ? (
                                    <React.Fragment>
                                        <InputTextComponent
                                            id='consultantFeedbackMessage'
                                            name='consultantFeedbackMessage'
                                            label={'Komentarz Konsultanta'}
                                            colClass='col-md-6 '
                                            className={'mt-2'}
                                            value={this.state.element.consultantFeedbackMessage}
                                            validator={this.validator}
                                            onChange={this.handleChange}
                                            rendered={this.isNotMailing()}
                                            viewMode={viewMode}
                                        />
                                        {this.isNotMailing() && (
                                            <Rating value={this.state.element.consultantRating} className='col-md-6 mt-2' cancel={false} />
                                        )}
                                    </React.Fragment>
                                ) : null}
                            </div>
                        </div>
                    </>
                )}
            </React.Fragment>
        );
    }
}

ConsultationHistoryContainer.defaultProps = {
    backUrl: '/#/consultation-history-list',
    cancelUrl: '/#/consultation-history-list/details',
};

ConsultationHistoryContainer.propTypes = {
    backUrl: PropTypes.string,
    editUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
};

export default withTranslation()(ConsultationHistoryContainer);
