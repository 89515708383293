/* eslint-disable react/jsx-max-props-per-line */
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

export const UserLexDocSDocumentsCardComponent = (props) => {
    const {
        statusName,
        status,
        index,
        buyDate,
        documentName,
        documentTitle,
        btnGenerateVisible,
        onSend,
        btnEditVisible,
        onChangeGenerate,
        onChangeEdit,
        onSelectAgreement,
    } = props;

    const [title, setTitle] = React.useState(documentTitle);
    const [date, setBuyDate] = React.useState('');
    const [name, setName] = React.useState('');
    const [id, setId] = React.useState('');

    React.useEffect(() => {
        if (documentTitle.length >= 80) {
            const title = documentTitle.substring(0, 80) + '...';
            setTitle(title);
        }
        if (buyDate) {
            const cdate = moment(buyDate).format('YYYY-MM-DD');
            setBuyDate(cdate);
        }
        if (documentName) {
            const name = documentName >= 40 ? documentName.substring(0, 40) + '...' : documentName;
            setName(name);
        }
        const id = `card-documents-${index}`;
        setId(id);
    }, [props, documentTitle, date, buyDate, index, documentName, status]);

    return (
        <div
            id={id}
            className='card-documents col-xl-4 col-lg-6 col-md-8 col-sm-12'
            onClick={(el) => {
                onSelectAgreement(index);

                const element = document.getElementById(id);

                if (Array.from(element.classList).includes('selected-agreement')) {
                    element.classList.remove('selected-agreement');
                } else {
                    element.classList.add('selected-agreement');
                }
            }}
        >
            <div className='row header-top '>
                <div className='header-top-left col-9'> {title} </div>
                <div className='header-top-right col-3 '> {date}</div>
            </div>
            <div className='document-name white'>{name}</div>
            <div className='footer'>
                {btnGenerateVisible && (
                    <div className='generate row'>
                        {status === 'BEFORE_GENERATE' && (
                            <button
                                className=' col-4 footer-generate-btn footer-generate-btn-send'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onSend();
                                }}
                            >
                                Wyślij
                            </button>
                        )}
                        <button
                            className='col-4 footer-generate-btn footer-generate-btn-generate'
                            onClick={(e) => {
                                e.stopPropagation();
                                onChangeGenerate();
                            }}
                        >
                            Pobierz
                        </button>
                        {btnEditVisible ? (
                            <button
                                className='col-4 edit-temp float-right'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onChangeEdit();
                                }}
                            >
                                Edytuj
                            </button>
                        ) : null}
                    </div>
                )}
            </div>
        </div>
    );
};

UserLexDocSDocumentsCardComponent.defaultProps = {};

UserLexDocSDocumentsCardComponent.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    onChangeEdit: PropTypes.func,
    onChangeGenerate: PropTypes.func,
    btnGenerateVisible: PropTypes.bool,
    btnEditVisible: PropTypes.bool,
    documentName: PropTypes.string,
    documentTitle: PropTypes.string,
    status: PropTypes.string,
    buyDate: PropTypes.string,
};

export default UserLexDocSDocumentsCardComponent;
