import React from 'react';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import DivContainer from '../../components/DivContainer';
import {ActionButton} from '../../components/ActionButton';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import ScheduledConsultationService from '../../services/ScheduledConsultationService';
import ScheduledConsultationPublicService from '../../services/public/ScheduledConsultationService';
import DialogResizer from '../../components/DialogResizer';

class MailingDialog extends BaseDialogContainer {
    constructor(props) {
        super(props);
        this.state = {
            subject: undefined,
            content: undefined,
            viewMode: this.props.viewMode,
            activeIndex: 0,
        };
        this.service = this.authService.loggedIn() ? new ScheduledConsultationService() : new ScheduledConsultationPublicService();
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    prepareFooter() {
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton label={'Anuluj'} variant='button cancel-button gray' handleClick={this.onHide} />
                <ActionButton label={'Wyślij'} variant='button save-button blue' handleClick={this.handleFormSubmit} />
            </div>
        );
    }

    onHide() {
        this.props.onHide();
        DialogResizer.removeMaskIfExistOneDialog();
    }
    onKeyDown(e) {
        console.log(e);
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    renderHeader() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>Wysyłanie wiadomości do konsultanta</label>
                </DivContainer>
            </DivContainer>
        );
    }

    renderInformation() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <p>
                        <label className='text-danger'>
                            {' '}
                            Po wysłaniu wiadomości oczekuj na odpowiedź na skrzynce mailowej. Nie zamykaj okna przeglądarki do czasu
                            wysłania wiadomości email.{' '}
                        </label>
                    </p>
                </DivContainer>
            </DivContainer>
        );
    }

    getContainerListName() {
        return 'template-list-container';
    }

    getAddSucces() {
        return 'Wysłano wiadomość';
    }

    renderDetails() {
        return this.renderView();
    }

    handleFormSubmit() {
        if (this.validator.allValid()) {
            DialogResizer.removeMaskIfExistOneDialog();

            this.service
                .startMailing(
                    {
                        specializationId: this.props.specializationId,
                        consultantId: this.state.consultant.id,
                        subject: this.state.subject,
                        content: this.state.content,
                    },
                    this.props.scheduledConsultationId
                )
                .then(() => {
                    this.props.afterSave();
                })
                .catch((ex) => {
                    this.showErrorMessage(ex);
                });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // this.scrollToError = true;
            this.forceUpdate();
        }
    }

    renderView() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    {this.renderInformation()}
                    <InputDropdownComponent
                        id='consultant'
                        name='consultant'
                        colClass='col-md-12'
                        label='Konsultanci'
                        value={this.state.consultant}
                        validator={this.validator}
                        validators='required'
                        stateField={''}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        options={this.props.consultants}
                        dataKey='id'
                        optionLabel='fullName'
                        filter
                        filterBy='fullName'
                    />

                    <InputTextComponent
                        id='subject'
                        name='subject'
                        label={'Tytuł'}
                        colClass='col-md-12'
                        value={this.state.subject}
                        stateField={''}
                        validator={this.validator}
                        validators='required|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextareaComponent
                        id='content'
                        name='content'
                        label={'Wiadomość'}
                        colClass='col-md-12'
                        stateField={''}
                        value={this.state.content}
                        validator={this.validator}
                        validators='required|max:500'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        rows={7}
                        autoResize
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
}

MailingDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
};

MailingDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
};

export default withTranslation()(MailingDialog);
