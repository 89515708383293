import {FilterMatchMode} from 'primereact/api';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import {withTranslation} from 'react-i18next';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import ActionLink from '../../components/ActionLink';
import CustomDataTable from '../../components/CustomDataTable';
import CustomLabel from '../../components/CustomLabel';
import DivContainer from '../../components/DivContainer';
import EditableDataTableExt from '../../components/EditableDataTableExt';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import CategoryService from '../../services/CategoryService';
import InternalUserService from '../../services/InternalUserService';
import PurchasedService from '../../services/PurchasedService';
import SubscriptionService from '../../services/SubscriptionService';
import TemplateService from '../../services/TemplateService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import CurrencyUtils from './../../utils/CurrencyUtils';
import PackageTypeDialog from './PackageTypeDialog';
import {CookiesConstants} from '../../utils/CookiesConstants';
import PrimiAccordion from '../../components/inputs/PrimiAccordion';
import DialogResizer from '../../components/DialogResizer';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import InputTextEditorComponent from '../../components/inputs/InputTextEditorComponent';

class SubscriptionContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new SubscriptionService());
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.categoryService = new CategoryService();
        this.templateService = new TemplateService();
        this.purchasedService = new PurchasedService();
        this.showExitConfirmationDialog = true;
        this.state = {
            loading: true,
            elementId: props.params.id,
            element: {
                name: '',
                description: '',
                counter: undefined,
                active: undefined,
                selected: undefined,
                agreementTemplates: [],
                types: [],
            },
            users: [],
            categories: [],
            templates: [],
            temporary: false,
            allTypes: [],
            copyOfAllTypes: [],
            types: [],
            filteredTypeOfPackage: [],
            viewMode: this.props.viewMode,
            packageTypeOptions: [
                {
                    name: 'Ograniczony',
                    enumValue: 'LIMITED',
                },
                {
                    name: 'Indywidualny',
                    enumValue: 'INDIVIDUAL',
                },
                {
                    name: 'Premium',
                    enumValue: 'PREMIUM',
                },
            ],
            planTypeOptions: [
                {
                    name: 'Kwartalny',
                    enumValue: 'QUARTERLY',
                },
                {
                    name: 'Półroczny',
                    enumValue: 'SEMI_ANNUAL',
                },
                {
                    name: 'Roczny',
                    enumValue: 'ANNUAL',
                },
            ],
            globalFilterValue: '',
            filters: {
                global: {value: null, matchMode: FilterMatchMode.CONTAINS},
                name: {value: null, matchMode: FilterMatchMode.CONTAINS},
                'category.name': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'specialization.name': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
        };
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.internalUserService = new InternalUserService();
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onAfterRemoveElement = this.onAfterRemoveElement.bind(this);
        this.showTypeDetails = this.showTypeDetails.bind(this);
        this.onHide = this.onHide.bind(this);
        this.showPackageTypeDialog = this.showPackageTypeDialog.bind(this);
        this.handleAddTypes = this.handleAddTypes.bind(this);
        this.handleEditType = this.handleEditType.bind(this);
        this.changeActiveStatus = this.changeActiveStatus.bind(this);
        this.goToUserProfile = this.goToUserProfile.bind(this);
        this.calculatedAmountBodyTemplate = this.calculatedAmountBodyTemplate.bind(this);
    }

    handleEditType(element) {
        let allElements = this.state.types;

        for (let index = 0; index < allElements.length; index++) {
            if (allElements[index].id === element.id) {
                allElements[index] = element;
                break;
            }
        }
        this.setState(
            () => ({
                types: allElements,
                dialogVisibile: false,
            }),
            () => this.unblockUi()
        );
    }

    handleAddTypes(element) {
        let addedElements = this.state.types;

        for (let index = 0; index < element.length; index++) {
            addedElements.push(element[index]);
        }

        this.setState(
            () => ({
                types: addedElements,
            }),
            () => this.unblockUi()
        );

        this.onHide();
    }

    initBeforeSetElement() {
        this.service
            .getTypeBySubscription(this.state.elementId)
            .then((types) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                    },
                    types: types,
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });

        this.categoryService
            .getAll()
            .then((categories) =>
                this.setState({
                    categories,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });
        this.templateService
            .getAll()
            .then((templates) => {
                this.setState({
                    templates,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać szablonów');
            });
        this.enumService
            .getEnumList('SubscriptionType', this.translate)
            .then((allTypes) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                    },
                    allTypes: allTypes,
                    copyOfAllTypes: structuredClone(allTypes),
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });

        this.internalUserService
            .getUsersBySubscriptionId(this.state.elementId)
            .then((users) => {
                this.setState({
                    users,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy uzytkowników');
            });
    }

    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: false}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
            if (callback) callback();
        });
    }

    prepareFooterItems() {
        const {cancleEditUrl, cancelUrl, viewMode} = this.props;
        const {elementId} = this.state;
        let goBackUrl;
        if (viewMode === 'EDIT') {
            goBackUrl = `${cancleEditUrl}/${elementId}`;
        } else {
            goBackUrl = `${cancelUrl}/${elementId}`;
        }
        return [
            {
                label: 'Anuluj',
                onClick: this.removeCookie(CookiesConstants.DETAILS_VIEW_MODE),
                href: AppPrefixUtils.locationHrefUrl(goBackUrl),
                className: 'header-item  cancel-button gray',
                rendered: viewMode === 'EDIT',
            },
            {
                label: 'Zapisz',
                className: 'float-right',
                onClick: this.handleFormSubmit,
                variant: 'accent',
                rendered: viewMode === 'EDIT',
            },
        ];
    }

    prepareEditButton(rendered, label) {
        const {editUrl} = this.props;
        const {element} = this.state;
        return {
            label: label !== undefined ? label : 'Edytuj',
            type: 'BUTTON',
            variant: '',
            onClick: this.saveCookie(CookiesConstants.DETAILS_VIEW_MODE, 'EDIT'),
            href: AppPrefixUtils.locationHrefUrl(`${editUrl}/${element.id}`),
            rendered: rendered,
            // iconName: 'mdi-pencil',
            iconSide: 'left',
            iconSize: 'm',
        };
    }

    getBackLabel() {
        return 'Wróć do listy';
    }

    render() {
        return (
            <div className='container-fluid'>
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                <BreadcrumbsItem to='/subscription-list' className='p-link'>
                    {'Szablony wiadomości'}
                </BreadcrumbsItem>
                {this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/subscription/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
                {this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/subscription/edit'>{'Edycja'}</BreadcrumbsItem> : null}
                {this.renderView()}
            </div>
        );
    }
    getContainerListName() {
        return 'subscription-list';
    }

    getUpdateSucces() {
        return 'Pakiet został zaktualizowany';
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    renderCategories() {
        return this.state.categories
            ? this.state.categories.map((i, index) => {
                  return (
                      <div class='row' style={{width: '100%'}}>
                          <InputCheckboxComponent
                              id={`${index}-categories`}
                              name='selected'
                              label={i.name}
                              labelOnRight
                              value={!!i.selected}
                              validator={this.validator}
                              validators='not_required'
                              colClass='col-md-6'
                              onChange={this.handleChange}
                              viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
                              disabled={this.state.categories.selected}
                              stateField={`categories[${index}]`}
                              positioning='col-md-12 form-group-checkbox-flex'
                          />
                      </div>
                  );
              })
            : null;
    }

    onSelectionChange(event) {
        const value = event.value;
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                agreementTemplates: value,
            },
        }));
    }

    changeActiveStatus(value) {
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                active: !!value,
            },
        }));
        const {elementId} = this.state;

        if (!!value) this.service.activateSubscription(elementId);
        else this.service.deactivateSubscription(elementId);

        if (!!value) {
            this.redirectToDetails(elementId);
        }
    }

    redirectToDetails(elementId) {
        window.location.href = AppPrefixUtils.locationHrefUrl(this.props.detailsUrl + '/' + elementId);
    }

    renderDataTemplateHeader() {
        return (
            <div className='mt-3'>
                <CustomLabel colClass='col-md-12' label='Lista szablonów' />
                <PrimiAccordion
                    body={
                        <div className='col-12 p-2'>
                            <div className='row' style={{justifyContent: 'space-evenly'}}>
                                <InputText
                                    className='col-lg-4'
                                    value={this.state.filterName}
                                    onChange={(e) => this.onGlobalFilterChange(e, 'name')}
                                    placeholder='Wpisz nazwę '
                                />

                                <InputText
                                    className='col-lg-4'
                                    value={this.state.filterCategory}
                                    onChange={(e) => this.onGlobalFilterChange(e, 'category.name')}
                                    placeholder='Wpisz kategorię'
                                />

                                <InputText
                                    className='col-lg-4'
                                    value={this.state.filterSpecialization}
                                    onChange={(e) => this.onGlobalFilterChange(e, 'specialization.name')}
                                    placeholder='Wpisz specjalizację'
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        );
    }

    onGlobalFilterChange(e, type) {
        const value = e.target.value;
        let filters = {...this.state.filters};
        filters[type].value = value;
        switch (type) {
            case 'name':
                this.setState({filters, filterName: value});
                break;
            case 'category.name':
                this.setState({filters, filterCategory: value});
                break;
            case 'specialization.name':
                this.setState({filters, filterSpecialization: value});
                break;
            default:
                console.log(`no opt`);
        }
    }

    renderTemplatesTable() {
        return (
            <React.Fragment>
                {this.renderDataTemplateHeader()}
                <div className='datatable-responsive'>
                    <CustomDataTable
                        value={this.state.templates}
                        selection={this.state.element.agreementTemplates}
                        onSelectionChange={this.onSelectionChange}
                        emptyMessage='Brak rekordów do wyświetlenia'
                        globalFilterFields={['name', 'category.name', 'specialization.name']}
                        currentPageReportTemplate={this.state.element.agreementTemplates}
                        loading={this.state.loading}
                        filters={this.state.filters}
                        filterDisplay='menu'
                        dataKey='id'
                        className='p-datatable-responsive'
                    >
                        <Column field='id' header='ID' />
                        <Column field='name' header='Tytuł szablonu' />
                        <Column field='description' header='Opis' />
                        <Column field='category.name' header='Kategoria' />
                        <Column field='specialization.name' header='Specjalizacja' />
                        <Column selectionMode='multiple' />
                    </CustomDataTable>
                </div>
            </React.Fragment>
        );
    }

    showTypeDetails(rowData) {
        this.setState({dialogVisibile: true, viewMode: 'EDIT', typeDetail: rowData});
    }

    handleFormSubmit(event) {
        let {element} = this.state;
        element.types = this.state.types;
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            this.blockUi(this.handleValidForm);
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.scrollToError = true;
            this.forceUpdate();
        }
        this.removeCookie(CookiesConstants.DETAILS_VIEW_MODE);
    }

    renderTypeOfPackage() {
        return (
            <EditableDataTableExt
                id={`package-table-id`}
                name='packages'
                label=''
                showLabel={true}
                columns={[
                    {
                        field: 'enumValue',
                        header: 'Typ',
                        type: 'TEXT',
                        validators: 'required|max:50',
                        body: this.enumTemplate.bind(this, 'SubscriptionType', 'enumValue'),
                    },
                    {
                        field: 'consultation',
                        header: 'Liczba konsultacji',
                        type: 'TEXT',
                        validators: 'not_required|max:100',
                    },
                    {
                        field: 'price',
                        header: 'Cena',
                        type: 'TEXT',
                        validators: 'not_required|max:500',
                        body: this.calculatedAmountBodyTemplate,
                    },
                ]}
                addedListName='addedList'
                addedList={this.state.types}
                modifiedListName='modifiedList'
                validator={this.props.validator}
                viewMode={this.props.viewMode}
                editable
                showAddButton={this.state.types.length !== 3}
                showDeleteButton={this.props.viewMode !== 'VIEW'}
                addButtonLabel='Dodaj typ pakietu'
                dataKey='uuid'
                tableClassName='agreement-tags-table datatable-responsive'
                value={this.state.types}
                publicMode
                validators='array_required'
                permanentDelete
                stateField={'element'}
                addButtonIconName='mdi-plus'
                customIconViewSrc={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                customItemDeleteSrc={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/delete_red.svg`)}
                deleteButtonClassName='float-left pi pi-trash no-underline'
                showDeleteButtonLabel={false}
                handleAddElement={this.showPackageTypeDialog}
                onAfterRemoveElement={this.onAfterRemoveElement}
                showDetails={this.showTypeDetails}
                showDetailsButton
            />
        );
    }

    onAfterRemoveElement(elementToDelete) {
        let editableArray = this.state.types.filter((element) => element !== elementToDelete);
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
            },
            types: editableArray,
        }));
    }

    goToUserProfile(user) {
        return (
            <div>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                    onClick={this.handleUserDetails.bind(this, user)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        );
    }
    calculatedAmountBodyTemplate(rowData) {
        return CurrencyUtils.currency(rowData.price);
    }

    handleUserDetails(user, e) {
        window.location.href = AppPrefixUtils.locationHrefUrl(this.props.userDetailsUrl + '/' + user.id);
    }

    onHide() {
        this.setState(
            {
                allTypes: structuredClone(this.state.copyOfAllTypes),
                dialogVisibile: false,
            },
            () => DialogResizer.removeMaskIfExistOneDialog()
        );
    }

    showPackageTypeDialog(e) {
        this.setState({dialogVisibile: true, viewMode: 'NEW'});
    }
    prepareHeaderItems() {
        return [
            this.prepareEditButton(this.props.viewMode === 'VIEW' && this.state.element.active === false),
            {
                label: this.state.element.active === false ? 'Aktywuj' : 'Dezaktywuj',
                onClick: (e) => {
                    e.preventDefault();
                    this.changeActiveStatus(!this.state.element.active);
                    this.removeCookie(CookiesConstants.DETAILS_VIEW_MODE);
                },
                type: 'BUTTON',
                className: `${this.state.element.active === false ? 'cancel-button gray' : ''}`,
                variant: 'white',
            },
        ];
    }
    breadCrumbItems() {
        return [
            {name: 'Pakiety abonamentowe', url: '/#/subscription-list'},
            {name: 'Pakiet', url: `subscription/details${this.state.element?.id}`},
        ];
    }
    renderDetails() {
        return (
            <React.Fragment>
                <div class='row'>
                    {this.renderProperStructureForDetail(
                        <>
                            <div class='col-md-12 col-sm-12'>
                                <div class='row'>
                                    <InputTextComponent
                                        id='name'
                                        name='name'
                                        label={'Nazwa'}
                                        colClass='col-lg-4 col-md-6 col-sm-12'
                                        value={this.state.element.name}
                                        validator={this.validator}
                                        validators='required|max:255'
                                        onChange={this.handleChange}
                                        viewMode={this.props.viewMode}
                                    />
                                    <InputDropdownComponent
                                        id='planType'
                                        name='planType'
                                        label={'Plan pakietu'}
                                        colClass='col-lg-4 col-md-6 col-sm-12'
                                        value={this.state.element.planType}
                                        validator={this.validator}
                                        options={this.state.planTypeOptions}
                                        onChange={this.handleChange}
                                        viewMode={this.props.viewMode}
                                        dataKey='enumValue'
                                        validators='required'
                                        optionLabel='name'
                                        optionValue='enumValue'
                                        enumMode
                                        enumName='PlanType'
                                    />
                                    <InputDropdownComponent
                                        id='packageType'
                                        name='packageType'
                                        label={'Rodzaj pakietu'}
                                        colClass='col-lg-4 col-md-6 col-sm-12'
                                        value={this.state.element.packageType}
                                        validator={this.validator}
                                        options={this.state.packageTypeOptions}
                                        onChange={this.handleChange}
                                        viewMode={this.props.viewMode}
                                        dataKey='enumValue'
                                        validators='required'
                                        optionLabel='name'
                                        optionValue='enumValue'
                                        enumMode
                                        enumName='PackageType'
                                    />

                                    <InputTextEditorComponent
                                        id='description-package'
                                        name='description'
                                        colClass='col-12'
                                        value={this.state.element.description}
                                        label='Opis'
                                        border={true}
                                        validator={this.validator}
                                        validators={`not-required|max:499`}
                                        rows='3'
                                        onChange={this.handleChange}
                                        viewMode={this.props.viewMode}
                                        customStyle={{width: '100%', height: '100px', border: 'unset'}}
                                    />
                                </div>

                                <div class='row'>
                                    <InputTextComponent
                                        id='active'
                                        name='active'
                                        label={'Status aktywności'}
                                        colClass='col-md-6'
                                        value={
                                            this.state.element.active ? (
                                                <img
                                                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/done_green.svg`)}
                                                    alt=''
                                                    title='Aktywny'
                                                ></img>
                                            ) : (
                                                <img
                                                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/block.svg`)}
                                                    alt=''
                                                    title='Nieaktywny'
                                                ></img>
                                            )
                                        }
                                        validator={this.validator}
                                        validators='required|max:255'
                                        onChange={this.handleChange}
                                        viewMode={'VIEW'}
                                    />
                                </div>

                                {this.props.viewMode === 'VIEW' ? (
                                    <DivContainer>
                                        <div className='datatable-responsive'>
                                            <CustomDataTable
                                                value={this.state.types ? this.state.types : null}
                                                emptyMessage='Brak rekordów do wyświetlenia'
                                                responsive
                                            >
                                                <Column
                                                    field='enumValue'
                                                    header='Typ'
                                                    body={this.enumTemplate.bind(this, 'SubscriptionType', 'enumValue')}
                                                />
                                                <Column field='consultation' header='Liczba konsultacji' />
                                                <Column field='price' header='Cena' body={this.calculatedAmountBodyTemplate} />
                                            </CustomDataTable>
                                        </div>
                                    </DivContainer>
                                ) : (
                                    this.renderTypeOfPackage()
                                )}
                            </div>
                        </>
                    )}
                    {/* // TODO: zmien ikone filtrue */}

                    {this.renderProperStructureForDetail(
                        <>
                            {this.props.viewMode === 'VIEW' || this.state.element.packageType === 'PREMIUM' ? (
                                <DivContainer>
                                    <div className='datatable-responsive'>
                                        {this.renderDataTemplateHeader()}
                                        <CustomDataTable
                                            value={
                                                this.state.element.agreementTemplates
                                                    ? this.state.element.agreementTemplates.sort(
                                                          (a, b) => parseFloat(a.id) - parseFloat(b.id)
                                                      )
                                                    : null
                                            }
                                            emptyMessage='Brak rekordów do wyświetlenia'
                                            // header={}
                                            globalFilterFields={['name', 'description', 'category.name', 'specialization.name']}
                                            currentPageReportTemplate={this.state.element.agreementTemplates}
                                            loading={this.state.loading}
                                            filters={this.state.filters}
                                            filterDisplay='menu'
                                            //responsiveLayout='scroll'
                                            paginator
                                            rows={10}
                                            dataKey='id'
                                            className='p-datatable-responsive'
                                        >
                                            <Column field='id' header='Identyfikator' />
                                            <Column field='name' header='Nazwa' />
                                            <Column field='description' header='Opis' />
                                            <Column field='category.name' header='Kategoria' />
                                            <Column field='specialization.name' header='Specjalizcja' />
                                        </CustomDataTable>
                                    </div>
                                </DivContainer>
                            ) : (
                                this.renderTemplatesTable()
                            )}
                            <CustomLabel colClass='col-md-12' label='Użytkownicy przypisani do pakietu' />
                            <DivContainer>
                                <div className='datatable-responsive'>
                                    <CustomDataTable
                                        value={
                                            this.state.users ? this.state.users.sort((a, b) => parseFloat(a.id) - parseFloat(b.id)) : null
                                        }
                                        emptyMessage='Brak rekordów do wyświetlenia'
                                        responsive
                                        paginator
                                        rows={10}
                                    >
                                        <Column field='id' header='ID' sortable='true' sortBy='id' />
                                        <Column field='fullName' header='Użytkownik' />
                                        <Column field='email' header='Email' />
                                        {!this.authService.isUserInAnyRole('ROLE_ADMIN_CONTENT_MANAGE') && (
                                            <Column
                                                field='id'
                                                onClick={this.goToUserProfile.bind(this)}
                                                body={this.goToUserProfile.bind(this)}
                                                header='Profil'
                                            />
                                        )}
                                    </CustomDataTable>
                                </div>
                            </DivContainer>
                            {this.state.dialogVisibile ? (
                                <PackageTypeDialog
                                    className='package-type-dialog'
                                    visible={this.state.dialogVisibile}
                                    key={`package-type-dialog`}
                                    onHide={this.onHide}
                                    blockUi={this.blockUi}
                                    allTypes={this.state.allTypes}
                                    types={this.state.types}
                                    unblockUi={this.unblockUi}
                                    viewMode={this.state.viewMode}
                                    _isMounted={this._isMounted}
                                    handleAddTypes={this.handleAddTypes}
                                    handleEditType={this.handleEditType}
                                    element={this.state.typeDetail}
                                    afterSave={(e) =>
                                        this.handleCloseDialog(e, () => {
                                            this.readMessage();
                                            this.refreshTable();
                                            this.unblockUi();
                                        })
                                    }
                                />
                            ) : null}
                        </>,
                        false
                    )}
                </div>
            </React.Fragment>
        );
    }
}

SubscriptionContainer.defaultProps = {
    backUrl: '/#/subscription-list',
    cancelUrl: '/#/subscription-list',
    cancleEditUrl: '/#/subscription/details',
    editUrl: '/#/subscription/edit',
    userDetailsUrl: '/#/user/details',
    activeElementUrl: '/#/subscription/details',
    detailsUrl: '/#/subscription/details',
    lazy: true,
};

SubscriptionContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    detailsUrl: PropTypes.string,
    editUrl: PropTypes.string,
    userDetailsUrl: PropTypes.string,
};

export default withTranslation()(SubscriptionContainer);
