import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseContainer from '../baseContainers/BaseContainer';
import {ActionLink} from '../components/ActionLink';
import UserService from '../services/UserService';
import AppPrefixUtils from '../utils/AppPrefixUtils';

class CookiesContainer extends BaseContainer {
    constructor(props) {
        super(props, new UserService());
        this.state = {
            loading: true,
        };
    }

    prepareHeaderItems() {
        return [];
    }

    prepareFooterItems() {
        return [];
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    render() {
        return (
            <div className='container'>
                <React.Fragment>
                    <div className='row'>
                        <div className='col-12 cookie-text'>
                            <h5>
                                <center className='mt-4'>
                                    <b>
                                        <u>Polityka cookies</u>
                                    </b>
                                </center>
                            </h5>
                            <br />
                            <div className='container'>
                                Stosownie do treści art. 6 pkt 2 ustawy z dnia 18 lipca 2002r. o świadczeniu usług drogą elektroniczną z
                                późniejszymi zmianami oraz art. 173 ustawy z dnia 16 lipca 2004r. Prawo telekomunikacyjne, administrator
                                Serwisu leksy.pl tj. Katarzyna Podyma prowadząca Kancelarię Radcy Prawnego Katarzyna Podyma, ze stałym
                                miejscem wykonywania działalności gospodarczej w Katowicach (40-078), pl. Wolności, nr 3, lok. 3A, NIP:
                                6291667024 (zwana dalej Usługodawcą), informuje niniejszym o funkcji i celu oprogramowania lub danych
                                niebędących składnikiem treści usługi, wprowadzanych przez Usługodawcę do systemu teleinformatycznego,
                                którym posługuje się Użytkownik.
                            </div>
                            <br />
                            <ol>
                                <p className='p-2'>
                                    <li className='container'>
                                        <p>
                                            <b>
                                                <u>Czym są cookies?</u>{' '}
                                            </b>
                                        </p>
                                        <p>
                                            Serwis wykorzystuje pliki cookies (ciasteczka), tj. małe pliki tekstowe, które strona
                                            internetowa wysyła do przeglądarki z której korzysta Użytkownik podczas korzystania z tej strony
                                            oraz które przeglądarka Użytkownika wysyła z powrotem przy wejściach na stronę internetową.
                                            Pliki cookies przechowywane są w pamięci trwałej (np. dysku) urządzenia (tj. komputer, laptop,
                                            tablet, smartfon) Użytkownika korzystającego ze strony internetowej. Pliki cookies stanowią
                                            prawnie dopuszczalne i użyteczne narzędzie, służące np. utrzymaniu połączenia Użytkownika ze
                                            stroną internetową, zapamiętywaniu dokonanych przez Użytkownika czynności np. zakupowych,
                                            analizowaniu skuteczności projektu strony internetowej i reklamy oraz w sprawdzaniu tożsamości
                                            Użytkowników prowadzących transakcje w systemie on-line.
                                        </p>
                                    </li>
                                </p>
                                <p className='p-2'>
                                    <li className='container'>
                                        <p>
                                            <b>
                                                <u>W jaki sposób Serwis wykorzystuje pliki cookies?</u>{' '}
                                            </b>
                                        </p>
                                        <p>
                                            Serwis wykorzystuje pliki cookies (ciasteczka), tj. małe pliki tekstowe, które strona
                                            internetowa wysyła do przeglądarki z której korzysta Użytkownik podczas korzystania z tej strony
                                            oraz które przeglądarka Użytkownika wysyła z powrotem przy wejściach na stronę internetową.
                                            Pliki cookies przechowywane są w pamięci trwałej (np. dysku) urządzenia (tj. komputer, laptop,
                                            tablet, smartfon) Użytkownika korzystającego ze strony internetowej. Pliki cookies stanowią
                                            prawnie dopuszczalne i użyteczne narzędzie, służące np. utrzymaniu połączenia Użytkownika ze
                                            stroną internetową, zapamiętywaniu dokonanych przez Użytkownika czynności np. zakupowych,
                                            analizowaniu skuteczności projektu strony internetowej i reklamy oraz w sprawdzaniu tożsamości
                                            Użytkowników prowadzących transakcje w systemie on-line.
                                        </p>
                                    </li>
                                </p>
                                <p className='p-2'>
                                    <li className='container'>
                                        <p>
                                            <b>
                                                <u>
                                                    W jaki sposób Użytkownik Serwisu może kontrolować korzystanie z plików cookies przez
                                                    Serwis?{' '}
                                                </u>
                                            </b>
                                        </p>
                                        <p>
                                            Warunkiem działania cookies jest ich akceptacja w przeglądarce poprzez wprowadzenie odpowiednich
                                            ustawień przeglądarki oraz ich nieusuwanie. Powszechnie używane przeglądarki internetowe
                                            (Chrome, Firefox, Samsung Internet, Opera, Safari, Android, Internet Explorer, Microsoft Edge)
                                            pozwalają na włączenie bądź wyłączenie mechanizmu cookies, przy czym domyślnie zazwyczaj jest on
                                            włączony. Użytkownik może w każdym czasie ograniczyć lub wyłączyć możliwość przetwarzania plików
                                            cookies w ramach swojego urządzenia poprzez zmianę ustawień przeglądarki internetowej, z której
                                            korzysta. Brak wyłączenia lub ograniczenia możliwości przechowywania oraz udostępniania
                                            zawartości plików cookies oznacza wyrażenie przez Użytkownika zgody na takie działania.
                                            Instrukcja w jaki sposób wyłączyć lub ograniczyć przetwarzanie plików cookies w ramach danego
                                            typu przeglądarki, dostępna jest powszechnie w Internecie m.in.:
                                            <ul>
                                                <li className='m-2'>
                                                    - dla Firefox: https://support.mozilla.org/pl/kb/blokowanie-ciasteczek{' '}
                                                </li>
                                                <li className='m-2'>
                                                    - dla Chrome:
                                                    https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pl
                                                </li>
                                                <li className='m-2'>- dla Opery: https://help.opera.com/pl/latest/web-preferences/</li>
                                            </ul>
                                            <span className='mt-4'>
                                                W powyższym zakresie informujemy jednakże, że ograniczenie stosowania określonych plików
                                                cookies, może wpłynąć na niektóre funkcjonalności Serwisu.
                                            </span>
                                        </p>
                                    </li>
                                </p>
                            </ol>
                            <ActionLink
                                label={'Wróć'}
                                href={AppPrefixUtils.locationHrefUrl('/#/')}
                                variant='blue'
                                className='p-link header-link'
                                key={'contact-button'}
                            />
                        </div>
                    </div>
                </React.Fragment>
            </div>
        );
    }
}

CookiesContainer.defaultProps = {
    viewMode: 'EDIT',
};

CookiesContainer.propTypes = {
    viewMode: PropTypes.string.isRequired,
};

export default withTranslation()(CookiesContainer);
