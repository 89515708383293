import PropTypes from 'prop-types';
import React from 'react';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import Constants from '../../utils/constants';
import ApprovalContentService from '../../services/ApprovalContentService';
import YesNoDialog from '../../components/YesNoDialog';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
import InputFileUploadComponent from '../../components/inputs/InputFileUploadComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import {withTranslation} from 'react-i18next';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import DialogResizer from '../../components/DialogResizer';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';

class ApprovalContentContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new ApprovalContentService());
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.state = {
            loading: false,
            elementId: this.props.elementId,
            element: {
                content: undefined,
                required: undefined,
                order: undefined,
                attachments: [],
            },
            booleanOptions: Constants.BOOLEAN_OPTIONS,
        };
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
    }

    getContainerListName() {
        return 'approval-content-list';
    }

    getUpdateSucces() {
        return 'Edytowano zgodę';
    }

    getRemoveSucces() {
        return 'Usunięto zgodę';
    }

    prepareFooterItems() {
        const {cancelUrl, viewMode} = this.props;
        const {elementId} = this.props;
        let goBackUrl = `${cancelUrl}/${elementId}`;

        return [
            {
                label: 'Anuluj',
                className: 'cancel-button gray',
                href: AppPrefixUtils.locationHrefUrl(goBackUrl),
                rendered: viewMode === 'NEW' || viewMode === 'EDIT',
            },

            {
                label: 'Zapisz',
                className: 'float-right',
                onClick: this.handleFormSubmit,
                rendered: viewMode === 'NEW' || viewMode === 'EDIT',
            },
        ];
    }
    showDeleteDialog() {
        this.setState({
            dialogVisibile: true,
        });
    }

    hideDeleteDialog() {
        this.setState({
            dialogVisibile: false,
        });
    }

    prepareHeaderItems() {
        const {viewMode} = this.props;
        return [
            {
                label: 'Usuń',
                variant: 'button cancel-button gray',
                onClick: this.showDeleteDialog,
                rendered: viewMode === 'VIEW',
            },
            this.prepareEditButton(viewMode === 'VIEW'),
        ];
    }

    getBackLabel() {
        return 'Wróć do listy zgód';
    }
    breadCrumbItems() {
        return [
            {name: 'Zgody', url: '/#/approval-content-list'},
            {name: 'Zgoda', url: `/#/approval-content/details/${this.state.element?.id}`},
        ];
    }
    render() {
        return (
            <React.Fragment>
                <YesNoDialog
                    onChange={(type, x, target) => {
                        if (target.value) {
                            this.onRemoveAction(type, x, target);
                            this.hideDeleteDialog();
                        } else {
                            this.hideDeleteDialog();
                        }
                    }}
                    visible={this.state.dialogVisibile}
                    header='Usuwanie zgód'
                    name='visible'
                    onHide={() => {
                        this.setState({dialogVisibile: false});
                        DialogResizer.removeMaskIfExistOneDialog();
                    }}
                >
                    Czy na pewno chcesz usunąć wybraną zgodę?
                </YesNoDialog>
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                {this.renderView()}
            </React.Fragment>
        );
    }

    renderDetails() {
        return (
            <React.Fragment>
                {this.renderProperStructureForDetail(
                    <>
                        <div class='row'>
                            <InputTextareaComponent
                                id='content'
                                name='content'
                                label={'Treść'}
                                colClass='col-md-12'
                                value={this.state.element.content}
                                validator={this.validator}
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                                validators={'required|max:500'}
                            />

                            <InputNumberComponent
                                id='order'
                                name='order'
                                label={'Kolejność'}
                                colClass='col-md-6'
                                min={0}
                                value={this.state.element.order}
                                validator={this.validator}
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                                validators={'naturalNumber'}
                            />

                            <InputTextComponent
                                id='requiredText'
                                name='requiredText'
                                dataKey='label'
                                label={'Obowiązkowa'}
                                colClass='col-md-6'
                                value={this.state.element.required === true ? 'Tak' : 'Nie'}
                                validator={this.validator}
                                onChange={this.handleChangeOpt}
                                viewMode={this.props.viewMode}
                                rendered={this.props.viewMode === 'VIEW'}
                                validators={'required'}
                                optionLabel='label'
                            />

                            <InputDropdownComponent
                                id='required'
                                name='required'
                                dataKey='label'
                                label={'Obowiązkowa'}
                                optionValue={'value'}
                                enumMode={true}
                                optionLabel='label'
                                colClass='col-md-6'
                                value={this.state.element.required}
                                validator={this.validator}
                                onChange={() =>
                                    this.setState((prevState) => ({
                                        element: {
                                            ...prevState.element,
                                            required: !this.state.element.required,
                                        },
                                    }))
                                }
                                viewMode={this.props.viewMode}
                                rendered={this.props.viewMode === 'EDIT'}
                                validators={'required'}
                                options={this.state.booleanOptions}
                            />

                            <InputFileUploadComponent
                                messages={this.messages}
                                id='attachments'
                                name='attachments'
                                label={'Pliki'}
                                fileList={this.state.element.attachments}
                                showLabel
                                value={this.state.element.attachments}
                                restApiUrl={`${this.service.getPath()}/${this.state.element.id}/attachments`}
                                multiple
                                maxFileSize={50000000}
                                onChange={this.handleChange}
                                itemLabel='fileName'
                                itemName='uuid'
                                colClass='col-xl-12'
                                viewMode={this.props.viewMode}
                                validator={this.validator}
                                validators='not_required'
                                style={{width: '100%'}}
                                restrictExt={true}
                                accept='.pdf,.jpg'
                                handleError={this.onUploladError}
                                token={this.authService.getToken()}
                                validateViewMode
                                maxFileCount={1}
                                overrideFileNotAcceptedMessage='Nieprawidłowy format pliku. Wspierane formaty: .pdf, .jpg'
                            />
                        </div>
                    </>
                )}
            </React.Fragment>
        );
    }
}

ApprovalContentContainer.defaultProps = {
    backUrl: '/#/approval-content-list',
    cancelUrl: '/#/approval-content/details',
    editUrl: '/#/approval-content/edit',
};

ApprovalContentContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withTranslation()(ApprovalContentContainer);
