export default class TemplateModel {
    constructor() {
        this.id = undefined;
        this.version = 0;
        this.user = undefined;
        this.status= undefined;
        this.email= undefined;
        this.title= ''
        this.fullName= undefined
    }

    static assign(from, to) {
        if (from) {
            to.id = from.id;
            to.start =from.start;
            to.version = from.version;
            to.user = from.user;
            to.fullName = from.fullName;
            to.email = from.email;
            to.status= from.status
            to.title= from.title;
        } else {
            to = new TemplateModel();
        }
    }

    static copy(from) {
        const to = new TemplateModel();
        TemplateModel.assign(from, to);
        return to;
    }
}
