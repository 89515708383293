import SessionUtils from './SessionUtils';

class SunEditorParentWidthCounter {
    static countWidthWhenResize(callback, sidebarVisible, otherMargins, device) {
        window.addEventListener(
            'resize',
            () => {
                const currentWidth = SunEditorParentWidthCounter.countWidth(sidebarVisible, otherMargins, device);
                callback(currentWidth);
            },
            false
        );
    }

    static countWidth(sidebarVisible, otherMargins, device) {
        setTimeout(() => {
            const pintcuts = SunEditorParentWidthCounter.calculatePoincuts(device);
            const windowWidth =
                device?.type && (device.type === 'mobile' || device.type === 'tablet') ? window.outerWidth : window.innerWidth;
            const sidebarWidth = windowWidth >= 992 && sidebarVisible ? pintcuts.pointcutSidebar : pintcuts.pointcut;
            const result = windowWidth - sidebarWidth - otherMargins;
            return result;
        }, 1);
    }
    static isThin(sidebar) {
        return sidebar.clientWidth === 85;
    }
    static isCallapsed(sidebar) {
        if (sidebar) {
            return this.isThin(sidebar) || sidebar.style.display === 'none';
        } else return false;
    }

    static calculatePoincuts(device) {
        let pointcutSidebar = 380;
        if (window.location.href.includes('agreement')) {
            const expander = document.getElementById('expander-icon-expand');
            const stepIndex = SessionUtils.readSession('current-index-step');
            if (stepIndex > 0 && expander === null) {
                pointcutSidebar = 400;
            } else {
                // TODO: na razie tak samo
                pointcutSidebar = 400;
            }
        }
        if (device.type !== 'mobile' || device.type !== 'tablet') {
            const sidebar = document.getElementsByClassName('pro-sidebar')[0];
            if (this.isCallapsed(sidebar)) {
                if (this.isThin(sidebar)) {
                    pointcutSidebar = 85;
                } else {
                    pointcutSidebar = 1;
                }
            }
        }
        const thinScrollbar = device?.type && (device.type === 'mobile' || device.type === 'tablet');
        let pointcut = 0;
        const body = document.body;
        if (body.scrollHeight > body.clientHeight) {
            // zmienia sie na ostatnim stepie
            pointcutSidebar = pointcutSidebar + 25;
            pointcut = !!thinScrollbar ? 0 : 25;
        }
        return {pointcut, pointcutSidebar};
    }
}
export default SunEditorParentWidthCounter;
