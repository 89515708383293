import React from 'react';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import DivContainer from '../../components/DivContainer';
import {ActionButton} from '../../components/ActionButton';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InternalUserService from '../../services/InternalUserService';
import SpecializationService from '../../services/SpecializationService';
import UserModel from '../../model/UserModel';
import UserRoleService from '../../services/UserRoleService';
import SubscriptionService from '../../services/SubscriptionService';
import PurchasedService from '../../services/PurchasedService';
import PackageContainer from '../PackagesContainer';
import InputSelectButtonComponent from '../../components/inputs/InputSelectButtonComponent';
import UserService from '../../services/UserService';
import {CustomMessages} from '../../components/CustomMessages';
import InputAddressComponent from '../../components/inputs/ePrawnik/InputAddressComponent';
import DialogResizer from '../../components/DialogResizer';

class UserDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new InternalUserService());

        this.options = ['Osoba', 'Firma'];
        this.state = {
            option: 'Osoba',
            element: new UserModel(),
            viewMode: this.props.viewMode,
            activeIndex: 0,
            fakeStatus: 'ACTIVE',
            selectedPackagesMap: new Map(),
            billingCycleMap: new Map(),
            backStep: false,
            packages: [],
            personOptions: [
                {
                    label: 'Osoba',
                    enumValue: 'PERSON',
                },
                {
                    label: 'Firma',
                    enumValue: 'COMPANY',
                },
            ],
        };
        this.purchasedService = new PurchasedService();
        this.userRoleService = new UserRoleService();
        this.userService = new UserService();
        this.subscriptionService = new SubscriptionService();
        this.specializationService = new SpecializationService();
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    shouldBeFooterDisabled(nextButtonLexdocs) {
        const name = this.state.element?.role?.name;
        if (!nextButtonLexdocs) {
            if (name !== 'Leksy') {
                return true;
            }
        }
        return false;
    }
    renderFooter() {
        const {t} = this.props;
        const name = this.state.element?.role?.name;
        const person = this.state.element?.person?.enumValue;
        var indexToCompare = person === 'PERSON' ? 1 : 2;

        const backStepBtn =
            (this.state.activeIndex === 1 && person !== 'PERSON' && name !== 'LexDocS') ||
            (this.state.activeIndex === 1 && person === 'COMPANY' && name === 'LexDocS');
        const cancelButton = this.state.activeIndex === 0;
        const addButton =
            (this.state.activeIndex === 0 && name === 'Leksy' && !this.isThereSecondStep()) ||
            (this.state.activeIndex === 1 && name === 'Leksy');

        const nextButton = !(this.state.activeIndex === 1) && name === 'Leksy' && this.isThereSecondStep();

        const nextButtonLexdocs =
            !(this.state.activeIndex === indexToCompare) && (this.isThereSecondStep() || name === undefined) && name !== 'Leksy';
        if (this.shouldBeFooterDisabled(nextButtonLexdocs)) {
            return null;
        }
        return (
            <React.Fragment>
                {backStepBtn || cancelButton || addButton || nextButton || nextButtonLexdocs ? <hr className='line-footer' /> : null}
                <div className='footer-dialog-button-container'>
                    <ActionButton
                        buttonType={'PREV'}
                        label={t('global.backStepBtn')}
                        variant={'button cancel-button gray'}
                        handleClick={this.handleChangeStep.bind(this, {index: this.state.activeIndex}, true)}
                        rendered={backStepBtn}
                    />
                    <ActionButton
                        label={this.state.viewMode === 'NEW' ? t('user.addButton') : t('details.saveChanges')}
                        variant='button save-button blue'
                        handleClick={this.handleFormSubmit}
                        rendered={addButton}
                        buttonType={'NEXT'}
                    />
                    <ActionButton
                        label={t('details.next')}
                        variant='button save-button blue'
                        handleClick={this.handleChangeStep.bind(this, {index: this.state.activeIndex}, false)}
                        rendered={nextButton}
                        buttonType={'NEXT'}
                    />
                    <ActionButton
                        label={t('details.next')}
                        variant='button save-button blue'
                        handleClick={this.handleChangeStep.bind(this, {index: this.state.activeIndex})}
                        rendered={nextButtonLexdocs}
                        buttonType={'NEXT'}
                    />
                </div>
            </React.Fragment>
        );
    }

    isThereSecondStep() {
        const {element} = this.state;
        return element?.role?.name === 'LexDocS' || (element.person?.enumValue === 'COMPANY' && element?.role?.name === 'Leksy');
    }

    renderHeader() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>Nowy użytkownik</label>
                </DivContainer>
            </DivContainer>
        );
    }
    getContainerListName() {
        return 'user-list-container';
    }

    getAddSucces() {
        const {t} = this.props;
        return t('user.addUserSuccess');
    }

    handleValidForm(e, selectedPackagesMap) {
        const {element} = this.state;

        if (this.state.element?.role?.name === 'LEKSY') {
            element.portal = 'LEKSY';
        }

        if (selectedPackagesMap?.size === 0) {
            this.showErrorMessage('Nie wybrano pakietu');
            return;
        }

        let userModel = UserModel.copy(element);
        if (this.state.element.person.enumValue !== 'COMPANY') {
            userModel.address = undefined;
        }

        this.scrollToTop();
        // TODO: metoda restowa w metodzie restowej jakiś cos w backendzie trza poprawic
        if (this.props._isMounted) {
            DialogResizer.removeMaskIfExistOneDialog();
            this.service
                .add(userModel)
                .then((user) => {
                    if (userModel.role.name === 'LexDocS') {
                        let arrayOfTypes = [];
                        [...selectedPackagesMap].map(([id, value]) => arrayOfTypes.push(value.type));
                        this.purchasedService
                            .changeUserSubscriptions(arrayOfTypes, user.id)
                            .then(() => {
                                this.persistMessage('success', '', 'Utworzono użytkownika oraz wysłano link do ustanowienia hasła');
                                if (this.props.afterSave) {
                                    this.props.afterSave();
                                }
                            })
                            .catch((err) => {
                                this.persistMessage('error', '', err.message);
                                if (this.props.afterSave) {
                                    this.props.afterSave();
                                }
                            });
                    } else {
                        this.persistMessage('success', '', 'Utworzono użytkownika oraz wysłano link do ustanowienia hasła');
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                    }
                })
                .catch((err) => {
                    this.persistMessage('error', '', err.message);
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                });
        }
        this.unblockUi();
    }

    handleChangeStep(e, backStep) {
        if (backStep === true) {
            e.index--;
            this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
        } else {
            if (this.validator.allValid()) {
                if (e.index === 0) {
                    this.userService
                        .isEmailExist(this.state.element.email)
                        .then((emailExist) => {
                            if (emailExist) {
                                this.showErrorMessage('Podany email istnieje w systemie');
                                return;
                            } else {
                                if (e.index === 0 && this.state.packages.length === 0) {
                                    this.showErrorMessage('Aby utworzyć użytkownika lexdocs wymagane jest stworzenie pakietu');
                                    return;
                                } else {
                                    e.index++;
                                    this.validator.hideMessages();
                                    this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
                                }
                            }
                            this.unblockUi();
                        })
                        .catch(() => {
                            this.showErrorMessage('Problem z pobieraniem informacji o mailach');
                            return;
                        });
                } else {
                    e.index++;
                    this.props.blockUi(this.setState({activeIndex: e.index}, () => this.props.unblockUi()));
                }
            } else {
                this.validator.showMessages();
                // this.scrollToError = true;
                this.forceUpdate();
            }
        }
    }

    initBeforeSetElement() {
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                person: this.state.personOptions[0],
            },
        }));

        this.subscriptionService
            .getAllAvailableActiveSubs()
            .then((packages) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                    },
                    packages: packages,
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message, 10000, true, 'Błąd przy pobieraniu pakietów ogólnych');
            });
    }

    renderDetails() {
        const {element} = this.state;
        return this.isThereSecondStep() ? (
            <div>
                {this.state.activeIndex === 0 ? this.renderFirstStep() : null}
                {this.state.activeIndex === 1 &&
                (element.role.name === 'Leksy' || (element.role.name === 'LexDocS' && element.person.enumValue === 'COMPANY'))
                    ? this.renderSecondStep()
                    : null}
                <div className='w-100  justify-content-center'>
                    {this.state.activeIndex === 2 && element.role.name === 'LexDocS' && element.person.enumValue === 'COMPANY'
                        ? this.renderThirdStep()
                        : null}
                </div>
                <div className='w-100  justify-content-center'>
                    {this.state.activeIndex === 1 && element.role.name === 'LexDocS' && element.person.enumValue !== 'COMPANY'
                        ? this.renderThirdStep()
                        : null}
                </div>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)}></CustomMessages>
            </div>
        ) : (
            this.renderFirstStep()
        );
    }

    renderSecondStep() {
        const {element} = this.state;
        if (this.state.element.person.enumValue !== 'PERSON') {
            return (
                <React.Fragment>
                    <DivContainer colClass='row'>
                        <InputTextComponent
                            id='nip'
                            placeholder='Wprowadź numer NIP'
                            name='nip'
                            label='Numer NIP'
                            value={element.nip}
                            colClass='col-md-4'
                            onChange={this.handleChange}
                            validators={`required|nip`}
                            validator={this.validator}
                            viewMode={this.props.viewMode}
                        />
                        <InputAddressComponent
                            element={this.state.element}
                            validator={this.validator}
                            rendered={true}
                            handleChange={this.handleChange}
                            stateField={'element.address'}
                            viewMode={this.props.viewMode}
                        />
                    </DivContainer>
                </React.Fragment>
            );
        }
    }

    renderThirdStep() {
        return this.state.packages.length ? (
            <PackageContainer
                colClass='col-12'
                handleChangePackage={this.handleValidForm}
                handleTranslate={this.translate}
                viewMode={'EDIT'}
                style={{minWidth: '12em'}}
                packages={this.state.packages}
                showHeader={true}
                handleChangeStep={this.handleChangeStep}
                service={new SubscriptionService()}
                showTemplates={true}
                paddingForDetailsAndTemplateBtn={'p-2'}
                startupCol='col-12'
                // TODO: TEN FOOTER W DIALOGU TROCHE PAŹDZIERZ :(
                footerItems={[
                    {
                        label: 'Wstecz',
                        variant: 'button cancel-button gray',
                        onClick: this.handleChangeStep.bind(this, {index: this.state.activeIndex}, true),
                    },
                    {
                        label: 'Dodaj użytkownika',
                        className: 'float-right',
                    },
                ]}
            />
        ) : null;
    }

    renderFirstStep() {
        const {person} = this.state.element;
        return (
            <React.Fragment>
                <div className='row'>
                    <InputSelectButtonComponent
                        id='person'
                        name='person'
                        colClass='col-md-6'
                        value={this.state.element.person}
                        options={this.state.personOptions}
                        // stateField={"enumValue"}
                        optionLabel='label'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validator={this.props.validator}
                        validators='required'
                        validateViewMode
                    />
                    <InputTextComponent
                        id='email'
                        placeholder='Wpisz email'
                        name='email'
                        label={'Email'}
                        colClass='col-md-12'
                        value={this.state.element.email}
                        validator={this.validator}
                        validators='required|email|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='firstName'
                        placeholder='Wpisz imię'
                        name='firstName'
                        label={'Imię'}
                        rendered={person?.enumValue === 'PERSON'}
                        colClass='col-md-6'
                        value={this.state.element.firstName}
                        validator={this.validator}
                        validators='required|alpha_space_dash|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='lastName'
                        placeholder='Wpisz nazwisko'
                        name='lastName'
                        label={'Nazwisko'}
                        colClass='col-md-6'
                        rendered={person?.enumValue === 'PERSON'}
                        value={this.state.element.lastName}
                        validator={this.validator}
                        validators='required|alpha_space_dash|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />

                    <InputTextComponent
                        id='companyName'
                        placeholder='Wpisz nazwę firmy'
                        name='companyName'
                        label='Nazwa firmy'
                        rendered={person?.enumValue === 'COMPANY'}
                        value={this.state.element.companyName}
                        onChange={this.handleChange}
                        colClass='col-md-6'
                        validators='required|max:50'
                        validator={this.validator}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='industry'
                        name='industry'
                        placeholder='Wpisz branżę'
                        label='Branża '
                        rendered={person?.enumValue === 'COMPANY'}
                        value={this.state.element.industry}
                        onChange={this.handleChange}
                        colClass='col-md-6'
                        validators='required|alpha|max:50'
                        validator={this.validator}
                        viewMode={this.props.viewMode}
                    />

                    <InputDropdownComponent
                        id='role'
                        name='role'
                        label={'Rola'}
                        colClass='col-md-12'
                        value={this.state.element.role}
                        validator={this.validator}
                        options={this.props.roleOptions}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        optionLabel='name'
                        dataKey='id'
                        validators='required'
                        valueView={this.state.element.role ? this.state.element.role.name : ''}
                        validateViewMode
                    />
                </div>
            </React.Fragment>
        );
    }
}

UserDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
    changePackageUrl: '/#/change-package',
};

UserDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    changePackageUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
};

export default withTranslation()(UserDialog);
