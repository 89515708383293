import moment from 'moment';
import 'moment-duration-format';
import {OpenVidu} from 'openvidu-browser';
import {Card} from 'primereact/card';
import PropTypes from 'prop-types';
import React from 'react';
import {v4 as uuidv4} from 'uuid';
import Draggable from 'react-draggable';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'moment-duration-format';
import {Rating} from 'primereact/rating';
import BaseContainer from '../../baseContainers/BaseContainer';
import ActionButton from '../../components/ActionButton';
import {CustomMessages} from '../../components/CustomMessages';
import BlockUi from '../../components/waitPanel/BlockUi';
import AuthService from '../../services/AuthService';
import ChatService, {ConsultationType, EventType, MessageType} from '../../services/ChatService';
import ConsultationHelper from '../../services/ConsultationHelper';
import Chat from './Chat';
import ChatNotification, {ChatNotificationType} from './ChatNotification';
import Video from './Video';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import ScheduledConsultationService from '../../services/ScheduledConsultationService';
import ScheduledConsultationPublicService from '../../services/public/ScheduledConsultationService';
import YesNoDialog from '../../components/YesNoDialog';
import DivContainer from '../../components/DivContainer';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import {withTranslation} from 'react-i18next';
import PrimiDialog from '../../components/PrimiDialog';
import DialogResizer from '../../components/DialogResizer';

export const ChatType = {
    NONE: 'none',
    CLIENT: 'client',
    CONSULTANT: 'consultant',
};

export const ReminerType = {
    FIFTEEN_MINUTES: 'FIFTEEN_MINUTES',
    TWENTY_MINUTES: 'TWENTY_MINUTES',
};

class ChatContainer extends BaseContainer {
    intervalId = undefined;

    constructor(props) {
        super(props, new ChatService());
        this.jwtRefreshBlocked = true;
        this.startChatWindow = this.startChatWindow.bind(this);
        this.handleSendMessage = this.handleSendMessage.bind(this);
        this.handleCloseAction = this.handleCloseAction.bind(this);
        this.handleReminderAction = this.handleReminderAction.bind(this);
        this.showReminderDialog = this.showReminderDialog.bind(this);
        this.showEndConversationDialog = this.showEndConversationDialog.bind(this);
        this.closeEndConversationDialog = this.closeEndConversationDialog.bind(this);
        this.showStartConversationDialog = this.showStartConversationDialog.bind(this);
        this.closeStartConversationDialog = this.closeStartConversationDialog.bind(this);
        this.renderReminderDialog = this.renderReminderDialog.bind(this);
        this.checkClickPostition = this.handleNavbarItemClick.bind(this);
        this.closeNoConsultantDialog = this.closeNoConsultantDialog.bind(this);
        this.showNoConsultantDialog = this.showNoConsultantDialog.bind(this);
        this.showFeedbackDialog = this.showFeedbackDialog.bind(this);
        this.backToTemplate = this.backToTemplate.bind(this);
        this.closeNotifies = this.closeNotifies.bind(this);
        this.redirectAfterEstablished = this.redirectAfterEstablished.bind(this);
        this.renderNoConsultantDialog = this.renderNoConsultantDialog.bind(this);
        this.closePaymentSummaryDialog = this.closePaymentSummaryDialog.bind(this);
        this.showPaymentSummaryDialog = this.showPaymentSummaryDialog.bind(this);
        this.renderConsultationReservedConfirmationDialog = this.renderConsultationReservedConfirmationDialog.bind(this);
        this.renderPaymentSummaryDialog = this.renderPaymentSummaryDialog.bind(this);
        this.renderStartConversationDialog = this.renderStartConversationDialog.bind(this);
        this.pay = this.pay.bind(this);
        this.changePublisherVideoState = this.changePublisherVideoState.bind(this);
        this.changePublisherAudioState = this.changePublisherAudioState.bind(this);
        this.closeVideoChatWindow = this.closeVideoChatWindow.bind(this);
        this.cleanupVideoSession = this.cleanupVideoSession.bind(this);

        this.authService = new AuthService(this.domain);
        this.scheduledConsultationPublicService = new ScheduledConsultationPublicService();

        this.messagesHistory = {};

        //const documentElement = document.documentElement;
        //const wrapperHeight = window.innerHeight; // || documentElement.clientHeight;
        const wrapperWidth = window.innerWidth; //|| documentElement.clientWidth;
        this.chatService = new ChatService();
        this.consultationHelper = new ConsultationHelper();
        this.chatType = ChatType.NONE;
        this.defaultPosition = {
            y: 91,
            x: wrapperWidth > 435 ? wrapperWidth - 435 : 0,
        };
        this.defaultVideoPosition = {
            y: 91,
            x: wrapperWidth > 820 ? wrapperWidth - 820 : 0,
        };

        this.videoResolution = {
            width: 640,
            heigth: 480,
        };
        this.positions = {};
        this.positionsStart = {};
        this.state = {
            loading: false,
            message: undefined,
            viewMode: 'SHOW_MESSAGE',
            visibleNoConsultantDialog: false,
            visibleEndConversationDialog: false,
            visibleStartConversationDialog: false,
            visiblePaymentSummaryDialog: false,
            consultationReservedConfirmation: false,
            visiblePaymentStatusDialog: false,
            visibleReservedConfirmationDialog: false,
            visibleFeedbackDialog: false,
            visibleReminderDialog: false,
            isReservationDialog: false,
            isPaid: false,
            paid: false,
            paymentRequired: false,
            incommingCallDialogTimeout: 60,
            tickerTimeOut: 30,
            reservedSummary: undefined,
            feedback: {
                sentByConsultant: undefined,
                scheduledConsultationId: undefined,
                rating: undefined,
                message: undefined,
            },
            activeNotifications: [],
            chatWindows: [],
            messages: {},
            element: {},
            reminder: {},
            consultationSummary: {},
            videoWindow: {connected: false},
        };
    }

    //override
    componentDidMount() {
        super.componentDidMount();
        this.initBeforeSetElement();
        moment.locale('pl');
    }

    setTimeoutSummaryPaymentDialog(secondTimeOut) {
        let tickerTimeOut = new Date();
        tickerTimeOut.setSeconds(tickerTimeOut.getSeconds() + secondTimeOut);
        this.setState({
            tickerTimeOut: tickerTimeOut,
        });
    }

    componentWillUnmount() {
        this.socketDisconnect();
        super.componentWillUnmount();
        clearInterval(this.intervalId);
    }

    changePublisherVideoState(on) {
        const videoWindow = this.state.videoWindow;
        videoWindow.publisher.publishVideo(on);
        this.setState((prevState) => ({
            videoWindow: {
                ...prevState.videoWindow,
                publisherVideoOn: on,
            },
        }));
    }

    changePublisherAudioState(on) {
        const videoWindow = this.state.videoWindow;
        videoWindow.publisher.publishAudio(on);
        this.setState((prevState) => ({
            videoWindow: {
                ...prevState.videoWindow,
                publisherAudioOn: on,
            },
        }));
    }

    cleanupVideoSession() {
        const videoWindow = this.state.videoWindow;
        if (videoWindow.videoSession) {
            videoWindow.videoSession.disconnect();
            console.log('#### video session disconnected!');
        }
        this.setState({
            videoWindow: {connected: false},
        });
    }

    closeVideoChatWindow() {
        const videoWindow = this.state.videoWindow;
        if (videoWindow.conversationId) {
            this.chatService.closeWindow(videoWindow.conversationId);
        }
        this.cleanupVideoSession();
    }

    //override
    initBeforeSetElement() {
        const currentUser = this.authService.getProfile();
        const chatAccessClientRoles = ['ROLE_USER_LEKSY_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE'];
        const chatAccessConsultantRoles = ['ROLE_LAW_OFFICE_CONSULTANT_MANAGE'];

        const client =
            currentUser && currentUser.role && currentUser.role.filter((r) => chatAccessClientRoles.includes(r.authority)).length > 0;
        const consultant =
            currentUser && currentUser.role && currentUser.role.filter((r) => chatAccessConsultantRoles.includes(r.authority)).length > 0;
        if (!this.authService.loggedIn() || client || consultant) {
            const urlStr = window.location.toString().replace('/#', '');
            const url = new URL(urlStr);
            const fromPaymentSystem = url.searchParams.get('fromPaymentSystem');
            const conversationId = url.searchParams.get('conversationId');
            const consultantAcceptanceRequired = url.searchParams.get('consultantAcceptanceRequired');
            if (fromPaymentSystem === 'true') {
                if (consultantAcceptanceRequired === 'false') {
                    this.scheduledConsultationPublicService
                        .getScheduledEventsByClientId(this.authService.getUserId())
                        .then((sc) => {
                            this.setState({
                                reservedSummary: sc,
                                visibleReservedConfirmationDialog: true,
                            });
                        })
                        .catch(() => {
                            this.setState({
                                visibleReservedConfirmationDialog: true,
                            });
                        });
                    // this.showSuccessMessage('Konsultacja została zaplanowana, sprawdź zaplanowane konsultacje');
                } else {
                    this.showSuccessMessage('Konsultacja za chwilę się rozpocznie, proszę pozostać w systemie');
                }

                try {
                    let url = window.location.href.toString();
                    const splitUrl = url.split('?')[0];
                    window.history.pushState({}, document.title, splitUrl);
                } catch (ex) {}
                // lekkie opoznienie zebyz atrybilo
                setTimeout(() => {
                    const chatWindows = this.state.chatWindows;
                    if (chatWindows.length === 0) {
                        this.acceptByClient(conversationId, consultantAcceptanceRequired);
                    }
                }, 1000);
            }

            this.chatType = client || !this.authService.loggedIn() ? ChatType.CLIENT : ChatType.CONSULTANT;
            this.chatService.addEventListener((type, message) => {
                console.log('chatServiceEvent: type=' + type, message);
                switch (type) {
                    case EventType.REQUEST_START:
                        if (message.type === MessageType.CHECK_CONSULTANT_AVAILABILITY) {
                            this.blockUi(null, 'Oczekiwanie na konsultanta...');
                        } else if (message.type === MessageType.FETCH_MESSAGES) {
                            //this.blockUi(null);
                        }
                        break;
                    case EventType.ERROR:
                        this.showErrorMessage(message);
                        break;
                    case EventType.USER_LOGGED:
                        if (this.props.allowPublicUser === true) {
                            this.socketDisconnect();
                            this.socketConnect();
                        }
                        break;
                    case EventType.USER_LOGOUT:
                        this.socketDisconnect();
                        break;
                    case EventType.MESSAGE:
                        switch (message.type) {
                            case MessageType.CLIENT_SETTINGS:
                                break;
                            case MessageType.CONSULTANT_SETTINGS:
                                this.setState({
                                    incommingCallDialogTimeout: message.incommingCallDialogTimeout,
                                    paymentTimeout: message.paymentTimeout,
                                });
                                break;
                            case MessageType.CONSULTANT_AVAILABILITY_STATUS:
                                this.unblockUi();
                                if (message.acceptedByConsultant === false) {
                                    this.showNoConsultantDialog();
                                } else if (message.paymentRequired === true) {
                                    this.setTimeoutSummaryPaymentDialog(this.state.tickerTimeOut);
                                    this.showPaymentSummaryDialog(message.conversationId, message.consultantName, message.paymentLink);
                                } else if (message.paymentRequired === false) {
                                    //odpowiedź po czasie, bo transakcja po stronie backendu się czasem nie commit'uje na czas i np nie ma w db ScheduledConsultation!
                                    setTimeout(() => this.acceptByClient(message.conversationId, true), 1000);
                                }
                                break;
                            case MessageType.INCOMING_CONSULTATION:
                                this.incommingConsultation(
                                    message.conversationId,
                                    message.paid,
                                    message.agreementUuid,
                                    message.consultationType
                                );
                                break;
                            case MessageType.RESERVATION_CONFIRMED:
                                this.paymentCancelled(message.reservationId);
                                break;
                            case MessageType.CANCEL_CONVERSATION:
                                this.showWarningMessage('Klient nie opłacił konwersacji.');
                                this.unregisterNotificationByConversationId(message.conversationId);
                                break;
                            case MessageType.NEXT_PAYMENT_POSSIBILITY:
                                if (message.paymentPossibility) {
                                    this.showErrorMessage('Przedłużenie konsultacji nie jest już możliwe.');
                                } else {
                                    this.setTimeoutSummaryPaymentDialog(this.state.tickerTimeOut);
                                    this.showReminderDialog(ReminerType.FIFTEEN_MINUTES, message.paymentRequired, message.conversationId);
                                }
                                break;
                            case MessageType.INCOMING_CONSULTATION_TIMEOUT:
                            case MessageType.INCOMING_CONSULTATION_ALREADY_ACCEPTED:
                                this.unregisterNotificationByConversationId(message.conversationId);
                                break;
                            case MessageType.RESERVATION_BY_CLIENT:
                                if (message.isNoLongerAvailable === true) {
                                    this.showErrorMessage('Dostępne godziny konsultacji zmieniły się! Należy odświeżyć okno przeglądarki.');
                                } else if (message.paymentRequired === true) {
                                    this.setTimeoutSummaryPaymentDialog(240);
                                    this.showPaymentSummaryDialog(message.conversationId, message.consultantName, message.paymentLink);
                                    this.setState({isReservationDialog: true});
                                } else {
                                    this.acceptByClient(message.conversationId, message.consultantAcceptanceRequired);
                                    this.showSuccessMessage('Konsultacja została zarezerwowana.');
                                    window.legalConsultationDialogRef?.current?.props.onHide();
                                }
                                break;
                            case MessageType.CONSULTATION_REJECTED_BY_CLIENT:
                                this.startChatWindow(
                                    message.conversationId,
                                    message.scheduledConsultationId,
                                    message.agreementUuid,
                                    message.endTime,
                                    this.chatType === ChatType.CONSULTANT
                                );
                                break;

                            case MessageType.CONSULTATION_ESTABLISHED:
                                if (message.consultationType === ConsultationType.CHAT) {
                                    this.startChatWindow(
                                        message.conversationId,
                                        message.scheduledConsultationId,
                                        message.agreementUuid,
                                        message.endTime,
                                        this.chatType === ChatType.CONSULTANT
                                    );
                                } else if (message.consultationType === ConsultationType.VIDEO_CHAT) {
                                    this.initializeVideo(
                                        message.conversationId,
                                        message.videoToken,
                                        message.endTime,
                                        message.agreementUuid
                                    );
                                }
                                if (message.initiated) {
                                    this.unregisterAllNotifications();
                                    this.showStartConversationDialog();
                                }
                                this.closeNotifies();
                                this.paymentCancelled(message.conversationId);
                                window.legalConsultationDialogRef?.current?.props?.onHide();
                                break;
                            case MessageType.TEXT:
                                this.textMessage(message);
                                break;
                            case MessageType.NEXT_PAYMENT:
                                if (message.paymentLink) {
                                    window.location.href = message.paymentLink;
                                } else {
                                    window.location.reload();
                                }
                                break;
                            case MessageType.WINDOW_CLOSED_ON_OPPOSITE_SIDE:
                                this.closedOnOppositeSide(message.conversationId, message.senderName);
                                break;
                            case MessageType.FEEDBACK_MESSAGE:
                                this.showFeedbackDialog(message.scheduledConsultationId);
                                break;
                            case MessageType.CONVERSATION_IN_PROGRESS:
                                if (message.consultationType === ConsultationType.CHAT) {
                                    this.startChatWindow(
                                        message.conversationId,
                                        message.scheduledConsultationId,
                                        message.agreementUuid,
                                        message.endTime,
                                        false
                                    );
                                    this.fetchMessages(message.conversationId);
                                } else if (message.consultationType === ConsultationType.VIDEO_CHAT) {
                                    this.initializeVideo(
                                        message.conversationId,
                                        message.videoToken,
                                        message.endTime,
                                        message.agreementUuid
                                    );
                                }
                                break;
                            case MessageType.FETCH_MESSAGES_END:
                                this.fetchMessagesEnd(message.conversationId);
                                break;
                            case MessageType.USER_CONNECTED:
                                if (message.remainingTimeChanged) {
                                    window.location.reload();
                                } else {
                                    this.userConnected(message.conversationId, message.senderName);
                                }
                                break;
                            case MessageType.USER_DISCONNECTED:
                                this.userDisconnected(message.conversationId, message.senderName);
                                break;

                            case MessageType.PAYMENT_CANCELLED:
                                this.showWarningMessage('Konsultacja nie została opłacona.');
                                this.paymentCancelled(message.conversationId);
                                break;
                            case MessageType.SCHEDULED_CONSULTATION_STARTED_TOO_EARLY:
                                this.showWarningMessage('Nie możesz rozpocząć konsultacji tak wcześnie!');
                                break;
                            case MessageType.WAIT_FOR_CLIENT:
                                this.waitForClient(message.scheduledConsultationId, message.startDate, message.timeout, message.clientName);
                                break;
                            default:
                        }
                        break;
                    default:
                }
            });
            this.socketConnect();
        }
    }

    socketConnect() {
        if (!this.authService.loggedIn() && localStorage.getItem('clientUuid') === null) {
            localStorage.setItem('clientUuid', uuidv4());
        }
        let token = !this.authService.loggedIn() ? localStorage.getItem('clientUuid') : encodeURI(this.authService.getToken());
        const wsUrl = process.env.REACT_APP_BACKEND_WS_URL + '/' + token;
        this.chatService.connect(wsUrl);
        console.log('@@@@@ connecting to WSS Server ...');
    }

    socketDisconnect() {
        this.chatService.close();
        this.cleanupVideoSession();
        this.setState({chatWindows: []});
        console.log('@@@@@ disconnecting from WSS Server ...');
    }

    //override
    initAfterSetElement() {
        super.initAfterSetElement();
    }

    initializeVideo(conversationId, videoToken, endTime, agreementUuid) {
        try {
            console.log('@@@ create new OV');
            this.OV = new OpenVidu();
            const videoSession = this.OV.initSession();
            console.log('@@@ videoSession', videoSession);
            videoSession.on('streamCreated', (event) => {
                const currentUser = this.authService.getProfile();
                const userLogin = JSON.parse(event.stream.connection.data).clientData;
                console.log('@@@ state.videoWindow.publisher', this.state.videoWindow.publisher);
                console.log('@@@ event', event);
                console.log(
                    `@@@ subscribe, userLogin=${userLogin}, event.stream.connection.connectionId=${event.stream.connection.connectionId}, this.state.videoWindow.publisher.stream.connection.connectionId=${this.state.videoWindow?.publisher?.stream?.connection?.connectionId}`,
                    event
                );
                if (
                    this.state.videoWindow?.publisher?.stream?.connection?.connectionId !== event.stream.connection.connectionId &&
                    currentUser.sub !== userLogin
                ) {
                    const subscriber = videoSession.subscribe(event.stream, undefined);
                    console.log('@@@ subscribe', subscriber);
                    this.setState((prevState) => ({
                        videoWindow: {
                            ...prevState.videoWindow,
                            subscriber,
                        },
                    }));
                }
            });
            // On every Stream destroyed...
            videoSession.on('streamDestroyed', (event) => {
                console.log('@@@ streamDestroyed', event);
                const userLogin = JSON.parse(event.stream.connection.data).clientData;
                console.log(
                    `@@@ streamDestroyed, userLogin=${userLogin}, event.stream.connection.connectionId=${event.stream.connection.connectionId}, this.state.videoWindow.subscriber.stream.connection.connectionId=${this.state.videoWindow.subscriber.stream.connection.connectionId}`,
                    event
                );
                if (event.stream.connection.connectionId === this.state.videoWindow.subscriber.stream.connection.connectionId) {
                    this.setState((prevState) => ({
                        videoWindow: {
                            ...prevState.videoWindow,
                            subscriber: undefined,
                        },
                    }));
                }
            });

            // On every asynchronous exception...
            videoSession.on('exception', (exception) => {
                console.warn('ERROR!!!!', exception);
                //TODO - error handling
            });
            const currentUser = this.authService.getProfile();
            const publishVideo = true; //this.chatType === ChatType.CLIENT;
            //alert(JSON.stringify(currentUser));
            videoSession
                .connect(videoToken, {clientData: currentUser.sub, conversationId})
                .then(async () => {
                    const devices = await this.OV.getDevices();
                    console.log('devices', devices);
                    // alert(JSON.stringify(devices));

                    var videoDevices = devices.filter((device) => device.kind === 'videoinput');
                    // --- 5) Get your own camera stream ---
                    console.log('videoDevices', videoDevices);

                    // Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
                    // element: we will manage it on our own) and with the desired properties
                    let publisher = this.OV.initPublisher(undefined, {
                        audioSource: undefined, // The source of audio. If undefined default microphone
                        videoSource: undefined, //videoDevices[0].deviceId, // The source of video. If undefined default webcam
                        publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
                        publishVideo: publishVideo, // Whether you want to start publishing with your video enabled or not
                        resolution: `${this.videoResolution.width}x${this.videoResolution.heigth}`, // The resolution of your video
                        frameRate: 30, // The frame rate of your video
                        insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                        mirror: false, // Whether to mirror your local video or not
                    });

                    // --- 6) Publish your stream ---

                    videoSession.publish(publisher);

                    // Set the main video in the page to display our webcam and store our Publisher
                    this.setState((prevState) => ({
                        videoWindow: {
                            ...prevState.videoWindow,
                            videoSession,
                            publisher: publisher,
                            publisherVideoOn: publishVideo,
                            publisherAudioOn: true,
                            videoWindow: true,
                            connected: true,
                            conversationId,
                            agreementUuid,
                            endTime: moment(endTime, 'YYYY-MM-DDTHH:mm:ss'),
                        },
                    }));
                    if (this.authService.isUserInRole('ROLE_LAW_OFFICE_CONSULTANT_MANAGE')) this.redirectAfterEstablished(agreementUuid);
                })
                .catch((error) => {
                    console.log(error);
                    //TODO - error handling
                    console.log('There was an error connecting to the session:', error.code, error.message);
                });
        } catch (err) {
            //TODO - error handling
            console.log('ERROR', err);
            alert('ERROR OPENVIDU');
        }
    }

    acceptByClient(conversationId, consultantAcceptanceRequired) {
        this.chatService.acceptByClient(conversationId, consultantAcceptanceRequired);
    }
    rejectedByClient(conversationId) {
        this.chatService.rejectedByClient(conversationId);
    }

    textMessage(message) {
        if (message.multiMode) {
            this.messagesHistory[message.conversationId].push(message);
        } else {
            let messages = this.state.messages[message.conversationId];
            if (!messages) {
                messages = [];
            }
            this.setState((prevState) => ({
                messages: {
                    ...prevState.messages,
                    [message.conversationId]: messages.concat(message),
                },
            }));
        }
    }

    closedOnOppositeSide(conversationId, senderName) {
        if (this.state.videoWindow.conversationId === conversationId) {
            this.setState({videoWindow: {connected: false}}, () => {
                this.showSuccessMessage('Połączenie zostało zakończone');
            });
        } else {
            const messages = this.state.messages[conversationId];
            if (messages) {
                const closedByOppositeSideMessage = {
                    text: `${senderName} zakończył konsultację.`,
                    time: moment(),
                    conversationId,
                    type: 'error',
                };
                this.setState(
                    (prevState) => ({
                        messages: {
                            ...prevState.messages,
                            [conversationId]: messages.concat(closedByOppositeSideMessage),
                        },
                    }),
                    () => {
                        const chatWindows = this.state.chatWindows;
                        const window = chatWindows.find((w) => w.conversationId === conversationId);
                        if (window) {
                            window.viewMode = 'USER_DISCONNECTED';
                            window.stopTimer = true;
                            this.setState({chatWindows});
                        }
                    }
                );
            }
        }
    }

    userConnected(conversationId, senderName) {
        const messages = this.state.messages[conversationId];
        if (messages) {
            const disconnectMessage = {
                text: `${senderName} odzyskał połączenie....`,
                time: moment(),
                conversationId,
                type: 'warning',
            };
            this.setState(
                (prevState) => ({
                    messages: {
                        ...prevState.messages,
                        [conversationId]: messages.concat(disconnectMessage),
                    },
                }),
                () => {
                    const chatWindows = this.state.chatWindows;
                    const window = chatWindows.find((w) => w.conversationId === conversationId);
                    if (window) {
                        window.viewMode = 'CHAT_WINDOW';
                        // window.stopTimer = true;
                        this.setState({chatWindows});
                    }
                }
            );
        }
    }

    userDisconnected(conversationId, senderName) {
        const messages = this.state.messages[conversationId];
        if (messages) {
            const disconnectMessage = {
                text: `${senderName} stracił połączenie....`,
                time: moment(),
                conversationId,
                type: 'warning',
            };
            this.setState(
                (prevState) => ({
                    messages: {
                        ...prevState.messages,
                        [conversationId]: messages.concat(disconnectMessage),
                    },
                }),
                () => {
                    const chatWindows = this.state.chatWindows;
                    const window = chatWindows.find((w) => w.conversationId === conversationId);
                    if (window) {
                        window.viewMode = 'BLOCKED';
                        // window.stopTimer = true;
                        this.setState({chatWindows});
                    }
                }
            );
        }
    }

    incommingConsultation(conversationId, isPaid, agreementUuid, consultationType) {
        const toastId = React.createRef();
        let type = consultationType;
        if (consultationType === ConsultationType.CHAT) {
            type = ChatNotificationType.newChat;
        } else if (consultationType === ConsultationType.VIDEO_CHAT) {
            type = ChatNotificationType.newVideoChat;
        }
        toastId.current = toast(
            <ChatNotification
                handleOpenChatWindowAction={(e) => this.openChatWindowAction(e, conversationId, isPaid, toastId.current, agreementUuid)}
                type={type}
            />,
            {
                onClose: () => this.unregisterNotificationByToastId(toastId.current),
                autoClose: this.state.incommingCallDialogTimeout * 1000,
            }
        );
        this.setState((prevState) => ({
            activeNotifications: [
                ...prevState?.activeNotifications,
                {
                    toastId: toastId.current,
                    conversationId: conversationId,
                },
            ],
        }));
    }

    paymentCancelled(conversationId) {
        if (this.state.activeNotifications) {
            const notification = this.state.activeNotifications.find((n) => n.conversationId === conversationId);
            if (notification) {
                toast.dismiss(notification.toastId);
                this.unregisterNotificationByToastId(notification.toastId);
            }
        }
    }

    waitForPayment(conversationId) {
        const toastId = React.createRef();

        toastId.current = toast(<ChatNotification type={ChatNotificationType.waitForPayment} />, {
            onClose: () => this.unregisterNotificationByToastId(toastId.current),
            autoClose: this.state.paymentTimeout * 1000,
            closeButton: false,
            draggable: false,
        });
        this.setState((prevState) => ({
            activeNotifications: [
                ...prevState.activeNotifications,
                {
                    toastId: toastId.current,
                    conversationId: conversationId,
                },
            ],
        }));
    }

    waitForClient(scheduledConsultationId, startDate, timeout, clientName) {
        const toastId = React.createRef();
        toastId.current = toast(
            <ChatNotification type={ChatNotificationType.waitForClient} clientName={clientName} startDate={startDate} />,
            {
                onClose: () => this.unregisterNotificationByToastId(toastId.current),
                autoClose: timeout * 1000,
                closeButton: false,
                draggable: false,
            }
        );
        this.setState((prevState) => ({
            activeNotifications: [
                ...prevState.activeNotifications,
                {
                    toastId: toastId.current,
                    scheduledConsultationId,
                },
            ],
        }));
    }

    closeNotifies() {
        console.log('closing notifies');
        if (this.state.activeNotifications) {
            this.state.activeNotifications.forEach((n) => toast.dismiss(n.toastId));
            this.setState({
                activeNotifications: [],
            });
        }
    }

    startChatWindow(conversationId, scheduledConsultationId, agreementUuid, endTime, sendWelcomeMessage) {
        this.unregisterNotificationByScheduledConsultationId(scheduledConsultationId);
        if (this.state.chatWindows.find((w) => w.conversationId === conversationId) >= 0) {
            console.log('startChatWindow: window already started for ' + conversationId);
            return;
        }
        this.setState(
            (prevState) => ({
                chatWindows: [
                    ...prevState.chatWindows,
                    {
                        conversationId,
                        endTime: moment(endTime, 'YYYY-MM-DDTHH:mm:ss'),
                        viewMode: 'CHAT_WINDOW',
                        agreementUuid,
                    },
                ],
            }),
            () => {
                if (sendWelcomeMessage) {
                    this.chatService.sendTextMessage(conversationId, 'Dzień dobry, w czym mogę pomóc?');
                    this.redirectAfterEstablished(agreementUuid);
                }
            }
        );
    }

    redirectAfterEstablished(agreementUuid) {
        let validUrl = '';
        if (!this.authService.loggedIn()) {
            validUrl = 'public/';
        }
        if (agreementUuid) {
            validUrl = '/#/' + validUrl + 'agreement/edit/' + agreementUuid;
        } else {
            validUrl = '/#/' + validUrl + 'template-list';
        }
        window.location.href = AppPrefixUtils.locationHrefUrl(validUrl);
    }

    backToTemplate(agreementUuid) {
        let validUrl = '';
        if (!this.authService.loggedIn()) {
            validUrl = 'public/';
        }
        validUrl = '/#/' + validUrl + 'agreement/edit/' + agreementUuid;
        window.location.href = AppPrefixUtils.locationHrefUrl(validUrl);
    }

    fetchMessages(conversationId) {
        this.messagesHistory[conversationId] = [];
        this.chatService.fetchMessages(conversationId);
    }

    fetchMessagesEnd(conversationId) {
        this.setState((prevState) => ({
            messages: {
                ...prevState.messages,
                [conversationId]: this.messagesHistory[conversationId],
            },
        }));
        this.messagesHistory[conversationId] = [];
    }

    openChatWindowAction(event, conversationId, isPaid, toastId, agreementUuid) {
        if (event !== undefined) {
            event.preventDefault();
        }
        console.log('openChatWindowAction: conversationId=' + conversationId);
        toast.dismiss(toastId);
        this.unregisterNotificationByToastId(toastId);

        this.chatService.acceptByConsultant(conversationId, agreementUuid);
        if (isPaid === false) {
            toast.dismiss(toastId);
            this.waitForPayment(conversationId);
        }
    }

    unregisterNotificationByToastId(toastId) {
        console.log('unregisterNotificationByToastId: toastId=' + toastId);
        if (this.state.activeNotifications) {
            this.setState({
                activeNotifications: this.state.activeNotifications.filter((toast) => toast.toastId !== toastId),
            });
        }
    }

    unregisterNotificationByScheduledConsultationId(scheduledConsultationId) {
        console.log('unregisterNotificationByScheduledConsultationId: scheduledConsultationId=' + scheduledConsultationId);
        if (!scheduledConsultationId || scheduledConsultationId === '') {
            return;
        }
        this.state.activeNotifications
            .filter((toast) => toast.scheduledConsultationId === scheduledConsultationId)
            .forEach((t) => {
                toast.dismiss(t.toastId);
            });
        this.setState(
            {
                activeNotifications: this.state.activeNotifications.filter(
                    (toast) => toast.scheduledConsultationId !== scheduledConsultationId
                ),
            },
            () => console.log(this.state.activeNotifications)
        );
    }

    unregisterAllNotifications() {
        console.log('unregisterAllNotifications');
        console.log(this.state.activeNotifications);
        this.state.activeNotifications.forEach((notify) => {
            toast.dismiss(notify.toastId);
        });
        this.setState({
            activeNotifications: [],
        });
    }

    unregisterNotificationByConversationId(conversationId) {
        console.log('unregisterNotificationByConversationId: conversationId=' + conversationId);
        console.log(this.state.activeNotifications);

        let current = this.state.activeNotifications.find((t) => t.conversationId === conversationId);
        //console.log(current);
        if (current) {
            toast.dismiss(current.toastId);
        }
        this.setState(
            {
                activeNotifications: this.state.activeNotifications.filter((n) => n.conversationId !== conversationId),
            },
            () => {
                //console.log(this.state.activeNotifications);
            }
        );
    }

    handleSendMessage(event, message, conversationId) {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            this.chatService.sendTextMessage(conversationId, message);
        } else {
            this.validator.showMessages();
            // this.scrollToError = true;
            this.forceUpdate();
        }
    }

    //override
    getContainerListName() {
        return 'chat-container';
    }

    handleCloseAction(conversationId) {
        const chatWindows = [...this.state.chatWindows];
        if (chatWindows.length !== 0) {
            const index = chatWindows.indexOf(chatWindows.find((w) => w.conversationId === conversationId));
            if (index !== -1) {
                chatWindows.splice(index, 1);
                this.setState({chatWindows});
            }
            this.chatService.closeWindow(conversationId);
        } else {
            const videoWindow = this.state.videoWindow;
            if (videoWindow.conversationId) {
                this.chatService.closeWindow(videoWindow.conversationId);
            }
            this.cleanupVideoSession();
        }
    }

    showFeedbackDialog(scheduledConsultationId) {
        this.setState((prevState) => ({
            visibleFeedbackDialog: true,
            feedback: {
                ...prevState.feedback,
                scheduledConsultationId,
                rating: undefined,
                message: undefined,
            },
        }));
    }

    showNoConsultantDialog() {
        this.setState({visibleNoConsultantDialog: true});
    }

    closeNoConsultantDialog() {
        this.setState({visibleNoConsultantDialog: false});
    }

    handleReminderAction(conversationId, reminderType) {
        if (reminderType === ReminerType.TWENTY_MINUTES) this.showReminderDialog(reminderType);
        else this.chatService.nextPaymentPossibility(conversationId);
    }

    showReminderDialog(reminderType, paymentRequired, conversationId) {
        this.setState((prevState) => ({
            ...prevState,
            visibleReminderDialog: true,
            reminder: {
                type: reminderType,
                paymentRequired,
                conversationId,
                text:
                    reminderType === ReminerType.TWENTY_MINUTES
                        ? 'Twoja konsultacja zakończy się za 20 minut'
                        : 'Twoja konsultacja dopiega konca',
            },
        }));
    }

    closeReminderDialog() {
        this.setState({visibleReminderDialog: false}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
        });
    }
    renderConsultationReservedConfirmationDialog() {
        const date = new Date(this.state.reservedSummary?.start);
        const momentDate = moment(date);
        return (
            this.state.visibleReservedConfirmationDialog && (
                <YesNoDialog
                    className={'confirmation-dialog'}
                    visible={true}
                    header='Zrobione!'
                    onChange={() => {
                        this.setState({
                            visibleReservedConfirmationDialog: false,
                        });
                        window.location.href = AppPrefixUtils.locationHrefUrl('/#/scheduled-event-calendar');
                    }}
                    onHide={() => {
                        this.setState({
                            visibleReservedConfirmationDialog: false,
                        });
                    }}
                    singleButton={true}
                    noButtonLabel={'Przejdź'}
                >
                    <label>
                        Twoja konsultacja została umówiona, odbędzie się ona: <b> {momentDate.format('DD-MM-YYYY HH:mm')}</b> z{' '}
                        <b>{this.state.reservedSummary?.consultantName}</b>.
                    </label>
                </YesNoDialog>
            )
        );
    }

    renderPaymentSummaryDialog() {
        const isReservationDialog = this.state.isReservationDialog;
        this.timeTicker();
        const {t} = this.props;
        return (
            <PrimiDialog
                ariaCloseIconLabel='Zamknij okno dialogowe'
                header='Podsumowanie płatności za konsultację'
                visible={this.state.visiblePaymentSummaryDialog}
                onHide={() => {
                    this.closePaymentSummaryDialog();
                }}
                className='summary-payment-consultation'
                draggable={false}
            >
                <Card
                    className='small-dialog-card'
                    footer={() => {
                        return (
                            <React.Fragment>
                                <hr className='line-footer' />
                                <div className='footer-dialog-button-container'>
                                    <ActionButton
                                        label='Anuluj'
                                        className='ml-2 gray'
                                        handleClick={(e) => {
                                            this.closePaymentSummaryDialog();
                                        }}
                                    />
                                    <ActionButton
                                        label='Zapłać za konsultację'
                                        handleClick={(e) => {
                                            this.pay();
                                        }}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    }}
                >
                    <div style={{marginBottom: '10px'}} className='row col-12'>
                        {!isReservationDialog ? (
                            <div>
                                <span className='row mb-2 text-danger'>
                                    <div>Nasz prawnik jest dostępny, konsultacja będzie trwała maksymalnie godzinę.</div>
                                </span>
                            </div>
                        ) : (
                            <span className='row p-4 text-danger'>Konsultacja będzie trwała maksymalnie godzinę. </span>
                        )}
                        <div className='row'>
                            <InputTextComponent
                                id='consultationTypeMailing'
                                name='consultationTypeMailing'
                                label='Typ konsultacji'
                                colClass='col-md-6 col-sm-12'
                                validator={this.validator}
                                value={
                                    this.state.consultationSummary.consultationType
                                        ? t(`ConsultationType.${this.state.consultationSummary.consultationType}`)
                                        : ''
                                }
                                viewMode={'VIEW'}
                            />
                            <InputTextComponent
                                id='specializationName'
                                name='specializationName'
                                label='Specjalizacja'
                                colClass='col-md-6 col-sm-12'
                                validator={this.validator}
                                value={this.state.consultationSummary.specializationName}
                                viewMode={'VIEW'}
                            />
                            <InputTextComponent
                                id='consultantName'
                                name='consultantName'
                                label='Konsultant'
                                colClass='col-md-6 col-sm-12'
                                validator={this.validator}
                                value={this.state.consultationSummary.consultantName}
                                viewMode={'VIEW'}
                            />
                            <InputNumberComponent
                                id='price'
                                name='price'
                                label={'Cena'}
                                colClass='col-md-6 col-sm-12'
                                locale='pl-PL'
                                currency='PLN'
                                mode='currency'
                                validator={this.validator}
                                value={this.state.consultationSummary.price}
                                viewMode={'VIEW'}
                            />
                            <div className='row mt-2 text-danger'>
                                {<div>Czas na opłacenie konsultacji {this.state.remainingTime}</div>}
                            </div>
                        </div>
                    </div>
                </Card>
            </PrimiDialog>
        );
    }

    renderReminderDialog() {
        let footer = undefined;
        const reminder = this.state.reminder;
        this.timeTicker();
        if (reminder.type === ReminerType.FIFTEEN_MINUTES) {
            footer = (
                <div className='row'>
                    <hr className='line-footer' />
                    <div className='footer-dialog-button-container'>
                        <ActionButton
                            className='cancel-button gray'
                            label='Wróć do konsultacji'
                            handleClick={() => {
                                this.closeReminderDialog();
                                DialogResizer.removeMaskIfExistOneDialog();
                            }}
                        />
                        <ActionButton
                            label={reminder.paymentRequired ? 'Dokup kolejną konsultację' : 'Przedłuż konsultację'}
                            className='ml-2'
                            handleClick={() => {
                                this.chatService.nextPayment(reminder.conversationId);
                                this.closeReminderDialog();
                                DialogResizer.removeMaskIfExistOneDialog();
                            }}
                        />
                    </div>
                </div>
            );
        }
        return (
            <PrimiDialog
                ariaCloseIconLabel='Zamknij okno dialogowe'
                header='Informacja'
                visible={this.state.visibleReminderDialog}
                onHide={() => {
                    this.closeReminderDialog();
                }}
                className='small-dialog reminder-dialog'
                draggable={false}
            >
                <Card footer={footer} className='small-dialog-card'>
                    <div style={{marginBottom: '20px'}} className='row '>
                        <div className='col-12 mt-2  bold'>
                            <span>{reminder.text}</span>{' '}
                        </div>
                        <div className='col-12 mt-2 text-danger'>
                            {reminder.type === ReminerType.FIFTEEN_MINUTES && (
                                <label>
                                    Czas na {reminder.paymentRequired ? ' opłacenie ' : ' przedłużenie '}
                                    konsultacji {this.state.remainingTime}
                                </label>
                            )}
                        </div>
                    </div>
                </Card>
            </PrimiDialog>
        );
    }

    renderFeedbackDialog() {
        DialogResizer.recalculateDialog();
        return (
            <PrimiDialog
                ariaCloseIconLabel='Zamknij okno dialogowe'
                header='Oceń konsultację'
                visible={this.state.visibleFeedbackDialog}
                closable={false}
                className='rating-dialog'
                draggable={false}
            >
                <Card
                    className='small-dialog-card'
                    footer={() => {
                        return (
                            <React.Fragment>
                                <hr className='line-footer' />
                                <div className='footer-dialog-button-container'>
                                    <ActionButton
                                        label='Wyślij'
                                        className='ml-2'
                                        handleClick={() => {
                                            let feedback = this.state.feedback;
                                            feedback.sentByConsultant = this.authService.isUserInRole('ROLE_LAW_OFFICE_CONSULTANT_MANAGE');
                                            const scheduledConsultationService = this.authService.loggedIn()
                                                ? new ScheduledConsultationService()
                                                : new ScheduledConsultationPublicService();
                                            scheduledConsultationService.saveFeedback(feedback);
                                            this.setState(
                                                () => ({
                                                    visibleFeedbackDialog: false,
                                                }),
                                                () => {
                                                    DialogResizer.removeMaskIfExistOneDialog();
                                                }
                                            );
                                        }}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    }}
                >
                    <Rating
                        value={this.state.feedback.rating}
                        className='m-2 w-100'
                        cancel={false}
                        onChange={(e) => {
                            e.preventDefault();
                            this.setState((prevState) => ({
                                feedback: {
                                    ...prevState.feedback,
                                    rating: e.value,
                                },
                            }));
                        }}
                    />

                    <InputTextareaComponent
                        id='message'
                        name='message'
                        className='mt-4 w-100'
                        label={'Wiadomość'}
                        showLabel={false}
                        colClass='col-md-12'
                        value={this.state.feedback.message}
                        validators='not_required'
                        validator={this.validator}
                        viewMode={'NEW'}
                        onChange={(a, b, c) => {
                            this.setState((prevState) => ({
                                feedback: {
                                    ...prevState.feedback,
                                    message: c.target.value,
                                },
                            }));
                        }}
                        placeholder={'Wiadomość...'}
                        stateField='feedback.message'
                        insideTable
                        rows={5}
                    />
                </Card>
            </PrimiDialog>
        );
    }

    renderStartConversationDialog() {
        const viedoChatMsg =
            this.state.consultationSummary?.consultationType === ConsultationType.VIDEO_CHAT ? (
                <p class='text-danger font-weight-normal'>
                    W przypadku konsultacji video klient musi zezwolić na dostęp kamery i mikrofonu, następnie konsultant powinien odswieżyć
                    okno przeglądarki.
                </p>
            ) : null;
        return (
            <PrimiDialog
                ariaCloseIconLabel='Zamknij okno dialogowe'
                header='Informacja'
                visible={this.state.visibleStartConversationDialog}
                onHide={() => {
                    this.closeStartConversationDialog();
                }}
                className='small-dialog'
                draggable={false}
            >
                <Card className='small-dialog-card'>
                    {viedoChatMsg}
                    <div class='font-weight-normal'>
                        Jeżeli w czasie trwania konsultacji przejdziesz do podglądu umowy lub zapiszesz zmiany, pamiętaj o poinformowaniu
                        klienta o konieczności odświeżenia umowy.
                    </div>
                </Card>
            </PrimiDialog>
        );
    }

    showEndConversationDialog(conversationId) {
        this.setState({
            visibleEndConversationDialog: true,
            conversationId,
        });
    }
    closeEndConversationDialog() {
        this.setState({
            visibleEndConversationDialog: false,
        });
    }

    showStartConversationDialog() {
        this.setState({
            visibleStartConversationDialog: true,
        });
    }
    closeStartConversationDialog() {
        this.setState(
            {
                visibleStartConversationDialog: false,
            },
            () => {
                DialogResizer.removeMaskIfExistOneDialog();
            }
        );
    }

    renderEndConversationDialog() {
        return (
            <React.Fragment>
                <YesNoDialog
                    className={'small-dialog'}
                    visible={this.state.visibleEndConversationDialog}
                    header='Kończenie konwersacji'
                    name='conversationCloseDialog'
                    yesButtonVariant='accent button'
                    onChange={(type, x, target) => {
                        if (target.value) {
                            this.handleCloseAction(this.state.conversationId);
                            this.closeEndConversationDialog();
                        } else {
                            this.closeEndConversationDialog();
                        }
                        DialogResizer.removeMaskIfExistOneDialog();
                    }}
                    onHide={() => {
                        this.closeEndConversationDialog();
                        DialogResizer.removeMaskIfExistOneDialog();
                    }}
                    yesButtonLabel={'Zakończ'}
                    noButtonLabel={'Wróć'}
                >
                    <label>{'Czy na pewno chcesz zakończyć konwersację?'}</label>
                </YesNoDialog>
            </React.Fragment>
        );
    }

    renderNoConsultantDialog() {
        const msg = 'Żaden z konsultantów nie jest w tej chwili dostępny.';
        return (
            <PrimiDialog
                ariaCloseIconLabel='Zamknij okno dialogowe'
                header='Informacja'
                visible={this.state.visibleNoConsultantDialog}
                onHide={() => {
                    this.closeNoConsultantDialog();
                }}
                className='small-dialog'
                draggable={false}
            >
                <Card
                    className='small-dialog-card'
                    footer={() => {
                        return (
                            <React.Fragment>
                                <hr className='line-footer' />
                                <div className='footer-dialog-button-container'>
                                    <ActionButton
                                        className='cancel-button gray'
                                        label='Anuluj'
                                        handleClick={() => {
                                            DialogResizer.removeMaskIfExistOneDialog();
                                            this.closeNoConsultantDialog();
                                        }}
                                    />
                                    <ActionButton
                                        label='Umów konsultację'
                                        rendered={this.authService.loggedIn()}
                                        className='ml-2'
                                        handleClick={() => {
                                            DialogResizer.removeMaskIfExistOneDialog();
                                            this.closeNoConsultantDialog();
                                            window.tabViewLegalRef.current.props.onMoveToArrange();
                                        }}
                                    />
                                    <ActionButton
                                        label='Zaloguj'
                                        rendered={!this.authService.loggedIn()}
                                        className='ml-2'
                                        handleClick={() => {
                                            this.closeNoConsultantDialog();
                                            window.location.href = AppPrefixUtils.locationHrefUrl('/#/login');
                                            DialogResizer.removeMaskIfExistOneDialog();
                                        }}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    }}
                >
                    <div className='row'>
                        <DivContainer className='col-12'>
                            <label>
                                {this.authService.loggedIn() ? msg : msg + ' Załóż konto, żeby zaplanować konsultację z naszymi prawnikami'}
                            </label>
                        </DivContainer>
                    </div>
                </Card>
            </PrimiDialog>
        );
    }

    showPaymentSummaryDialog(conversationId, consultantName, paymentLink) {
        this.setState({
            consultationSummary: {
                specializationName: this.consultationHelper.readConsultationRequestSpecializationName(),
                consultationType: this.consultationHelper.readConsultationRequestConsultationType(),
                price: this.consultationHelper.readConsultationRequestPrice(),
                consultantName,
                conversationId,
                paymentLink,
            },
            visiblePaymentSummaryDialog: true,
        });
    }

    closePaymentSummaryDialog() {
        this.setState({visiblePaymentSummaryDialog: false}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
        });
        this.chatService.invalidateReservation(this.state.consultationSummary.conversationId);
    }

    pay() {
        DialogResizer.removeMaskIfExistOneDialog();
        console.log('pay...');
        window.location.href = this.state.consultationSummary.paymentLink;
    }

    timeTicker() {
        this.intervalId = setInterval(() => {
            const diff = moment.duration(moment(this.state.tickerTimeOut).diff(moment()));
            const remainingTime = moment.duration(diff).format('mm:ss');
            if (this._isMounted) {
                if (remainingTime < 0) {
                    clearInterval(this.intervalId);
                    this.setState({
                        visiblePaymentSummaryDialog: false,
                        visibleReminderDialog: false,
                    });
                    DialogResizer.removeMask();
                }
                this.setState({remainingTime});
            }
        }, 1 * 1000);
    }

    calculateInitPosition(chatWindow) {
        const chatWindows = this.state.chatWindows;
        chatWindows.forEach((w) => {
            const x = this.findXPosition(w);
            const y = this.findYPosition(w);
            this.positions[w.conversationId] = {x, y};
        });
        return this.positions[chatWindow.conversationId];
    }

    findXPosition(chatWindow) {
        try {
            const deltaX = 50;
            if (this.positions[chatWindow.conversationId]) {
                return this.positions[chatWindow.conversationId].x;
            }
            let result = this.defaultPosition.x;
            while (true) {
                const arr = [];
                this.state.chatWindows
                    .map((cw) => cw.conversationId)
                    .filter((id) => id !== chatWindow.conversationId)
                    .forEach((id) => {
                        if (this.positions[id]) {
                            arr.push(this.positions[id]);
                        }
                    });
                if (arr.map((p) => p.x).find((x) => x <= result && x >= result - deltaX)) {
                    result = result - deltaX;
                } else {
                    break;
                }
                if (result < 400) {
                    break;
                }
            }
            return result;
        } catch (err) {
            return this.defaultPosition.x;
        }
    }

    findYPosition(chatWindow) {
        try {
            const deltaY = 10;
            if (this.positions[chatWindow.conversationId]) {
                return this.positions[chatWindow.conversationId].y;
            }
            let result = this.defaultPosition.y;
            while (true) {
                const arr = [];
                this.state.chatWindows
                    .map((cw) => cw.conversationId)
                    .filter((id) => id !== chatWindow.conversationId)
                    .forEach((id) => {
                        if (this.positions[id]) {
                            arr.push(this.positions[id]);
                        }
                    });
                if (arr.map((p) => p.y).find((y) => y <= result && y >= result - deltaY)) {
                    result = result + deltaY;
                } else {
                    break;
                }
                if (result > 300) {
                    break;
                }
            }
            return result;
        } catch (err) {
            return this.defaultPosition.y;
        }
    }

    persistStartPosition(chatWindow, e) {
        this.positionsStart[chatWindow] = {x: e.clientX, y: e.clientY};
    }

    updatePosition(chatWindow, e) {
        const start = this.positionsStart[chatWindow];
        const p = this.positions[chatWindow];
        p.x = p.x + e.x - start.x;
        p.y = p.y + e.y - start.y;
    }

    handleNavbarItemClick(event) {
        const deviationXMin = 8;
        const deviationXMax = 45;
        const deviationYMin = -50;
        const deviationYMax = 50;

        if (event.targetTouches) {
            const x = event.targetTouches[0].clientX; // Pobierz pozycję X kliknięcia myszy
            const y = event.targetTouches[0].clientY; // Pobierz pozycję Y kliknięcia myszy

            const minimizeBtn = document.getElementById('minimize-button');
            const maximizeBtn = document.getElementById('maximize-button');
            const closeBtn = document.getElementById('close-button');

            if (
                minimizeBtn?.getBoundingClientRect().x + deviationXMax > x &&
                x > minimizeBtn?.getBoundingClientRect().x + deviationXMin &&
                minimizeBtn?.getBoundingClientRect().y + deviationYMax > y &&
                y > minimizeBtn?.getBoundingClientRect().y + deviationYMin
            ) {
                minimizeBtn.click();
            } else if (
                maximizeBtn?.getBoundingClientRect().x + deviationXMax > x &&
                x > maximizeBtn?.getBoundingClientRect().x + deviationXMin &&
                maximizeBtn?.getBoundingClientRect().y + deviationYMax > y &&
                y > maximizeBtn?.getBoundingClientRect().y + deviationYMin
            ) {
                maximizeBtn.click();
            } else if (
                closeBtn?.getBoundingClientRect().x + deviationXMax > x &&
                x > closeBtn?.getBoundingClientRect().x + deviationXMin &&
                closeBtn?.getBoundingClientRect().y + deviationYMax > y &&
                y > closeBtn?.getBoundingClientRect().y + deviationYMin
            ) {
                closeBtn.click();
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                    <CustomMessages ref={(el) => (this.messages = el)} />
                    <ToastContainer
                        position='bottom-right'
                        //autoClose={this.state.incommingCallDialogTimeout * 1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick={false}
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable
                        pauseOnHover={false}
                    />
                    {this.state.visiblePaymentSummaryDialog && this.renderPaymentSummaryDialog()}
                    {this.state.visibleReservedConfirmationDialog && this.renderConsultationReservedConfirmationDialog()}
                    {this.state.visibleStartConversationDialog && this.renderStartConversationDialog()}
                    {this.state.visibleEndConversationDialog && this.renderEndConversationDialog()}
                    {this.state.visibleFeedbackDialog && this.renderFeedbackDialog()}
                    {this.state.visibleNoConsultantDialog && this.renderNoConsultantDialog()}
                    {this.state.visibleReminderDialog && this.renderReminderDialog()}
                    {this.state.chatWindows.map((component, index) => (
                        <Draggable
                            onMouseDown={(event) => {
                                this.handleNavbarItemClick(event);
                            }}
                            defaultPosition={this.calculateInitPosition(component)}
                            key={'dragable-' + component.conversationId}
                            onStart={(e) => {
                                this.persistStartPosition(component, e);
                            }}
                        >
                            <div className='position-top' id={component.conversationId}>
                                <Chat
                                    key={'chat-' + component.conversationId}
                                    element={this.state.element}
                                    backToTemplate={this.backToTemplate}
                                    agreementUuid={component.agreementUuid}
                                    showEndConversationDialog={this.showEndConversationDialog}
                                    handleChange={this.handleChange}
                                    validator={this.validator}
                                    showReminderDialog={this.handleReminderAction}
                                    handleSendAction={this.handleSendMessage}
                                    handleCloseAction={(conversationId) => {
                                        this.handleCloseAction(conversationId);
                                    }}
                                    viewMode={component.viewMode}
                                    messages={this.state.messages[component.conversationId]}
                                    conversationId={component.conversationId}
                                    type={this.chatType === ChatType.CLIENT ? 'CLIENT' : 'CONSULTANT'}
                                    endTime={component.endTime}
                                    stopTimer={component.stopTimer}
                                />
                            </div>
                        </Draggable>
                    ))}

                    {this.state.videoWindow.connected ? (
                        <Draggable
                            defaultPosition={this.defaultVideoPosition}
                            key={'dragable-video-0'}
                            onMouseDown={(event) => {
                                this.handleNavbarItemClick(event);
                            }}
                        >
                            <div className='position-top'>
                                <Video
                                    agreementUuid={this.state.videoWindow.agreementUuid}
                                    conversationId={this.state.videoWindow.conversationId}
                                    showEndConversationDialog={this.showEndConversationDialog}
                                    handleCloseAction={this.closeVideoChatWindow}
                                    backToTemplate={this.backToTemplate}
                                    publisher={this.state.videoWindow.publisher}
                                    showReminderDialog={this.handleReminderAction}
                                    type={this.chatType === ChatType.CLIENT ? 'CLIENT' : 'CONSULTANT'}
                                    subscriber={this.state.videoWindow.subscriber}
                                    publisherVideoOn={this.state.videoWindow.publisherVideoOn}
                                    publisherAudioOn={this.state.videoWindow.publisherAudioOn}
                                    subscriberVideoOn={this.state.videoWindow.subscriberVideoOn}
                                    subscriberAudioOn={this.state.videoWindow.subscriberAudioOn}
                                    changePublisherVideoState={this.changePublisherVideoState}
                                    changePublisherAudioState={this.changePublisherAudioState}
                                    endTime={this.state.videoWindow.endTime}
                                    stopTimer={this.state.videoWindow.stopTimer}
                                />
                            </div>
                        </Draggable>
                    ) : null}
                </BlockUi>
            </React.Fragment>
        );
    }
}

ChatContainer.defaultProps = {
    allowPublicUser: false,
};

ChatContainer.propTypes = {
    showMessage: PropTypes.func,
    allowPublicUser: PropTypes.bool,
};

export default withTranslation()(ChatContainer);
