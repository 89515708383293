import {InputText} from 'primereact/inputtext';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import MenuItemIcon from '../MenuItemIcon';

class InputTextComponent extends Component {
    renderView() {
        const {
            colClass,
            formGroup,
            iconColor,
            iconName,
            iconSide,
            iconSize,
            iconSvg,
            id,
            insideTable,
            label,
            objectId,
            publicMode,
            renderValue,
            renderLabel,
            showLabel,
            titleElement,
            titleElementClass,
            titleElementShowId,
            value,
            validateViewMode,
            validator,
            wordBreak,
            validators,
            tooltipView,
        } = this.props;
        const style = wordBreak ? {overflowWrap: 'break-word'} : {};
        return publicMode ? (
            <div className='input_easy_label row pl-0'>
                {renderLabel ? (
                    renderLabel()
                ) : label !== undefined && showLabel ? (
                    <label id={`${id}-label-id`} className='easy_label col-lg-2 col-md-3' htmlFor={id}>
                        {label}
                    </label>
                ) : null}
                <div className='col-md-5'>
                    <span
                        aria-label={label}
                        aria-labelledby={`${id}-label-id`}
                        className={`p-inputtext-view icon_text ${titleElement ? ' title-element' : ''} ${
                            iconName !== undefined ? iconColor : ''
                        }`}
                    >
                        {iconSide === 'left' && iconName !== undefined ? <i className={`icon mdi ${iconName} ${iconSize}`} /> : null}
                        {iconSide === 'left' && iconSvg !== undefined ? (
                            <MenuItemIcon alt='' className={`icon-svg ${iconSize}`} iconSvg={iconSvg} />
                        ) : null}
                        {renderValue ? renderValue() : value}
                        {iconSide === 'right' && iconName !== undefined ? <i className={`icon mdi ${iconName} ${iconSize}`} /> : null}
                    </span>
                    {titleElement && titleElementShowId ? <span className='p-inputtext-view'>{` ID: ${objectId}`}</span> : null}
                    {validateViewMode && validator ? validator.message(id, label, value, validators) : null}
                </div>
            </div>
        ) : (
            <div className={insideTable ? '' : titleElement ? titleElementClass : colClass}>
                <div className={insideTable ? '' : 'row'}>
                    {/* {tooltipView ? <Tooltip target={`.p-inputtext-view-content`} /> : null} */}
                    <div className={insideTable ? '' : `col-md-12 ${formGroup ? 'form-group' : ''}`}>
                        {renderLabel ? (
                            renderLabel()
                        ) : label !== undefined && showLabel && !titleElement ? (
                            <label id={`${id}-label-id`} className='p-label' htmlFor={id} style={{width: '100%'}}>
                                {label}
                            </label>
                        ) : null}
                        <span
                            aria-label={label}
                            aria-labelledby={`${id}-label-id`}
                            className={`p-inputtext-view icon_text ${titleElement ? ' title-element' : ''} ${
                                iconName !== undefined ? iconColor : ''
                            }`}
                        >
                            <span className='p-inputtext-view-content' style={style} data-pr-tooltip={tooltipView ? tooltipView : ''}>
                                {iconSide === 'left' && iconName !== undefined ? (
                                    <i className={`icon mdi ${iconName} ${iconSize}`} />
                                ) : null}
                                {iconSide === 'left' && iconSvg !== undefined ? (
                                    <MenuItemIcon alt='' className={`icon-svg ${iconSize}`} iconSvg={iconSvg} />
                                ) : null}
                                {renderValue ? renderValue() : value}
                                {iconSide === 'right' && iconName !== undefined ? (
                                    <i className={`icon mdi ${iconName} ${iconSize}`} />
                                ) : null}
                            </span>
                        </span>
                        {titleElement && titleElementShowId ? <span className='p-inputtext-view'>{` ID: ${objectId}`}</span> : null}
                        {validateViewMode && validator ? validator.message(id, label, value, validators) : null}
                    </div>
                </div>
            </div>
        );
    }

    renderEdit() {
        return this.renderNew();
    }

    renderNew() {
        const {
            colClass,
            disabled,
            id,
            insideTable,
            keyfilter,
            label,
            max,
            className,
            name,
            onAfterStateChange,
            onChange,
            placeholder,
            publicMode,
            wordBreak,
            showLabel,
            stateField,
            tooltip,
            validator,
            validators,
            value,
            renderRequired,
            renderLabel,
            leftMarginLabel,
            onBlur,
            iconColor,
            iconName,
            iconSide,
            iconSize,
            iconSvg,
            onInput,
        } = this.props;
        const required = validators !== undefined && validators.includes('required') && !validators.includes('not_required');
        const style = wordBreak ? {width: '100%', overflowWrap: 'break-word'} : {width: '100%'};

        return publicMode ? (
            <div className='input_easy_label row pl-0'>
                {label !== undefined && showLabel ? (
                    <label id={`${id}-label-id`} className='easy_label col-lg-2 col-md-3' htmlFor={id}>
                        {label}
                    </label>
                ) : null}
                <div className='col-md-5'>
                    <InputText
                        aria-describedby={`${id}-error`}
                        aria-label={label}
                        aria-labelledby={label === undefined && showLabel ? `${id}-label-id` : undefined}
                        key={id}
                        className={className}
                        id={id}
                        name={name}
                        max={max}
                        style={style}
                        type='text'
                        keyfilter={keyfilter}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e) => (onChange ? onChange('TEXT', undefined, e, onAfterStateChange, stateField) : null)}
                        onBlur={onBlur}
                        disabled={disabled}
                        required={required}
                    />
                    <div aria-live='assertive'>{validator ? validator.message(id, label, value, validators) : null}</div>
                </div>
            </div>
        ) : (
            <div className={insideTable ? '' : colClass}>
                <div className={insideTable ? '' : 'row'}>
                    <div className={insideTable ? '' : 'col-md-12 form-group'}>
                        {renderLabel ? (
                            renderLabel()
                        ) : label !== undefined && showLabel ? (
                            <label
                                id={`${id}-label-id`}
                                className={`p-label ${className}`}
                                htmlFor={id}
                                style={{width: '100%', marginLeft: leftMarginLabel}}
                                data-pr-tooltip={tooltip ? tooltip : ''}
                            >
                                {renderRequired === true ? (
                                    <span>
                                        {label}
                                        <span className='text-danger'>*</span>
                                    </span>
                                ) : (
                                    label
                                )}
                            </label>
                        ) : null}
                        <span className={`p-inputtext-view icon_text  ${iconName !== undefined ? iconColor : ''}`}>
                            {iconSide === 'left' && iconName !== undefined ? <i className={`icon mdi ${iconName} ${iconSize}`} /> : null}
                            {iconSide === 'left' && iconSvg !== undefined ? (
                                <MenuItemIcon alt='' className={`icon-svg ${iconSize}`} iconSvg={iconSvg} />
                            ) : null}

                            {iconSide === 'right' && iconName !== undefined ? <i className={`icon mdi ${iconName} ${iconSize}`} /> : null}
                        </span>
                        <InputText
                            aria-describedby={`${id}-error`}
                            aria-label={label}
                            aria-labelledby={label === undefined && showLabel ? `${id}-label-id` : undefined}
                            key={id}
                            id={id}
                            name={name}
                            max={max}
                            className={className}
                            type='text'
                            keyfilter={keyfilter}
                            placeholder={placeholder}
                            style={style}
                            value={value}
                            onChange={(e) => (onChange ? onChange('TEXT', undefined, e, onAfterStateChange, stateField) : null)}
                            onBlur={onBlur}
                            disabled={disabled}
                            required={required}
                            onInput={onInput}
                        />
                        <div aria-live='assertive'>{validator ? validator.message(id, label, value, validators) : null}</div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {rendered, viewMode} = this.props;
        if (rendered) {
            switch (viewMode) {
                case 'NEW':
                    return this.renderNew();
                case 'EDIT':
                    return this.renderEdit();
                case 'VIEW':
                default:
                    return this.renderView();
            }
        } else {
            return null;
        }
    }
}

InputTextComponent.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    disabled: false,
    formGroup: true,
    iconSide: 'left',
    insideTable: false,
    max: 255,
    objectId: '',
    className: '',
    placeholder: '',
    publicMode: false,
    rendered: true,
    showLabel: true,
    stateField: 'element',
    titleElement: false,
    wordBreak: false,
    titleElementShowId: false,
    titleElementClass: 'col-md-12',
    leftMarginLabel: undefined,
    validateViewMode: false,
    validators: 'required',
    viewMode: 'VIEW',
    renderRequired: false,
};

InputTextComponent.propTypes = {
    colClass: PropTypes.string,
    disabled: PropTypes.bool,
    formGroup: PropTypes.bool,
    iconColor: PropTypes.string,
    className: PropTypes.string,
    wordBreak: PropTypes.bool,
    iconName: PropTypes.string,
    iconSide: PropTypes.string,
    iconSize: PropTypes.string,
    iconSvg: PropTypes.string,
    id: PropTypes.string.isRequired,
    insideTable: PropTypes.bool,
    keyfilter: PropTypes.string,
    label: PropTypes.string.isRequired,
    leftMarginLabel: PropTypes.string,
    max: PropTypes.number,
    name: PropTypes.string.isRequired,
    objectId: PropTypes.string,
    onAfterStateChange: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    publicMode: PropTypes.bool,
    rendered: PropTypes.bool,
    showLabel: PropTypes.bool,
    stateField: PropTypes.string,
    titleElement: PropTypes.bool,
    titleElementClass: PropTypes.string,
    titleElementShowId: PropTypes.bool,
    validateViewMode: PropTypes.bool,
    validator: PropTypes.object.isRequired,
    validators: PropTypes.string,
    value: PropTypes.string,
    viewMode: PropTypes.string,
    renderLabel: PropTypes.func,
    onBlur: PropTypes.func,
    renderRequired: PropTypes.bool,
};

export default withTranslation()(InputTextComponent);
