import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {ActionButton} from '../../components/ActionButton';
import DivContainer from '../../components/DivContainer';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import ConsultationModel from '../../model/ConsultatonModel';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import EventCalendarService from '../../services/EventCalendarService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import DialogResizer from '../../components/DialogResizer';

class ConsultationDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new EventCalendarService());
        this.state = {
            consultation: new ConsultationModel(),
            element: {
                consultationId: undefined,
                subject: '',
                text: '',
                address: '',
            },
            viewMode: this.props.viewMode,
            activeIndex: 0,
        };
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton
                    buttonType='NEXT'
                    label={this.state.viewMode === 'NEW' ? t('consultationProposal.messageTitle') : t('consultationProposal.send')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                />
            </div>
        );
    }

    handleValidForm() {
        this.sendMessage();
    }
    sendMessage() {
        const {backUrl} = this.props;
        const {element, consultation} = this.state;
        this.scrollToTop();
        if (this._isMounted) {
            this.service
                .changeStatusAndSendMessage(element, consultation)
                .then((response) => {
                    DialogResizer.removeMaskIfExistOneDialog();
                    this.afterAddSuccess(response);
                    window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
                })
                .catch((err) => {
                    this.showErrorMessage(err.message, 10000);
                });
        }
    }

    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('consultationProposal.messageTitle')}`
                            : this.state.viewMode === 'EDIT'
                            ? `${t('consultationProposal.messageTitle')}`
                            : `${t('consultationProposal.messageTitle')}`}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }
    //override
    onKeyDown(e) {}
    updateElement(data) {
        this.setState(
            {
                consultation: {
                    end: data.end,
                    start: data.start,
                    id: data.id,
                    specialization: data.specialization,
                    status: data.status,
                    title: data.title,
                    user: data.user,
                },
                element: {
                    consultationId: data.id,
                    address: data.user?.email,
                },
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    getContainerListName() {
        return 'consultation-list';
    }

    getAddSucces() {
        return 'Konsultacja została odrzucona';
    }

    renderDetails() {
        const {t} = this.props;
        return this.state.viewMode === 'DELETE' ? (
            <DivContainer>
                <label id={`delete-user-label-id`} className='p-label' htmlFor={'delete-user'} style={{width: '100%'}}>
                    {t('details.deleteConfirmation')}
                </label>
                <span
                    aria-label={t('details.deleteConfirmation')}
                    aria-labelledby={`delete-user-label-id`}
                    className={`p-inputtext-view icon_text element-to-remove`}
                >
                    {this.state.element.firstName} {this.state.element.lastName}
                </span>
            </DivContainer>
        ) : (
            this.renderDialog()
        );
    }

    renderDialog() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='name'
                        name='name'
                        label={'Odbiorca'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6'
                        value={this.state.consultation.title}
                        validator={this.validator}
                        validators='required|max:100'
                        onChange={this.handleChange}
                        viewMode={'VIEW'}
                    />
                    <InputTextComponent
                        id='address'
                        name='address'
                        label={'Adres'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6'
                        value={this.state.consultation.user?.email}
                        validator={this.validator}
                        validators='required|max:100'
                        onChange={this.handleChange}
                        viewMode={'VIEW'}
                    />
                    <InputTextComponent
                        id='subject'
                        name='subject'
                        label={'Tytuł'}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                        value={this.state.element.subject}
                        validator={this.validator}
                        validators='required|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                </DivContainer>
                <DivContainer colClass='row'>
                    <InputTextareaComponent
                        id='text'
                        name='text'
                        label={'Wiadomość'}
                        colClass='col-md-12'
                        value={this.state.element.text}
                        validator={this.validator}
                        validators='required|max:500'
                        onChange={this.handleChange}
                        viewMode={'NEW'}
                        rows={5}
                        autoResize
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
}

ConsultationDialog.defaultProps = {
    backUrl: '/#/consultation-list',
    currentUser: undefined,
    visible: false,
};

ConsultationDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
};

export default withTranslation()(ConsultationDialog);
