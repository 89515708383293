export default class HierarchicalDictionaryModel {
    constructor() {
        this.id = undefined;
        this.version = 0;
        this.code = '';
        this.name = '';
    }

    static assign(from, to) {
        if (from) {
            to.id = from.id;
            to.version = from.version;
            to.code = from.code;
            to.name = from.name;
        } else {
            to = new HierarchicalDictionaryModel();
        }
    }

    static copy(from) {
        const to = new HierarchicalDictionaryModel();
        HierarchicalDictionaryModel.assign(from, to);
        return to;
    }
}