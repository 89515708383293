import {Accordion, AccordionTab} from 'primereact/accordion';
import CustomLabel from '../CustomLabel';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import {InputText} from 'primereact/inputtext';
import {propTypes} from 'mgr-pdf-viewer-react/dist/mgr-pdf-viewer-react';
import PropTypes from 'prop-types';
import {t} from 'i18next';

import React, {useEffect} from 'react';

export const PrimiAccordion = (props) => {
    const {body} = props;

    useEffect(() => {}, [props]);
    return (
        <Accordion className='filter-accordion' activeIndex={-1}>
            <AccordionTab
                header={
                    <div>
                        <img
                            className='card-icon'
                            alt={t('list.filter')}
                            src={`${
                                process.env.REACT_APP_PORTAL_TYPE === 'LEXY'
                                    ? AppPrefixUtils.locationHrefUrl('/images/ico_lexy_filter.svg')
                                    : AppPrefixUtils.locationHrefUrl('/images/ico_filter.svg')
                            }`}
                        />
                        <span>{t('list.filter')}</span>
                    </div>
                }
            >
                {body}
            </AccordionTab>
        </Accordion>
    );
};

export default PrimiAccordion;

PrimiAccordion.defaultProps = {
    body: undefined,
};

PrimiAccordion.propTypes = {
    body: PropTypes.object,
};
