import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {ActionButton} from '../../components/ActionButton';
import DivContainer from '../../components/DivContainer';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import TemplateModel from '../../model/TemplateModel';
import TemplateService from '../../services/TemplateService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import DialogResizer from '../../components/DialogResizer';

class TemplateDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new TemplateService());
        this.state = {
            template: new TemplateModel(),
            element: {
                id: this.props.elementId,
                email: this.props.email,
                subject: '',
                text: '',
            },
            viewMode: this.props.viewMode,
            activeIndex: 0,
        };
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton label={t('details.cancel')} variant={'button cancel-button gray'} handleClick={this.props.onHide}
/>
                <ActionButton
                    label={t('consultationProposal.send')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                />
            </div>
        );
    }

    handleValidForm() {
        this.sendMessage();
    }
    sendMessage() {
        const {backUrl} = this.props;
        const {element, template} = this.state;
        this.scrollToTop();
        if (this._isMounted) {
            this.service
                .changeStatusAndSendMessage(element, template)
                .then((response) => {
                    DialogResizer.removeMaskIfExistOneDialog();
                    this.afterAddSuccess(response);
                    window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
                    this.unblockUi();
                    
                })
                .catch((err) => {
                    this.showErrorMessage(err.message, 10000);
                    this.unblockUi();
                });
        }
    }

    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('consultationProposal.messageTitle')}`
                            : this.state.viewMode === 'EDIT'
                            ? `${t('consultationProposal.messageTitle')}`
                            : `${t('consultationProposal.messageTitle')}`}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }
    //override
    onKeyDown(e) {}

    getContainerListName() {
        return 'template-list';
    }

    getAddSucces() {
        return 'Zmiana została odrzucona';
    }

    renderDetails() {
        const {t} = this.props;
        return this.state.viewMode === 'DELETE' ? (
            <DivContainer>
                <label id={`delete-user-label-id`} className='p-label' htmlFor={'delete-user'} style={{width: '100%'}}>
                    {t('details.deleteConfirmation')}
                </label>
                <span
                    aria-label={t('details.deleteConfirmation')}
                    aria-labelledby={`delete-user-label-id`}
                    className={`p-inputtext-view icon_text element-to-remove`}
                >
                    {this.state.element.firstName} {this.state.element.lastName}
                </span>
            </DivContainer>
        ) : (
            this.renderDialog()
        );
    }

    renderDialog() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='name'
                        name='name'
                        label={'Odbiorca'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6'
                        value={this.props.author}
                        validator={this.validator}
                        validators='required|max:100'
                        onChange={this.handleChange}
                        viewMode={'VIEW'}
                    />
                    <InputTextComponent
                        id='email'
                        name='email'
                        label={'Email'}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6'
                        value={this.state.element.email}
                        validator={this.validator}
                        validators='required|max:100'
                        onChange={this.handleChange}
                        viewMode={'VIEW'}
                    />
                    <InputTextComponent
                        id='subject'
                        name='subject'
                        label={'Tytuł'}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                        value={this.state.element.subject}
                        validator={this.validator}
                        validators='required|max:100'
                        onChange={this.handleChange}
                        viewMode={'NEW'}
                    />
                </DivContainer>
                <DivContainer colClass='row'>
                    <InputTextareaComponent
                        id='text'
                        name='text'
                        label={'Wiadomość'}
                        colClass='col-md-12'
                        value={this.state.element.text}
                        validator={this.validator}
                        validators='required|max:500'
                        onChange={this.handleChange}
                        viewMode={'NEW'}
                        rows={5}
                        autoResize
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
}

TemplateDialog.defaultProps = {
    backUrl: '/#/configurator-template-list',
    currentUser: undefined,
    visible: false,
};

TemplateDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
};

export default withTranslation()(TemplateDialog);
