import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import {withTranslation} from 'react-i18next';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import ConsultationModel from '../../model/ConsultatonModel';
import EventCalendarService from '../../services/EventCalendarService';
import ConsultationDialog from './ConsultationDialog';
import moment from 'moment';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
import DialogResizer from '../../components/DialogResizer';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';
class ConsultationContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new EventCalendarService());

        this.isCustomHeaderItems = !this.isLexyPortal();
        this.state = {
            dialogViewMode: '',
            dialogVisibile: false,
            loading: true,
            elementId: props.params.id,
            element: new ConsultationModel(),
            consultations: [],
            purchased: [],
            categories: [],
            templates: [],
            type: [],
        };
        this.openRejectDialog = this.openRejectDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    updateElement(data) {
        this.setState(
            {
                element: {
                    end: data.end,
                    start: data.start,
                    id: data.id,
                    specialization: data.specialization,
                    status: data.status,
                    title: data.title,
                    user: data.user,
                },
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    prepareFooterItems() {
        const {viewMode} = this.props;
        return [
            {
                label: 'Odrzuć',
                className: 'button cancel-button gray',
                onClick: this.openRejectDialog,
                rendered: viewMode && this.state.element.status !== 'REJECTED' && this.state.element.status !== 'CONFIRMED',
            },
            {
                label: 'Zaakceptuj termin',
                className: 'float-right',
                onClick: this.handleFormSubmit,
                rendered: viewMode && this.state.element.status !== 'CONFIRMED' && this.state.element.status !== 'REJECTED',
            },
        ];
    }

    openRejectDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, dialogViewMode: 'EDIT'});
    }
    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: false}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
            if (callback) callback();
        });
    }
    getBackLabel() {
        return 'Wróć do listy';
    }

    getUpdateSucces() {
        return 'Konsultacja została zatwierdzona';
    }

    getRemoveSucces() {
        return 'Konsultacja została odrzucona';
    }

    getContainerListName() {
        return 'consultation-list';
    }

    handleFormSubmit(event) {
        const {element} = this.state;
        if (this.validator.allValid()) {
            element.status = 'CONFIRMED';
            this.service
                .update(element)
                .then((response) => {
                    this.afterUpdateSuccess(response);
                })
                .catch((err) => {
                    this.showErrorMessage(err.message, 10000);
                });
        } else {
            this.validator.showMessages();
            this.scrollToError = true;
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/consultation-list' className='p-link'>
                    {'Konsultacje'}
                </BreadcrumbsItem>
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                {this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/consultation/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
                {this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/consultation/edit'>{'Edycja'}</BreadcrumbsItem> : null}
                {this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/consultation/create'>{'Nowy'}</BreadcrumbsItem> : null}
                {this.renderView()}
            </div>
        );
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    breadCrumbItems() {
        return [
            {name: 'Propozycje terminów', url: '/#/consultation-list'},
            {name: 'Propozycja', url: `/#/consultation/details/${this.state.element?.id}`},
        ];
    }
    renderDetails() {
        const {t} = this.props;
        const {user} = this.state.element;
        return (
            <React.Fragment>
                {this.renderProperStructureForDetail(
                    <>
                        <InputTextComponent
                            id='title'
                            name='title'
                            label={'Użytkownik'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            value={this.state.element.title}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        <InputTextComponent
                            id='email'
                            name='email'
                            label={'Email'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            value={user?.email}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />

                        <InputNumberComponent
                            id='chatFee'
                            name='chatFee'
                            label={'Cena za czat'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            locale='pl-PL'
                            currency='PLN'
                            mode='currency'
                            value={user?.chatFee}
                            validator={this.validator}
                            validators='required|numeric|max:4'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />

                        <InputNumberComponent
                            id='viedoChatFee'
                            name='viedoChatFee'
                            label={'Cena za wideo czat'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            locale='pl-PL'
                            currency='PLN'
                            mode='currency'
                            value={user?.videoChatFee}
                            validator={this.validator}
                            validators='required|numeric|max:4'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />

                        <InputNumberComponent
                            id='mailingFee'
                            name='mailingFee'
                            label={'Cena za mailing'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            locale='pl-PL'
                            currency='PLN'
                            mode='currency'
                            value={user?.mailingFee}
                            validator={this.validator}
                            validators='required|numeric|max:4'
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />

                        <InputTextComponent
                            id='start'
                            name='start'
                            label={'Początek konsultacji'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            value={moment(this.state.element.start).format('DD-MM-YYYY HH:mm').toString()}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        <InputTextComponent
                            id='end'
                            name='end'
                            label={'Koniec konsultacji'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            value={moment(this.state.element.end).format('DD-MM-YYYY HH:mm').toString()}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        <InputTextComponent
                            id='status'
                            name='status'
                            label={'Status'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            value={t(`ConsultationStatus.${this.state.element.status}`)}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                        />
                        {this.state.dialogVisibile ? (
                            <ConsultationDialog
                                key={`dialog-${this.state.element?.title}`}
                                elementId={this.state.element.id}
                                onHide={(e) => {
                                    this.handleCloseDialog(e);
                                }}
                                visible={this.state.dialogVisibile}
                                viewMode={this.state.dialogViewMode}
                                afterSave={(e) =>
                                    this.handleCloseDialog(e, () => {
                                        this.readMessage();
                                        this.refreshTable();
                                        this.unblockUi();
                                    })
                                }
                                blockUi={this.blockUi}
                                unblockUi={this.unblockUi}
                                _isMounted={this._isMounted}
                                className='consultation-proposal-dialog'
                            />
                        ) : null}
                    </>
                )}
            </React.Fragment>
        );
    }
}

ConsultationContainer.defaultProps = {
    backUrl: '/#/consultation-list',
    cancelUrl: '/#/consultation/',
    editUrl: '/#/consultation/edit',
    detailsUrl: '/#/consultation/details',
    lazy: true,
};

ConsultationContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withTranslation()(ConsultationContainer);
