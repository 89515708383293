import PropTypes from 'prop-types';
import React from 'react';
import AppPrefixUtils from '../utils/AppPrefixUtils';

export const AgreementConfiguratorTemplate = (props) => {
    const {element, editClickEvent} = props;

    const renderElement = (element) => {
        console.log(element);
        return (
            <div className='configurator-template-list-view'>
                <div className='row'>
                    <div className='name                  col-lg-3 col-sm-12'>{element?.name}</div>
                    <div className='id                    col-lg-1 col-sm-12'>ID:[{element?.id}] </div>
                    <div className='categoryName          col-lg-2 col-sm-12'>{element?.category?.name} </div>
                    <div className='availability                      col-lg-6 col-sm-12'>{statuImageSelector(element?.availability)}</div>
                </div>
                <div className='footer'>
                    <div className='row '>
                        <div className='description   ml-2 col-12'>{element?.description}</div>
                    </div>
                    <div className='row mt-4'>
                        <div className='price   ml-2 col-6'>Cena zakupu: {element?.price} zł</div>
                    </div>
                </div>
            </div>
        );
    };

    const renderDivAvailabilityBlock = (srcImageLexDocS, srcImageLeksy) => {
        const classNameImages = 'text-end ';
        return (
            <div>
                <div className={classNameImages}>
                    LexDocS <img alt='' src={srcImageLexDocS} />
                </div>
                <div className={classNameImages}>
                    Leksy <img alt='' src={srcImageLeksy} />
                </div>
            </div>
        );
    };

    const statuImageSelector = (availability) => {
        const link = AppPrefixUtils.locationHrefUrl('/images/lexdocs/');
        const checked = link + 'done_green.svg';
        const unChecked = link + 'close.svg';
        switch (availability) {
            case 'FULL':
                return renderDivAvailabilityBlock(checked, checked);
            case 'LEKSY':
                return renderDivAvailabilityBlock(checked, unChecked);
            default:
                return renderDivAvailabilityBlock(unChecked, checked);
        }
    };

    React.useEffect(() => {}, []);
    return <div className='container-fluid'>{renderElement(element)}</div>;
};

AgreementConfiguratorTemplate.defaultProps = {};

AgreementConfiguratorTemplate.propTypes = {
    content: PropTypes.object,
};

export default React.memo(AgreementConfiguratorTemplate);
