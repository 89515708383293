import classNames from 'classnames';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from 'primereact/dropdown';
import {Ripple} from 'primereact/ripple';
import PropTypes from 'prop-types';
import React from 'react';
import {v4 as uuidv4} from 'uuid';
import ActionButton from './ActionButton';
import ActionLink from './ActionLink';
import BaseInputComponent from './inputs/BaseInputComponent';
import InputAutoCompleteComponent from './inputs/InputAutocompleteComponent';
import InputCalendarComponent from './inputs/InputCalendarComponent';
import InputCheckboxComponent from './inputs/InputCheckboxComponent';
import InputDropdownComponent from './inputs/InputDropdownComponent';
import InputFileUploadComponent from './inputs/InputFileUploadComponent';
import InputNumberComponent from './inputs/InputNumberComponent';
import InputPasswordComponent from './inputs/InputPasswordComponent';
import InputTextareaComponent from './inputs/InputTextareaComponent';
import InputTextComponent from './inputs/InputTextComponent';
import HandleChangeUtils from './utils/HandleChangeUtils';
import AppPrefixUtils from '../utils/AppPrefixUtils';

class EditableDataTableExt extends BaseInputComponent {
    constructor(props, context) {
        super(props, context);
        this.handlePage = this.handlePage.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
        this.onAfterAddElement = this.onAfterAddElement.bind(this);
        this.onAfterEditElement = this.onAfterEditElement.bind(this);
        this.onAfterRemoveElement = this.onAfterRemoveElement.bind(this);
        this.getPageLinkSize = this.getPageLinkSize.bind(this);
        this.renderPagination = this.renderPagination.bind(this);
        this.handlePreRemove = this.handlePreRemove.bind(this);
        this.state = {
            addDialogVisible: false,
            list: [],
            addElement: {
                value: '',
                expiryDate: undefined,
            },
            loading: true,
            size: props.lazy ? 0 : props.value ? props.value.length : 0,
            first: 0,
            criteria: {
                firstResult: 0,
                maxResults: props.defaultRowsCount,
                sortField: 'id',
                sortAsc: true,
            },
        };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.lazy && prevState.size !== this.props.value.length) {
            if (prevState.size + 1 === this.props.value.length) {
                const page = Math.ceil(this.props.value.length / this.state.criteria.maxResults) - 1;
                const first = page * this.state.criteria.maxResults;
                this.setState((prevState) => ({
                    size: this.props.value.length,
                    first: first,
                    criteria: {
                        ...prevState.criteria,
                        firstResult: first,
                    },
                }));
            } else if (prevState.size - 1 === this.props.value.length) {
                this.setState({size: this.props.value.length});
            } else {
                this.setState({size: this.props.value.length});
            }
        }
    }

    init() {
        this.setState(
            {
                loading: true,
            },
            () => this.refreshTable()
        );
    }

    handleChangeScSetState(criteria, filters, refreshFromBackend, onAfterStateChange) {
        this.setState({criteria, filters, loading: refreshFromBackend}, () => {
            if (refreshFromBackend) {
                this.refreshTable();
            }
            if (onAfterStateChange) {
                onAfterStateChange();
            }
        });
    }

    handleChangeSc(inputType, parameters, event, matchMode, refreshFromBackend, onAfterStateChange) {
        HandleChangeUtils.handleChangeSc(
            this,
            inputType,
            parameters,
            event,
            matchMode,
            refreshFromBackend,
            onAfterStateChange,
            this.handleChangeScSetState,
            this.state
        );
    }

    onAfterAddElement(lastPage) {
        const {lazy, onAfterAddElement} = this.props;
        const {criteria} = this.state;
        if (lazy) {
            // go to last page
            this.handlePage({
                rows: criteria.maxResults,
                first: lastPage * criteria.maxResults,
                page: lastPage,
                type: 'ADD',
            });
        } else {
            this.setState({
                size: this.props.value.length,
            });
        }
        if (onAfterAddElement !== undefined && onAfterAddElement instanceof Function) {
            onAfterAddElement();
        }
    }

    onAfterEditElement() {
        const {lazy, onAfterEditElement} = this.props;
        if (lazy) {
            // this.refreshTable();
        }
        if (onAfterEditElement !== undefined && onAfterEditElement instanceof Function) {
            onAfterEditElement();
        }
    }

    onAfterRemoveElement(refreshTable, deletedRowData) {
        const {lazy, onAfterRemoveElement} = this.props;
        const {criteria, first, size} = this.state;
        if (lazy) {
            if (first >= size - 1 && size > 0) {
                const lastPage = Math.ceil(size - 1 / criteria.maxResults) - 1;
                this.handlePage({
                    rows: criteria.maxResults,
                    first: lastPage * criteria.maxResults,
                    page: lastPage,
                    type: 'REMOVE',
                });
            } else if (refreshTable) {
                this.refreshTable();
            }
        } else {
            this.setState({
                size: this.props.value.length,
            });
        }
        if (onAfterRemoveElement !== undefined && onAfterRemoveElement instanceof Function) {
            onAfterRemoveElement(deletedRowData);
        }
    }

    handleAddElement() {
        const {addedListName, createNewElement, dataKey, lazy, name, onChange, stateField, handleAddElement} = this.props;
        if (handleAddElement) {
            handleAddElement();
        } else {
            const {criteria, size} = this.state;
            const value = createNewElement();
            if (value[dataKey] === undefined) {
                value[dataKey] = uuidv4();
            }
            let computedName = name;
            if (lazy) {
                computedName = addedListName;
            }
            const page = Math.floor(size / criteria.maxResults);
            onChange('EDITABLE_DATA_TABLE', ['ADD'], {name: computedName, value}, this.onAfterAddElement(page), stateField);
        }
    }
    handlePreRemove(rowData, deleted, permanent) {
        if (this.props.beforeRemoveElement) {
            this.props.beforeRemoveElement(rowData, deleted, permanent, () => {
                this.handleRemoveElement(rowData, deleted, permanent);
            });
        } else {
            this.handleRemoveElement(rowData, deleted, permanent);
        }
    }

    handleRemoveElement(rowData, deleted, permanent) {
        const {addedList, addedListName, dataKey, lazy, modifiedListName, name, onChange, stateField} = this.props;
        const {criteria, size} = this.state;
        if (lazy) {
            let found = false;
            let indexOfRemoved = -1;
            if (addedList !== undefined && addedList.length > 0) {
                addedList.forEach((el, i) => {
                    if (el[dataKey] === rowData[dataKey]) {
                        found = true;
                        indexOfRemoved = i;
                    }
                });
            }
            if (found) {
                const fromDbSize = size - addedList.length;
                const addedElementFirstPage = (fromDbSize % criteria.maxResults) + 1;
                let firstIndex = criteria.maxResults - addedElementFirstPage + 1;
                for (let i = criteria.maxResults - addedElementFirstPage; i < indexOfRemoved; i += criteria.maxResults) {
                    firstIndex = i;
                }
                for (let i = firstIndex; i < addedList.length; i += criteria.maxResults) {
                    addedList[i].page -= 1;
                }
                onChange(
                    'EDITABLE_DATA_TABLE',
                    ['REMOVE', rowData, dataKey, deleted, true],
                    {name: addedListName},
                    this.onAfterRemoveElement(true, rowData),
                    stateField
                );
            } else {
                onChange(
                    'EDITABLE_DATA_TABLE',
                    ['REMOVE_DB', rowData, dataKey, deleted, false],
                    {name: modifiedListName},
                    this.onAfterRemoveElement(false, rowData),
                    stateField
                );
            }
        } else {
            onChange(
                'EDITABLE_DATA_TABLE',
                ['REMOVE', rowData, dataKey, deleted, permanent],
                {name},
                this.onAfterRemoveElement(false, rowData),
                stateField
            );
        }
    }

    handleChange(name, rowData, field, afterEditCol, inputType, parameters, event, onAfterStateChange, stateField) {
        const {addedList, addedListName, dataKey, lazy, modifiedListName, onChange} = this.props;
        const {criteria} = this.state;
        if (lazy) {
            let found = false;
            if (addedList !== undefined && addedList.length > 0) {
                addedList.forEach((el) => {
                    if (el[dataKey] === rowData[dataKey]) {
                        found = true;
                    }
                });
            }
            if (found) {
                onChange(
                    'EDITABLE_DATA_TABLE',
                    ['EDIT', rowData, dataKey, inputType, parameters, event, criteria.page],
                    {name: addedListName},
                    afterEditCol ? afterEditCol(rowData, event) : this.onAfterEditElement,
                    stateField
                );
            } else {
                onChange(
                    'EDITABLE_DATA_TABLE',
                    ['EDIT_DB', rowData, dataKey, inputType, parameters, event, criteria.page],
                    {name: modifiedListName},
                    afterEditCol ? afterEditCol(rowData, event) : this.onAfterEditElement,
                    stateField
                );
            }
        } else {
            onChange(
                'EDITABLE_DATA_TABLE',
                ['EDIT', rowData, dataKey, inputType, parameters, event],
                {name},
                afterEditCol ? afterEditCol(rowData, event) : onAfterStateChange ? onAfterStateChange : this.onAfterEditElement,
                stateField
            );
        }
    }

    inputText(editableFunction, field, header, placeholder, validators, rowData) {
        const {crossFieldValidation, dataKey, editable, name, stateField, validator, viewMode, editOnlyNew} = this.props;
        const id = field + rowData[dataKey];
        if (crossFieldValidation) {
            const crossValidators = crossFieldValidation(field, rowData);
            if (crossValidators) {
                validators += `|${crossValidators}`;
            }
        }

        return (
            <InputTextComponent
                id={id}
                name={field}
                label={header}
                value={rowData[field]}
                placeholder={placeholder}
                showLabel={false}
                insideTable
                rendered
                validator={validator}
                validators={validators}
                onChange={this.handleChange.bind(this, name, rowData, field, undefined)}
                stateField={stateField}
                //edytowanie tylko nowych jeżeli editOnlyNew = true
                viewMode={
                    editOnlyNew ? (rowData.id === undefined ? 'EDIT' : 'VIEW') : editableFunction(rowData, editable) ? viewMode : 'VIEW'
                }
            />
        );
    }

    inputNumber(editableFunction, field, header, placeholder, validators, afterEditCol, rowData) {
        const {crossFieldValidation, dataKey, editable, name, stateField, validator, viewMode, editOnlyNew} = this.props;
        const id = field + rowData[dataKey];
        if (crossFieldValidation) {
            const crossValidators = crossFieldValidation(field, rowData);
            if (crossValidators) {
                validators += `|${crossValidators}`;
            }
        }

        return (
            <InputNumberComponent
                id={id}
                name={field}
                label={header}
                value={rowData[field]}
                placeholder={placeholder}
                showLabel={false}
                insideTable
                rendered
                validator={validator}
                validators={validators}
                onChange={this.handleChange.bind(this, name, rowData, field, afterEditCol)}
                stateField={stateField}
                //edytowanie tylko nowych jeżeli editOnlyNew = true
                viewMode={
                    editOnlyNew ? (rowData.id === undefined ? 'EDIT' : 'VIEW') : editableFunction(rowData, editable) ? viewMode : 'VIEW'
                }
                min={0}
                inputStyle={{width: '100%'}}
            />
        );
    }

    inputCheckbox(disabled, editableFunction, field, header, placeholder, validators, rowData) {
        const {crossFieldValidation, dataKey, editable, name, stateField, validator, viewMode, editOnlyNew} = this.props;
        const id = field + rowData[dataKey];
        if (crossFieldValidation) {
            const crossValidators = crossFieldValidation(field, rowData);
            if (crossValidators) {
                validators += `|${crossValidators}`;
            }
        }

        return (
            <InputCheckboxComponent
                id={id}
                name={field}
                label={header}
                value={rowData[field]}
                insideTable
                rendered
                showLabel={false}
                validator={validator}
                validators={validators}
                onChange={this.handleChange.bind(this, name, rowData, field, undefined)}
                stateField={stateField}
                //edytowanie tylko nowych jeżeli editOnlyNew = true
                viewMode={
                    editOnlyNew ? (rowData.id === undefined ? 'EDIT' : 'VIEW') : editableFunction(rowData, editable) ? viewMode : 'VIEW'
                }
                disabled={disabled}
            />
        );
    }

    inputPassword(editableFunction, field, header, placeholder, validators, rowData) {
        const {crossFieldValidation, dataKey, editable, name, stateField, validator, viewMode, editOnlyNew} = this.props;
        const id = field + rowData[dataKey];
        if (crossFieldValidation) {
            const crossValidators = crossFieldValidation(field, rowData);
            if (crossValidators) {
                validators += `|${crossValidators}`;
            }
        }

        return (
            <InputPasswordComponent
                id={id}
                name={field}
                label={header}
                value={rowData[field]}
                placeholder={placeholder}
                showLabel={false}
                insideTable
                rendered
                feedback={false}
                validator={validator}
                validators={validators}
                onChange={this.handleChange.bind(this, name, rowData, field, undefined)}
                stateField={stateField}
                //edytowanie tylko nowych jeżeli editOnlyNew = true
                viewMode={
                    editOnlyNew ? (rowData.id === undefined ? 'EDIT' : 'VIEW') : editableFunction(rowData, editable) ? viewMode : 'VIEW'
                }
            />
        );
    }

    inputTextArea(editableFunction, field, header, placeholder, validators, rowData) {
        const {crossFieldValidation, dataKey, editable, name, stateField, validator, viewMode} = this.props;
        const id = field + rowData[dataKey];
        if (crossFieldValidation) {
            const crossValidators = crossFieldValidation(field, rowData);
            if (crossValidators) {
                validators += `|${crossValidators}`;
            }
        }
        return (
            <InputTextareaComponent
                id={id}
                name={field}
                label={header}
                value={rowData[field]}
                placeholder={placeholder}
                showLabel={false}
                insideTable
                rendered
                validator={validator}
                validators={validators}
                onChange={this.handleChange.bind(this, name, rowData, field, undefined)}
                stateField={stateField}
                viewMode={editableFunction(rowData, editable) ? viewMode : 'VIEW'}
                autoResize
            />
        );
    }

    inputCalendar(editableFunction, field, header, validators, rowData) {
        const {crossFieldValidation, dataKey, editable, name, stateField, validator, viewMode} = this.props;
        const id = field + rowData[dataKey];
        if (crossFieldValidation) {
            const crossValidators = crossFieldValidation(field, rowData);
            if (crossValidators) {
                validators += `|${crossValidators}`;
            }
        }
        return (
            <InputCalendarComponent
                id={id}
                name={field}
                label={header}
                value={rowData[field]}
                showLabel={false}
                insideTable
                rendered
                validator={validator}
                validators={validators}
                onChange={this.handleChange.bind(this, name, rowData, field, undefined)}
                stateField={stateField}
                viewMode={editableFunction(rowData, editable) ? viewMode : 'VIEW'}
            />
        );
    }

    inputDropdown(
        renderTextField,
        textField,
        filter,
        editableFunction,
        field,
        header,
        placeholder,
        optionLabel,
        dataKeyDropdown,
        options,
        validators,
        filterBy,
        rowData
    ) {
        const {crossFieldValidation, dataKey, editable, name, stateField, validator, viewMode} = this.props;
        const id = field + rowData[dataKey];
        if (crossFieldValidation) {
            const crossValidators = crossFieldValidation(field, rowData);
            if (crossValidators) {
                validators += `|${crossValidators}`;
            }
        }

        const additionalTextField = renderTextField && renderTextField(rowData[field]);
        return (
            <React.Fragment>
                <div className='row'>
                    <div className={additionalTextField ? 'col-md-6' : 'col-md-12'}>
                        <InputDropdownComponent
                            id={id}
                            name={field}
                            label={header}
                            value={rowData[field]}
                            placeholder={placeholder}
                            showLabel={false}
                            insideTable
                            rendered
                            validator={validator}
                            validators={validators}
                            optionLabel={optionLabel}
                            dataKey={dataKeyDropdown}
                            options={options}
                            showClear={rowData[field] ? true : false}
                            onChange={this.handleChange.bind(this, name, rowData, field, undefined)}
                            stateField={stateField}
                            viewMode={editableFunction(rowData, editable) ? viewMode : 'VIEW'}
                            colClass='col-6'
                            filter={filter}
                            filterBy={filterBy}
                        />
                    </div>
                    {additionalTextField ? (
                        <div className={additionalTextField ? 'col-md-6' : 'col-md-12'}>
                            <InputTextComponent
                                name={textField}
                                label={header}
                                showLabel={false}
                                textFieldName
                                colClass='col-md-6'
                                value={rowData[textField]}
                                stateField={`${stateField}`}
                                viewMode={editableFunction(rowData, editable) ? viewMode : 'VIEW'}
                                onChange={this.handleChange.bind(this, name, rowData, textField, undefined)}
                                validator={validator}
                                validators={validators}
                                insideTable
                            />
                        </div>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }

    fileUploadEditor(editableFunction, field, fileList, header, restApiUrl, itemLabel, itemName, validators, viewMode, rowData) {
        const {dataKey, editable, messages, name, stateField, validator} = this.props;
        const id = field + rowData[dataKey];
        return (
            <InputFileUploadComponent
                id={id}
                name={field}
                label={header}
                fileList={rowData[fileList]}
                showLabel={false}
                insideTable
                restApiUrl={restApiUrl}
                multiple
                maxFileSize={1000000}
                // handleUpload={this.onUpload.bind(this, rowData, fileList, onUpload)}
                // handleRemove={this.onRemove.bind(this, rowData, fileList, onRemove)}
                onChange={this.handleChange.bind(this, name, rowData, field, undefined)}
                stateField={stateField}
                itemLabel={itemLabel} // 'originalFileName'
                itemName={itemName} // 'fileName'
                rendered
                validator={validator}
                validators={validators}
                viewMode={editableFunction(rowData, editable) ? viewMode : 'VIEW'}
                messages={messages}
            />
        );
    }

    inputAutoComplete(editableFunction, field, header, placeholder, validators, rowData, stateField, onChange) {
        const {crossFieldValidation, dataKey, editable, name, validator, viewMode} = this.props;
        const id = field + rowData[dataKey];
        if (crossFieldValidation) {
            const crossValidators = crossFieldValidation(field, rowData);
            if (crossValidators) {
                validators += `|${crossValidators}`;
            }
        }

        return (
            <InputAutoCompleteComponent
                id={id}
                name={field}
                label={header}
                value={rowData[field]}
                placeholder={placeholder}
                showLabel={false}
                insideTable
                rendered
                validator={validator}
                validators={validators}
                onChange={
                    onChange ? onChange.bind(this.name, rowData, field) : this.handleChange.bind(this, name, rowData, field, undefined)
                }
                stateField={stateField}
                viewMode={editableFunction(rowData, editable) ? viewMode : 'VIEW'}
                colClass='col-6'
            />
        );
    }

    onUpload(rowData, varName, onUpload, event) {
        const varValue = JSON.parse(event.xhr.response);
        const modifiedList = rowData[varName].concat(varValue);
        onUpload(modifiedList, rowData, varName);
    }

    onRemove(rowData, varName, onRemove, e, i) {
        const modifiedList = rowData[varName];
        modifiedList.splice(i, 1);
        onRemove(modifiedList, rowData, varName);
    }

    detailsTemplate(rowData) {
        return (
            <ActionLink
            className='p-link float-right'
            handleClick={(e) => {
                e.preventDefault();
                this.props.showDetails(rowData);
            }}
            variant='blue'
            iconName='mdi-eye'
            iconSide='right'
            iconSize='lg'
            iconColor='blue'
        />
        );
    }
    actionTemplate(object) {
        const {
            editable,
            permanentDelete,
            showDeleteButtonFunction,
            showDeleteButtonFunctionLabel,
            showDeleteButtonOnlyNew,
            deleteButtonClassName,
            customItemDeleteSrc,
            showDeleteButtonLabel,
            showDetailsButton,
            showDeleteButton,
        } = this.props;
        const rowData = object;
        if ((showDeleteButtonOnlyNew && rowData.id === undefined) || !showDeleteButtonOnlyNew) {
            if (!!showDeleteButtonFunction && showDeleteButtonFunction(editable, rowData)) {
                return <span>{showDeleteButtonFunctionLabel}</span>;
            }
            if (rowData.deleted) {
                return (
                    <React.Fragment>
                        {showDeleteButton ? (
                            <ActionLink
                                label={showDeleteButtonLabel ? 'COFNIJ' : ''}
                                handleClick={(e) => {
                                    e.preventDefault();
                                    this.handlePreRemove(rowData, false, false);
                                }}
                                key={'remove-cancel-button'}
                                className={deleteButtonClassName}
                            />
                        ) : null}{' '}
                        <span></span>
                        {showDetailsButton ? this.detailsTemplate(rowData) : null}
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        {showDeleteButton ? (
                            !this.islexDocsIconDelete() ? (
                                <ActionLink
                                    label={showDeleteButtonLabel ? 'USUŃ' : ''}
                                    handleClick={(e) => {
                                        e.preventDefault();
                                        this.handlePreRemove(rowData, true, permanentDelete);
                                    }}
                                    key={'remove-button'}
                                    className={deleteButtonClassName}
                                />
                            ) : (
                                <img
                                    src={customItemDeleteSrc}
                                    title='Usuń'
                                    alt=''
                                    className='cursor-pointer'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.handlePreRemove(rowData, true, permanentDelete);
                                    }}
                                />
                            )
                        ) : null}
                        <span></span>
                        {showDetailsButton ? this.detailsTemplate(rowData) : null}
                    </React.Fragment>
                );
            }
        }
    }

    islexDocsIconDelete() {
        return this.props?.customItemDeleteSrc !== undefined;
    }

    isEditable(editable) {
        return editable;
    }

    refreshTable() {
        const {dataKey, getList, lazy, modifiedList, objectId, updateItemElementAfterGetList, updateItemElementAfterEdit, value} =
            this.props;
        const {criteria} = this.state;
        if (lazy) {
            if (objectId !== null && objectId !== undefined) {
                getList(objectId, criteria).then((data) => {
                    if (data.content !== undefined) {
                        data.content.forEach((element) => {
                            if (updateItemElementAfterGetList) updateItemElementAfterGetList(element);
                            if (modifiedList.get(element[dataKey]) !== null && modifiedList.get(element[dataKey]) !== undefined) {
                                updateItemElementAfterEdit(element, modifiedList.get(element[dataKey]));
                            }
                        });
                    }
                    this.updateList(data.content, data.totalElements);
                });
            } else {
                this.updateList([], 0);
            }
        } else {
            this.updateList(value, value.length);
        }
    }

    updateList(contentList, totalElements) {
        const {addedList, lazy, value} = this.props;
        const {criteria} = this.state;
        const page = criteria.firstResult / criteria.maxResults + 1;
        const maxResults = criteria.maxResults;
        if (contentList === undefined) {
            contentList = [];
        }
        if (page * maxResults > totalElements) {
            if (page * maxResults - totalElements > maxResults) {
                contentList = contentList.concat(
                    addedList.slice(page * maxResults - totalElements - maxResults, maxResults * page - totalElements)
                );
            } else {
                contentList = contentList.concat(addedList.slice(0, page * maxResults - totalElements));
            }
        }
        if (lazy) {
            this.setState({
                loading: false,
                list: contentList,
                size: totalElements + addedList.length,
            });
        } else {
            this.setState({
                loading: false,
                size: value.length,
            });
        }
    }

    onSort(event) {
        this.setState(
            (prevState) => ({
                loading: true,
                criteria: {
                    ...prevState.criteria,
                    sortField: event.sortField,
                    sortAsc: event.sortOrder > 0 ? true : false,
                },
            }),
            () => this.refreshTable()
        );
    }

    getPageLinkSize() {
        let totalPages = this.state.size / this.state.criteria.maxResults;
        let currentPage = this.state.first / this.state.criteria.maxResults;
        if (totalPages <= 5) {
            return 5;
        } else if (totalPages === 6 || currentPage < 3 || currentPage >= totalPages - 3) {
            return 6;
        } else {
            return 7;
        }
    }

    renderPagination(rowsPerPageOptions) {
        return (
            <div className='p-paginator-rows-per-page'>
                <Dropdown
                    aria-label={'Pokaż na stronie'}
                    key={'maxResults'}
                    id={'maxResults'}
                    inputId={'maxResults-input'}
                    name={'maxResults'}
                    //style={{ width: '100%' }}
                    className='dropdown-paginator'
                    value={this.state.criteria.maxResults}
                    options={rowsPerPageOptions}
                    onChange={(e) =>
                        this.setState(
                            (prevState) => ({
                                criteria: {
                                    ...prevState.criteria,
                                    maxResults: e.target.value,
                                },
                            }),
                            () => this.handleChangeLimitObj(e)
                        )
                    }
                    filter={false}
                    showClear={false}
                />
                <label id={'limit-label-id'} className='p-paginator-rows-per-page-label' htmlFor={'limit-input'}>
                    {this.translate('list.paginator')}
                </label>
            </div>
        );
    }

    handleChangeLimitObj(v) {
        this.setState(
            (prevState) => ({
                first: 0,
                criteria: {
                    ...prevState.criteria,
                    firstResult: 0,
                    maxResults: v.value,
                    maxResultsObj: {value: v.value},
                },
            }),
            () => (this.props.lazy ? this.refreshTable() : null)
        );
    }

    handlePage(event) {
        const {lazy} = this.props;
        const {criteria, size} = this.state;
        let newSize = size;
        if (event.type === 'ADD') {
            newSize += 1;
        } else if (event.type === 'REMOVE') {
            newSize -= 1;
        }
        const maxPage = Math.ceil(newSize / criteria.maxResults) - (lazy ? 1 : 0);
        if (event.page === undefined || event.page === null) {
            event.page = event.first / event.rows;
        }
        let page = event.page;
        if (maxPage < event.page) {
            page = maxPage;
        }
        if (lazy) {
            this.setState(
                (prevState) => ({
                    loading: true,
                    first: event.first,
                    criteria: {
                        ...prevState.criteria,
                        firstResult: page * event.rows,
                        maxResults: event.rows,
                    },
                }),
                () => this.refreshTable()
            );
        } else {
            this.setState((prevState) => ({
                loading: true,
                first: event.first,
                criteria: {
                    ...prevState.criteria,
                    firstResult: page * event.rows,
                    maxResults: event.rows,
                },
            }));
        }
    }
    rowClassName = (data) => {
        return this.props.rowClassName ? this.props.rowClassName(data) : '';
    };

    translate(key) {
        if (this.props.handleTranslate) {
            return this.props.handleTranslate(key);
        } else {
            return key;
        }
    }

    render() {
        const {
            addButtonDisabled,
            addButtonClassName,
            addButtonIconColor,
            addButtonIconName,
            addButtonIconSize,
            addButtonIconSvg,
            addButtonLabel,
            addButtonSize,
            addButtonVariant,
            breakpoint,
            className,
            columns,
            selectionDataKey,
            editable,
            handleSelectionChange,
            headerColumnGroup,
            id,
            label,
            lazy,
            paginator,
            publicMode,
            rendered,
            selectable,
            selection,
            selectionMode,
            showAddButton,
            showDeleteButton,
            showDetailsButton,
            showLabel,
            tableClassName,
            value,
            validator,
            validators,
            customButton,
            customButtonLabel,
            handleCustomButton,
            customButtonClassName,
        } = this.props;
        let columnsWithAction = columns;
        if (selectionMode === 'multiple') {
            columnsWithAction = [
                {
                    selectionMode: 'multiple',
                },
                ...columnsWithAction,
            ];
        }
        if (showDeleteButton || showDetailsButton) {
            columnsWithAction = [
                ...columnsWithAction,
                {
                    key: 'actions',
                    header: 'Akcje',
                    body: this.actionTemplate.bind(this),
                    width: '100px',
                },
            ];
        }
        const dynamicColumns = columnsWithAction.map((col) => {
            let editableFunc;
            if (col.editable && col.editable instanceof Function) {
                editableFunc = col.editable.bind(this, editable);
            } else {
                editableFunc = this.isEditable.bind(this, editable);
            }
            let body;
            if (col.editor === 'TEXT') {
                body = this.inputText.bind(this, editableFunc, col.field, col.header, col.placeholder, col.validators);
            } else if (col.editor === 'PASSWORD') {
                body = this.inputPassword.bind(this, editableFunc, col.field, col.header, col.placeholder, col.validators);
            } else if (col.editor === 'NUMBER') {
                body = this.inputNumber.bind(this, editableFunc, col.field, col.header, col.placeholder, col.validators, col.afterEditCol);
            } else if (col.editor === 'CHECKBOX') {
                body = this.inputCheckbox.bind(this, col.disabled, editableFunc, col.field, col.header, col.placeholder, col.validators);
            } else if (col.editor === 'TEXTAREA') {
                body = this.inputTextArea.bind(this, editableFunc, col.field, col.header, col.placeholder, col.validators);
            } else if (col.editor === 'CALENDAR') {
                body = this.inputCalendar.bind(this, editableFunc, col.field, col.header, col.validators);
            } else if (col.editor === 'DROPDOWN') {
                body = this.inputDropdown.bind(
                    this,
                    col.renderTextField,
                    col.textFieldName,
                    col.filter,
                    editableFunc,
                    col.field,
                    col.header,
                    col.placeholder,
                    col.optionLabel,
                    col.dataKey,
                    col.options,
                    col.validators,
                    col.filterBy
                );
            } else if (col.editor === 'FILEULOAD') {
                body = this.fileUploadEditor.bind(
                    this,
                    editableFunc,
                    col.field,
                    col.fileList,
                    col.header,
                    col.restApiUrl,
                    col.itemLabel,
                    col.itemName,
                    col.validators,
                    editable ? 'EDIT' : 'VIEW'
                );
                col.colClassName = 'file-upload-column';
            } else if (col.editor === 'AUTOCOMPLETE') {
                body = this.inputAutoComplete.bind(
                    this,
                    editableFunc,
                    col.field,
                    col.header,
                    col.placeholder,
                    col.validators,
                    col.filterList,
                    col.filteredList,
                    col.stateField,
                    col.onChange
                );
            } else if (col.selectionMode === 'multiple') {
                return <Column key='selection-column' selectionMode='multiple' headerStyle={{width: '3em'}}></Column>;
            } else if (col.body instanceof Function) {
                body = col.body; // .bind(this, editableFunc);
            } else {
                body = col.body;
            }
            return (
                <Column
                    key={col.key ? col.key : col.field}
                    field={col.field}
                    header={col.header}
                    body={body}
                    className={`${col.className} ${col.colClassName}`}
                    sortable={col.sortable}
                    style={col.width !== undefined ? {width: col.width} : null}
                />
            );
        });
        const rowsPerPageOptions = [5, 10, 20, 50, 100];
        const {criteria, first, list, loading, size} = this.state;
        if (rendered && !lazy) {
            return (
                <div className={className}>
                    {showLabel ? (
                        <label className='p-label' htmlFor={id} style={{width: '100%'}}>
                            {label}
                        </label>
                    ) : null}
                    <div className={tableClassName}>
                        <DataTable
                            id={id}
                            key='data-table'
                            emptyMessage='Brak rekordów do wyświetlenia'
                            className='p-datatable-responsive'
                            // responsive
                            breakpoint={breakpoint}
                            value={value}
                            paginator={paginator}
                            filters={this.state.filters}
                            rows={paginator ? criteria.maxResults : undefined}
                            totalRecords={value ? value.length : 0}
                            first={paginator ? first : 0}
                            onPage={this.handlePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            loading={this.props.loading}
                            paginatorPosition='bottom'
                            currentPageReportTemplate={
                                size !== 0
                                    ? `Pozycje od ${first + 1} do ${
                                          first + criteria.maxResults > size ? size : first + criteria.maxResults
                                      } z ${size} łącznie`
                                    : '0 pozycji'
                            }
                            paginatorTemplate={{
                                layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
                                PrevPageLink: (options) => {
                                    if (criteria.firstResult === 0) {
                                        return null;
                                    } else {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={options.onClick}
                                                disabled={options.disabled}
                                            >
                                                <span className='p-paginator-icon pi pi-angle-left'></span>
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                                NextPageLink: (options) => {
                                    if (criteria.firstResult + criteria.maxResults >= size) {
                                        return null;
                                    } else {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={options.onClick}
                                                disabled={options.disabled}
                                            >
                                                <span className='p-paginator-icon pi pi-angle-right'></span>
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                                RowsPerPageDropdown: (options) => {
                                    return this.renderPagination(rowsPerPageOptions);
                                },
                                CurrentPageReport: (options) => {
                                    return <div className='p-paginator-dummy' />;
                                },
                                PageLinks: (options) => {
                                    if (options.view.startPage === options.page && options.view.startPage !== 0) {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={() => {
                                                    this.handlePage({first: 0, rows: this.state.criteria.maxResults});
                                                }}
                                            >
                                                {1}
                                                <Ripple />
                                            </button>
                                        );
                                    } else if (options.view.endPage === options.page && options.view.endPage !== options.totalPages - 1) {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={() => {
                                                    this.handlePage({
                                                        first: (options.totalPages - 1) * this.state.criteria.maxResults,
                                                        rows: this.state.criteria.maxResults,
                                                    });
                                                }}
                                            >
                                                {options.totalPages}
                                                <Ripple />
                                            </button>
                                        );
                                    } else if (
                                        (options.view.startPage + 1 === options.page && options.view.startPage !== 0) ||
                                        (options.view.endPage - 1 === options.page &&
                                            options.view.endPage !== options.totalPages - 1 &&
                                            options.page !== options.totalPages - 1)
                                    ) {
                                        const className = classNames(options.className, {'p-disabled': true});
                                        return (
                                            <span className={className} style={{userSelect: 'none'}}>
                                                ...
                                            </span>
                                        );
                                    } else {
                                        return (
                                            <button type='button' className={options.className} onClick={options.onClick}>
                                                {options.page + 1}
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                            }}
                            pageLinkSize={this.getPageLinkSize()}
                            selection={selection}
                            onSelectionChange={handleSelectionChange}
                            dataKey={selectionDataKey}
                            headerColumnGroup={headerColumnGroup}
                            rowClassName={this.rowClassName}
                        >
                            {dynamicColumns}
                        </DataTable>
                        <div className='col-12'>
                            <ActionLink
                                key='add-link'
                                className={addButtonClassName}
                                rendered={showAddButton && !publicMode}
                                label={addButtonLabel}
                                size={addButtonSize}
                                variant={addButtonVariant}
                                handleClick={() => {
                                    this.handleAddElement();
                                }}
                                iconColor={addButtonIconColor}
                                iconName={addButtonIconName}
                                iconSize={addButtonIconSize}
                                iconSide='left'
                            />
                            <ActionButton
                                key='add-button'
                                className={addButtonClassName}
                                rendered={showAddButton && publicMode}
                                label={addButtonLabel}
                                disabled={!!addButtonDisabled}
                                size={addButtonSize}
                                variant={addButtonVariant}
                                handleClick={() => {
                                    this.handleAddElement();
                                }}
                                iconColor={addButtonIconColor}
                                iconName={addButtonIconName}
                                iconSize={addButtonIconSize}
                                iconSide='left'
                                variant={addButtonVariant}
                                iconSvg={addButtonIconSvg}
                            />
                            <ActionButton
                                key='custom-button'
                                className={customButtonClassName}
                                rendered={!!customButton && publicMode}
                                label={customButtonLabel}
                                //	disabled={!!addButtonDisabled}
                                size={addButtonSize}
                                variant={addButtonVariant}
                                handleClick={() => {
                                    handleCustomButton();
                                }}
                                iconColor={addButtonIconColor}
                                iconName={addButtonIconName}
                                iconSize={addButtonIconSize}
                                iconSide='left'
                            />
                        </div>
                    </div>
                    {this.renderValidatorsMessages(validator, id, label, value, validators)}
                </div>
            );
        } else if (rendered && lazy) {
            return (
                <div className={className}>
                    {showLabel ? (
                        <label className='p-label' htmlFor={id} style={{width: '100%'}}>
                            {label}
                        </label>
                    ) : null}
                    <DataTable
                        id={id}
                        key='data-table'
                        className='p-datatable-responsive'
                        emptyMessage='Brak rekordów do wyświetlenia'
                        value={list}
                        selectable={selectable}
                        selection={selection}
                        selectionMode={selectionMode}
                        onSelectionChange={handleSelectionChange}
                        headerColumnGroup={headerColumnGroup}
                        paginator
                        rows={criteria.maxResults}
                        totalRecords={size}
                        dataKey={selectionDataKey}
                        lazy
                        first={first}
                        onPage={this.handlePage}
                        onSort={(e) => this.onSort(e)}
                        sortField={criteria.sortField}
                        sortOrder={criteria.sortAsc === true ? 1 : -1}
                        loading={loading}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        paginatorPosition='bottom'
                        paginatorTemplate='RowsPerPageDropdown PageLinks'
                        rowClassName={this.rowClassName}
                    >
                        {dynamicColumns}
                    </DataTable>
                    <ActionLink
                        key='add-link'
                        className={addButtonClassName}
                        rendered={showAddButton && !publicMode}
                        label={addButtonLabel}
                        size={addButtonSize}
                        variant={addButtonVariant}
                        handleClick={() => {
                            this.handleAddElement();
                        }}
                        iconColor={addButtonIconColor}
                        iconName={addButtonIconName}
                        iconSize={addButtonIconSize}
                        iconSide='left'
                    />
                    <ActionButton
                        key='add-button'
                        className={addButtonClassName}
                        rendered={showAddButton && publicMode}
                        disabled={!!addButtonDisabled}
                        label={addButtonLabel}
                        size={addButtonSize}
                        variant={addButtonVariant}
                        handleClick={() => {
                            this.handleAddElement();
                        }}
                        iconColor={addButtonIconColor}
                        iconName={addButtonIconName}
                        iconSize={addButtonIconSize}
                        iconSide='left'
                    />
                    {this.renderValidatorsMessages(validator, id, label, value, validators)}
                </div>
            );
        } else {
            return null;
        }
    }
}

EditableDataTableExt.defaultProps = {
    addButtonDisabled: false,
    addButtonLabel: '+ Dodaj',
    addButtonSize: 'normal',
    addButtonVariant: 'white',
    addedList: [],
    className: 'row',
    defaultRowsCount: 10,
    showLabel: false,
    editable: false,
    lazy: false,
    modifiedList: new Map(),
    objectId: null,
    paginator: false,
    permanentDelete: false,
    publicMode: false,
    showAddButton: false,
    showDeleteButton: false,
    showDeleteButtonOnlyNew: false,
    selectable: false,
    selectionMode: 'single',
    selection: {},
    rendered: true,
    viewMode: 'VIEW',
    validators: 'not_required',
    editOnlyNew: false,
    handleCustomButton: () => {},
    customButton: false,
    customButtonLabel: '',
    deleteButtonClassName: '',
    showDeleteButtonLabel: true,
    loading: false,
    breakpoint: '10px',
    showDetailsButton: false,
    customIconDeleteSrc: undefined,
    customIconViewSrc: undefined,
};

EditableDataTableExt.propTypes = {
    addButtonClassName: PropTypes.string,
    addButtonIconColor: PropTypes.string,
    addButtonIconName: PropTypes.string,
    addButtonIconSize: PropTypes.string,
    customIconViewSrc: PropTypes.string,
    customIconDeleteSrc: PropTypes.string,
    addButtonLabel: PropTypes.string,
    addButtonSize: PropTypes.string,
    addButtonVariant: PropTypes.string,
    addedList: PropTypes.array, // wymagane gdy lazy jest true
    addedListName: PropTypes.string, // wymagane gdy lazy jest true
    addButtonDisabled: PropTypes.bool,
    className: PropTypes.string,
    columns: PropTypes.array.isRequired,
    createNewElement: PropTypes.func, // wymagane gdy showAddButton jest true
    crossFieldValidation: PropTypes.func,
    dataKey: PropTypes.string, // wymagane gdy editable jest true
    defaultRowsCount: PropTypes.number,
    editable: PropTypes.bool,
    getList: PropTypes.func, // wymagane gdy lazy jest true
    handleSelectionChange: PropTypes.func, // wymagane gdy selectable jest true
    handleTranslate: PropTypes.func,
    headerColumnGroup: PropTypes.any,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    lazy: PropTypes.bool,
    loading: PropTypes.bool,
    messages: PropTypes.any,
    modifiedList: PropTypes.instanceOf(Map), // wymagane gdy lazy jest true
    modifiedListName: PropTypes.string, // wymagane gdy lazy jest true
    name: PropTypes.string, // wymagane gdy editable jest true
    objectId: PropTypes.any,
    onAfterAddElement: PropTypes.func,
    onAfterEditElement: PropTypes.func,
    onAfterRemoveElement: PropTypes.func,
    onChange: PropTypes.func, // wymagane gdy editable jest true
    paginator: PropTypes.bool,
    permanentDelete: PropTypes.bool, // ignorowane gdy lazy jest true
    publicMode: PropTypes.bool,
    rendered: PropTypes.bool,
    selectable: PropTypes.bool,
    selection: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    selectionDataKey: PropTypes.string,
    selectionMode: PropTypes.string, // wymagane gdy selectable jest true
    showAddButton: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    showDeleteButtonFunction: PropTypes.func,
    showDeleteButtonFunctionLabel: PropTypes.string,
    showDeleteButtonOnlyNew: PropTypes.bool,
    showLabel: PropTypes.bool,
    stateField: PropTypes.string, // wymagane gdy editable jest true
    tableClassName: PropTypes.string,
    updateItemElementAfterEdit: PropTypes.func, // wymagane gdy lazy jest true
    updateItemElementAfterGetList: PropTypes.func, // wymagane gdy lazy jest true
    validator: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    validators: PropTypes.string,
    value: PropTypes.array, // wymagane gdy lazy jest false
    viewMode: PropTypes.string.isRequired,
    editOnlyNew: PropTypes.bool,
    handleCustomButton: PropTypes.func,
    customButton: PropTypes.bool,
    customButtonLabel: PropTypes.string,
    customButtonClassName: PropTypes.string,
    deleteButtonClassName: PropTypes.string,
    showDeleteButtonLabel: PropTypes.bool,
    showDetailsButton: PropTypes.bool,
    showDetails: PropTypes.func,
};

export default EditableDataTableExt;
