import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import ActionLink from '../ActionLink';
import {Dropdown} from 'primereact/dropdown';
import i18n from '../../i18n';
import ReminderContainer from '../../containers/ReminderContainer';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import {refreshAgreementBase} from '../../containers/AgreementBaseContainer';
import {refreshAppAfterDetectedNewBuild} from '../../AppContext';

class HeaderComponent extends Component {
    constructor(props) {
        super(props);
        this.authService = new AuthService();
        this.userService = new UserService();
        this.state = {
            timer: this.authService.expirationTimer(),
            loggedIn: this.authService.loggedIn(),
            sapRoleId: this.authService.getProfile().agreementSapRoleId,
        };
    }

    componentDidMount() {
        if (!sessionStorage.getItem('i18nextLng')) {
            if (this.authService.loggedIn() && this.authService.isTokenValidForRefresh()) {
                this.authService.refresh().then((res) => {
                    sessionStorage.setItem('i18nextLng', res.language);
                    i18n.changeLanguage(res.language);
                });
            }
        }
        this.timer = setInterval(() => {
            refreshAppAfterDetectedNewBuild(false);
            this.setState({
                timer: this.authService.expirationTimer(),
            });
            if (!this.authService.loggedIn()) {
                if (this.props.logout) {
                    this.props.logout();
                } else {
                    this.authService.logout();
                }
            }
        }, 1000);
        this.setState({
            loggedIn: this.authService.loggedIn(),
            timer: this.authService.expirationTimer(),
        });
    }
    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    changeRoleType(sapRoleId) {
        this.userService.changeRoleType(sapRoleId).then((res) => {
            this.authService.setToken(res.token);
            window.location.href = AppPrefixUtils.locationHrefUrl('/#/start');
            window.location.reload();
        });
    }

    render() {
        const {t} = this.props;
        return (
            <React.Fragment>
                {this.authService.loggedIn() ? (
                    <header className='container-fluid'>
                        <div className='session-div'>
                            <span className='session-label'>
                                {t('header.sessionTimer')}: <span className='session-time'>{this.state.timer}</span>
                            </span>
                        </div>
                        <div className='header-content'>
                            {this.props.label ? (
                                this.props.backUrl ? (
                                    <ActionLink
                                        label={t(this.props.label)}
                                        className='back-blue-link header-welcome-link'
                                        href={AppPrefixUtils.locationHrefUrl(this.props.backUrl)}
                                        variant='blue'
                                        iconName='mdi-arrow-left left-arrow-style'
                                        iconSide='left'
                                        iconSize='xs'
                                        iconColor='blue'
                                    />
                                ) : (
                                    <span className='header-welcome header-welcome-label'>{t(this.props.label)}</span>
                                )
                            ) : (
                                <span className='header-welcome'>
                                    {/* {t('header.welcome')}, */}
                                    <b>
                                        {`${
                                            this.authService.getProfile().fullName &&
                                            this.authService.getProfile().fullName.trim().length > 0
                                                ? ` ${this.authService.getProfile().fullName}`
                                                : ''
                                        }`}
                                    </b>
                                </span>
                            )}

                            <div className='header-links'>
                                {this.authService.getProfile().sapRoleIdsList?.length > 1 ? (
                                    <div className='header-link dropdown'>
                                        <span className='dropdown-label'>{`${t('header.agreement')}: `}</span>
                                        <Dropdown
                                            options={this.authService.getProfile().sapRoleIdsList.map((el) => {
                                                return {value: el, label: t(`header.${el}`)};
                                            })}
                                            optionLabel='label'
                                            optionValue='value'
                                            value={this.state.sapRoleId}
                                            onChange={(e) => {
                                                if (e.value !== this.state.sapRoleId) {
                                                    this.setState(
                                                        {
                                                            sapRoleId: e.value,
                                                        },
                                                        () => this.changeRoleType(this.state.sapRoleId)
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                ) : null}

                                {this.authService.isUserInAnyRole(
                                    'ROLE_ADMIN_SUPER_MANAGE',
                                    'ROLE_USER_LEKSY_MANAGE',
                                    'ROLE_USER_LEX_DOCS_MANAGE',
                                    'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                    'ROLE_ADMIN_TERM_MANAGE',
                                    'ROLE_ADMIN_PAYMENT_MANAGE',
                                    'ROLE_LAW_OFFICE_EDITOR_MANAGE'
                                ) ? (
                                    <React.Fragment>
                                        <ActionLink
                                            label={t('header.profil')}
                                            href={AppPrefixUtils.locationHrefUrl(
                                                '/#/manage-account/details/' + this.authService.getProfile().userId
                                            )}
                                            className='header-link'
                                            variant='blue'
                                            iconName='mdi-account'
                                            iconSide='left'
                                            iconSize='x-lg'
                                            iconColor='blue'
                                            key={'profil-button'}
                                        />
                                    </React.Fragment>
                                ) : null}
                                {this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE') ? (
                                    <ActionLink
                                        label={'Kontakt'}
                                        href={AppPrefixUtils.locationHrefUrl('/#/contact')}
                                        className='p-link header-link icon-svg.profile'
                                        variant='blue'
                                    />
                                ) : null}

                                <ActionLink
                                    label='Wyloguj się'
                                    handleClick={this.props.logout}
                                    className='header-link'
                                    rel='noopener noreferrer'
                                >
                                    <span>{t('header.logout')}</span>
                                </ActionLink>
                                <ActionLink
                                    label='Wyloguj się'
                                    handleClick={this.props.logout}
                                    className='header-link'
                                    rel='noopener noreferrer'
                                >
                                    <span>{t('header.logout')}</span>
                                </ActionLink>

                                <ReminderContainer rel='noopener noreferrer' className='header-link' />
                            </div>
                        </div>
                    </header>
                ) : (
                    <header className='container-fluid'>
                        <div className='header-content'></div>
                    </header>
                )}
            </React.Fragment>
        );
    }
}

export default withTranslation()(HeaderComponent);
