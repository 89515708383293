export default class SimpleUserTypeModel {
    constructor() {
        this.userRoleName= '';
        this.person= '';
        this.portal= '';
        this.status= '';
    }

    static assign(from, to) {
        if (from) {
            to.userRoleName = from.userRoleName
            to.person = from.person;
            to.portal = from.portal;
            to.status = from.status;
        } else {
            to = new SimpleUserTypeModel();
        }
    }

    static copy(from) {
        const to = new SimpleUserTypeModel();
        SimpleUserTypeModel.assign(from, to);
        return to;
    }
}
