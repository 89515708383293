import {withTranslation} from 'react-i18next';
import AgreementBaseContainer from './AgreementBaseContainer';
import AgreementService from '../services/AgreementService';
import AgreementPublicService from '../services/public/AgreementPublicService';
import AuthService from '../services/AuthService';
class AgreementFormContainer extends AgreementBaseContainer {
    constructor(props) {
        super(props, AgreementFormContainer.getService());
        this.translate = this.translate.bind(this);
    }
    breadCrumbItems() {
        const {templatesUrl, publicTemplatesUrl} = this.props;
        return [{name: 'Szablony', url: this.authService.loggedIn() ? templatesUrl : publicTemplatesUrl}, {name: 'Kreator dokumentu'}];
    }

    static getService() {
        return new AuthService().loggedIn() ? new AgreementService() : new AgreementPublicService();
    }
    translate(key, plurals) {
        const {t} = this.props;
        if (plurals) {
            return t(key, {count: plurals.count, postProcess: plurals.postProcess});
        } else return t(key);
    }
}
AgreementFormContainer.defaultProps = {
    backUrl: '/#/agreement-list',
    templatesUrl: '/#/template-list',
    publicTemplatesUrl: '/#/public/template-list',
};
AgreementFormContainer.propTypes = {};
export default withTranslation()(AgreementFormContainer);
