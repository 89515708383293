import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import LawOfficeListDialog from './LawOfficeListDialog';
import InternalUserService from '../../services/InternalUserService';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import DialogResizer from '../../components/DialogResizer';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';

class LawOfficeListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.state = {
            name: '',
            list: [],
            loading: true,
            size: 0,
            dialogVisibile: false,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            personOptions: [
                {
                    label: 'Wieloosobowa',
                    enumValue: 'LAW_OFFICE_MANAGER',
                },
                {
                    label: 'Jednoosobowa',
                    enumValue: 'LAW_OFFICE',
                },
            ],
        };
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handlefullName = this.handlefullName.bind(this);
    }

    updateSearchCriteria(criteria) {
        return {
            text: criteria.text,
            role: criteria.role,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            roles: criteria.roles,
        };
    }

    getCleanSearchCriteria() {
        return {
            text: '',
            role: undefined,
            name: undefined,
            person: undefined,
            roles: ['Kancelaria partnerska'],
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    getDataKey() {
        return 'id';
    }
    getListTitle() {
        return 'lawOffice.listTitle';
    }

    getCriteriaName() {
        return 'law-office-list';
    }

    getContainerListName() {
        return 'law-office-list-container';
    }

    prepareHeaderItems() {
        const {t} = this.props;
        return this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE')
            ? [
                  {
                      label: t('lawOffice.add'),
                      onClick: this.handleOpenDialog,
                      type: 'BUTTON',
                      className: 'float-left',
                      variant: 'white',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                  },
              ]
            : [];
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name'
                    name='name'
                    label='Nazwa kancelarii'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.name}
                    options={this.state.lawOfficeParameterTypeOptions}
                    placeholder='Wyszukaj...'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
                <CriteriaDropdownComponent
                    id='personType-sc'
                    name='person'
                    showClear
                    label={'Typ kancelarii'}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.person}
                    options={this.state.personOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                />
            </div>
        );
    }

    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState(
            {
                dialogVisibile: false,
                elementId: undefined,
            },
            () => {
                DialogResizer.removeMaskIfExistOneDialog();
                if (callback) callback();
            }
        );
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    handlefullName(e) {
        return e.fullName;
    }

    prepareColumns() {
        return [
            {field: 'companyName', header: 'Nazwa kancelarii', sortable: true},
            {field: 'firstName', header: 'Zarządzający kancelarią', sortable: true, body: this.handlefullName.bind(this)},
            {field: 'person', header: 'Typ kancelarii', sortable: true, body: this.enumTemplate.bind(this, 'LawOfficeType', 'person')},
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <div className='float-center'>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                    onClick={this.handleGoToDetail.bind(this, href)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        );
    }
    breadCrumbItems() {
        return [{name: 'Kancelarie partnerskie', url: '/#/law-office-list'}];
    }

    handleOpenDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            dialogVisibile: true,
            dialogViewMode: 'NEW',
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className='container-fluid'>
                    {this.state.dialogVisibile ? (
                        <LawOfficeListDialog
                            className='law-office-dialog'
                            statusOptions={this.state.statusOptions}
                            elementId={this.state.elementId}
                            onHide={(e) => {
                                this.handleCloseDialog(e);
                            }}
                            visible={this.state.dialogVisibile}
                            viewMode={this.state.dialogViewMode}
                            afterSave={(e) =>
                                this.handleCloseDialog(e, () => {
                                    this.readMessage();
                                    this.refreshTable();
                                    this.unblockUi();
                                })
                            }
                            blockUi={this.blockUi}
                            unblockUi={this.unblockUi}
                            _isMounted={this._isMounted}
                        />
                    ) : null}
                    <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                    {this.renderView()}
                </div>
            </React.Fragment>
        );
    }
}

LawOfficeListContainer.defaultProps = {
    detailUrl: '/#/law-office/details',
    newUrl: '/#/law-office/new',
    editUrl: '/#/law-office/edit',
    lazy: true,
};

LawOfficeListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(LawOfficeListContainer);
