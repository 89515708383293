import BaseServiceCrud from './BaseServiceCrud';


export default class SpecializationService extends BaseServiceCrud {
	constructor() {
		super();
		this.path = 'specializations';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			id: criteria.id,
			first_result: criteria.firstResult,
			max_results: criteria.maxResults,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
			name: criteria.name,
		});
	}

	update(element) {
		return this.fetch(`${this.domain}/${this.path}/edit/${element.id}`, {
			method: 'PUT',
			body: JSON.stringify(element),
		}).then((res) => {
			return Promise.resolve(res);
		});
	}

	getAllSpecializations(){
		return this.fetch(`${this.domain}/${this.path}/specializations`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	
	getAllByRole(role){
		return this.fetch(`${this.domain}/${this.path}/specializations-by-role/${role}`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	getAllSpecializationsByUserId(userId){
		return this.fetch(`${this.domain}/${this.path}/specializations-by-user/${userId}`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	
}
