import React, {Component} from 'react';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';

import CookieConsent from './components/CookieConsent';
import Sidebar from './components/layouts/Sidebar';
import AgreementFormContainer from './containers/AgreementFormContainer';
import AgreementListContaner from './containers/AgreementListContaner';
import ApprovalContentContainer from './containers/approval/ApprovalContentContainer';
import ApprovalContentListContainer from './containers/approval/ApprovalContentListContainer';
import CategoryContainer from './containers/category/CategoryContainer';
import CategoryListContainer from './containers/category/CategoryListContainer';
import ChangePasswordContainer from './containers/ChangePasswordContainer';
import EventCalendar from './containers/eventCalendar/EventCalendar';
import ContactContainer from './containers/ContactContainer';
import CookiesContainer from './containers/CookiesContainer';
import EmailContainer from './containers/EmailContainer';
import EmailListContainer from './containers/EmailListContainer';
import EventLogContainer from './containers/EventLogContainer';
import EventLogListContainer from './containers/EventLogListContainer';
import {
    default as HierarchicalDictionaryContainer,
    default as HierarchicalDictionaryItemContainer,
} from './containers/HierarchicalDictionaryContainer/HierarchicalDictionaryItemContainer';
import HierarchicalDictionaryListContainer from './containers/HierarchicalDictionaryContainer/HierarchicalDictionaryListContainer';
import LawOfficeNavContainer from './containers/LawOffice/LawOfficeNavContainer';
import LawOfficeListContainer from './containers/LawOffice/LawOfficeListContainer';
import LawyerListContainer from './containers/LawOffice/LawyerListContainer';
import Login from './containers/LoginContainer';
import RegistrationContainer from './containers/registration/RegistrationContainer';
import RemindPasswordContainer from './containers/RemindPasswordContainer';
import SettingListContainer from './containers/SettingListContainer';
import SpecializationContainer from './containers/specialization/SpecializationContainer';
import SpecializationListContainer from './containers/specialization/SpecializationListContainer';
import SubscriptionContainer from './containers/subscription/SubscriptionContainer';
import SubscriptionListContainer from './containers/subscription/SubscriptionListContainer';
import TemplateContainer from './containers/templates/TemplateContainer';
import ConfirmPasswordContainer from './containers/user/ConfirmPasswordContainer';
import ResetPasswordContainer from './containers/user/ResetPasswordContainer';
import ConsultationListContainer from './containers/consultation/ConsultationListContainer';
import ConsultationContainer from './containers/consultation/ConsultationContainer';
import UserListContainer from './containers/user/UserListContainer';
import SystemUserListContainer from './containers/systemUsers/SystemUserListContainer';
import VersionContainer from './containers/VersionContainer';
import AuthService from './services/AuthService';
import UserService from './services/UserService';
import withAuth from './utils/withAuth';
import ProfileNavContainer from './containers/user/account/ProfileNavContainer';
import UserManageNavContainer from './containers/user/manage/UserManageNavContainer';
import PackageOfferContainer from './containers/packageOffer/PackageOfferContainer';
import UserChangePackageContainer from './containers/user/manage/UserChangePackageContainer';
import AgreementList from './containers/AgreementList';
import ConfiguratorNavContainer from './containers/ConfiguratorNavContainer';
import SystemUserContainer from './containers/systemUsers/SystemUserContainer';
import LawyerContainer from './containers/LawOffice/LawyerContainer';
import ScheduledEventCalendar from './containers/scheduledConsultation/ScheduledEventCalendar';
import ConsultationHistoryListContainer from './containers/consultationHistory/ConsultationHistoryListContainer';
import ChatContainer from './containers/chat/ChatContainer';
import ChatService from './services/ChatService';
import {CustomMessages} from './components/CustomMessages';
import ConsultationHistoryContainer from './containers/consultationHistory/ConsultationHistoryContainer';
import OrderListContainer from './containers/order/OrderListContainer';
import ReportListContainer from './containers/report/ReportListContainer';
import withoutAuth from './utils/withoutAuth';
import NavBar from './containers/portals/NavBar';
import StartLexdocsContainer from './containers/StartLexdocsContainer';
import StartLawyerContainer from './containers/StartLawyerContainer';
import AppPrefixUtils from './utils/AppPrefixUtils';
import Footer from './containers/Footer';
import SettingService from './services/SettingService';
import SettingsPublicService from './services/public/SettingsPublicService';
import RolesUtils from './utils/RolesUtils';
import AgreementPreviewContainer from './containers/AgreementPreviewContainer';
import {CookiesConstants} from './utils/CookiesConstants';
import {UAParser} from 'ua-parser-js';
import {device} from './baseContainers/BaseContainer';
import {detectAppHasBeenUpdated, refreshAppAfterDetectedNewBuild} from './AppContext';
import YesNoDialog from './components/YesNoDialog';

class App extends Component {
    constructor() {
        super();
        this.authService = new AuthService();
        this.userService = new UserService();
        this.state = {
            user: this.authService.getProfile(),
            collapsed: false,
            sidebarThin: false,
            redirectLink: undefined,
            toggled: false,
            renderSidebar: true,
            generatedDocumentAvailable: false,
            navbarVisible: true,
        };
        this.afterLogin = this.afterLogin.bind(this);
        this.handleLogoutUser = this.handleLogoutUser.bind(this);
        this.handleHideMenu = this.handleHideMenu.bind(this);
        this.handleShowMenu = this.handleShowMenu.bind(this);
        this.setActiveMenu = this.setActiveMenu.bind(this);
        this.handleHideMenuAndNavbar = this.handleHideMenuAndNavbar.bind(this);
        this.chatService = new ChatService();
        this.settingService = this.authService.loggedIn() ? new SettingService() : new SettingsPublicService();
        const {device} = UAParser(navigator.userAgent);
        this.device = device;
    }

    setActiveMenu(activeUrl) {
        if (activeUrl !== this.state.activeUrl) {
            this.setState({activeUrl});
        }
    }
    handleHideMenu() {
        if (!!this.state.renderSidebar) {
            this.setState({renderSidebar: false});
        }
    }
    handleHideMenuAndNavbar() {
        if (!!this.state.renderSidebar) {
            this.setState({renderSidebar: false, navbarVisible: false});
        }
    }

    handleShowMenu() {
        if (!this.state.renderSidebar) {
            this.setState({renderSidebar: true});
        }
    }

    handleToggleSidebar() {
        const element = document.getElementById('pro-sidebar');
        if (element) element.click();
    }

    afterLogin() {
        this.setState(
            {
                user: this.authService.getProfile().sub,
            },
            () => this.chatService.userLogged()
        );
    }
    componentDidMount() {
        refreshAppAfterDetectedNewBuild();
        setTimeout(() => {
            detectAppHasBeenUpdated();
        }, 200);
        if (this.device?.type && this.device.type === 'mobile') {
            document.body.style.zoom = '90%';
        }
        this.settingService
            .getValue('TEMPLATE_SAVE_AFTER_GENERATE')
            .then((resp) => {
                this.setState({generatedDocumentAvailable: resp});
            })
            .catch(() => {
                console.log('cannot get document available parameter');
            });
    }
    componentDidUpdate() {
        refreshAppAfterDetectedNewBuild();
        detectAppHasBeenUpdated();
    }
    handleRedirect(url) {
        window.location.href = AppPrefixUtils.locationHrefUrl(url);
    }

    handleLogoutUser(url) {
        const lastUseToken = this.authService.getToken();
        this.authService.logout();
        if (!!url && typeof url === 'string') {
            window.location.href = AppPrefixUtils.locationHrefUrl('/#login?location=' + url);
        } else {
            window.location.href = AppPrefixUtils.locationHrefUrl('/#/login?force=true');
        }
        if (this.state.user) {
            this.setState({user: null}, () => {
                this.chatService.userLogout();
                if (this.isLexyPortal()) {
                    //to takie logowanie niezalogowanego, żeby dla socket się zestawił
                    this.chatService.userLogged();
                }
            });
        }
        if (lastUseToken !== undefined && lastUseToken !== null) {
            this.authService.logout();
            this.userService.logout(lastUseToken);
        }
    }
    afterPreLogoutUser() {}

    handlePreLogoutUser(callBack) {
        this.authService.logout();
        if (this.state.user !== null) {
            this.setState({user: null}, () => this.afterPreLogoutUser());
            this.setState({user: null}, () => {
                if (callBack) callBack();
            });
        } else {
            if (callBack) callBack();
        }
    }

    showMessage(severity, summary, detail, life = 5000, closable = true, errMsg) {
        if (this.props.showMessage !== undefined && this.props.showMessage instanceof Function) {
            this.props.showMessage(severity, summary, detail, life, closable, errMsg);
        } else if (this.messages !== undefined && this.messages !== null) {
            // this.messages.clear();
            this.messages.show({
                severity,
                summary,
                detail,
                life,
                closable,
            });
        } else {
            if (errMsg !== undefined) {
                console.log('this.messages === undefined', errMsg);
            } else {
                console.log('this.messages === undefined');
            }
        }
    }
    render() {
        const authService = this.authService;
        const lexdocsLogged = !this.isLexyPortal() && authService.loggedIn();
        return (
            <React.Fragment>
                <CookieConsent
                    location='bottom'
                    buttonText='Akceptuję'
                    cookieName='cookiePolicy'
                    style={{background: 'rgba(0, 0, 0, 0.8)', zIndex: '100000'}}
                    buttonStyle={{
                        background: '#1c3275',
                        color: 'white',
                        fontSize: '16px',
                        fontFamily: 'Ubuntu',
                        fontWeight: 500,
                        borderRadius: '3px',
                    }}
                    expires={150}
                >
                    Nasz serwis wykorzystuje pliki cookies. Możesz zrezygnować ze zbierania informacji poprzez pliki cookies, zmieniając
                    ustawienia Twojej przeglądarki - w takim przypadku nie gwarantujemy poprawnego działania serwisu.
                </CookieConsent>

                <Router>
                    {this.state.navbarVisible && !lexdocsLogged && (
                        <NavBar
                            editConfirmation={(fnc) => {
                                window.exitConfirmationRefDialog?.current?.props?.onShow(fnc);
                            }}
                            handleRedirect={this.handleRedirect}
                            loggedIn={authService.loggedIn()}
                            logout={this.handleLogoutUser.bind(this)}
                            generatedDocumentAvailable={this.state.generatedDocumentAvailable}
                            footerVisible={this.footerVisible()}
                        ></NavBar>
                    )}
                    {this.isLexyPortal() || !!this.authService.loggedIn() ? (
                        <ChatContainer
                            showMessage={(severity, summary, details) => {
                                this.showMessage(severity, summary, details);
                            }}
                            allowPublicUser={this.isLexyPortal()}
                        />
                    ) : null}
                    <div className={`app ${this.footerVisible() ? 'root-child-when-footer' : ''}`}>
                        {!!authService.loggedIn() && !!this.state.renderSidebar ? (
                            !this.isLexyPortal() ? (
                                <Sidebar
                                    exitEditConfirmation={(link) => {
                                        let currentViewMode = sessionStorage.getItem(CookiesConstants.DETAILS_VIEW_MODE);
                                        if (window.location.href.includes('edit') && currentViewMode.toUpperCase() === 'EDIT') {
                                            window.exitConfirmationRefDialog?.current?.props?.onShow(link, 'EDIT');
                                        } else if (
                                            (window.location.href.includes('new') || window.location.href.includes('create')) &&
                                            currentViewMode.toUpperCase() === 'NEW'
                                        ) {
                                            window.exitConfirmationRefDialog?.current?.props?.onShow(link, 'NEW');
                                        } else {
                                            window.location.href = AppPrefixUtils.locationHrefUrl(link);
                                        }
                                        sessionStorage.removeItem(CookiesConstants.DETAILS_VIEW_MODE);
                                    }}
                                    collapsed={this.state.collapsed}
                                    toggled={this.state.toggled}
                                    loggedUser={this.state.user}
                                    handleToggleSidebar={this.handleToggleSidebar.bind(this)}
                                    handleLogoutUser={this.handleLogoutUser.bind(this)}
                                    authService={this.authService}
                                    activeUrl={this.state.activeUrl}
                                    generatedDocumentAvailable={this.state.generatedDocumentAvailable}
                                />
                            ) : null
                        ) : null}
                        <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />

                        <main id='main' key={'main'}>
                            {this.state.navbarVisible && lexdocsLogged && (
                                <NavBar
                                    editConfirmation={(fnc) => {
                                        window.exitConfirmationRefDialog?.current?.props?.onShow(fnc);
                                    }}
                                    handleRedirect={this.handleRedirect}
                                    loggedIn={authService.loggedIn()}
                                    logout={this.handleLogoutUser.bind(this)}
                                    generatedDocumentAvailable={this.state.generatedDocumentAvailable}
                                    footerVisible={this.footerVisible()}
                                    handleToggleSidebar={this.handleToggleSidebar.bind(this)}
                                ></NavBar>
                            )}
                            <Switch>
                                <Route
                                    exact
                                    path='/'
                                    render={(props) => (
                                        <Login
                                            {...props}
                                            onAfterLogin={() => {
                                                this.afterLogin();
                                            }}
                                            handlePreLogoutUser={this.handlePreLogoutUser.bind(this)}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path='/agreement-preview/:id'
                                    component={withAuth(
                                        AgreementPreviewContainer,
                                        'NEW',
                                        [
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                            'ROLE_ADMIN_TEMPLATE_MANAGE',
                                            'ROLE_ADMIN_PACKAGE_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'Kreator dokumentu',
                                        'default-white-background large-width no-padding',
                                        undefined,
                                        false,
                                        this.handleHideMenuAndNavbar,
                                        this.setActiveMenu,
                                        'agreement-list'
                                    )}
                                />
                                <Route
                                    path='/login'
                                    render={(props) => (
                                        <Login
                                            {...props}
                                            onAfterLogin={() => {
                                                this.afterLogin();
                                            }}
                                            handlePreLogoutUser={this.handlePreLogoutUser.bind(this)}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path='/agreement-list'
                                    component={withAuth(
                                        AgreementList,
                                        'LIST',
                                        //todo uprawnienie do pobrania przydzielonych szablonów
                                        [
                                            'ROLE_USER_LEKSY_MANAGE',
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_USER_LEX_DOCS_MANAGE',
                                            'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                            'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                            'ROLE_ADMIN_PAYMENT_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'Dokumenty',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'agreement-list'
                                    )}
                                />
                                {!authService.loggedIn() && this.isLexyPortal() ? (
                                    <Route
                                        exact
                                        path='/public/agreement/create/:id'
                                        component={withoutAuth(
                                            AgreementFormContainer,
                                            'NEW',
                                            undefined,
                                            'default-white-background large-width no-padding',
                                            undefined,
                                            true,
                                            this.handleShowMenu,
                                            this.setActiveMenu,
                                            'agreement-list'
                                        )}
                                    />
                                ) : (
                                    <Route
                                        exact
                                        path='/agreement/create/:id'
                                        component={withAuth(
                                            AgreementFormContainer,
                                            'NEW',
                                            [
                                                'ROLE_USER_LEKSY_MANAGE',
                                                'ROLE_USER_LEX_DOCS_MANAGE',
                                                'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                                'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                            ],
                                            this.handleLogoutUser,
                                            'Kreator dokumentu',
                                            'default-white-background large-width no-padding',
                                            undefined,
                                            false,
                                            this.handleShowMenu,
                                            this.setActiveMenu,
                                            'agreement-list'
                                        )}
                                    />
                                )}
                                {!authService.loggedIn() && this.isLexyPortal() ? (
                                    <Route
                                        exact
                                        path='/public/agreement/pre-create/:id'
                                        component={withoutAuth(
                                            AgreementFormContainer,
                                            'PRE_CREATE',
                                            undefined,
                                            'default-white-background large-width no-padding',
                                            undefined,
                                            true,
                                            this.handleShowMenu,
                                            this.setActiveMenu,
                                            'agreement-list'
                                        )}
                                    />
                                ) : (
                                    <Route
                                        exact
                                        path='/agreement/pre-create/:id'
                                        component={withAuth(
                                            AgreementFormContainer,
                                            'PRE_CREATE',
                                            [
                                                'ROLE_USER_LEKSY_MANAGE',
                                                'ROLE_USER_LEX_DOCS_MANAGE',
                                                'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                                'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                            ],
                                            this.handleLogoutUser,
                                            'Kreator dokumentu',
                                            'default-white-background large-width no-padding',
                                            undefined,
                                            false,
                                            this.handleShowMenu,
                                            this.setActiveMenu,
                                            'agreement-list'
                                        )}
                                    />
                                )}
                                {!authService.loggedIn() && this.isLexyPortal() ? (
                                    <Route
                                        exact
                                        path='/public/agreement/edit/:id'
                                        component={withoutAuth(
                                            AgreementFormContainer,
                                            'EDIT',
                                            undefined,
                                            'default-white-background large-width',
                                            undefined,
                                            true,
                                            this.handleShowMenu,
                                            this.setActiveMenu,
                                            'agreement-list'
                                        )}
                                    />
                                ) : null}
                                <Route
                                    exact
                                    path='/agreement/edit/:id'
                                    component={withAuth(
                                        AgreementFormContainer,
                                        'EDIT',
                                        [
                                            'ROLE_USER_LEKSY_MANAGE',
                                            'ROLE_USER_LEX_DOCS_MANAGE',
                                            'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                            'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'Kreator dokumentu',
                                        'default-white-background large-width no-padding',
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'agreement-list'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/template/create'
                                    component={withAuth(
                                        TemplateContainer,
                                        'NEW',
                                        [
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                            'ROLE_ADMIN_TEMPLATE_MANAGE',
                                            'ROLE_ADMIN_PACKAGE_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'Konfigurator szablonu',
                                        'default-white-background large-width no-padding',
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'configurator-template-list'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/template/edit/:id'
                                    component={withAuth(
                                        TemplateContainer,
                                        'EDIT',
                                        [
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                            'ROLE_ADMIN_TEMPLATE_MANAGE',
                                            'ROLE_ADMIN_PACKAGE_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'Konfigurator szablonu',
                                        'default-white-background large-width no-padding',
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'configurator-template-list'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/template/details/:id'
                                    component={withAuth(
                                        TemplateContainer,
                                        'VIEW',
                                        [
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                            'ROLE_ADMIN_TEMPLATE_MANAGE',
                                            'ROLE_ADMIN_PACKAGE_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'Szczegóły szablonu',
                                        'default-white-background large-width no-padding',
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'configurator-template-list'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/sub-template/create/:subtemplate'
                                    component={withAuth(
                                        TemplateContainer,
                                        'NEW',
                                        [
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                            'ROLE_ADMIN_TEMPLATE_MANAGE',
                                            'ROLE_ADMIN_PACKAGE_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'Konfigurator subszablonu',
                                        'default-white-background large-width no-padding',
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'configurator-template-list'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/sub-template/edit/:subtemplate/:id'
                                    component={withAuth(
                                        TemplateContainer,
                                        'EDIT',
                                        [
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                            'ROLE_ADMIN_TEMPLATE_MANAGE',
                                            'ROLE_ADMIN_PACKAGE_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'Konfigurator subszablonu',
                                        'default-white-background large-width no-padding',
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'configurator-template-list'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/sub-template/details/:subtemplate/:id'
                                    component={withAuth(
                                        TemplateContainer,
                                        'VIEW',
                                        [
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                            'ROLE_ADMIN_TEMPLATE_MANAGE',
                                            'ROLE_ADMIN_PACKAGE_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'Konfigurator subszablonu',
                                        'default-white-background large-width no-padding',
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'configurator-template-list'
                                    )}
                                />
                                <Route
                                    path='/user-register'
                                    component={(props) => (
                                        <ConfirmPasswordContainer
                                            {...props}
                                            type='CREATE'
                                            handlePreLogoutUser={this.handlePreLogoutUser.bind(this)}
                                        />
                                    )}
                                />
                                <Route path='/reset-password' component={ResetPasswordContainer} />
                                <Route
                                    path='/confirm-reset-password'
                                    component={(props) => (
                                        <ConfirmPasswordContainer
                                            {...props}
                                            type='RESET'
                                            handlePreLogoutUser={this.handlePreLogoutUser.bind(this)}
                                        />
                                    )}
                                />
                                {!authService.loggedIn() && this.isLexyPortal() ? (
                                    <Route
                                        exact
                                        path='/public/template-list'
                                        component={withoutAuth(
                                            AgreementListContaner,
                                            'VIEW',
                                            undefined,
                                            'default-white-background no-padding',
                                            'template.name',
                                            true,
                                            this.handleShowMenu,
                                            this.setActiveMenu,
                                            'template-list'
                                        )}
                                    />
                                ) : (
                                    <Route
                                        exact
                                        path='/template-list'
                                        component={withAuth(
                                            AgreementListContaner,
                                            'VIEW',
                                            [
                                                'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                                'ROLE_USER_LEKSY_MANAGE',
                                                'ROLE_USER_LEX_DOCS_MANAGE',
                                                'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                            ],
                                            this.handleLogoutUser,
                                            'template.name',
                                            'default-white-background no-padding',
                                            undefined,
                                            false,
                                            this.handleShowMenu,
                                            this.setActiveMenu,
                                            'template-list'
                                        )}
                                    />
                                )}
                                <Route
                                    exact
                                    path='/configurator-template-list'
                                    component={withAuth(
                                        ConfiguratorNavContainer,
                                        'LIST',
                                        [
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                            'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                            'ROLE_ADMIN_TEMPLATE_MANAGE',
                                            'ROLE_ADMIN_PACKAGE_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'configuratorTemplate.name',
                                        'default-white-background large-width no-padding',
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'configurator-template-list'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/template-list/package/:id'
                                    component={withAuth(
                                        AgreementListContaner,
                                        'LIST',
                                        //todo uprawnienie do pobrania przydzielonych szablonów
                                        [
                                            'ROLE_USER_LEKSY_MANAGE',
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_USER_LEX_DOCS_MANAGE',
                                            'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                            'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        undefined,
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'template-list'
                                    )}
                                />
                                <Route exact path='/cookies-page' component={CookiesContainer} />
                                <Route
                                    exact
                                    path='/setting-list'
                                    component={withAuth(
                                        SettingListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_SETTING_MANAGE'],
                                        this.handleLogoutUser,
                                        undefined,
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'setting-list'
                                    )}
                                />
                                {!this.isLexyPortal() &&
                                    this.authService.loggedIn() &&
                                    this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE') && (
                                        <Route
                                            path='/start'
                                            component={withAuth(
                                                StartLexdocsContainer,
                                                'VIEW',
                                                ['ROLE_USER_LEX_DOCS_MANAGE'],
                                                this.handleLogoutUser,
                                                'start',
                                                undefined,
                                                undefined,
                                                false,
                                                this.handleShowMenu,
                                                this.setActiveMenu,
                                                'start'
                                            )}
                                        />
                                    )}
                                {!this.isLexyPortal() &&
                                    this.authService.loggedIn() &&
                                    this.authService.isUserInAnyRole(
                                        'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                        'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                        'ROLE_ADMIN_SUPER_MANAGE'
                                    ) && (
                                        <Route
                                            path='/start'
                                            component={withAuth(
                                                StartLawyerContainer,
                                                'VIEW',
                                                [
                                                    'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                                    'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                                    'ROLE_ADMIN_SUPER_MANAGE',
                                                ],
                                                this.handleLogoutUser,
                                                'start',
                                                undefined,
                                                undefined,
                                                false,
                                                this.handleShowMenu,
                                                this.setActiveMenu,
                                                'start'
                                            )}
                                        />
                                    )}
                                <Route
                                    exact
                                    path='/event-log-list'
                                    component={withAuth(
                                        EventLogListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_LAW_OFFICE_CONSULTANT_MANAGE'],
                                        this.handleLogoutUser,
                                        'eventLog.name',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'event-log-list'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/event-log/details/:id'
                                    component={withAuth(
                                        EventLogContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_LAW_OFFICE_CONSULTANT_MANAGE'],
                                        this.handleLogoutUser
                                    )}
                                />
                                <Route path='/version' render={(props) => <VersionContainer />} />
                                <Route
                                    exact
                                    path='/email-template-list'
                                    component={withAuth(
                                        EmailListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE'],
                                        this.handleLogoutUser,
                                        'email.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'email-template-list'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/email-template/details/:id'
                                    component={withAuth(EmailContainer, 'VIEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)}
                                />
                                <Route
                                    exact
                                    path='/email-template/edit/:id'
                                    component={withAuth(EmailContainer, 'EDIT', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)}
                                />
                                <Route
                                    exact
                                    path='/category/edit/:id'
                                    component={withAuth(
                                        CategoryContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN_SUPER_MANAGE'],
                                        this.handleLogoutUser,
                                        'category.category'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/category/details/:id'
                                    component={withAuth(
                                        CategoryContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE'],
                                        this.handleLogoutUser,
                                        'category.category'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/user/details/:id'
                                    component={withAuth(
                                        UserManageNavContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE'],
                                        this.handleLogoutUser,
                                        'Zarządzanie użytkownikiem'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/user/edit/:id'
                                    component={withAuth(
                                        UserManageNavContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN_SUPER_MANAGE'],
                                        this.handleLogoutUser,
                                        'Zarządzanie użytkownikiem'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/system-user/details/:id'
                                    component={withAuth(
                                        SystemUserContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE'],
                                        this.handleLogoutUser,
                                        'Zarządzanie użytkownikiem'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/system-user/edit/:id'
                                    component={withAuth(
                                        SystemUserContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN_SUPER_MANAGE'],
                                        this.handleLogoutUser,
                                        'Zarządzanie użytkownikiem'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/specialization/details/:id'
                                    component={withAuth(
                                        SpecializationContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE'],
                                        this.handleLogoutUser,
                                        'specialization.specialization'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/specialization/edit/:id'
                                    component={withAuth(
                                        SpecializationContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE'],
                                        this.handleLogoutUser,
                                        'specialization.specialization'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/law-office/edit/:id'
                                    component={withAuth(
                                        LawOfficeNavContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_USER_MANAGE'],
                                        this.handleLogoutUser,
                                        'lawOffice.lawOffice'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/law-office/details/:id'
                                    component={withAuth(
                                        LawOfficeNavContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_USER_MANAGE'],
                                        this.handleLogoutUser,
                                        'lawOffice.lawOffice'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/package-offer'
                                    component={withAuth(
                                        PackageOfferContainer,
                                        'VIEW',
                                        ['ROLE_USER_LEX_DOCS_MANAGE'],
                                        this.handleLogoutUser,
                                        'Zmień swój pakiet abonamentowy na inny',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'package-offer'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/manage-account/edit/:id'
                                    component={withAuth(
                                        ProfileNavContainer,
                                        'EDIT',
                                        RolesUtils.allRoles(),
                                        this.handleLogoutUser,
                                        'Moje konto',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'manage-accounts'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/manage-account/details/:id'
                                    component={withAuth(
                                        ProfileNavContainer,
                                        'VIEW',
                                        RolesUtils.allRoles(),
                                        this.handleLogoutUser,
                                        'Moje konto',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'manage-accounts'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/package-offer/change-package'
                                    component={withAuth(
                                        UserChangePackageContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE', 'ROLE_USER_LEKSY_MANAGE'],
                                        this.handleLogoutUser,
                                        'Zmiana pakietu',
                                        undefined,
                                        '/#/package-offer',
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu
                                    )}
                                />
                                <Route
                                    exact
                                    path='/package-offer/edit'
                                    component={withAuth(
                                        PackageOfferContainer,
                                        'EDIT',
                                        ['ROLE_USER_LEX_DOCS_MANAGE'],
                                        this.handleLogoutUser,
                                        'Wybierz pakiet abonamentowy'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/lawyer/edit/:id'
                                    component={withAuth(
                                        LawyerContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_USER_MANAGE'],
                                        this.handleLogoutUser,
                                        'lawOffice.lawOffice',
                                        undefined,
                                        '/#/law-office/details'
                                    )}
                                />{' '}
                                <Route
                                    exact
                                    path='/lawyer/details/:id'
                                    component={withAuth(
                                        LawyerContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_USER_MANAGE'],
                                        this.handleLogoutUser,
                                        'lawOffice.lawOffice',
                                        undefined,
                                        '/#/law-office/details'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/user/details/:id/change-package'
                                    component={withAuth(
                                        UserChangePackageContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE', 'ROLE_USER_LEKSY_MANAGE'],
                                        this.handleLogoutUser,
                                        'Zmiana pakietu',
                                        undefined,
                                        '/#/user/details',
                                        false
                                    )}
                                />
                                <Route
                                    exact
                                    path='/manage-account/details/:id/change-package'
                                    component={withAuth(
                                        UserChangePackageContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE', 'ROLE_USER_LEKSY_MANAGE'],
                                        this.handleLogoutUser,
                                        'Zmiana pakietu',
                                        undefined,
                                        '/#/manage-account/details',
                                        undefined,
                                        undefined,
                                        undefined,
                                        'manage-accounts'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/change-package/:id'
                                    component={withAuth(
                                        UserChangePackageContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE'],
                                        this.handleLogoutUser,
                                        'profil.name',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu
                                    )}
                                />
                                <Route
                                    exact
                                    path='/approval-content/details/:id'
                                    component={withAuth(
                                        ApprovalContentContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE'],
                                        this.handleLogoutUser,
                                        'Szczegóły zgody'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/approval-content/edit/:id'
                                    component={withAuth(
                                        ApprovalContentContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE'],
                                        this.handleLogoutUser,
                                        'Edycja zgody'
                                    )}
                                />
                                <Route
                                    exact
                                    path={'/forgot-password'}
                                    render={(props) => (
                                        <RemindPasswordContainer
                                            {...props}
                                            onAfterLogin={() => {
                                                this.afterLogin();
                                            }}
                                            type='REMIND'
                                            handlePreLogoutUser={this.handlePreLogoutUser.bind(this)}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path='/registration'
                                    render={(props) => (
                                        <RegistrationContainer
                                            {...props}
                                            onAfterLogin={() => {
                                                this.afterLogin();
                                            }}
                                            type='REMIND'
                                            handlePreLogoutUser={this.handlePreLogoutUser.bind(this)}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path='/registration/confirm'
                                    render={(props) => (
                                        <Login
                                            {...props}
                                            onAfterLogin={() => {
                                                this.afterLogin();
                                            }}
                                            confirmToken='confirm'
                                            handlePreLogoutUser={this.handlePreLogoutUser.bind(this)}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path='/first-login'
                                    render={(props) => (
                                        <RemindPasswordContainer
                                            {...props}
                                            onAfterLogin={() => {
                                                this.afterLogin();
                                            }}
                                            type='FIRST'
                                            handlePreLogoutUser={this.handlePreLogoutUser.bind(this)}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path='/set-password/:token'
                                    render={(props) => (
                                        <ChangePasswordContainer
                                            {...props}
                                            onAfterLogin={() => {
                                                this.afterLogin();
                                            }}
                                            handlePreLogoutUser={this.handlePreLogoutUser.bind(this)}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path='/users-list'
                                    component={withAuth(
                                        UserListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE'],
                                        this.handleLogoutUser,
                                        'user.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'users-list'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/system-users-list'
                                    component={withAuth(
                                        SystemUserListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE'],
                                        this.handleLogoutUser,
                                        'systemUser.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'system-users-list'
                                    )}
                                />
                                <Route
                                    path='/hierarchical-dictionary-list'
                                    component={withAuth(
                                        HierarchicalDictionaryListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE'],
                                        this.handleLogoutUser,
                                        'hierarchicalDictionary.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'hierarchical-dictionary-list'
                                    )}
                                />
                                <Route
                                    path='/hierarchical-dictionary/details/:id'
                                    component={withAuth(
                                        HierarchicalDictionaryItemContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE'],
                                        this.handleLogoutUser,
                                        'hierarchicalDictionary.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'hierarchical-dictionary-list'
                                    )}
                                />
                                <Route
                                    path='/hierarchical-dictionary/edit/:id'
                                    component={withAuth(
                                        HierarchicalDictionaryContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE'],
                                        this.handleLogoutUser,
                                        'hierarchicalDictionary.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'hierarchical-dictionary-list'
                                    )}
                                />
                                <Route
                                    path='/hierarchical-dictionary/item/edit/:id'
                                    component={withAuth(HierarchicalDictionaryItemContainer, 'EDIT', [
                                        'ROLE_ADMIN_SUPER_MANAGE',
                                        'ROLE_ADMIN_CONTENT_MANAGE',
                                    ])}
                                />
                                {this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE') ? (
                                    <Route
                                        exact
                                        path='/contact'
                                        component={withAuth(
                                            ContactContainer,
                                            'VIEW',
                                            ['ROLE_USER_LEKSY_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE'],
                                            this.handleLogoutUser,
                                            'contact.title',
                                            undefined,
                                            undefined,
                                            false,
                                            this.handleShowMenu
                                        )}
                                    />
                                ) : null}
                                <Route
                                    path='/category-list'
                                    component={withAuth(
                                        CategoryListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE'],
                                        this.handleLogoutUser,
                                        'category.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'category-list'
                                    )}
                                />
                                <Route
                                    path='/law-office-list'
                                    component={withAuth(
                                        LawOfficeListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_USER_MANAGE'],
                                        this.handleLogoutUser,
                                        'lawOffice.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'law-office-list'
                                    )}
                                />
                                <Route
                                    path='/lawyer-list'
                                    component={withAuth(
                                        LawyerListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_USER_MANAGE'],
                                        this.handleLogoutUser,
                                        'lawOffice.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'lawyer-list'
                                    )}
                                />
                                <Route
                                    path='/event-calendar'
                                    component={withAuth(
                                        EventCalendar,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_LAW_OFFICE_CONSULTANT_MANAGE', 'ROLE_ADMIN_TERM_MANAGE'],
                                        this.handleLogoutUser,
                                        'Kalendarz',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'event-calendar'
                                    )}
                                />
                                <Route
                                    path='/scheduled-event-calendar'
                                    component={withAuth(
                                        ScheduledEventCalendar,
                                        'VIEW',
                                        [
                                            'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                            'ROLE_USER_LEX_DOCS_MANAGE',
                                            'ROLE_USER_LEKSY_MANAGE',
                                            'ROLE_ADMIN_TERM_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'Zaplanowane konsultacje',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'scheduled-event-calendar'
                                    )}
                                />
                                <Route
                                    path='/consultation-list'
                                    component={withAuth(
                                        ConsultationListContainer,
                                        'VIEW',
                                        [
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                            'ROLE_ADMIN_CONSULTATION_VIEW',
                                            'ROLE_ADMIN_TERM_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'consultationProposal.proposalList',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'consultation-list'
                                    )}
                                />
                                <Route
                                    path='/consultation-history-list'
                                    component={withAuth(
                                        ConsultationHistoryListContainer,
                                        'VIEW',
                                        [
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                            'ROLE_ADMIN_CONSULTATION_VIEW',
                                            'ROLE_USER_LEKSY_MANAGE',
                                            'ROLE_USER_LEX_DOCS_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'consultationHistory.title',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'consultation-history-list'
                                    )}
                                />
                                <Route
                                    path='/consultation-history/details/:id'
                                    component={withAuth(
                                        ConsultationHistoryContainer,
                                        'VIEW',
                                        [
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_LAW_OFFICE_CONSULTANT_MANAGE',
                                            'ROLE_ADMIN_CONSULTATION_VIEW',
                                            'ROLE_USER_LEKSY_MANAGE',
                                            'ROLE_USER_LEX_DOCS_MANAGE',
                                        ],
                                        this.handleLogoutUser,
                                        'consultationHistory.title',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'consultation-history-list'
                                    )}
                                />
                                <Route
                                    path='/consultation/details/:id'
                                    component={withAuth(
                                        ConsultationContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE'],
                                        this.handleLogoutUser,
                                        'consultationProposal.proposalDetails',
                                        undefined,
                                        undefined,
                                        false
                                    )}
                                />
                                <Route
                                    path='/approval-content-list'
                                    component={withAuth(
                                        ApprovalContentListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE'],
                                        this.handleLogoutUser,
                                        'approval.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'approval-content-list'
                                    )}
                                />
                                <Route
                                    path='/specialization-list'
                                    component={withAuth(
                                        SpecializationListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE'],
                                        this.handleLogoutUser,
                                        'specialization.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'specialization-list'
                                    )}
                                />
                                <Route
                                    path='/subscription-list'
                                    component={withAuth(
                                        SubscriptionListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE', 'ROLE_ADMIN_PACKAGE_MANAGE'],
                                        this.handleLogoutUser,
                                        'subscription.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'subscription-list'
                                    )}
                                />{' '}
                                <Route
                                    path='/subscription/details/:id'
                                    component={withAuth(
                                        SubscriptionContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE', 'ROLE_ADMIN_PACKAGE_MANAGE'],
                                        this.handleLogoutUser,
                                        'subscription.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'subscription-list'
                                    )}
                                />
                                <Route
                                    path='/subscription/edit/:id'
                                    component={withAuth(
                                        SubscriptionContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE', 'ROLE_ADMIN_PACKAGE_MANAGE'],
                                        this.handleLogoutUser,
                                        'subscription.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'subscription-list'
                                    )}
                                />
                                <Route
                                    path='/subscription/details/:id'
                                    component={withAuth(
                                        SubscriptionContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE', 'ROLE_ADMIN_PACKAGE_MANAGE'],
                                        this.handleLogoutUser,
                                        'subscription.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'subscription-list'
                                    )}
                                />
                                <Route
                                    path='/report-list'
                                    component={withAuth(
                                        ReportListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_PAYMENT_MANAGE'],
                                        this.handleLogoutUser,
                                        'reports.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'report-list'
                                    )}
                                />
                                <Route
                                    path='/order-list'
                                    component={withAuth(
                                        OrderListContainer,
                                        'VIEW',
                                        ['ROLE_USER_LEX_DOCS_MANAGE', 'ROLE_USER_LEKSY_MANAGE'],
                                        this.handleLogoutUser,
                                        'order.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'order-list'
                                    )}
                                />
                            </Switch>
                        </main>
                    </div>
                </Router>
                {((!authService.loggedIn() && this.isLexyPortal()) || this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE')) && (
                    <React.Fragment>
                        <div id='footer-div-margin'></div>
                        <Footer isMobile={device.type === 'mobile'} />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
    footerVisible() {
        return !this.authService.loggedIn();
    }
}

export default App;
