import BaseServiceCrud from './BaseServiceCrud';

export default class CategoryService extends BaseServiceCrud {
    constructor() {
        super();
        this.path = 'categories';
    }

    getCategory(id) {
        return this.fetch(`${this.domain}/${this.path}/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getAll() {
        return this.fetch(`${this.domain}/${this.path}/all`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            id: criteria.id,
            first_result: criteria.firstResult,
            max_results: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            name: criteria.name,
        });
    }

    update(element) {
        return this.fetch(`${this.domain}/${this.path}/edit/${element.id}`, {
            method: 'PUT',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
