import React from 'react';
import Initial from './App';
const LexDocsStyleComponent = React.lazy(() => import('./lexdocsStyleComponent.tsx'));
const LexyStyleComponent = React.lazy(() => import('./lexyStyleComponent.tsx'));

export const App: React.FC = () => {
    return (
        <React.Fragment>
            {false && <Initial />}
            <React.Suspense fallback={<></>}>
                {process.env.REACT_APP_PORTAL_TYPE === 'LEXDOCS' && <LexDocsStyleComponent />}
                {process.env.REACT_APP_PORTAL_TYPE === 'LEXY' && <LexyStyleComponent />}
            </React.Suspense>
        </React.Fragment>
    );
};
export default App;
