import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';

const options = {
    order: ['sessionStorage', 'navigator'],
    sessionStorage: 'i18nextLng',
    navigator: 'language',
};
i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .use(intervalPlural)
    .init({
        fallbackLng: 'pl',
        backend: {
            loadPath: `./locales/{{lng}}/{{ns}}.json`,
        },
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: options,
        cache: [],
        react: {
            bindI18n: 'added loaded languageChanged',
            bindI18nStore: 'added loaded languageChanged',
            useSuspense: true,
        },
        supportedLngs: ['pl', 'en'],
        load: 'languageOnly',
    });

export default i18n;
