import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import LawyerService from '../../services/LawyerService';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import LawyerDialog from './LawyerDialog';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import {CustomMessages} from '../../components/CustomMessages';
import ActionLink from '../../components/ActionLink';
import DialogResizer from '../../components/DialogResizer';
import AppPrefixUtils from '../../utils/AppPrefixUtils';

class LawyerListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new LawyerService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            dialogVisibile: false,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            roleOptions: [],
        };
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }
    getDataKey() {
        return 'id';
    }

    getListTitle() {
        return 'lawyer.listTitle';
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    updateSearchCriteria(criteria) {
        return {
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            text: criteria.text,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            assignedUserId: criteria.assignedUserId,
        };
    }

    getCleanSearchCriteria() {
        return {
            dateFrom: undefined,
            dateTo: undefined,
            text: '',
            assignedUserId: this.props.elementId,
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    getCriteriaName() {
        return 'lawyer-list-sc';
    }

    getContainerListName() {
        return 'lawyer-list-container';
    }

    renderFilter() {
        return null;
    }

    handleOpenDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            dialogVisibile: true,
            dialogViewMode: 'NEW',
        });
    }

    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState(
            {
                dialogVisibile: false,
                elementId: undefined,
            },
            () => {
                DialogResizer.removeMaskIfExistOneDialog();
                if (callback) callback();
            }
        );
    }

    prepareHeaderItems() {
        const {t} = this.props;
        return this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_USER_MANAGE')
            ? [
                  {
                      label: t('lawyer.add'),
                      onClick: this.handleOpenDialog,
                      type: 'BUTTON',
                      className: 'float-left mt-2',
                      variant: 'white',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                  },
              ]
            : [];
    }

    render() {
        return (
            <React.Fragment>
                <BreadcrumbsItem to='/lawyer-list'>{'Prawnicy'}</BreadcrumbsItem>
                <div className='container-fluid'>
                    {this.state.dialogVisibile ? (
                        <LawyerDialog
                            className='lawyer-dialog'
                            statusOptions={this.state.statusOptions}
                            elementId={this.props.elementId}
                            onHide={(e) => {
                                this.handleCloseDialog(e);
                            }}
                            visible={this.state.dialogVisibile}
                            viewMode={this.state.dialogViewMode}
                            afterSave={(e) =>
                                this.handleCloseDialog(e, () => {
                                    this.readMessage();
                                    this.refreshTable();
                                    this.unblockUi();
                                })
                            }
                            blockUi={this.blockUi}
                            unblockUi={this.unblockUi}
                            _isMounted={this._isMounted}
                        />
                    ) : null}
                    <div className='container-fluid'>{this.renderView()}</div>
                </div>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
            </React.Fragment>
        );
    }

    prepareColumns() {
        return [
            {field: 'email', header: 'Email', sortable: true},
            {field: 'firstName', header: 'Imię', sortable: true},
            {field: 'lastName', header: 'Nazwisko', sortable: true},
            {
                key: 'actions',
                header: '',
                body: this.actionTemplate,
            },
        ];
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <div className='float-center'>
                <img
                    src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/search.svg`)}
                    onClick={this.handleGoToDetail.bind(this, href)}
                    alt=''
                    className='cursor-pointer '
                />
            </div>
        );
    }
}

LawyerListContainer.defaultProps = {
    detailUrl: '/#/lawyer/details',
    editUrl: '/#/lawyer/edit',
    backUrl: '/#/law-office/details',
    lazy: true,
};

LawyerListContainer.propTypes = {
    detailUrl: PropTypes.string,
};

export default withTranslation()(LawyerListContainer);
