import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import CategoryService from '../../services/CategoryService';
import YesNoDialog from '../../components/YesNoDialog';
import {t} from 'i18next';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import DialogResizer from '../../components/DialogResizer';

class CategoryContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new CategoryService());
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.state = {
            loading: true,
            elementId: props.params.id,
            element: {
                id: undefined,
                name: '',
            },
            dialogVisibile: false,
        };
        this.showDeleteConfirmation = this.showDeleteConfirmation.bind(this);
        this.hideDeleteConfirmation = this.hideDeleteConfirmation.bind(this);
    }

    getContainerListName() {
        return 'category-list-container';
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('category.updateSuccessfull');
    }

    getRemoveSucces() {
        const {t} = this.props;
        return t('category.deleteSuccessfull');
    }

    prepareHeaderItems() {
        const {viewMode} = this.props;
        return this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE') && viewMode === 'VIEW'
            ? [
                  {
                      label: 'Usuń',
                      variant: 'button cancel-button gray',
                      onClick: this.showDeleteConfirmation,
                  },
                  this.prepareEditButton(true),
              ]
            : [];
    }

    prepareFooterItems() {
        const {cancelUrl, viewMode} = this.props;
        const {elementId} = this.props;
        let goBackUrl = `${cancelUrl}/${elementId}`;

        return [
            {
                label: 'Anuluj',
                className: 'gray',
                href: AppPrefixUtils.locationHrefUrl(goBackUrl),
                rendered: viewMode === 'NEW' || viewMode === 'EDIT',
            },
            {
                label: 'Zapisz',
                variant: 'accent',
                className: 'float-right',
                onClick: this.handleFormSubmit,
                rendered: viewMode === 'NEW' || viewMode === 'EDIT',
            },
        ];
    }
    breadCrumbItems() {
        return [
            {name: 'Kategorie', url: '/#/category-list'},
            {name: 'Kategoria', url: `/#/category/details/${this.state.element?.id}`},
        ];
    }

    getBackLabel() {
        return 'Wróć do listy kategorii';
    }

    showDeleteConfirmation() {
        this.setState({
            dialogVisibile: true,
        });
    }

    hideDeleteConfirmation(callback) {
        this.setState(
            {
                dialogVisibile: false,
            },
            () => {
                DialogResizer.removeMaskIfExistOneDialog();
                if (callback) {
                    callback();
                }
            }
        );
    }

    render() {
        return (
            <React.Fragment>
                <YesNoDialog
                    visible={this.state.dialogVisibile}
                    header={t('category.deleteHeader')}
                    name='visible'
                    className='remove-category-dialog'
                    onChange={(type, x, target) => {
                        if (target.value) {
                            this.service
                                .remove(this.state.element)
                                .then((response) => {
                                    DialogResizer.removeMaskIfExistOneDialog();
                                    window.location.href = AppPrefixUtils.locationHrefUrl(this.props.backUrl);
                                    this.afterDeleteSuccess(response);
                                })
                                .catch((err) => {
                                    this.hideDeleteConfirmation(() => {
                                        this.showErrorMessage(err.message);
                                    });
                                });
                        } else {
                            this.hideDeleteConfirmation();
                        }
                    }}
                    onHide={() => {
                        this.hideDeleteConfirmation();
                    }}
                >
                    Czy na pewno chcesz usunąć kategorię?
                </YesNoDialog>
                {this.renderView()}
            </React.Fragment>
        );
    }

    renderDetails() {
        const {viewMode} = this.props;
        return (
            <React.Fragment>
                {this.renderProperStructureForDetail(
                    <>
                        <div className='row'>
                            <InputTextComponent
                                id='name'
                                name='name'
                                label={'Nazwa kategorii'}
                                colClass='col-md-6'
                                value={this.state.element.name}
                                validator={this.validator}
                                validators={'required|max:100'}
                                onChange={this.handleChange}
                                viewMode={viewMode}
                            />
                        </div>
                    </>
                )}
            </React.Fragment>
        );
    }
}

CategoryContainer.defaultProps = {
    backUrl: '/#/category-list',
    editUrl: '/#/category/edit',
    cancelUrl: '/#/category/details',
};

CategoryContainer.propTypes = {
    backUrl: PropTypes.string,
    editUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
};

export default withTranslation()(CategoryContainer);
