import React, {Component} from 'react';
import AuthService from '../services/AuthService';
import UrlUtils from './UrlUtils';

export default function withAuth(
    AuthComponent,
    viewMode,
    roles,
    logoutFunc,
    label,
    whiteBackgroundStyle = 'default-white-background',
    backUrl,
    hideHeader = false,
    afterRenderComponent,
    setActiveMenu,
    menuName
) {
    const authService = new AuthService();

    return class AuthWrapped extends Component {
        constructor() {
            super();
            this.state = {
                user: null,
            };
            this.mainRef = React.createRef();
        }

        containerStyle() {
            if (UrlUtils.isCreator() && !document.getElementById('pro-sidebar')) {
                return 'container-custom';
            }
            if (process.env.REACT_APP_PORTAL_TYPE === 'LEXDOCS') {
                return 'container-fluid';
            } else {
                return 'container-custom';
            }
        }
        containsUrl(array) {
            let contains = false;
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                if (window.location.href.includes(element)) {
                    contains = true;
                }
            }
            return contains;
        }

        componentDidMount() {
            if (window.location.href.includes('404') || window.location.href.includes('403')) {
                console.log(window.location.href, '404');
            }
            if (afterRenderComponent) {
                afterRenderComponent(true);
            }
            if (setActiveMenu) {
                setActiveMenu(menuName);
            }
            if (!authService.loggedIn()) {
                if (!!logoutFunc) {
                    logoutFunc(this.props.location?.pathname);
                } else {
                    this.props.history.replace('/login?location=' + this.props.location?.pathname);
                }
            } else {
                const userRoles = authService.getRoles();
                let authorized = false;
                if (roles !== undefined) {
                    roles.forEach((role) => {
                        if (userRoles.includes(role)) {
                            authorized = true;
                        }
                    });
                } else {
                    authorized = true;
                }
                if (authorized) {
                    try {
                        const profile = authService.getProfile();
                        this.setState({
                            user: profile,
                            viewMode: viewMode,
                            elementId: this.props.match.params.id,
                            params: this.props.match.params,
                        });
                    } catch (err) {
                        authService.logout();
                        this.props.history.replace('/login');
                    }
                } else {
                    this.props.history.replace('/403');
                }
            }
        }

        render() {
            if (this.state.user) {
                const containerStyle = this.containerStyle();
                return (
                    <React.Fragment>
                        <div className={`${authService.loggedIn() ? `header-white-background ${whiteBackgroundStyle}` : ''}`} />
                        <div
                            ref={this.mainRef}
                            className={`${authService.loggedIn() ? `${containerStyle} ${whiteBackgroundStyle}` : ''} auth-component`}
                            style={{position: 'relative'}}
                        >
                            <AuthComponent
                                mainRef={this.mainRef}
                                history={this.props.history}
                                currentUser={this.state.user}
                                logoutFunc={logoutFunc}
                                viewMode={this.state.viewMode}
                                elementId={this.state.elementId}
                                backUrl={backUrl}
                                {...this.state}
                            />
                        </div>
                    </React.Fragment>
                );
            } else {
                return null;
            }
        }
    };
}
