import {InputText} from 'primereact/inputtext';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {Redirect} from 'react-router-dom';
import BaseContainer from '../baseContainers/BaseContainer';
import ActionButton from '../components/ActionButton';
import {CustomMessages} from '../components/CustomMessages';
import SimpleReactValidator from '../components/validator';
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import BlockUi from './../components/waitPanel/BlockUi';
import {Card} from 'primereact/card';
import {Button} from 'react-bootstrap';

class RemindPasswordContainer extends BaseContainer {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.authService = new AuthService();
        this.userService = new UserService();
        this.jwtRefreshBlocked = true;
        this.state = {
            username: '',
            password: '',
            redirectToReferrer: true,
            authValid: true,
            showCaptcha: false,
            captchaPreventAction: false,
            loggedIn: false,
        };
        this.validator = new SimpleReactValidator({}, this.translate);
    }
    refreshJwtToken() {}

    componentDidMount() {
        super.componentDidMount();
        if (this.props.handlePreLogoutUser) {
            this.props.handlePreLogoutUser(() => {});
        }
        const values = queryString.parse(this.props.location.search);
        this.targetLocation = values.location;
        //Messages.multiReadPersistMessage(['login-page'], this.messages);
    }
    persistMessage(severity, summary, detail) {
        this.saveCookie(
            'login-container',
            JSON.stringify({
                severity,
                summary,
                detail,
            })
        );
    }
    handleFormSubmit(e) {
        e.preventDefault();
        const {t} = this.props;
        if (this.validator.allValid()) {
            this.blockUi();
            new UserService()
                .sendLinkForGenerateNewPassword(this.state.email)
                .then(() => {
                    this.persistMessage('success', '', t('password.resetSuccessLabel'));
                    window.location.href = AppPrefixUtils.locationHrefUrl('/');
                })
                .catch((err) => {
                    this.showErrorMessage(err.message);
                    this.unblockUi();
                });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.setState({
                valid: false,
            });
            this.forceUpdate();
        }
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    render() {
        const {t} = this.props;
        return (
            <React.Fragment>
                <BlockUi
                    tag='div'
                    blocking={this.state.blocking || this.state.loading}
                    loader={this.loader}
                    rendered={!this.props.embedded}
                >
                    <div id='main-login'>
                        <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} rendered={!this.props.embedded} />
                        <div className='login-background-container'>
                            <Card className='details-card no-border'>
                                <form
                                    className='login-container'
                                    onSubmit={(e) => {
                                        if (!this.state.captchaPreventAction) {
                                            this.handleFormSubmit(e);
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            if (!this.state.captchaPreventAction) {
                                                this.handleFormSubmit();
                                            }
                                        }
                                    }}
                                    //avoid setting red border just after the page has loaded (red border because of undefined required value in FF)
                                    noValidate
                                >
                                    <div className='login-form-container'>
                                        <div className='title'>Zmiana hasła</div>
                                        <div className='reset-password-subtitle desc-content'>
                                            {this.props.type === 'REMIND'
                                                ? t('password.remindInstruction')
                                                : t('password.firstLoginInstruction')}
                                        </div>
                                        <div className='login-label'>{t('password.email')}</div>
                                        <div location='user_field'>
                                            <InputText
                                                className='login-input'
                                                aria-label={t('password.email')}
                                                key={'email'}
                                                id={'email'}
                                                name={'email'}
                                                placeholder={''}
                                                style={{width: '100%'}}
                                                value={this.state.email}
                                                onChange={(e) => this.handleChange('TEXT', undefined, e, undefined, '')}
                                                required={true}
                                                validator={this.validator}
                                                validators='required|email|max:50'
                                            />
                                            <div aria-live='assertive'>
                                                {this.validator.message(
                                                    'email',
                                                    t('password.email'),
                                                    this.state.email,
                                                    'required|email|max:50'
                                                )}
                                            </div>
                                        </div>
                                        <div className='margin-bottom-32'></div>
                                        <div className='centered-buttons'>
                                            <ActionButton
                                                label={t('password.send')}
                                                variant='send-reminder all-width-button'
                                                handleClick={this.handleFormSubmit}
                                                disabled={this.state.captchaPreventAction}
                                            />
                                            <ActionButton
                                                label={t('details.cancel')}
                                                variant='cancel-button no-border no-shadow back-label'
                                                href={AppPrefixUtils.locationHrefUrl('/#/login')}
                                                disabled={this.state.captchaPreventAction}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>
                </BlockUi>
            </React.Fragment>
        );
    }
}
RemindPasswordContainer.propTypes = {
    onAfterLogin: PropTypes.func,
};
export default withTranslation()(RemindPasswordContainer);
