import PropTypes from 'prop-types';
import React from 'react';
import BaseDetailsContainer from '../../../baseContainers/BaseDetailsContainer';
import InputTextComponent from '../../../components/inputs/InputTextComponent';
import InputDropdownComponent from '../../../components/inputs/InputDropdownComponent';
import {t} from 'i18next';
import ProfileChangePasswordDialog from './ProfileChangePasswordDialog';
import {CustomMessages} from '../../../components/CustomMessages';
import AppPrefixUtils from '../../../utils/AppPrefixUtils';
import InternalUserService from '../../../services/InternalUserService';
import InputMultiSelectComponent from '../../../components/inputs/InputMultiSelectComponent';
import DivContainer from '../../../components/DivContainer';
import InputConsultantComponent from '../../../components/inputs/ePrawnik/InputConsultantComponent';
import InputAddressComponent from '../../../components/inputs/ePrawnik/InputAddressComponent';
import InputPhoneComponent from '../../../components/inputs/ePrawnik/InputPhoneComponent';
import AuthService from '../../../services/AuthService';
import DialogResizer from '../../../components/DialogResizer';

class ProfileContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.state = {
            viewMode: props.viewMode,
            newPassword: '',
            repeatPassword: '',
            element: {},
            showConfirmDialog: false,
        };
        this.backLinkRendered = false;
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.showEditPasswordConfirmation = this.showEditPasswordConfirmation.bind(this);
        this.hideEditPasswordConfirmation = this.hideEditPasswordConfirmation.bind(this);
        this.hideEditUserConfirmation = this.hideEditUserConfirmation.bind(this);
        this.isConsultantSelected = this.isConsultantSelected.bind(this);
        this.authService = new AuthService();
    }
    hideEditUserConfirmation() {
        this.setState({showConfirmDialog: false});
    }

    setElement() {
        if (this.validator.allValid()) {
            this.blockUi();
            this.service
                .get(this.props.currentUser.userId)
                .then((data) => {
                    this.setState(
                        (prevState) => ({
                            element: {
                                ...prevState.element,
                                id: data.id,
                                industry: data.industry,
                                email: data.email,
                                firstName: data?.firstName,
                                permissions: data?.permissions.filter((el) => el.selected),
                                lastName: data?.lastName,
                                person: data.person,
                                chatFee: data?.chatFee,
                                videoChatFee: data?.videoChatFee,
                                mailingFee: data?.mailingFee,
                                bankAccountNumber: data?.bankAccountNumber,
                                phoneNumber: data?.phoneNumber,
                                password: data.password,
                                portal: data.portal,
                                role: data.role,
                                status: data.status,
                                specializations: data.specializations,
                            },
                            permissions: data.permissions,
                        }),
                        () => this.initAfterSetElement(data)
                    );
                })
                .catch((err) => {
                    this.handleGetDetailsError(err);
                });
        }
    }

    initAfterSetElement(data) {
        if (data.person === 'LAW_OFFICE_MANAGER') {
            this.setState((prevState) => ({
                element: {
                    ...prevState.element,
                    ownedLawOffice: {
                        nip: data.ownedLawOffice?.nip,
                        name: data.ownedLawOffice?.name,
                        address: {
                            city: data?.ownedLawOffice?.address?.city,
                            postalCode: data?.ownedLawOffice.address?.postalCode,
                            houseNumber: data?.ownedLawOffice.address?.houseNumber,
                            streetName: data?.ownedLawOffice.address?.streetName,
                            streetNumber: data?.ownedLawOffice.address?.streetNumber,
                        },
                    },
                },
            }));
        } else {
            this.setState((prevState) => ({
                element: {
                    ...prevState.element,
                    nip: data.nip,
                    companyName: data.companyName,
                    address: {
                        city: data?.address?.city,
                        postalCode: data?.address?.postalCode,
                        houseNumber: data?.address?.houseNumber,
                        streetName: data?.address?.streetName,
                        streetNumber: data?.address?.streetNumber,
                    },
                },
            }));
        }

        this.unblockUi();
    }

    getUpdateSucces() {
        return 'Konto zostało zaktualizowane';
    }

    readMessage() {
        const messageFromStorage = this.readCookie(this.getContainerListName());
        if (messageFromStorage !== undefined && messageFromStorage != null) {
            const message = JSON.parse(messageFromStorage);
            this.removeCookie(this.getContainerListName());
            if (
                message.severity !== undefined &&
                message.severity != null &&
                message.summary !== undefined &&
                message.summary != null &&
                message.detail !== undefined &&
                message.detail != null
            ) {
                this.showMessage(message.severity, message.summary, message.detail);
            }
        }
    }

    initBeforeSetElement() {
        this.readMessage();
    }

    handleChangePassword(event) {
        const varName = event.target.name;
        const varValue = event.checked ? event.checked : event.target.value;
        if (this._isMounted) {
            this.setState((prevState) => ({
                element: {
                    ...prevState.element,
                    [varName]: varValue,
                },
            }));
        }
    }
    showEditPasswordConfirmation() {
        this.setState({dialogVisibile: true});
    }

    hideEditPasswordConfirmation() {
        this.setState({dialogVisibile: false}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
        });
    }
    
    prepareChangePasswordButton(){
       return {
            label: 'Zmień hasło',
            onClick: this.showEditPasswordConfirmation,
            variant: 'blue',
            iconSide: 'left',
            iconSize: 'xs',
            iconColor: 'blue',
            className: 'white',
        }
    }
    prepareLogoutButton(){
        const logoutBtnClassName =  this.isMobile() ? "mb-4" :  "";
        return {
            label: 'Wyloguj',
            onClick: this.props.logoutFunc,
            variant: '',
            iconSide: 'left',
            iconSize: 'xs',
            iconColor: '',
            className:  `white ${logoutBtnClassName}`};
    }
    prepareHeaderItems() {
        const headerItems = [];
        const {viewMode} = this.state;

        if(this.isMobile() && this.isLexyPortal())
            headerItems.push(this.prepareLogoutButton());
        
        headerItems.push(this.prepareEditButton(viewMode === 'VIEW'));
        headerItems.push(this.prepareChangePasswordButton());
        
        if(!this.isMobile() && this.isLexyPortal())
            headerItems.push(this.prepareLogoutButton());
        
        return headerItems;
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    handleFormSubmit() {
        if (this.validator.allValid()) {
            const {backUrl} = this.props;
            const {element} = this.state;
            let successfullUrl = `${backUrl}/${element.id}`;
            this.service
                .updateMyAccount(element)
                .then(() => {
                    this.persistMessage('success', '', this.getUpdateSucces());
                    window.location.href = AppPrefixUtils.locationHrefUrl(successfullUrl);
                })
                .catch((err) => {
                    this.showErrorMessage(err.message);
                });
        } else {
            this.validator.showMessages();
            this.scrollToError = true;
            this.forceUpdate();
        }
    }

    render() {
        return (
            <React.Fragment>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                {this.state.dialogVisibile ? (
                    <ProfileChangePasswordDialog
                        elementId={this.props.elementId}
                        onHide={(e) => {
                            this.hideEditPasswordConfirmation(e);
                        }}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}
                        afterSave={(e) => {
                            this.showSuccessMessage('Hasło zostało zmienione');
                            this.setState(
                                {
                                    dialogVisibile: false,
                                },
                                () => {
                                    DialogResizer.removeMaskIfExistOneDialog();
                                }
                            );
                        }}
                        className='profile-change-password-dialog'
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}
                {this.renderView()}
            </React.Fragment>
        );
    }
    getContainerListName() {
        return 'manage-account';
    }

    isLawyer() {
        const person = this.state.element?.person;
        return person === 'LAW_OFFICE' || person === 'LAWYER' || person === 'LAW_OFFICE_MANAGER';
    }
    isClient() {
        return this.authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE');
    }

    renderCompanyAndLawOfficeComponents() {
        return (
            <div className='row'>
                <InputTextComponent
                    id='companyName'
                    name='companyName'
                    label={'Nazwa kancelarii'}
                    colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                    value={this.state.element?.companyName}
                    validator={this.validator}
                    validators='required|max:50'
                    onChange={this.handleChange}
                    viewMode={this.state.viewMode}
                />
                <InputTextComponent
                    id='nip'
                    name='nip'
                    label={'Numer NIP'}
                    colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                    value={this.state.element?.nip}
                    validator={this.validator}
                    validators='required|nip'
                    onChange={this.handleChange}
                    viewMode={this.state.viewMode}
                />
                <InputAddressComponent
                    element={this.state.element}
                    validator={this.validator}
                    rendered={true}
                    handleChange={this.handleChange}
                    stateField={'element.address'}
                    viewMode={this.props.viewMode}
                />
            </div>
        );
    }

    isConsultantSelected() {
        let permission = this.state?.element?.permissions;
        return permission?.find((el) => el.permissionGroup === 'ROLE_LAW_OFFICE_CONSULTANT' && el.selected === true) ? true : false;
    }

    renderLawOfficeManagerComponents() {
        return (
            <div className='row'>
                <InputTextComponent
                    id='name'
                    name='name'
                    label={'Nazwa kancelarii'}
                    colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                    value={this.state.element?.ownedLawOffice?.name}
                    validator={this.validator}
                    validators='required|max:50'
                    onChange={this.handleChange}
                    viewMode={this.state.viewMode}
                    stateField={'element.ownedLawOffice'}
                />
                <InputTextComponent
                    id='nip'
                    name='nip'
                    label={'Numer NIP'}
                    colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                    value={this.state.element?.ownedLawOffice?.nip}
                    validator={this.validator}
                    validators='required|nip'
                    onChange={this.handleChange}
                    viewMode={this.state.viewMode}
                    stateField={'element.ownedLawOffice'}
                />
                <InputAddressComponent
                    element={this.state.element?.ownedLawOffice}
                    validator={this.validator}
                    rendered={true}
                    handleChange={this.handleChange}
                    stateField={'element.ownedLawOffice.address'}
                    viewMode={this.props.viewMode}
                />
            </div>
        );
    }

    renderDetails() {
        const person = this.state.element.person;
        return (
            <React.Fragment>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                {this.renderProperStructureForDetail(
                    <>
                        {person !== 'COMPANY' && person !== undefined ? (
                            <React.Fragment>
                                <InputTextComponent
                                    id='firstName'
                                    name='firstName'
                                    label={'Imię'}
                                    colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                                    value={this.state.element?.firstName}
                                    validator={this.validator}
                                    validators='required|alpha_space_dash|max:100'
                                    onChange={this.handleChange}
                                    viewMode={this.state.viewMode}
                                />
                                <InputTextComponent
                                    id='lastName'
                                    name='lastName'
                                    label={'Nazwisko'}
                                    colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                                    value={this.state.element?.lastName}
                                    validator={this.validator}
                                    validators='required|alpha_space_dash|max:100'
                                    onChange={this.handleChange}
                                    viewMode={this.state.viewMode}
                                />
                            </React.Fragment>
                        ) : null}
                        <InputTextComponent
                            id='email'
                            name='email'
                            label={'Email'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            value={this.state.element.email}
                            validator={this.validator}
                            validators='required|email'
                            onChange={this.handleChange}
                            viewMode={'VIEW'}
                        />
                        <InputTextComponent
                            id='industry'
                            name='industry'
                            label={'branża'}
                            rendered={person === 'COMPANY'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            value={this.state.element.industry}
                            validator={this.validator}
                            validators='required|max:50'
                            onChange={this.handleChange}
                            viewMode={this.state.viewMode}
                        />
                        <InputTextComponent
                            id='bankAccountNumber'
                            name='bankAccountNumber'
                            label='Numer konta bankowego'
                            value={this.state.element.bankAccountNumber}
                            onChange={this.handleChange}
                            rendered={this.isLawyer() && this.state.element.role.name !== 'Pracownik kancelarii RPKP'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            validators='required|numeric|min:26|max:26'
                            validator={this.validator}
                            viewMode={this.state.viewMode}
                        />
                        <InputPhoneComponent
                            id='phoneNumber'
                            name='phoneNumber'
                            label='Numer telefonu'
                            rendered={this.isLawyer() && this.state.element.role.name !== 'Pracownik kancelarii RPKP'}
                            value={this.state.element.phoneNumber}
                            onChange={this.handleChange}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            validators='required|phone|max:9|min:9'
                            validator={this.validator}
                            viewMode={this.state.viewMode}
                        />
                        <InputDropdownComponent
                            id='status'
                            name='status'
                            label={'Status'}
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            value={t(`status.${this.state.element.status}`)}
                            validator={this.validator}
                            options={this.state.statusOptions}
                            onChange={this.handleChange}
                            viewMode={'VIEW'}
                            optionLabel='label'
                            dataKey='value'
                            valueView={this.state.element.status ? this.state.element.status.label : ''}
                        />
                        <InputMultiSelectComponent
                            id='specializations'
                            name='specializations'
                            colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                            label='Specjalizacje'
                            rendered={this.isLawyer()}
                            value={this.state.element?.specializations}
                            validator={this.validator}
                            validators={'required|array_required'}
                            onChange={this.handleChange}
                            viewMode={'VIEW'}
                            options={this.state.specializations}
                            dataKey='id'
                            optionLabel='name'
                            filter
                            filterBy='name'
                        />
                        <InputMultiSelectComponent
                            id='permissions'
                            name='permissions'
                            colClass='col-md-8'
                            label='Uprawnienia'
                            rendered={!this.isClient()}
                            value={this.state.element?.permissions}
                            validator={this.validator}
                            validators={'required|array_required'}
                            onChange={this.handleChange}
                            viewMode={'VIEW'}
                            options={this.state.element.permissions}
                            dataKey='id'
                            optionLabel='description'
                        />
                        <InputConsultantComponent
                            dialogMode={false}
                            element={this.state.element}
                            validator={this.validator}
                            rendered={this.isConsultantSelected() && this.state.element.role.name !== 'Pracownik kancelarii RPKP'}
                            handleChange={this.handleChange}
                            viewMode={'VIEW'}
                        />
                        <DivContainer
                            rendered={this.isLawyer() && this.state.element.role.name !== 'Pracownik kancelarii RPKP'}
                            className='row'
                        >
                            <hr className='line-separator' />
                            <InputTextComponent
                                id='person'
                                label={'Typ kancelarii'}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'
                                value={t(`PersonType.${person}`)}
                                validator={this.validator}
                                validators='required|alpha|max:100'
                                onChange={this.handleChange}
                                viewMode={'VIEW'}
                            />
                        </DivContainer>
                        {person === 'COMPANY' || person === 'LAW_OFFICE'
                            ? this.renderCompanyAndLawOfficeComponents()
                            : person === 'LAW_OFFICE_MANAGER'
                            ? this.renderLawOfficeManagerComponents()
                            : null}
                    </>
                )}
            </React.Fragment>
        );
    }
}

ProfileContainer.defaultProps = {
    cancelUrl: '/#/manage-account/details',
    detailUrl: '/#/manage-account/details',
    editUrl: '/#/manage-account/edit',
    backUrl: '/#/manage-account/details',
    changePackageUrl: '/#/change-package',
};

ProfileContainer.propTypes = {
    cancelUrl: PropTypes.string,
    detailUrl: PropTypes.string,
    editUrl: PropTypes.string,
    changePackageUrl: PropTypes.string,
};

export default ProfileContainer;
