import {Dialog} from 'primereact/dialog';
import React from 'react';
import DialogResizer from './DialogResizer';

class PrimiDialog extends React.Component {
    constructor(props) {
        super(props);
        this.recalculateDialogProperties = this.recalculateDialogProperties.bind(this);
        this.excludedClassesFromUpdating = this.excludedClassesFromUpdating.bind(this);
    }

    render() {
        return (
            <Dialog
                {...this.props}
                resizable={false}
                onHide={() => {
                    DialogResizer.removeMaskIfExistOneDialog();
                    this.props.onHide();
                }}
            />
        );
    }

    recalculateDialogProperties() {
        if (!!this.props.visible) {
            DialogResizer.recalculateDialog();
        }
    }

    excludedClassesFromUpdating() {
        return [
            'summary-payment-consultation',
            'small-dialog reminder-dialog',
            'payment-peronal-data-dialog',
            'reminder-dialog',
            'send-to-contractor-dialog ',
            'rating-dialog',
            'confirmation-exit-edit-dialog small-dialog',
            'file-dialog col-xl-4 col-lg-6 col-md-6 col-sm-12',
            'confirmation-found-consultation-reservation',
        ];
    }

    componentDidUpdate(prevProps) {
        if (this.excludedClassesFromUpdating().includes(prevProps?.className)) {
            return;
        }
        console.log(prevProps?.className);

        if (this.props.visible === true) {
            setTimeout(function () {
                if (this.validator && !!this.validator.messagesShown) {
                    this.validator.recalculateDialog();
                }
                DialogResizer.recalculateDialog();
            }, 1);
        }
        if (!this.props.visible) {
            DialogResizer.removeMaskIfExistOneDialog();
        }
    }
    componentWillUnmount() {
        setTimeout(function () {
            const dialogs = Array.from(document.getElementsByClassName('p-dialog'));

            if (dialogs.length === 0) {
                DialogResizer.removeMaskIfExistOneDialog();
            }
        }, 1);
        window.removeEventListener('resize', this.recalculateDialogProperties, false);
    }

    componentDidMount() {
        const {visible} = this.props;
        if (visible) {
            DialogResizer.recalculateDialog();
        }
        window.addEventListener('resize', this.recalculateDialogProperties, false);
    }
}
export default PrimiDialog;
