import BaseServiceCrud from './BaseServiceCrud';

export default class UserRoleService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'roles';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			id: criteria.id,
			first_result: criteria.firstResult,
			max_results: criteria.maxResults,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
	getPermissionsForRole(id){
		return this.fetch(`${this.domain}/${this.path}/permissions-by-role/${id}`, {
			method: 'GET'
		}).then(res => {
			return Promise.resolve(res);
		});
	}
	
	getPermissionsByUserAndRole(idUser,idRole){
		return this.fetch(`${this.domain}/${this.path}/permissions-by-user-and-role/${idUser}/${idRole}`, {
			method: 'GET'
		}).then(res => {
			return Promise.resolve(res);
		});
	}
	
	getPermissionsByRoleName(roleName){
		return this.fetch(`${this.domain}/${this.path}/permissions-by-roleName/${roleName}`, {
			method: 'GET'
		}).then(res => {
			return Promise.resolve(res);
		});
	}

	getRoles() {
		return this.fetch(`${this.domain}/${this.path}/allNotHidden`, {
			method: 'GET'
		}).then(res => {
			return Promise.resolve(res);
		});
	}	
	getRolesByNames(array) {
		return this.fetch(`${this.domain}/${this.path}/roles-by-names/${array}`, {
			method: 'GET'
		}).then(res => {
			return Promise.resolve(res);
		});
	}	
	
	getRoleByName(name) {
		return this.fetch(`${this.domain}/${this.path}/role-by-roleName/${name}`, {
			method: 'GET'
		}).then(res => {
			return Promise.resolve(res);
		});
	}
}
