import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {TreeSelect} from 'primereact/treeselect';
import {Tooltip} from 'primereact/tooltip';

class InputTreeSelectComponent extends Component {
    renderView() {
        const {
            children,
            colClass,
            id,
            insideTable,
            label,
            optionLabel,
            renderValue,
            showLabel,
            value,
            valueView,
            validateViewMode,
            validator,
            validators,
        } = this.props;
        let displayValue = '';
        if (valueView) {
            displayValue = valueView;
        } else if (optionLabel && value && value[optionLabel]) {
            displayValue = value[optionLabel];
        } else if (value && (value instanceof String || typeof value === 'string')) {
            displayValue = value;
        }
        return (
            <div className={insideTable ? '' : colClass}>
                <div className={insideTable ? '' : 'row'}>
                    <div className={insideTable ? '' : 'col-md-12 form-group'}>
                        {label !== undefined && showLabel ? (
                            <label id={`${id}-label-id`} className='p-label' htmlFor={id} style={{width: '100%'}}>
                                {label}
                            </label>
                        ) : null}
                        <span aria-label={label} aria-labelledby={`${id}-label-id`} className='p-inputtext-view'>
                            {renderValue ? renderValue() : displayValue}
                        </span>
                        {children ? children() : null}
                        {validateViewMode && validator ? validator.message(id, label, value, validators) : null}
                    </div>
                </div>
            </div>
        );
    }

    renderEdit() {
        return this.renderNew();
    }

    renderNew() {
        const {
            children,
            colClass,
            clearFunction,
            filter,
            filterBy,
            id,
            insideTable,
            outerArea,
            label,
            onChange,
            options,
            placeholder,
            selectionMode,
            showClear,
            showLabel,
            valueTemplate,
            validator,
            validators,
            value,
            tooltip,
        } = this.props;
        const required = validators !== undefined && validators.includes('required') && !validators.includes('not_required');

        const showClearValueTemplate = (values) =>
            values?.map((value) => (
                <div class='p-treeselect-label-container'>
                    <div class='p-treeselect-label-with-trash'>
                        <div class='p-treeselect-label'>{value.label}</div>
                        <div class='p-treeselect-clear' role='button' aria-haspopup='listbox' aria-expanded='false'>
                            <span
                                onClick={(e) => {
                                    if (e) {
                                        e.preventDefault();
                                    }
                                    clearFunction(e, value);
                                }}
                                class='p-treeselect-clear-icon pi pi-times'
                            ></span>
                        </div>
                    </div>
                </div>
            ));
        return (
            <div className={`${insideTable ? '' : colClass} `}>
                {/* {tooltip ? <Tooltip target={`#${id}-label-id`} /> : null} */}
                <div className={insideTable ? '' : 'row'}>
                    <div className={`${children !== undefined ? 'col-md-12 form-group' : 'col-md-12'} ${outerArea}`}>
                        <div className={children !== undefined ? 'row' : 'row'}>
                            <div
                                className={
                                    insideTable ? 'width-100' : children !== undefined ? 'col-8 col-lg-9 col-md-7' : 'col-md-12 form-group'
                                }
                            >
                                {label !== undefined && showLabel ? (
                                    <label
                                        id={`${id}-label-id`}
                                        className='p-label'
                                        htmlFor={id}
                                        style={{width: '100%'}}
                                        data-pr-tooltip={tooltip ? tooltip : ''}
                                    >
                                        {label}
                                    </label>
                                ) : null}
                                <TreeSelect
                                    id={id}
                                    key={id}
                                    value={value}
                                    options={options}
                                    selectionMode={selectionMode}
                                    onNodeSelect={onChange}
                                    placeholder={placeholder}
                                    filter={filter}
                                    filterBy={filterBy}
                                    valueTemplate={valueTemplate ? valueTemplate : showClear && value ? showClearValueTemplate : undefined}
                                    tooltip={tooltip}
                                ></TreeSelect>
                            </div>
                            <div aria-live='assertive'>{validator ? validator.message(id, label, value, validators) : null}</div>
                        </div>
                        {children !== undefined ? <div className={'col-4 col-lg-3 col-md-5'}>{children ? children() : null}</div> : null}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {rendered, viewMode} = this.props;
        if (rendered) {
            switch (viewMode) {
                case 'NEW':
                    return this.renderNew();
                case 'EDIT':
                    return this.renderEdit();
                case 'VIEW':
                default:
                    return this.renderView();
            }
        } else {
            return null;
        }
    }
}

InputTreeSelectComponent.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    disabled: false,
    filter: false,
    placeholder: 'Wybierz',
    rendered: true,
    showClear: false,
    showLabel: true,
    validateViewMode: false,
    validators: 'required',
    outerArea: '',
    viewMode: 'VIEW',
    selectionMode: 'single',
    insideTable: false,
    showClear: true,
    clearFunction: () => {},
};

InputTreeSelectComponent.propTypes = {
    selectionMode: PropTypes.string,
    children: PropTypes.func,
    colClass: PropTypes.string,
    dataKey: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    insideTable: PropTypes.bool,
    itemTemplate: PropTypes.func,
    filter: PropTypes.bool,
    filterBy: PropTypes.string,
    label: PropTypes.string,
    onAfterStateChange: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    optionLabel: PropTypes.string,
    outerArea: PropTypes.string,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    rendered: PropTypes.bool,
    renderValue: PropTypes.func,
    showClear: PropTypes.bool,
    showLabel: PropTypes.bool,
    validateViewMode: PropTypes.bool,
    validator: PropTypes.object.isRequired,
    validators: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    valueTemplate: PropTypes.string,
    valueView: PropTypes.string,
    viewMode: PropTypes.string,
};

export default withTranslation()(InputTreeSelectComponent);
