import BaseServiceCrud from './BaseServiceCrud';

export default class ReportService extends BaseServiceCrud {
    constructor() {
        super();
        this.path = 'reports';
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,
            first_result: criteria.firstResult,
            max_results: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    add(element) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/addReport`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getCriteriaForDownloading(format, criteria) {
        return this.objToQueryString({
            format: format?.enumValue,
            id: criteria.id,
            personType: criteria?.personType,
        });
    }

    downloadZip(token, reports) {
        reports = reports.map((reports) => reports.id);
        const url = new URL(`${this.domain}/${this.path}/zip/${reports}`);
        const headers = {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cahce',
        };

        headers['Authorization'] = token;
        this.fetchGet(url, headers, reports)
            .then((res) => {
                return res.blob();
            })
            .then((data) => {
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(data);
                a.download = 'Raporty';
                a.click();
            });
    }
}
