import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import {withTranslation} from 'react-i18next';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import moment from 'moment';
import CalendarScheduleComponent from '../../components/CalendarScheduleComponent';
import {CustomMessages} from '../../components/CustomMessages';
import DivContainer from '../../components/DivContainer';
import ScheduledEventsModel from '../../model/ScheduledEventsModel';
import ScheduledConsultationService from '../../services/ScheduledConsultationService';
import SpecializationService from '../../services/SpecializationService';
import UserService from '../../services/UserService';
import ActionButton from '../../components/ActionButton';
import {Card} from 'primereact/card';
import ChatService from '../../services/ChatService';
import EventCalendarService from '../../services/EventCalendarService';
import {EventCalendarUtils} from '../../utils/EventCalendarUtils';
import BlockUi from '../../components/waitPanel/BlockUi';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';
import PrimiDialog from '../../components/PrimiDialog';
import DialogResizer from '../../components/DialogResizer';
import Constants from '../../utils/constants';
import {device} from '../../baseContainers/BaseContainer';
import CalendarTodayEventList from '../../components/CalendarTodayEventList';

const RED = EventCalendarUtils.RED;
const GREEN = EventCalendarUtils.GREEN;
const ORANGE = EventCalendarUtils.ORANGE;
const AQUA = EventCalendarUtils.AQUA;

class ScheduledEventCalendar extends BaseDetailsContainer {
    constructor(props) {
        super(props, new ScheduledConsultationService());

        this.state = {
            startDate: undefined,
            endDate: undefined,
            dialogVisibile: false,
            element: new ScheduledEventsModel(),
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            specializations: [],
            users: [],
            eventsInizialize: [],
            events: [],
            selectedEvent: [],
            onHide: false,
        };
        this.specializationService = new SpecializationService();
        this.userService = new UserService();
        this.eventCalendarService = new EventCalendarService();
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }
    getDataKey() {
        return 'id';
    }

    getCriteriaName() {
        return 'scheduled-event-calendar-sc';
    }

    getContainerListName() {
        return 'scheduled-event-calendar';
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    updateSearchCriteria(criteria) {
        return {
            status: criteria.status,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            status: '',
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    initBeforeSetElement() {
        const today = new Date();
        const initPeriodType = 'MONTHS';
        if (this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE', 'ROLE_USER_LEKSY_MANAGE')) {
            this.getScheduledEventsByClientId(today, initPeriodType);
        } else {
            this.getScheduledEventsByConsultantId(today, initPeriodType);
        }
    }

    getScheduledEventsByClientId(date, periodType) {
        this.service
            .getScheduledEventsByClientId(this.props.user.userId, date, periodType)
            .then((scheduled) => {
                const convertedEvents = this.createScheduledModel(scheduled, periodType);
                this.setState({
                    events: convertedEvents,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy wydarzeń');
            });
    }

    getScheduledEventsByConsultantId(date, periodType) {
        this.service
            .getScheduledEventsByConsultantId(this.props.user.userId, date, periodType)
            .then((scheduled) => {
                const convertedEvents = this.createScheduledModel(scheduled, periodType);
                this.setState({
                    events: convertedEvents,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy wydarzeń');
            });
    }

    getEvents(date, periodType) {
        if (this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE', 'ROLE_USER_LEKSY_MANAGE')) {
            this.getScheduledEventsByClientId(date, periodType);
        } else {
            this.getScheduledEventsByConsultantId(date, periodType);
        }
    }
    // rysujemy eventy
    createScheduledModel(scheduledEvents, periodType) {
        let scheduledEventsArray = [];
        for (let index = 0; index < scheduledEvents.length; index++) {
            let model = ScheduledEventsModel.copy(scheduledEvents[index]);
            const startDate = new Date(scheduledEvents[index].startDate);
            const endDate = new Date(scheduledEvents[index].endDate);
            let start = startDate;
            let end = endDate;

            if (periodType === 'MONTHS') {
                start = this.createDates(startDate);
                end = start;
            }

            let isListWeek = periodType === 'LIST_WEEKS' ? true : false;

            const startHour = this.createTimes(startDate);
            const endHour = this.createTimes(endDate);

            if (scheduledEvents[index].paymentStatus === 'UNPAID') isListWeek ? (model.color = RED) : (model.background = RED);
            else if (startDate.getDay() === new Date().getDay() && !scheduledEvents[index].executed) {
                let today = new Date();
                today.setMinutes(today.getMinutes() + 5);
                if (
                    (today > startDate && today < endDate && !scheduledEvents[index].executed && scheduledEvents[index].reserved) ||
                    (endDate < new Date() && !scheduledEvents[index].executed)
                )
                    isListWeek ? (model.color = ORANGE) : (model.background = ORANGE);
            } else if (scheduledEvents[index].executed) isListWeek ? (model.color = GREEN) : (model.background = GREEN);
            else if (endDate < new Date() && !scheduledEvents[index].executed) isListWeek ? (model.color = RED) : (model.background = RED);

            model.title = startHour + ' - ' + endHour;
            model.start = start;
            model.end = end;
            model.startHour = startHour;
            model.endHour = endHour;

            scheduledEventsArray.push(model);
        }

        return scheduledEventsArray;
    }

    createDates(date) {
        return moment(new Date(date)).format('YYYY-MM-DD');
    }

    createTimes(date) {
        return moment(date).format('HH:mm');
    }

    getDate(rowData) {
        let date = new Date(rowData.start);
        return <div>{date.toLocaleDateString()}</div>;
    }

    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: false, elementId: undefined}, () => {
            DialogResizer.removeMaskIfExistOneDialog();
            if (callback) callback();
        });
    }

    handleOpenDialog(e) {
        let fixedDate = new Date(e.end);
        if (e.allDay) {
            fixedDate.setDate(e.end.getDate() - 1);
        } else {
            fixedDate.setDate(e.end.getDate());
        }
        this.setState({
            viewMode: 'NEW',
            dialogVisibile: true,
            startDate: e.start,
            endDate: fixedDate,
        });
    }

    handleOpenDetailsDialog(e) {
        this.setState({
            viewMode: 'VIEW',
            visibleStartConsultationDialog: true,
            elementId: e.event._def.publicId,
            selectedEvent: e.event,
        });
    }
    todayEventList = () => {
        if (window.location.href.includes('scheduled-event-calendar') && !this.isLexyPortal() && device.type === 'mobile') {
            const todayEvents = this.getTodaysEvents();
            return (
                <div id='today-event-list'>
                    {this.getTodayTitle(todayEvents[0]?.start)}
                    {this.renderTodayEvents(todayEvents)}
                </div>
            );
        }
    };
    getTodayTitle(date) {
        if (date) {
            const today = new Date(date);
            const foundedMonth = Constants.monthOpt.find((el) => el.enumValue === parseInt(today.getMonth()));
            return <div id='dates'>{today.getDate() + ' ' + foundedMonth.label + ' ' + today.getFullYear()} </div>;
        }
        return '';
    }
    renderTodayEvents(todayEvents) {
        return todayEvents.map((event) => {
            let conultationWith = event.client;
            if (this.authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE', 'ROLE_USER_LEKSY_MANAGE')) {
                conultationWith = event.consultant;
            }
            return <div>{this.rednerTodayEvent(event.startHour, event.endHour, conultationWith)} </div>;
        });
    }
    rednerTodayEvent(startDate, endDate, conultationWith) {
        // const text = executed?
        return (
            <div className='content'>
                <div className='date'>{startDate + ' - ' + endDate}</div>
                <div className='incoming-text'>Nadchodząca konsultacja z:</div>
                <div className='consultation-with'>{conultationWith}</div>
            </div>
        );
    }

    getTodaysEvents() {
        console.log(this.state.events, 'this.state.events');
        return this.state.events
            .filter((el) => {
                return this.isTodayDate(new Date(el.start));
            })
            .filter((el) => !el.executed);
    }
    isTodayDate(dateIn) {
        const today = new Date();
        return (
            dateIn.getDate() === today.getDate() && dateIn.getMonth() === today.getMonth() && dateIn.getFullYear() === today.getFullYear()
        );
    }

    renderEventContent(eventInfo) {
        let fontSize = '11px';
        if (eventInfo.view.type === 'timeGridWeek') {
            fontSize = '10px';
        }
        return (
            <div
                style={{
                    fontSize: `${fontSize}`,
                    background: `${eventInfo.event._def.extendedProps.background}`,
                    height: '100%',
                    textDecoration: 'none',
                    color: 'white',
                }}
                className='col-12 p-1 '
            >
                Godzina:
                <br /> <span style={{fontWeight: '600'}}> {eventInfo.event.title}</span>
            </div>
        );
    }
    legend() {
        return <DivContainer id='legend' colClass='row center-in-div'></DivContainer>;
    }

    renderStartConsultationDialog() {
        const eventId = this.state?.selectedEvent?._def?.publicId;
        const event = this.state?.events?.filter((e) => e.id === parseInt(eventId))[0];
        let availableToStart = true;
        if (event?.executed || event?.fullEndDate < new Date() || event?.paymentStatus === 'UNPAID') availableToStart = false;
        return (
            <PrimiDialog
                ariaCloseIconLabel='Zamknij okno dialogowe'
                header='Konsultacja'
                visible={this.state.visibleStartConsultationDialog}
                closable={true}
                onHide={() => {
                    this.setState({visibleStartConsultationDialog: false}, () => {
                        DialogResizer.removeMaskIfExistOneDialog();
                    });
                }}
                className='small-dialog consultation-dialog'
                draggable={false}
            >
                <Card
                    className='small-dialog-card'
                    footer={() => {
                        const buttonJoinRendered =
                            this.authService.isUserInAnyRole(
                                'ROLE_LAW_OFFICE_CONSULTANT_MANAGE'
                                // 'ROLE_USER_LEKSY_MANAGE',
                                // 'ROLE_USER_LEX_DOCS_MANAGE'
                            ) && availableToStart;

                        return (
                            <div>
                                {buttonJoinRendered && <hr className='line-footer' />}
                                <div className='footer-dialog-button-container '>
                                    <ActionButton
                                        rendered={buttonJoinRendered}
                                        label='Dołącz'
                                        className='float-right'
                                        handleClick={(e) => {
                                            e.preventDefault();
                                            if (this.validator.allValid()) {
                                                console.log('startujemy konsultację', eventId);
                                                const chatService = new ChatService();
                                                chatService.startScheduledConsultation(eventId);
                                                this.setState({visibleStartConsultationDialog: false}, () => {
                                                    DialogResizer.removeMaskIfExistOneDialog();
                                                });
                                            } else {
                                                this.validator.showMessages();
                                                this.scrollToError = true;
                                                this.forceUpdate({visibleStartConsultationDialog: false}, () => {
                                                    DialogResizer.removeMaskIfExistOneDialog();
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    }}
                >
                    <div className='row'>
                        <InputTextComponent
                            id='client'
                            name='client'
                            label='Klient'
                            colClass='col-md-6 col-sm-12'
                            value={event?.client}
                            viewMode={'VIEW'}
                        />
                        <InputTextComponent
                            id='title'
                            name='title'
                            label='Godzina konsultacji'
                            colClass='col-md-6 col-sm-12'
                            value={event?.title}
                            viewMode={'VIEW'}
                        />
                    </div>
                </Card>
            </PrimiDialog>
        );
    }
    breadCrumbItems() {
        return [
            {name: 'Zaplanowane konsultacje', fontSize: '0px', url: '/#/scheduled-event-calendar'},
            {name: <b style={{fontSize: '18px'}}> {document.getElementsByClassName('fc-toolbar-title')[0]?.innerText}</b>, url: null},
        ];
    }
    render() {
        const headerToolbar = {
            right: 'today prev,next',
            left: 'title',
            center: 'dayGridMonth,timeGridWeek,listWeek',
        };

        const customBusinessHours = {
            dayesOfWeek: [1, 2, 3, 6, 7],
            startTime: '00:00',
            endTime: '24:00',
        };
        return (
            <React.Fragment>
                <BreadcrumbsItem to='/event-calendar'>{'Konsultacje'}</BreadcrumbsItem>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)}></CustomMessages>
                <BlockUi
                    tag='div'
                    blocking={this.state.blocking || this.state.loading}
                    loader={this.loader}
                    rendered={!this.props.embedded}
                >
                    <BaseBreadCrumb items={this.breadCrumbItems()} />
                    <Card className='details-card card-padding-lexdocs calendar-component'>
                        {this.renderStartConsultationDialog()}
                        <DivContainer colClass='col-12'>
                            <div style={{marginTop: '20px'}}>
                                <CalendarScheduleComponent
                                    handleClickOperationButton={(date, periodType) => this.getEvents(date, periodType)}
                                    headerToolbar={headerToolbar}
                                    initialView={'dayGridMonth'}
                                    isLexdocs={!this.isLexyPortal()}
                                    hideAddEventButton={true}
                                    customBusinessHours={customBusinessHours}
                                    renderEventContent={this.renderEventContent}
                                    select={(e) => this.handleOpenDialog(e)}
                                    events={this.state.events}
                                    html={true}
                                    eventClick={(e) => this.handleOpenDetailsDialog(e)}
                                />
                            </div>
                        </DivContainer>
                    </Card>
                    <CalendarTodayEventList events={this.state.events} authService={this.authService}></CalendarTodayEventList>
                </BlockUi>
                {/* <Example /> */}
            </React.Fragment>
        );
    }
}

ScheduledEventCalendar.defaultProps = {
    detailUrl: '/#/consultation-proposal/details',
    newUrl: '/#/consultation-proposal/create',
    lazy: true,
};

ScheduledEventCalendar.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default withTranslation()(ScheduledEventCalendar);
