
class CustomValidator {

    atLeastOneOptionRequired(checkboxes,message){
		  message = (typeof message === 'undefined' || message === null || message === '') ? "Przynajmniej jedna opcja musi zostać zaznaczona" : message;
          let isExistElement = checkboxes.find(el=>el.selected);
          if(!isExistElement){
                return {
                    response:{
                        visible: true,
                        message: message
                    }
            }
        }else return null;
    }
}

export default CustomValidator;
