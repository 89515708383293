export default class ScheduledEventsModel {
    constructor() {
        this.id = undefined;
        this.end =undefined;
        this.start =undefined;
        this.title=undefined;
        this.paymentStatus = undefined;
        this.consultant = undefined;
        this.client = undefined;
        this.fullStartDate = undefined;
        this.fullEndDate = undefined;
        this.executed = undefined;
        this.consultationType = undefined;
        this.backgroundColor = undefined;
        this.document = undefined;
        this.textColor = undefined;
        this.border = undefined;
    }

    static assign(from, to) {
        if (from) {
            to.id = from.id;
            to.end = from.end;
            to.start = from.start;
            to.title = from.title;
            to.consultant = from.consultation?.user?.fullName;
            to.executed = from.executed;
            to.client = from.user?.fullName;
            to.startHour = from.startHour;
            to.endHour = from.endHour;
            to.fullStartDate = new Date(from.startDate);
            to.fullEndDate = new Date(from.endDate);
            to.backgroundColor = from.backgroundColor;
            to.textColor = from.textColor;
            to.document = from.document;
            to.consultationType = from.consultationType;
            to.paymentStatus = from.paymentStatus;
            to.border = from.border;
        } else {
            to = new ScheduledEventsModel();
        }
    }

    static copy(from) {
        const to = new ScheduledEventsModel();
        ScheduledEventsModel.assign(from, to);
        return to;
    }
}
