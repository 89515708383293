import BaseServiceCrud from './BaseServiceCrud';

export default class InternalUserService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'user-manage';
    }

    getCriteria(criteria) {
        return this.objToQueryString({
            text: criteria.text,
            name: criteria.name,
            role: criteria.role?.id,
            roles: criteria.roles,
            active: criteria.active,
            not_blocked: criteria.notBlocked,
            status: criteria.status?.enumValue,
            person: criteria.person?.enumValue,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    updateUserPassword(body) {
        return this.fetch(`${this.domain}/${this.path}/update-password/`, {
            method: 'PUT',
            body: JSON.stringify(body),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getInternalUserReference(id) {
        return this.fetch(`${this.domain}/${this.path}/user-reference/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getConsultantsBySpecializationId(id) {
        return this.fetch(`${this.domain}/${this.path}/user-list/specialization/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getUsersBySubscriptionId(id) {
        return this.fetch(`${this.domain}/${this.path}/all-by-subscription-id/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getUsersByRoleName(roleName) {
        return this.fetch(`${this.domain}/${this.path}/all-by-role-name/${roleName}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getUserReferenceList(criteria) {
        const queryString = this.getCriteria(criteria);

        return this.fetch(`${this.domain}/${this.path}/list/reference${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    checkStatusPassword(login, password, captchaValid) {
        return this.fetch(`${this.domain}/${this.path}/password/check-status-password`, {
            method: 'POST',
            body: JSON.stringify({login, password, captchaValid}),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    verifyTokenAndSendNewPassword(token) {
        const encodedValue = encodeURIComponent(token);
        return this.fetch(`${this.domain}/${this.path}/password/verify-reset-link?token=${encodedValue}`, {
            method: 'POST',
        }).catch((err) => {
            throw err;
        });
    }

    updateMyAccount(element) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/me/${element.id}`, {
            method: 'PUT',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getMyAccount(id) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/me/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    blockAccount(id) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/block/${id}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    removeAccount(id) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/remove/${id}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    unblockAccount(id) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/unblock/${id}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    remindPassword(id) {
        return this.fetch(`${this.domain}/${this.path}/remind-password/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getUserType(id) {
        return this.fetch(`${this.domain}/${this.path}/type/${id}`, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    }
}
