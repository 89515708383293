import PropTypes from 'prop-types';
import {React} from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import ActionButton from '../../components/ActionButton';
import DivContainer from '../../components/DivContainer';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import ConsultationModel from '../../model/ConsultatonModel';
import EventCalendarService from '../../services/EventCalendarService';
import InputCalendarComponent from '../../components/inputs/InputCalendarComponent';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import moment from 'moment';
import YesNoDialog from '../../components/YesNoDialog';
import InternalUserService from '../../services/InternalUserService';
import AuthService from '../../services/AuthService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import DialogResizer from '../../components/DialogResizer';

class EventCalendarDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new EventCalendarService());
        this.internalUserService = new InternalUserService();

        this.state = {
            operation: undefined,
            startDate: new Date(new Date().setHours(0, 0, 0, 0)),
            endDate: new Date(new Date().setHours(0, 0, 0, 0)),
            startHour: undefined,
            dialogVisibile: false,
            endHour: undefined,
            elementId: '',
            element: new ConsultationModel(),
            viewMode: this.props.viewMode,
            activeIndex: 0,
        };

        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.authService = new AuthService();
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    initBeforeSetElement() {
        // Ta data musi być dwukrotnie wyzerowana przy vieMode - NEW.
        const date = new Date();
        date.setHours(0, 0, 0, 0);

        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                end: date,
                start: date,
            },
        }));

        if (!this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE')) {
            this.internalUserService
                .getInternalUserReference(this.props.currentUser.userId)
                .then((user) => {
                    this.setState((prevState) => ({
                        element: {
                            ...prevState.element,
                            user: user,
                        },
                    }));
                })
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać danych użytkownika');
                });
        }
    }

    handleCloseDialog(e, callback) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: false}, () => {
            if (callback) callback();
        });
    }

    getContainerListName() {
        return 'event-calendar';
    }
    getAddSucces() {
        return 'Konsultacja została utworzona';
    }

    getUpdateSucces() {
        return 'Konsultacja została zaktualizowana';
    }

    getRemoveSucces() {
        return 'Konsultacja została usunięta';
    }

    renderHeader() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${'Nowa konsultacja'}`
                            : this.state.viewMode === 'VIEW'
                            ? `${'Szczegóły konsultacji'}`
                            : this.state.viewMode === 'EDIT'
                            ? `${'Edytuj konsultację'}`
                            : null}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }

    updateElement(data) {
        this.setState(
            {
                element: ConsultationModel.copy(data),
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    renderDetails() {
        return this.renderView();
    }
    initializeUsersBySpecialization(specialization) {
        if (this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE')) {
            this.internalUserService
                .getConsultantsBySpecializationId(specialization.id)
                .then((users) => {
                    this.setState({
                        users,
                    });
                })
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy użytkowników');
                });
        }
    }

    handleOpenDialog(e) {
        this.setState({
            viewMode: 'NEW',
            dialogVisibile: true,
        });
    }

    handleFormSubmit(event, operation) {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            this.setState({
                operation: operation,
            });
            this.blockUi(this.handleValidForm);
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // this.scrollToError = true;
            this.forceUpdate();
        }
    }

    createOrUpdate() {
        const {element} = this.state;
        if (this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE')) {
            element.status = 'CONFIRMED';
        } else {
            element.status = 'REQUIRES_APPROVAL';
        }
        this.scrollToTop();
        if (this._isMounted) {
            if (element.id === undefined || element.id === null) {
                this.service
                    .add(element)
                    .then(() => {
                        if (this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE')) {
                            this.persistMessage('success', '', this.getAddSucces());
                        } else {
                            this.persistMessage('success', '', 'Propozycja terminu została wysłana!');
                        }
                        if (this.props.afterSave) {
                            this.props.afterSaveWithOperation(this.state.operation);
                        }
                        DialogResizer.removeMaskIfExistOneDialog();
                    })
                    .catch((err) => {
                        this.persistMessage('error', '', err.message);
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                        DialogResizer.removeMaskIfExistOneDialog();
                    });
            } else {
                this.service
                    .update(element)
                    .then((response) => {
                        this.afterUpdateSuccess(response);
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                        DialogResizer.removeMaskIfExistOneDialog();
                    })
                    .catch((err) => {
                        this.persistMessage('error', '', err.message);
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                        DialogResizer.removeMaskIfExistOneDialog();
                    });
            }
        }
    }

    renderDetailsButton() {
        const {t} = this.props;
        return (
            <DivContainer colClass='row details-consultation-container'>
                <div className='right'>
                    <ActionButton
                        className='button cancel-button gray'
                        label={t('details.delete')}
                        handleClick={(e) => {
                            this.setState({viewMode: 'DELETE'});
                        }}
                    />
                    <ActionButton
                        label={this.state.viewMode === 'VIEW' ? t('details.editButton') : t('details.saveChanges')}
                        variant='button save-button blue float-right'
                        handleClick={(e) => {
                            const parseStart = moment(this.state.element.start, 'YYYY-MM-DD HH:mm').toDate();
                            const parseEnd = moment(this.state.element.end, 'YYYY-MM-DD HH:mm').toDate();
                            this.setState({
                                startDate: parseStart,
                                endDate: parseEnd,
                                viewMode: 'EDIT',
                            });
                        }}
                        rendered={true}
                    />
                </div>
            </DivContainer>
        );
    }
    hideDeleteEventConfirmation() {
        this.setState({
            viewMode: 'VIEW',
        });
    }

    deleteEvent() {
        const {element} = this.state;
        this.scrollToTop();
        if (this._isMounted) {
            this.blockUi();
            this.service
                .remove(element)
                .then(() => {
                    this.persistMessage('success', '', this.getRemoveSucces());
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                })
                .catch((err) => {
                    this.persistMessage('error', '', err.message);
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                });
        } else {
            this.validator.showMessages();
            // this.scrollToError = true;
            this.forceUpdate();
        }
    }

    prepareFooterItems() {
        const {viewMode} = this.props;
        const onSaveOption = this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE')
            ? 'Zapisz'
            : 'Wyślij do akceptacji';
        return [
            {
                label: 'Zapisz i dodaj kolejną',
                onClick: (e) => this.handleFormSubmit(e, 'SAVE_ADD'),
                variant: 'white',
                iconName: '',
                iconRight: 'add_blue.svg',
                iconSide: 'left',
                rendered: viewMode === 'NEW' && this.authService.isUserInRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE'),
            },
            {
                label: onSaveOption,
                onClick: this.handleFormSubmit,
                variant: 'accent ',
                iconRight: this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE')
                    ? 'add_green.svg'
                    : 'add_blue.svg',
                rendered: viewMode === 'NEW',
            },
        ];
    }

    renderButtonOnEditDialog() {
        const {t} = this.props;
        return (
            <DivContainer colClass='row  details-consultation-container'>
                <div className='right'>
                    <ActionButton
                        label={this.state.viewMode === 'VIEW' ? t('details.editButton') : t('details.saveChanges')}
                        variant='button save-button blue'
                        handleClick={(e) => this.handleFormSubmit(e, 'SAVE')}
                        rendered={true}
                        buttonType='NEXT'
                    />
                </div>
            </DivContainer>
        );
    }

    changeHour(e) {
        let start = this.state.element.start;
        let end = this.state.element.end;

        let fullDate = e.target.id === 'startHour' ? moment(start, 'YYYY-MM-DD').toDate() : moment(end, 'YYYY-MM-DD').toDate();

        fullDate.setHours(e.target.value.getHours());
        fullDate.setMinutes(e.target.value.getMinutes());

        if (e.target.id === 'startHour') {
            fullDate = this.setDay(fullDate, start);

            this.setState((prevState) => ({
                element: {
                    ...prevState.element,
                    start: fullDate,
                },
                startDate: fullDate,
            }));
        } else {
            fullDate = this.setDay(fullDate, end);
            this.setState((prevState) => ({
                element: {
                    ...prevState.element,
                    end: fullDate,
                },
                endDate: fullDate,
            }));
        }
    }

    changeDate(e) {
        let start = this.state.element.start;
        let end = this.state.element.end;

        let fullDate = e.target.id === 'startDate' ? moment(start, 'YYYY-MM-DD').toDate() : moment(end, 'YYYY-MM-DD').toDate();

        fullDate.setDate(e.value.getDate());
        fullDate.setMonth(e.value.getMonth());
        fullDate.setFullYear(e.value.getFullYear());

        if (e.target.id === 'startDate') {
            fullDate = this.setMinutesAndHours(fullDate, start);
            this.setState((prevState) => ({
                element: {
                    ...prevState.element,
                    start: fullDate,
                },
                startDate: fullDate,
            }));
        } else {
            fullDate = this.setMinutesAndHours(fullDate, end);
            this.setState((prevState) => ({
                element: {
                    ...prevState.element,
                    end: fullDate,
                },
                endDate: fullDate,
            }));
        }
    }

    setDay(toDate, fromDate) {
        if (this.props.viewMode === 'NEW') {
            toDate.setDate(fromDate.getDate());
            return toDate;
        } else return toDate;
    }

    setMinutesAndHours(toDate, fromDate) {
        if (this.props.viewMode === 'NEW') {
            toDate.setHours(fromDate.getHours());
            toDate.setMinutes(fromDate.getMinutes());
            return toDate;
        } else return toDate;
    }

    renderView() {
        const {t} = this.props;
        const {viewMode} = this.props;

        return (
            <div>
                {this.state.viewMode !== 'DELETE' ? (
                    <div>
                        <div className='row'>
                            {this.state.viewMode === 'VIEW' ? (
                                <InputTextComponent
                                    id='start'
                                    name='start'
                                    label={'Data rozpoczęcia'}
                                    value={
                                        this.state.element.start
                                            ? moment(this.state.element.start).format('DD-MM-YYYY HH:mm').toString()
                                            : moment(this.state.startDate).format('DD-MM-YYYY HH:mm').toString()
                                    }
                                    validator={this.validator}
                                    colClass='col-md-6 col-sm-6'
                                    validators={`required`}
                                    viewMode={'VIEW'}
                                />
                            ) : (
                                <InputCalendarComponent
                                    id='startDate'
                                    name='startDate'
                                    label={'Data rozpoczęcia'}
                                    value={this.state.startDate}
                                    validator={this.validator}
                                    colClass='col-md-6 col-sm-6'
                                    placeholder={moment(this.state.element.start).format('DD-MM-YYYY').toString()}
                                    minDate={this.props.viewMode === 'NEW' ? new Date() : null}
                                    validators={`required`}
                                    onChange={(a, b, e) => {
                                        this.changeDate(e);
                                    }}
                                    showIcon={true}
                                    viewMode={this.state.viewMode}
                                />
                            )}
                            {this.state.viewMode !== 'VIEW' ? (
                                <InputCalendarComponent
                                    id='startHour'
                                    name='startHour'
                                    label={'Godzina rozpoczęcia'}
                                    value={this.state.startDate}
                                    validator={this.validator}
                                    readOnlyInput={false}
                                    colClass='col-md-6 col-sm-6'
                                    placeholder={this.state.viewMode === 'NEW' ? 'gg:mm' : moment(this.state.element.start).format('HH:mm')}
                                    validators={`required`}
                                    timeOnly
                                    hourFormat='24'
                                    onChange={(a, b, e) => {
                                        this.changeHour(e);
                                    }}
                                    viewMode={this.state.viewMode}
                                />
                            ) : null}
                        </div>
                        <div className='row'>
                            {this.state.viewMode === 'VIEW' ? (
                                <InputTextComponent
                                    id='start'
                                    name='start'
                                    label={'Data zakończenia'}
                                    value={
                                        this.state.element.end
                                            ? moment(this.state.element.end).format('DD-MM-YYYY HH:mm').toString()
                                            : moment(this.state.endDate).format('DD-MM-YYYY HH:mm').toString()
                                    }
                                    validator={this.validator}
                                    colClass='col-md-6 col-sm-6'
                                    validators={`required`}
                                    viewMode={'VIEW'}
                                />
                            ) : (
                                <InputCalendarComponent
                                    id='endDate'
                                    name='endDate'
                                    label={'Data zakończenia'}
                                    value={this.state.endDate}
                                    showIcon={true}
                                    validator={this.validator}
                                    colClass='col-md-6 col-sm-6'
                                    placeholder={moment(this.state.element.end).format('DD-MM-YYYY').toString()}
                                    validators={
                                        'required|after_or_equal:' + moment(this.state.element.start).format('DD-MM-YYYY').toString()
                                    }
                                    minDate={this.props.viewMode === 'NEW' ? new Date() : null}
                                    onChange={(a, b, e) => {
                                        this.changeDate(e);
                                    }}
                                    viewMode={this.state.viewMode}
                                />
                            )}

                            {this.state.viewMode !== 'VIEW' ? (
                                <InputCalendarComponent
                                    id='endHour'
                                    name='endHour'
                                    label={'Godzina zakończenia'}
                                    value={this.state.endDate}
                                    readOnlyInput={false}
                                    validator={this.validator}
                                    colClass='col-md-6 col-sm-6'
                                    placeholder={this.state.viewMode === 'NEW' ? 'gg:mm' : moment(this.state.element.end).format('HH:mm')}
                                    validators={`required|date_after_one_hour~${moment(
                                        this.state.element.start ? this.state.element.start : new Date()
                                    )
                                        .format('DD-MM-YYYY HH:mm')
                                        .toString()})`}
                                    timeOnly
                                    hourFormat='24'
                                    onChange={(a, b, e) => {
                                        this.changeHour(e);
                                    }}
                                    viewMode={this.state.viewMode}
                                />
                            ) : null}
                        </div>
                        <div className='row'>
                            <InputDropdownComponent
                                id='specialization'
                                name='specialization'
                                label={'Specjalizacja'}
                                colClass='col-md-12'
                                value={this.state.element.specialization}
                                validator={this.validator}
                                options={this.props.specializations}
                                onChange={this.handleChange}
                                onAfterStateChange={() => {
                                    this.initializeUsersBySpecialization(this.state.element.specialization);
                                }}
                                viewMode={this.props.viewMode !== 'VIEW' ? 'NEW' : 'VIEW'}
                                optionLabel='name'
                                validators='required'
                            />

                            <InputDropdownComponent
                                id='user'
                                name='user'
                                colClass='col-md-12'
                                label={viewMode === 'NEW' ? 'Użytkownicy' : 'Użytkownik'}
                                value={viewMode === 'NEW' ? this.state.element.user : this.props.user}
                                validator={this.validator}
                                rendered={this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE')}
                                validators='required'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode === 'EDIT' ? 'VIEW' : this.props.viewMode}
                                options={this.state.users}
                                dataKey='id'
                                optionLabel='fullName'
                                // filter janusz
                                filterBy='fullName'
                                disabled={this.state.element.specialization ? false : true}
                            />
                        </div>
                        {this.state.viewMode === 'VIEW' &&
                        this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE')
                            ? this.renderDetailsButton()
                            : this.state.viewMode === 'EDIT'
                            ? this.renderButtonOnEditDialog()
                            : null}
                    </div>
                ) : (
                    <YesNoDialog
                        visible={this.state.viewMode === 'DELETE' ? true : false}
                        header={t('consultationProposal.deleteHeader')}
                        name='visible'
                        forceRemoveMask={true}
                        onChange={(type, x, target) => {
                            if (target.value) {
                                this.deleteEvent();
                                window.location.href = AppPrefixUtils.locationHrefUrl(this.props.backUrl);
                            } else {
                                this.hideDeleteEventConfirmation();
                            }
                            DialogResizer.removeMask();
                        }}
                        onHide={() => {
                            this.hideDeleteEventConfirmation();
                            DialogResizer.removeMask();
                        }}
                    >
                        Czy na pewno chcesz usunąć konsultację?
                    </YesNoDialog>
                )}
            </div>
        );
    }
}

EventCalendarDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
    backUrl: '/#/event-calendar',
};

EventCalendarDialog.propTypes = {
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.string,
    stationId: PropTypes.number,
};

export default withTranslation()(EventCalendarDialog);
