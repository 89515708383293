import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import {CustomMessages} from '../../components/CustomMessages';
import InternalUserService from '../../services/InternalUserService';
import CustomLabel from '../../components/CustomLabel';
import SpecializationService from '../../services/SpecializationService';
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import UserRoleService from '../../services/UserRoleService';
import BaseUserDetailsContainer from '../../baseContainers/BaseUserDetailsContainer';
import InputConsultantComponent from '../../components/inputs/ePrawnik/InputConsultantComponent';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';

class LawyerContainer extends BaseUserDetailsContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.isCustomHeaderItems = !this.isLexyPortal();
        this.state = {
            loading: true,
            elementId: props.params.id,
            element: {
                status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            },
            managerId: undefined,
            dialogVisibile: false,
            lawyerDialogVisibile: false,
        };
        this.specializationService = new SpecializationService();
    }

    getContainerListName() {
        return 'lawyer-list-container';
    }

    getUpdateSucces() {
        return 'Edytowano użytkownika kancelarii';
    }

    afterUpdateSuccess(response) {
        const {backUrl} = this.props;
        this.blockUi();
        this.persistMessage('success', '', this.getUpdateSucces(response));
        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl + '/' + this.state.managerId);
    }

    handleGoBack(e) {
        e.preventDefault();
        this.blockUi();
        const {backUrl} = this.props;
        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl + '/' + this.state.managerId);
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    getBackLabel() {
        return 'Wróć do listy prawników';
    }

    render() {
        return <div className='container-fluid'>{this.renderView()}</div>;
    }

    updateElement(data) {
        this.setState(
            (prevState) => ({
                element: {
                    ...prevState.element,
                    id: data.id,
                    firstName: data.firstName,
                    email: data.email,
                    lastName: data.lastName,
                    status: data.status,
                    specializations: data.specializations,
                    portal: data.portal,
                    person: data.person,
                    role: data.role,
                    chatFee: data.chatFee,
                    mailingFee: data.mailingFee,
                    lawOffice: data.lawOffice,
                    videoChatFee: data.videoChatFee,
                },
            }),
            () => {
                this.initAfterSetElement(data);
            }
        );
    }

    initBeforeSetElement() {
        const managerId = this.readCookie('law-office-manager-id');

        this.setState({
            managerId: managerId,
        });

        this.specializationService
            .getAllSpecializations()
            .then((specializations) => {
                this.setState({specializations});
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });
    }

    initAfterSetElement(data) {
        new UserRoleService()
            .getPermissionsByUserAndRole(data.id, data.role.id)
            .then((permissions) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        permissions: permissions,
                    },
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
            });
        super.initAfterSetElement();
    }

    editButton() {
        const {viewMode} = this.props;
        return this.prepareEditButton(
            viewMode === 'VIEW' && this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_USER_MANAGE')
        );
    }
    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            this.clearConsultantCompontents();
            this.blockUi(this.handleValidForm);
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.scrollToError = true;
            this.forceUpdate();
        }
        this.unblockUi();
    }

    renderPermisssions() {
        return this.state.element.permissions
            ? this.state.element.permissions.map((i, index) => {
                  return (
                      <div class='row' style={{width: '100%'}}>
                          <InputCheckboxComponent
                              id={`${index}-permission`}
                              name='selected'
                              label={i.description}
                              labelOnRight
                              value={!!i.selected}
                              validator={this.validator}
                              validators='not_required'
                              colClass='col-md-6'
                              onChange={this.handleChange}
                              viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
                              disabled={this.props.viewMode === 'VIEW'}
                              stateField={`element.permissions[${index}]`}
                              positioning='col-md-6 form-group-checkbox-flex'
                          />
                      </div>
                  );
              })
            : null;
    }

    clearConsultantCompontents() {
        if (!this.isConsultantSelected()) {
            this.state.element.chatFee = undefined;
            this.state.element.mailingFee = undefined;
            this.state.element.videoChatFee = undefined;
        }
    }

    isConsultantSelected() {
        let permission = this.state?.element?.permissions;
        return permission?.find((el) => el.permissionGroup === 'ROLE_LAW_OFFICE_CONSULTANT' && el.selected === true) ? true : false;
    }
    breadCrumbItems() {
        const {element} = this.state;
        return [
            {name: 'Kancelarie partnerskie', url: '/#/law-office-list'},
            {name: 'Kancelaria', url: `/#/law-office/details/${element?.lawOffice?.owner?.id}`},
            {name: 'Prawnik', url: `/#/lawyer/details/${element.id}`},
        ];
    }
    renderDetails() {
        const {element} = this.state;
        return (
            <React.Fragment>
                {this.renderProperStructureForDetail(
                    <>
                        <div className='row'>
                            <InputTextComponent
                                id='firstName'
                                name='firstName'
                                label={'Imię'}
                                placeholder='Wprowadź imię'
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                value={element.firstName}
                                validator={this.validator}
                                validators='required|alpha_space_dash|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                            <InputTextComponent
                                id='lastName'
                                name='lastName'
                                label={'Nazwisko'}
                                placeholder='Wpisz nazwisko'
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                value={element.lastName}
                                validator={this.validator}
                                validators='required|alpha_space_dash|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                            <InputTextComponent
                                id='email'
                                name='email'
                                label={'Email'}
                                wordBreak
                                placeholder='Wpisz email'
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                value={element.email}
                                validator={this.validator}
                                validators='required|email|max:100'
                                onChange={this.handleChange}
                                viewMode={'VIEW'}
                            />

                            <InputMultiSelectComponent
                                id='specializations'
                                name='specializations'
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                label='Specjalizacje'
                                value={this.state.element.specializations}
                                validator={this.validator}
                                validators={'required|array_required'}
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                                options={this.state.specializations}
                                dataKey='id'
                                optionLabel='name'
                                filter
                                filterBy='name'
                            />

                            <InputDropdownComponent
                                id='status'
                                name='status'
                                label={'Status'}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 '
                                value={this.translate(`UserActivityStatus.${this.state.element.status}`)}
                                validator={this.validator}
                                options={this.state.statusOptions}
                                viewMode={'VIEW'}
                                optionLabel='label'
                                dataKey='enumValue'
                                validators='not_required'
                                rendered={this.props.viewMode}
                                valueView={this.state.element.status ? this.state.element.status.label : ''}
                            />

                            <CustomLabel label={'Uprawnienia'} colClass='col-12' />
                            {this.renderPermisssions()}

                            <InputConsultantComponent
                                dialogMode={false}
                                element={this.state.element}
                                validator={this.validator}
                                rendered={this.isConsultantSelected()}
                                handleChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                        </div>
                    </>
                )}

                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
            </React.Fragment>
        );
    }
}

LawyerContainer.defaultProps = {
    backUrl: '/#/law-office-list',
    editUrl: '/#/lawyer/edit',
    detailUrl: '/#/lawyer/details',
    cancelUrl: '/#/lawyer/details',
};

LawyerContainer.propTypes = {
    backUrl: PropTypes.string,
    editUrl: PropTypes.string,
    detailUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
};

export default withTranslation()(LawyerContainer);
