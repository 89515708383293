import BaseServiceCrud from "./BaseServiceCrud";
export default class SubscriptionService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'subscription';
	}

	getCriteria(criteria) {
		if(criteria.active !== undefined){
			criteria.active === 'Aktywny' ? criteria.active = true : criteria.active = false;
		}
		return this.objToQueryString({
			only_active: criteria.onlyActive,
			name: criteria.name, 
			first_result: criteria.firstResult,
			max_results: criteria.maxResults,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
	
	getTypeBySubscription(id) {
		return this.fetch(`${this.domain}/${this.path}/type/${id}`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}

	getActiveSubsIndividualPackageType(){
        return this.fetch(`${this.domain}/${this.path}/subscription-active-individual`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

	getActiveSubsGeneralPackageType(){
        return this.fetch(`${this.domain}/${this.path}/subscription-active-premium`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });

    }

	getSubscriptionWithTemplate(id){
		
		return this.fetch(`${this.domain}/${this.path}/subscription-with-templates/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
	}

	getAllAvailableActiveSubs(){
        return this.fetch(`${this.domain}/${this.path}/subscription-active`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });

    }
	
	getAllSubscriptionForUser(userId){
        return this.fetch(`${this.domain}/${this.path}/subscription-for-user/${userId}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });

    }	

	activateSubscription(id){
		return this.fetch(`${this.domain}/${this.path}/activate-subscription/${id}`, {
            method: 'POST',
        }).then((res) => {
            return Promise.resolve(res);
        });
	}
	
	deactivateSubscription(id){
		return this.fetch(`${this.domain}/${this.path}/deactivate-subscription/${id}`, {
            method: 'POST',
        }).then((res) => {
            return Promise.resolve(res);
        });
	}

	getAllActiveSubsAvailableForAdmin(){
        return this.fetch(`${this.domain}/${this.path}/subscription-active-for-admin`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });

    }

}
