import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OpenViduVideoComponent from './OpenViduVideoComponent';
// import './UserVideo.css';

export default class UserVideoComponent extends Component {

    // getNicknameTag() {
    //     // Gets the nickName of the user
    //     return JSON.parse(this.props.streamManager.stream.connection.data).clientData;
    // }

    render() {        
        return (
            <div className={`${this.props.className} ${this.props.streamManager ? '' : 'noVideo'}`}>
                {this.props.streamManager !== undefined ? (
                    <div>
                        <OpenViduVideoComponent streamManager={this.props.streamManager} />
                        {/* <div><p>{this.getNicknameTag()}</p></div> */}
                    </div>
                ) : (
                    <span></span>
                )}
            </div>
        );
    }
}

UserVideoComponent.defaultProps = {};

UserVideoComponent.propTypes = {
    showstreamManager: PropTypes.object,
    className: PropTypes.string,
};
