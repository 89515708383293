/*********************************************************************** 
Documentations = https://github.com/azouaoui-med/pro-sidebar-template
************************************************************************/
import $ from 'jquery';
import React, {useEffect, useRef, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarHeader, SubMenu, SidebarFooter} from 'react-pro-sidebar';
// import MenuItemIcon from '../MenuItemIcon';
import {
    FaBars,
    FaUsers,
    FaSignOutAlt,
    FaFileCode,
    FaFileContract,
    FaEnvelope,
    FaEdit,
    FaUser,
    FaArchive,
    FaBook,
    FaCog,
    FaDollarSign,
    FaCalendarAlt,
    FaFolderOpen,
    FaBuilding,
    FaFileAlt,
    FaRegNewspaper,
} from 'react-icons/fa';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import {CookiesConstants} from '../../utils/CookiesConstants';
import {UAParser} from 'ua-parser-js';
import UrlUtils from '../../utils/UrlUtils';
import {isLexDocs} from '../../baseContainers/BaseContainer';
import {refreshAgreementBase} from '../../containers/AgreementBaseContainer';

const Sidebar = ({
    t,
    collapsed,
    toggled,
    loggedUser,
    handleToggleSidebar,
    exitEditConfirmation,
    handleLogoutUser,
    authService,
    userService,
    submenu,
    activeUrl,
    generatedDocumentAvailable,
}) => {
    // const userName = authService.getProfile().sub;
    // const role = authService.getProfile().role;
    const [showMenu, changeShowMenu] = useState(false);
    const [toggle, setToggle] = useState(toggled);

    const [stateActiveUrl, changeStateActiveUrl] = useState();
    const [thinSidebarSelected, setThinSidebarSelected] = useState(false);
    const {device} = UAParser(navigator.userAgent);

    useEffect(() => {
        if (activeUrl !== stateActiveUrl) {
            $('.pro-inner-item').each(function (index) {
                $(this).removeClass('active');
            });
            $(document.getElementsByClassName(`pro-menu-item ${activeUrl}`))
                .children('div')
                .addClass('active')
                .siblings()
                .removeClass('active');
        }
    }, [activeUrl, stateActiveUrl, thinSidebarSelected, toggle]);

    const sideNavbarTooglerClick = () => {
        if (window.innerWidth <= 1021) {
            document.getElementsByClassName('navbar-toggler sidebar-toggler collapsed')[0]?.click();
        }
    };

    const changeTab = (showMenu, link) => {
        const editViewModeCookie = CookiesConstants.DETAILS_VIEW_MODE;
        if (sessionStorage.getItem(editViewModeCookie)) {
            exitEditConfirmation(link);
        } else {
            changeContainerStyleToFluidIfPossible();
            changeShowMenu(showMenu);
            sideNavbarTooglerClick();
            const url = AppPrefixUtils.locationHrefUrl(link);
            window.location.href = url;
        }
    };

    const isCollapsed = () => {
        const sidebar = document.getElementsByClassName('pro-sidebar')[0];
        if (sidebar) {
            return sidebar.style.display === 'none';
        } else {
            return false;
        }
    };

    const toggleNavbarCollapsed = (isCollapsed) => {
        const toogler = document.getElementById('navbar-toggler-laptop-colapsed');
        if (toogler) {
            isCollapsed ? (toogler.style.display = 'flex') : (toogler.style.display = 'none');
        }
    };

    // myk na kalendarz :DDDD
    const resizeCalendar = () => {
        const {href} = window.location;
        if (href.includes('/event-calendar') || href.includes('/scheduled-event-calendar')) {
            setTimeout(() => {
                document.getElementById('prev-period').click();
                document.getElementById('next-period').click();
            }, 200);
        }
    };

    const changeContainerStyleToCustomIfPossible = () => {
        const authComponent = document.getElementsByClassName('auth-component')[0];
        if (authComponent && authComponent.classList.contains('container-fluid') && UrlUtils.isCreator()) {
            authComponent.classList.remove('container-fluid');
            authComponent.classList.add('container-custom');
        }
    };
    const changeContainerStyleToFluidIfPossible = () => {
        const authComponent = document.getElementsByClassName('auth-component')[0];
        if (authComponent && authComponent.classList.contains('container-custom') && isLexDocs) {
            authComponent.classList.add('container-fluid');
            authComponent.classList.remove('container-custom');
        }
    };

    const renderVerticalSidebar = () => {
        toggleNavbarCollapsed(isCollapsed());
        return (
            <React.Fragment>
                <ProSidebar
                    id={'pro-sidebar'}
                    collapsed={collapsed}
                    breakPoint='lg'
                    onClick={(e) => {
                        setToggle(!toggle);
                    }}
                    toggled={toggle}
                    onToggle={handleToggleSidebar}
                    className='pro-sidebar-layout'
                >
                    <SidebarHeader>
                        <div class='sidebar-header-appname '>
                            <button
                                id={'navbar-toggler-laptop'}
                                className={`navbar-toggler-laptop`}
                                type='button'
                                data-toggle='collapse'
                                data-target='.dual-collapse'
                                aria-controls='navbarSupportedContent'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={(e) => {
                                    const {href} = window.location;
                                    e.preventDefault();
                                    if (
                                        href.includes('template/edit') ||
                                        href.includes('template/create') ||
                                        href.includes('template/details')
                                    ) {
                                        if (window.templateRef.current) {
                                            if (window.templateRef.current?.onResizeEditor) {
                                                window.templateRef.current.onResizeEditor();
                                            }
                                        }
                                    }
                                    if (typeof refreshAgreementBase === 'function') {
                                        refreshAgreementBase();
                                    }
                                    changeContainerStyleToCustomIfPossible();
                                    resizeCalendar();
                                    setThinSidebarSelected(true);
                                    e.stopPropagation();
                                }}
                            >
                                <span className='navbar-toggler-icon'>
                                    <img src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/menu_open.svg')} id='hamburger' alt='' />
                                </span>
                            </button>
                            <div className={{minWidth: '250px'}}>
                                <img
                                    id='logo-lexdocs'
                                    className='img-fluid'
                                    src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/Lexdocs_logo.svg')}
                                    alt='Logo LexDocs'
                                />
                            </div>
                        </div>
                    </SidebarHeader>
                    <SidebarContent>
                        {/* ADMINI */}
                        <Menu>
                            {device.type === 'mobile' ? (
                                <MenuItem icon={<FaCalendarAlt />} className='manage-accounts'>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            let userId = loggedUser.userId;
                                            if (!loggedUser.userId) {
                                                userId = authService.getUserId();
                                            }
                                            changeTab(false, '/#/manage-account/details/' + userId);
                                        }}
                                    >
                                        {'Moje konto'}
                                    </div>
                                </MenuItem>
                            ) : null}
                            {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_PAYMENT_MANAGE') ? (
                                <MenuItem icon={<FaFileContract />} className='agreement-list'>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeTab(false, '/#/agreement-list');
                                        }}
                                    >
                                        {'Dokumenty użytkowników'}
                                    </div>
                                </MenuItem>
                            ) : null}
                            {authService.isUserInAnyRole(
                                'ROLE_ADMIN_SUPER_MANAGE',
                                'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                'ROLE_ADMIN_TEMPLATE_MANAGE',
                                'ROLE_ADMIN_PACKAGE_MANAGE'
                            ) ? (
                                <MenuItem icon={<FaFileCode />} className='configurator-template-list'>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeTab(false, '/#/configurator-template-list');
                                        }}
                                    >
                                        {'Konfigurator szablonów'}
                                    </div>
                                </MenuItem>
                            ) : null}
                            {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE') ? (
                                <MenuItem icon={<FaCalendarAlt />} className='event-calendar'>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeTab(false, '/#/event-calendar');
                                        }}
                                    >
                                        {'Kalendarz'}
                                    </div>
                                </MenuItem>
                            ) : null}
                            {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_TERM_MANAGE') ? (
                                <MenuItem icon={<FaEdit />} className='consultation-list'>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeTab(false, '/#/consultation-list');
                                        }}
                                    >
                                        {'Propozycje terminów'}
                                    </div>
                                </MenuItem>
                            ) : null}

                            {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_USER_MANAGE') ? (
                                <Menu iconShape='circle' popperArrow='false'>
                                    <SubMenu title='Zarządzanie Użytkownikami' icon={<FaUsers />}>
                                        {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE') ? (
                                            <MenuItem className='system-users-list'>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        changeTab(false, '/#/system-users-list');
                                                    }}
                                                >
                                                    {'Użytkownicy systemowi'}
                                                </div>
                                            </MenuItem>
                                        ) : null}

                                        {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE') ? (
                                            <MenuItem className='users-list'>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        changeTab(false, '/#/users-list');
                                                    }}
                                                >
                                                    {'Klienci'}
                                                </div>
                                            </MenuItem>
                                        ) : null}

                                        {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_USER_MANAGE') ? (
                                            <MenuItem className='law-office-list' icon={<FaBuilding />}>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        changeTab(false, '/#/law-office-list');
                                                    }}
                                                >
                                                    {'Kancelarie partnerskie'}
                                                </div>
                                            </MenuItem>
                                        ) : null}
                                    </SubMenu>
                                </Menu>
                            ) : null}

                            {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONSULTATION_VIEW') ? (
                                <MenuItem className='consultation-history-list' icon={<FaBook />}>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeTab(false, '/#/consultation-history-list');
                                        }}
                                    >
                                        {'Historia konsultacji'}
                                    </div>
                                </MenuItem>
                            ) : null}

                            {authService.isUserInAnyRole(
                                'ROLE_ADMIN_SUPER_MANAGE',
                                'ROLE_ADMIN_CONTENT_MANAGE',
                                'ROLE_ADMIN_PACKAGE_MANAGE'
                            ) ? (
                                <Menu iconShape='circle' popperArrow='false'>
                                    <SubMenu title='Zarządzanie treściami' icon={<FaRegNewspaper />}>
                                        {authService.isUserInAnyRole(
                                            'ROLE_ADMIN_SUPER_MANAGE',
                                            'ROLE_ADMIN_CONTENT_MANAGE',
                                            'ROLE_ADMIN_PACKAGE_MANAGE'
                                        ) ? (
                                            <MenuItem className='subscription-list' icon={<FaArchive />}>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        changeTab(false, '/#/subscription-list');
                                                    }}
                                                >
                                                    {'Pakiety abonamentowe'}
                                                </div>
                                            </MenuItem>
                                        ) : null}
                                        {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE') ? (
                                            <MenuItem className='hierarchical-dictionary-list' icon={<FaArchive />}>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        changeTab(false, '/#/hierarchical-dictionary-list');
                                                    }}
                                                >
                                                    {'Słowniki'}
                                                </div>
                                            </MenuItem>
                                        ) : null}
                                        {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE') ? (
                                            <MenuItem className='approval-content-list'>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        changeTab(false, '/#/approval-content-list');
                                                    }}
                                                >
                                                    {t('approval.listName')}
                                                </div>
                                            </MenuItem>
                                        ) : null}

                                        {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE') ? (
                                            <MenuItem className='specialization-list' icon={<FaArchive />}>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        changeTab(false, '/#/specialization-list');
                                                    }}
                                                >
                                                    {'Specjalizacje'}
                                                </div>
                                            </MenuItem>
                                        ) : null}
                                    </SubMenu>
                                </Menu>
                            ) : null}
                            {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_SETTING_MANAGE') ? (
                                <Menu iconShape='circle' popperArrow='true'>
                                    <SubMenu title='Administracja' icon={<FaCog />}>
                                        {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE') ? (
                                            <MenuItem className='category-list'>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        changeTab(false, '/#/category-list');
                                                    }}
                                                >
                                                    {'Kategorie'}
                                                </div>
                                            </MenuItem>
                                        ) : null}

                                        {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE') ? (
                                            <MenuItem icon={<FaEnvelope />} className='email-template-list'>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        changeTab(false, '/#/email-template-list');
                                                    }}
                                                >
                                                    {t('sidebar.email')}
                                                </div>
                                            </MenuItem>
                                        ) : null}

                                        {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE') ? (
                                            <MenuItem className='event-log-list' icon={<FaArchive />}>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        changeTab(false, '/#/event-log-list');
                                                    }}
                                                >
                                                    {t('eventLog.name')}
                                                </div>
                                            </MenuItem>
                                        ) : null}
                                        {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_SETTING_MANAGE') ? (
                                            <MenuItem className='setting-list'>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        changeTab(false, '/#/setting-list');
                                                    }}
                                                >
                                                    Parametry Systemowe
                                                </div>
                                            </MenuItem>
                                        ) : null}
                                    </SubMenu>
                                </Menu>
                            ) : null}
                            {authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_PAYMENT_MANAGE') ? (
                                <MenuItem icon={<FaFileAlt />} className='report-list'>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();

                                            changeTab(true, '/#/report-list');
                                        }}
                                    >
                                        {'Raporty płatności'}
                                    </div>
                                </MenuItem>
                            ) : null}

                            {/*END ADMINI */}

                            {/* KLIENT Leksy & LexDocS*/}
                            {authService.isUserInAnyRole(
                                'ROLE_USER_LEKSY_MANAGE',
                                'ROLE_USER_LEX_DOCS_MANAGE',
                                'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                'ROLE_LAW_OFFICE_CONSULTANT_MANAGE'
                            ) || !authService.loggedIn() ? (
                                <MenuItem icon={<FaFileContract />} className={'template-list'}>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();

                                            changeTab(true, !authService.loggedIn() ? '/#/public/template-list' : '/#/template-list');
                                        }}
                                    >
                                        {'Szablony'}
                                    </div>
                                </MenuItem>
                            ) : null}

                            {authService.isUserInAnyRole(
                                'ROLE_USER_LEKSY_MANAGE',
                                'ROLE_USER_LEX_DOCS_MANAGE',
                                'ROLE_LAW_OFFICE_EDITOR_MANAGE',
                                'ROLE_LAW_OFFICE_CONSULTANT_MANAGE'
                            ) && generatedDocumentAvailable ? (
                                <MenuItem icon={<FaFolderOpen />} className='agreement-list'>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeTab(true, '/#/agreement-list');
                                        }}
                                    >
                                        {'Moje dokumenty'}
                                    </div>
                                </MenuItem>
                            ) : null}

                            {authService.isUserInAnyRole('ROLE_LAW_OFFICE_CONSULTANT_MANAGE') ? (
                                <MenuItem icon={<FaCalendarAlt />} className='event-calendar'>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeTab(true, '/#/event-calendar');
                                        }}
                                    >
                                        {'Kalendarz'}
                                    </div>
                                </MenuItem>
                            ) : null}

                            {authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE') ? (
                                <MenuItem icon={<FaEdit />} className='order-list'>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeTab(true, '/#/order-list');
                                        }}
                                    >
                                        {'Zamówienia'}
                                    </div>
                                </MenuItem>
                            ) : null}

                            {authService.isUserInAnyRole('ROLE_LAW_OFFICE_CONSULTANT_MANAGE') ? (
                                <MenuItem className='consultation-history-list' icon={<FaBook />}>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeTab(false, '/#/consultation-history-list');
                                        }}
                                    >
                                        {'Historia konsultacji'}
                                    </div>
                                </MenuItem>
                            ) : null}
                            {authService.isUserInAnyRole(
                                'ROLE_USER_LEKSY_MANAGE',
                                'ROLE_USER_LEX_DOCS_MANAGE',
                                'ROLE_LAW_OFFICE_CONSULTANT_MANAGE'
                            ) ? (
                                <MenuItem icon={<FaEdit />} className='scheduled-event-calendar'>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeTab(true, '/#/scheduled-event-calendar');
                                        }}
                                    >
                                        {'Zaplanowane konsultacje'}
                                    </div>
                                </MenuItem>
                            ) : null}

                            {authService.isUserInAnyRole('ROLE_USER_LEX_DOCS_MANAGE') ? (
                                <MenuItem icon={<FaDollarSign />} className='package-offer'>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeTab(true, '/#/package-offer');
                                        }}
                                    >
                                        {'Cennik'}
                                    </div>
                                </MenuItem>
                            ) : null}
                            {authService.isUserInAnyRole('ROLE_USER_LEKSY_MANAGE', 'ROLE_USER_LEX_DOCS_MANAGE') ? (
                                <MenuItem className='consultation-history-list' icon={<FaBook />}>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeTab(false, '/#/consultation-history-list');
                                        }}
                                    >
                                        {'Historia konsultacji'}
                                    </div>
                                </MenuItem>
                            ) : null}
                            {/* END KLIENT */}
                        </Menu>
                    </SidebarContent>{' '}
                    <SidebarFooter>
                        {device.type === 'mobile' && (
                            <MenuItem className='logout-sidebar-btn'>
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleLogoutUser();
                                    }}
                                >
                                    {'Wyloguj się'}
                                </div>
                            </MenuItem>
                        )}
                    </SidebarFooter>
                </ProSidebar>
            </React.Fragment>
        );
    };

    const renderFunctionsSidebar = () => {
        return (
            <React.Fragment>
                <ProSidebar
                    id='small-sidebar'
                    collapsed={collapsed}
                    breakPoint='lg'
                    toggled={toggle}
                    onToggle={handleToggleSidebar}
                    className='pro-sidebar-layout'
                >
                    <SidebarHeader>
                        <div class='sidebar-header-appname '>
                            <button
                                id={'navbar-toggler-laptop'}
                                className={`navbar-toggler-laptop`}
                                type='button'
                                data-toggle='collapse'
                                data-target='.dual-collapse'
                                aria-controls='navbarSupportedContent'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={(e) => {
                                    const {href} = window.location;
                                    e.preventDefault();
                                    if (
                                        href.includes('template/edit') ||
                                        href.includes('template/create') ||
                                        href.includes('template/details')
                                    ) {
                                        if (window.templateRef.current) {
                                            if (window.templateRef.current?.onResizeEditor) {
                                                window.templateRef.current.onResizeEditor();
                                            }
                                        }
                                    }
                                    if (typeof refreshAgreementBase === 'function') {
                                        refreshAgreementBase();
                                    }
                                    changeContainerStyleToFluidIfPossible();
                                    resizeCalendar();
                                    setThinSidebarSelected(false);

                                    e.stopPropagation();
                                }}
                            >
                                <span className='navbar-toggler-icon'>
                                    <img src={AppPrefixUtils.locationHrefUrl('/images/lexdocs/menu_close.svg')} id='hamburger' alt='' />
                                </span>
                            </button>
                        </div>
                    </SidebarHeader>
                    <SidebarContent>{/* ADMINI */}</SidebarContent>
                </ProSidebar>
            </React.Fragment>
        );
    };

    const render = () => {
        if (thinSidebarSelected) {
            return renderFunctionsSidebar();
        }
        return renderVerticalSidebar();
    };

    return render();
};

export default withTranslation()(Sidebar);
