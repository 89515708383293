import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/pl';
import moment from 'moment';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import AppPrefixUtils from '../utils/AppPrefixUtils';

//** operations */
const TODAY = 'today';
const NEXT = 'next';
const PREV = 'prev';

const DAY_GRID_MONTH = {
    translate: 'Miesiąc',
    dayOperation: 'dayGridMonth',
};

const TIME_GRID_WEEK = {
    translate: 'Tydzień',
    dayOperation: 'timeGridWeek',
};

const TIME_GRID_DAY = {
    translate: 'Dzień',
    dayOperation: 'timeGridDay',
};
const LIST_WEEK = {
    translate: 'Plan dnia',
    dayOperation: 'listWeek',
};
const FOUR_DAYS = {
    translate: 'Cztery dni',
    dayOperation: 'timeGridFourDay',
};

const calendarRef = React.createRef();
const dropDownRef = React.createRef();

let prevDayGrid = undefined;

class CalendarScheduleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            renderFullCalendar: false,
            styleLeftHandSide: undefined,
            selectedOption: {
                label: DAY_GRID_MONTH.translate,
                type: DAY_GRID_MONTH.dayOperation,
            },

            viewCalendarOptions: [
                {
                    label: DAY_GRID_MONTH.translate,
                    type: DAY_GRID_MONTH.dayOperation,
                },
                {
                    label: TIME_GRID_WEEK.translate,
                    type: TIME_GRID_WEEK.dayOperation,
                },
                {
                    label: TIME_GRID_DAY.translate,
                    type: TIME_GRID_DAY.dayOperation,
                },
            ],
        };
    }

    renderEdit() {
        return this.renderNew();
    }

    windowResize() {
        this.updateSize();
    }

    getPeriodType = () => {
        let periotType;
        switch (calendarRef.current._calendarApi.currentDataManager.data.currentViewType) {
            case DAY_GRID_MONTH.dayOperation:
                periotType = 'MONTHS';
                break;
            case LIST_WEEK.dayOperation:
                periotType = 'LIST_WEEKS';
                break;
            case TIME_GRID_WEEK.dayOperation:
                periotType = 'WEEKS';
                break;
            case FOUR_DAYS.dayOperation:
                periotType = 'FOUR_DAYS';
                break;
            case TIME_GRID_DAY.dayOperation:
                periotType = 'DAYS';
                break;
            default:
                console.log('period is not defined');
                periotType = 'MONTHS';
        }
        return periotType;
    };

    handleClickOperationButton = (operation) => {
        const calendarApi = calendarRef.current._calendarApi;
        switch (operation) {
            case NEXT:
                calendarApi.next();
                break;
            case PREV:
                calendarApi.prev();
                break;
            case TODAY:
                calendarApi.today();
                break;
            default:
                console.log('operation not found');
        }
        let periodType = this.getPeriodType();
        this.props.handleClickOperationButton(calendarApi.currentDataManager.data.currentDate, periodType);
    };

    handleClickPeriodType = (view) => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current._calendarApi;
            calendarApi.changeView(view);
            let periodType = this.getPeriodType();
            this.props.handleClickOperationButton(calendarApi.currentDataManager.data.currentDate, periodType);
        }
    };

    registerEventClickHandler() {
        const elements = document.querySelectorAll('.fc-daygrid-day-number');
        elements.forEach((element) => {
            element.addEventListener('click', this.clickEventHandler);
        });
    }
    registerEventCellCushionClickHandler() {
        const elements = document.querySelectorAll('.fc-col-header-cell-cushion');
        elements.forEach((element) => {
            element.addEventListener('click', this.clickCellCushionClick);
        });
    }
    unregisterEventClickHandler() {
        const elements = document.querySelectorAll('.fc-daygrid-day-number');
        elements.forEach((element) => {
            element.removeEventListener('click', this.clickEventHandler);
        });
    }
    unregisterEventCellCushionClickHandler() {
        const elements = document.querySelectorAll('.fc-col-header-cell-cushion');
        elements.forEach((element) => {
            element.removeEventListener('click', this.clickCellCushionClick);
        });
    }
    componentDidMount() {
        this.registerEventCellCushionClickHandler();
        this.registerEventClickHandler();
        this.registerEventResize();
        this.changeHeaderCellText();
        if (prevDayGrid !== undefined) {
            this.setState(
                {
                    selectedOption: prevDayGrid,
                },
                () => {
                    this.setState(
                        {
                            renderFullCalendar: true,
                        },
                        () => {
                            this.periodTypeSelector(prevDayGrid.type);
                        }
                    );
                }
            );
        } else {
            this.setState({
                renderFullCalendar: true,
            });
        }
    }
    componentDidUpdate() {
        this.registerEventClickHandler();
        this.registerEventCellCushionClickHandler();
        if (this.state.selectedOption?.type === 'dayGridMonth') {
            this.changeHeaderCellText();
        }
    }
    componentWillUnmount() {
        this.unregisterEventClickHandler();
        this.unregisterEventCellCushionClickHandler();
        this.unregisterEventResize();
        this.handleSelectedOptionAfterUnmount();
    }

    handleSelectedOptionAfterUnmount() {
        if (window.location.href.includes('event-calendar')) {
            prevDayGrid = this.state.selectedOption;
        } else {
            prevDayGrid = undefined;
        }
    }

    registerEventResize() {
        window.addEventListener('resize', this.changeHeaderCellText);
    }

    unregisterEventResize() {
        window.removeEventListener('resize', this.changeHeaderCellText);
    }
    canBeLabelsReplaced(elements) {
        return elements[0] && elements[0].lastChild && elements[0].lastChild.lastChild && elements.length === 7;
    }
    changeHeaderCellText = () => {
        if (document.getElementsByClassName('fc-view-harness').length !== 0) {
            const size = document.getElementsByClassName('fc-view-harness')[0].offsetWidth;
            let elements = window.document.getElementsByClassName('fc-col-header-cell');
            if (this.canBeLabelsReplaced(elements)) {
                if (size > 1200) {
                    elements[0].lastChild.lastChild.innerText = 'Poniedziałek';
                    elements[1].lastChild.lastChild.innerText = 'Wtorek';
                    elements[2].lastChild.lastChild.innerText = 'Środa';
                    elements[3].lastChild.lastChild.innerText = 'Czwartek';
                    elements[4].lastChild.lastChild.innerText = 'Piątek';
                    elements[5].lastChild.lastChild.innerText = 'Sobota';
                    elements[6].lastChild.lastChild.innerText = 'Niedziela';
                } else {
                    elements[0].lastChild.lastChild.innerText = 'Pn.';
                    elements[1].lastChild.lastChild.innerText = 'Wt.';
                    elements[2].lastChild.lastChild.innerText = 'Śr.';
                    elements[3].lastChild.lastChild.innerText = 'Cz.';
                    elements[4].lastChild.lastChild.innerText = 'Pt.';
                    elements[5].lastChild.lastChild.innerText = 'So.';
                    elements[6].lastChild.lastChild.innerText = 'Ni.';
                }
            }
        }

        if (!window.location.href.includes('/scheduled-event-calendar')) {
            const windowSize = window.innerWidth;
            if (this.props.isLexdocs) {
                const addConsultationBtn = document.getElementById('add-consultation-btn');
                if (windowSize > 750) {
                    addConsultationBtn.style.display = '';
                } else {
                    addConsultationBtn.style.display = 'none';
                }
            }
        }
    };

    clickEventHandler = () => {
        // zmiana prio
        setTimeout(function () {
            let fakeEvent = {
                value: {
                    type: TIME_GRID_DAY.dayOperation,
                    label: TIME_GRID_DAY.translate,
                },
            };
            dropDownRef.current.props.onChange(fakeEvent);
        }, 1);
    };
    clickCellCushionClick = (e) => {
        const innerText = e.currentTarget.innerText;
        setTimeout(() => {
            this.registerEventCellCushionClickHandler();
        }, 1);
        if (!innerText.includes(' ') && this.props.clientMode) {
            // znaczy tyle, że to dzien
            setTimeout(() => {
                this.handleClickPeriodType(FOUR_DAYS.dayOperation);
            }, 1);
        }
    };

    render() {
        const businessHours = [
            {
                dayesOfWeek: [1, 2, 3, 4, 5, 6, 7],
                startTime: '00:00',
                endTime: '24:00',
            },
        ];

        const basicToolbar = document.getElementsByClassName('fc-header-toolbar')[0];
        if (basicToolbar && !this.props.clientMode) {
            basicToolbar.style.display = 'none';
        }
        const chevronRight = this.props.isLexdocs ? '/images/lexdocs/chevron_right.svg' : '/images/lexy/chevron_right.svg';
        const chevronLeft = this.props.isLexdocs ? '/images/lexdocs/chevron_left.svg' : '/images/lexy/chevron_left.svg';
        console.log(this.state.selectedOption, 'SELECTED VARIANT');
        const stylesForDropdown = this.isLexdocs ? {fontSize: '2px'} : {fontSize: '2px', marginLeft: '10px'};
        const customToolbar = (
            <p id='view-calendar'>
                <div id='calendar-header' className='col-12 row'>
                    <div className='col-12 row calendar-buttons '>
                        <Dropdown
                            ref={dropDownRef}
                            id='dropdown-month'
                            options={this.state.viewCalendarOptions}
                            optionLabel='label'
                            style={stylesForDropdown}
                            value={this.state.selectedOption}
                            onChange={(el) => {
                                const type = el.value.type;
                                this.periodTypeSelector(type);
                                this.setState({selectedOption: el.value});
                            }}
                            className='col-xl-4 col-md-6 col-sm-4 col-5'
                        />

                        <Button
                            id='prev-period'
                            className='col-xl-1 col-md-1 col-sm-1 col-1 '
                            onClick={() => this.handleClickOperationButton(PREV)}
                            style={{
                                maxWidth: '42px',
                                fontSize: '15px!important',
                                background: 'white',
                                color: '#5c74ac',
                                border: 'none',
                            }}
                        >
                            <img className='float-right ' src={AppPrefixUtils.locationHrefUrl(chevronLeft)} alt='' />
                            {/* <i className='pi pi-angle-left' style={{fontSize: '1.3rem'}}></i> */}
                        </Button>
                        <Button
                            id='next-period'
                            className='col-xl-1 col-md-1 col-sm-1 col-1 '
                            onClick={() => this.handleClickOperationButton(NEXT)}
                            raised
                            style={{
                                maxWidth: '42px',
                                fontSize: '15px!important',
                                background: 'white',
                                color: '#5c74ac',
                                border: 'none',
                            }}
                        >
                            {<img className='float-right ' src={AppPrefixUtils.locationHrefUrl(chevronRight)} alt='' />}
                            {/* <i className='pi pi-angle-right' style={{fontSize: '1.3rem'}}></i> */}
                        </Button>
                        {this.props.isLexdocs && !window.location.href.includes('scheduled-event-calendar') && (
                            <div
                                className='col-xl-4 col-md-4 col-sm-4 col-4 mt-1 cursor-pointer'
                                onClick={() => {
                                    this.props.addEvent();
                                }}
                            >
                                <span>
                                    <label id='add-consultation-btn' className='cursor-pointer '>
                                        Dodaj konsultację
                                    </label>
                                    <img src={AppPrefixUtils.locationHrefUrl(`/images/lexdocs/add_blue.svg`)} alt='' />
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </p>
        );

        const eventTimeFormat = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };

        const {
            navLinks,
            views,
            extendedProps,
            initialView,
            events,
            renderEventContent,
            headerToolbar,
            handleWindowResize,
            dayMaxEventRows,
            clientMode,
            select,
            selectable,

            firstDay,
            defaultView,
            eventClick,
        } = this.props;
        return (
            <div>
                <div>
                    {!clientMode ? <span>{customToolbar}</span> : <div className='mt-3'></div>}
                    {this.state.renderFullCalendar && (
                        <FullCalendar
                            ref={calendarRef}
                            navLinks={navLinks}
                            handleWindowResize={handleWindowResize}
                            eventClick={(e) => eventClick(e)}
                            dayMaxEventRows={dayMaxEventRows}
                            displayEventTime={false}
                            displayEventEnd={true}
                            events={events}
                            allDaySlot={false}
                            slotEventOverlap={true}
                            customButtons={
                                clientMode && {
                                    next: {
                                        text: NEXT,
                                        click: () => this.handleClickOperationButton(NEXT),
                                    },
                                    prev: {
                                        text: PREV,
                                        click: () => this.handleClickOperationButton(PREV),
                                    },
                                }
                            }
                            themeSystem='bootstrap'
                            style={{border: 'none'}}
                            views={views}
                            unselectAuto={true}
                            eventTimeFormat={eventTimeFormat}
                            selectable={selectable}
                            eventContent={renderEventContent === undefined ? this.renderEventContent : renderEventContent}
                            select={(e) => select(e)}
                            businessHours={businessHours}
                            extendedProps={extendedProps}
                            locale={esLocale}
                            html={true}
                            headerToolbar={headerToolbar}
                            defaultView={defaultView}
                            nowIndicator
                            initialView={initialView}
                            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                            firstDay={firstDay}
                        />
                    )}
                </div>
            </div>
        );
    }
    periodTypeSelector(type) {
        switch (type) {
            case DAY_GRID_MONTH.dayOperation:
                this.handleClickPeriodType(DAY_GRID_MONTH.dayOperation);
                break;
            case TIME_GRID_WEEK.dayOperation:
                this.handleClickPeriodType(TIME_GRID_WEEK.dayOperation);
                break;
            case TIME_GRID_DAY.dayOperation:
                this.handleClickPeriodType(TIME_GRID_DAY.dayOperation);
                break;
            case FOUR_DAYS.dayOperation:
                this.handleClickPeriodType(FOUR_DAYS.dayOperation);
                break;
            case LIST_WEEK.dayOperation:
                this.handleClickPeriodType(LIST_WEEK.dayOperation);
                break;
            default:
                this.handleClickPeriodType(TIME_GRID_DAY.dayOperation);
        }
    }

    renderEventContent(eventInfo) {
        const start = moment(eventInfo.event.start).format('HH:mm');
        const end = moment(eventInfo.event.end).format('HH:mm');
        if (eventInfo.view.type === DAY_GRID_MONTH.dayOperation) {
            return (
                <div style={{borderRadius: '5px'}}>
                    <i className='mdi mdi-circle' style={{color: eventInfo.backgroundColor}}></i> {eventInfo.event.startTime}
                    <span style={{fontWeight: '500'}}> {`${start}-${end}`}</span>
                    <br />
                    <span>{eventInfo.event.title}</span>
                </div>
            );
        } else {
            return (
                <div style={{borderRadius: '5px'}}>
                    {eventInfo.event.startTime}
                    <span style={{fontWeight: '500'}}> {`${start}-${end}`}</span>
                    <br />
                    <span>{eventInfo.event.title}</span>
                </div>
            );
        }
    }
}

CalendarScheduleComponent.defaultProps = {
    navLinks: true,
    handleWindowResize: true,
    dayMaxEventRows: true,
    hideAddEventButton: false,
    clientMode: false,
    events: undefined,
    selectable: true,
    isLexdocs: false,
    renderEventContent: undefined,
    defaultView: 'dayGridMonth',
    initialView: 'dayGridMonth',
    locale: 'pl',
    firstDay: 1,
};

CalendarScheduleComponent.propTypes = {
    navLinks: PropTypes.bool,
    windowResizeDelay: PropTypes.number,
    height: PropTypes.string,
    handleWindowResize: PropTypes.bool,
    clientMode: PropTypes.bool,
    dayMaxEventRows: PropTypes.bool,
    hideAddEventButton: PropTypes.bool,
    selectable: PropTypes.bool,
    isLexdocs: PropTypes.bool,
    defaultView: PropTypes.string,
    firstDay: PropTypes.number,
    duration: PropTypes.number,
    locale: PropTypes.string,
    select: PropTypes.func.isRequired,
    eventClick: PropTypes.func.isRequired,
    handleClickOperationButton: PropTypes.func.isRequired,
    renderEventContent: PropTypes.string,
};

export default CalendarScheduleComponent;
