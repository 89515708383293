import {Column} from 'primereact/column';
import {TreeTable} from 'primereact/treetable';
import PropTypes from 'prop-types';
import React from 'react';
import {v4 as uuidv4} from 'uuid';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import ActionLink from '../../components/ActionLink';
import {CustomMessages} from '../../components/CustomMessages';
import DivContainer from '../../components/DivContainer';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import HandleChangeUtils from '../../components/utils/HandleChangeUtils';
import SimpleReactValidator from '../../components/validator';
import HierarchicalDictionaryItemService from '../../services/HierarchicalDictionaryItemService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import ActionButton from '../../components/ActionButton';
import {CookiesConstants} from '../../utils/CookiesConstants';
import BaseBreadCrumb from '../../baseContainers/BaseBreadCrumb';

class HierarchicalDictionaryContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new HierarchicalDictionaryItemService());

        this.isCustomHeaderItems = !this.isLexyPortal();
        this.showExitConfirmationDialog = true;
        this.validateAndExecute = this.validateAndExecute.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.onEditorValueChange = this.onEditorValueChange.bind(this);
        this.state = {
            loading: true,
            elementId: props.elementId,
            element: {
                protocols: [],
            },
            prevName: undefined,
            validatedProtocols: false,
            expandedKeys: [],
        };
        this.validators = [];
        this.columnVisibilityOptions = [{name: 'state', label: 'Stan'}];
    }

    getUpdateSucces(response) {
        const id = response?.code;
        if (id) {
            return `Słownik ${id} został zaktualizowany`;
        } else {
            return 'Słownik został zaktualizowany';
        }
    }

    getAddSucces() {
        return 'Słownik został utworzony';
    }

    processChildren(nodes) {
        if (nodes !== null && nodes !== undefined) {
            nodes.forEach((element) => {
                this.validators[element.data.uuid] = new SimpleReactValidator();
                this.processChildren(element.children);
            });
        }
    }

    updateElement(data) {
        this.processChildren(data.children);
        this.setState(
            {
                element: {
                    id: data.id,
                    code: data.code,
                    name: data.name,
                    children: data.children,
                },
                prevName: data.name,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    initAfterSetElement() {
        const buttons = document.getElementsByClassName('p-treetable-toggler');
        if (buttons?.length > 0) {
            for (let i = 0; i < buttons.length; i++) {
                const button = buttons[i];
                if (button?.style?.visibility === 'hidden') {
                    button.style.width = '0px';
                }
            }
        }
        super.initAfterSetElement();
    }

    initBeforeSetElement() {}

    prepareFooterItems() {
        const {cancelUrl, viewMode} = this.props;
        const {elementId} = this.state;
        let goBackUrl = cancelUrl;

        goBackUrl = `${cancelUrl}/${elementId}`;
        return [
            {
                label: 'Anuluj',
                className: 'cancel-button gray',
                href: AppPrefixUtils.locationHrefUrl(goBackUrl),
                rendered: viewMode === 'EDIT',
            },
            {
                label: 'Zapisz',
                className: 'float-right',
                onClick: this.handleFormSubmit,
                variant: 'accent',
                rendered: viewMode === 'EDIT' && this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE'),
            },
        ];
    }

    prepareHeaderItems() {
        const {viewMode} = this.props;
        return [
            this.prepareEditButton(
                viewMode === 'VIEW' && this.authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_ADMIN_CONTENT_MANAGE')
            ),
        ];
    }

    handleGoToEdit() {
        const {editUrl} = this.props;
        const {element} = this.state;
        window.location.href = AppPrefixUtils.locationHrefUrl(`${editUrl}/${element.code}`);
    }

    getBackLabel() {
        return 'Wróć';
    }

    getContainerListName() {
        return 'hierarchical-dictionary-list-container';
    }

    render() {
        return (
            <React.Fragment>
                <BaseBreadCrumb items={this.breadCrumbItems()}></BaseBreadCrumb>
                {this.renderView()}
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
            </React.Fragment>
        );
    }

    validateTemplateNode(node) {
        const valid = node?.level !== undefined && node?.level !== null && node?.level !== '';
        return valid;
    }

    validateTemplateChildren(children, expandedKeys, parentKey) {
        let valid = true;
        if (children) {
            children.forEach((node) => {
                let nodeValid = this.validateTemplateNode(node.data);
                if (!nodeValid && parentKey) {
                    expandedKeys[parentKey] = true;
                }
                valid = valid && nodeValid;
                valid = valid && this.validateTemplateChildren(node.children, expandedKeys, node.key);
            });
        }
        return valid;
    }

    validateAndExecute() {
        let valid = true;
        let expandedKeys = this.state.expandedKeys;
        valid = valid && this.validateTemplateChildren(this.state.element.children, expandedKeys);
        console.log('valid', valid, this.state.element.children);
        for (let validator in this.validators) {
            if (!this.validators[validator].allValid()) {
                valid = false;
                // expandedKeys[validator] = true;
            }
        }
        if (!this.validator.allValid()) {
            valid = false;
        }
        if (valid) {
            const {backUrl} = this.props;
            const {element} = this.state;
            this.scrollToTop();
            if (this._isMounted) {
                this.blockUi();
                this.service
                    .update(element)
                    .then(() => {
                        this.unblockUi();
                        this.persistMessage('success', '', this.getUpdateSucces());
                        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
                    })
                    .catch((err) => {
                        this.unblockUi();
                        this.showErrorMessage(err.message);
                    });
            }
        } else {
            this.handleExpandedKeys(expandedKeys, () => {
                this.validator.showMessages();
                for (let validator in this.validators) {
                    this.validators[validator].showMessages();
                }
                this.scrollToError = true;
                this.validated = true;
                this.forceUpdate();
            });
        }
    }

    afterUpdateSuccess(response) {
        const backUrl = this.props.cancelUrl + '/' + this.state.elementId;
        this.blockUi();
        this.persistMessage('success', '', this.getUpdateSucces(response));
        window.location.href = AppPrefixUtils.locationHrefUrl(backUrl);
    }

    handleFormSubmit() {
        this.validateAndExecute();
    }

    breadCrumbItems() {
        return [
            {name: 'Słowniki', url: '/#/hierarchical-dictionary-list'},
            {name: 'Słownik', url: `/#/hierarchical-dictionary/details/${this.state.element?.id}`},
        ];
    }

    prepareEditButton(rendered, label) {
        const {editUrl} = this.props;
        return {
            label: label !== undefined ? label : 'Edytuj',
            type: 'BUTTON',
            variant: 'dark',
            onClick: this.saveCookie(CookiesConstants.DETAILS_VIEW_MODE, 'EDIT'),
            href: `${AppPrefixUtils.locationHrefUrl(`${editUrl}/${this.props.elementId}`)}`,
            rendered: rendered,
            // iconName: 'mdi-pencil',
            iconSide: 'left',
            iconSize: 'm',
            iconColor: 'white',
        };
    }

    handleRemoveElement(rowData) {
        let newNodes = this.state.element.children;
        console.log('handleRemoveElement', newNodes, rowData.data.uuid);
        let editedNode = this.findNodeByUuid(newNodes, rowData.data.uuid, true);
        console.log('editedNode', editedNode);
        if (editedNode !== null && editedNode !== undefined) {
            let index = -1;
            editedNode.children.forEach((el, i) => {
                if (el.data.uuid === rowData.data.uuid) {
                    index = i;
                }
            });
            if (index >= 0 && editedNode.children !== undefined && editedNode.children.length >= index - 1) {
                editedNode.children.splice(index, 1);
            }
        } else {
            let index = -1;
            newNodes.forEach((el, i) => {
                if (el.data.uuid === rowData.data.uuid) {
                    index = i;
                }
            });
            if (index >= 0 && newNodes !== undefined && newNodes.length >= index - 1) {
                newNodes.splice(index, 1);
            }
        }
        delete this.validators[rowData.data.uuid];
        this.recalculateTree(newNodes);
    }

    handleAddSubElement(rowData) {
        let newNodes = this.state.element.children;
        console.log('handleAddSubElement');
        let editedNode = this.findNodeByUuid(newNodes, rowData.data.uuid);
        if (editedNode.children === null || editedNode.children === undefined) {
            editedNode.children = [];
        }
        let lastLevel = 0;
        if (editedNode.children) {
            editedNode.children.forEach((el) => {
                const originalLevel = parseInt(el.data.level);
                if (originalLevel > lastLevel) {
                    lastLevel = originalLevel;
                }
            });
        }
        lastLevel = lastLevel + 1;
        let generatedUuid = uuidv4();
        let obj = {
            key: `${rowData.key}-${lastLevel}`,
            data: {
                element: '',
                level: lastLevel,
                treeLevel: `${rowData.key}-${lastLevel}`,
                uuid: generatedUuid,
            },
        };
        editedNode.children.push(obj);
        this.validators[generatedUuid] = new SimpleReactValidator();
        let expandedKeys = this.state.expandedKeys;
        expandedKeys[editedNode.key] = true;
        this.handleExpandedKeys(expandedKeys);
        this.recalculateTree(newNodes);
    }

    handleAddElement() {
        console.log('handleAddElement');
        let newNodes = this.state.element.children;
        let lastLevel = 0;
        if (newNodes) {
            newNodes.forEach((el) => {
                const originalLevel = parseInt(el.data.level);
                if (originalLevel > lastLevel) {
                    lastLevel = originalLevel;
                }
            });
        }
        lastLevel = lastLevel + 1;
        let generatedUuid = uuidv4();
        let obj = {
            key: lastLevel,
            data: {
                name: '',
                level: lastLevel,
                treeLevel: lastLevel,
                treeLevelReport: lastLevel,
                uuid: generatedUuid,
            },
        };
        newNodes.push(obj);
        this.validators[generatedUuid] = new SimpleReactValidator();
        this.recalculateTree(newNodes);
    }

    actionTemplate(rowData) {
        return (
            <DivContainer colClass='row'>
                <DivContainer colClass='col-md-12'>
                    <ActionLink
                        label={'Usuń punkt'}
                        handleClick={(e) => {
                            e.preventDefault();
                            this.handleRemoveElement(rowData);
                        }}
                        key={`remove-${rowData.key}`}
                    />
                </DivContainer>
                <DivContainer colClass='col-md-12'>
                    <ActionLink
                        label={'Dodaj podpunkt'}
                        handleClick={(e) => {
                            e.preventDefault();
                            this.handleAddSubElement(rowData);
                        }}
                        key={`add-${rowData.key}`}
                    />
                </DivContainer>
            </DivContainer>
        );
    }

    isEditable(editable) {
        return editable;
    }

    findNodeByUuid(nodes, uuid, findParent, nod, recursive = true) {
        let result = null;
        if (nodes !== null && nodes !== undefined) {
            nodes.forEach((node) => {
                if (!findParent) {
                    if (node.data.uuid === uuid) {
                        result = node;
                    } else if (recursive) {
                        let foundInChildren = this.findNodeByUuid(node.children, uuid);
                        if (foundInChildren) {
                            result = foundInChildren;
                        }
                    }
                } else {
                    let foundInChildren = this.findNodeByUuid(node.children, uuid, false, node.key, false);
                    if (foundInChildren) {
                        result = node;
                    } else if (!result) {
                        result = this.findNodeByUuid(node.children, uuid, findParent, node.key);
                    }
                }
            });
        }
        return result;
    }
    renderBackLink() {
        return (
            <ActionLink
                rendered={this.backLinkRendered}
                label={this.getBackLabel()}
                className='p-link float-right'
                handleClick={this.handleGoBack.bind(this)}
                variant='accent'
                iconName='mdi-arrow-left'
                iconSide='left'
                iconSize='xs'
                iconColor='accent'
            />
        );
    }

    recalculateNodes(nodes, parentKey) {
        let level = 1;
        if (nodes) {
            nodes.forEach((node) => {
                node.data.level = level;
                node.data.treeLevel = `${parentKey ? `${parentKey}-` : ''}${level}`;
                node.key = `${parentKey ? `${parentKey}-` : ''}${level}`;
                this.recalculateNodes(node.children, node.key);
                level = level + 1;
            });
        }
    }

    recalculateTree(newNodes) {
        this.recalculateNodes(newNodes);
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                children: newNodes,
            },
        }));
    }

    onEditorValueChange(uuid, field, value) {
        console.log('onEditorValueChange', uuid, field, value);
        let newNodes = this.state.element.children;
        let editedNode = this.findNodeByUuid(newNodes, uuid);
        console.log('editedNode', editedNode);
        editedNode.data[field] = value;
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                children: newNodes,
            },
        }));
    }

    inputText(editableFunction, fieldPath, header, placeholder, validators, rowData) {
        const {viewMode} = this.props;
        let fieldPathSplit = fieldPath.split('.');
        const id = fieldPathSplit[fieldPathSplit.length - 1] + rowData.data.uuid;
        let value = HandleChangeUtils.getValueInObjPath(fieldPath, rowData);
        return (
            <InputTextComponent
                id={id}
                label={header}
                name={fieldPath}
                value={value}
                placeholder={placeholder}
                showLabel={false}
                insideTable
                rendered
                validator={this.validators[rowData.data.uuid]}
                validators={validators}
                onChange={(inputType, parameters, event) =>
                    this.onEditorValueChange(rowData.data.uuid, fieldPathSplit[fieldPathSplit.length - 1], event.target.value)
                }
                viewMode={editableFunction(rowData, this.props.viewMode !== 'VIEW') ? viewMode : 'VIEW'}
            />
        );
    }

    inputTextArea(editableFunction, fieldPath, header, placeholder, validators, rowData) {
        const {viewMode} = this.props;
        let fieldPathSplit = fieldPath.split('.');
        const id = fieldPathSplit[fieldPathSplit.length - 1] + rowData.data.uuid;
        let value = HandleChangeUtils.getValueInObjPath(fieldPath, rowData);
        return (
            <InputTextareaComponent
                id={id}
                label={header}
                name={fieldPath}
                value={value}
                placeholder={placeholder}
                showLabel={false}
                insideTable
                rendered
                validator={this.validators[rowData.data.uuid]}
                validators={validators}
                onChange={(inputType, parameters, event) =>
                    this.onEditorValueChange(rowData.data.uuid, fieldPathSplit[fieldPathSplit.length - 1], event.target.value)
                }
                viewMode={editableFunction(rowData, this.props.viewMode !== 'VIEW') ? viewMode : 'VIEW'}
                autoResize
            />
        );
    }

    handleExpandedKeys(expandedKeys, onAfterStateChange) {
        this.setState({expandedKeys}, () => (onAfterStateChange ? onAfterStateChange() : null));
    }

    renderDetails() {
        let editable = this.props.viewMode !== 'VIEW';
        let columns = [
            {field: 'treeLevel', header: 'Lp', expander: true, width: '120px'},
            // { field: 'data.level', header: 'Poziom', editor: 'TEXT', editable: this.checkIfFromTemplate },
            {field: 'data.name', header: 'Nazwa', editor: 'TEXT', editable: true},
            {
                field: 'data.description',
                header: 'Opis',
                editor: 'TEXTAREA',
                editable: true,
                validators: 'not_required',
            },
        ];
        if (this.props.viewMode !== 'VIEW') {
            columns.push({
                key: 'actions',
                header: 'Akcje',
                body: this.actionTemplate.bind(this),
                width: '180px',
            });
        }
        let columnsWithAction = columns;
        const dynamicColumns = columnsWithAction.map((col) => {
            let editableFunc;
            if (col.editable && col.editable instanceof Function) {
                editableFunc = col.editable.bind(this, editable, col);
            } else {
                editableFunc = this.isEditable.bind(this, editable, col);
            }
            let body;
            if (col.editor === 'TEXT') {
                body = this.inputText.bind(this, editableFunc, col.field, col.header, col.placeholder, col.validators);
            } else if (col.editor === 'TEXTAREA') {
                body = this.inputTextArea.bind(this, editableFunc, col.field, col.header, col.placeholder, col.validators);
            } else if (col.body instanceof Function) {
                body = col.body; // .bind(this, editableFunc);
            } else {
                body = col.body;
            }
            return (
                <Column
                    expander={col.expander ? col.expander : false}
                    key={col.key ? col.key : col.field}
                    field={col.field}
                    header={col.header}
                    body={body}
                    style={col.width !== undefined ? {width: col.width} : null}
                />
            );
        });
        const rowClassName = (node) => {
            if (!node.key.includes('-')) {
                return {'parent-node': !node.key.includes('-')};
            } else return {'child-node': node.key.includes('-')};
        };
        return (
            <React.Fragment>
                <div>
                    <InputTextComponent
                        id='name'
                        name='name'
                        label={'Nazwa'}
                        colClass='col-lg-4 col-sm-12'
                        value={this.state.element.name}
                        validator={this.validator}
                        validators='required|alpha_space|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='code'
                        name='code'
                        label={'Kod'}
                        colClass='col-lg-4 col-sm-12'
                        value={this.state.element.code}
                        validator={this.validator}
                        validators='required|alpha_space_dash|max:100'
                        onChange={this.handleChange}
                        viewMode={'VIEW'}
                    />
                </div>
                {this.props.viewMode !== 'VIEW' && (
                    <DivContainer colClass='row'>
                        <DivContainer colClass='col-md-12  mb-4 mt-2'>
                            <ActionButton
                                className='float-left button-add'
                                label={'Dodaj punkt '}
                                handleClick={() => {
                                    this.handleAddElement(this.state.treeStructure);
                                }}
                                key={`add-element`}
                                iconName='mdi-plus'
                                iconSide='left'
                            />
                        </DivContainer>
                    </DivContainer>
                )}
                <DivContainer colClass='col-12'>
                    <DivContainer colClass='row datatable-responsive'>
                        <TreeTable
                            id='tree-table'
                            emptyMessage='Brak rekordów do wyświetlenia'
                            value={this.state.element.children}
                            expandedKeys={this.state.expandedKeys}
                            onToggle={(e) => this.handleExpandedKeys(e.value)}
                            rowClassName={rowClassName}
                        >
                            {dynamicColumns}
                        </TreeTable>
                    </DivContainer>
                </DivContainer>
            </React.Fragment>
        );
    }
}

HierarchicalDictionaryContainer.defaultProps = {
    backUrl: '/#/hierarchical-dictionary-list',
    cancelUrl: '/#/hierarchical-dictionary/details',
    editUrl: '/#/hierarchical-dictionary/edit',
};

HierarchicalDictionaryContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default HierarchicalDictionaryContainer;
