import moment from 'moment';
import Constants from '../utils/constants';
import BaseServiceCrud from './BaseServiceCrud';
import {saveAs} from 'file-saver';

export default class ScheduledConsultationService extends BaseServiceCrud {
    constructor() {
        super();
        this.path = 'scheduledConsultations';
        this.getMessages = this.getMessages.bind(this);
        this.getEventLogs = this.getEventLogs.bind(this);
    }

    getCriteria(criteria) {
        return this.objToQueryString({
            id: criteria.id,
            first_result: criteria.firstResult,
            max_results: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            payment_status: criteria.paymentStatus?.enumValue,
            client: criteria.client,
            date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            date_to: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
        });
    }

    getMessageCriteria(criteria) {
        return this.objToQueryString({
            first_result: criteria?.firstResult,
            max_results: criteria?.maxResults,
            sort_field: criteria?.sortField,
            sort_asc: criteria?.sortAsc,
        });
    }

    getEventLogsCriteria(criteria) {
        return this.objToQueryString({
            first_result: criteria?.firstResult,
            max_results: criteria?.maxResults,
            sort_field: criteria?.sortField,
            sort_asc: criteria?.sortAsc,
        });
    }

    getScheduledEventsByClientId(id, date, periodType) {
        return this.fetch(`${this.domain}/${this.path}/scheduled-events-by-client-id/${id}/${date}/${periodType}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }



    
    getScheduledEventsByConsultantId(id, date, periodType) {
        return this.fetch(`${this.domain}/${this.path}/scheduled-events-by-consultant-id/${id}/${date}/${periodType}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    isTemplatePinnedToConsultation(agreementUuid) {
        return this.fetch(`${this.domain}/${this.path}/is-template-pinned-to-consultation/${agreementUuid}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getMessages(scheduledConsultationId, criteria) {
        const queryString = this.getMessageCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/messages-by-scheduled-consultation/${scheduledConsultationId}/${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getEventLogs(scheduledConsultationId, criteria) {
        const queryString = this.getEventLogsCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/event-logs-by-scheduled-consultation/${scheduledConsultationId}/${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    saveFeedback(element) {
        return this.fetch(`${this.domain}/${this.path}/save-feedback`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    shouldUserPayForConsultation(clientId) {
        return this.fetch(`${this.domain}/${this.path}/should-user-pay-for-consultation/${clientId}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    reserveMailing(element) {
        return this.fetch(`${this.domain}/${this.path}/reserve-mailing`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    startMailing(element, scheduledConsultationId) {
        return this.fetch(`${this.domain}/${this.path}/mailing-for-scheduled-consultation/${scheduledConsultationId}`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    reserveAndPayForMailing(element) {
        return this.fetch(`${this.domain}/${this.path}/reserve-and-pay-for-mailing`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getByOrderUuid(uuid) {
        return this.fetch(`${this.domain}/${this.path}/get-by-order-uuid/${uuid}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    canBePaid(scheduledConsultationId, orderUuid) {
        return this.fetch(`${this.domain}/${this.path}/can-be-paid/${scheduledConsultationId}/${orderUuid}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    downloadConsultationMessages(scheduledConsultationId, errFnc) {
        const url = new URL(`${this.domain}/${this.path}/download-consultation-message/${scheduledConsultationId}`);
        const headers = {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cahce',
        };
        if (this.auth.loggedIn()) {
            headers['Authorization'] = this.auth.getToken();
        }

        this.fetchGet(url, headers)
            .then((response) => {
                const fileHeader = [...response.headers.entries()].find((value) => {
                    return value[0] === 'file-name';
                });

                return [response, fileHeader[1]];
            })
            .then((response) => {
                new Promise((resolve, reject) => {
                    return resolve(response[0].blob());
                }).then((blob) => {
                    saveAs(blob, `${response[1]}`);
                });
            })
            .catch((err) => {
                errFnc('Brak wiadomości do wygenerowania');
                console.log('downloadFile error', err);
            });
    }
}
