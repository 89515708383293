import React from 'react';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import DivContainer from '../../components/DivContainer';
import {ActionButton} from '../../components/ActionButton';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import TypesModel from '../../model/TypesModel';
import SettingService from '../../services/SettingService';
import DialogResizer from '../../components/DialogResizer';

class PackageTypeDialog extends BaseDialogContainer {
    constructor(props) {
        super(props);
        this.state = {
            allTypes: this.props.allTypes,
            subscriptionTypes: this.props.types,
            element: undefined,
            types: undefined,
            viewMode: this.props.viewMode,
            activeIndex: 0,
            maxPaymentAmount: 15000,
        };
        this.settingsService = new SettingService();
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    createOrUpdate() {
        if (this.state.viewMode === 'NEW') {
            let value = this.state.types.filter((t) => t.selected);
            this.props.handleAddTypes(value);
            DialogResizer.removeMaskIfExistOneDialog();
        } else {
            let element = this.state.element;
            this.props.handleEditType(element);
            DialogResizer.removeMaskIfExistOneDialog();
        }
    }

    initBeforeSetElement() {
        let filteredArray = [];
        this.settingsService
            .getPaymentMaxAmount()
            .then((resp) =>
                this.setState({
                    maxPaymentAmount: resp['PAYMENT_MAX_AMOUNT'],
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać maksymalnej kwoty transakcji');
            });
        if (this.props.viewMode === 'NEW') {
            let subscriptionTypes = this.state.subscriptionTypes;
            let allTypes = this.state.allTypes;
            filteredArray = allTypes.filter(function (x) {
                return (
                    subscriptionTypes.filter(function (y) {
                        return y.enumValue === x.enumValue;
                    }).length === 0
                );
            });
            this.setState({
                types: filteredArray,
            });
        } else {
            this.setState({
                element: TypesModel.copy(this.props.element),
            });
        }
    }

    prepareFooter() {
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton
                    buttonType={'NEXT'}
                    label={this.state.viewMode === 'NEW' ? this.translate('type.add') : this.translate('Zapisz')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                />
            </div>
        );
    }

    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            DialogResizer.removeMaskIfExistOneDialog();
            this.blockUi(this.handleValidForm);
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // this.scrollToError = true;
            this.forceUpdate();
        }
    }
    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('type.addHeader')}`
                            : this.state.viewMode === 'EDIT'
                            ? `${t('type.editHeader')}`
                            : null}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }

    getContainerListName() {
        return 'subscription-list-container';
    }

    getAddSucces() {
        const {t} = this.props;
        return t('type.addSuccessfull');
    }

    renderDetails() {
        return this.renderView();
    }

    renderComponents(element, index) {
        return (
            <React.Fragment>
                <InputCheckboxComponent
                    id={`${index}-types`}
                    name='selected'
                    label={element.label}
                    labelOnRight
                    value={!!element.selected}
                    validator={this.validator}
                    validators='not_required'
                    colClass='col-md-12'
                    onChange={this.handleChange}
                    viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
                    rendered={this.props.viewMode === 'NEW'}
                    stateField={index !== undefined ? `types[${index}]` : index}
                    positioning='col-md-12 form-group-checkbox-flex'
                />
                <InputTextComponent
                    label={'Okres rozliczeniowy'}
                    placeholder='Wprowadź imię'
                    colClass='col-md-12'
                    value={this.translate(`SubscriptionType.${element.enumValue}`)}
                    validator={this.validator}
                    validators='required|alpha|max:100'
                    onChange={this.handleChange}
                    rendered={this.props.viewMode === 'EDIT'}
                    viewMode={'VIEW'}
                />
                <InputNumberComponent
                    id='consultation'
                    name='consultation'
                    label={'Konsultacje'}
                    colClass='col-6'
                    validators='required|number_max:999'
                    value={element.consultation}
                    validator={this.validator}
                    onChange={this.handleChange}
                    viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
                    stateField={index !== undefined ? `types[${index}]` : index}
                    rendered={this.props.viewMode === 'EDIT' || element.selected ? true : false}
                />
                <InputNumberComponent
                    id='price'
                    name='price'
                    label={'Cena'}
                    colClass='col-6'
                    validators={`required|number_max:${this.state.maxPaymentAmount}|number_min:1`}
                    value={element.price}
                    validator={this.validator}
                    onChange={this.handleChange}
                    viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
                    stateField={index !== undefined ? `types[${index}]` : index}
                    rendered={this.props.viewMode === 'EDIT' || element.selected ? true : false}
                    mode='currency'
                    currency='PLN'
                    locale='pl-PL'
                />
            </React.Fragment>
        );
    }

    renderView() {
        return this.state?.types
            ? this.state.types.map((i, index) => {
                  return (
                      <div class='row' style={{width: '100%'}}>
                          {this.renderComponents(i, index)}
                      </div>
                  );
              })
            : this.state.element
            ? this.renderComponents(this.state.element, undefined)
            : null;
    }
}

PackageTypeDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
};

PackageTypeDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
};

export default withTranslation()(PackageTypeDialog);
