import {v4 as uuidv4} from 'uuid';

export default class AgreementTagModel {
    constructor() {
        this.id = undefined;
        this.version = null;
        this.label = '';
        this.description = '';
        this.uuid = '';
        this.requirementType = null;
        this.requirementDialog = '';
        this.field = '';
        this.type = '';
        this.answerOptions = [];
        this.minText = null;
        this.maxText = null;
        this.minDate = null;
        this.dateLimitRequired = null;
        this.maxDate = null;
        this.dictionary = null;
        this.numberProperties = new NumberProperties();
    }

    static assign(from, to) {
        if (from) {
            to.id = from.id;
            to.version = from.version;
            to.label = from.label;
            to.description = from.description;
            to.uuid = from.uuid;
            to.requirementType = from.requirementType;
            to.requirementDialog = from.requirementDialog;
            to.field = from.field;
            to.type = from.type;
            to.answerOptions = from.answerOptions ? AgreementTagModel.answerOptions(from.answerOptions) : undefined;
            to.minText = from.minText;
            to.maxText = from.maxText;
            to.dateLimitRequired = from.dateLimitRequired;
            const date = new Date();
            date.setHours(0, 0, 0, 0);
            to.minDate = from.minDate ? new Date(from.minDate) : date;
            to.maxDate = from.maxDate ? new Date(from.maxDate) : date;
            to.dictionary = from.dictionary;
            to.parentTag = from.parentTag;
            to.subTemplate = from.subTemplate;
            if (to.subTemplate) {
                if (to.subTemplate?.active) {
                    to.subTemplate.visibleName = to.subTemplate.name;
                } else {
                    to.subTemplate.visibleName = to.subTemplate.name + ' (Nieaktywny)';
                }
            }
            to.numberProperties = JSON.parse(JSON.stringify(from.numberProperties));
            to.sumElements = from.sumElements;
            to.point = from.point;
            to.factors = from.factors;
        } else {
            to = new AgreementTagModel();
        }
    }

    static copy(from) {
        const to = new AgreementTagModel();
        AgreementTagModel.assign(from, to);
        return to;
    }
    static answerOptions(from, to) {
        to = [];
        if (!from) {
            return to;
        }
        from.forEach((f, index) => {
            const newTo = f;
            newTo.index = index;
            newTo.subTemplate = f.subTemplate;
            newTo.uuid = uuidv4();
            to.push(newTo);
        });
        return to;
    }
}
class NumberProperties {
    constructor() {
        this.showPercent = false;
        this.showDescription = false;
        this.convertToRoman = false;
        this.showCurrency = false;
        this.decimalPlaces = 0;
        this.numberMin = null;
        this.numberMax = null;
    }
}
