import React from 'react';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import DivContainer from '../../components/DivContainer';
import {ActionButton} from '../../components/ActionButton';
import SpecializationService from '../../services/SpecializationService';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
import SettingService from '../../services/SettingService';
import DialogResizer from '../../components/DialogResizer';

class SpecializationListDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new SpecializationService());
        this.settingsService = new SettingService();
        this.state = {
            element: {
                id: '',
                name: '',
                videoChatFee: undefined,
                chatFee: undefined,
                mailingFee: undefined,
            },
            viewMode: this.props.viewMode,
            activeIndex: 0,
            maxPaymentAmount: 15000,
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <ActionButton
                    label={this.state.viewMode === 'NEW' ? t('Dodaj') : t('Edytuj')}
                    buttonType={'NEXT'}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                />
            </div>
        );
    }
    initBeforeSetElement() {
        this.settingsService
            .getPaymentMaxAmount()
            .then((resp) =>
                this.setState({
                    maxPaymentAmount: resp['PAYMENT_MAX_AMOUNT'],
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać maksymalnej kwoty transakcji');
            });
    }

    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('specialization.addHeader')}`
                            : this.state.viewMode === 'EDIT'
                            ? `${t('specialization.editHeader')}`
                            : null}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }

    getContainerListName() {
        return 'specialization-list-container';
    }

    getAddSucces() {
        const {t} = this.props;
        return t('specialization.addSpecializationSuccess');
    }

    renderDetails() {
        return this.renderView();
    }

    handleFormSubmit() {
        const {t} = this.props;
        if (this.validator.allValid()) {
            DialogResizer.removeMaskIfExistOneDialog();
            new SpecializationService()
                .add(this.state.element)
                .then(() => {
                    this.persistMessage('success', '', t('specialization.addSuccessfull'));
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                })
                .catch((err) => {
                    this.persistMessage('error', '', err.message);
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                });
        } else {
            this.validator.showMessages();
            // this.scrollToError = true;
            this.forceUpdate();
        }
    }
    renderView() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='name'
                        name='name'
                        label={'Nazwa specjalizacji'}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-6'
                        value={this.state.element.name}
                        validator={this.validator}
                        validators='required|alpha_space|max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputNumberComponent
                        id='chatFee'
                        name='chatFee'
                        label={'Cena za czat'}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-6'
                        value={this.state.element.chatFee}
                        locale='pl-PL'
                        currency='PLN'
                        validator={this.validator}
                        validators={`required|number_max:${this.state.maxPaymentAmount}`}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        mode='currency'
                    />
                    <InputNumberComponent
                        id='videoChatFee'
                        name='videoChatFee'
                        label={'Cena za rozmowę wideo'}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-6'
                        locale='pl-PL'
                        currency='PLN'
                        mode='currency'
                        value={this.state.element.videoChatFee}
                        validator={this.validator}
                        validators={`required|number_max:${this.state.maxPaymentAmount}`}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputNumberComponent
                        id='mailingFee'
                        name='mailingFee'
                        label={'Cena za mailing'}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-6'
                        locale='pl-PL'
                        currency='PLN'
                        mode='currency'
                        value={this.state.element.mailingFee}
                        validator={this.validator}
                        validators={`required|number_max:${this.state.maxPaymentAmount}`}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
}

SpecializationListDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
};

SpecializationListDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
};

export default withTranslation()(SpecializationListDialog);
