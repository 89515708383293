import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import InputPasswordComponent from '../../components/inputs/InputPasswordComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import SimpleReactValidator from '../../components/validator';
import SettingService from '../../services/SettingService';
import RegistrationService from '../../services/RegistrationService';
import RegisterModel from '../../model/RegisterModel';
import {Messages} from 'primereact/messages';
import ApprovalContentService from '../../services/ApprovalContentService';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import DivContainer from '../../components/DivContainer';
import PackageContainer from '../PackagesContainer';
import InputSelectButtonComponent from '../../components/inputs/InputSelectButtonComponent';
import UserService from '../../services/UserService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import ActionButton from '../../components/ActionButton';

class RegistrationContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props);
        this.handleFormRegisterUser = this.handleFormRegisterUser.bind(this);
        this.registrationService = new RegistrationService();
        this.approvalContentService = new ApprovalContentService();
        this.settingService = new SettingService();
        this.userService = new UserService();
        this.jwtRefreshBlocked = true;

        this.state = {
            element: new RegisterModel(),
            attachments: [],
            handleAgreement: false,
            showCaptcha: false,
            captchaPreventAction: false,
            initDatas: true,
            maxLength: 10,
            activeIndex: 0,
            minLength: 8,
            lowerCaseCount: 1,
            upperCaseCount: 1,
            numbersCount: 1,
            selectedPackagesMap: new Map(),
            billingCycleMap: new Map(),
            specialCharactersCount: 1,
            personOptions: [
                {
                    label: 'Osoba',
                    enumValue: 'PERSON',
                },
                {
                    label: 'Firma',
                    enumValue: 'COMPANY',
                },
            ],
        };
        this.isLexyPortal = this.isLexyPortal();
        this.validator = new SimpleReactValidator({}, this.translate);
        this.handleChangeOption = this.handleChangeOption.bind(this);
    }

    handleChangeOption(e) {
        this.setState(
            (prevState) => ({
                element: {
                    ...prevState.element,
                },
            }),
            () => this.cleanElementInputs()
        );
    }

    // Clear tylko wybranych pól z modelu.
    cleanElementInputs() {
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                firstName: undefined,
                lastName: undefined,
                industry: undefined,
                nip: undefined,
                address: {
                    postalCode: undefined,
                    streetName: undefined,
                    streetNumber: undefined,
                    city: undefined,
                    houseNumber: undefined,
                },
                companyName: undefined,
            },
            initDatas: false,
        }));
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    componentDidMount() {
        super.componentDidMount();
        if (this.props.handlePreLogoutUser) {
            this.props.handlePreLogoutUser(() => {
                this.initBeforeSetElement();
            });
        }
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth', // opcjonalnie, aby wykonać płynne przewijanie
        });
    }
    refreshJwtToken() {}

    initBeforeSetElement() {
        this.registrationService
            .getAllApprovals()
            .then((approvals) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        approvalContents: approvals,
                    },
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message, 10000, true, 'Błąd przy pobieraniu listy zgód ');
            });
        this.registrationService
            .getAllActiveSubscriptions(null)
            .then((packages) => {
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        packages: packages,
                    },
                }));
            })
            .catch((err) => {
                this.showErrorMessage(err.message, 10000, true, 'Błąd przy pobieraniu pakietów ');
            });

        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                person: this.state.personOptions[0],
            },
        }));
    }

    requiredApprovals() {
        let selectedApproval = this.state.element.approvalContents;
        for (let index = 0; index < selectedApproval.length; index++) {
            if (selectedApproval[index].required && selectedApproval[index].selected !== true) {
                this.showErrorMessage('Zaznacz obowiązkowe zgody');
                return true;
            }
        }
        return false;
    }

    handleFormRegisterUser(e, selectedPackagesMap) {
        if (!this.requiredApprovals()) {
            let selectedApproval = this.state.element.approvalContents.filter((option) => option.selected === true);
            if (!this.isLexyPortal) {
                if (selectedPackagesMap.size === 0) {
                    this.showErrorMessage('Nie wybrano pakietu');
                    return;
                }
            }

            if (this.validator.allValid()) {
                const {element} = this.state;

                let registerModel = RegisterModel.copy(element);
                registerModel.portal = this.isLexyPortal ? 'LEKSY' : 'LEX_DOCS';

                if (!this.isLexyPortal) {
                    let arrayOfTypes = [];
                    [...selectedPackagesMap].map(([id, value]) => arrayOfTypes.push(value.type));
                    registerModel.subscriptionType = arrayOfTypes;
                }

                if (registerModel.person === 'PERSON') {
                    registerModel.address = undefined;
                }

                registerModel.approvalContents = selectedApproval;

                this.registrationService
                    .registerUser(registerModel)
                    .then((res) => {
                        if (res.portal === 'LEX_DOCS') {
                            window.location.href = res.paymentRegistrationResponse.url + res.paymentRegistrationResponse.token;
                        } else {
                            this.afterAddSuccess(res);
                        }
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message, 10000, true, 'Błąd ');
                    });
            } else {
                this.validator.showMessages();
                this.scrollToError = true;
                this.forceUpdate();
            }
        }
    }

    getContainerListName() {
        return 'login-container';
    }

    afterAddSuccess(response) {
        this.blockUi();
        this.persistMessage('success', '', this.getAddSucces(response));
        window.location.href = AppPrefixUtils.locationHrefUrl('/#/login');
    }

    getAddSucces() {
        return 'Konto zostało utworzone! Proszę sprawdzić email w celach dalszej weryfikacji';
    }

    render() {
        if (this.state.person?.enumValue === 'PERSON') {
            return (
                <div>
                    {this.state.activeIndex === 0 ? this.renderFirstStep() : null}
                    {this.state.activeIndex === 2 && !this.isLexyPortal ? this.renderThirdStep() : null}
                </div>
            );
        } else {
            return (
                <div>
                    {this.state.activeIndex === 0 ? this.renderFirstStep() : null}
                    {this.state.activeIndex === 1 ? this.renderSecondStep() : null}
                    {this.state.activeIndex === 2 && !this.isLexyPortal ? this.renderThirdStep() : null}
                </div>
            );
        }
    }

    handleChangeStep(e) {
        if (!this.requiredApprovals()) {
            if (e.index !== 0) {
                if (this.validator.allValid()) {
                    if (
                        (this.state.element.person.enumValue === 'COMPANY' && e.index === 1) ||
                        (this.state.element.person.enumValue === 'PERSON' && e.index === 2)
                    ) {
                        this.registrationService
                            .isEmailExist(this.state.element.email)
                            .then((emailExist) => {
                                if (emailExist) {
                                    this.showErrorMessage('Podany email istnieje w systemie');
                                    return;
                                } else {
                                    this.validator.hideMessages();
                                    this.setState({activeIndex: e.index});
                                }
                                this.unblockUi();
                            })
                            .catch(() => {
                                this.showErrorMessage('Problem z pobieraniem informacji o mailach');
                                return;
                            });
                    } else {
                        this.setState({activeIndex: e.index});
                    }
                } else {
                    this.validator.showMessages();
                    this.scrollToError = true;
                    this.forceUpdate();
                }
            } else this.setState({activeIndex: e.index});
        }
    }

    renderApproval() {
        return (
            <div className='margin-top-16'>
                {' '}
                {this.state.element.approvalContents
                    .sort((x, y) => x.order - y.order)
                    .map((i, index) => {
                        return (
                            <InputCheckboxComponent
                                id={`${index}-approval`}
                                name='selected'
                                labelClassName='figtree-label'
                                label={i.content}
                                labelOnRight
                                renderLink={i.hyperlink != null ? `${new RegistrationService().getPath()}/${i.hyperlink}` : undefined}
                                renderRequired={i.required === true}
                                value={!!i.selected}
                                validator={this.validator}
                                colClass='col-11'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
                                stateField={`element.approvalContents[${index}]`}
                                positioning='col-md-6 form-group-checkbox-flex'
                            />
                        );
                    })}
            </div>
        );
    }

    renderSubscriptions() {
        return this.state.element.packages.length ? (
            <PackageContainer
                colClass='col-12'
                handleChangePackage={this.handleFormRegisterUser}
                handleTranslate={this.translate}
                viewMode={'EDIT'}
                style={{minWidth: '12em'}}
                packages={this.state.element.packages}
                showHeader={true}
                showTemplates={true}
                service={new RegistrationService()}
                addButton={{label: 'Opłać pakiet', className: 'big-button'}}
            />
        ) : null;
    }

    renderPersonComponents() {
        return (
            <React.Fragment>
                <InputTextComponent
                    id='firstName'
                    name='firstName'
                    label='Imię'
                    classLabel='black-label'
                    placeholder='Wpisz imię'
                    labelForValidator='Imię'
                    value={this.state.element.firstName}
                    onChange={this.handleChange}
                    type='text'
                    validators='required|alpha_space_dash|max:50'
                    validator={this.validator}
                    viewMode='NEW'
                    colClass='col-lg-12 col-md-12'
                    leftMarginLabel={this.isLexyPortal ? '10px' : undefined}
                />
                <InputTextComponent
                    id='lastName'
                    name='lastName'
                    label='Nazwisko'
                    placeholder='Wpisz nazwisko'
                    classLabel='black-label'
                    labelForValidator='Nazwisko'
                    value={this.state.element.lastName}
                    onChange={this.handleChange}
                    type='text'
                    validators='required|alpha_space_dash|max:50'
                    validator={this.validator}
                    viewMode='NEW'
                    colClass='col-lg-12 col-md-12'
                    leftMarginLabel={this.isLexyPortal ? '10px' : undefined}
                />
            </React.Fragment>
        );
    }

    renderCompanyComponents() {
        return (
            <React.Fragment>
                <InputTextComponent
                    id='companyName'
                    name='companyName'
                    label='Nazwa firmy '
                    classLabel='black-label'
                    labelForValidator='Nazwa firmy'
                    placeholder='Wpisz nazwę firmy'
                    value={this.state.element.companyName}
                    onChange={this.handleChange}
                    type='text'
                    validators='required|max:50'
                    validator={this.validator}
                    viewMode='NEW'
                    colClass='col-lg-12 col-md-12'
                    leftMarginLabel={this.isLexyPortal ? '10px' : undefined}
                />

                <InputTextComponent
                    id='nip'
                    name='nip'
                    label='Numer NIP'
                    placeholder='Wprowadź numer NIP'
                    classLabel='black-label'
                    value={this.state.element.nip}
                    labelForValidator='NIP'
                    onChange={this.handleChange}
                    type='text'
                    validators={`required|nip`}
                    validator={this.validator}
                    viewMode='NEW'
                    colClass='col-lg-12 col-md-12'
                    leftMarginLabel={this.isLexyPortal ? '10px' : undefined}
                />

                <InputTextComponent
                    id='industry'
                    name='industry'
                    label='Branża '
                    classLabel='black-label'
                    value={this.state.element.industry}
                    onChange={this.handleChange}
                    type='text'
                    placeholder='Wpisz branżę'
                    validators='required|alpha_space_dash|max:50'
                    validator={this.validator}
                    viewMode='NEW'
                    colClass='col-lg-12 col-md-12'
                    leftMarginLabel={this.isLexyPortal ? '10px' : undefined}
                />
            </React.Fragment>
        );
    }

    renderThirdStep() {
        return (
            <div id='main-package'>
                <div className='col-lg-12 col-sm-12 col-md-12 mx-auto '>
                    <div className='mt-2'>
                        <div className='mb-2 text-center'>
                            <h2 className='title-registration-available-packages'>Dostępne pakiety</h2>
                        </div>
                        <div className='card-body '>
                            <div className='create-user-subtitle '>
                                <Messages id='custom-messages' ref={(el) => (this.messages = el)} />
                                {this.state.message}
                            </div>
                            <div className='row justify-content-center'>{this.renderSubscriptions()}</div>
                            <DivContainer>
                                <div className='row justify-content-center'>
                                    <button
                                        className='back-label   mt-4 col-12'
                                        label='Wróć'
                                        onClick={
                                            this.state.element.person?.enumValue === 'PERSON'
                                                ? this.handleChangeStep.bind(this, {index: 0})
                                                : this.handleChangeStep.bind(this, {index: 1})
                                        }
                                    >
                                        Wróć do poprzedniego kroku
                                    </button>
                                </div>
                            </DivContainer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSecondStep() {
        return (
            <div id='main-register'>
                <div className=' col-lg-5 col-md-8 col-sm-12  mx-auto ' style={{maxWidth: '500px'}}>
                    <div className='card card-signin details-card mt-2'>
                        <div className=' mt-1'>
                            <h2 className='title' style={{marginLeft: '10px'}}>
                                Dane adresowe firmy
                            </h2>
                        </div>
                        <div className='card-body p-2'>
                            <div className='create-user-subtitle '>
                                <Messages id='custom-messages' ref={(el) => (this.messages = el)} />
                                {this.state.message}
                            </div>
                            <div className='row'>
                                <InputTextComponent
                                    id='city'
                                    name='city'
                                    label='Miejscowość '
                                    classLabel='black-label'
                                    value={this.state.element.address.city}
                                    onChange={this.handleChange}
                                    type='text'
                                    placeholder='Wpisz miejscowość...'
                                    validators='required|alpha_space_dash|max:50'
                                    validator={this.validator}
                                    stateField={'element.address'}
                                    viewMode='NEW'
                                    colClass='col-lg-12 col-md-12'
                                />
                                <InputTextComponent
                                    id='streetName'
                                    name='streetName'
                                    label='Ulica '
                                    classLabel='black-label'
                                    value={this.state.element.address.streetName}
                                    onChange={this.handleChange}
                                    type='text'
                                    stateField={'element.address'}
                                    placeholder='Wpisz ulice...'
                                    validators='required|max:50'
                                    validator={this.validator}
                                    viewMode='NEW'
                                    colClass='col-lg-12 col-md-12'
                                />
                                <InputTextComponent
                                    id='postalCode'
                                    name='postalCode'
                                    label='Kod pocztowy'
                                    classLabel='black-label'
                                    placeholder='Wpisz kod pocztowy...'
                                    labelForValidator='Kod pocztowy'
                                    value={this.state.element.address.postalCode}
                                    onChange={this.handleChange}
                                    stateField={'element.address'}
                                    type='text'
                                    validators={`required|zipcode`}
                                    validator={this.validator}
                                    viewMode='NEW'
                                    colClass='col-lg-12 col-md-12'
                                />
                                <InputTextComponent
                                    id='streetNumber'
                                    name='streetNumber'
                                    label='Numer domu'
                                    classLabel='black-label'
                                    value={this.state.element.address.streetNumber}
                                    placeholder='Wpisz numer domu...'
                                    labelForValidator='Numer domu'
                                    stateField={'element.address'}
                                    onChange={this.handleChange}
                                    type='text'
                                    validators={`required|max:5`}
                                    validator={this.validator}
                                    viewMode='NEW'
                                    colClass='col-lg-12 col-md-12'
                                />
                                <InputTextComponent
                                    id='houseNumber'
                                    name='houseNumber'
                                    placeholder='Wpisz numer lokalu...'
                                    label='Numer lokalu'
                                    classLabel='black-label'
                                    stateField={'element.address'}
                                    value={this.state.element.address.houseNumber}
                                    labelForValidator='Numer lokalu'
                                    onChange={this.handleChange}
                                    // className={'form-control'}
                                    type='text'
                                    validators={`required|max:5`}
                                    viewMode='NEW'
                                    colClass='col-12'
                                />

                                <div className='space-between row '>
                                    <div className='col-12 space-between'>
                                        <button
                                            className='back-label   mt-4 col-lg-5 col-sm-12'
                                            label='Wróć'
                                            onClick={() => this.handleChangeStep({index: 0})}
                                        >
                                            Wróć
                                        </button>
                                        <ActionButton
                                            className='next-btn centered-buttons mt-4 col-lg-5 col-sm-12'
                                            label={this.isLexyPortal ? 'Zarejestruj się' : 'Dalej'}
                                            handleClick={() => {
                                                this.isLexyPortal ? this.handleFormRegisterUser() : this.handleChangeStep({index: 2});
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    isPerson() {
        return this.state.element.person?.enumValue === 'PERSON';
    }

    renderFirstStep() {
        return (
            <div id='main-register'>
                <div id='' className='col-xl-4 col-lg-6 col-sm-12 col-md-8 mx-auto '>
                    <div className=' card-signin details-card p-4'>
                        <div style={{paddingLeft: '20px', paddingTop: '40px', marginBottom: '-20px'}}>
                            <h2 className='title'>Zarejestruj się</h2>
                        </div>
                        <div className='card-body p-4'>
                            <div className='create-user-subtitle '>
                                <Messages id='custom-messages' ref={(el) => (this.messages = el)} />
                                {this.state.message}
                            </div>

                            <div className='row my-2 '>
                                <div className='person-selection'>
                                    <InputSelectButtonComponent
                                        id='person'
                                        name='person'
                                        colClass='col-12 mb-2'
                                        value={this.state.element.person}
                                        options={this.state.personOptions}
                                        optionLabel='label'
                                        onChange={this.handleChange}
                                        onAfterStateChange={this.handleChangeOption}
                                        viewMode={'EDIT'}
                                        validator={this.props.validator}
                                        validators='required'
                                        validateViewMode
                                    />
                                </div>

                                {this.isPerson() ? this.renderPersonComponents() : this.renderCompanyComponents()}

                                <InputTextComponent
                                    id='email'
                                    name='email'
                                    leftMarginLabel={this.isLexyPortal ? '10px' : undefined}
                                    label='Email'
                                    placeholder='Wpisz email'
                                    classLabel='black-label'
                                    value={this.state.element.email}
                                    onChange={this.handleChange}
                                    type='text'
                                    validators='required|email'
                                    validator={this.validator}
                                    viewMode='NEW'
                                    colClass='col-lg-12 col-md-12'
                                />
                                <InputPasswordComponent
                                    id='password'
                                    name='password'
                                    label='Hasło'
                                    leftMarginLabel={this.isLexyPortal ? '10px' : undefined}
                                    classLabel='black-label'
                                    value={this.state.element.password}
                                    labelForValidator='Hasło'
                                    placeholder='Wpisz hasło'
                                    onChange={this.handleChange}
                                    type='password'
                                    validators={`required|extended_password:${this.state.minLength}:${this.state.maxLength}:${this.state.lowerCaseCount}:${this.state.upperCaseCount}:${this.state.numbersCount}:${this.state.specialCharactersCount}`}
                                    validator={this.validator}
                                    viewMode='NEW'
                                    colClass='col-lg-12 col-md-12'
                                />
                                <InputPasswordComponent
                                    id='repeatPassword'
                                    name='repeatPassword'
                                    leftMarginLabel={this.isLexyPortal ? '10px' : undefined}
                                    label='Powtórz hasło'
                                    classLabel='black-label'
                                    value={this.state.element.repeatPassword}
                                    labelForValidator='Powtórz hasło'
                                    onChange={this.handleChange}
                                    placeholder='Powtórz hasło'
                                    type='password'
                                    className='p-password-input'
                                    validators={`required|equals:${this.state.element.password}:Hasło`}
                                    validator={this.validator}
                                    viewMode='NEW'
                                    colClass='col-lg-12 col-md-12'
                                />
                                <div className='row'>
                                    <div className='col-xl-7 col-lg-7 col-md-12 '>{this.renderApproval()}</div>

                                    {this.state.element.approvalContents.length !== 0 ? (
                                        <div className='col-xl-5 col-lg-5 col-md-12 '>
                                            <label
                                                id='mendatoryConsentLabel'
                                                style={{fontSize: '13px', fontWeight: '900'}}
                                                className='text-danger'
                                            >
                                                (*) Zgoda obowiązkowa
                                            </label>
                                        </div>
                                    ) : null}
                                </div>

                                <div className='mb-2'>
                                    <div className='row col-lg-12'>
                                        <div className='col-xl-4 col-lg-2 col-md-12 col-sm-12 justify-content-center'></div>
                                        <button
                                            className='col-xl-4 col-lg-5 col-md-12 col-sm-12 back-label  mt-4 '
                                            label={this.isLexyPortal ? 'Zaloguj się' : 'Wróć'}
                                            onClick={() => {
                                                window.location.href = AppPrefixUtils.locationHrefUrl('/#/login');
                                            }}
                                        >
                                            <div style={{textAlign: ''}}>{this.isLexyPortal ? 'Zaloguj się' : 'Wróć'}</div>
                                        </button>
                                        <button
                                            className='col-xl-4 col-lg-5 col-md-12 col-sm-12 btn-register mt-4 '
                                            label={this.isLexyPortal ? 'Zarejestruj się' : 'Następny krok'}
                                            onClick={() => {
                                                if (this.isLexyPortal) {
                                                    this.isPerson() ? this.handleFormRegisterUser() : this.handleChangeStep({index: 1});
                                                } else {
                                                    this.isPerson() ? this.handleChangeStep({index: 2}) : this.handleChangeStep({index: 1});
                                                }
                                            }}
                                        >
                                            <div style={{textAlign: ''}}>{this.isLexyPortal ? 'Zarejestruj się' : 'Następny krok'}</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
RegistrationContainer.propTypes = {
    onAfterLogin: PropTypes.func,
    backUrl: '/#/',
};
RegistrationContainer.propTypes = {
    onAfterLogin: PropTypes.func,
    backUrl: PropTypes.string,
};

export default withTranslation()(RegistrationContainer);
