import BaseServiceCrud from './BaseServiceCrud';

export default class EventLogService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'eventLog';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			dateFrom: criteria.dateFrom,
			dateTo: criteria.dateTo,
			eventType: criteria.eventType?.enumValue,
			logType: criteria.logType?.enumValue,
			onlyLogOut: criteria.onlyLogOut,
			user_email: criteria.userEmail,
			object_type: criteria.objectType?.enumValue,
			object_id: criteria.objectId,
			first_result: criteria.firstResult,
			max_results: criteria.maxResults,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
}
